import {
  REQUEST_REFERAL_SALES,
  RECEIVE_REFERAL_SALES,
  NOT_RECEIVE_REFERAL_SALES,
  REQUEST_REFERAL_DATA,
  RECEIVE_REFERAL_DATA,
  NOT_RECEIVE_REFERAL_DATA,
  REQUEST_CITIES,
  RECEIVE_CITIES,
  NOT_RECEIVE_CITIES,
  REQUEST_COLLECTIONS,
  RECEIVE_COLLECTIONS,
  NOT_RECEIVE_COLLECTIONS,
  REQUEST_LANGUAGES,
  RECEIVE_LANGUAGES,
  FETCH_WIDGET_STATS,
  FETCH_WIDGET_STATS_SUCCESS,
  FETCH_WIDGET_STATS_FAILURE,
  RESET_REFERAL_ERRORS,
} from '../constants/ActionTypes';

export default function referal(
  state = {
    paid: [],
    unpaid: [],
    totalUnpaid: 0,
    filter: 'ALL',
    id: null,
    rate: 0,
    errors: [],
    languages: [],
    widgetStats: {
      total: {},
      labels: [],
      isFetchingWidgetStats: false,
    },
    isFetching: false,
  },
  action
) {
  switch (action.type) {
    case REQUEST_REFERAL_DATA:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_REFERAL_DATA:
      return {
        ...state,
        ...action.data,
        isFetching: false,
      };

    case NOT_RECEIVE_REFERAL_DATA:
      return {
        ...state,
        error: [...state.errors, action.error],
        isFetching: false,
      };

    case REQUEST_REFERAL_SALES:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_REFERAL_SALES:
      return {
        ...state,
        isFetching: false,
        paid: action.paid,
        unpaid: action.unpaid,
        totalEarned: action.totalEarned,
        totalUnpaid: action.totalUnpaid,
      };

    case NOT_RECEIVE_REFERAL_SALES:
      return {
        ...state,
        errors: [...state.errors, action.error],
        isFetching: false,
      };

    case REQUEST_CITIES:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_CITIES:
      return {
        ...state,
        isFetching: false,
        cities: action.data,
      };

    case NOT_RECEIVE_CITIES:
      return {
        ...state,
        isFetching: false,
        errors: [...state.errors, action.error],
      };

    case REQUEST_COLLECTIONS:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_COLLECTIONS:
      return {
        ...state,
        isFetching: false,
        collections: action.data,
      };

    case NOT_RECEIVE_COLLECTIONS:
      return {
        ...state,
        isFetching: false,
        errors: [...state.errors, action.error],
      };

    case REQUEST_LANGUAGES:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_LANGUAGES:
      return {
        ...state,
        isFetching: false,
        languages: action.data,
      };

    case FETCH_WIDGET_STATS:
      return {
        ...state,
        widgetStats: {
          ...state.widgetStats,
          isFetchingWidgetStats: true,
        },
      };

    case FETCH_WIDGET_STATS_SUCCESS:
      return {
        ...state,
        widgetStats: {
          total: action.total,
          labels: action.labels,
          isFetchingWidgetStats: false,
        },
      };

    case FETCH_WIDGET_STATS_FAILURE:
      return {
        ...state,
        widgetStats: {
          ...state.widgetStats,
          isFetchingWidgetStats: false,
        },
        errors: [...state.errors, action.error],
      };

    case RESET_REFERAL_ERRORS:
      return {
        ...state,
        errors: [],
      };

    default:
      return state;
  }
}
