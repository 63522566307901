/**
 * Removes keys with values appeared to be to `false` after conversion to Boolean type (except number `0`)
 * @param {Object} query - query params
 * @returns {Object} - object without keys considered to be useless
 */
export default function normalizeQueryObject(query) {
  return Object.keys(query).reduce(
    (acc, key) =>
      query[key] || (typeof query[key] === 'number' && !isNaN(query[key]))
        ? { ...acc, [key]: query[key] }
        : acc,
    {}
  );
}
