import React, { useState, useEffect } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import StructuredPopup from '../Popup/StructuredPopup';
import Button from '../Button';
import linesToParagraphs from '../../functions/linesToParagraphs';
import './MovedUserPopup.css';

/**
 * Translations for popup with notifications of moving account to WeGoTrip
 * @type {Object}
 */
const movedUserText = {
  header: {
    id: 'MovedUser.notificationHeader',
    defaultMessage: 'Surprise Me becomes WeGoTrip',
  },
  text: {
    id: 'MovedUser.notificationText',
    defaultMessage: `Hi {name}! We are happy to announce that we are launching our new brand name – WeGoTrip!
      
      To continue editing tours, please activate your account using the link below. We already transferred all your tours and payments to WeGoTrip.
      
      In case of any problems, please contact us via {email}.`,
  },
};

/**
 * Notifies to user about their's account was moved to WeGoTrip
 * @param {Boolean} isOpen - regulates notification popup visibility
 */
const MovedUserPopup = ({ intl: { formatMessage }, user, isOpen }) => {
  const [flipped, setFlipped] = useState(false);

  useEffect(() => {
    setTimeout(() => setFlipped(true), 5000);
  });

  return (
    <StructuredPopup
      theme="card"
      isOpen={isOpen}
      closable={false}
      title={formatMessage(movedUserText.header)}
      className="MovedUserPopup"
    >
      <div className="MovedUserPopup__logo-container">
        <div
          className={classNames(
            'MovedUserPopup__flipper',
            flipped && '--flipped'
          )}
        >
          <div className="MovedUserPopup__logo MovedUserPopup__logo--front">
            <img src="https://static.tildacdn.com/tild6135-6431-4230-b539-623762383064/iconlogo-black.png" />
          </div>
          <div className="MovedUserPopup__logo MovedUserPopup__logo--back">
            <img src="https://wegotrip.com/wp-content/uploads/2019/12/logo-200x106.png" />
          </div>
        </div>
      </div>
      <div className="MovedUserPopup__text">
        <FormattedMessage
          {...movedUserText.text}
          values={{
            name: user.firstName || 'there',
            email: 'hello@srprsm.com',
          }}
        >
          {linesToParagraphs}
        </FormattedMessage>
        <p>
          <a
            className="link--blue"
            target="_blank"
            rel="noopener noreferrer"
            href="https://srprsm.com/surprise-me-becomes-wegotrip/"
          >
            <FormattedMessage
              id="MovedUser.learnMore"
              defaultMessage="Learn more"
            />
          </a>
        </p>
      </div>
      <a
        href={`https://wegotrip.com/en/login-magic/?next=/my&username=${user.email}`}
        className="MovedUserPopup__ok"
      >
        <Button theme="action">
          <FormattedMessage
            id="MovedUser.notificationOk"
            defaultMessage="Continue on WeGoTrip"
          />
        </Button>
      </a>
    </StructuredPopup>
  );
};

export default injectIntl(MovedUserPopup);
