import filterGeoEvents from './filterGeoEvents';

export default function getGeoTriggersCoords(events) {
  return filterGeoEvents(events).reduce((geoPoints, geoEvent) => {
    const {
      trigger: {
        geo: { lat, lon },
      },
    } = geoEvent;
    return [...geoPoints, [lat, lon]];
  }, []);
}
