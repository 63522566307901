/**
 * Names of the requisites of user for creating payout
 * Also keys of the `store::user.requisites` object came from server
 * @type {Array[String]}
 */
export const REQUISITE_NAMES = [
  'country',
  'city',
  'address',
  'index',
  'name',
  'taxNumber',
  'bankName',
  'bankSwift',
  'bankBic',
  'accountNumber',
];
