/* eslint-disable camelcase */
import {
  all,
  takeEvery,
  put,
  call,
  fork,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import * as Sentry from '@sentry/browser';
import * as types from '../constants/ActionTypes';
import { API_URL, DOMAIN_URL } from '../constants/branding';
import { deleteToken } from '../functions/tokenUtils';
import Api from '../functions/Api';
import dataURLtoFile from '../functions/dataURLtoFile';
import sendEvent from '../functions/analytics';
import { currentLanguage } from '../functions/urlLanguages';
import checkRequisites from '../functions/requisites/checkRequisites';
import { setCookie } from '../functions/manageCookie';
import {
  INSUFFICIENT_FUNDS,
  NO_REQUISITES,
  PAYOUT_SUCCESS_MESSAGE,
  PAYOUT_IN_PROCESS,
} from '../constants/NetworkErrors';
import { WEEK_IN_SEC } from '../constants';

// TODO: refactor
export const currencyMapping = {
  en: 'EUR',
  ru: 'RUB',
};
export function convertErrorsFromDjango(errors) {
  // Transform django errors
  // e.g.
  // {"errors": [{"message": {"phone": ["Please provide a valid phone number in international format, e.g. +33 123 456 7890"]}}]}
  // to formik simple obj errors
  // e.g.
  // {phone: "Error message"}
  return errors.reduce((obj, err) => {
    const { message } = err;
    Object.keys(message).forEach((key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = Array.isArray(message[key])
        ? message[key].join(',')
        : message[key];
    });
    return obj;
  }, {});
}

/**
 * Logins user with username and password
 * @param {Object} action
 * @param {String} action.payload.username
 * @param {String} action.payload.password
 */
export function* login({ payload }) {
  try {
    const data = yield call(Api.post, 'login/', { data: payload });

    if (data.token) {
      setCookie('token', data.token, {
        path: '/',
        domain: `.${window.location.host}`,
        expires: WEEK_IN_SEC,
      });
    }
  } catch (error) {
    yield put({
      type: types.LOGIN_FAILURE,
      errors: error.response,
      error,
    });
  }

  yield put({ type: types.REQUEST_USER });
}

/**
 * Sends magic link to the users email
 * @param {Object} action
 * @param {String} action.email - targeting user's email
 * @param {String} action.next - what page to open after successful login
 * @param {Number} action.favouriteId - id of the product user tried to like
 * @param {Boolean?} action.guide - if user is signing in as guide
 * @param {Boolean?} action.referal - if user is signing in as referral partner
 */
export function* loginEmail({ payload }) {
  try {
    yield call(Api.post, 'login-magic/', {
      data: {
        username: payload.email,
        next: payload.next,
        ...payload,
      },
    });
  } catch (error) {
    yield put({
      type: types.LOGIN_EMAIL_FAILURE,
      errors: error.response,
      error,
    });
  }
}

export function* updateProfile(action) {
  try {
    const { data: payload } = yield call(Api.put, 'profile/', {
      data: action.payload,
    });

    yield put({
      type: types.USER_UPDATE_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({ type: types.NETWORK_ERROR, error });
    const {
      response: { errors },
    } = error;
    let profileError = [];

    if (errors && errors.length) {
      profileError = Object.keys(errors[0].message).reduce(
        (arr, errorField) => {
          return [errorField, errors[0].message[errorField][0]];
        },
        []
      );
    }
    yield put({
      type: types.USER_UPDATE_FAILURE,
      error: profileError,
    });
  }
}

export function* selectSubcategory({ payload }) {
  try {
    const subcategoriesSelected = yield select((state) =>
      state.user.subcategoriesSelected.map((i) => i.id)
    );

    const { data } = yield call(Api.put, 'v2/authors/', {
      data: {
        subcategories: [...subcategoriesSelected, payload.id],
      },
    });

    if (data && data.userId) {
      yield put({
        type: types.SEND_PART_USER_INFO_SUCCESS,
        payload: data,
      });
      yield put({
        type: types.GET_SENDED_INFO,
      });
    }
  } catch (error) {
    yield put({
      type: types.SEND_USER_INFO_FAILURE,
      errors: error.response
        ? convertErrorsFromDjango(error.response.errors)
        : {},
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* deleteSubcategory(action) {
  try {
    const subcategoriesSelected = yield select((state) =>
      state.user.subcategoriesSelected
        .filter((i) => i.id !== action.id)
        .map((i) => i.id)
    );

    const { data } = yield call(Api.put, 'v2/authors/', {
      data: {
        subcategories: [...subcategoriesSelected],
      },
    });

    if (data && data.userId) {
      yield put({
        type: types.SEND_PART_USER_INFO_SUCCESS,
        payload: data,
      });
      yield put({
        type: types.GET_SENDED_INFO,
      });
    }
  } catch (error) {
    yield put({
      type: types.SEND_USER_INFO_FAILURE,
      errors: error.response
        ? convertErrorsFromDjango(error.response.errors)
        : {},
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* selectLanguage({ payload }) {
  try {
    const languagesSelected = yield select((state) =>
      state.user.languagesSelected.map((i) => i.id)
    );

    const { data } = yield call(Api.put, 'v2/authors/', {
      data: {
        languages: [...languagesSelected, payload.id],
      },
    });

    if (data && data.userId) {
      yield put({
        type: types.SEND_PART_USER_INFO_SUCCESS,
        payload: data,
      });
      yield put({
        type: types.GET_SENDED_INFO,
      });
    }
  } catch (error) {
    yield put({
      type: types.SEND_USER_INFO_FAILURE,
      errors: error.response
        ? convertErrorsFromDjango(error.response.errors)
        : {},
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* deleteSelectedLanguage(action) {
  try {
    const languagesSelected = yield select((state) =>
      state.user.languagesSelected
        .filter((i) => i.id !== action.id)
        .map((i) => i.id)
    );

    const { data } = yield call(Api.put, 'v2/authors/', {
      data: {
        languages: [...languagesSelected],
      },
    });

    if (data && data.userId) {
      yield put({
        type: types.SEND_PART_USER_INFO_SUCCESS,
        payload: data,
      });
      yield put({
        type: types.GET_SENDED_INFO,
      });
    }
  } catch (error) {
    yield put({
      type: types.SEND_USER_INFO_FAILURE,
      errors: error.response
        ? convertErrorsFromDjango(error.response.errors)
        : {},
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* addLocation({ payload }) {
  try {
    const newItem = { location: { coordinates: [] } };
    newItem.name = payload.description;
    newItem.location.coordinates = [payload.lat, payload.lng];

    const { data } = yield call(Api.post, 'v2/locations/', {
      data: {
        ...newItem,
        attach: true,
      },
    });

    if (data && data.id) {
      yield put({
        type: types.ADD_LOCATION_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    yield put({
      type: types.ADD_LOCATION_FAILURE,
      errors: error.response
        ? convertErrorsFromDjango(error.response.errors)
        : {},
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* deleteSelectedLocation(action) {
  try {
    const response = yield call(Api.delete, `v2/locations/${action.id}`);

    if (response) {
      yield put({
        type: types.DELETE_SELECTED_LOCATION_SUCCESS,
        id: action.id,
      });
      yield put({
        type: types.GET_SENDED_INFO,
      });
    }
  } catch (error) {
    yield put({
      type: types.SEND_USER_INFO_FAILURE,
      errors: error.response
        ? convertErrorsFromDjango(error.response.errors)
        : {},
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* addPlaceOfStay({ payload }) {
  try {
    const newItem = { location: { coordinates: [] } };
    newItem.name = payload.description;
    newItem.location.coordinates = [payload.lat, payload.lng];

    const { data } = yield call(Api.post, 'v2/locations/', {
      data: {
        ...newItem,
      },
    });

    if (data && data.id) {
      yield put({
        type: types.ADD_PLACE_OF_STAY_SUCCESS,
      });
      yield put({
        type: types.SEND_USER_INFO,
        payload: { placeId: data.id },
      });
    }
  } catch (error) {
    yield put({
      type: types.ADD_PLACE_OF_STAY_FAILURE,
      errors: error.response
        ? convertErrorsFromDjango(error.response.errors)
        : {},
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* sendUserInfo({ payload, redirect, locale }) {
  try {
    const { data } = yield call(Api.put, 'v2/authors/', {
      data: { ...payload },
    });

    if (redirect) {
      if (data && data.userId) {
        yield put({ type: types.SEND_USER_INFO_SUCCESS, data });
      }
      // Redirect to onboarding confirmation page
      yield put(replace(`${locale ? `/${locale}` : ''}/authors/onboarding/3`));
    } else if (data && data.userId) {
      yield put({
        type: types.SEND_PART_USER_INFO_SUCCESS,
        payload: data,
      });
      yield put({ type: types.GET_SENDED_INFO });
    }
  } catch (error) {
    yield put({
      type: types.SEND_USER_INFO_FAILURE,
      errors: error.response
        ? convertErrorsFromDjango(error.response.errors)
        : {},
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* receiveUserInfo(action) {
  try {
    const { data } = yield call(Api.get, 'v2/authors/', {
      locale: action.locale,
    });

    yield put({
      type: types.RECEIVE_USER_INFO_SUCCESS,
      payload: data,
    });
    yield put({
      type: types.GET_SENDED_INFO,
    });
  } catch (error) {
    yield put({
      type: types.RECEIVE_USER_INFO_FAILURE,
      errors: error.response
        ? convertErrorsFromDjango(error.response.errors)
        : {},
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* signup({
  payload: {
    email,
    phone,
    first_name,
    last_name,
    password,
    locale,
    ...payloadData
  },
}) {
  try {
    const { data } = yield call(Api.post, 'signup/authors/', {
      data: {
        email,
        phone,
        first_name,
        last_name,
        password,
      },
      locale,
    });

    if (data?.id) {
      if (payloadData.locations) {
        // Send selected locations and store locations IDs from server
        yield all(
          payloadData.locations.map((location) =>
            call(addLocation, { payload: location })
          )
        );
      }

      if (payloadData.languages || payloadData.locations) {
        // Get locations IDs from store
        const { locationsSelected } = yield select(({ user }) => user);

        const userInfo = {
          languages: payloadData.languages.map(({ id }) => id),
          locations: payloadData.locations.map(({ description }) => {
            return locationsSelected.find(({ name }) => name === description)
              ?.id;
          }),
        };

        // Send selected locations and cities IDs
        yield call(sendUserInfo, {
          payload: {
            ...userInfo,
            userId: data.id,
          },
          redirect: true,
          locale,
        });

        // Send magic link to email
        yield call(loginEmail, { payload: { email, next: '/my' } });
      }

      // Count sign up success only if `id` has presented in the response
      yield put({
        type: types.SIGNUP_SUCCESS,
        data,
      });
    }
  } catch (error) {
    yield put({
      type: types.SIGNUP_FAILURE,
      errors: error.response
        ? convertErrorsFromDjango(error.response.errors)
        : {},
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* requestCategories(action) {
  try {
    const { data } = yield call(Api.get, 'v2/categories/', {
      locale: action.locale,
    });

    yield put({
      type: types.RECEIVE_CATEGORIES,
      data,
    });
  } catch (error) {
    yield put({
      type: types.NOT_RECEIVE_CATEGORIES,
      errors: error.response
        ? convertErrorsFromDjango(error.response.errors)
        : {},
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* requestSelectedLocations(action) {
  try {
    const { data } = yield call(Api.get, 'v2/locations/', {
      locale: action.locale,
    });

    yield put({
      type: types.RECEIVE_SELECTED_LOCATIONS,
      data,
    });
  } catch (error) {
    yield put({
      type: types.NOT_RECEIVE_SELECTED_LOCATIONS,
      errors: error.response
        ? convertErrorsFromDjango(error.response.errors)
        : {},
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* changeLanguage(action) {
  try {
    const {
      data: { locale },
    } = yield call(Api.post, 'setlang/', {
      data: {
        language: action.locale,
      },
    });

    yield put({
      type: types.CHANGE_LANGUAGE_SUCCESS,
      locale,
    });
  } catch (error) {
    yield put({
      type: types.CHANGE_LANGUAGE_FAILURE,
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* fetchDefaultCountry(action) {
  try {
    // const { data } = yield call(Api.get, 'countries/ip/');
    const data = { name: 'Russia', code: 'RU' };

    yield put({
      type: types.FETCH_DEFAULT_COUNTRY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: types.FETCH_DEFAULT_COUNTRY_FAILURE,
    });

    if (!action.silent) {
      yield put({ type: types.NETWORK_ERROR, error });
    }
  }
}

export function* requestUser(action) {
  try {
    yield put({
      type: types.FETCH_DEFAULT_COUNTRY,
    });

    const {
      data,
      data: { isSales, isPartner },
    } = yield call(Api.get, 'profile/');

    /**
     * Analytics
     */
    sendEvent('identify', data.id, {
      phone: data.phone,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      username: data.nickname,
    });

    yield put({
      type: types.RECEIVE_USER,
      payload: { ...data, currencyCode: currencyMapping[data.locale] },
    });

    yield put({
      type: types.RECEIVE_USER_INFO,
    });

    yield put({
      type: types.FETCH_LANGUAGES,
    });

    if (data.requisites === null)
      yield put({
        type: types.ADD_REQUISITES,
        data: { user: data.id },
      });

    let locale = yield select((state) => state.user.locale);

    while (!locale) {
      locale = yield select((state) => state.user.locale);
    }

    yield put({
      type: types.FETCH_CURRENCIES,
      locale,
    });

    if (isSales) {
      yield put({
        type: types.REQUEST_REFERAL_DATA,
      });
      yield put({
        type: types.REQUEST_REFERAL_SALES,
      });
      yield put({
        type: types.REQUEST_CITIES,
      });
    }

    if (isPartner) {
      yield put({
        type: types.FETCH_AFFILIATE_PARTNER,
      });
      yield put({
        type: types.FETCH_PARTNER_STATS,
      });
      yield put({
        type: types.FETCH_PARTNER_DATA,
      });
    }

    yield put({
      type: types.REQUEST_QUESTS_STATS,
      page: 1,
    });

    yield put({
      type: types.FETCH_PAYMENTS_STATS,
    });

    yield put({
      type: types.FETCH_PAYOUTS,
    });

    yield put({
      type: types.FETCH_TOTAL_INCOME,
    });
  } catch (error) {
    const { status = 0 } = error;
    if (status === 401 || status === 500) {
      const locale = currentLanguage(document.referrer);
      const origin = window.location.origin || DOMAIN_URL;
      deleteToken();
      yield put({
        type: types.LOGOUT,
        returnUrl: action.nextLink
          ? `${origin}/${locale}/login?next=${action.nextLink}`
          : action.returnUrl,
        email: '',
        isHardRedirect: action.nextLink ? true : action.isHardRedirect,
      });
    }
    if (!action.silent) {
      yield put({ type: types.NETWORK_ERROR, error });
    }
  }
}

export function* handleLogout({ returnUrl, isHardRedirect }) {
  if (isHardRedirect) {
    if (returnUrl) window.location.href = returnUrl;
    else window.location.href = `${API_URL}/logout/`;
  } else {
    yield put(push(returnUrl));
  }
}

export function* saveLoginProvider(action) {
  yield sessionStorage.setItem('loginProvider', action.provider);
}

export function* uploadAvatar(payload) {
  const userNickName = yield select((state) => state.user.nickname);
  const { image } = payload;
  const file = dataURLtoFile(image, `${userNickName}-avatar.png`);

  try {
    const formData = new FormData();
    formData.append('avatar', file);
    const {
      data: { avatar },
    } = yield call(Api.post, 'avatar/', {
      data: formData,
    });

    if (avatar) {
      yield put({
        type: types.UPLOAD_AVATAR_SUCCESS,
        avatar,
      });
    }
  } catch (error) {
    yield put({
      type: types.UPLOAD_AVATAR_FAILURE,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* redirectAuthors({ payload }) {
  const { phone, isPartner } = payload;

  const pathname = yield select((state) => state.router.location.pathname);
  const partner = yield select((state) => state.partner);
  const { data: { bio = '' } = {} } = partner;

  if (pathname.indexOf('authors/onboarding') === -1) {
    return;
  }

  if (isPartner && phone && bio) {
    const language =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage;
    const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
    const match = window.location.pathname.match(/^\/(en|ru)/);
    const locale = match ? match[1] : languageWithoutRegionCode;

    yield put(push(`/${locale}/authors/onboarding/done`));
  }
}

export function* handleAuth({ payload }) {
  const { id, email } = payload;

  // Set user scope
  yield call(() => {
    Sentry.withScope((scope) => {
      scope.setUser({ email, id });
    });
  });

  // Add bradcrumb with auth
  yield call(Sentry.addBreadcrumb, {
    category: 'auth',
    message: `Authenticated user (${email}, ${id})`,
    level: 'info',
  });
}

export function* unhijack() {
  try {
    yield call(deleteToken);
    yield call(Api.post, '/logas/release-hijack/', { notApiUrl: true });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
  // yield call(requestUser, { silent: false });
  window.location.href = '/admin/';
}

export function* addRequisites({ data }) {
  try {
    const { data: payload } = yield call(Api.post, `v2/requisite/`, {
      data,
    });
    yield put({
      type: types.ADD_REQUISITES_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({
      type: types.ADD_REQUISITES_FAILURE,
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* updateRequisites({ data }) {
  try {
    const requisiteId = yield select((state) => state.user.requisites.id);
    const { data: payload } = yield call(
      Api.put,
      `v2/requisite/${requisiteId}/`,
      {
        data,
      }
    );
    yield put({
      type: types.UPDATE_REQUISITES_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({
      type: types.UPDATE_REQUISITES_FAILURE,
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

const isFirstNotPayoutRequest =
  localStorage.getItem('isFirstPayoutRequest') === 'false';
export function* requestPayout() {
  try {
    const userId = yield select((state) => state.user.id);
    const payouts = yield select((state) => state.stats.payouts.results);
    const summary = yield select((state) => state.partner.summary);

    // Redirect to requisites page if they were not provided
    const requisites = yield select((state) => state.user.requisites);
    if (!checkRequisites(requisites)) {
      yield put({ type: types.CUSTOM_ERROR, error: NO_REQUISITES });
      yield put(push('/settings/requisites?fromRedirectPayouts=true'));
      return;
    }

    if (summary && (summary[2][1] === 0 || summary[2][1] === '0.00')) {
      yield put({ type: types.CUSTOM_ERROR, error: INSUFFICIENT_FUNDS });
      return;
    }

    if (payouts && payouts[0] && payouts[0].status === 'processing') {
      yield put({ type: types.CUSTOM_ERROR, error: PAYOUT_IN_PROCESS });
      localStorage.setItem('isFirstPayoutRequest', 'false');

      return;
    }

    const { data } = yield call(Api.post, `v2/payouts/`, {
      data: { user: userId },
    });

    if (data) {
      if (isFirstNotPayoutRequest === true) {
        yield put({
          type: types.CUSTOM_ERROR,
          error: PAYOUT_SUCCESS_MESSAGE,
        });
      }
      yield put({
        type: types.REQUEST_PAYOUT_SUCCESS,
      });
    }
  } catch (error) {
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export default function* watchUsers() {
  yield fork(() => takeEvery(types.USER_UPDATE, updateProfile));
  yield takeEvery(types.FETCH_DEFAULT_COUNTRY, fetchDefaultCountry);
  yield takeEvery(types.CHANGE_LANGUAGE, changeLanguage);
  yield takeEvery(types.REQUEST_USER, requestUser);
  yield takeEvery(types.RECEIVE_USER, handleAuth);
  yield takeEvery(types.RECEIVE_USER, redirectAuthors);
  yield takeEvery(types.UPLOAD_AVATAR, uploadAvatar);
  yield takeLatest(types.LOGIN, login);
  yield takeLatest(types.LOGIN_EMAIL, loginEmail);
  yield takeEvery(types.LOGOUT, handleLogout);
  yield takeEvery(types.SIGNUP, signup);
  yield takeEvery(
    [types.RECEIVE_USER_INFO, types.CHANGE_LANGUAGE_SUCCESS],
    receiveUserInfo
  );
  yield takeEvery(types.SEND_USER_INFO, sendUserInfo);
  yield takeEvery(types.ADD_LOCATION, addLocation);
  yield takeEvery(types.DELETE_SELECTED_LOCATION, deleteSelectedLocation);
  yield takeEvery(types.ADD_PLACE_OF_STAY, addPlaceOfStay);
  yield takeEvery(types.REQUEST_CATEGORIES, requestCategories);
  yield takeEvery(types.REQUEST_SELECTED_LOCATIONS, requestSelectedLocations);
  yield takeEvery(types.SELECT_SUBCATEGORY, selectSubcategory);
  yield takeEvery(types.DELETE_SELECTED_SUBCATEGORY, deleteSubcategory);
  yield takeEvery(types.SELECT_LANGUAGE, selectLanguage);
  yield takeEvery(types.DELETE_SELECTED_LANGUAGE, deleteSelectedLanguage);
  yield takeEvery(types.USER_CLICK_LOGIN, saveLoginProvider);
  yield takeEvery(types.UNHIJACK, unhijack);
  yield takeEvery(types.ADD_REQUISITES, addRequisites);
  yield takeEvery(types.UPDATE_REQUISITES, updateRequisites);
  yield takeEvery(types.REQUEST_PAYOUT, requestPayout);
}
