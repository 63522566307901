import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HELP_CENTER_URL } from '../../../constants/branding';
import { EVENT_CONTENT_STICKER } from '../../../constants/upload/contentTypes';
import EventHeader from '../../Event/EventHeader';
import EventAlert from '../../Event/EventAlert';
import Event from '../../Event';
import withContentLoader from '../../Dropzone/withContentLoader';
import DropzoneContent from '../../Dropzone/DropzoneContent';
import withPictureEditor from '../../Dropzone/withPictureEditor';
import PictureDropzonePure from '../../Dropzone/PictureDropzone';
import DefaultPictureEditor from '../../PictureEditor/DefaultPictureEditor';

/**
 * `DropzoneContent` with loader
 * @type {React::Component}
 */
const DropzonePicturesContent = withContentLoader(DropzoneContent);

/**
 * `PictureDropzone` with `PictureEditor`
 * @type {React::Component}
 */
const PictureDropzone = withPictureEditor(
  PictureDropzonePure,
  DefaultPictureEditor
);

export default function EventControlsStickers({
  eventId,
  questId,
  locale,
  uploads,
  stickers = [],
  onAdded,
  onRemoved,
  ...pictureDropzoneProps
}) {
  const params = { questId, eventId };
  return (
    <Event>
      <EventHeader variant="sub" margin={false}>
        <FormattedMessage
          id="EventControlsStickers.title"
          defaultMessage="Photo-hints - maximum 5"
        />
      </EventHeader>
      <EventAlert>
        <a
          href={`${HELP_CENTER_URL}${locale}/photo/`}
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage
            id="EventControlsStickers.info"
            defaultMessage="Which photos could be added?"
          />
        </a>
      </EventAlert>
      <PictureDropzone
        {...pictureDropzoneProps}
        contentType={EVENT_CONTENT_STICKER}
        params={params}
        uploads={uploads}
        images={stickers}
        ContentPreviewComponent={DropzonePicturesContent}
        onAdded={(metas) => onAdded(params, metas)}
        onRemoved={onRemoved}
      />
    </Event>
  );
}
