import React, { Component } from 'react';
import classNames from 'classnames';
import './Tumbler.css';

class Tumbler extends Component {
  getClassName(props) {
    let className = 'Tumbler';
    if (props.className) className += ` ${props.className}`;
    return className;
  }

  filterProps(props) {
    const filtered = { ...props };
    return filtered;
  }

  render() {
    const { name, value, checked, title } = this.props;
    return (
      <label className="Tumbler">
        <input
          className={classNames(
            'Tumbler__input',
            checked && 'Tumbler__input--checked'
          )}
          type="radio"
          name={name}
          value={value}
          {...this.filterProps(this.props)}
        />
        <span className="Tumbler__label">{title}</span>
      </label>
    );
  }
}

export default Tumbler;
