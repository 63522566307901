import React from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import GapItem from '../../Gap/GapItem';

/**
 * JSS styles for `DropzoneContent` component
 * @type {Object}
 */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  colRoot: {
    flexDirection: 'row',
  },
  imgOverlay: {
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    textAlign: 'center',
    fontSize: '9px',
    paddingBottom: '2px !important',
    paddingRight: '5px !important',
    fontWeight: '600',
  },
  index: {
    right: '10px',
    top: '10px',
    width: '25px',
    borderRadius: '50%',
    lineHeight: '25px',
  },
  tag: {
    right: 0,
    borderRadius: '5px',
    padding: '5px 10px',
  },
  imgContainer: {
    display: 'flex',
    position: 'relative',
    width: '48px',
    minHeight: '48px',
    maxHeight: '48px',
    borderRadius: '4px',
    overflow: 'hidden',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      minHeight: '80px',
      maxHeight: '178px',
    },
    '& > *': {
      pointerEvents: 'none',
    },
  },
  img: {
    width: '100%',
    objectFit: 'cover',
  },
  imgTagged: {
    border: '4px solid',
    borderColor: theme.palette.primary.main,
  },
}));

/**
 * `withContentBelow(Dropzone)` component item displayed below it
 * @param {Object} $
 * @param {String?} $.className - additional CSS class
 * @param {String?} $.variant - variant for displaying component (`"row"` or `"col"`)
 * @param {String?} $.src - image preview URL
 * @param {String?} $.tag - text will be displayed above image on the right corner in tag frame
 * @param {Object?} $.classes - CSS class names for children of the element (`"img"`)
 * @param {Number?} $.index - index of the image, will be displayed above image on the right corner
 * @param {React::JSX?} $.imageGap - will be displayed above image (mist be with `position: absolute` CSS code)
 * @param {React::JSX?} $.children - will be displayed near preview image (position depends on the `variant` prop value)
 * @param {Function} $.onPreviewClicked - function from usePhotoFull hook, that sets photo src to modal
 * @param {Object} $.gapProps - will be passed to `GapItem` component
 */
export default function DropzoneContent({
  className,
  classes = {},
  variant = 'col',
  tag,
  index,
  src,
  imageGap = null,
  children,
  onPreviewClicked,
  InputProps, // fix: not to pass as `gapProps` to avoid errors in console
  onChanged, // fix: not to pass as `gapProps` to avoid errors in console
  ...gapProps
}) {
  const styles = useStyles();

  return (
    <GapItem
      className={classNames(className, styles.root, styles[`${variant}Root`])}
      {...gapProps}
    >
      <div
        className={classNames(styles.imgContainer, classes.img)}
        onClick={() => onPreviewClicked(src)}
      >
        <img
          className={classNames(styles.img, tag && styles.imgTagged)}
          alt=""
          src={src}
        />
        {imageGap}
        {[
          { data: index, type: 'number', style: 'index' },
          { data: tag, type: 'string', style: 'tag' },
        ].map(({ data, type, style }) =>
          typeof data === type ? (
            <Typography
              key={type}
              className={classNames(styles.imgOverlay, styles[style])}
              variant="body2"
            >
              {data}
            </Typography>
          ) : null
        )}
      </div>
      {children}
    </GapItem>
  );
}
