import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './MediaPlayer.css';

const defaultState = {
  loaded: false,
  isPlaying: false,
  duration: 0,
  currentTime: 0,
  progress: 0,
  error: null,
};

const funcMock = () => {};

const getProgress = (duration, currentTime) => {
  if (!duration) {
    return 0;
  }
  return (currentTime / duration) * 100;
};

class MediaPlayer extends Component {
  state = {
    ...defaultState,
  };

  componentDidMount() {
    this.initMedia(true); // Maybe in setTimeout
  }

  componentWillReceiveProps(nextProps) {
    const { src } = this.props;
    const { src: nextSrc } = nextProps;

    if (src === nextSrc) {
      return;
    }

    // TODO: fix bug with infinite loading

    this.initMedia(true); // Maybe in setTimeout
  }

  componentWillUnmount() {
    // Remove event listeners
    this._media.removeEventListener('canplaythrough', this.setDuration);

    this._media.removeEventListener('timeupdate', this.updateTime);

    this._source.removeEventListener('error', this.handleError, false);
  }

  setDuration = () => {
    this.setState((prev) => ({
      ...prev,
      loaded: true,
      duration: this._media.duration,
    }));
  };

  /**
   * Sets current playing time
   * @param {Number} time
   */
  setTime = (time) => {
    const {
      _media,
      _media: { duration },
    } = this;

    if (_media) {
      time = time < 0 ? 0 : time;
      time = time > duration ? duration : time;
      _media.currentTime = time;
    }
  };

  updateTime = () => {
    if (this._media.currentTime === this.state.duration) {
      this.setState((prev) => ({
        ...prev,
        isPlaying: false,
      }));
    }
    this.setState((prev) => ({
      ...prev,
      currentTime: this._media.currentTime,
      progress: getProgress(prev.duration, this._media.currentTime),
    }));
  };

  handleError = (error) => {
    this.setState((prev) => ({
      ...prev,
      error: true,
    }));
  };

  initMedia(forceLoad = false) {
    if (!this._media) {
      return;
    }

    // Clear duration and currentTime
    this.setState({ ...defaultState });

    // When loaded, set duration to state
    this._media.addEventListener('canplaythrough', this.setDuration, false);

    // When playing, update current position, if meets end turn off player
    this._media.addEventListener('timeupdate', this.updateTime, false);

    if (!this._source) {
      return;
    }

    // Register error
    // TODO: show an error badge
    this._source.addEventListener('error', this.handleError, false);

    if (forceLoad) {
      this.loadMedia();
    }
  }

  loadMedia() {
    if (!this._media) {
      return;
    }
    this._media.load();
  }

  togglePlaying = () => {
    const { isPlaying } = this.state;
    if (isPlaying) {
      this._media.pause();
    } else {
      this._media.play();
    }
    this.setState((prev) => ({
      ...prev,
      isPlaying: !prev.isPlaying,
    }));
  };

  getMeta() {
    const { src } = this.props;
    const isAudio = src.match(/\.(mp3|m4a)$/);
    if (isAudio) {
      return {
        tagName: 'audio',
        type: 'audio/mpeg',
      };
    }
    const videoMatch = src.match(/\.(mp4|MOV)$/);
    if (videoMatch) {
      const mime = videoMatch[1] === 'MOV' ? 'quicktime' : 'mp4';
      return {
        tagName: 'video',
        type: `video/${mime}`,
      };
    }

    // TODO: better fallback
    return {
      tagName: 'video',
      type: '',
    };
  }

  render() {
    const { src, render, className, ...other } = this.props;
    const { loaded, isPlaying, error, progress, duration } = this.state;
    let status;
    if (!loaded) {
      status = 'loading';
    } else {
      status = error ? 'error' : isPlaying ? 'pause' : 'play';
    }
    const handleToggle = error ? funcMock : this.togglePlaying;
    const setTime = error ? funcMock : this.setTime;
    const meta = this.getMeta();
    const Tag = meta.tagName;
    const info = {
      time: (this._media && this._media.currentTime) || 0,
      duration: duration || 0,
    };

    return (
      <div className={classNames('MediaPlayer__wrapper', className)} {...other}>
        <Tag ref={(a) => (this._media = a)} className="MediaPlayer__media">
          <source ref={(s) => (this._source = s)} type={meta.type} src={src} />
        </Tag>
        {render({
          status,
          progress,
          controls: { togglePlaying: handleToggle, setTime },
          info,
        })}
      </div>
    );
  }
}

MediaPlayer.propTypes = {
  src: PropTypes.string.isRequired,
};

export default MediaPlayer;
