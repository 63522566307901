/**
 * Amount of time in ms to show error indropzone
 * @type {Number}
 */
export const ERROR_SHOW_TIME = 3000;

/**
 * Error id of the dropzone
 * @typedef {Number} DropzoneError
 */

/**
 * File with wrong type appeared
 * @type {DropzoneError}
 */
export const ERR_TYPE = 1;

/**
 * Amount of files with given type exceeded
 * @type {DropzoneError}
 */
export const ERR_OVERFLOW = 0;

/**
 * No errors occured
 * @type {DropzoneError}
 */
export const NO_ERR = -1;
