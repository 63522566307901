import { defineMessages } from 'react-intl';

/**
 * Messages describing product statuses
 * @type {Object}
 */
export default defineMessages({
  draft: {
    id: 'ProfileTours.draft',
    defaultMessage: 'Draft',
  },
  waitingForModeration: {
    id: 'ProfileTours.waitingForModeration',
    defaultMessage: 'Waiting for moderation',
  },
  onModeration: {
    id: 'ProfileTours.onModeration',
    defaultMessage: 'On moderation',
  },
  changesAreNeeded: {
    id: 'ProfileTours.changesAreNeeded',
    defaultMessage: 'Changes are needed',
  },
  approved: {
    id: 'ProfileTours.published',
    defaultMessage: 'Published',
  },
  delisted: {
    id: 'ProfileTours.delisted',
    defaultMessage: 'Withdrawn from the sale',
  },
  unknown: {
    id: 'ProfileTours.unknown',
    defaultMessage: 'Unknown step',
  },
});
