import editEventContent from '../event/editEventContent';

/**
 * Converts quest props to use them in Apps
 * - copies `prevButton` prop to `nextButton` of the previous event in the given quest
 * - copies `direction` content to the previous event
 * !!IMPORTANT: modifies original `quest` object
 * @param {Object} quest - quest to process events from
 * @returns {Object} - processed quest
 */
export default function convertEventsNext(quest) {
  const eventsAmount = quest.events ? quest.events.length : 0;
  for (let i = 1; i < eventsAmount; i++) {
    const event = quest.events[i];
    const eventContent = quest.events[i].content;
    // Moving next buttons to the previous step
    quest.events[i - 1].nextButton = event.prevButton;
    delete event.prevButton;
    // Moving `directions` and `stickers` content to the previous event
    ['directions', 'sticker'].forEach((contentType) => {
      const dirI = eventContent.findIndex((c) => c.contentType === contentType);
      quest.events[i - 1].content = editEventContent(
        quest.events[i - 1].content,
        contentType,
        () => (dirI > -1 ? eventContent[dirI] : null)
      ).filter((c) => !!c);
    });

    // Removing already copied stuff from step before last
    if (i >= eventsAmount - 2) {
      // Removing `directions` event content
      ['directions', 'sticker'].forEach((contentType) => {
        event.content = eventContent.filter(
          (c) => c.contentType !== contentType
        );
      });
      // Removing `nextButton` prop
      delete event.nextButton;
    }
  }

  return quest;
}
