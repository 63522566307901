import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import parseQuery from './parseQuery';
import formQuery from './formQuery';

/**
 * Adds ability to listen query param changes and syncing it with specified state
 * !IMPORTANT: Currently rewrites URL only with specified pathname and query
 * @param {Object} $
 * @param {String} $.key - param name in URL query string
 * @param {String} $.urlTemplate - URL to which updated query will be appended
 * @param {String} $.stateValue - previous value stored in the component's state
 * @param {Function} $.onParamChanged - triggers with new URL query param value, when it changes
 */
export default function useUrlApi({
  key,
  urlTemplate,
  stateValue,
  onParamChanged,
}) {
  const history = useHistory();
  const location = useLocation();

  /**
   * Current URL query
   * @type {Object}
   */
  const { [key]: paramValue, ...query } = parseQuery(location.search);

  /**
   * Updating editing point in state when URL query param `${key}` was changed (not by code of target component)
   */
  useEffect(() => {
    if (paramValue && paramValue !== stateValue) {
      onParamChanged(paramValue);
    }
  }, [paramValue]);

  /**
   * Updating URL with query containing `${key}` param with route point guid
   */
  useEffect(() => {
    if (paramValue !== stateValue) {
      history.push(
        `${urlTemplate}${formQuery({
          ...query,
          [key]: stateValue,
        })}`
      );
    }
  }, [stateValue]);
}
