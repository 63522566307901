/* eslint-disable no-restricted-globals */
/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import './TourCheck.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  makeStyles,
  Paper,
  Container,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import CreateIcon from '@material-ui/icons/Create';
import { NavLink } from 'react-router-dom';
import * as publishingActions from '../../../actions/publishing';
import Map from '../../Map';
import Examples from '../../Examples';
import TourContentFooterButtons from '../../TourContentFooter/TourContentFooterButtons';
import { DEFAULT_CENTER } from '../../../constants/GeoParams';
import { MAX_SELECTED_SUBCATEGORIES } from '../../../constants/limits';
import { HELP_CENTER_URL } from '../../../constants/branding';
import { getCurrentLanguage } from '../../../functions/languages/getCurrentLanguage';
import { ConvertMinutesToShortFormat } from '../../../functions/dateUtils';
import { DEFAULT_PRICING_CATEGORY_ID } from '../../../constants/prices';
import {
  getAllPricingCategories,
  getFinalCategories,
} from '../../../functions/prices';

/**
 * JSS styles for `TourCheck` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 24,
    marginTop: 40,
    marginBottom: -15,
    '& .Map:before': {
      background: 'none',
    },
    '& .Map': {
      marginBottom: 0,
    },
  },
  container: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
  },
  createIcon: {
    float: 'right',
    color: (props) => (props.error ? '#F44336' : '#BDBDBD'),
    marginTop: (props) => props.marginTop,
  },
  title: {
    marginTop: 40,
    marginBottom: 60,
  },
  description: {
    whiteSpace: 'pre-wrap',
  },
  checkboxContainer: {
    marginTop: 45,
    marginBottom: 20,
  },
  checkboxContainerMargin: {
    marginTop: 45,
    marginBottom: -20,
  },
  cardTitle: {
    marginBottom: 16,
  },
  cardTitleSights: {
    marginBottom: 5,
  },
  notFilled: {
    color: '#F44336',
    marginBottom: -20,
  },
  coverImg: {
    width: '100%',
    borderRadius: 4,
    border: '4px solid',
    borderColor: theme.palette.primary.main,
  },
  image: {
    width: '100%',
  },
  coverTitle: {
    position: 'absolute',
    right: 4,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    textAlign: 'center',
    padding: '5px 10px',
    color: 'white',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: 7,
    },
  },
  relative: {
    position: 'relative',
  },
  attractionLabel: {
    fontSize: 20,
    fontWeight: 500,
  },
  attractionSubtitleLast: {
    marginBottom: 1,
  },
  fee: {
    float: 'right',
    marginTop: -13,
  },
  priceBlockLabel: {
    display: 'inline-block',
    width: 250,
  },
  priceBlockPrice: {
    display: 'inline-block',
  },
}));

/**
 * Messages for `TourCheck` component
 * @type {Object}
 */
const messages = defineMessages({
  title: {
    id: 'StudioCreateProduct.TourCheckTitle',
    defaultMessage: 'title',
  },
  description: {
    id: 'StudioCreateProduct.TourCheckDescription2',
    defaultMessage: 'description',
  },
  highlights: {
    id: 'StudioCreateProduct.TourCheckHighlights',
    defaultMessage: 'highlights',
  },
  address: {
    id: 'StudioCreateProduct.TourCheckAddress',
    defaultMessage: 'tour start address',
  },
  startLocation: {
    id: 'StudioCreateProduct.TourCheckStartLocation',
    defaultMessage: 'tour start location',
  },
  finishAddress: {
    id: 'StudioCreateProduct.TourCheckFinishAddress',
    defaultMessage: 'tour end address',
  },
  finishLocation: {
    id: 'StudioCreateProduct.TourCheckFinishAddressLocation',
    defaultMessage: 'tour end location',
  },
  attractions: {
    id: 'StudioCreateProduct.TourCheckAttractions',
    defaultMessage: 'sights',
  },
  distance: {
    id: 'StudioCreateProduct.TourCheckDistance',
    defaultMessage: 'route length',
  },
  duration: {
    id: 'StudioCreateProduct.TourCheckDuration2',
    defaultMessage: 'duration',
  },
  inclusions: {
    id: 'StudioCreateProduct.TourCheckInclusions',
    defaultMessage: 'what is included in the tour',
  },
  exclusions: {
    id: 'StudioCreateProduct.TourCheckExclusions',
    defaultMessage: 'what is not included in the tour',
  },
  images: {
    id: 'StudioCreateProduct.TourCheckImages',
    defaultMessage: 'photos',
  },
  locale: {
    id: 'StudioCreateProduct.TourCheckLocale',
    defaultMessage: 'language',
  },
  subcategories: {
    id: 'StudioCreateProduct.TourCheckSubcategories',
    defaultMessage: 'tour categories',
  },
  price: {
    id: 'StudioCreateProduct.TourCheckPrice',
    defaultMessage: 'price',
  },
  events: {
    id: 'StudioCreateProduct.TourCheckEvents',
    defaultMessage:
      '{number, plural, one {{number} step in tour content} few {{number} steps in tour content} other {{number} steps in tour content}}',
  },
});

const notFilled = (
  <Typography color="error" variant="body1">
    <FormattedMessage
      id="StudioCreateProduct.TourCheckNotFilled"
      defaultMessage="Not filled"
    />
  </Typography>
);

const isTourWasSendedToModeration = (status) => {
  if (status === 'draft' || status === 'changes_are_needed') {
    return false;
  }
  return true;
};

const TourCheck = ({
  currentQuest,
  intl,
  publishing,
  publishing: {
    receivedPublishData,
    receivedPublishData: {
      title,
      description,
      images,
      address,
      locationGeo,
      finishAddress,
      finishLocationGeo,
      startLocation,
      finishLocation,
      distance,
      duration,
      durationMax,
      locale,
      defaultCurrency,
      status,
      subcategories,
    },
    attractions,
    priceLevels,
  },
  // eslint-disable-next-line no-shadow
  publishingActions,
  languages,
  checkFullness,
  currentQuestId,
  user,
  prices,
  finalPrices,
  uniquePricingCategoriesLabels,
}) => {
  /**
   * JSS styles for `TourTitle` element
   * @type {React::Hook}
   */
  const classes = useStyles();

  /**
   * State for status of all confirmations
   * @type {Object}
   */
  const [confirm, setConfirm] = useState({
    first: false,
    second: false,
    third: false,
  });

  const finalCategoriesLabels = getFinalCategories(
    uniquePricingCategoriesLabels
  );

  /**
   * Checks if tour have only one default priceLevel (Tour price without pricing categories)
   * @returns {Boolean} - `true` if tour with only one default priceLevel
   */
  const isTourWithOnlyDefaultPriceLevel = () => {
    return (
      priceLevels.length &&
      priceLevels.find(
        (pl) => pl.pricingCategoryId === DEFAULT_PRICING_CATEGORY_ID
      ) &&
      getAllPricingCategories(attractions).length === 0
    );
  };
  /**
   * Switch checks of confirmations
   * @param {String} key - confirm object key
   */
  const handleConfirm = (key) => {
    setConfirm({ ...confirm, [key]: !confirm[key] });
  };

  /**
   * Set all confirmations to True if product was sended to moderation
   */
  useEffect(() => {
    status !== 'draft' &&
      setConfirm({ first: true, second: true, third: true });
  }, [status]);

  /**
   * Gets chosen start/finish coordinates or default coordinates
   * @param {String} value - attribute name for geo coordinates
   * @returns {Object} - coords, e.g. { lon: 37.617592, lat: 55.751322 }
   */
  const getCoords = (value) => {
    const { [value]: geo } = receivedPublishData;
    if (geo !== null)
      return { lon: geo.coordinates[0], lat: geo.coordinates[1] };
    return DEFAULT_CENTER;
  };

  /**
   * Render detail menu
   * @param {String} label - detail name
   * @param {Array|String} values - detail values
   * @param {String} link - url path to edit details fields
   * @param {Boolean} withBorder - is bottom borderline show
   */
  const renderDetail = (label, values, link, withBorder = true) => {
    return (
      <div className={withBorder ? 'TourCheck__borderline' : ''}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="body1">{label}</Typography>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <LinkIcon
              link={link}
              error={
                Array.isArray(values)
                  ? values.length < MAX_SELECTED_SUBCATEGORIES
                  : !values
              }
            />
            {Array.isArray(values) ? (
              <div>
                {values.length
                  ? user.categories
                      .filter((x) => x.subcategories.length !== 0)
                      .map((i) =>
                        i.subcategories.map(
                          (s) =>
                            values.some((value) => value === s.id) && (
                              <Typography key={s.id} variant="body1">
                                {s.title}
                              </Typography>
                            )
                        )
                      )
                  : notFilled}
              </div>
            ) : values ? (
              <Typography variant="body1">{values}</Typography>
            ) : (
              notFilled
            )}
          </Grid>
        </Grid>
      </div>
    );
  };

  const standard = (
    <a
      target="_blank"
      href={`${HELP_CENTER_URL}${user.locale}/requirements/`}
      rel="noopener noreferrer"
      style={{ textDecoration: 'underline' }}
    >
      <FormattedMessage
        id="StudioCreateProduct.qualityStandards"
        defaultMessage="quality standards"
      />
    </a>
  );

  /**
   * Send product to moderation
   * @param {Event} event - DOM event caused `onClick`
   */
  const handlePublish = (e) => {
    e.preventDefault();
    const { products } = currentQuest;

    publishingActions.publishProduct(products[0].id);
  };

  /**
   * Checking the filling of fields and events count, and returning what is not filled
   * @returns {String} - fields that are not filled
   */
  const handleTourCheckFullness = () => {
    const { highlights, inclusions, exclusions } = publishing;
    const entities = [];

    if (confirm.third === false) {
      !title && entities.push(intl.formatMessage(messages.title));
      !locale && entities.push(intl.formatMessage(messages.locale));
      !description && entities.push(intl.formatMessage(messages.description));
      currentQuest.events.length < 10 &&
        entities.push(
          intl.formatMessage(messages.events, {
            number: 10 - currentQuest.events.length,
          })
        );
      !address && entities.push(intl.formatMessage(messages.address));
      !startLocation &&
        entities.push(intl.formatMessage(messages.startLocation));
      !finishAddress &&
        finishLocation &&
        entities.push(intl.formatMessage(messages.finishAddress));
      finishAddress &&
        !finishLocation &&
        entities.push(intl.formatMessage(messages.finishLocation));
      attractions.length < 1 &&
        entities.push(intl.formatMessage(messages.attractions));
      !distance && entities.push(intl.formatMessage(messages.distance));
      !duration && entities.push(intl.formatMessage(messages.duration));
      highlights.length < 3 &&
        entities.push(intl.formatMessage(messages.highlights));
      !inclusions.length &&
        entities.push(intl.formatMessage(messages.inclusions));
      !exclusions.length &&
        entities.push(intl.formatMessage(messages.exclusions));
      images.length < 6 && entities.push(intl.formatMessage(messages.images));
      subcategories.length < MAX_SELECTED_SUBCATEGORIES &&
        entities.push(intl.formatMessage(messages.subcategories));
      !checkFullness('price') &&
        entities.push(intl.formatMessage(messages.price));
      !entities.length && setConfirm({ ...confirm, third: true });
    }
    return entities.join(', ');
  };

  const attractionsList = attractions.map((elem, index) => (
    <div
      key={elem.id}
      className={
        index + 1 === attractions.length ? '' : 'TourCheck__borderline'
      }
    >
      <Typography className={classes.attractionLabel} variant="h6">
        {elem.name}
      </Typography>
      <Typography className={classes.fee}>
        {elem.fee ? (
          <FormattedMessage
            id="StudioCreateProduct.TourCheckWithTickets"
            defaultMessage="With ticket"
          />
        ) : (
          <FormattedMessage
            id="StudioCreateProduct.TourCheckFree"
            defaultMessage="Free"
          />
        )}
      </Typography>
      <Typography
        className={
          index + 1 === attractions.length ? classes.attractionSubtitleLast : ''
        }
        color="textSecondary"
        variant="body2"
      >
        {elem.address}
      </Typography>
    </div>
  ));

  const getTotalPrice = () => {
    let totalPrice = 0;
    if (isTourWithOnlyDefaultPriceLevel())
      totalPrice = priceLevels.find(
        (pl) => pl.pricingCategoryId === DEFAULT_PRICING_CATEGORY_ID
      ).price;
    else
      getFinalCategories(uniquePricingCategoriesLabels).forEach(
        (fc) => (totalPrice += isNaN(finalPrices[fc]) ? 0 : finalPrices[fc])
      );
    return totalPrice;
  };

  const getTotalPriceBlocks = () => {
    let totalPrice = 0;

    if (finalCategoriesLabels.length === 0)
      return (
        <Typography className={classes.priceBlockLabel} variant="body1">
          {getTotalPrice()}
          &nbsp;
          {defaultCurrency.symbol}
        </Typography>
      );

    if (isTourWithOnlyDefaultPriceLevel())
      totalPrice = priceLevels.find(
        (pl) => pl.pricingCategoryId === DEFAULT_PRICING_CATEGORY_ID
      ).price;
    else
      finalCategoriesLabels.forEach(
        (fc) => (totalPrice += isNaN(finalPrices[fc]) ? 0 : finalPrices[fc])
      );
    return finalCategoriesLabels.map(
      (finalCategory, index) =>
        !isNaN(finalPrices[finalCategory]) && (
          <div
            key={finalCategory}
            className={
              index === finalCategoriesLabels.length - 1
                ? ''
                : 'TourCheck__borderline'
            }
          >
            <LinkIcon
              error={!checkFullness('price')}
              link={`/quest/${currentQuestId}/price`}
              marginTop={0}
            />
            <Typography className={classes.priceBlockLabel} variant="body1">
              {finalCategory}
            </Typography>
            <Typography className={classes.priceBlockPrice} variant="body1">
              {finalPrices[finalCategory]}
              &nbsp;
              {defaultCurrency.symbol}
            </Typography>
          </div>
        )
    );
  };

  return (
    <section className="App__main TourCheck__main">
      <Container className={classes.container}>
        <div className={classes.title}>
          <LinkIcon error={!title} link={`/quest/${currentQuestId}/title`} />
          {title ? <Typography variant="h4">{title}</Typography> : notFilled}
        </div>

        <Paper elevation={0} className={classes.paper}>
          <Typography className={classes.cardTitle} variant="h5">
            <FormattedMessage
              id="StudioCreateProduct.TourCheckLocations"
              defaultMessage="Start and end points of the tour"
            />
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={finishAddress === null ? 12 : 6}>
              <LinkIcon
                error={!address || !startLocation}
                link={`/quest/${currentQuestId}/locations`}
              />
              {address === null ? (
                notFilled
              ) : (
                <Typography variant="body1">{address}</Typography>
              )}
              <Typography variant="body2">{startLocation}</Typography>
            </Grid>
            {finishAddress !== null && (
              <Grid item xs={6}>
                <LinkIcon
                  error={!finishAddress || !finishLocation}
                  link={`/quest/${currentQuestId}/locations`}
                />
                <Typography variant="body1">{finishAddress}</Typography>
                <Typography variant="body2">{finishLocation}</Typography>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={finishAddress === null ? 12 : 6}>
              <div className="TourCheck__map-container">
                {(locationGeo === null ||
                  locationGeo.coordinates[0] !== null) && (
                  <Map
                    centerIsPin
                    zoom={!address ? 3 : 16}
                    positions={{
                      search: null,
                    }}
                    coords={getCoords('locationGeo')}
                  />
                )}
              </div>
            </Grid>

            {finishAddress !== null ? (
              <Grid item xs={6}>
                <div className="TourCheck__map-container">
                  {(finishLocationGeo === null ||
                    finishLocationGeo.coordinates[0] !== null) && (
                    <Map
                      centerIsPin
                      zoom={!finishAddress ? 3 : 16}
                      positions={{
                        search: null,
                      }}
                      coords={getCoords('finishLocationGeo')}
                    />
                  )}
                </div>
              </Grid>
            ) : null}
          </Grid>
        </Paper>

        <Paper elevation={0} className={classes.paper}>
          <Typography className={classes.cardTitle} variant="h5">
            <FormattedMessage
              id="StudioCreateProduct.TourCheckDetails"
              defaultMessage="Details"
            />
          </Typography>

          {renderDetail(
            <FormattedMessage
              id="StudioCreateProduct.TourCheckCategories"
              defaultMessage="Categories"
            />,
            subcategories,
            `/quest/${currentQuestId}/audience`
          )}
          {renderDetail(
            <FormattedMessage
              id="StudioCreateProduct.TourCheckRouteLength"
              defaultMessage="Route length"
            />,
            distance,
            `/quest/${currentQuestId}/locations`
          )}
          {renderDetail(
            <FormattedMessage
              id="StudioCreateProduct.TourCheckLanguage"
              defaultMessage="Language"
            />,
            getCurrentLanguage(locale, languages).name,
            `/quest/${currentQuestId}/title`
          )}
          {renderDetail(
            <FormattedMessage
              id="StudioCreateProduct.TourCheckDuration"
              defaultMessage="Duration"
            />,
            durationMax
              ? ConvertMinutesToShortFormat(durationMax, intl)
              : durationMax,
            `/quest/${currentQuestId}/locations`,
            false
          )}
        </Paper>

        <Paper elevation={0} className={classes.paper}>
          <div className={attractions.length ? 'TourCheck__borderline' : ''}>
            <LinkIcon
              error={!checkFullness('attractions')}
              link={`/quest/${currentQuestId}/locations`}
            />
            <Typography
              className={
                attractions.length ? classes.cardTitleSights : classes.cardTitle
              }
              variant="h5"
            >
              <FormattedMessage
                id="StudioCreateProduct.TourCheckSights"
                defaultMessage="Sights"
              />
            </Typography>
          </div>
          {!attractions.length && notFilled}
          {attractionsList}
        </Paper>

        <Paper elevation={0} className={classes.paper}>
          <LinkIcon
            error={!description.length}
            link={`/quest/${currentQuestId}/description`}
          />
          <Typography className={classes.cardTitle} variant="h5">
            <FormattedMessage
              id="StudioCreateProduct.TourCheckDescription"
              defaultMessage="Description"
            />
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              {description ? (
                <Typography variant="body1" className={classes.description}>
                  {description}
                </Typography>
              ) : (
                notFilled
              )}
            </Grid>
          </Grid>
        </Paper>

        <Paper elevation={0} className={classes.paper}>
          <LinkIcon
            error={!checkFullness('highlights')}
            link={`/quest/${currentQuestId}/highlights`}
          />
          <Typography className={classes.cardTitle} variant="h5">
            <FormattedMessage
              id="StudioCreateProduct.TourCheckFeatures"
              defaultMessage="Highlights"
            />
          </Typography>
          {publishing.highlights.length ? (
            <Examples
              checkIconColor="#24CCD8"
              closeIconColor="#BDBDBD"
              good={
                publishing.highlights &&
                publishing.highlights.map((x) => x.text)
              }
              marginRight={25}
            />
          ) : (
            notFilled
          )}
        </Paper>

        <Paper elevation={0} className={classes.paper}>
          <LinkIcon
            error={!checkFullness('details')}
            link={`/quest/${currentQuestId}/details`}
          />
          <Typography className={classes.cardTitle} variant="h5">
            <FormattedMessage
              id="StudioCreateProduct.TourCheckedIncludedAndNot"
              defaultMessage="What's included and not included"
            />
          </Typography>
          {!!publishing.inclusions.length || !!publishing.exclusions.length ? (
            <Examples
              checkIconColor="#24CCD8"
              closeIconColor="#BDBDBD"
              good={
                publishing.inclusions &&
                publishing.inclusions.map((x) => x.text)
              }
              bad={
                publishing.exclusions &&
                publishing.exclusions.map((x) => x.text)
              }
              marginRight={25}
            />
          ) : (
            notFilled
          )}
        </Paper>

        <Paper elevation={0} className={classes.paper}>
          <LinkIcon
            error={!checkFullness('photos')}
            link={`/quest/${currentQuestId}/photos`}
          />
          <Typography className={classes.cardTitle} variant="h5">
            <FormattedMessage
              id="StudioCreateProduct.TourCheckedPhotos"
              defaultMessage="Photos"
            />
          </Typography>
          {images.length ? (
            <Grid container spacing={1}>
              {images.map((p) => (
                <Grid
                  key={p.id}
                  item
                  xs={6}
                  md={4}
                  lg={3}
                  className={classes.relative}
                >
                  {p.cover && (
                    <Typography className={classes.coverTitle} variant="body2">
                      <FormattedMessage
                        id="StudioCreateProduct.tourCheckCover"
                        defaultMessage="Cover"
                      />
                    </Typography>
                  )}
                  <img
                    className={p.cover ? classes.coverImg : classes.image}
                    src={p.preview}
                    alt=""
                  />
                  <Typography variant="caption">{p.description}</Typography>
                </Grid>
              ))}
            </Grid>
          ) : (
            notFilled
          )}
        </Paper>
        <Paper elevation={0} className={classes.paper}>
          {((finalCategoriesLabels.length &&
            finalCategoriesLabels
              .map((fcl) => prices[fcl] < 0)
              .includes(true)) ||
            finalCategoriesLabels
              .map((fcl) => prices[fcl] < 0)
              .includes(true) ||
            !checkFullness('price') ||
            !finalCategoriesLabels.length) && (
            <LinkIcon
              error={!checkFullness('price')}
              link={`/quest/${currentQuestId}/price`}
            />
          )}
          <Typography className={classes.cardTitle} variant="h5">
            <FormattedMessage
              id="StudioCreateProduct.TourCheckedTourPrice"
              defaultMessage="Tour price"
            />
          </Typography>
          {checkFullness('price') ? getTotalPriceBlocks() : notFilled}
        </Paper>

        <div
          className={
            !confirm.third
              ? classes.checkboxContainer
              : classes.checkboxContainerMargin
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={confirm.first}
                onChange={() => status === 'draft' && handleConfirm('first')}
              />
            }
            label={
              <FormattedMessage
                id="StudioCreateProduct.TourCheckedConfirm1"
                defaultMessage="The text is checked for grammar and punctuation mistakes, easy to read."
              />
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={confirm.second}
                onChange={() => status === 'draft' && handleConfirm('second')}
              />
            }
            label={
              <FormattedMessage
                id="StudioCreateProduct.TourCheckedConfirm2"
                defaultMessage="The tour complies with the {value} of WeGoTrip, the communication style is informal."
                values={{
                  value: standard,
                }}
              />
            }
          />
        </div>
        {!confirm.third && (
          <React.Fragment>
            <Typography variant="body1">
              <FormattedMessage
                id="StudioCreateProduct.TourCheckedNotFullFilled"
                defaultMessage="We will not be able to send your excursion for publication until the missing fields are filled in:"
              />
            </Typography>
            <Typography className={classes.notFilled} variant="body1">
              {handleTourCheckFullness()}
            </Typography>
          </React.Fragment>
        )}

        <TourContentFooterButtons
          rightBtnDisable={!(confirm.first && confirm.second && confirm.third)}
          rightBtnLabel={
            isTourWasSendedToModeration(status) ? null : (
              <FormattedMessage
                id="StudioCreateProduct.TourCheckedPublish"
                defaultMessage="Send for moderation"
              />
            )
          }
          leftLink={`/quest/${currentQuestId}/price`}
          rightLink={
            isTourWasSendedToModeration(status)
              ? `/quest/${currentQuestId}/publish`
              : '#'
          }
          rightBtnFunc={
            isTourWasSendedToModeration(status) ? null : handlePublish
          }
        />
      </Container>
    </section>
  );
};

function makeMapStateToProps() {
  const mapStateToProps = (state) => {
    return {
      user: state.user,
      publishing: state.publishing,
      languages: state.languages.items,
      currentQuestId: state.quests.currentQuestId,
    };
  };
  return mapStateToProps;
}

function mapDispatchToProps(dispatch) {
  return {
    publishingActions: bindActionCreators(publishingActions, dispatch),
  };
}

/**
 * Render linked CreateIcon
 * @param {String} link - path to redirect after click
 * @param {Boolean?} error - indicates if icon should be in error mode
 * @param {Number?} marginTop - set marginTop (3px is default)
 */
const LinkIcon = ({ link, error = false, marginTop = 3 }) => {
  const style = { error, marginTop };
  const classes = useStyles(style);

  return (
    <NavLink to={{ pathname: link, highlights: error }}>
      <CreateIcon className={classes.createIcon} />
    </NavLink>
  );
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(TourCheck)));
