// Users
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const SEND_USER_INFO = 'SEND_USER_INFO';
export const SEND_USER_INFO_SUCCESS = 'SEND_USER_INFO_SUCCESS';
export const SEND_USER_INFO_FAILURE = 'SEND_USER_INFO_FAILURE';
export const SEND_PART_USER_INFO_SUCCESS = 'SEND_PART_USER_INFO_SUCCESS';
export const ADD_LOCATION = 'ADD_LOCATION';
export const DELETE_SELECTED_LOCATION = 'DELETE_SELECTED_LOCATION';
export const DELETE_SELECTED_LOCATION_SUCCESS =
  'DELETE_SELECTED_LOCATION_SUCCESS';
export const ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS';
export const ADD_LOCATION_FAILURE = 'ADD_LOCATION_FAILURE';
export const ADD_PLACE_OF_STAY = 'ADD_PLACE_OF_STAY';
export const ADD_PLACE_OF_STAY_SUCCESS = 'ADD_PLACE_OF_STAY_SUCCESS';
export const ADD_PLACE_OF_STAY_FAILURE = 'ADD_PLACE_OF_STAY_FAILURE';
export const SELECT_LANGUAGE = 'SELECT_LANGUAGE';
export const DELETE_SELECTED_LANGUAGE = 'DELETE_SELECTED_LANGUAGE';
export const DELETE_SELECTED_LANGUAGE_SUCCESS =
  'DELETE_SELECTED_LANGUAGE_SUCCESS';
export const SELECT_SUBCATEGORY = 'SELECT_SUBCATEGORY';
export const DELETE_SELECTED_SUBCATEGORY = 'DELETE_SELECTED_SUBCATEGORY';
export const RECEIVE_USER_INFO = 'RECEIVE_USER_INFO';
export const RECEIVE_USER_INFO_SUCCESS = 'RECEIVE_USER_INFO_SUCCESS';
export const RECEIVE_USER_INFO_FAILURE = 'RECEIVE_USER_INFO_FAILURE';
export const GET_SENDED_INFO = 'GET_SENDED_INFO';
export const REQUEST_SELECTED_LOCATIONS = 'REQUEST_SELECTED_LOCATIONS';
export const RECEIVE_SELECTED_LOCATIONS = 'RECEIVE_SELECTED_LOCATIONS';
export const NOT_RECEIVE_SELECTED_LOCATIONS = 'NOT_RECEIVE_SELECTED_LOCATIONS';
export const LOGIN = 'LOGIN';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_EMAIL = 'LOGIN_EMAIL';
export const LOGIN_EMAIL_FAILURE = 'LOGIN_EMAIL_FAILURE';
export const LOGOUT = 'LOGOUT';
export const SEND_PASSWORD = 'SEND_PASSWORD';
export const REQUEST_USER = 'REQUEST_USER';
export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const NOT_UPDATE_PASSWORD = 'NOT_UPDATE_PASSWORD';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS';
export const CHANGE_LANGUAGE_FAILURE = 'CHANGE_LANGUAGE_FAILURE';
export const RECEIVE_USER = 'RECEIVE_USER';
export const NOT_RECEIVE_USER = 'NOT_RECEIVE_USER';
export const PARTNER_APPLY = 'PARTNER_APPLY';
export const SEND_USER = 'SEND_USER';
export const USER_ADD_ERROR = 'USER_ADD_ERROR';
export const USER_REMOVE_ERRORS = 'USER_REMOVE_ERRORS';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';
export const UNHIJACK = 'UNHIJACK';
export const UPLOAD_AVATAR = 'UPLOAD_AVATAR';
export const UPLOAD_AVATAR_SUCCESS = 'UPLOAD_AVATAR_SUCCESS';
export const UPLOAD_AVATAR_FAILURE = 'UPLOAD_AVATAR_FAILURE';
export const USER_RESET_ERRORS = 'USER_RESET_ERRORS';
export const USER_CLICK_LOGIN = 'USER_CLICK_LOGIN';
export const REQUEST_PAYOUT = 'REQUEST_PAYOUT';
export const REQUEST_PAYOUT_SUCCESS = 'REQUEST_PAYOUT_SUCCESS';

// ETC
export const FETCH_DEFAULT_COUNTRY = 'FETCH_DEFAULT_COUNTRY';
export const FETCH_DEFAULT_COUNTRY_SUCCESS = 'FETCH_DEFAULT_COUNTRY_SUCCESS';
export const FETCH_DEFAULT_COUNTRY_FAILURE = 'FETCH_DEFAULT_COUNTRY_FAILURE';
export const REQUEST_CATEGORIES = 'REQUEST_CATEGORIES';
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES';
export const NOT_RECEIVE_CATEGORIES = 'NOT_RECEIVE_CATEGORIES';
export const REQUEST_CURRENCIES = 'REQUEST_CURRENCIES';
export const ADD_LANGUAGES_FOR_USER_STATE = 'ADD_LANGUAGES_FOR_USER_STATE';
export const ADD_CURRENCIES_FOR_USER_STATE = 'ADD_CURRENCIES_FOR_USER_STATE';

// Quests
export const FETCH_QUESTS = 'FETCH_QUESTS';
export const FETCH_UPDATED_QUEST = 'FETCH_UPDATED_QUEST';
export const REQUEST_QUESTS = 'REQUEST_QUESTS';
export const RECEIVE_QUESTS = 'RECEIVE_QUESTS';
export const ALREADY_RECEIVE_QUESTS = 'ALREADY_RECEIVE_QUESTS';
export const REQUEST_QUEST = 'REQUEST_QUEST';
export const RECEIVE_QUEST = 'RECEIVE_QUEST';
export const NOT_RECEIVE_QUEST = 'NOT_RECEIVE_QUEST';
export const SEND_QUEST = 'SEND_QUEST';
export const APPROVE_QUEST = 'APPROVE_QUEST';
export const SYNC_QUEST_SUCCESS = 'SYNC_QUEST_SUCCESS';
export const SYNC_QUEST_FAILURE = 'SYNC_QUEST_FAILURE';
export const ADD_QUEST = 'ADD_QUEST';
export const EDIT_QUEST = 'EDIT_QUEST';
export const RENAME_QUEST = 'RENAME_QUEST';
export const SELECT_QUEST = 'SELECT_QUEST';
export const SYNC_QUEST = 'SYNC_QUEST';
export const FETCH_QUEST = 'FETCH_QUEST';
export const UPDATE_QUEST_DATA = 'UPDATE_QUEST_DATA';

export const COPY_QUEST = 'COPY_QUEST';
export const COPY_QUEST_SUCCESS = 'COPY_QUEST_SUCCESS';
export const COPY_QUEST_FAILURE = 'COPY_QUEST_FAILURE';

export const DELETE_QUEST = 'DELETE_QUEST';
export const DELETE_QUEST_UNDO = 'DELETE_QUEST_UNDO';
export const DELETE_QUEST_SUCCESS = 'DELETE_QUEST_SUCCESS';
export const DELETE_QUEST_FAILURE = 'DELETE_QUEST_FAILURE';

export const CREATE_QUEST = 'CREATE_QUEST';
export const CREATE_QUEST_SUCCESS = 'CREATE_QUEST_SUCCESS';
export const CREATE_QUEST_FAILURE = 'CREATE_QUEST_FAILURE';

export const TOGGLE_MUSEUM_SAGA = 'TOGGLE_MUSEUM_SAGA';
export const TOGGLE_MUSEUM = 'TOGGLE_MUSEUM';

// Events
export const ADD_EVENT = 'ADD_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_SYNC = 'DELETE_EVENT_SYNC';
export const RENAME_EVENT = 'RENAME_EVENT';
export const EDIT_EVENT_GEO = 'EDIT_EVENT_GEO';
export const EDIT_EVENT_TIME = 'EDIT_EVENT_TIME';
export const SEND_FILES = 'SEND_FILES';
export const ADD_EVENT_IMAGES = 'ADD_EVENT_IMAGES';
export const RECEIVE_IMAGES_URLS = 'RECEIVE_IMAGES_URLS';
export const NOT_RECEIVE_IMAGES_URLS = 'NOT_RECEIVE_IMAGES_URLS';
export const REMOVE_EVENT_IMAGE = 'REMOVE_EVENT_IMAGE';
export const REMOVE_EVENT_IMAGES = 'REMOVE_EVENT_IMAGES';
export const REPLACE_EVENT_IMAGE = 'REPLACE_EVENT_IMAGE';
export const DRAG_EVENT_IMAGE = 'DRAG_EVENT_IMAGE';
export const SYNC_DRAG_EVENT_IMAGE = 'SYNC_DRAG_EVENT_IMAGE';
export const EDIT_EVENT_DIRECTIONS = 'EDIT_EVENT_DIRECTIONS';

export const UPDATE_EVENT_IMAGE_DESCRIPTION = 'UPDATE_EVENT_IMAGE_DESCRIPTION';
export const RECEIVE_VIDEOS_URLS = 'RECEIVE_VIDEOS_URLS';
export const REMOVE_EVENT_VIDEO = 'REMOVE_EVENT_VIDEO';
export const RECEIVE_IMAGEAR_URL = 'RECEIVE_IMAGEAR_URL';
export const EDIT_EVENT_AR = 'EDIT_EVENT_AR';
export const REMOVE_AR_TARGET = 'REMOVE_AR_TARGET';
export const ADD_EVENT_AUDIOS = 'ADD_EVENT_AUDIOS';
export const RECEIVE_AUDIOS_URLS = 'RECEIVE_AUDIOS_URLS';
export const NOT_RECEIVE_AUDIOS_URLS = 'NOT_RECEIVE_AUDIOS_URLS';
export const REMOVE_EVENT_AUDIO = 'REMOVE_EVENT_AUDIO';
export const EDIT_EVENT_AUDIOS = 'EDIT_EVENT_AUDIOS';
export const EDIT_EVENT_AUDIO_HTML = 'EDIT_EVENT_AUDIO_HTML';
export const EDIT_EVENT_TEXT = 'EDIT_EVENT_TEXT';
export const EDIT_EVENT_HTML = 'EDIT_EVENT_HTML';
export const EDIT_EVENT_CHOICE = 'EDIT_EVENT_CHOICE';
export const CHANGE_TITLE = 'CHANGE_TITLE';
export const EDIT_EVENT_HINTS = 'EDIT_EVENT_HINTS';
export const EDIT_EVENT_SKIP = 'EDIT_EVENT_SKIP';
export const EDIT_EVENT_BONUS = 'EDIT_EVENT_BONUS';
export const RECEIVE_STICKERS_URL = 'RECEIVE_STICKERS_URL';
export const REMOVE_EVENT_STICKER = 'REMOVE_EVENT_STICKER';
export const REORDER_EVENT_STICKER = 'REORDER_EVENT_STICKER';
export const REORDER_EVENTS_AND_POINTS = 'REORDER_EVENTS_AND_POINTS';
export const REORDER_EVENTS = 'REORDER_EVENTS';

// Routes
export const ADD_ROUTE_POINT = 'ADD_ROUTE_POINT';
export const EDIT_ROUTE_POINT = 'EDIT_ROUTE_POINT';
export const REMOVE_ROUTE_POINT = 'REMOVE_ROUTE_POINT';
export const REARRANGE_ROUTE_POINTS = 'REARRANGE_ROUTE_POINTS';
export const MERGE_ROUTE_POINTS = 'MERGE_ROUTE_POINTS';
export const SPLIT_ROUTE_POINT = 'SPLIT_ROUTE_POINT';
export const ADD_ROUTE_POINT_WITH_EVENT = 'ADD_ROUTE_POINT_WITH_EVENT';
export const RENAME_ROUTE_POINT_WITH_EVENT = 'RENAME_ROUTE_POINT_WITH_EVENT';
export const SEND_ROUTE_POINT = 'SEND_ROUTE_POINT';
export const SEND_ROUTE_POINT_FAILURE = 'SEND_ROUTE_POINT_FAILURE';
export const SEND_ROUTE_POINT_SUCCESS = 'SEND_ROUTE_POINT_SUCCESS';

// Publishing
export const RECEIVE_QUEST_CODE = 'RECEIVE_QUEST_CODE';
export const REQUEST_PLANS = 'REQUEST_PLANS';
export const RECEIVE_PLANS = 'RECEIVE_PLANS';
export const NOT_RECEIVE_PLANS = 'NOT_RECEIVE_PLANS';
export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const RECEIVE_PAYMENT = 'RECEIVE_PAYMENT';
export const NOT_RECEIVE_PAYMENT = 'NOT_RECEIVE_PAYMENT';
export const START_PAYMENT = 'START_PAYMENT';
export const PAYMENT_FAILURE = 'PAYMENT_FAILURE';
export const CHOOSE_BUSINESS_PLAN = 'CHOOSE_BUSINESS_PLAN';
export const PUBLISH_FREE = 'PUBLISH_FREE';
export const OPEN_PUBLISH_POPUP = 'OPEN_PUBLISH_POPUP';
export const START_PUBLISH_PRODUCT = 'START_PUBLISH_PRODUCT';
export const PUBLISH_PRODUCT = 'PUBLISH_PRODUCT';
export const PUBLISH_PRODUCT_SUCCESS = 'PUBLISH_PRODUCT_SUCCESS';
export const PUBLISH_PRODUCT_FAILURE = 'PUBLISH_PRODUCT_FAILURE';
export const RESET_PUBLISH_PRODUCT_ERRORS = 'RESET_PUBLISH_PRODUCT_ERRORS';
export const SEND_PRODUCT_DATA = 'SEND_PRODUCT_DATA';
export const SEND_PRODUCT_DATA_FAILURE = 'SEND_PRODUCT_DATA_FAILURE';

export const SEND_PUBLISH_DATA = 'SEND_PUBLISH_DATA';
export const SEND_PUBLISH_DATA_SUCCESS = 'SEND_PUBLISH_DATA_SUCCESS';
export const SEND_PUBLISH_DATA_FAILURE = 'SEND_PUBLISH_DATA_FAILURE';
export const SEND_LISTED_DATA = 'SEND_LISTED_DATA';
export const SEND_LISTED_DATA_SUCCESS = 'SEND_LISTED_DATA_SUCCESS';
export const SEND_LISTED_IMAGES_DATA_SUCCESS =
  'SEND_LISTED_IMAGES_DATA_SUCCESS';
export const SEND_LISTED_PRICE_DATA_SUCCESS = 'SEND_LISTED_PRICE_DATA_SUCCESS';
export const SEND_LISTED_DATA_FAILURE = 'SEND_LISTED_DATA_FAILURE';
export const POST_LISTED_DATA = 'POST_LISTED_DATA';
export const POST_LISTED_DATA_SUCCESS = 'POST_LISTED_DATA_SUCCESS';
export const POST_LISTED_PRICE_DATA_SUCCESS = 'POST_LISTED_PRICE_DATA_SUCCESS';
export const POST_LISTED_DATA_FAILURE = 'POST_LISTED_DATA_FAILURE';
export const DELETE_LISTED_DATA = 'DELETE_LISTED_DATA';
export const DELETE_MULTIPLE_IMAGES = 'DELETE_MULTIPLE_IMAGES';
export const DELETE_LISTED_DATA_SUCCESS = 'DELETE_LISTED_DATA_SUCCESS';
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';
export const DELETE_LISTED_DATA_FAILURE = 'DELETE_LISTED_DATA_FAILURE';
export const SYNC_PRODUCT_IMAGES_ORDER = 'SYNC_PRODUCT_IMAGES_ORDER';
export const RECEIVE_IMAGESTORE_URL = 'RECEIVE_IMAGESTORE_URL';
export const REORDER_PRODUCT_IMAGES = 'REORDER_PRODUCT_IMAGES';
export const REORDER_PRODUCT_IMAGES_SYNC = 'REORDER_PRODUCT_IMAGES_SYNC';

export const REQUEST_PRODUCT = 'REQUEST_PRODUCT';
export const RECEIVE_PRODUCT = 'RECEIVE_PRODUCT';
export const NOT_RECEIVE_PRODUCT = 'NOT_RECEIVE_PRODUCT';

export const SEND_ATTRACTIONS_DATA = 'SEND_ATTRACTIONS_DATA';
export const SEND_ATTRACTIONS_DATA_SUCCESS = 'SEND_ATTRACTIONS_DATA_SUCCESS';
export const SEND_ATTRACTIONS_DATA_FAILURE = 'SEND_ATTRACTIONS_DATA_FAILURE';
export const DELETE_ATTRACTIONS_DATA_SUCCESS =
  'DELETE_ATTRACTIONS_DATA_SUCCESS';

export const SEND_TICKETS_DATA = 'SEND_TICKETS_DATA';
export const SEND_TICKETS_DATA_SUCCESS = 'SEND_TICKETS_DATA_SUCCESS';
export const SEND_TICKETS_DATA_FAILURE = 'SEND_TICKETS_DATA_FAILURE';
export const DELETE_TICKETS_DATA_SUCCESS = 'DELETE_TICKETS_DATA_SUCCESS';
export const SEND_TICKETS_CATEGORIES_DATA = 'SEND_TICKETS_CATEGORIES_DATA';
export const SEND_TICKETS_CATEGORIES_DATA_SUCCESS =
  'SEND_TICKETS_CATEGORIES_DATA_SUCCESS';
export const SEND_TICKETS_CATEGORIES_DATA_FAILURE =
  'SEND_TICKETS_CATEGORIES_DATA_FAILURE';
export const DELETE_TICKETS_CATEGORIES_DATA_SUCCESS =
  'DELETE_TICKETS_CATEGORIES_DATA_SUCCESS';
export const DELETE_TICKET = 'DELETE_TICKET';
export const CONVERT_AUDIO_GUIDES_PRICES = 'CONVERT_AUDIO_GUIDES_PRICES';
export const CONVERT_AUDIO_GUIDES_PRICES_FAILURE =
  'CONVERT_AUDIO_GUIDES_PRICES_FAILURE';

// Stats
export const REQUEST_STATS = 'REQUEST_STATS';
export const RECEIVE_STATS = 'RECEIVE_STATS';
export const NOT_RECEIVE_STATS = 'NOT_RECEIVE_STATS';
export const ALREADY_RECEIVE_STATS = 'ALREADY_RECEIVE_STATS';
export const REQUEST_QUESTS_STATS = 'REQUEST_QUEST_STATS';
export const RECEIVE_QUESTS_STATS = 'RECEIVE_QUEST_STATS';
export const NOT_RECEIVE_QUESTS_STATS = 'NOT_RECEIVE_QUEST_STATS';
export const UPDATE_QUEST_STAT = 'UPDATE_QUEST_STAT';
export const SYNC_QUEST_STAT = 'SYNC_QUEST_STAT';

// Referal
export const REQUEST_REFERAL_SALES = 'REQUEST_REFERAL_SALES';
export const RECEIVE_REFERAL_SALES = 'RECEIVE_REFERAL_SALES';
export const NOT_RECEIVE_REFERAL_SALES = 'NOT_RECEIVE_REFERAL_SALES';
export const ALREADY_RECEIVE_REFERAL_SALES = 'ALREADY_RECEIVE_REFERAL_SALES';
export const REQUEST_REFERAL_DATA = 'REQUEST_REFERAL_DATA';
export const RECEIVE_REFERAL_DATA = 'RECEIVE_REFERAL_DATA';
export const NOT_RECEIVE_REFERAL_DATA = 'NOT_RECEIVE_REFERAL_DATA';
export const REQUEST_CITIES = 'REQUEST_CITIES';
export const RECEIVE_CITIES = 'RECEIVE_CITIES';
export const NOT_RECEIVE_CITIES = 'NOT_RECEIVE_CITIES';
export const REQUEST_COLLECTIONS = 'REQUEST_COLLECTIONS';
export const RECEIVE_COLLECTIONS = 'RECEIVE_COLLECTIONS';
export const NOT_RECEIVE_COLLECTIONS = 'NOT_RECEIVE_COLLECTIONS';
export const REQUEST_LANGUAGES = 'REQUEST_LANGUAGES';
export const RECEIVE_LANGUAGES = 'RECEIVE_LANGUAGES';
export const FETCH_WIDGET_STATS = 'FETCH_WIDGET_STATS';
export const FETCH_WIDGET_STATS_SUCCESS = 'FETCH_WIDGET_STATS_SUCCESS';
export const FETCH_WIDGET_STATS_FAILURE = 'FETCH_WIDGET_STATS_FAILURE';
export const RESET_REFERAL_ERRORS = 'RESET_REFERAL_ERRORS';

// Partner
export const FETCH_PARTNER_STATS = 'FETCH_PARTNER_STATS';
export const FETCH_PARTNER_STATS_SUCCESS = 'FETCH_PARTNER_STATS_SUCCESS';
export const FETCH_PARTNER_STATS_FAILURE = 'FETCH_PARTNER_STATS_FAILURE';
export const FETCH_PARTNER_DATA = 'FETCH_PARTNER_DATA';
export const FETCH_PARTNER_DATA_SUCCESS = 'FETCH_PARTNER_DATA_SUCCESS';
export const FETCH_PARTNER_DATA_FAILURE = 'FETCH_PARTNER_DATA_FAILURE';
export const UPDATE_PARTNER_DATA = 'UPDATE_PARTNER_DATA';
export const UPDATE_PARTNER_DATA_SUCCESS = 'UPDATE_PARTNER_DATA_SUCCESS';
export const UPDATE_PARTNER_DATA_FAILURE = 'UPDATE_PARTNER_DATA_FAILURE';
export const UPDATE_REQUISITES = 'UPDATE_REQUISITES';
export const UPDATE_REQUISITES_SUCCESS = 'UPDATE_REQUISITES_SUCCESS';
export const UPDATE_REQUISITES_FAILURE = 'UPDATE_REQUISITES_FAILURE';
export const ADD_REQUISITES = 'ADD_REQUISITES';
export const ADD_REQUISITES_SUCCESS = 'ADD_REQUISITES_SUCCESS';
export const ADD_REQUISITES_FAILURE = 'ADD_REQUISITES_FAILURE';
export const OPEN_FORM_BIO_POPUP = 'OPEN_FORM_BIO_POPUP';
export const CLOSE_FORM_BIO_POPUP = 'CLOSE_FORM_BIO_POPUP';
export const FETCH_PAYMENTS_STATS = 'FETCH_PAYMENTS_STATS';
export const FETCH_PAYMENTS_STATS_SUCCESS = 'FETCH_PAYMENTS_STATS_SUCCESS';
export const FETCH_PAYMENTS_STATS_FAILURE = 'FETCH_PAYMENTS_STATS_FAILURE';
export const FETCH_AFFILIATE_PARTNER = 'FETCH_AFFILIATE_PARTNER';
export const FETCH_AFFILIATE_PARTNER_SUCCESS =
  'FETCH_AFFILIATE_PARTNER_SUCCESS';
export const FETCH_AFFILIATE_PARTNER_FAILURE =
  'FETCH_AFFILIATE_PARTNER_FAILURE';
export const UPDATE_AFFILIATE_PARTNER = 'UPDATE_AFFILIATE_PARTNER';
export const UPDATE_AFFILIATE_PARTNER_SUCCESS =
  'UPDATE_AFFILIATE_PARTNER_SUCCESS';
export const UPDATE_AFFILIATE_PARTNER_FAILURE =
  'UPDATE_AFFILIATE_PARTNER_FAILURE';
export const FETCH_TOTAL_INCOME = 'FETCH_TOTAL_INCOME';
export const FETCH_TOTAL_INCOME_SUCCESS = 'FETCH_TOTAL_INCOME_SUCCESS';
export const FETCH_TOTAL_INCOME_FAILURE = 'FETCH_TOTAL_INCOME_FAILURE';

// Uploads
export const UPLOAD_ADD = 'UPLOAD_ADD';
export const UPLOAD_BATCH = 'UPLOAD_BATCH';
export const UPLOAD_START = 'UPLOAD_START';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const UPLOAD_FAILURE = 'UPLOAD_FAILURE';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_ABORT = 'UPLOAD_ABORT';
export const UPLOAD_ABORT_SUCCESS = 'UPLOAD_ABORT_SUCCESS';
export const UPLOAD_ABORT_FAILURE = 'UPLOAD_ABORT_FAILURE';
export const READ_FAILURE = 'READ_FAILURE';
export const READ_SUCCESS = 'READ_SUCCESS';

// Codes
export const CREATE_CODES = 'CREATE_CODES';
export const CREATE_CODES_SUCCESS = 'CREATE_CODES_SUCCESS';
export const CREATE_CODES_FAILURE = 'CREATE_CODES_FAILURE';
export const FETCH_CODES = 'FETCH_CODES';
export const FETCH_CODES_SUCCESS = 'FETCH_CODES_SUCCESS';
export const FETCH_CODES_FAILURE = 'FETCH_CODES_FAILURE';
export const UPDATE_CODE = 'UPDATE_CODE';
export const UPDATE_CODE_SUCCESS = 'UPDATE_CODE_SUCCESS';
export const UPDATE_CODE_FAILURE = 'UPDATE_CODE_FAILURE';
export const ADD_RECENT_CODES = 'ADD_RECENT_CODES';
export const REMOVE_RECENT_CODES = 'REMOVE_RECENT_CODES';

// Tutorials
export const CREATE_TUTORIAL = 'CREATE_TUTORIAL';
export const UPDATE_TUTORIAL_INDEX = 'UPDATE_TUTORIAL_INDEX';
export const CLOSE_TUTORIAL = 'CLOSE_TUTORIAL';
export const FINISH_TUTORIAL = 'FINISH_TUTORIAL';
export const RESET_TUTORIAL = 'RESET_TUTORIAL';
export const UPDATE_UNFINISHED_TUTORIALS_COUNT =
  'UPDATE_UNFINISHED_TUTORIALS_COUNT';

// Network
export const NETWORK_ERROR = 'NETWORK_ERROR';
export const NETWORK_ACCESS_DENIED = 'NETWORK_ACCESS_DENIED';
export const NETWORK_CONFLICT = 'NETWORK_CONFLICT';
export const NETWORK_SERVER_ERROR = 'NETWORK_SERVER_ERROR';
export const NETWORK_UNAUTHORIZED = 'NETWORK_UNAUTHORIZED';
export const NETWORK_DEFAULT_ERROR = 'NETWORK_DEFAULT_ERROR';
export const RESET_NETWORK_ERROR = 'RESET_NETWORK_ERROR';
export const RESET_NETWORK_ERRORS = 'RESET_NETWORK_ERRORS';
export const NETWORK_BAD_REQUEST = 'NETWORK_BAD_REQUEST';
export const CUSTOM_ERROR = 'CUSTOM_ERROR';

// Analytics
export const AUTHORS_LOGGED = 'AUTHORS_LOGGED';

// Languages
export const FETCH_LANGUAGES = 'FETCH_LANGUAGES';
export const FETCH_LANGUAGES_SUCCESS = 'FETCH_LANGUAGES_SUCCESS';
export const FETCH_LANGUAGES_FAILURE = 'FETCH_LANGUAGES_FAILURE';

export const ANALYTICS_TRACK_EVENT = 'ANALYTICS_TRACK_EVENT';
export const ANALYTICS_USER_UPDATE = 'ANALYTICS_USER_UPDATE';

// Currencies
export const FETCH_CURRENCIES = 'FETCH_CURRENCIES';
export const FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS';
export const FETCH_CURRENCIES_FAILURE = 'FETCH_CURRENCIES_FAILURE';

// Products
export const FETCH_PAYOUTS = 'FETCH_PAYOUTS';
export const FETCH_PAYOUTS_SUCCESS = 'FETCH_PAYOUTS_SUCCESS';
export const FETCH_PAYOUTS_FAILURE = 'FETCH_PAYOUTS_FAILURE';
