export const SEC = 1000;
export const MIN_IN_SEC = 60;
export const MIN = MIN_IN_SEC * SEC;
export const HOUR_IN_SEC = 60 * MIN_IN_SEC;
export const HOUR = 60 * MIN;
export const DAY = 24 * HOUR;
export const DAY_IN_SEC = 24 * HOUR_IN_SEC;
export const WEEK = 7 * DAY;
export const WEEK_IN_SEC = 7 * DAY_IN_SEC;

export const REVIEW_TEXT_WORDS_LIMIT = 59;
export const COOKIE_TEN_YEAR_AGE = 315569520;
export const COOKIE_WEEK_AGE = 604800;
