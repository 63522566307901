import {
  FETCH_CODES,
  FETCH_CODES_SUCCESS,
  FETCH_CODES_FAILURE,
  CREATE_CODES_SUCCESS,
  UPDATE_CODE,
  UPDATE_CODE_SUCCESS,
  UPDATE_CODE_FAILURE,
  ADD_RECENT_CODES,
  REMOVE_RECENT_CODES,
  CREATE_CODES,
} from '../constants/ActionTypes';

export default function codes(
  state = {
    codes: [],
    lastCreated: [],
    recent: [],
    balance: 0,
    errors: [],
    isFetching: false,
    isCreating: false,
    isUpdating: false,
  },
  action
) {
  switch (action.type) {
    case FETCH_CODES:
      return {
        ...state,
        isFetching: true,
      };

    case CREATE_CODES_SUCCESS:
      return {
        ...state,
        codes: [].concat(action.payload, state.codes),
        lastCreated: action.payload.map((a) => a.accessCode),
        isCreating: false,
      };

    case FETCH_CODES_SUCCESS:
      return {
        ...state,
        codes: action.payload,
        isFetching: false,
      };

    case FETCH_CODES_FAILURE:
      return {
        ...state,
        error: [...state.errors, action.error],
        isFetching: false,
      };

    case CREATE_CODES: {
      return {
        ...state,
        isCreating: true,
      };
    }

    case UPDATE_CODE: {
      return {
        ...state,
        isUpdating: true,
      };
    }

    case UPDATE_CODE_SUCCESS: {
      const { payload: nextAccess } = action;
      return {
        ...state,
        codes: state.codes.map((item) =>
          item.accessCode === nextAccess.accessCode ? nextAccess : item
        ),
        isUpdating: false,
      };
    }

    case UPDATE_CODE_FAILURE: {
      return {
        ...state,
        isUpdating: false,
      };
    }

    case ADD_RECENT_CODES: {
      return {
        ...state,
        recent: [...state.recent, ...action.payload],
      };
    }

    case REMOVE_RECENT_CODES: {
      return {
        ...state,
        recent: state.recent.filter((r) => r.indexOf(action.payload) === -1),
      };
    }

    default:
      return state;
  }
}
