/**
 * Creates HTML canvas element and draws image with `url` source on it
 * @param {String} url - image URL to load
 * @returns {Promise} - resolving with canvas html element with image drawn on it
 */
export default function imageToCanvas(url) {
  return new Promise(function (resolve) {
    const img = new Image();
    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      resolve(canvas);
    };

    img.src = url;
  });
}
