import { getToken } from './tokenUtils';

export default function () {
  const token = getToken();
  if (!token) {
    return {};
  }
  return {
    Authorization: `Token ${token}`,
  };
}
