import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import formatDistance from 'date-fns/formatDistance';
import format from 'date-fns/format';
import Tooltip from 'react-tooltip';
import { enUS as en, ru } from 'date-fns/locale';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
// TODO: replace with custom function
import classNames from 'classnames';
import { isDesktop } from '../../functions/queries';
import './QuestStatus.css';
import Button from '../Button';
import { syncQuest } from '../../actions/quests';

const messages = defineMessages({
  lastSaved: {
    id: 'QuestStatus.lastSaved',
    defaultMessage: 'Last saved {time} ago',
  },
});

const QuestStatus = ({
  quest: { id, lastModified, ok, syncing },
  intl: { locale, formatMessage },
  full,
  dispatch,
}) => {
  const dateFnsLocale = locale === 'ru' ? ru : en;

  const lastModifiedDate = new Date(lastModified);
  const lastModifiedDistance = formatMessage(messages.lastSaved, {
    time: formatDistance(lastModifiedDate, new Date(), {
      locale: dateFnsLocale,
    }),
  });

  return (
    <div
      data-tip
      className={classNames('QuestStatus', full && 'QuestStatus--full')}
      data-for={`quest-status-${id}`}
    >
      {syncing ? (
        <span role="img" aria-label="Syncing">
          🔄
        </span>
      ) : ok ? (
        <span>
          {!full && (
            <span role="img" aria-label="OK">
              ✅
            </span>
          )}
          <span className="QuestStatus__message">
            {full && lastModifiedDistance}
          </span>
        </span>
      ) : (
        <Fragment>
          <span role="img" aria-label="Error">
            ⚠️
          </span>
          <span className="QuestStatus__message">
            <FormattedMessage
              id="QuestStatus.notSaved"
              defaultMessage="Your changes are not saved"
            />
          </span>
          <Button
            theme="light"
            size="inherit"
            className="QuestStatus__retry"
            onClick={() => {
              dispatch(syncQuest(id));
            }}
          >
            <FormattedMessage id="QuestStatus.retry" defaultMessage="Retry" />
          </Button>
        </Fragment>
      )}
      <Tooltip
        id={`quest-status-${id}`}
        effect="solid"
        globalEventOff="click"
        delayHide={300}
        className="HelpTooltip__hint"
        place={isDesktop() ? 'bottom' : 'left'}
      >
        {!full && lastModifiedDistance} {!full && '('}
        {format(lastModifiedDate, 'dd MMMM yyyy HH:mm:ss', {
          locale: dateFnsLocale,
        })}
        {!full && ')'}
      </Tooltip>
    </div>
  );
};

export default connect()(injectIntl(QuestStatus));
