/* eslint-disable consistent-return */
import React from 'react';
import { sanitize } from 'dompurify';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { EditorState, Modifier } from 'draft-js';
import convertStylesToTags from '../../../../functions/xml/convertStylesToTags';
/**
 * Names of tags allowed to be in event text content
 * @type {Array[String]}
 */
export const ALLOWED_TAGS = [
  'a',
  'i',
  'em',
  'b',
  'strong',
  'u',
  's',
  'strike',
  'del',
  'p',
  'div',
  'br',
  'ol',
  'ul',
  'li',
];

export const fromHtmlOptions = {
  htmlToEntity: (nodeName, node, createEntity) => {
    if (nodeName === 'a') {
      return createEntity('LINK', 'MUTABLE', { url: node.href });
    }
  },
};

export const toHtmlOptions = {
  entityToHTML: (entity, originalText) =>
    entity.type === 'LINK'
      ? `<a href="${entity.data.url || entity.data.href}">${originalText}</a>`
      : originalText,

  blockToHTML: ({ type, text }) => {
    if (type === 'unstyled' && (!text || text === ' ')) {
      return <br />;
    }
  },
};

export const stateFromHtml = convertFromHTML(fromHtmlOptions);
export const htmlFromState = convertToHTML(toHtmlOptions);

export const handlePastedText = (_t, html, state, onChanged) => {
  if (typeof html !== 'string') {
    return false;
  }

  const sanitizedHTML = sanitize(convertStylesToTags(html), {
    ALLOWED_TAGS,
    ALLOW_UNKNOWN_PROTOCOLS: false,
  });

  const newContent = Modifier.replaceWithFragment(
    state.getCurrentContent(),
    state.getSelection(),
    stateFromHtml(sanitizedHTML).getBlockMap()
  );

  onChanged(EditorState.push(state, newContent, 'insert-fragment'));

  return true;
};

export const setStateWithContent = (html, setStateFn) => {
  const contentState = stateFromHtml(html);
  const stateWithContent = EditorState.createWithContent(contentState);

  setStateFn(stateWithContent);
};
