/**
 * Applies several mods to Component
 * @param {React::Component} Component - component to overload with mods
 * @param  {...Array[Function]} mods - HOC mods to overload component, applying in reversed order
 * @returns {CReact::Component} - `Component` overloaded with all valid `mods[]`
 */
export default function compose(Component, ...mods) {
  return mods
    .reverse()
    .reduce(
      (ComponentOverloaded, withMod) =>
        typeof withMod === 'function'
          ? withMod(ComponentOverloaded)
          : ComponentOverloaded,
      Component
    );
}
