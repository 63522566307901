/**
 * Strips leading zeros from string
 * @param {Array[String]} finalCategoriesLabels - array of final finally merged pricing categories labels
 * @return {Boolean} - returns `true` if all final categories has price
 */
export const isFinalCategoriesSet = (finalCategoriesLabels, prices) => {
  return finalCategoriesLabels
    .map((finalCategoriesLabel) => prices[finalCategoriesLabel] >= 0)
    .every(Boolean);
};
