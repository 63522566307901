import { camelizeKeys } from 'humps';
import fetchJSON from '../fetchJSON';
import { YA_METRIC_ACCESS_TOKEN } from '../../constants/branding';
import { getYAMetricURL } from './getMetricApiURL';

const YAMetricHeaders = {
  Authorization: `OAuth ${YA_METRIC_ACCESS_TOKEN}`,
  'Content-Type': 'application/x-yametrika+json',
};

export default async function fetchMetric(endpointParams) {
  try {
    let json = await fetchJSON(getYAMetricURL(endpointParams), {
      headers: {
        ...YAMetricHeaders,
      },
    });
    if (json instanceof Object && json.constructor === Object) {
      json = camelizeKeys(json);
    }

    return json;
  } catch (error) {
    return Promise.reject(error);
  }
}
