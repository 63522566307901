import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import {
  ADD_ROUTE_POINT,
  EDIT_ROUTE_POINT,
  REMOVE_ROUTE_POINT,
  REARRANGE_ROUTE_POINTS,
  SPLIT_ROUTE_POINT,
  MERGE_ROUTE_POINTS,
  SEND_ROUTE_POINT,
  SEND_ROUTE_POINT_SUCCESS,
  SEND_ROUTE_POINT_FAILURE,
} from '../constants/ActionTypes';
import guid from '../functions/id/guid';
import findRoutePoint from '../functions/route/findRoutePoint';
import { findQuest } from './quests';

/**
 * Splits route point by `eventOrder` in `events` point array and edits point bound to the `eventOrder`
 * @param {Object} point - route point to split
 * @param {String} eventId - guid of the event in `events` array
 * @param {Object} pointOverload - new props for route point object
 * @returns {Array[RoutePoint]}
 */
function splitRoutePointEvent(point, eventOrder, pointOverload) {
  return point
    ? point.events
        .reduce(
          (acc, order) => {
            if (order !== eventOrder) {
              acc[acc.length - 1].push(order);
            } else {
              acc.splice(acc.length, 0, [order], []);
            }
            return acc;
          },
          [[]]
        )
        .filter((events) => events.length)
        .map((events) => ({
          ...cloneDeep(point),
          events,
          ...(events[0] === eventOrder ? pointOverload : { guid: guid() }),
        }))
    : [];
}

/**
 * Subreducer editing routes for quest
 * **Important:** returns `null` if nothing was modified
 * @param {Object} state - `store::quests` redux store
 * @param {Object} action - redux action
 * @returns {Object|Null} - updated copy of the `state`, `null` if nothing updated
 */
export default function routes(state, action) {
  const quest = findQuest(state.quests, action.questId);
  const points = quest
    ? [...(quest.routes instanceof Array ? quest.routes : [])]
    : null;
  const pointIndex = action.pointId
    ? findRoutePoint(points, action.pointId, true)
    : -1;

  /**
   * Will be spread to `quest` object with id `questId`
   * May contain updated `points` after main switch
   * @type {Object|Null}
   */
  let questOverload = null;
  const storeOverload = {};

  switch (action.type) {
    case ADD_ROUTE_POINT: {
      const pointId = pointIndex > -1 ? pointIndex : points.length;
      points.splice(pointId, 0, action.routePoint);
      questOverload = {
        routes: points,
        lastModifiedText: new Date().toISOString(),
        copies: quest.copies.map((copy) => ({ ...copy, synchronized: false })),
      };
      break;
    }

    case EDIT_ROUTE_POINT: {
      points[pointIndex] = { ...action.routePoint, guid: action.pointId };
      questOverload = {
        routes: points,
        lastModifiedText: new Date().toISOString(),
        copies: quest.copies.map((copy) => ({ ...copy, synchronized: false })),
      };
      break;
    }

    case REMOVE_ROUTE_POINT: {
      if (pointIndex > -1) {
        points.splice(pointIndex, 1);
        questOverload = {
          routes: points,
          lastModifiedText: new Date().toISOString(),
          copies: quest.copies.map((copy) => ({
            ...copy,
            synchronized: false,
          })),
        };
      }
      break;
    }

    case REARRANGE_ROUTE_POINTS: {
      const newIndex =
        typeof action.newPointId === 'string'
          ? findRoutePoint(points, action.newPointId, true)
          : points.length;
      const rearrangingPoint = points.splice(pointIndex, 1)[0];
      points.splice(newIndex, 0, rearrangingPoint);
      questOverload = {
        routes: points,
        lastModifiedText: new Date().toISOString(),
        copies: quest.copies.map((copy) => ({ ...copy, synchronized: false })),
      };
      break;
    }

    case MERGE_ROUTE_POINTS: {
      const siblings = action.pointIds
        .map((guid) => findRoutePoint(points, guid, true))
        .sort((a, b) => a - b);
      const events = siblings
        .map((i) => points[i])
        .reduce(
          (acc, p) => [...acc, ...p.events.filter((e) => !acc.includes(e))],
          []
        );
      points.splice(siblings[0], siblings.length, {
        ...points[siblings[0]],
        events,
      });
      questOverload = {
        routes: points,
        lastModifiedText: new Date().toISOString(),
        copies: quest.copies.map((copy) => ({ ...copy, synchronized: false })),
      };
      break;
    }

    case SPLIT_ROUTE_POINT: {
      const pointIndex = findRoutePoint(
        points,
        (point) => get(point, 'events', []).includes(action.event),
        true
      );
      const newPoints = splitRoutePointEvent(
        points[pointIndex],
        action.event,
        action.routePoint
      );
      if (newPoints.length) {
        points.splice(pointIndex, 1, ...newPoints);
        questOverload = {
          routes: points,
          lastModifiedText: new Date().toISOString(),
          copies: quest.copies.map((copy) => ({
            ...copy,
            synchronized: false,
          })),
        };
      }
      break;
    }

    case SEND_ROUTE_POINT: {
      const syncingRoute = state.syncingRoute || [];
      const { guid } = action.routePoint;
      const { purpose } = action;
      questOverload = {
        syncing: true,
        lastModifiedText: new Date().toISOString(),
        copies: quest.copies.map((copy) => ({ ...copy, synchronized: false })),
      };
      storeOverload.syncingRoute = [...syncingRoute, { guid, purpose }];
      break;
    }

    case SEND_ROUTE_POINT_FAILURE:
    case SEND_ROUTE_POINT_SUCCESS: {
      const syncingRoute = [...state.syncingRoute] || [];
      const { guid } = action.routePoint;
      const syncingIndex = syncingRoute.findIndex(
        (g) => g.guid === guid && g.purpose === action.purpose
      );
      if (syncingIndex > -1) {
        syncingRoute.splice(syncingIndex, 1);
        questOverload = {
          syncing: !!syncingRoute.length,
          lastModifiedText: new Date().toISOString(),
          copies: quest.copies.map((copy) => ({
            ...copy,
            synchronized: false,
          })),
        };
        storeOverload.syncingRoute = syncingRoute;
      }
      break;
    }
  }

  /**
   * Merging `questOverload` with current redux store state
   */
  if (questOverload) {
    return {
      ...state,
      ...storeOverload,
      quests: state.quests.map((questTmp) =>
        questTmp.id === action.questId
          ? { ...questTmp, ...questOverload }
          : questTmp
      ),
    };
  }

  return null;
}
