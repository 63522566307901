import React, { Component } from 'react';
import Checkbox from '../Checkbox';
import './InputLine.css';

class InputLine extends Component {
  render() {
    const {
      onInputChange,
      onKeyDown,
      onInputKeyDown,
      placeholder,
      defaultValue,
      onButtonClick,
      onCheckboxChange,
      checked,
      type,
      ...props
    } = this.props;
    return (
      <div className="InputLine__wrapper InputLine__wrapper--line">
        <input
          ref={(input) => this.props.onRef(input)}
          type="text"
          className="InputLine__input"
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          {...props}
        />
        <div className="InputLine__view" />
        {type === 'enter' ? (
          <button
            className="InputLine__button InputLine__button--enter"
            onClick={onButtonClick.bind(null, this)}
          />
        ) : (
          ''
        )}
        {type === 'checkbox' ? (
          <Checkbox
            checked={checked}
            className="Input__button Input__button--checkbox"
            onChange={onCheckboxChange.bind(null, this)}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default InputLine;
