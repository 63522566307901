import {
  ADD_ROUTE_POINT,
  SEND_ROUTE_POINT,
  EDIT_ROUTE_POINT,
  REMOVE_ROUTE_POINT,
  REARRANGE_ROUTE_POINTS,
  ADD_ROUTE_POINT_WITH_EVENT,
  RENAME_ROUTE_POINT_WITH_EVENT,
} from '../constants/ActionTypes';
import findRoutePoint from '../functions/route/findRoutePoint';
import { findQuest } from '../reducers/quests';
import { syncQuest } from './quests';

/**
 * Route point description
 * @typedef {Object} RoutePoint
 * @prop {String} id - id of the point generated by `guid()`
 * @prop {String} type - type of the point (`wpt` - describing route shape, `poi` - describing route standstill)
 * @prop {Array[Number]} events - indexes of the events bound to the point (for `point` type only)
 * @prop {String} name - name of the point (for `point` type only)
 * @prop {String} address - text address of the point (for `point` type only)
 * @prop {Number} radius - radius of the geolocation point detection (for identifying place by GPS, for `point` type only)
 * @prop {Object} location - location description
 * @prop {String} location.type - GPS location type (`"Point"`)
 * @prop {Array[Number]} location.coordinates - `lat` and `lng` of the point
 */

/**
 * Verifies `RoutePoint` objects by their type
 * @param {Array[RoutePoint]} route
 * @returns {Boolean}
 */
function verifyRoutePoints(route) {
  return route.every(
    ({ type, location }) =>
      ['point', 'waypoint'].includes(type) &&
      typeof location === 'object' &&
      typeof location.lat === 'number' &&
      typeof location.lng === 'number'
  );
}

/**
 * Check errors for available `targets` scenarios:
 * - `questId`: if quest with this id exists (`props` must contain `questId`)
 * - `pointerId`: if route point index is valid (`props` must contain `questId` and `pointId`)
 * - `point`: if route point has correct `type` and `location` props (`props` must contain `route`)
 *
 * @param {Object} props - props set for checking
 * @param {Number?} props.questId - id of the quest
 * @param {Number?} props.pointId - index of the route point
 * @param {Array[RoutePoint]?} props.route - route points description
 * @param {Array[String]} targets - what errors to check (`questId`, `pointId`)
 * @param {Function} getState - gets current Redux store state
 * @returns {Object|Null} - object with `type` prop, containing check type from `targets` if error occurred, `null` if no errors presented
 */
function hasErrors({ questId, pointId, route }, targets = [], getState) {
  for (let i = 0; i < targets.length; i += 1) {
    const target = targets[i];
    let errorText = null;

    switch (target) {
      case 'point': {
        if (verifyRoutePoints(route)) {
          errorText =
            'Type `RoutePoint` is incomplete, no `type` or `location` prop';
        }
        break;
      }
      case 'questId':
        if (!findQuest(getState().quests.quests, questId)) {
          errorText = `No quest with ID ${questId}`;
        }
        break;
      case 'pointId': {
        const quest = findQuest(getState().quests.quests, questId);
        const point = findRoutePoint(quest.routes, pointId);
        if (!quest || !point) {
          errorText = `No quest with ID ${questId} or route point №${pointId}`;
        }
        break;
      }
      case 'hasRoute':
        const quest = findQuest(getState().quests.quests, questId);
        if (!quest || !quest.routes) {
          errorText = `Quest with ID ${questId} not found or has no route points`;
        }
        break;
    }

    if (errorText) {
      console.error(errorText);
      return { type: target };
    }
  }

  return null;
}

/**
 * Wrapper for `syncQuest` call, can cancel syncing process call
 * @param {Function} dispatch - `dispatch` from Redux store
 * @param {Boolean} isSync - is it necessary to sync quest with backend
 * @param {Number} questId - id of the quest to sync
 */
function syncRoute(dispatch, isSync, questId) {
  if (isSync) {
    dispatch(syncQuest(questId));
  }
}

/**
 * Adds geopoint to the quest to the end of the `quest.routes[]`
 * @param {Object} $
 * @param {String} $.pointId - id of the point on which position new point will be added
 * @param {Number|*} $.questId - id of the quest where route point will be added
 * @param {RoutePoint} $.routePoint - route point description (`type` and `location` are obligatory)
 * @param {Boolean} $.isSync - should quest be refetched after all
 * @param {Boolean} $.isSend - should point be created only locally, or sent to the server
 */
export function addRoutePoint({
  pointId,
  questId,
  routePoint,
  isSync,
  isSend,
}) {
  questId = parseInt(questId, 10);
  routePoint.events = Array.isArray(routePoint.events) ? routePoint.events : [];

  return {
    type: ADD_ROUTE_POINT,
    pointId,
    questId,
    routePoint,
    isSend,
    isSync,
  };
}

/**
 * Dispatches `ADD_ROUTE_POINT_WITH_EVENT` saga, which
 * - adds event and route point bound to it
 * @param {Object} $ - will be passed to the saga
 */
export function addRoutePointWithEvent({ questId, ...action }) {
  questId = parseInt(questId, 10);
  return {
    type: ADD_ROUTE_POINT_WITH_EVENT,
    questId,
    ...action,
  };
}

/**
 * Dispatches `RENAME_ROUTE_POINT_WITH_EVENT` saga which renames event and route point
 * @param {Object} action
 * @param {String|*} action.questId - id of the quest containing quest to rename
 * @param {String} action.eventId - id of the event to rename (also similar with guid of the point)
 * @param {String} action.title - event title
 */
export function renameRoutePointWithEvent({ questId, eventId, title }) {
  questId = parseInt(questId, 10);
  return {
    type: RENAME_ROUTE_POINT_WITH_EVENT,
    questId,
    eventId,
    title,
    pointId: eventId,
  };
}

/**
 * Editing route point with new prop values of `RoutePoint`
 * Can split editing point, or merge it with nearest if `$.event` param given
 * @param {Object} $
 * @param {Number|*} $.questId - id of the quest where route point stored
 * @param {Object} $.event - additional data for changing in the event description
 * @param {...RoutePoint} $.routePoint - new values (with obligatory `guid` prop)
 * @param {Boolean} $.isSync - is it necessary to sync quest with backend now
 */
export function editRoutePoint({
  questId,
  isSync = true,
  event = {},
  ...routePoint
}) {
  questId = parseInt(questId, 10);
  return {
    type: EDIT_ROUTE_POINT,
    questId,
    pointId: routePoint.guid,
    event,
    routePoint,
    isSync,
  };
}

/**
 * Triggers route points removing from route points array
 * Calls `REMOVE_ROUTE_POINTS` saga
 * @param {Object} $
 * @param {Number|*} $.questId - id of the quest with route point to remove
 * @param {Number} $.pointId - index of the start route point to remove
 * @param {Boolean} $.isSync - is it necessary to sync quest with backend now
 * @param {Boolean} $.isSend - should point be removed only locally, or sent to the server
 */
export function removeRoutePoint({ questId, pointId, isSync, isSend }) {
  questId = parseInt(questId, 10);
  return {
    type: REMOVE_ROUTE_POINT,
    questId,
    pointId,
    routePoint: { guid: pointId },
    isSync,
    isSend,
  };
}

/**
 * Change route point position in the `route` array
 * @param {Object} $
 * @param {Number|*} $.questId - id of the quest
 * @param {Number} $.pointId - index of the route point which will be moved
 * @param {Number} $.newPointId - index where route point will be placed (if < `0` - into `0` position, if > current geopoints amount - to the end position)
 * @param {Boolean} $.isSync - is it necessary to sync quest with backend now
 */
export function rearrangeRoutePoints({
  questId,
  pointId,
  newPointId,
  isSync = true,
}) {
  questId = parseInt(questId, 10);
  return (dispatch, getState) => {
    if (!hasErrors({ questId, pointId }, ['pointId'], getState)) {
      dispatch({ type: REARRANGE_ROUTE_POINTS, questId, pointId, newPointId });
      syncRoute(dispatch, isSync, questId);
    }
  };
}

/**
 * Syncs route points with the server
 * @param {Object} $
 * @param {Number} $.questId - id of the quest to sync route points
 * @param {Boolean} $.isSync - should quest be refetched after all
 */
export function sendRoutePoints({ questId, isSync }) {
  questId = parseInt(questId, 10);
  return {
    type: SEND_ROUTE_POINT,
    questId,
    isSync,
  };
}
