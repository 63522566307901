import { latLng } from 'leaflet';

/**
 * Converts route points to geographical coordinates
 * @param {Array[RoutePoint]} route
 * @returns {LatLng}
 */
export default function routeToCoords(route) {
  return route.map(({ location: { coordinates: p } = {} }) =>
    latLng(p[0], p[1])
  );
}
