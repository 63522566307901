import React, { Component } from 'react';
import classNames from 'classnames';
import TextareaAutoresize from 'react-textarea-autosize';
import './Textarea.css';

class Textarea extends Component {
  render() {
    const {
      inputRef,
      className,
      noneDefaultStyles = false,
      ...props
    } = this.props;

    return (
      <TextareaAutoresize
        inputRef={inputRef}
        className={classNames({ Textarea: !noneDefaultStyles }, className)}
        {...props}
      />
    );
  }
}

Textarea.propTypes = {
  // TODO:
};

export default Textarea;
