import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ACCEPTABLE_TYPES } from '../../PictureEditor';
import useQueue from '../../../functions/queue/useQueue';

/**
 * Adds ability to use `PictureEditor` on the component containing `Dropzone`
 * @param {Object} $
 * @param {Function} $.onEdited - image was edited in editor
 */
export default function usePictureEditor({ onEdited: onEditedExt = () => {} }) {
  const { queue: editing, ...editingApi } = useQueue();
  const [amount, setAmount] = useState(0);

  /**
   * Calls when files were dropped in the `Dropzone`
   * @param {Array[Object]} metasUnsafe - file descriptions from `PictureEditor::onAdded`
   */
  const editImages = (metasUnsafe) => {
    const metas = metasUnsafe.reduce((acc, meta) => {
      if (ACCEPTABLE_TYPES.test(meta.file.type)) {
        return [...acc, meta];
      }
      onEditedExt([meta], false, false);

      return acc;
    }, []);
    editingApi.push(...metas);
    setAmount(metas.length + editing.length);
  };

  /**
   * Calls when files were edited and "save" button in `PictureEditor` was clicked
   * @param {File} file - file from `PictureEditor`
   * @param {Boolean} wasEdited - if the file was edited
   * @param {Boolean} wasClosed - if the editor was closed
   */
  const onEdited = (file, wasEdited, wasClosed) => {
    if (wasClosed) {
      editingApi.flush();
      return;
    }

    const meta = editingApi.take();
    onEditedExt([{ ...meta, file }], wasEdited, wasClosed);
  };

  return {
    editImages,
    editorProps: {
      title: (
        <FormattedMessage
          id="DropzonePictureEditor.title"
          defaultMessage="Photo {num}/{amount}"
          values={{ amount, num: amount - editing.length + 1 }}
        />
      ),
      imageData: editing[0] && editing[0].file,
      visible: !!editing.length,
      onEdited,
    },
  };
}
