/**
 * Finds route point in `route` store
 * @param {Array[RoutePoint]} route - points in the `route` store
 * @param {String|Function} selector - id of the route point, or functional parameter for `find` methon on `route` array
 * @param {Boolean} indexRequired - if position in array should be returned
 * @returns {RoutePoint|Number}
 */
export default function findRoutePoint(route, selector, indexRequired) {
  if (!(route instanceof Array)) return indexRequired ? -1 : undefined;
  return route[indexRequired ? 'findIndex' : 'find']((point) =>
    typeof selector === 'function' ? selector(point) : point.guid === selector
  );
}
