import React, { Component, useEffect, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import * as EventActions from '../../actions/events';
import * as UploadActions from '../../actions/uploads';
import {
  renameRoutePointWithEvent as renameRoutePointWithEventAction,
  removeRoutePoint as removeRoutePointAction,
} from '../../actions/route';
import { isMobile, isTablet } from '../../functions/queries';
import { getTommorow } from '../../functions/dateUtils';
import getEventDirections from '../../functions/event/getEventDirections';
import getEventStickers from '../../functions/event/getEventStickers';
import { textLimitExceeded } from '../../functions/validation/rules/rulesName';
import { makeGetCurrentEvent, makeGetUploadsByType } from '../../selectors';
import withPreview from '../App/AppPreviewContext';
import PopupRemoveEvent from '../Popup/withRemoveEvent';
import Event from '../Event';
import EventHeader from '../Event/EventHeader';
import EventControlsHeader from './EventControlsHeader';
import EventControlsName from './EventControlsName';
import EventControlsThisStep, {
  EventControlsThisStepTemplate,
} from './EventControlsThisStep';
import EventControlsHTML from './EventControlsHTML';
import EventControlsPictures from './EventControlsPictures';
import EventControlsAdditional from './EventControlsAdditional';
import EventControlsAudioGuideHelper from './EventControlsAudioGuideHelper';
import EventControlsAudio from './EventControlsAudio';
import '../../css-init/layout.css';
import './EventControls.css';

/**
 * Elevation of the `Paper` components with `EventControls...` components
 * Prop `elevation` of `MaterialUI::Paper` component
 * @type {Number}
 */
const CONTENT_PAPER_ELEVATION = 0;

function scrollToAnchor(anchor) {
  const anchorElement = document.querySelector(`#${anchor}`);
  // TODO: fix not enought scroll with height of workspace
  // TODO: scroll a little bit less because of header
  if (!anchorElement) return;
  if (anchor === 'triggers') {
    setTimeout(() => {
      anchorElement.scrollIntoView();
      // TODO: create dymamic scroll to element
    }, 0);
  } else {
    setTimeout(() => {
      anchorElement.scrollIntoView();
      window.scrollBy(0, -70); // scroll = 50 header height + 20 margin
    }, 0);
  }
}

function updateScrollTop(props) {
  const { anchor } = props;
  if (!anchor) {
    window.scroll(0, 0);
    return;
  }
  scrollToAnchor(anchor);
}

const EventContentSyncStatus = ({ content }) => {
  const [isSynchronized, setIsSynchronized] = useState(true);

  useEffect(() => {
    const audioContent = content.find((item) => item.contentType === 'audio');
    const htmlContent = content.find((item) => item.contentType === 'html');

    const checkSynchronization = () => {
      if (audioContent && htmlContent) {
        if (!htmlContent.lastModified) {
          setIsSynchronized(true);
        } else if (
          !audioContent.lastModified ||
          (audioContent.data && audioContent.data.length === 0)
        ) {
          setIsSynchronized(true);
        } else {
          setIsSynchronized(
            new Date(htmlContent.lastModified).getTime() <=
              new Date(audioContent.lastModified).getTime()
          );
        }
      } else {
        setIsSynchronized(true);
      }
    };

    checkSynchronization();
  }, [
    content.map((item) => item.lastModified).join(),
    content
      .map((item) => (item.contentType === 'audio' ? item.data : ''))
      .join(),
  ]);

  return (
    <div>
      {isSynchronized ? null : (
        <div className="EventControls__synchronized-audio">
          <FormattedMessage
            id="EventControls.updateAudio"
            defaultMessage="Differences with text, update audio"
          />
        </div>
      )}
    </div>
  );
};

/**
 * JSS styles for `EventControls` element
 * @type {React::Hook}
 */
const generateStyles = (theme) => ({
  block: {
    marginTop: '40px',
    padding: '24px',
    backgroundColor: theme.palette.background.main,
    '&:not(:last-child)': {
      marginBottom: '-15px',
    },
  },
});

class EventControls extends Component {
  constructor(props) {
    super(props);
    this.state = { isPopupOpen: false };
  }

  componentDidMount() {
    updateScrollTop(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { questId, eventId, anchor } = this.props;
    const {
      questId: nextQuestId,
      eventId: nextEventId,
      anchor: nextAnchor,
    } = nextProps;

    const needToUpdateScrollTop = [
      questId !== nextQuestId,
      eventId !== nextEventId,
      anchor !== nextAnchor,
    ].some(Boolean);

    if (needToUpdateScrollTop) {
      updateScrollTop(nextProps);
    }
  }

  getEventIds() {
    const { eventId, questId } = this.props;
    return { eventId, questId: parseInt(questId, 10) };
  }

  getMinTime() {
    const { prev } = this.props;
    if (prev && prev.trigger.time) {
      // TODO: plus day
      return prev.trigger.time;
    }
    return getTommorow(new Date()).getTime();
  }

  deletePin = (questId, eventId) => {
    const { eventActions } = this.props;

    eventActions?.deleteEvent({
      questId,
      eventId,
    });
    this.setState({ isPopupOpen: false });
  };

  renderNextEventButton() {
    const { next, event, questId } = this.props;
    const choice = event?.content.find((item) => item.contentType === 'choice');
    const isChoiceSet = choice ? choice.data.options.some(Boolean) : null;
    if (
      !next ||
      (!isChoiceSet &&
        !next.trigger.geo &&
        !next.trigger.time &&
        !next.trigger.ar)
    )
      return null;
    return (
      <Link to={`/quest/${questId}/events/${next.order}/`}>
        <div className="EventControls__next-link">
          <FormattedMessage
            id="EventControls.nextEventButtonText"
            defaultMessage="Next event"
          />
        </div>
      </Link>
    );
  }

  render() {
    const {
      className,
      classes: styles,
      prev,
      next,
      event,
      event: { id: eventId, content },
      routePoint,
      eventsAmount,
      eventActions,
      uploadActions,
      renameRoutePointWithEvent,
      removeRoutePoint,
      questId,
      isEditable,
      uploads,
      validationResults,
      isPreviewShown,
      togglePreview,
      user,
      quest: { products },
    } = this.props;
    let text;
    let html;
    let images;
    let audios;
    let choice;
    if (content) {
      text = content.find((item) => item.contentType === 'text') || null;
      html = content.find((item) => item.contentType === 'html') || null;
      images = content.find((item) => item.contentType === 'image') || null;
      audios = content.find((item) => item.contentType === 'audio') || null;
      choice = content.find((item) => item.contentType === 'choice') || null;
    }
    const hasAITourGroup = user.groups.includes('AI Tour');
    const isStaticEvent =
      event?.order === 0 || event?.order + 1 === eventsAmount;

    const ids = this.getEventIds();

    return (
      <div className={classNames('EventControls', className)}>
        <PopupRemoveEvent
          event={event}
          theme="material"
          isOpen={this.state.isPopupOpen}
          deleteItem={() => this.deletePin(questId, eventId)}
          toggleOpen={() =>
            this.setState((state) => ({ isPopupOpen: !state.isPopupOpen }))
          }
        />
        <Paper className={styles.block} elevation={CONTENT_PAPER_ELEVATION}>
          <EventControlsHeader
            className="EventControls__header"
            stepOrder={event?.order + 1}
            stepsAmount={eventsAmount}
            isPreview={isPreviewShown}
            onPreviewToggled={!isMobile() && togglePreview}
            onRemoveRequested={
              !isStaticEvent ? () => this.setState({ isPopupOpen: true }) : null
            }
            hasAITourGroup={hasAITourGroup}
            products={products}
            isTablet={isTablet()}
          />
          <EventControlsName
            title={event?.title}
            questId={questId}
            eventId={event?.id}
            isEditable={isEditable}
            onRename={renameRoutePointWithEvent}
          />
          {prev && next ? (
            <EventControlsThisStep
              questId={questId}
              eventId={event?.id}
              routePoint={routePoint}
              prevEventName={prev.title}
              directions={getEventDirections(event)}
              stickerUploads={uploads?.stickers}
              stickers={getEventStickers(event)}
              addStickers={uploadActions?.uploadBatch}
              abortStickers={uploadActions?.uploadAbort}
              removeStickers={eventActions?.removeEventSticker}
              reorderStickers={eventActions?.reorderEventSticker}
              prevButtonText={event?.prevButton}
              editEventDirections={eventActions?.editEventDirections}
              editEventSkip={eventActions?.editEventSkip}
              removeRoutePoint={removeRoutePoint}
            />
          ) : (
            <EventControlsThisStepTemplate
              hasPrevEvent={!!prev}
              hasNextEvent={!!next}
            />
          )}
          <div id="img">
            <EventControlsPictures
              params={ids}
              locale={user.locale}
              images={images ? images.data : []}
              descriptions={images ? images.captions : []}
              uploads={uploads?.image}
              onAdded={uploadActions?.uploadBatch}
              onAborted={uploadActions?.uploadAbort}
              onRemoved={eventActions?.removeEventImage}
              onRemovedAll={eventActions?.removeEventImages}
              onReordered={eventActions?.syncDragEventImage}
              onDescription={eventActions?.updateEventImageDescription}
            />
          </div>
          <div id="text">
            <Event>
              <EventHeader>
                <FormattedMessage
                  id="EventControls.html"
                  defaultMessage="Text of the audio guide"
                />
              </EventHeader>
              <EventControlsHTML
                params={ids}
                html={html ? html.data : ''}
                text={text ? text.data : ''}
                visible={html?.visible}
                {...validationResults.eventErrors.reduce((acc, e) => {
                  switch (e.title) {
                    case textLimitExceeded:
                      return { errorTextLength: true, ...acc };
                    default:
                      return acc;
                  }
                }, {})}
                onChange={eventActions?.editEventHTML}
              />
            </Event>
          </div>
          <div id="audio">
            <Event>
              <div className="EventControls__audio-block">
                <div>
                  <EventHeader>
                    <FormattedMessage
                      id="EventControls.audioGuide"
                      defaultMessage="Audio guide"
                    />
                  </EventHeader>
                  <EventControlsAudioGuideHelper locale={user.locale} />
                </div>
                <EventContentSyncStatus content={content} />
              </div>
              <EventControlsAudio
                params={ids}
                locale={user?.locale}
                uploads={uploads?.audio}
                audios={audios?.data}
                autoplay={audios?.autoplay}
                visible={audios?.visible}
                onAdd={uploadActions?.uploadBatch}
                onAbort={uploadActions?.uploadAbort}
                onChange={eventActions?.editEventAudios}
                onRemove={eventActions?.removeEventAudio}
              />
            </Event>
          </div>
          <div id="additional">
            <EventControlsAdditional
              questId={questId}
              eventId={eventId}
              choice={choice}
              eventActions={eventActions}
            />
          </div>
        </Paper>
        {!isEditable && <div className="EventControls__overlay" />}
      </div>
    );
  }
}

function makeMapStateToProps() {
  const getCurrentEvent = makeGetCurrentEvent();
  const mapStateToProps = (state, props) => {
    return {
      user: state.user,
      currentQuest: state.quest,
      event: getCurrentEvent(state, props),
      uploads: {
        audio: makeGetUploadsByType('audio')(state, props),
        image: makeGetUploadsByType('image')(state, props),
        video: makeGetUploadsByType('video')(state, props),
        imageAR: makeGetUploadsByType('imageAR')(state, props),
        stickers: makeGetUploadsByType('sticker')(state, props),
      },
      tutorial: state.tutorial,
    };
  };
  return mapStateToProps;
}

function mapDispatchToProps(dispatch) {
  return {
    eventActions: bindActionCreators(EventActions, dispatch),
    uploadActions: bindActionCreators(UploadActions, dispatch),
    ...bindActionCreators(
      {
        renameRoutePointWithEvent: renameRoutePointWithEventAction,
        removeRoutePoint: removeRoutePointAction,
      },
      dispatch
    ),
  };
}

export default withStyles(generateStyles, { withTheme: true })(
  withRouter(
    withPreview(
      connect(
        makeMapStateToProps,
        mapDispatchToProps
      )(injectIntl(EventControls))
    )
  )
);

export { EventControls as PureEventControls };
