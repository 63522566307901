/**
 * Generates template object for constructing event content of type 'image'
 * @returns {Object}
 */
export const spawnImageContent = () => ({
  contentType: 'image',
  data: [],
  captions: [],
  visible: true,
});

/**
 * Generates template object for constructing event content of type 'text'
 * @returns {Object}
 */
export const spawnTextContent = () => ({
  contentType: 'text',
  data: '',
  visible: false,
});

/**
 * Generates template object for constructing event content of type 'audio'
 * @returns {Object}
 */
export const spawnAudioContent = () => ({
  contentType: 'audio',
  data: [],
  html: '',
  visible: false,
  autoplay: true,
});

/**
 * Generates template object for constructing event content of type 'html'
 * @param {String?} html - initial text to add
 * @returns {Object}
 */
export const spawnHtmlContent = (html = `<p></p>`) => ({
  contentType: 'html',
  data: html,
  visible: false,
});

/**
 * Generates template object for constructing event content of type 'choice'
 * @returns {Object}
 */
export const spawnChoiceContent = () => ({
  contentType: 'choice',
  data: {
    answer: -1,
    options: [],
  },
  visible: false,
});

/**
 * Generates template object for constructing event content of type 'choice'
 * @returns {Object}
 */
export const spawnDirectionsContent = () => ({
  contentType: 'directions',
  data: [],
  visible: false,
});

/**
 * Generates template object for constructing event content of type 'sticker'
 * @returns {Object}
 */
export const spawnStickerContent = () => ({
  contentType: 'sticker',
  data: [],
  visible: true,
});
