import React, { Fragment, useRef, useState, useEffect } from 'react';
import { latLng } from 'leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { injectIntl } from 'react-intl';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { Polyline } from 'react-leaflet';
import { POINT_ID, EVENT_ID } from '../../../constants/routePoints/urlParams';
import { openStreetMap as ADDRESS } from '../../../constants/geocoding/addressLevels';
import messagesEvent from '../../../constants/events/intl';
import useUrlApi from '../../../functions/URL/useUrlApi';
import getGUID from '../../../functions/id/guid';
import logicalSelect from '../../../functions/array/logicalSelect';
import routeToCoords from '../../../functions/route/routeToCoords';
import appendPixelOffset from '../../../functions/map/appendPixelOffset';
import findEvent from '../../../functions/event/findEvent';
import findRoutePoint from '../../../functions/route/findRoutePoint';
import prevBoundEvent from '../../../functions/route/prevBoundEvent';
import useRouteSyncTracking from '../../../functions/route/useRouteSyncTracking';
import Map from '../../Map';
import MapPopup from '../../Map/MapPopup';
import withPinRadius from '../../Map/withPinRadius';
import MapPinPure, { getIconSize } from '../../Map/MapPin';
import usePanOnMount from '../../Map/usePanOnMount';
import usePinDrag from '../../Map/usePinDrag';
import useRoutePath from '../../Map/useRoutePath';
import RoutePointEditor from '../RoutePointEditor';
import './RouteMap.css';

/**
 * Extended `MapPin` with ability to change radius
 * @type {React::Component}
 */
const MapPin = withPinRadius(MapPinPure);

/**
 * Default coords shown on route map
 * These are coords of Moscow
 * @type {Object}
 */
const DEFAULT_CENTER = { lon: 37.617592, lat: 55.751322 };

/**
 * View with map for selecting tour route
 * Has URL API:
 * - query param `${constants::POINT_ID}` changes currently editing route point
 * @param {Object} $
 * @param {String} $.className - additional CSS class
 * @param {String} $.questId - id of the quest route points related to
 * @param {Array[RoutePoint]} $.points - route points description
 * @param {Array[Object]} $.events - quest events
 * @param {Array[String]} $.syncingPoints - guids of the currently syncing points
 * @param {Function?} $.onAdded - new route point was created
 * @param {Function?} $.onEdited - route point was edited
 */
export default injectIntl(function RouteMap({
  className,
  questId,
  points = [],
  events: questEvents = [],
  syncingPoints = [],
  onAdded = () => {},
  onEdited = () => {},
  intl,
  setIsPopupOpen,
  isPopupOpen,
  setCurrentPointId,
}) {
  /**
   * Ref to guid of the currently editing point in the `pointsWithNew[]` array
   * @type {String|Null}
   */
  const editingPointRef = useRef(null);
  const setEditingPoint = (guid) => {
    editingPointRef.current = guid;
  };

  /**
   * Description of the new selected point
   * If contains `guid` - new point is being added
   * `null` if no point selected
   * @type {RoutePoint|Null}
   */
  const [newPoint, _setNewPoint] = useState(null);
  const pointDataRef = useRef();
  const setNewPoint = (pointData) => {
    pointDataRef.current = pointData;
    _setNewPoint(pointData);
    setEditingPoint(get(pointData, 'guid', null));
  };

  /**
   * Guid of the currently editing point in the `pointsWithNew[]` array
   * @type {String|Null}
   */
  const editingPoint = get(newPoint, 'guid', null);

  /**
   * If current editing point is syncing
   * @type {Boolean}
   */
  const isSyncingAdding = !!syncingPoints.find(
    ({ guid, purpose }) => guid === editingPoint && purpose === 'add'
  );

  /**
   * Initializing geocoder bound to the component
   */
  const geocoder = useRef();
  useEffect(() => {
    geocoder.current = new OpenStreetMapProvider({
      params: { addressdetails: 1 },
    });
  }, []);

  /**
   * Fetches address of the route point with data stored in `pointDataRef`
   * @param {String} pointId - guid of the point to fetch (for preventing race conditions)
   * @param {Boolean} updateState - reset `newPoint.address` before fetching
   * @param {Object} otherPoint - passed with `updateState = false` to update address of point which is not in state
   * @returns {String|Null} - added to `newPoint` address, or `null` if error ocurred
   */
  const fetchAddress = async (pointId, updateState, otherPoint) => {
    const pointData = otherPoint || get(pointDataRef, 'current', {});
    const coords = get(pointData, 'location.coordinates');

    if (pointData.address) {
      if (updateState) setNewPoint(pointData);
      return pointData.address;
    }

    if (coords && geocoder.current) {
      if (updateState) {
        setNewPoint({ ...pointData, address: undefined });
      }

      try {
        const locations = await geocoder.current.search({
          query: `${coords[0]},${coords[1]}`,
        });
        let address = logicalSelect(get(locations, '0.raw.address'), [
          ADDRESS.place,
          ADDRESS.building,
          ['road', [ADDRESS.settlementPartH], [ADDRESS.settlementPartL]],
          ['city', [ADDRESS.settlement, 'state']],
          'country',
        ]).join(', ');
        if (address.length > 200) {
          address = `${address.slice(0, 196)}...`;
        }
        if (pointId === editingPointRef.current && locations.length) {
          setNewPoint({ ...pointData, address });
        }
        return address;
      } catch (error) {
        setNewPoint({ ...pointData, address: false });
        return null;
      }
    }
    return null;
  };

  /**
   * Changing coordinates of the route point in `MapPin` functional
   * @type {Object}
   * @prop {Boolean} isDragging - is pin in the dragging process
   * @prop {...Object} pinDragProps - props to be passed to the pin
   */
  const { isDragging, ...pinDragProps } = usePinDrag({
    onDragFinished: async (coordinates) => {
      const pointData = get(pointDataRef, 'current', {});

      if (coordinates) {
        fetchAddress(editingPoint, true, {
          ...pointData,
          address: undefined,
          location: { ...pointData.location, coordinates },
        });
      }
    },
  });

  /**
   * Unified `points` from store and new point
   * For reducing amount of JSX
   * @type {Array[RoutePoint]}
   */
  const pointsWithNew = [...points];
  if (newPoint && editingPoint) {
    const editingIndex = points.findIndex(({ guid }) => guid === editingPoint);
    if (editingIndex > -1) {
      pointsWithNew[editingIndex] = {
        ...newPoint,
        guid: editingPoint,
      };
    } else {
      const order =
        typeof newPoint.order === 'number'
          ? newPoint.order
          : pointsWithNew.length;
      pointsWithNew.splice(order, 0, { ...newPoint, guid: editingPoint });
    }
  }

  /**
   * Initial data of the edited point
   * @type {Object?}
   */
  const editingOrigin = newPoint
    ? points.find((p) => p.guid === editingPoint)
    : undefined;
  const changedValues = ['location.coordinates', 'radius'].filter((val) => {
    const prev = get(editingOrigin, val);
    const curr = get(newPoint, val, Array.isArray(prev) ? [] : undefined);
    return editingOrigin && !isEqual(prev, curr);
  });

  /**
   * Ref to root `Map` component
   * @type {React::Ref}
   */
  const mapAPIRef = useRef();
  const setMapAPIRef = (mapAPI) => {
    mapAPIRef.current = mapAPI;
  };

  /**
   * Ref to instance of vanilla leaflet js map component
   * @type {Object}
   */
  const leafletRef = useRef();

  /**
   * Pseudo ref to the Map `.MapSearchControl` element
   * Sets with `setSearchElement` function
   * @type {React::Ref}
   */
  const [searchRef, _setSearchRef] = useState({ current: null });
  const setSearchElement = (searchElement) => {
    _setSearchRef({ current: searchElement });
  };

  /**
   * Size of `MapPin`
   * @type {String}
   */
  const mapPinSize = 'big';

  /**
   * Offset for the map center coords in pixels
   * @type {Object|Null}
   */
  const panOffset = get(searchRef, 'current')
    ? {
        x: 0,
        y: -(searchRef.current.clientHeight + getIconSize(mapPinSize)[1]),
      }
    : null;

  /**
   * Coords of points currently shown on `RouteMap`
   * @type {Array[LatLng]}
   */
  const pointsCoords = routeToCoords(pointsWithNew);

  /**
   * `ReactLeaflet::LeafletMap` was created
   * @param {Object} leafletMap - instance of vanilla leaflet js map component
   */
  const setLeafletRef = (leafletMap) => {
    leafletRef.current = leafletMap;
  };

  /**
   * Installing URL API for creating point on load with specified event bounding via URL param `${EVENT_ID}`
   * Will be reset in `this::editRoutePoint()` when point will be added
   */
  const eventIdToBoundRef = useRef();
  useUrlApi({
    key: EVENT_ID,
    urlTemplate: `/quest/${questId}/map/`,
    stateValue: eventIdToBoundRef.current
      ? String(eventIdToBoundRef.current)
      : '',
    onParamChanged: (eventId) => {
      eventIdToBoundRef.current = eventId;
      if (mapAPIRef.current) {
        mapAPIRef.current.togglePositionSelection();
      }
    },
  });

  /**
   * Processes new point info by pushing in store and updating with address
   * @param {MapPoint} $
   */
  const newRoutePoint = async ({ address, ...coords }) => {
    const boundEventId = eventIdToBoundRef.current;
    const event = boundEventId ? findEvent(questEvents, boundEventId) : null;

    const pointId = event ? event.id : getGUID();
    const defaultName = event
      ? event.title
      : intl.formatMessage(messagesEvent.new);
    const storedPointData = {
      address,
      name: defaultName,
      guid: pointId,
      order: boundEventId
        ? prevBoundEvent(event, questEvents, points).routePoint + 1
        : undefined,
      location: { type: 'Point', coordinates: [coords.lat, coords.lon] },
      radius: 25,
      type: 'poi',
    };

    eventIdToBoundRef.current = null;
    setNewPoint(storedPointData);

    onAdded(pointId, {
      ...storedPointData,
      name: get(pointDataRef, 'current.name') || defaultName,
    });
  };

  /**
   * Resets editing process:
   * - deletes info about new route point
   * - resets guid of the editing point to `null`
   * - closes popup as the result of the performed actions
   */
  const resetEditing = () => {
    setNewPoint(null);
  };

  /**
   * Starts editing process
   * @param {String} guid - editing point guid
   */
  const editRoutePoint = (guid) => {
    const point = points.find((p) => p.guid === guid);

    if (point) {
      setNewPoint({ ...point });
    }
  };

  /**
   * Dispatches route point added or edited event
   * If `newPoint` has `guid` prop - it is editing process, not adding
   * @param {String} name - route point name
   * @param {String?} description - route point descrption
   */
  const finishEditing = (name, description) => {
    onEdited(editingPoint, { ...newPoint, name, description });
    setNewPoint({ ...newPoint, name });
  };

  /**
   * Passing current editing point ID up through `setCurrentPointId` callback
   */
  useEffect(() => {
    if (editingPoint) {
      setCurrentPointId(editingPoint);
    }
  }, [editingPoint]);

  /**
   * Panning to the newly created point
   * When `RoutePointEditor` appears
   */
  useEffect(() => {
    if (editingPoint) {
      const newCenter = get(newPoint, 'location.coordinates');
      const map = leafletRef.current;
      if (newCenter && map) {
        map.panTo(
          appendPixelOffset(map, latLng(newCenter[0], newCenter[1]), panOffset)
        );
      }
    }
  }, [editingPoint, searchRef]);

  /**
   * Constructing path between route points
   * @prop {Array[latLng]} path - path between route points including new one
   */
  const { path } = useRoutePath({ points: pointsCoords });

  /**
   * Installing URL API for managing currently editing point via URL param `${POINT_ID}`
   */
  useUrlApi({
    key: POINT_ID,
    urlTemplate: `/quest/${questId}/map/`,
    stateValue: editingPoint ? String(editingPoint) : '',
    onParamChanged: (pointId) => editRoutePoint(pointId),
  });

  /**
   * Fitting all route points on screen when `RouteMap` was mounted in DOM and `panOffset` appeared
   * Triggers only one time
   */
  usePanOnMount({
    disableOnStart: !!editingPointRef.current || !points.length,
    leafletMap: leafletRef.current,
    points: pointsCoords,
    trigger: panOffset !== null,
    fitOptions: {
      paddingTopLeft: panOffset
        ? [panOffset.x + 20, -panOffset.y + 20]
        : [0, 0],
      paddingBottomRight: [20, 20],
    },
  });

  /**
   * Fetching address when point adding process finished
   */
  useRouteSyncTracking({
    syncingRoute: syncingPoints,
    onChanged: async ({ guid, purpose }, wasAdded) => {
      const addedPoint = findRoutePoint(points, guid);
      if (addedPoint && wasAdded === false && purpose === 'add') {
        onEdited(guid, {
          ...addedPoint,
          address: await fetchAddress(guid, false, addedPoint),
        });
      }
    },
  });

  /**
   * No x-axis offset
   * Coefficient `+ 20` in `tooltipOffset[1]` for overloading marker top point offset
   */
  const editingPinSize = getIconSize('big', newPoint ? 'red' : null);
  const tooltipOffset = [0, (editingPinSize[1] + 20) * -1];

  return (
    <div
      className={classNames(
        'RouteMap',
        className,
        editingPoint && 'RouteMap--editing'
      )}
    >
      <Map
        forceRefUpdate
        searchRef={setSearchElement}
        theme="material"
        coords={DEFAULT_CENTER}
        panOffset={panOffset}
        leafletMap={{
          zoomSnap: 0,
          wheelDebounceTime: 80,
          scrollWheelZoom: true,
          closePopupOnClick: false,
        }}
        positions={{
          zoom: 'bottomleft',
          geo: 'bottomleft',
          pos: 'topright',
          search: 'topleft',
        }}
        zoom={13}
        onMapAPI={setMapAPIRef}
        onLeafletRef={setLeafletRef}
        onSelect={newRoutePoint}
      >
        <Polyline positions={path} />
        {pointsWithNew.map(
          ({ guid, location: { coordinates } = {}, ...point }, i) => (
            <Fragment key={guid}>
              <MapPin
                size={mapPinSize}
                coords={{ lat: coordinates[0], lng: coordinates[1] }}
                label={i + 1}
                radius={point.radius}
                theme={guid === editingPoint ? 'red' : 'default'}
                draggable={guid === editingPoint && !isSyncingAdding}
                circleProps={{
                  active: guid === editingPoint && !isDragging,
                  min: 25,
                  max: 150,
                }}
                leafletMap={leafletRef.current}
                onRadiusChanged={(radius) =>
                  setNewPoint({ ...newPoint, radius })
                }
                onClick={
                  guid !== editingPoint ? () => editRoutePoint(guid) : () => {}
                }
                {...pinDragProps}
              />
              {guid === editingPoint && !isDragging ? (
                <MapPopup
                  visible
                  className="RouteMap__tooltip"
                  position={coordinates}
                  offset={tooltipOffset}
                  closeButton={false}
                  autoPan={false}
                  maxWidth={333}
                  minWidth={333}
                >
                  <RoutePointEditor
                    name={newPoint.name}
                    address={point.address}
                    allowSave={changedValues.length}
                    locked={isSyncingAdding}
                    intl={intl}
                    setIsPopupOpen={setIsPopupOpen}
                    isPopupOpen={isPopupOpen}
                    editingPoint={editingPoint}
                    onCancelled={resetEditing}
                    onRemoved={resetEditing}
                    onAdded={finishEditing}
                    onAddressRequested={() => fetchAddress(editingPoint, true)}
                  />
                </MapPopup>
              ) : null}
            </Fragment>
          )
        )}
      </Map>
    </div>
  );
});
