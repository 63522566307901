import getEventTitle from './getEventTitle';
import getEventImages from './getEventImages';
import getEventAudios from './getEventAudios';
import getEventText from './getEventText';

/**
 * Hash map for getting content from event by its type
 * Keys are event content types, values - functions for getting content of that type
 * @type {Object}
 */
const contentGetters = {
  title: getEventTitle,
  image: getEventImages,
  audio: getEventAudios,
  text: getEventText,
  html: getEventText,
};

/**
 * Gets amount of event content specified by type
 * Do not pass both content types `"text"` and `"html"` - they do the same thing, `"text"` is old
 * @param {Array[String]} contentTypes - types of content to find (`"title"`, `"image"`, `"audio"`, `"text"` and `"html"` are available, last to are same)
 * @param {Object} event - event description from redux store
 * @returns {Object} - keys are contentTypes, values are amount of content of suitable type
 */
export default function getEventInfo(contentTypes = [], event) {
  return contentTypes.reduce((acc, contentType) => {
    const item = contentGetters[contentType](event);
    if (Array.isArray(item)) {
      return { ...acc, [contentType]: item.length };
    } else if (['text', 'html'].includes(contentType)) {
      return { ...acc, [contentType]: Number(!['', '<p></p>'].includes(item)) };
    }
    return { ...acc, [contentType]: Number(!!item) };
  }, {});
}
