import { takeEvery, put, call } from 'redux-saga/effects';
import Api from '../functions/Api';
import * as types from '../constants/ActionTypes';

export function* fetchCurrencies(action) {
  try {
    const { data } = yield call(Api.get, 'v2/currencies/', {
      locale: action.locale,
    });

    yield put({
      type: types.FETCH_CURRENCIES_SUCCESS,
      payload: data,
    });
    yield put({
      type: types.ADD_CURRENCIES_FOR_USER_STATE,
      data,
    });
  } catch (error) {
    yield put({
      type: types.FETCH_CURRENCIES_FAILURE,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export default function* watchCurrencies() {
  yield takeEvery(
    [types.FETCH_CURRENCIES, types.CHANGE_LANGUAGE_SUCCESS],
    fetchCurrencies
  );
}
