import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import './PricingRadio.css';

class PricingRadio extends Component {
  getPriceClassName(price) {
    let className = 'PricingRadio__price';
    if (price > 0) className += ' PricingRadio__price--rubles';
    return className;
  }

  render() {
    const {
      name,
      value,
      checked,
      price,
      caption,
      currency: { code },
      maxPlayers,
      maxEventsCount,
      isEditableAfterPublish,
      onChange,
      className,
    } = this.props;
    return (
      <label className="PricingRadio__wrapper">
        <input
          type="radio"
          className={classNames(
            'PricingRadio__radio',
            checked && 'PricingRadio__radio--checked'
          )}
          name={name}
          value={value}
          onChange={onChange}
        />
        <div className={classNames('PricingRadio__label', className)}>
          <div className="PricingRadio__caption">
            <FormattedMessage
              id="PricingRadio.eventsLimit"
              defaultMessage="{maxEventsCount, plural, =0 {Unlimited number of event} =1 {Up to 1 event} other { Up to # events }}"
              values={{
                maxEventsCount,
              }}
            />
          </div>
          <div className="PricingRadio__caption">
            <FormattedMessage
              id="PricingRadio.playersLimit"
              defaultMessage="{maxPlayers, plural, =0 {Unlimited number of players} =1 {One player} other {# players }}"
              values={{
                maxPlayers,
              }}
            />
          </div>
          {isEditableAfterPublish && (
            <div className="PricingRadio__caption PricingRadio__caption--edit">
              <FormattedMessage
                id="PricingRadio.editing"
                defaultMessage="Editing"
              />
            </div>
          )}
          <div className="PricingRadio__price">
            {price === 0 ? (
              <FormattedMessage
                id="PricingRadio.freePlan"
                defaultMessage="Free"
              />
            ) : (
              <FormattedNumber
                value={price}
                style="currency"
                currency={code}
                currencyDisplay="symbol"
                minimumFractionDigits={0}
                maximumFractionDigits={2}
              />
            )}
          </div>
        </div>
      </label>
    );
  }
}

export default PricingRadio;
