import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { LogoutIcon, CirclesIcon, ArrowHideIcon } from '../Icon';
import UserMenu from '../UserMenu';
import { FirstSteps } from '../../constants/tutorials/TutorialsName';
import Avatar from '../Avatar';
import { Mobile, Desktop, Tablet } from '../../functions/queries';
import './User.css';
import nullAsDefault from '../../functions/nullAsDefault';

const Balance = ({ user: { client } }) => {
  if (!client) return null;
  const { maxDownloads, downloads } = client;
  return (
    <div className="User__balance">
      <span
        className="User__balance-status"
        style={{ width: `${(downloads / maxDownloads) * 100}%` }}
      />
      <span className="User__balance-label">
        {downloads}{' '}
        <FormattedMessage
          id="User.balanceLabel"
          defaultMessage="{downloads, plural, =1 {download} other {downloads}} / {maxDownloads}"
          values={{
            downloads,
            maxDownloads,
          }}
        />
      </span>
    </div>
  );
};

class User extends Component {
  state = {
    isActionsOpened: false,
  };

  componentDidMount() {
    window.document.addEventListener('click', this.handleClick);
  }

  handleClick = (event) => {
    if (this.user && this.user.contains(event.target)) return;
    this.setState({ isActionsOpened: false });
  };

  componentWillUnmount() {
    window.document.removeEventListener('click', this.handleClick);
  }

  toggleActions = () => {
    const {
      tutorial: { name: tutorialName, run: isTutorialRun, stepIndex } = {},
      tutorialActions: { updateIndex } = {},
    } = this.props;

    this.setState((prev) => ({ isActionsOpened: !prev.isActionsOpened }));

    if (tutorialName === FirstSteps) {
      return isTutorialRun && stepIndex === 1
        ? updateIndex({ stepIndex: 2 })
        : null;
    }
  };

  renderUserMobile() {
    const { isActionsOpened } = this.state;
    const {
      className,
      closeSidebar,
      isResizeBlock,
      statuses: [[statusSlug, statusText] = []] = [],
      user,
      user: { email = '', firstName, lastName },
      userActions: { logoutUser },
    } = this.props;
    const scheme = statusSlug === 'creator' ? '' : 'light';
    return (
      // TODO: replace with Link when complete
      <div
        ref={(u) => (this.user = u)}
        className={classNames('User', className && `${className}`)}
      >
        <div className="User__block">
          <div className="User__personality">
            <span className="User__name">
              {/* <AvatarMock email={email} className="User__avatar" /> */}
              {firstName && lastName ? `${firstName} ${lastName}` : null}
            </span>
            <span className="User__email">{email}</span>
          </div>
          <LogoutIcon mod={scheme} onClick={logoutUser} />
        </div>
        <div
          className={classNames(
            'User__block',
            isResizeBlock && 'User__block--hide'
          )}
        >
          <div className="User__status">
            <FormattedMessage
              id="User.statusText"
              defaultMessage="{statusText}"
              values={{
                statusText,
              }}
            />
          </div>
          <div className="User__menu-icon" onClick={this.toggleActions}>
            {isActionsOpened ? (
              <ArrowHideIcon mod={scheme} />
            ) : (
              <CirclesIcon mod={scheme} />
            )}
          </div>
          <UserMenu
            active={isActionsOpened}
            logoutUser={logoutUser}
            closeSidebar={closeSidebar}
            user={user}
          />
        </div>
      </div>
    );
  }

  isInWorkspace(props) {
    const { currentQuest = null } = nullAsDefault(this.props);
    return currentQuest && !isEmpty(currentQuest);
  }

  renderUserDesktop() {
    const { isActionsOpened } = this.state;
    const {
      className,
      tutorial: { name: tutorialName, stepIndex } = {},
      userActions: { logoutUser },
      user,
      user: { email, firstName, lastName, avatar },
    } = this.props;

    if (this.isInWorkspace()) return;

    const userName = (
      <span className="User__name">
        {firstName && lastName ? `${firstName} ${lastName}` : email}
        <Balance user={user} />
      </span>
    );
    return (
      <Tablet>
        {(isTablet) => (
          <div
            ref={(u) => (this.user = u)}
            className={classNames(
              'User',
              (isActionsOpened ||
                (tutorialName === FirstSteps && stepIndex === 2)) &&
                'User--active',
              className
            )}
            onClick={this.toggleActions}
          >
            <Avatar className="User__avatar" email={email} avatar={avatar} />
            {!isTablet ? userName : null}
            <UserMenu
              userName={isTablet ? userName : null}
              active={
                isActionsOpened ||
                (tutorialName === FirstSteps && stepIndex === 2)
              }
              logoutUser={logoutUser}
              user={user}
            />
          </div>
        )}
      </Tablet>
    );
  }

  render() {
    return (
      <Fragment>
        <Mobile>{this.renderUserMobile()}</Mobile>
        <Desktop>{this.renderUserDesktop()}</Desktop>
      </Fragment>
    );
  }
}

User.propTypes = {
  user: PropTypes.object.isRequired,
  userActions: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default User;
