import React, { useEffect, useRef } from 'react';
import get from 'lodash/get';
import { Popup } from 'react-leaflet';

/**
 * Popup on the map
 * Extends `ReactLeaflet::Popup` with ability to set visibility via appropriate prop
 * !!! Do not use as the child of `ReactLeaflet::Marker` or `MapPin` - will be toggled when clicked on marker by default
 * @param {Object} $
 * @param {Boolean} $.visibility - if popup shown
 * @param {...Object} $.popupProps - options for the `Leaflet::Popup` through the `ReactLeaflet::Popup`
 */
export default function MapPopup({ visible, ...popupProps }) {
  /**
   * Ref to `ReactLeaflet::Popup` for accessing leaflet element
   * @type {React::Ref}
   */
  const popupRef = useRef();

  /**
   * Shows/hides popup in accordance with `visibility` prop
   */
  const toggleVisibility = () => {
    const leafletPopup = get(popupRef, 'current.leafletElement');
    if (leafletPopup) {
      if (visible) {
        leafletPopup.openPopup();
      } else {
        leafletPopup.closePopup();
      }
    }
  };

  /**
   * Toggles popup visibility when `visibility` prop changed
   * And when component was mounted, if `visibility` is true
   */
  useEffect(() => toggleVisibility(), [visible]);
  useEffect(() => toggleVisibility(), []);

  return <Popup ref={popupRef} {...popupProps} />;
}
