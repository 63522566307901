import React, { Component, Fragment } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import HelmetTitle from '../HelmetTitle';
import Loader from '../Loader';

const messages = defineMessages({
  login: {
    id: 'Authenticated.login',
    defaultMessage: 'Log in',
  },
  logout: {
    id: 'Authenticated.logout',
    defaultMessage: 'Log out',
  },
});

class Authenticated extends Component {
  componentWillReceiveProps(nextProps) {
    const { user } = nextProps;
    if (!user.isLogged && !user.isFetching) {
      window.location.href = `/logout/`;
    }
  }

  render() {
    const {
      user: { isLogged, isLogOuting },
    } = this.props;

    if (!isLogged)
      return (
        <Loader active>
          {isLogOuting ? (
            <FormattedMessage {...messages.logout} />
          ) : (
            <FormattedMessage {...messages.login} />
          )}
        </Loader>
      );

    return (
      <Fragment>
        <HelmetTitle message={messages.login} />
        {this.props.children}
      </Fragment>
    );
  }
}

export default injectIntl(Authenticated);
