const regexIsNumber = /^-?\d+(?:\.{0,1}\d{0,2})$/;
const regexIsPositiveNumber = /^\d+(?:\.{0,1}\d{0,2})$/;

/**
 * Checks if string is number with 2 numbers after floating dot
 * @param {String} value - number in string
 * @return {Boolean} - if string is correct number
 */
export const isStringPositiveNumber = (string) => {
  return regexIsPositiveNumber.test(string);
};
