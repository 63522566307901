/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import loadImage from 'blueimp-load-image';
import { DEFAULT_TYPE } from '..';
import getFileName from '../../../functions/file/getFileName';
import getFileFormat from '../../../functions/file/getFileFormat';
import imageToCanvas from '../../../functions/img/imageToCanvas';

/**
 * Adds ability to use `Blob` and `File` types as input/output of the `PictureEditor`
 * @param {React::Component} Component - component class to overload
 */
export default function withBlobInput(Component) {
  /**
   * `PictureEditor` with ability to load images from `File` or `Blob` type
   * @param {Object} $
   * @param {File} $.imageData - image file
   * @param {Function} $.onEdit - first argument will be converted to file
   * @param {...Object} $.editorProps - props will be passed to `PictureEditor`
   */
  return function ({
    imageData: file,
    onEdited: onEditedExt = () => {},
    ...editorProps
  }) {
    const [base64, setBase64] = useState();

    const onEdited = (img, wasEdited, wasClosed) => {
      if (!wasEdited || wasClosed) {
        onEditedExt(file, wasEdited, wasClosed);
        return;
      }

      imageToCanvas(img).then((canvas) =>
        canvas.toBlob(
          (blob) => {
            blob.lastModifiedDate = new Date();
            blob.name = `${getFileName(file.name)}.${getFileFormat(file.name)}`;
            onEditedExt(blob, wasEdited, wasClosed);
          },
          file.type,
          0.4
        )
      );
    };

    /**
     * Converting images to base64
     */
    useEffect(() => {
      if (file instanceof Blob) {
        loadImage(file, (canvas) => setBase64(canvas.toDataURL(file.type)), {
          canvas: true,
          orientation: true,
        });
      }
    }, [file]);

    return (
      <Component
        imageData={base64}
        fileType={file ? file.type : DEFAULT_TYPE}
        onEdited={onEdited}
        {...editorProps}
      />
    );
  };
}
