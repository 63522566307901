import React, { Component } from 'react';
import classNames from 'classnames';
import './HamburgerIcon.css';

class HamburgerIcon extends Component {
  render() {
    const { isSidebarOpen, toggleSidebar } = this.props;
    return (
      <div className="Hamburger__block" onClick={toggleSidebar}>
        <div
          className={classNames(
            'Hamburger__icon',
            isSidebarOpen && 'Hamburger__icon--cross'
          )}
        >
          <span className="Hamburger__icon--line Hamburger__icon--line1" />
          <span className="Hamburger__icon--line Hamburger__icon--line2" />
          <span className="Hamburger__icon--line Hamburger__icon--line3" />
        </div>
      </div>
    );
  }
}

export default HamburgerIcon;
