import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import isEqual from 'lodash/isEqual';
import DataListWrapper from '../../functions/DataListWrapper';
import SortHeaderCell, { SortTypes } from '../SortHeaderCell';
import Form, * as validations from '../Form';
import SmartInput from '../SmartInput';
import CodesBalance from '../CodesBalance';
import Button from '../Button';
import HelpTooltip from '../HelpTooltip';
import { codeItem } from '../../functions/tableItems';
import '../StatsTable/StatsTable.css';
import './CodesTable.css';

class CodesTable extends Component {
  constructor(props) {
    super(props);

    this._defaultIndexes = [...Array(props.codes.length)].map(
      (_, index) => index
    );
    this._dataList = this.getCleanRows(props);

    this.state = {
      colSortDirs: {},
      rows: new DataListWrapper(this._defaultIndexes, this._dataList),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { questId, codes = [] } = this.props;
    const { questId: nextQuestId, codes: nextCodes = [] } = nextProps;

    const rowsNeedsUpdate = [
      questId !== nextQuestId,
      codes.length !== nextCodes.length,
      !isEqual(codes, nextCodes),
    ].some(Boolean);

    if (!rowsNeedsUpdate) {
      return;
    }

    this._dataList = this.getCleanRows(nextProps);

    this.setState({
      rows: new DataListWrapper(
        [...Array(nextCodes.length)].map((_, index) => index),
        this._dataList
      ),
    });
  }

  renderHead() {
    const headers = [
      [
        'accessCode',
        <Fragment>
          <FormattedMessage
            id="CodesTable.accessCode"
            defaultMessage="Access code"
          />
          <HelpTooltip id="acht">
            <FormattedMessage
              id="CodesTable.accessCodeHelpTooltip"
              defaultMessage="Unique code for this tour. To start enter it on the app."
            />
          </HelpTooltip>
        </Fragment>,
      ],
      [
        'maxPlayers',
        <Fragment>
          <FormattedMessage
            id="CodesTable.maxPlayers"
            defaultMessage="Max players"
          />
          <HelpTooltip id="mpht">
            <FormattedMessage
              id="CodesTable.maxPlayersHelpTooltip"
              defaultMessage="How many unique accounts can use this access"
            />
          </HelpTooltip>
        </Fragment>,
      ],
      [
        'downloads',
        <Fragment>
          <FormattedMessage
            id="CodesTable.downloaded"
            defaultMessage="Downloaded"
          />
          <HelpTooltip id="dht">
            <FormattedMessage
              id="CodesTable.downloadedHelpTooltip"
              defaultMessage="How many people downloaded your tour by this access"
            />
          </HelpTooltip>
        </Fragment>,
      ],
    ];

    return (
      <thead>
        <tr className="StatsTable__head">
          {headers.map(([key, text]) => {
            const sortDir = this.state.colSortDirs[key];
            return (
              <SortHeaderCell
                key={key}
                columnKey={key}
                sortDir={sortDir}
                onSortChange={this.onSortChange}
              >
                {text}
              </SortHeaderCell>
            );
          })}
        </tr>
      </thead>
    );
  }

  updateCode = (id, maxPlayers) => {
    const { updateCode } = this.props;
    updateCode(id, { maxPlayers });
  };

  renderRow(options) {
    const {
      isSending,
      balance,
      recent,
      questId,
      maxDownloads,
      usedDownloads,
    } = this.props;

    const { id, index, accessCode, maxPlayers, downloads } = options;

    const greaterOrEqualThanSetIfFilled = validations.createValidationRule(
      'greaterOrEqualThanSetIfFilled',
      (value) => !value || value > maxPlayers - 1,
      true
    );
    const isRecentUpdated = recent.indexOf(accessCode) > -1;

    return (
      <Form
        instantlyValidate
        defaultValues={{
          maxPlayers,
        }}
        validations={{
          maxPlayers: [validations.notEmpty, greaterOrEqualThanSetIfFilled],
        }}
        render={({ getValue, setValue, getErrors, validate, isValid }) => {
          const nextMaxPlayers =
            parseInt(getValue('maxPlayers'), 10) || maxPlayers;
          const diff = Math.abs(maxPlayers - nextMaxPlayers);
          const changed = Boolean(diff);
          const focus = getValue('focus') || false;
          return (
            <Fragment key={index}>
              <tr
                className={classNames(
                  'StatsTable__row CodesTable__row',
                  isRecentUpdated && 'CodesTable__row--newely'
                )}
              >
                <td className="StatsTable__cell">
                  {codeItem([], accessCode)}
                  <span> </span>
                  <Link to={`/quest/${questId}/codes/${accessCode}`}>
                    <Button theme="main-light">
                      <FormattedMessage
                        id="CodesTable.copyShareLink"
                        defaultMessage="Copy invitation link"
                      />
                    </Button>
                  </Link>
                </td>
                <td className="StatsTable__cell CodesTable__code-cell">
                  <div className="CodesTable__justify-content">
                    <SmartInput
                      name="maxPlayers"
                      className="CodesTable__input"
                      placeholder={maxPlayers}
                      value={getValue('maxPlayers')}
                      errors={getErrors('maxPlayers')}
                      errorMessages={{
                        greaterOrEqualThanSetIfFilled: (
                          <FormattedMessage
                            id="CodesTable.errorMessage"
                            defaultMessage="You can only increase number"
                          />
                        ),
                      }}
                      onChange={setValue}
                      onFocus={setValue.bind(null, 'focus', true)}
                      onBlur={setValue.bind(null, 'focus', false)}
                    />
                    <Button
                      className="CodesTable__button"
                      theme="shadow"
                      loading={isSending}
                      readOnly={!isValid() || !changed}
                      style={{
                        visibility: focus || changed ? 'visible' : 'hidden',
                      }}
                      onClick={() => {
                        validate(true);
                        if (!isValid()) return;
                        this.updateCode(id, nextMaxPlayers);
                      }}
                    >
                      <FormattedMessage
                        id="CodesTable.updateButtonText"
                        defaultMessage="Update"
                      />
                    </Button>
                  </div>
                </td>
                <td className="StatsTable__cell">
                  {downloads === 0 ? '–' : downloads}
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  {isValid() && (
                    <CodesBalance
                      maxDownloads={maxDownloads}
                      usedDownloads={usedDownloads}
                      balance={balance}
                      nextDownloads={diff}
                    />
                  )}
                </td>
              </tr>
            </Fragment>
          );
        }}
      />
    );
  }

  getCleanRows(props) {
    const { codes } = props;
    return codes;
  }

  onSortChange = (columnKey, sortDir) => {
    const { rows } = this.state;
    const sortIndexes = rows._indexMap.slice();
    sortIndexes.sort((indexA, indexB) => {
      const valueA = this._dataList[indexA][columnKey];
      const valueB = this._dataList[indexB][columnKey];
      let sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }
      if (sortVal !== 0 && sortDir === SortTypes.ASC) {
        sortVal *= -1;
      }

      return sortVal;
    });

    this.setState({
      rows: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  };

  render() {
    const { rows } = this.state;
    return (
      <section className="StatsTable CodesTable">
        <div className="StatsTable__header">
          <h1 className="StatsTable__heading">
            <FormattedMessage
              id="CodesTable.title"
              defaultMessage="Access codes"
            />
          </h1>
        </div>

        <div className="StatsTable__inner">
          <table
            className="StatsTable__table CodesTable__table"
            cellSpacing={0}
          >
            {this.renderHead()}
            <tbody>
              {[...Array(rows.getSize())].map((_, index) => {
                return this.renderRow({ ...rows.getObjectAt(index), index });
              })}
            </tbody>
          </table>
        </div>
      </section>
    );
  }
}

CodesTable.propTypes = {
  questId: PropTypes.string.isRequired,
  balance: PropTypes.number.isRequired,
  codes: PropTypes.arrayOf(
    PropTypes.shape({
      accessCode: PropTypes.string.isRequired,
      maxPlayers: PropTypes.number.isRequired,
      downloads: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default CodesTable;
