import {
  CREATE_TUTORIAL,
  UPDATE_TUTORIAL_INDEX,
  CLOSE_TUTORIAL,
  FINISH_TUTORIAL,
  UPDATE_UNFINISHED_TUTORIALS_COUNT,
} from '../constants/ActionTypes';

export function createTutorial(action) {
  return {
    type: CREATE_TUTORIAL,
    name: action.name,
  };
}

export function closeTutorial(action) {
  return {
    type: CLOSE_TUTORIAL,
    name: action.name,
    skipped: true,
  };
}

export function updateIndex(action) {
  return { type: UPDATE_TUTORIAL_INDEX, stepIndex: action.stepIndex };
}

export function finishTutorial(action) {
  return {
    type: FINISH_TUTORIAL,
    name: action.name,
    finished: true,
  };
}

export function updateUnFinishedTutorialsCount(action) {
  return {
    type: UPDATE_UNFINISHED_TUTORIALS_COUNT,
    unFinishedTutorialsCount: action.unFinishedTutorialsCount,
  };
}
