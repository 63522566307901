export default () => {
  // Prevents Chrome from focusing buttons on click
  // Copied from amplifr.com
  let currentTarget;
  let lastMouseDown;
  function q(t) {
    return t.tagName === 'A' || t.tagName === 'BUTTON'
      ? t
      : t.parentElement
      ? q(t.parentElement)
      : null;
  }
  document.addEventListener('mousedown', (event) => {
    currentTarget = q(event.target);
    lastMouseDown = Date.now();
  });

  document.addEventListener('focusin', (event) => {
    event.target === currentTarget &&
      Date.now() - lastMouseDown < 1000 &&
      event.target.blur();
  });
};
