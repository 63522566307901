import React, { Fragment } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Formik, Form, Field, FieldArray } from 'formik';
import TextField from '@material-ui/core/TextField';

/**
 * JSS styles for `TextForm` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  formField: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: '16px',
    '& > *:not(:last-child)': {
      marginRight: '8px',
      [theme.breakpoints.up('sm')]: {
        marginRight: '16px',
      },
    },
  },
}));

/**
 * Object describing values stored in `TextForm` `formik::Field`
 * @typedef {Object} TextFormItem
 * @prop {String} text - value in the `formik::Field` input
 */

/**
 * Function for overloading and merging `TextForm` `formik::Field` props
 * @typedef {Function} TextFormOverload
 * @param {Object} extFieldProps - `formik::Field` input props from previous overloading
 * @param {Function} extendThisField - function for helping overloading desired props (see `TextForm::extendThisField`, it was this function initially), call this if mod for `TextForm` uses `fieldProps.extend` prop
 * @param {Object} tresspass - additional data from `formik` and rendering process
 * @param {Object} tresspass.arrayHelpers - `formik::FieldArray` helpers
 * @param {Array[TextFormItem]} tresspass.values - array of `formik::Field` values
 */

/**
 * Generates initial value for `Formik::initialValues` array prop
 * @param {String} initialValue - initial text value of the field in form
 * @returns {TextFormItem}
 */
export function newFieldValue(initialValue) {
  return { text: String(initialValue) };
}

/**
 * Converts values from `String` to `TextFormItem` for `Formik::initialValues` array prop
 * @param {Array[String|Object]?} initialValue - initial text value of the field in form
 * @returns {Array[TextFormItem]}
 */
export function toFieldValues(initialValuesUnsafe) {
  return (Array.isArray(initialValuesUnsafe)
    ? [...initialValuesUnsafe]
    : []
  ).map((v) =>
    typeof v === 'object' && typeof v.text === 'string' ? v : newFieldValue(v)
  );
}

/**
 * Shows multiple fields with ability to extend their amount
 * @param {Object} $
 * @param {Object?} $.fieldProps - additional props passed to `formik::Field` element shown as `material-ui::TextField`, below listed additional possible props handled by `TextForm`
 * @param {React::JSX?} $.fieldProps.startGap - element listed before `formik::Field` in a row
 * @param {React::JSX?} $.fieldProps.endGap - element listed after `formik::Field` in a row
 * @param {TextFormOverload?} $.fieldProps.extend - calls when `formik::Field` is rendering, must return object with overloaded `$.fieldProps`, called for each `formik::Field` input
 * @param {Array[String]} $.initialValues - initial values passed to the `TextForm` `formik::Field` elements
 * @param {Function?} $.children - must return `React::JSX` as component children if given
 * @param {Function?} $.hasError - calls on `formik::Field` with input DOM element and index of it
 * @param {Function?} $.onBlurred - calls on blur with all current values in form, input element and it's index
 * @param {...Object} $.formProps - params passed to the `formik::Formik` instance
 */
export default function TextForm({
  initialValues: initialValuesUnsafe,
  fieldProps: { extend: extendField, ...fieldProps } = {},
  children = () => {},
  hasError = () => {},
  onBlurred = () => {},
  ...formProps
}) {
  const styles = useStyles();
  const initialValues = toFieldValues(initialValuesUnsafe);

  /**
   * Function merging props given by previous and current overloadings, like `super()` in constructor
   * @param {Object} extFieldProps - `formik::Field` input props from previous overloading
   * @param {TextFormOverload} extExtendField - function from `fieldProps.extend` props, calls to get current props overloading
   * @param {Object} tresspass - additional data passing to the `extExtendField`
   * @returns {Object} - overloaded props
   */
  const extendThisField = (extFieldProps, extExtendField, trespass) => {
    if (typeof extExtendField !== 'function') {
      return extFieldProps;
    }

    const newExtFieldProps = extExtendField(
      extFieldProps,
      extendThisField,
      trespass
    );
    const newFieldProps = [extFieldProps, newExtFieldProps].reduce(
      (acc, props) => {
        const { InputProps = {} } = props;
        const { InputProps: prevInputProps = {} } = acc;
        return {
          ...acc,
          ...props,
          InputProps: { ...prevInputProps, ...InputProps },
        };
      },
      {}
    );

    ['startGap', 'endGap'].forEach((key) => {
      const prevValue = extFieldProps[key];
      const value = newExtFieldProps[key];
      newFieldProps[key] = (
        <Fragment>
          {prevValue || null}
          {value || null}
        </Fragment>
      );
    });

    return newFieldProps;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        included: initialValues,
      }}
      {...formProps}
    >
      {({ values }) => (
        <Form>
          <FieldArray
            name="included"
            render={(arrayHelpers) => (
              <div>
                {values.included.map((elem, index) => {
                  const thisFieldProps = extendThisField(
                    {
                      className: 'grow',
                      variant: 'standard',
                      type: 'inclusions',
                      name: `included.${index}.text`,
                      error: hasError(elem, index),
                      as: TextField,
                      onBlur: (e) =>
                        onBlurred(
                          e,
                          values.included.map((v) => v.text),
                          index
                        ),
                      ...fieldProps,
                    },
                    extendField,
                    { arrayHelpers, values: values.included, index }
                  );

                  const {
                    startGap,
                    endGap,
                    ...pureFieldProps
                  } = thisFieldProps;

                  return (
                    <div key={index} className={styles.formField}>
                      {startGap || null}
                      <Field {...pureFieldProps} />
                      {endGap || null}
                    </div>
                  );
                })}
                {children({ arrayHelpers, values: values.included })}
              </div>
            )}
          />
        </Form>
      )}
    </Formik>
  );
}
