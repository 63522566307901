/**
 * Converts routePoint object to props of the `EventControlsGeo` component
 * @param {RoutePoint} routePoint
 * @returns {Object?} - may return null, if `routePoint` is not of `Object` type
 */
export default function routePointToGeoTrigger(routePoint) {
  return typeof routePoint === 'object' && routePoint
    ? {
        lat: routePoint.location.coordinates[0],
        lon: routePoint.location.coordinates[1],
        address: routePoint.address,
        radius: routePoint.radius,
      }
    : null;
}
