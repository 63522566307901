import * as contentTypes from './contentTypes';

const eventContentDefaultDesc = {
  method: 'put',
  formUrl: ({ questId }) => `quests/${questId}/media/`,
};

/**
 * Descriptions of endpoints for uploading event content
 * @type {Object<EventContentType, Object>}
 */
const CONTENT_UPLOAD_ENDPOINTS = {
  [contentTypes.EVENT_CONTENT_IMAGE]: eventContentDefaultDesc,
  [contentTypes.EVENT_CONTENT_STICKER]: eventContentDefaultDesc,
  [contentTypes.EVENT_CONTENT_AUDIO]: eventContentDefaultDesc,
  [contentTypes.PRODUCT_IMAGE]: {
    method: 'post',
    formUrl: ({ productId }) => `v2/products/${productId}/images/`,
  },
};

export default CONTENT_UPLOAD_ENDPOINTS;
