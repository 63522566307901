import React from 'react';
import { FormattedMessage } from 'react-intl';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';

export const getWidgetStatsRanges = (locale) => {
  const moment = extendMoment(originalMoment);
  moment.locale(locale);
  const today = moment();

  return [
    {
      label: (
        <FormattedMessage
          id="WidgetStatsRanges.todayRange"
          defaultMessage="Today"
        />
      ),
      range: moment.range(
        today.clone().startOf('day'),
        today.clone().startOf('day')
      ),
    },
    {
      label: (
        <FormattedMessage
          id="WidgetStatsRanges.yesterdayRange"
          defaultMessage="Yesterday"
        />
      ),
      range: moment.range(
        today.clone().startOf('day').subtract(1, 'days'),
        today.clone().startOf('day').subtract(1, 'days')
      ),
    },
    {
      label: (
        <FormattedMessage
          id="WidgetStatsRanges.weekRange"
          defaultMessage="Week"
        />
      ),
      range: moment.range(
        today.clone().startOf('day').subtract(7, 'days'),
        today.clone().startOf('day')
      ),
    },
  ];
};

export const getDefaultWidgetStatsRange = (locale) => {
  const moment = extendMoment(originalMoment);
  moment.locale(locale);
  const today = moment();

  const sevenDaysAgoDate = today.clone().startOf('day').subtract(7, 'days');
  const todayDate = today.clone().startOf('day');

  return {
    rangeDateObject: moment.range(sevenDaysAgoDate, todayDate),
    momentDateObject: {
      start: sevenDaysAgoDate,
      end: todayDate,
    },
  };
};
