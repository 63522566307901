import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { copyQuest } from '../../actions/quests';
import { QuestType } from '../../constants/PropTypes';

const emptyFunc = () => {};

const messages = defineMessages({
  defaultTitle: {
    id: 'QuestDuplicate.defaultQuestTitle',
    defaultMessage: 'Copy {title}',
  },
});

class QuestDuplicate extends Component {
  handleCopy = (title = this.defaultCopyTitle()) => {
    const {
      quest: { id },
      dispatch,
      redirect = true,
    } = this.props;

    dispatch(copyQuest(id, title, redirect));
  };

  defaultCopyTitle() {
    const {
      quest: { title },
      intl: { formatMessage },
    } = this.props;

    return formatMessage(messages.defaultTitle, { title });
  }

  render() {
    const {
      isCopying,
      render = emptyFunc,
      redirect, // just omit redirect attr
      ...props
    } = this.props;
    return <div>{render({ onCopy: this.handleCopy, isCopying })}</div>;
  }
}

function mapStateToProps(state) {
  return {
    isCopying: state.quests.isCopying,
  };
}

QuestDuplicate.propTypes = {
  quest: QuestType.isRequired,
  isCopying: PropTypes.bool,
  render: PropTypes.func,
};

export default connect(mapStateToProps)(injectIntl(QuestDuplicate));
