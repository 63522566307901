import { take, takeEvery, all, put, call, select } from 'redux-saga/effects';
import * as types from '../constants/ActionTypes';
import {
  uploadStart,
  uploadProgress,
  uploadFailure,
  uploadSuccess,
  readSuccess,
  readFailure,
} from '../actions/uploads';
import { syncProductImagesOrder } from '../actions/publishing';
import createFormUploadChannel from '../functions/createFormUploadChannel';
import createFileReadChannel from '../functions/createFileReadChannel';
import guid from '../functions/id/guid';
import getRandomInt from '../functions/getRandomInt';
import checkMediaId from '../functions/id/checkMediaId';
import operateMediaId from '../functions/uploads/operateMediaId';
import formContentUploadEndpoint from '../functions/uploads/formContentUploadEndpoint';

function getFileType(file) {
  return file.type.split('/')[0];
}

export function* readFileSaga(meta) {
  const { file } = meta;
  const channel = yield call(createFileReadChannel, file);
  try {
    while (true) {
      const { err, data } = yield take(channel);
      if (err) {
        yield put(readFailure(meta, err));
        return;
      }
      if (data) {
        yield put(readSuccess(meta, data));
        return;
      }
    }
  } finally {
    // console.log('Reading ended');
  }
}

export function* uploadFileSaga(meta) {
  const { form, endpoint } = meta;
  const channel = yield call(createFormUploadChannel, endpoint, form);

  try {
    while (true) {
      const { progress = 0, err, success, json, xhr } = yield take(channel);
      if (xhr) {
        yield put(uploadStart(meta, xhr));
      }
      if (err) {
        yield put(uploadFailure(meta, err));
        yield put({ type: types.NETWORK_ERROR, error: err });
        return;
      }
      if (success) {
        const uploads = yield select((state) => state.uploads.uploads);
        const upload = uploads.find(({ id }) => id === meta.id);

        if (upload) {
          const { mediaId } = upload;
          let index = 0;

          if (checkMediaId(mediaId)) {
            let uploadsBefore = 0;
            operateMediaId(uploads, mediaId, (uploadMediaId) => {
              if (uploadMediaId.index < mediaId.index) {
                uploadsBefore += 1;
              }
            });
            index = mediaId.index - uploadsBefore;
          }

          yield put(uploadSuccess({ index, ...meta }, json));
        }
      }
      yield put(uploadProgress(meta, progress));
    }
  } finally {
    // console.log('Uploading ended');
  }
}

export function* sequentialUpload() {
  const uploads = yield select((state) => state.uploads.uploads);
  for (let i = 0; i < uploads.length; i += 1) {
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { xhr, ...meta } = uploads[i];
    yield all(
      [
        call(uploadFileSaga, meta),
        meta.type === 'image' && call(readFileSaga, meta),
      ].filter(Boolean)
    );
  }
}

export function* uploadBatch(action) {
  const { eventPK, files } = action;
  const metas = files.map((meta) => {
    const form = new FormData();
    const id = guid();
    const {
      file: { name: fileName = `event-image-${getRandomInt(1, 1000)}` },
      contentType,
      file,
      mediaId,
    } = meta;
    form.append(fileName, file, fileName);
    const type = getFileType(file);
    const endpoint = formContentUploadEndpoint(contentType, eventPK);
    return { id, form, file, type, contentType, eventPK, mediaId, endpoint };
  });

  yield all(
    metas.map((meta) =>
      put({
        type: types.UPLOAD_ADD,
        meta,
      })
    )
  );
  yield sequentialUpload();
}

export function* uploadAbort(action) {
  const {
    meta: { id },
  } = action;
  const upload = yield select((state) => {
    return state.uploads.uploads.find((u) => u.id === id);
  });
  if (!upload || !upload.xhr) {
    yield put({
      type: types.UPLOAD_ABORT_FAILURE,
      meta: { id },
      payload: new Error('No such upload XHR!'),
    });
    return;
  }

  upload.xhr.abort();

  yield put({
    type: types.UPLOAD_ABORT_SUCCESS,
    meta: { id },
  });
}

export function* handleSuccessUpload(action) {
  const {
    meta: { eventPK, contentType, index },
    json,
  } = action;
  let dataForSync = 'quest';

  switch (contentType) {
    case 'audio': {
      yield put({
        type: types.RECEIVE_AUDIOS_URLS,
        audios: json.data,
        ...eventPK,
      });
      break;
    }

    case 'image': {
      yield put({
        type: types.RECEIVE_IMAGES_URLS,
        images: json.data,
        imageId: index,
        ...eventPK,
      });
      break;
    }

    case 'video': {
      yield put({
        type: types.RECEIVE_VIDEOS_URLS,
        payload: json.data,
        ...eventPK,
      });
      break;
    }

    case 'imageAR': {
      yield put({
        type: types.RECEIVE_IMAGEAR_URL,
        image: json.data,
        ...eventPK,
      });
      break;
    }

    case 'imageStore': {
      dataForSync = 'product';
      yield put({
        type: types.RECEIVE_IMAGESTORE_URL,
        images: json.data,
        imageId: index,
        ...eventPK,
      });
      break;
    }

    case 'sticker': {
      yield put({
        type: types.RECEIVE_STICKERS_URL,
        stickers: json.data,
        stickerId: index,
        ...eventPK,
      });
      break;
    }

    default: {
      return;
    }
  }

  switch (dataForSync) {
    case 'product':
      yield put(syncProductImagesOrder(eventPK.productId));
      break;

    case 'quest':
    default:
      yield put({
        type: types.SYNC_QUEST,
        id: eventPK.questId,
        updateMediaSize: true,
      });
      break;
  }
}

export default function* watchUploads() {
  yield takeEvery(types.UPLOAD_BATCH, uploadBatch);
  yield takeEvery(types.UPLOAD_ABORT, uploadAbort);
  yield takeEvery(types.UPLOAD_SUCCESS, handleSuccessUpload);
}
