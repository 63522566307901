import React from 'react';
import classNames from 'classnames';
import PaginationMui from '@material-ui/lab/Pagination';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  rootLoading: {
    opacity: 0.4,
  },
}));

function Pagination({ className, onChange, isLoading, pages, currentPage }) {
  const classes = useStyles();

  const onPageChange = (event, page) => {
    if (currentPage !== page) onChange(page);
  };
  return (
    <div
      className={classNames(
        classes.root,
        isLoading && classes.rootLoading,
        className
      )}
    >
      <PaginationMui page={currentPage} count={pages} onChange={onPageChange} />
    </div>
  );
}

export default Pagination;
