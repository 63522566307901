export const convertToMinutes = (value) => {
  const array = value.split(' ');
  let result = 0;
  if (array.length === 4) {
    if (array[0] !== '000') result = Number(array[0]) * 60;
    if (array[2] !== '00') result += Number(array[2]);
  }
  if (array.length === 2) {
    if (array[0] !== '000') result = Number(array[0]) * 60;
    if (array[1] !== '00') result += Number(array[1]);
  }
  return result;
};

export const convertToHoursAndMinutes = (value) => {
  const array = ['000', ' ', '00'];
  if (value / 60 > 0) {
    array[0] = (array[0] + (~~(value / 60)).toString()).substr(-3);
    array[2] =
      value - array[0] * 60 === 0
        ? '00'
        : (array[2] + (value - array[0] * 60).toString()).substr(-2);
  } else array[2] = value;
  return array.join('');
};
