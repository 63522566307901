import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip';
import classNames from 'classnames';
import hashString from '../../functions/hashString';
import { Mobile, Desktop } from '../../functions/queries';
import './HelpTooltip.css';

class HelpTooltip extends Component {
  getId() {
    const { children } = this.props;
    return hashString(children);
  }

  handleClick = (e) => {
    e.stopPropagation();
  };

  render() {
    const { children, className, id = this.getId() } = this.props;
    return (
      <span>
        <Mobile>
          <i
            data-tip
            className="HelpTooltip__button"
            data-for={`hint.${id}`}
            data-event="click"
            onClick={this.handleClick}
          />
        </Mobile>
        <Desktop>
          <i
            data-tip
            className="HelpTooltip__button"
            data-for={`hint.${id}`}
            onClick={this.handleClick}
          />
        </Desktop>
        <Tooltip
          id={`hint.${id}`}
          effect="solid"
          globalEventOff="click"
          delayHide={300}
          class={classNames('HelpTooltip__hint', className)}
        >
          <span onClick={(event) => event.stopPropagation()}>{children}</span>
        </Tooltip>
      </span>
    );
  }
}

HelpTooltip.propTypes = {
  children: PropTypes.string.isRequired,
};

export default HelpTooltip;
