/* eslint-disable unused-imports/no-unused-vars */
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ToggleButtonPure from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import styled from 'styled-components';
import PictureEditorTool from '../PictureEditorTool';
import withColor from '../../ToggleButton/withColor';

/**
 * Colored `material-ui::ToggleButton`
 * @type {React::Component}
 */
const ToggleButton = withColor(ToggleButtonPure);

/**
 * Formats for cropping image
 * @type {Array[Object]}
 */
const FORMATS = [
  { h: 1, v: 1 },
  { h: 5, v: 4 },
  { h: 9, v: 16 },
];

const Subtitle = styled.div`
  color: #757575;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  max-width: 163px;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;

/**
 * Messages for `PictureEditor::withFormat` mod
 * @type {Object}
 */
const messages = {
  title: (
    <FormattedMessage id="PictureEditorFormat.title" defaultMessage="Format" />
  ),
  subtitle: (
    <FormattedMessage
      id="PictureEditorFormat.subtitle"
      defaultMessage={`
      To adjust the required size,
      move the corners of the selection area
    `}
    />
  ),
};

/**
 * Adds "crop to format" tool to `PictureEditor`
 * @param {React::Component} Component - componenet to overload
 */
export default function withFormat(Component) {
  /**
   * `PictureEditor` component with "crop to format" tool
   * @param {Object} $
   * @param {...Object} $.editorProps - props for `PictureEditor` component
   */
  return function ({ children, ...editorProps }) {
    /**
     * Index of the item in the `FORMATS` array
     * @type {Number}
     */
    // eslint-disable-next-line no-unused-vars
    const [format, setFormat] = useState(0);
    const [cropAspect, setCropAspect] = useState(null);

    const handleFormatChange = (e, newFormat) => {
      setFormat(newFormat);
      setCropAspect(FORMATS[newFormat].v / FORMATS[newFormat].h);
    };

    return (
      <Component cropAspect={cropAspect} {...editorProps}>
        <PictureEditorTool title={messages.title}>
          <ToggleButtonGroup
            exclusive
            value={null}
            onChange={handleFormatChange}
          >
            {FORMATS.map(({ v, h }, i) => {
              const formatName = `${v}:${h}`;
              return (
                <ToggleButton key={formatName} color="primary" value={i}>
                  {formatName}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
          <Subtitle>{messages.subtitle}</Subtitle>
        </PictureEditorTool>
        {children}
      </Component>
    );
  };
}
