import { defineMessages } from 'react-intl';

const timeMessages = defineMessages({
  ago: {
    id: 'DateUtils.ago',
    defaultMessage: '{overload} ago',
  },
  recently: {
    id: 'DateUtils.recently',
    defaultMessage: 'few seconds ago',
  },
  minutes: {
    id: 'DateUtils.minutes',
    defaultMessage: '{amount, plural, one {a minute} other {{amount} minutes}}',
  },
  hours: {
    id: 'DateUtils.hours',
    defaultMessage: '{amount, plural, one {a hour} other {{amount} hours}}',
  },
  days: {
    id: 'DateUtils.days',
    defaultMessage: '{amount, plural, one {a day} other {{amount} days}}',
  },
  months: {
    id: 'DateUtils.months',
    defaultMessage: '{amount, plural, one {a month} other {{amount} months}}',
  },
  years: {
    id: 'DateUtils.years',
    defaultMessage: '{amount, plural, one {a year} other {{amount} years}}',
  },
  daysShort: {
    id: 'DateUtils.daysShort',
    defaultMessage: 'd',
  },
  hoursShort: {
    id: 'DateUtils.hoursShort',
    defaultMessage: 'h',
  },
  minutesShort: {
    id: 'DateUtils.minutesShort',
    defaultMessage: 'min',
  },
});

export function getDate(dateOrString) {
  if (typeof dateOrString === 'string') return new Date(dateOrString);
  return dateOrString;
}

export function makeYMD(d) {
  return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
}

export function getTommorow(d) {
  d = getDate(d);
  return new Date(d.setDate(d.getDate() + 1));
}

// a, b momentjs date objects
export function isSameYear(a, b) {
  return a.isSame(b, 'year');
}

// a, b momentjs date objects
export function isSameMonth(a, b) {
  return a.isSame(b, 'month');
}

// a, b momentjs date objects
export function isSameDay(a, b) {
  return a.isSame(b, 'day');
}

/**
 * Adds leading zeros to fit given amount of digits
 * @param  {String} number - to format
 * @param  {String} digits - amount of digits to fit in
 * @return {String}
 */
function formatDigits(number, amount) {
  const add = amount - number.length < 0 ? 0 : amount - number.length;

  return new Array(add + 1).join('0') + number;
}

/**
 * Formats time in seconds as `m*:ss`
 * @param  {Number} seconds
 * @return {String}
 */
export function timeString(seconds) {
  const minutes = String(Math.floor(seconds / 60));
  seconds = String(Math.floor(seconds % 60));

  return `${minutes}:${formatDigits(seconds, 2)}`;
}

/**
 * Gets inaccurate date, relative to now (e.g. 20 days before)
 * @param  {Function} formatMessage - translation function from react-intl
 * @param  {Date} last - date to relate
 * @return {String}
 */
export function relativeDate(formatMessage, modified) {
  const delta = Math.round((Number(new Date()) - new Date(modified)) / 1000);
  const day = 24 * 3600;
  const month = 30 * day;
  const year = 365 * day;

  let fuzzy = '';

  if (delta < 60) {
    fuzzy = formatMessage(timeMessages.recently);
  } else if (delta < 3600) {
    fuzzy = formatMessage(timeMessages.ago, {
      overload: formatMessage(timeMessages.minutes, {
        amount: Math.floor(delta / 60),
      }),
    });
  } else if (delta < day) {
    fuzzy = formatMessage(timeMessages.ago, {
      overload: formatMessage(timeMessages.hours, {
        amount: Math.floor(delta / 3600),
      }),
    });
  } else if (delta < month) {
    fuzzy = formatMessage(timeMessages.ago, {
      overload: formatMessage(timeMessages.days, {
        amount: Math.floor(delta / day),
      }),
    });
  } else if (delta < year) {
    fuzzy = formatMessage(timeMessages.ago, {
      overload: formatMessage(timeMessages.months, {
        amount: Math.floor(delta / month),
      }),
    });
  } else {
    fuzzy = formatMessage(timeMessages.ago, {
      overload: formatMessage(timeMessages.years, {
        amount: Math.floor(delta / year),
      }),
    });
  }

  return fuzzy;
}

/**
 * Convert minutes to short human readable format
 * @param {Number} num - minutes
 * @param {Object} intl - `react-intl` object for generating translations
 * @returns {String} - formatted sting e.g. '1d 2h 30min'
 */
export const ConvertMinutesToShortFormat = (num, intl) => {
  const d = Math.floor(num / 1440);
  const h = Math.floor((num - d * 1440) / 60);
  const m = Math.round(num % 60) === 0 ? '00' : Math.round(num % 60);

  if (d > 0) {
    return `${d} ${intl.formatMessage(
      timeMessages.daysShort
    )} ${h} ${intl.formatMessage(
      timeMessages.hoursShort
    )} ${m} ${intl.formatMessage(timeMessages.minutesShort)}`;
  }
  if (h > 0) {
    return `${h} ${intl.formatMessage(
      timeMessages.hoursShort
    )} ${m} ${intl.formatMessage(timeMessages.minutesShort)}`;
  }
  return `${m} ${intl.formatMessage(timeMessages.minutes)}`;
};
