export default function script(url) {
  if (Array.isArray(url)) {
    const prom = url.map((item) => script(item));
    return Promise.all(prom);
  }

  return new Promise(function (resolve, reject) {
    let r = false;
    let s;

    const existedScript = document.querySelector(`script[src="${url}"]`);
    if (existedScript) {
      s = existedScript;
    } else {
      s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = url;
      s.async = true;
    }

    s.onload = s.onreadystatechange = function () {
      if (!r && (!this.readyState || this.readyState === 'complete')) {
        r = true;
        resolve(this);
      }
    };
    s.onerror = s.onabort = reject;

    if (!existedScript) document.body.appendChild(s);
  });
}
