import get from 'lodash/get';

/**
 * Checks if choice is set
 * @param {Object?} content - choice description from quest JSON
 * @returns {Boolean} - `true` if choice is correctly set and will be shown to user
 */
export default function checkChoiceSet(content) {
  return (
    get(content, 'data.options.length') &&
    !content.data.options.every((opt) => !opt)
  );
}
