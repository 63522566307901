/**
 * Wrapper for analytics sending interface
 * @param {String} method - which method of analytics to use, "track" by default
 * @param {Object} params - will be passed into interface
 */
export default function sendEvent(method, ...info) {
  try {
    // eslint-disable-next-line no-undef
    analytics[method](...info);
  } catch (err) {
    console.log(`Error in analytics with method ${method}`, info, err);
  }
}
