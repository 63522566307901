/**
 * Includes script with `src` and `id` as a child of `position` element in DOM
 * If `position` is not specified - does nothing
 * @param {String} src - URL of the script
 * @param {Element} position - DOM element to include script
 * @param {String} id - id of the script to install
 */
export default function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}
