import { useRef } from 'react';

/**
 * Optimization for events firing repeatedly (continuous events), such as `"scroll"`, `"mousemove"`, etc.
 * Uses `requestAnimationFrame` to
 *   - stop `listener` function from being dispatched too frequently
 *   - align animation frames to have the same time offset between rerenders
 * @param {Function} eventDataSaver - saves data from the current trigger of event, return will be passed to the `listener`
 * @param {Function} listener - triggers when continuous event dispatched in static FPS mode, original `Event` object is unaccessible
 * @returns {Array} - first element is ref on listener to be set on the continuous event, second (optional) - readonly reference to ref with current animation state (`true` - drawing, `false` - waiting)
 */
export default function useContinuousEvent(eventDataSaver, listener) {
  /**
   * Current status of animation (`true` - drawing new frame, `false` - unloading drawing resources)
   * @type {Boolean}
   */
  const isTickBusyRef = useRef(false);

  /**
   * Event listener to be bound to continuous event
   * @param {Event} event - any continuous dispatching event
   * @param {...Array} args - additional arguments passing to the `listener` function
   */
  const continuousEventListenerWrapperRef = useRef((event, ...args) => {
    if (!isTickBusyRef.current) {
      const data = eventDataSaver(event);
      window.requestAnimationFrame(() => {
        listener(data, ...args);
        isTickBusyRef.current = false;
      });
    }

    isTickBusyRef.current = true;
  });

  return [continuousEventListenerWrapperRef, isTickBusyRef];
}
