import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import startCase from 'lodash/startCase';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';
import startOfDay from 'date-fns/esm/startOfDay';
import endOfDay from 'date-fns/esm/endOfDay';
import startOfWeek from 'date-fns/startOfWeek';
import { useSelector } from 'react-redux';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/esm/startOfMonth';
import endOfMonth from 'date-fns/esm/endOfMonth';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { defineMessages, injectIntl } from 'react-intl';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PureCalendar from 'react-calendar';
import withThemeMaterial from '../../Calendar/withThemeMaterial';
import messageById from '../../../functions/intl/messageById';
import { FILTER_PADDING } from '../TableFilter';
import TableFilterInfo from '../TableFilterInfo';
import TableFilterButton from '../TableFilterButton';

/**
 * `react-calendar::Calendar` with `material-ui` like theme
 * @type {React::Component}
 */
const Calendar = withThemeMaterial(PureCalendar);

/**
 * @typedef {Object} TableFilterDateValue
 * @prop {String?} difference - id of the time interval between dates from `TableFilterDate::RANGE_TYPES`
 * @prop {Date} from
 * @prop {Date} to
 */

/**
 * Types of distances between start and end selection dates in calendar
 * @type {Array[Object]}
 */
const RANGE_TYPES = [
  { id: 'day', from: (d) => startOfDay(d), to: (d) => endOfDay(d) },
  { id: 'week', from: (d) => startOfWeek(d), to: (d) => endOfWeek(d) },
  { id: 'month', from: (d) => startOfMonth(d), to: (d) => endOfMonth(d) },
  {
    id: 'custom',
    from: (d, ds) => ds[0] && startOfDay(ds[0]),
    to: (d, ds) => ds[1] && endOfDay(ds[1]),
  },
  {
    id: 'nodate',
    from: () => null,
    to: () => null,
  },
];

/**
 * Wrong format of the date in the field
 * @type {Number}
 */
const ERROR_INCORRECT_DATE = 1;

/**
 * Messages for `TableFilterDate` component
 * @type {Object}
 */
const messages = defineMessages({
  rangeLabel: {
    id: 'TableFilterDate.rangeLabel',
    defaultMessage: 'Range',
  },
  rangeMenuCustom: {
    id: 'TableFilterDate.rangeMenuCustom',
    defaultMessage: 'Custom',
  },
  rangeMenuNodate: {
    id: 'TableFilterDate.rangeMenuNodate',
    defaultMessage: 'Without date',
  },
  rangeMenuDay: {
    id: 'TableFilterDate.rangeMenuDay',
    defaultMessage: 'Day',
  },
  rangeMenuWeek: {
    id: 'TableFilterDate.rangeMenuWeek',
    defaultMessage: 'Week',
  },
  rangeMenuMonth: {
    id: 'TableFilterDate.rangeMenuMonth',
    defaultMessage: 'Month',
  },
  fromLabel: {
    id: 'TableFilterDate.fromLabel',
    defaultMessage: 'From',
  },
  toLabel: {
    id: 'TableFilterDate.toLabel',
    defaultMessage: 'To',
  },
});

/**
 * Error messages for `TableFilterDate` component
 * @type {Object}
 */
const errorMessages = defineMessages({
  [ERROR_INCORRECT_DATE]: {
    id: 'TableFilterDate.errorWrongDate',
    defaultMessage: 'DD.MM.YYYY',
  },
});

/**
 * Performs `Table` rows filtering with given date period as query
 * @param {String} id - id of the column to perform search on
 * @param {String} value - searching value
 * @param {Array[TableRowData]} rows - rows to filter
 * @returns {Array[TableRowData]} - filtered rows
 */
export function filterDate(id, value, rows) {
  const dateFrom = get(value, 'from');
  const dateTo = get(value, 'to');
  const difference = get(value, 'difference');

  if (difference === 'nodate') {
    return rows.filter((row) => {
      const { raw } = row[id];
      return !(raw instanceof Date);
    });
  }

  if (!dateFrom && !dateTo) {
    return rows;
  }

  return rows.filter((row) => {
    const { raw } = row[id];

    if (raw instanceof Date) {
      return raw >= dateFrom && raw <= dateTo;
    }

    return true;
  });
}

/**
 * JSS styles for `TableFilterDateInfo` component
 * @type {React::Hook}
 */
const useInfoStyles = makeStyles(() => ({
  icon: {
    width: '14px',
    height: '14px',
    marginLeft: '6px',
  },
}));

/**
 * Info badge for displaying in `Table::withFilters()` options panel
 * @param {Object} $
 * @param {TableFilterDateValue} $.value - `Table::TableFilterDate` value
 */
export function TableFilterDateInfo({ value, ...badgeProps }) {
  const styles = useInfoStyles();
  const user = useSelector((state) => state.user);

  const dates = ['from', 'to'].map((n) => {
    const date = value[n];
    const noDateMessage = user.locale === 'ru' ? 'Без даты' : 'Without date';
    return date ? format(date, 'dd.MM.yyyy') : noDateMessage;
  });

  return (
    <TableFilterInfo
      icon={<CalendarIcon className={styles.icon} />}
      label={`${dates[0]} - ${dates[1]}`}
      {...badgeProps}
    />
  );
}

/**
 * JSS styles for `TableFilterDate` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  root: {
    width: '268px',
  },
  content: {
    padding: `${FILTER_PADDING[0]}px ${FILTER_PADDING[1]}px`,
  },
  textfield: {
    marginBottom: '16px',
  },
  selectPaper: {
    zIndex: '10000 !important',
  },
  dates: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  date: {
    marginBottom: '16px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      '&:not(:last-child)': {
        marginRight: '10px',
      },
    },
  },
}));

/**
 * Table date filter form - sets date period to search
 * Has no own state for `value` prop
 * @param {Object}
 * @param {TableFilterDateValue} $.value - dates range description
 * @param {Function} $.onChanged - filter value was changed, calls with new filter value as an argument
 * @param {Function} $.onApplied - will be called after "Apply filter" button clicked
 */
export default injectIntl(function TableFilterDate({
  value: valueExt,
  onChanged: onChangedExt = () => {},
  onApplied = () => {},
  intl: { formatMessage = () => {} } = {},
}) {
  const styles = useStyles();
  const materialTheme = useTheme();
  const isMobile = useMediaQuery(materialTheme.breakpoints.down('sm'));
  const getMessage = messageById.bind(null, formatMessage, messages);
  const errorMessageById = messageById.bind(null, formatMessage, errorMessages);

  /**
   * Type of the current range (id of the distance between dates from `TableFilterDate::RANGE_TYPES[]`)
   * @type {String?}
   */
  const rangeTypeExt = get(valueExt, 'difference', 'custom');

  /**
   * From and to dates for `react-calendar::Calendar`
   * @type {Array[Date?]}
   */
  const calendarValue = ['from', 'to'].map((t) => get(valueExt, t, null));

  /**
   * Current selected date range type description
   * @type {Object}
   */
  const range = [rangeTypeExt, 'custom'].reduce(
    (acc, t) => (!acc ? RANGE_TYPES.find(({ id }) => id === t) : acc),
    null
  );

  /**
   * Date inputs values
   * Need for storing not fullfilled date strings which are invalid while entering
   * @type {Array[String]}
   */
  const [dateInputs, _setDateInputs] = useState(
    ['from', 'to'].map((t, i) => calendarValue[i] || '')
  );
  const setDateInputs = (i, value) => {
    const values = [...dateInputs];
    values[i] = value;
    _setDateInputs(values);
  };

  /**
   * Updating date values in inputs passed from top
   */
  const dateInputsExt = calendarValue.map((date) =>
    date instanceof Date ? format(date, 'dd.MM.yyyy') : ''
  );
  useEffect(() => {
    _setDateInputs(dateInputsExt);
  }, dateInputsExt);

  /**
   * Errors in text fields
   * @type {Object}
   */
  const [error, setError] = useState({});

  /**
   * Processing errors in text fields:
   * - checking
   * - dispatching or resolving
   */
  useEffect(() => {
    /** Checking if date inputs filled with valid dates */
    const inputErrors = dateInputs.map(
      (d) =>
        !d || (d.length === 10 && isValid(parse(d, 'dd.MM.yyyy', new Date())))
    );

    if (rangeTypeExt === 'nodate') {
      setError({});
      return;
    }

    const newErrors = ['from', 'to'].reduce(
      (acc, n, i) =>
        !inputErrors[i]
          ? { [`input${startCase(n)}`]: ERROR_INCORRECT_DATE, ...acc }
          : acc,
      {}
    );

    if (dateInputs.some((d) => !d)) {
      newErrors.notFullfilled = true;
    }

    if (!isEqual(error, newErrors)) {
      setError(newErrors);
    }
  }, [...dateInputs, rangeTypeExt]);

  /**
   * Converts given date to interval borders with length specified by `range`
   * Generated interval will contain date and have borders on the start and end of the time unit from `range.id`
   * @param {Object} range - range object from `TableFilterDate::RANGE_TYPES[]`
   * @param {Array[Date]} dates - will be returned if updating by range is not required
   * @param {Date} date - date to used for generating new `calendarValue[]`
   * @returns {Array[Date]}
   */
  const updateByRange = (range, dates, date) => {
    if (date && range) {
      return ['from', 'to'].map((t, i) => range[t](date, dates));
    }
    return dates;
  };

  /**
   * Applies filter after user pressed "Enter" button
   *   if "Apply filter" button is not disabled
   * @param {Event} - key up event
   */
  const applyOnEnter = (event) => {
    if (!isEmpty(error)) {
      return;
    }

    if (event.key === 'Enter' || event.keyCode === 13) {
      onApplied();
    }
  };

  /**
   * One of `TableFilterDateValue` was changed
   * @param {Object} range - range object from `TableFilterDate::RANGE_TYPES[]`
   * @param {Array[Date?]} dates - start and end dates
   */
  const onChanged = (range, dates) => {
    onChangedExt({
      difference: range.id,
      from: dates[0],
      to: dates[1],
    });
  };

  /**
   * Sets new range by it's id
   * Recalculates dates from start date related to the range
   * @param {String} newRangeId - id of the range type to select
   */
  const onRangeChanged = (newRangeId) => {
    const newRange = RANGE_TYPES.find(({ id }) => id === newRangeId);

    if (newRangeId === 'nodate') {
      onChanged(newRange, [null, null]);
      return;
    }

    let dates = calendarValue;
    if (newRangeId === 'month') {
      const now = new Date();
      dates = [startOfMonth(now), endOfMonth(now)];
    } else if (!calendarValue.find((d) => !!d)) {
      dates = [new Date(), null];
    } else {
      dates = updateByRange(
        newRange,
        calendarValue,
        calendarValue.find((d) => !!d)
      );
    }

    onChanged(newRange, dates);
  };

  /**
   * User changed date in one of inputs
   * @param {Number} index - number of the text field with date ()
   * @param {String} newDateString - date in string format
   */
  const onDateChanged = (index, newDateString) => {
    const newDate = newDateString
      ? parse(newDateString, 'dd.MM.yyyy', new Date())
      : null;
    setDateInputs(index, newDateString);

    if (newDate === null || (newDateString.length === 10 && isValid(newDate))) {
      const newCalendarValue = [...calendarValue];
      newCalendarValue[index] = newDate;
      onChanged(range, updateByRange(range, newCalendarValue, newDate));
    }
  };

  /**
   * User changed date in the calendar
   * @param {Date} newDate - date in calendar selected by user
   */
  const onCalendarChanged = (newDate) => {
    let newCalendarValue = [...calendarValue];
    const notFilledIndex = newCalendarValue.findIndex((d) => !d);
    if (notFilledIndex > -1) {
      newCalendarValue[notFilledIndex] = newDate;
    } else {
      newCalendarValue = [newDate, null];
    }

    onChanged(range, updateByRange(range, newCalendarValue, newDate));
  };

  const handleActiveStartDateChange = ({ activeStartDate, view }) => {
    if (view === 'month') {
      const start = startOfMonth(activeStartDate);
      const end = endOfMonth(activeStartDate);
      onChanged(range, [start, end]);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Select
          fullWidth
          className={styles.textfield}
          MenuProps={{ PopoverClasses: { root: styles.selectPaper } }}
          label={formatMessage(messages.rangeLabel)}
          value={range.id}
          onChange={(e) => onRangeChanged(e.target.value)}
        >
          {RANGE_TYPES.map((rangeType) => (
            <MenuItem key={rangeType.id} value={rangeType.id}>
              {getMessage(`rangeMenu${startCase(rangeType.id)}`, rangeType.id)}
            </MenuItem>
          ))}
        </Select>
        {rangeTypeExt !== 'nodate' && (
          <>
            <div className={styles.dates}>
              {['from', 'to'].map((label, i) => (
                <div key={label} className={styles.date}>
                  <TextField
                    className={styles.textfield}
                    error={
                      typeof error[`input${startCase(label)}`] === 'number'
                    }
                    fullWidth={isMobile}
                    label={formatMessage(messages[`${label}Label`])}
                    value={dateInputs[i]}
                    helperText={errorMessageById(
                      error[`input${startCase(label)}`]
                    )}
                    onKeyUp={applyOnEnter}
                    onChange={(e) => onDateChanged(i, e.target.value)}
                  />
                </div>
              ))}
            </div>
            <Calendar
              className={styles.calendar}
              value={calendarValue}
              onChange={onCalendarChanged}
              onActiveStartDateChange={handleActiveStartDateChange}
            />
          </>
        )}
      </div>
      <TableFilterButton
        disabled={rangeTypeExt !== 'nodate' && !isEmpty(error)}
        onApplied={onApplied}
      />
    </div>
  );
});
