import loadScript from '../loadScript';
import { GOOGLE_API_KEY } from '../../constants/map/google';
import getGeoTriggersCoords from '../getGeoTriggersCoords';

const GOOGLE_MAPS_API = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}`;

export default async function (events) {
  const loading = await loadScript(GOOGLE_MAPS_API);
  if (loading) return;

  // Encoding lat/lng for Google Maps
  const encodingGeoTriggersCoords = getGeoTriggersCoords(events).map(
    (coords) => {
      return new window.google.maps.LatLng(coords[0], coords[1]);
    }
  );

  // Set waypoints
  let waypoints;
  if (encodingGeoTriggersCoords.length > 2) {
    waypoints = encodingGeoTriggersCoords
      .slice(1, -1)
      .reduce((geoPointsArray, geoPoint) => {
        return [...geoPointsArray, { location: geoPoint }];
      }, []);
  } else {
    waypoints = [];
  }

  // Enabling Google Maps Directions API
  const directionsService = new window.google.maps.DirectionsService();

  return { encodingGeoTriggersCoords, waypoints, directionsService };
}
