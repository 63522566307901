import getEventContent from './getEventContent';

/**
 * Pops text (text or HTML) from event
 * If HTML content exists (even if empty) - will be shown instead of text content
 * @param  {Object} event - description of event from store
 * @return {String} - text/HTML code string
 */
export default function getEventText(event) {
  return ['text', 'html'].reduce((acc, contentType) => {
    const item = getEventContent(contentType, event);
    return item && item.data ? item.data : acc;
  }, '');
}
