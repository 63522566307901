/**
 * Find language object by locale code
 * @param {String} langCode - current product locale code
 * @param {Array[Object]} languages - array of languages
 * @returns {Object} - language object
 */
export const getCurrentLanguage = (langCode, languages) => {
  if (langCode && languages.length)
    return (
      languages.find((l) => l.code === langCode) &&
      languages.find((l) => l.code === langCode)
    );
  return { name: '' };
};
