import React, { useState, useEffect } from 'react';
import camelCase from 'lodash/camelCase';
import parse from 'date-fns/parse';
import { Button, Menu, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Redirect } from 'react-router';
import AddIcon from '@material-ui/icons/Add';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { NavLink } from 'react-router-dom';
import DEFAULT_FILTERS from '../../constants/table/filters';
import productStatusMessages from '../../constants/product/intlStatuses';
import messageById from '../../functions/intl/messageById';
import ProfileContent from '../ProfileContent';
import PureTable, { leftRightCellTextAlign } from '../Table';
import compose from '../../functions/react/compose';
import useBreakpoint from '../../functions/layout/useBreakpoint';
// eslint-disable-next-line import/no-cycle
import withStickyHeader from '../Table/withStickyHeader';
import withOptions from '../Table/withOptions';
import withColumnsToggle from '../Table/withColumnsToggle';
import withToggledColumnsSaving from '../Table/withToggledColumnsSaving';
import withFilters from '../Table/withFilters';
import tableCell from '../../functions/table/tableCell';
import rowsFromHeader from '../../functions/table/rowFromHeader';
import '../Quest/Quest.css';
import { DOMAIN_URL } from '../../constants/branding';
import { QuestActionsPopup } from '../Quest';
import { Loader } from '../Loader';
import Pagination from '../Pagination';
import HelmetTitle from '../HelmetTitle';
import { getTourTitleString } from '../../constants/quests/questsConstants';
import { TOUR_DEFAULT_COVER_IMAGES } from '../../constants/product/images';
import { isUserAuthor } from '../../functions/user';

/**
 * `Table` component with controls
 * @type {React:::Component}
 */
const Table = React.memo(
  compose(
    PureTable,
    withFilters,
    withToggledColumnsSaving,
    withColumnsToggle,
    withOptions,
    withStickyHeader
  )
);

/**
 * JSS styles for `ProfileTours`, `TourName`, `TourOptionsMenu` components
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  createTourButton: {
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
      padding: '5px',
    },
  },
  tableContainer: {
    marginTop: 33,
    '& td': {
      verticalAlign: 'top',
    },
    '& td:first-child': {
      position: 'sticky',
      left: '0',
      background: theme.palette.background.main,
    },
    ...leftRightCellTextAlign,
  },
  tourNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    cursor: 'pointer',
  },
  optionsButton: {
    cursor: 'pointer',
  },
  tourTitle: {
    whiteSpace: 'pre-wrap',
  },
  noImage: {
    width: 90,
    height: 90,
    borderRadius: 4,
    marginRight: 16,
    backgroundColor: '#C4C4C4',
    flexGrow: 0,
    flexShrink: 0,
  },
  crop: {
    width: 90,
    height: 90,
    borderRadius: 4,
    marginRight: 16,
    flexGrow: 0,
    flexShrink: 0,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  loaderContainer: {
    marginTop: 50,
  },
  pagination: {
    marginTop: 16,
  },
}));

/**
 * Messages for `ProfileTours::table` component
 * @type {Object}
 */
const messages = defineMessages({
  title: {
    id: 'ProfileTour.tourName',
    defaultMessage: 'Name',
  },
  eventsCount: {
    id: 'ProfileTour.steps',
    defaultMessage: 'Steps',
  },
  lastModified: {
    id: 'ProfileTour.lastChange',
    defaultMessage: 'Last changed',
  },
  reviewsCount: {
    id: 'ProfileTour.reviews',
    defaultMessage: 'Reviews',
  },
  purchasesCount: {
    id: 'ProfileTour.sales',
    defaultMessage: 'Sales',
  },
  status: {
    id: 'ProfileTour.status',
    defaultMessage: 'Status',
  },
  tours: {
    id: 'ProfileTours.tours',
    defaultMessage: 'Tours',
  },
});

const getHeader = (formatMessage = () => {}) => {
  const header = [
    { id: 'title', type: 'string' },
    { id: 'eventsCount', type: 'number' },
    { id: 'lastModified', type: 'date' },
    { id: 'reviewsCount', type: 'number' },
    { id: 'purchasesCount', type: 'number' },
    {
      id: 'status',
      filter: {
        id: 'options',
        props: {
          options: Object.keys(productStatusMessages).map((statusId) =>
            formatMessage(productStatusMessages[statusId])
          ),
        },
      },
    },
    { id: 'options', type: 'empty' },
  ].map(({ id, ...h }) => ({
    id,
    title: h.type === 'empty' ? '' : formatMessage(messages[id]),
    ...h,
  }));
  return header;
};

const getProductStatus = (formatMessage, status) => {
  return messageById(
    formatMessage,
    productStatusMessages,
    camelCase(status),
    formatMessage(productStatusMessages.unknown)
  );
};

const TourName = ({ image, title, questId, formatMessage }) => {
  const classes = useStyles();
  return (
    <NavLink
      className={classes.tourNameContainer}
      to={`/quest/${questId}/title/`}
    >
      {image && !TOUR_DEFAULT_COVER_IMAGES.includes(image) ? (
        <div className={classes.crop}>
          <img src={image} alt="tour_image" />
        </div>
      ) : (
        <div className={classes.noImage} />
      )}
      <div className={classes.tourTitle}>
        {getTourTitleString(title, formatMessage)}
      </div>
    </NavLink>
  );
};

const TourOptionsMenu = ({
  productId,
  questId,
  setSelectedProductId,
  setSelectedQuestId,
  setActionPopup,
  productUrl,
  status,
  quests,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <React.Fragment>
      <MoreHorizIcon className={classes.optionsButton} onClick={handleClick} />
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          disabled={status !== 'approved'}
          onClick={() => {
            openInNewTab(`${DOMAIN_URL}${productUrl}`);
            handleClose();
          }}
        >
          <FormattedMessage
            id="ProfileTours.tourPage"
            defaultMessage="View the tour in the store"
          />
        </MenuItem>
        <MenuItem
          disabled={quests.length === 0}
          onClick={() => {
            setActionPopup('delete');
            setSelectedProductId(productId);
            setSelectedQuestId(questId);
            handleClose();
          }}
        >
          <FormattedMessage
            id="ProfileTours.deleteTour"
            defaultMessage="Delete the tour"
          />
        </MenuItem>
        <MenuItem disabled onClick={handleClose}>
          <FormattedMessage
            id="ProfileTours.removeFromSale"
            defaultMessage="Remove from sale"
          />
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

function withAutoVerifying(Component) {
  return function ProfileToursAutoVerifying({ user, ...props }) {
    if (props.stats.questsStats.count === 0) return <Redirect to="/welcome" />;
    if (!isUserAuthor(user)) return <Redirect to="/reports/views" />;
    return <Component {...props} />;
  };
}

const ProfileTours = ({
  quests: { quests },
  handleAddQuest,
  deleteQuest,
  intl: { formatMessage } = {},
  stats,
  requestQuestsStats,
}) => {
  const layout = useBreakpoint(['md']);

  const classes = useStyles();
  /**
   * Handle current popup by actions for `QuestActionsPopup` component
   * @type {String|Null}
   */
  const [actionPopup, setActionPopup] = useState(null);

  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedQuestId, setSelectedQuestId] = useState(null);

  /**
   * Stores table data
   * @type {Array[Object]}
   */
  const [tableData, setTableData] = useState([]);

  /**
   * Uses to avoid extra renders
   */
  useEffect(() => {
    setTableData(
      stats.questsStats.results.map((quest) => ({
        title: {
          content: (
            <TourName
              image={quest.image}
              title={quest.title}
              questId={quest.questId}
              formatMessage={formatMessage}
            />
          ),
          text: quest.title,
        },
        eventsCount: quest.eventsCount,
        lastModified: quest.lastModified,
        reviewsCount: quest.reviewsCount,
        purchasesCount: quest.purchasesCount,
        status: getProductStatus(formatMessage, quest.status),
        options: (
          <TourOptionsMenu
            productUrl={quest.productUrl}
            setSelectedProductId={setSelectedProductId}
            setSelectedQuestId={setSelectedQuestId}
            productId={quest.productId}
            questId={quest.questId}
            setActionPopup={setActionPopup}
            status={quest.status}
            quests={quests}
          />
        ),
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stats.questsStats.count,
    stats.questsStats.current,
    stats.questsStats.results.length,
    quests.length,
  ]);

  return (
    <ProfileContent>
      <HelmetTitle message={messages.tours} />
      <Typography variant="h5">
        {formatMessage(messages.tours)}
        <Button
          className={classes.createTourButton}
          variant="contained"
          color="primary"
          onClick={() => handleAddQuest()}
        >
          {layout !== 'md' ? (
            <AddIcon />
          ) : (
            <FormattedMessage
              id="ProfileTours.createTour"
              defaultMessage="Create tour"
            />
          )}
        </Button>
      </Typography>
      {selectedQuestId && selectedProductId && actionPopup && (
        <QuestActionsPopup
          type={actionPopup}
          formatMessage={formatMessage}
          productId={selectedProductId}
          quests={quests}
          questId={selectedQuestId}
          deleteQuest={deleteQuest}
          handleClose={() => setActionPopup(null)}
        />
      )}
      <div className={classes.tableContainer}>
        {stats.questsStats.count === null ? (
          <div className={classes.loaderContainer}>
            <Loader>
              <FormattedMessage
                id="ProfileTours.loadingTours"
                defaultMessage="Loading tours"
              />
            </Loader>
          </div>
        ) : (
          <Table
            fullWidth
            updateWidthOnTableChanged
            id="tours"
            keys="orderNumber"
            hidable={['options', 'title']}
            filters={DEFAULT_FILTERS}
            filterable={['title', 'lastModified', 'status']}
            header={getHeader(formatMessage)}
            rows={rowsFromHeader(
              getHeader(formatMessage),
              tableData,
              (id, cellData) => {
                switch (id) {
                  case 'title':
                    return tableCell(cellData.content, {
                      pure: cellData.text,
                      raw: cellData.text,
                    });
                  case 'lastModified': {
                    const dateString = cellData.slice(0, 10);
                    const date = parse(dateString, 'yyyy-MM-dd', new Date());
                    return tableCell(cellData, { raw: date });
                  }
                  case 'eventsCount':
                  case 'reviewsCount':
                  case 'purchasesCount':
                  case 'status':
                  case 'options':
                    return tableCell(cellData);
                  default:
                    return tableCell(cellData);
                }
              },
              true
            )}
          />
        )}
        <Pagination
          className={classes.pagination}
          currentPage={stats.questsStats.current}
          isLoading={stats.isFetching}
          pages={stats.questsStats.pages}
          onChange={requestQuestsStats}
        />
      </div>
    </ProfileContent>
  );
};

export default withAutoVerifying(ProfileTours);
