import React, { Component } from 'react';
import classNames from 'classnames';
import './Loader.css';

export class Loader extends Component {
  render() {
    const { error, className = 'Loader__row' } = this.props;

    return (
      <div className={className}>
        <div className="Loader__message">
          <div
            className={classNames(
              'Loader__preloader',
              error && 'Loader__preloader--error'
            )}
          />
          {this.props.children}
        </div>
      </div>
    );
  }
}

export class LoaderOverlay extends Component {
  render() {
    const { active, error, children, className } = this.props;

    if (!active) {
      return null;
    }

    return (
      <div className={classNames('Loader__overlay', className)}>
        <Loader error={error}>{children}</Loader>
      </div>
    );
  }
}

export default LoaderOverlay;
