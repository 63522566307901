import React from 'react';
import Chip from '@material-ui/core/Chip';

/**
 * Removable badge for displaying filter value
 * @param {Object} $
 * @param {String} $.className - additional CSS class name
 * @param {Function} $.onDeleted - calls when user cliched on badge delete button (calls by `Table::withFilters`)
 * @param {...Object} $.badgeProps - props passed to root `material-ui::Chip` component
 */
export default function TableFilterInfo({
  className,
  onDeleted = () => {},
  ...badgeProps
}) {
  return (
    <Chip
      {...badgeProps}
      className={className}
      size="small"
      onDelete={onDeleted}
    />
  );
}
