import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import getEventInfo from '../../../functions/event/getEventInfo';
import eventContentString from '../../../functions/event/eventContentString';
import withAction from '../withAction';
import StructuredPopup from '../StructuredPopup';

/**
 * `Popup` component with action buttons
 * @type {React::Component}
 */
const PopupAction = withAction(StructuredPopup);

/**
 * Common messages for `PopupRemoveEvent` component
 * @type {Object}
 */
const messages = defineMessages({
  remove: { id: 'PopupRemoveEvent.remove', defaultMessage: 'Delete' },
});

/**
 * Messages for `PopupRemoveEvent` component in `"point"` mode
 * @type {Object}
 */
const messagesPoint = defineMessages({
  title: {
    id: 'PopupRemoveEvent.titlePoint',
    defaultMessage: 'Delete point?',
  },
  removeContent: {
    id: 'PopupRemoveEvent.toDeletePoint',
    defaultMessage: 'Point contains:',
  },
});

/**
 * Messages for `PopupRemoveEvent` component in `"event"` mode
 * @type {Object}
 */
const messagesEvent = defineMessages({
  title: {
    id: 'PopupRemoveEvent.titleEvent',
    defaultMessage: 'Delete event?',
  },
  removeContent: {
    id: 'PopupRemoveEvent.toDeleteEvent',
    defaultMessage: 'Event contains:',
  },
});

/**
 * Popup for removing event or route point
 * @param {Object} $
 * @param {Object} $.event - event description from server (for event / route point to delete)
 * @param {String} $.type - what to delete (`"point"` or `"event"`), influences messages in popup
 * @param {Boolean} $.showContent - will show content of the event in popup
 * @param {Function} $.deleteItem - function will be called to delete item (point or event)
 * @param {...Object} $.popupProps - will be passed to `withAction(Popup)` component
 */
export default injectIntl(function PopupRemoveEvent({
  event,
  showContent = true,
  type = 'event',
  deleteItem = () => {},
  intl: { formatMessage },
  ...popupProps
}) {
  const messagesExtra = type === 'event' ? messagesEvent : messagesPoint;

  const eventContent = event
    ? eventContentString(
        getEventInfo(['image', 'audio', 'text', 'title'], event),
        formatMessage
      ).join(', ')
    : '';

  return (
    <PopupAction
      {...popupProps}
      variant="outlinedActionSecondary"
      title={formatMessage(messagesExtra.title)}
      actionText={formatMessage(messages.remove)}
      onAction={deleteItem}
    >
      {showContent
        ? `${formatMessage(messagesExtra.removeContent)} ${eventContent}`
        : null}
    </PopupAction>
  );
});
