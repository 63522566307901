import { defineMessages } from 'react-intl';

/**
 * Messages for questConstants
 * @type {Object}
 */
const messages = defineMessages({
  untitled: {
    id: 'Quest.untitledTour',
    defaultMessage: 'Untitled tour',
  },
});

/**
 * Returns title string
 * @param {String} title - tour title from store
 * @param {Function} formatMessage - `formatMessage` from `react-intl`
 * @returns {String} - tour title string
 */
export const getTourTitleString = (title, formatMessage) =>
  title || formatMessage(messages.untitled);
