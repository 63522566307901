import React, { useRef, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '../../Icon';

export const DropdownInput = ({
  label,
  value,
  options,
  onSelect,
  disabledOption,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef(null);

  const handleSelect = (option) => {
    if (option !== disabledOption) {
      onSelect(option);
    }
  };

  return (
    <div style={{ position: 'relative' }} ref={inputRef}>
      <div
        className="GenerateAiTourForm__inputContainer"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <input
          readOnly
          className={`GenerateAiTourForm__input ${
            value ? 'GenerateAiTourForm__input_active' : ''
          }`}
          value={value || ''}
          placeholder=" "
        />
        <label className="GenerateAiTourForm__label">{label}</label>
        <div className="GenerateAiTourForm__chevron">
          {!showDropdown ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </div>
        {showDropdown && (
          <div className="GenerateAiTourForm__popup">
            <ul style={{ listStyle: 'none', padding: 0 }}>
              {options.map((option, index) => (
                <li
                  className={`GenerateAiTourForm__listItem ${
                    option === disabledOption ? 'disabled' : ''
                  }`}
                  key={index}
                  onMouseDown={() => handleSelect(option)}
                  style={{
                    cursor:
                      option === disabledOption ? 'not-allowed' : 'pointer',
                    color: option === disabledOption ? 'gray' : 'black',
                  }}
                >
                  {option}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
