import {
  REQUEST_STATS,
  RECEIVE_STATS,
  NOT_RECEIVE_STATS,
  REQUEST_QUESTS_STATS,
  RECEIVE_QUESTS_STATS,
  NOT_RECEIVE_QUESTS_STATS,
  UPDATE_QUEST_STAT,
  SYNC_QUEST_STAT,
  FETCH_PAYOUTS,
  FETCH_PAYOUTS_SUCCESS,
  FETCH_PAYOUTS_FAILURE,
} from '../constants/ActionTypes';
import getPaginatedData from '../functions/getPaginatedData';

function createSummary(data) {
  return {
    downloaded: {
      all: data.map((ch) => ch.downloads).reduce((a, b) => a + b, 0),
    },
    inProgress: data.map((ch) => ch.inProgress).reduce((a, b) => a + b, 0),
    done: data.map((ch) => ch.passed).reduce((a, b) => a + b, 0),
  };
}

function questStats(checkpoints, questId) {
  return {
    questId,
    checkpoints,
    summary: createSummary(checkpoints),
  };
}

export default function stats(
  state = {
    stats: [],
    questsStats: {
      count: null,
      pages: null,
      current: null,
      next: null,
      results: [],
    },
    payouts: {
      count: null,
      pages: null,
      current: null,
      next: null,
      results: null,
      unpayedSum: null,
    },
    filter: 'ALL',
    isFetching: false,
  },
  action
) {
  let newQuestsStats;
  let newQuestStatsResults;

  switch (action.type) {
    case REQUEST_STATS:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_STATS:
      return {
        ...state,
        isFetching: false,
        stats: [...state.stats, questStats(action.payload, action.questId)],
      };

    case NOT_RECEIVE_STATS:
      return {
        ...state,
        isFetching: false,
        stats: [],
      };

    case REQUEST_QUESTS_STATS:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_QUESTS_STATS:
      newQuestsStats = getPaginatedData(action.data);
      return {
        ...state,
        isFetching: false,
        questsStats: newQuestsStats,
      };

    case NOT_RECEIVE_QUESTS_STATS:
      return {
        ...state,
        isFetching: false,
        stats: [],
      };

    case UPDATE_QUEST_STAT: {
      const currentListedQuest = state.questsStats.results.find(
        (quest) =>
          quest.questId === action.questId ||
          quest.productId === action.productId
      );
      if (currentListedQuest)
        newQuestStatsResults = state.questsStats.results.map((quest) =>
          quest.questId === currentListedQuest.questId
            ? { ...quest, ...action.data }
            : quest
        );
      else newQuestStatsResults = state.questsStats.results;

      return {
        ...state,
        questsStats: { ...state.questsStats, results: newQuestStatsResults },
      };
    }

    case SYNC_QUEST_STAT: {
      return {
        ...state,
        questsStats: {
          ...state.questsStats,
          results: state.questsStats.results.map((quest) =>
            quest.questId === action.questId ? { ...quest, ...action } : quest
          ),
        },
      };
    }

    case FETCH_PAYOUTS:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_PAYOUTS_SUCCESS: {
      const newPayouts = getPaginatedData(action.data);
      return {
        ...state,
        payouts: newPayouts,
        isFetching: false,
      };
    }

    case FETCH_PAYOUTS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
}
