// TODO: move to private npm package
export default {
  yandex: {
    checkout: async (data, { redirectUrl, paymentCode, analytics }) => {
      window.location.href = redirectUrl;
    },
  },
  makecommerce: {
    checkout: (
      {
        transaction: { amount, currency, reference: description },
        customer: { email },
      },
      { apiKey: key, transaction, shopName: name, redirectUrl },
      locale
    ) => {
      // TODO: open in popup
      window.location.href = redirectUrl;
      // return new Promise((resolve, reject) => {
      //   window.mkCancelled = (data) => {
      //     // TODO:show fail message
      //     reject(data);
      //   };

      //   window.mkComplete = (data) => {
      //     // Show access
      //   };

      //   window.Maksekeskus.Checkout.initialize({
      //     key,
      //     transaction,
      //     amount,
      //     currency,
      //     email,
      //     locale,
      //     name,
      //     description,
      //     completed: 'mkComplete',
      //     cancelled: 'mkCancelled'
      //   });

      //   window.Maksekeskus.Checkout.open();
      //   resolve();
      // });
    },
  },
};
