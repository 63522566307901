import get from 'lodash/get';
import xlsTemplate from './xlsTemplate';

/**
 * Converts table header to `xls` format
 * @param {Array[TableHeaderData]} header
 * @returns {String} - table header converted to XSL string
 */
function headerToXLS(header) {
  return `<tr>${header.map(({ title }) => `<th>${title}</th>`).join('')}</tr>`;
}

/**
 * Converts table body to `xls` format
 * @param {Array[TableHeaderData]} header
 * @param {Array[TableRowData]} rows
 * @returns {String} - table body converted to XSL string
 */
function rowsToXLS(header, rows) {
  return rows
    .map(
      (item) =>
        `<tr>${header
          .map(
            ({ id }) =>
              `<td>${item[id].pure ? item[id].pure : item[id].content}</td>`
          )
          .join('')}</tr>`
    )
    .join('');
}

/**
 * Converts table to XLS format
 * Makes provided `linkRef` to be link for downloading generated file
 * @param {React::Ref} linkRef - ref to the `<a/>` element to trigger download
 * @param {String} tableName - name of the table to save
 * @param {Array[TableHeaderData]} header - table header description
 * @param {Array[TableRowData]} data - table rows data description
 */
export default function exportToXLS(linkRef, tableName, header, data) {
  // Inspired by https://redstapler.co/convert-html-table-excel-file-javascript/
  const exportTableType = 'data:application/vnd.ms-excel';
  const exportTableTemplate = xlsTemplate(
    tableName,
    headerToXLS(header),
    rowsToXLS(header, data)
  );

  const { userAgent } = window.navigator;
  const isMSIE = userAgent.includes('MSIE ');
  const link = get(linkRef, 'current');

  if (isMSIE || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    if (window.navigator.msSaveBlob) {
      const blob = new Blob([exportTableTemplate], {
        type: 'application/csv;charset=utf-8;',
      });
      navigator.msSaveBlob(blob, `${tableName}.xls`);
    }
  } else if (link) {
    link.href = `${exportTableType}, ${encodeURIComponent(
      exportTableTemplate
    )}`;
    link.download = `${tableName}.xls`;
    link.click();
  }
}
