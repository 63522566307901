/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/sort-comp */
/* eslint-disable consistent-return */
/* eslint-disable react/no-deprecated */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Route, Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import HelmetTitle from '../HelmetTitle';
import * as QuestActions from '../../actions/quests';
import * as EventActions from '../../actions/events';
import * as UserActions from '../../actions/user';
import * as PublishingActions from '../../actions/publishing';
import Sidebar from '../Sidebar';
import Loader from '../Loader';
import EventWorkSpace from '../EventWorkSpace';
import Codes from '../Codes';
import { makeGetCurrentQuest } from '../../selectors';
import './EventPage.css';
import ScrollToTop from '../../functions/ScrollTop';
import { MAX_SELECTED_SUBCATEGORIES } from '../../constants/limits';
import TourContainer from '../TourContainer';
import { DEFAULT_PRICING_CATEGORY_ID } from '../../constants/prices';
import { isFinalCategoriesSet } from '../../functions/prices/isFinalCategoriesSet';
import getUniquePricingCategoriesLabels from '../../functions/prices/getUniquePricingCategoriesLabels';
import {
  getFinalCategories,
  getSumTotalRetailPricesForTickets,
} from '../../functions/prices';

class EventPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prices: {},
    };
  }

  componentWillUnmount() {
    const { resetState } = this.props;
    resetState();
  }

  componentDidMount() {
    this.selectQuestIfNeeded({}, this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.selectQuestIfNeeded(this.props, nextProps);
  }

  handleAddEvent(options) {
    const {
      currentQuest: { events },
      eventActions,
      match: {
        params: { questId },
      },
      history,
    } = this.props;
    eventActions.addEvent(options);
    history.push(`/quest/${questId}/events/${events.length - 1}/`);
  }

  getCurrentEvent() {
    const {
      params: { eventOrder = 0 },
      currentQuest: { events },
    } = this.props;
    const filtered = events.find(
      (event) => event.order === parseInt(eventOrder, 10)
    );
    return filtered || null;
  }

  setPrices = (newPricesState) => {
    this.setState({ prices: newPricesState });
  };

  renderLoader() {
    const { isFetching, isSending } = this.props;
    return (
      <div>
        <Loader active={isFetching || isSending}>
          {isFetching ? (
            <FormattedMessage
              id="EventPage.loadQuest"
              defaultMessage="Loading tour"
            />
          ) : null}
          {isSending ? (
            <FormattedMessage
              id="EventPage.syncQuests"
              defaultMessage="Sync tours"
            />
          ) : null}
        </Loader>
        {!isFetching ? this.renderError() : null}
      </div>
    );
  }

  checkFullness = (menuName) => {
    const {
      publishing: {
        receivedPublishData: {
          id,
          title,
          description,
          address,
          startLocation,
          finishAddress,
          finishLocation,
          distance,
          duration,
          images,
          locale,
          subcategories,
        },
        inclusions,
        exclusions,
        highlights,
        attractions,
        priceLevels,
      },
      currentQuest: { products },
    } = this.props;
    const { prices } = this.state;

    if (products[0].id === id)
      switch (menuName) {
        case 'title':
          return !!(title && locale);
        case 'description':
          return !!description;
        case 'locations':
          return !!(
            address &&
            startLocation &&
            ((finishAddress && finishLocation) ||
              (finishAddress === null && finishLocation === null)) &&
            attractions.length >= 1 &&
            distance &&
            duration
          );
        case 'details':
          return !!(inclusions.length && exclusions.length);
        case 'highlights':
          return !!(highlights.length >= 3);
        case 'photos':
          return !!(images.length >= 6);
        case 'audience':
          return !!(subcategories.length >= MAX_SELECTED_SUBCATEGORIES);
        case 'attractions':
          return !!(attractions.length > 0);
        case 'price': {
          const ticketsTotalRetailPricesSums = getSumTotalRetailPricesForTickets(
            attractions
          );
          const uniquePricingCategoriesLabels = getUniquePricingCategoriesLabels(
            attractions
          );
          const finalCategoriesLabels = getFinalCategories(
            uniquePricingCategoriesLabels
          );
          const isFinalCategoriesSetResult = isFinalCategoriesSet(
            finalCategoriesLabels,
            prices
          );
          const isGuidePriceSet =
            !!priceLevels.length &&
            priceLevels.every(({ pricingCategoryId, price }) =>
              priceLevels.length === 1
                ? pricingCategoryId === DEFAULT_PRICING_CATEGORY_ID && price > 0
                : price > 0
            );

          return ticketsTotalRetailPricesSums.length > 0
            ? isFinalCategoriesSetResult &&
                isGuidePriceSet &&
                ticketsTotalRetailPricesSums.every(
                  (pc) => pc.totalRetailPriceSum > 0
                )
            : isGuidePriceSet;
        }
        default:
          break;
      }
    else {
      return false;
    }
  };

  selectQuestIfNeeded(props, nextProps) {
    const { match: { params: { questId } = {} } = {} } = props;
    const {
      currentQuestId = 'null',
      questActions: { selectQuest },
      match: { params: { questId: nextQuestId } } = {},
    } = nextProps;

    if (
      questId !== nextQuestId &&
      currentQuestId.toString() !== nextQuestId.toString()
    ) {
      selectQuest(nextQuestId);
      // this.forceUpdate(); //TODO: check for nested changes
    }
  }

  renderError() {
    return (
      <div className="EventPage__main">
        <div className="event__error">
          <Loader active error>
            <FormattedMessage
              id="EventPage.errorMessage"
              defaultMessage="There is no such tour."
            />{' '}
            <Link to="/" className="link--blue">
              <FormattedMessage
                id="EventPage.allQuests"
                defaultMessage="All tours →"
              />
            </Link>
          </Loader>
        </div>
      </div>
    );
  }

  render() {
    const {
      currentQuest,
      eventActions,
      questActions,
      user,
      userActions,
      currentPlans,
      publishingActions,
      params,
      isSidebarOpen,
      closeSidebar,
      isPopupOpen,
      openPopup,
      closePopup,
      isResizeBlock,
      toggleResizeBlock,
      publishing,
      locale,
    } = this.props;
    const { prices } = this.state;

    if (!currentQuest) return this.renderLoader();
    const { title = '' } = currentQuest;
    const event = this.getCurrentEvent(); // TODO: handle error
    if (isEmpty(event)) return this.renderLoader();

    const message = defineMessages({
      title: {
        id: 'EvenPage.titleTag',
        defaultMessage: 'Tour',
      },
    });
    // TODO: move to reducer
    return (
      <div className="App__row">
        <HelmetTitle message={message.title} value={title} />
        <Sidebar
          params={params}
          currentQuest={currentQuest}
          questActions={questActions}
          user={user}
          eventActions={eventActions}
          userActions={userActions}
          currentPlans={currentPlans}
          publishingActions={publishingActions}
          isSidebarOpen={isSidebarOpen}
          closeSidebar={closeSidebar}
          isPopupOpen={isPopupOpen}
          openPopup={openPopup}
          closePopup={closePopup}
          currentEvent={event}
          isResizeBlock={isResizeBlock}
          toggleResizeBlock={toggleResizeBlock}
          publishing={publishing}
          checkFullness={this.checkFullness}
          prices={prices}
        />
        <Route
          path="/quest/:questId/events/:eventOrder/:anchor?"
          render={(props) => (
            <EventWorkSpace
              user={user}
              questActions={questActions}
              eventActions={eventActions}
              currentEvent={event || {}}
              currentQuest={currentQuest}
              handleAddEvent={this.handleAddEvent.bind(this)}
              isSidebarOpen={isSidebarOpen}
              closeSidebar={closeSidebar}
              isPopupOpen={isPopupOpen}
              {...props}
            />
          )}
        />
        <ScrollToTop />
        <Route path="/quest/:questId/codes/" component={Codes} />
        <TourContainer
          publishing={publishing}
          checkFullness={this.checkFullness}
          currentQuest={currentQuest}
          locale={locale}
          publishingActions={publishingActions}
          prices={prices}
          setPrices={this.setPrices.bind(this)}
        />
      </div>
    ); // TODO: use routes components instead this ternary
  }
}

function makeMapStateToProps() {
  const getCurrentQuest = makeGetCurrentQuest();
  const mapStateToProps = (state, props) => {
    return {
      user: state.user,
      quests: state.quests.quests,
      currentQuest: getCurrentQuest(state, props),
      currentQuestId: state.quests.currentQuestId,
      isFetching: state.quests.isFetching,
      isSending: state.quests.isSending,
      currentPlans: state.publishing.currentPlans,
      publishing: state.publishing,
    };
  };
  return mapStateToProps;
}

function mapDispatchToProps(dispatch) {
  return {
    questActions: bindActionCreators(QuestActions, dispatch),
    eventActions: bindActionCreators(EventActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
    publishingActions: bindActionCreators(PublishingActions, dispatch),
  };
}

EventPage.propTypes = {
  questActions: PropTypes.object.isRequired,
  eventActions: PropTypes.object.isRequired,
  userActions: PropTypes.object.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  toggleResizeBlock: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
};

export default withRouter(
  connect(makeMapStateToProps, mapDispatchToProps)(EventPage)
);
