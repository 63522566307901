import messagesEventContent from '../../constants/events/intlContent';

/**
 * Forms string with info about event content
 * @param {Object} eventContent - event content from `getEventInfo` function
 * @param {Function} formatMessage - `formatMessage` function from `react-intl`
 * @param {Object?} dictionary - `react-intl` translations with keys as in `messagesEventContent` (default are `messagesEventContent`)
 * @returns {Array[String]}
 */
export default function eventContentString(
  eventContent,
  formatMessage,
  dictionary = messagesEventContent
) {
  return Object.keys(eventContent)
    .map(
      (contentType) =>
        eventContent[contentType] &&
        formatMessage(dictionary[contentType], {
          amount: eventContent[contentType],
        })
    )
    .filter((c) => c);
}
