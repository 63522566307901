import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { NavLink, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  IconButton,
  ListItemSecondaryAction,
  makeStyles,
  Typography,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import CheckIcon from '@material-ui/icons/Check';
import {
  MUSEUM_POINTS_AMOUNT,
  MIN_POINTS_AMOUNT,
} from '../../../constants/routePoints';
import SidebarSublistEvents from '../SidebarSublistEvents';

/**
 * JSS styles for `SidebarItems` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  buttonToggled: {
    backgroundColor: 'rgba(0,125,255,.1)',
  },
  sidebarGroupFirst: {
    color: '#696969',
    paddingTop: 17,
    paddingBottom: 17,
  },
  sidebarGroup: {
    borderTop: 'solid 0.5px #f0f0f0',
    color: '#696969',
    paddingTop: 17,
    paddingBottom: 17,
  },
  sidebarGroupToggled: {
    backgroundColor: 'rgba(0,125,255,.1)',
    borderTop: 'solid 0.5px #f0f0f0',
    color: '#696969',
    paddingTop: 17,
    paddingBottom: 17,
  },
  counter: {
    color: '#696969',
  },
  counterBlue: {
    color: theme.palette.primary.main,
  },
  checkIcon: {
    display: 'inline',
    fontSize: 15,
    marginRight: 5,
    marginBottom: -2,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

/**
 * Expandable lists with links to the tour construction pages
 * @param {Object} $
 * @param {String} $.questId - id of the quest loaded on the page
 * @param {Object} $.location - object with current URL pathname
 * @param {Object} $.currentQuest - current quest description (from server)
 * @param {Object} $.publishing - publishing info
 * @param {Object} $.questActions - store actions for operating with quests
 * @param {Function} $.questActions.reorderEvents - reorders events
 * @param {Object} $.eventActions - store actions for operating with events
 * @param {Function} $.eventActions.addEvent - event adding store action
 * @param {Function} $.closeSidebar - called every time link changes
 * @param {Function} $.checkFullness - for checking menu's fullness
 * @param {Object} $.prices - prices by keys, added for right rerenders with first load quest
 */
const SidebarItems = ({
  user,
  questId,
  location,
  closeSidebar,
  currentQuest,
  eventActions = {},
  questActions = {},
  publishing,
  checkFullness,
  prices,
}) => {
  /**
   * Shown/hidden state for expandable lists
   * Key is list id, value is status (shown/hidden)
   * @type {Object}
   */
  const [state, setState] = useState({
    isMainInfoListOpen: false,
    isTourContentOpen: false,
    isProductCardOpen: false,
  });

  /**
   * JSS styles for `SidebarItems` element
   * @type {React::Hook}
   */
  const styles = useStyles();

  /**
   * Toggles show/hide state of the list with `name` id
   * @param {String} name - id of the list in the `state` object
   */
  const handleListOpen = (name) => {
    setState({ ...state, [name]: !state[name] });
  };

  /**
   * Amount of route points bound to events in the current quest
   * @type {Number}
   */
  const routePointsAmount = get(currentQuest, 'routes.length', 0);

  /**
   * Required amount of route points
   * @type {Number}
   */
  const requiredPointsAmount = currentQuest.isMuseum
    ? MUSEUM_POINTS_AMOUNT
    : MIN_POINTS_AMOUNT;

  /**
   * Opens the desired sidebar menu
   */
  useEffect(() => {
    if (/\b(title)|(description)\b/i.test(location.pathname)) {
      setState({ ...state, isMainInfoListOpen: true });
    }
    if (/\b(map)|(events)\b/i.test(location.pathname)) {
      setState({ ...state, isTourContentOpen: true });
    }
    if (
      /\b(locations)|(highlights)|(details)|(photos)|(audience)|(price)|(check)\b/i.test(
        location.pathname
      )
    ) {
      setState({ ...state, isProductCardOpen: true });
    }
  }, [location.pathname]);

  /**
   * Counts filled fields
   * @param {String} menuName - menu name for check
   */
  const handleCounter = (menuName) => {
    switch (menuName) {
      case 'main':
        return checkFullness('title') + checkFullness('description');
      case 'product':
        return (
          checkFullness('locations') +
          checkFullness('highlights') +
          checkFullness('details') +
          checkFullness('photos') +
          checkFullness('audience') +
          checkFullness('price')
        );
      default:
        break;
    }
  };

  return (
    <List
      className={styles.list}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItem
        button
        className={styles.sidebarGroupFirst}
        onClick={() => handleListOpen('isMainInfoListOpen')}
      >
        <Typography variant="subtitle2">
          <ListItemText
            disableTypography
            primary={
              <FormattedMessage
                id="SidebarItems.mainInformation"
                defaultMessage="Main information"
              />
            }
          />
        </Typography>
        <ListItemSecondaryAction>
          <Typography
            display="inline"
            variant="subtitle2"
            className={
              handleCounter('main') === 2 ? styles.counterBlue : styles.counter
            }
          >
            {`${handleCounter('main')}/2`}
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse unmountOnExit in={state.isMainInfoListOpen} timeout="auto">
        <List disablePadding component="div">
          <NavLink to={`/quest/${questId}/title`} onClick={closeSidebar}>
            <ListItem
              button
              className={
                /\b(title)\b/i.test(location.pathname)
                  ? styles.buttonToggled
                  : ''
              }
            >
              <ListItemText
                primary={
                  <FormattedMessage
                    id="SidebarItems.titleAndLanguage"
                    defaultMessage="Title and language"
                  />
                }
              />
              {checkFullness('title') && (
                <ListItemSecondaryAction>
                  <IconButton edge="end">
                    <CheckIcon color="primary" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </NavLink>

          <NavLink to={`/quest/${questId}/description`} onClick={closeSidebar}>
            <ListItem
              button
              className={
                /\b(description)\b/i.test(location.pathname)
                  ? styles.buttonToggled
                  : ''
              }
            >
              <ListItemText
                primary={
                  <FormattedMessage
                    id="SidebarItems.description"
                    defaultMessage="Description"
                  />
                }
              />
              {checkFullness('description') && (
                <ListItemSecondaryAction>
                  <IconButton edge="end">
                    <CheckIcon color="primary" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </NavLink>
        </List>
      </Collapse>

      <ListItem
        button
        className={styles.sidebarGroup}
        onClick={() => handleListOpen('isTourContentOpen')}
      >
        <Typography variant="subtitle2">
          <ListItemText
            disableTypography
            primary={
              <FormattedMessage
                id="SidebarEvents.title"
                defaultMessage="Tour content"
              />
            }
          />
        </Typography>
        <ListItemSecondaryAction>
          {routePointsAmount <= requiredPointsAmount && (
            <Typography
              display="inline"
              className={
                routePointsAmount >= requiredPointsAmount
                  ? styles.counterBlue
                  : styles.counter
              }
              variant="subtitle2"
            >{`${routePointsAmount}/${requiredPointsAmount}`}</Typography>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <SidebarSublistEvents
        user={user}
        questId={questId}
        currentQuest={currentQuest}
        route={currentQuest.routes}
        events={currentQuest.events}
        questActions={questActions}
        eventActions={eventActions}
        shown={state.isTourContentOpen}
        onClicked={closeSidebar}
      />

      <ListItem
        button
        className={styles.sidebarGroup}
        onClick={() => handleListOpen('isProductCardOpen')}
      >
        <Typography variant="subtitle2">
          <ListItemText
            disableTypography
            primary={
              <FormattedMessage
                id="SidebarItems.tourCard"
                defaultMessage="Product design"
              />
            }
          />
        </Typography>
        <ListItemSecondaryAction>
          <Typography
            display="inline"
            className={
              handleCounter('product') === 6
                ? styles.counterBlue
                : styles.counter
            }
            variant="subtitle2"
          >{`${handleCounter('product')}/6`}</Typography>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse unmountOnExit in={state.isProductCardOpen} timeout="auto">
        <List disablePadding component="div">
          <NavLink to={`/quest/${questId}/locations`} onClick={closeSidebar}>
            <ListItem
              button
              className={
                /\b(locations)\b/i.test(location.pathname)
                  ? styles.buttonToggled
                  : ''
              }
            >
              <ListItemText
                primary={
                  <FormattedMessage
                    id="SidebarItems.locations"
                    defaultMessage="What the traveler will visit"
                  />
                }
              />
              {checkFullness('locations') && (
                <ListItemSecondaryAction>
                  <IconButton edge="end">
                    <CheckIcon color="primary" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </NavLink>

          <NavLink to={`/quest/${questId}/highlights`} onClick={closeSidebar}>
            <ListItem
              button
              className={
                /\b(highlights)\b/i.test(location.pathname)
                  ? styles.buttonToggled
                  : ''
              }
            >
              <ListItemText
                primary={
                  <FormattedMessage
                    id="SidebarItems.highlights"
                    defaultMessage="Highlights"
                  />
                }
              />
              {checkFullness('highlights') && (
                <ListItemSecondaryAction>
                  <IconButton edge="end">
                    <CheckIcon color="primary" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </NavLink>

          <NavLink to={`/quest/${questId}/details`} onClick={closeSidebar}>
            <ListItem
              button
              className={
                /\b(details)\b/i.test(location.pathname)
                  ? styles.buttonToggled
                  : ''
              }
            >
              <ListItemText
                primary={
                  <FormattedMessage
                    id="SidebarItems.details"
                    defaultMessage="Tour details"
                  />
                }
              />{' '}
              {checkFullness('details') && (
                <ListItemSecondaryAction>
                  <IconButton edge="end">
                    <CheckIcon color="primary" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </NavLink>

          <NavLink to={`/quest/${questId}/photos`} onClick={closeSidebar}>
            <ListItem
              button
              className={
                /\b(photos)\b/i.test(location.pathname)
                  ? styles.buttonToggled
                  : ''
              }
            >
              <ListItemText
                primary={
                  <FormattedMessage
                    id="SidebarItems.photos"
                    defaultMessage="Photos"
                  />
                }
              />
              {checkFullness('photos') && (
                <ListItemSecondaryAction>
                  <IconButton edge="end">
                    <CheckIcon color="primary" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </NavLink>

          <NavLink to={`/quest/${questId}/audience`} onClick={closeSidebar}>
            <ListItem
              button
              className={
                /\b(audience)\b/i.test(location.pathname)
                  ? styles.buttonToggled
                  : ''
              }
            >
              <ListItemText
                primary={
                  <FormattedMessage
                    id="SidebarItems.audience"
                    defaultMessage="Tour audience"
                  />
                }
              />
              {checkFullness('audience') && (
                <ListItemSecondaryAction>
                  <IconButton edge="end">
                    <CheckIcon color="primary" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </NavLink>

          <NavLink to={`/quest/${questId}/price`} onClick={closeSidebar}>
            <ListItem
              button
              className={
                /\b(price)\b/i.test(location.pathname)
                  ? styles.buttonToggled
                  : ''
              }
            >
              <ListItemText
                primary={
                  <FormattedMessage
                    id="SidebarItems.price"
                    defaultMessage="Price"
                  />
                }
              />
              {checkFullness('price') && (
                <ListItemSecondaryAction>
                  <IconButton edge="end">
                    <CheckIcon color="primary" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </NavLink>
        </List>
      </Collapse>

      <NavLink to={`/quest/${questId}/check`} onClick={closeSidebar}>
        <ListItem
          disableTypography
          button
          className={
            /\b(check)\b/i.test(location.pathname)
              ? styles.sidebarGroupToggled
              : styles.sidebarGroup
          }
        >
          <Typography variant="subtitle2">
            <ListItemText
              disableTypography
              primary={
                <FormattedMessage
                  id="SidebarItems.checking"
                  defaultMessage="Checking"
                />
              }
            />
          </Typography>
        </ListItem>
      </NavLink>

      <NavLink
        to={
          publishing.receivedPublishData.status &&
          publishing.receivedPublishData.status !== 'draft'
            ? `/quest/${questId}/publish`
            : '#'
        }
        onClick={closeSidebar}
      >
        <ListItem
          button
          disabled={
            !(
              publishing.receivedPublishData.status &&
              publishing.receivedPublishData.status !== 'draft'
            )
          }
          className={
            /\b(publish)\b/i.test(location.pathname)
              ? styles.sidebarGroupToggled
              : styles.sidebarGroup
          }
        >
          <Typography variant="subtitle2">
            <ListItemText
              disableTypography
              primary={
                <FormattedMessage
                  id="SidebarItems.publish"
                  defaultMessage="Publish"
                />
              }
            />
          </Typography>
        </ListItem>
      </NavLink>
    </List>
  );
};

export default React.memo(withRouter(SidebarItems));
