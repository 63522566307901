import { takeEvery, takeLatest, put, call, select } from 'redux-saga/effects';
import {
  FirstSteps,
  Workspace,
  FillingEvent,
} from '../constants/tutorials/TutorialsName';
import * as types from '../constants/ActionTypes';
import CachedTutorial from '../functions/cachedTutorial';

const WorkspaceFirstStepsRegexp = /^(\/{1}quest\/{1})(\d+\/{1})(events\/{1})(\d+\/?)$/g;
const WorkspaceTutorialRunRegexp = (pathname, tutorialAnchor) => {
  return pathname.match(
    new RegExp(
      `^(/{1}quest/{1})(\\d+/{1})(events/{1})(\\d+/?)(${tutorialAnchor})$`,
      'g'
    )
  );
};

function* finishTutorial(action) {
  const { name, finished } = action;
  const savingData = { name, finished };
  const unFinishedTutorialsCount = yield select(
    (state) => state.tutorial.unFinishedTutorialsCount
  );
  try {
    yield call(CachedTutorial.saveToCache, savingData);
    const unFinishedTutorialsCountFromCache = CachedTutorial.getUnFinishedTutorialsCountFromCache();
    if (unFinishedTutorialsCount !== unFinishedTutorialsCountFromCache) {
      yield put({
        type: types.UPDATE_UNFINISHED_TUTORIALS_COUNT,
        unFinishedTutorialsCount: unFinishedTutorialsCountFromCache,
      });
    }
  } catch (error) {
    yield put({
      type: types.RESET_TUTORIAL,
    });
  }
}

function* closeTutorial(action) {
  const { name, skipped } = action;
  const savingData = { name, skipped };
  try {
    yield call(CachedTutorial.saveToCache, savingData);
  } catch (error) {
    yield put({
      type: types.RESET_TUTORIAL,
    });
  }
}

function* startTutorial(action) {
  const pathname = yield select((state) => state.router.location.pathname);
  const questsCount = yield select((state) => state.quests.quests.length);

  try {
    // TODO: iterate over tutorial conditions
    yield put({
      type: types.RESET_TUTORIAL,
    });
    if (pathname === '/firststeps') {
      yield put({
        type: types.CREATE_TUTORIAL,
        name: FirstSteps,
      });
    } else if (WorkspaceTutorialRunRegexp(pathname, 'workspace')) {
      yield put({
        type: types.CREATE_TUTORIAL,
        name: Workspace,
      });
    } else if (
      questsCount < 3 &&
      pathname.match(WorkspaceFirstStepsRegexp) !== null
    ) {
      const isSkippedOrFinished = yield call(
        CachedTutorial.getTutorialDataFromCache,
        Workspace
      );
      if (isSkippedOrFinished) return;
      yield put({
        type: types.CREATE_TUTORIAL,
        name: Workspace,
      });
    } else if (WorkspaceTutorialRunRegexp(pathname, 'fillingevent')) {
      yield put({
        type: types.CREATE_TUTORIAL,
        name: FillingEvent,
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: types.RESET_TUTORIAL,
    });
  }
}

export default function* watchTutorial() {
  yield takeEvery('@@router/LOCATION_CHANGE', startTutorial);
  yield takeLatest(types.CLOSE_TUTORIAL, closeTutorial);
  yield takeLatest(types.FINISH_TUTORIAL, finishTutorial);
}
