/**
 * Amount of route points tour must contain if it is museum tour
 * @type {Number}
 */
export const MUSEUM_POINTS_AMOUNT = 1;

/**
 * Amount of route points tour must contain
 * @type {Number}
 */
export const MIN_POINTS_AMOUNT = 10;
