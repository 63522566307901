import React, { Component } from 'react';
import classNames from 'classnames';
import './Checkbox.css';

class Checkbox extends Component {
  render() {
    const { name, className, onChange, checked } = this.props;
    return (
      <div className={classNames('checkbox__wrapper', className)}>
        <label>
          <input
            type="checkbox"
            name={name}
            className="checkbox__input"
            checked={checked}
            onChange={onChange}
          />
          <div className="checkbox__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="61"
              height="44"
              viewBox="0 0 61 44"
              version="1.1"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M59.5 1.8C57.8 0.1 55.1 0.1 53.5 1.8L22 33.3 7.6 21.7C5.9 20.1 3.3 20.1 1.6 21.7 0 23.4 0 26 1.6 27.7L19 42.2C19.8 43 20.9 43.5 22 43.5 23.1 43.5 24.1 43 25 42.2L59.5 7.7C61.1 6.1 61.1 3.4 59.5 1.8L59.5 1.8Z" />
              </g>
            </svg>
          </div>
        </label>
      </div>
    );
  }
}

export default Checkbox;
