/**
 * Colors for material theme of the app
 * @type {MaterialUI::ThemePalette}
 */
export default {
  primary: {
    main: '#007dff',
    dark: '#0057b2',
    border: '#80beff',
    light: '#3397ff',
    background: 'rgba(0, 125, 255, 0.08)',
  },
  secondary: {
    main: '#ff505f',
    dark: '#b23842',
    light: '#ff737f',
  },
  error: {
    main: '#f44336',
    dark: '#e31B0C',
    light: '#f88078',
    background: 'rgba(255, 80, 95, 0.08)',
  },
  actions: {
    main: '#e0e0e0',
    background: '#ebebeb',
    disabledText: '#bdbdbd',
  },
  text: {
    primary: '#212121',
    secondary: '#757575',
    disabled: '#9e9e9e',
  },
  background: {
    main: '#ffffff',
  },
  info: {
    main: '#2196f3',
    textDark: '#0d3c61',
    background: '#e9f5fe',
  },
};
