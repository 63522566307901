import { defineMessages } from 'react-intl';

/**
 * Messages describing payment methods
 * @type {Object}
 */
export default defineMessages({
  UN: {
    id: 'payment.methodUnknown',
    defaultMessage: 'Unknown',
  },
});
