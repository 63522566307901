import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from 'react-daterange-picker';
import {
  FormattedMessage,
  defineMessages,
  injectIntl,
  intlShape,
} from 'react-intl';
import classNames from 'classnames';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import QuickRangeSelection from '../QuickRangeSelection';
import Button from '../Button';
import InputLight from '../InputLight';
import { isSameYear, isSameMonth, isSameDay } from '../../functions/dateUtils';
import { Desktop } from '../../functions/queries';
import './react-calendar.css';
import './DateRange.css';

const dateInputFormat = 'DD.MM.YYYY';
const dateRequestFormat = 'YYYY-MM-DD';

const messages = defineMessages({
  startRangeInputPlaceholder: {
    id: 'DateRange.startRangeInputPlaceholder',
    defaultMessage: dateInputFormat,
  },

  endRangeInputPlaceholder: {
    id: 'DateRange.endRangeInputPlaceholder',
    defaultMessage: dateInputFormat,
  },
});

const DATE_REGEXP = /^(3[01]|[12][0-9]|0[1-9])\.(1[012]|0[1-9])\.(20\d{2})$/;

class DateRange extends Component {
  constructor(props) {
    super(props);

    const {
      defaultRange: {
        rangeDateObject,
        momentDateObject: { start, end },
      },
      locale,
    } = props;

    this.state = {
      isOpen: false,
      range: rangeDateObject,
      formattedRange: {
        startRange: start.format(dateInputFormat),
        endRange: end.format(dateInputFormat),
      },
      isInputValueValid: true,
    };

    this.moment = extendMoment(originalMoment);
    this.moment.locale(locale);
  }

  togglePicker = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        isOpen: !prevState.isOpen,
      };
    });
  };

  setRange = (props) => {
    const { start, end } = props;

    this.setState((prevState) => {
      return {
        ...prevState,
        range: props,
        formattedRange: {
          startRange: start.format(dateInputFormat),
          endRange: end.format(dateInputFormat),
        },
      };
    });
  };

  fetchData = (startRange, endRange) => {
    const { fetchData } = this.props;

    fetchData({
      startTs: startRange.format(dateRequestFormat),
      endTs: endRange.format(dateRequestFormat),
    });
  };

  setRangeAndFetch = (props) => {
    const { start, end } = props;

    this.setRange(props);
    this.fetchData(start, end);
  };

  renderDefaultRanges = (quickRanges) => {
    return (
      <QuickRangeSelection
        dates={quickRanges}
        range={this.state.range}
        onSelect={this.setRangeAndFetch}
      />
    );
  };

  renderDateRangeText = () => {
    const {
      range: { start, end },
    } = this.state;

    return isSameDay(start, end) ? (
      start.format('D MMM YYYY')
    ) : isSameMonth(start, end) ? (
      <Fragment>
        {start.format('D')} {'\u2014'} {end.format('D MMM YYYY')}
      </Fragment>
    ) : isSameYear(start, end) ? (
      <Fragment>
        {start.format('D MMM')} {'\u2014'} {end.format('D MMM YYYY')}
      </Fragment>
    ) : (
      <Fragment>
        {start.format('D MMM YYYY')} {'\u2014'} {end.format('D MMM YYYY')}
      </Fragment>
    );
  };

  handleChangeInputRange = (name, event) => {
    if (!this.hasOwnProperty(`_${name}`)) return;
    const obj = {};
    obj[name] = event.target.value;
    this.setState((prevState) => {
      return {
        ...prevState,
        isInputValueValid: true,
        formattedRange: {
          ...prevState.formattedRange,
          ...obj,
        },
      };
    });
  };

  handlerInputError = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        isInputValueValid: false,
      };
    });
  };

  applyRangeAndFetch = () => {
    const { startRange, endRange } = this.state.formattedRange;

    if (![startRange, endRange].every((value) => DATE_REGEXP.test(value))) {
      return this.handlerInputError();
    }

    const momentStart = this.moment(startRange, dateInputFormat);
    const momentEnd = this.moment(endRange, dateInputFormat);

    if (!momentStart.isBefore(momentEnd)) {
      return this.handlerInputError();
    }

    this.setRange(this.moment.range(momentStart, momentEnd));
    this.fetchData(momentStart, momentEnd);
    this.togglePicker();
  };

  renderDateRangePicker = () => {
    const {
      isInputValueValid,
      formattedRange: { startRange, endRange },
    } = this.state;

    const {
      locale = 'en',
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="DateRange__date">
        <Button
          theme="main-light"
          className="DateRange__range"
          onClick={this.togglePicker}
        >
          {this.renderDateRangeText()}
        </Button>
        {this.state.isOpen && (
          <div className="DateRange__picker">
            <DateRangePicker
              singleDateRange
              firstOfWeek={1}
              locale={locale}
              numberOfCalendars={2}
              value={this.state.range}
              selectionType="range"
              onSelect={this.setRange}
            />
            <div className="DateRange__picker-submit">
              <InputLight
                inputRef={(input) => (this._startRange = input)}
                className={classNames(
                  'DateRange__picker-input',
                  !isInputValueValid && 'DateRange__picker-input--error'
                )}
                value={startRange}
                placeholder={formatMessage(messages.startRangeInputPlaceholder)}
                onChange={this.handleChangeInputRange.bind(this, 'startRange')}
              />{' '}
              {'\u2014'}
              <InputLight
                inputRef={(input) => (this._endRange = input)}
                className={classNames(
                  'DateRange__picker-input',
                  !isInputValueValid && 'DateRange__picker-input--error'
                )}
                value={endRange}
                placeholder={formatMessage(messages.endRangeInputPlaceholder)}
                onChange={this.handleChangeInputRange.bind(this, 'endRange')}
              />
              <Button
                className="DateRange__picker-button"
                theme="main-light"
                onClick={this.applyRangeAndFetch}
              >
                <FormattedMessage id="DateRange.apply" defaultMessage="Apply" />
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { enableQuickRanges = false, quickRanges = null } = this.props;
    return (
      <div className="DateRange__inner">
        {enableQuickRanges ? (
          <div className="DateRange__ranges">
            {this.renderDefaultRanges(quickRanges)}
          </div>
        ) : null}
        {enableQuickRanges ? (
          <Desktop>{this.renderDateRangePicker()}</Desktop>
        ) : (
          this.renderDateRangePicker()
        )}
      </div>
    );
  }
}

DateRange.propTypes = {
  enableQuickRanges: PropTypes.bool,
  quickRanges: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.object.isRequired,
      range: PropTypes.object.isRequired,
    })
  ),
  locale: PropTypes.string,
  fetchData: PropTypes.func,
  intl: intlShape.isRequired,
};
export default injectIntl(DateRange);
