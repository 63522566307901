import React, { Component } from 'react';
import get from 'lodash/get';
import hasProp from 'lodash/has';
import merge from 'lodash/merge';
import classNames from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';
import { latLng } from 'leaflet';
import {
  Map as LeafletMap,
  TileLayer,
  ZoomControl,
  LayersControl,
} from 'react-leaflet';
import { Desktop } from '../../functions/queries';
import getTileSetId from '../../functions/map/getTileSetId';
import appendPixelOffset from '../../functions/map/appendPixelOffset';
import { TILESET_URL, ATTRIBUTION } from '../../constants/map/mapbox';
import GeolocationControl from '../GeolocationControl';
import MapSearchControl from './MapSearchControl';
import MapPositionSelector from './MapPositionSelector';
import MapPin from './MapPin';
import './leaflet.css';
import './Map.css';

/**
 * Point on the map
 * @typedef {Object} MapPoint
 * @prop {String} address - address on the map
 * @prop {Number} lat - latitude
 * @prop {Number} lon - longitude
 */

/**
 * Positions of the layer controls above the map
 * For possible values of props see `position` prop on the suitable control
 * @typedef {Object} MapPositions
 * @prop {String} zoom - position of the `Leaflet::ZoomControl`
 * @prop {String} geo - position of the `GeolocationControl`
 * @prop {String} search - position of the `GeocodingControl`
 */

/**
 * Default positions of the controls above the map
 * @type {MapPositions}
 */
const DEFAULT_POSITIONS = {
  search: 'topleft',
  geo: 'bottomleft',
  zoom: 'bottomleft',
};

const message = defineMessages({
  locationName: {
    id: 'Map.titleIfDrag',
    defaultMessage: 'Specify a point...',
  },
});

/**
 * Map with ability to select points and search for places
 * @param {Object} $
 * @param {String} $.theme - CSS theme of the map, affects controls and geosearch (`"material"`)
 * @param {Object?} $.leafletMap - props passing directly to the `ReactLeaflet::LeafletMap`
 * @param {Object} $.coords - center point of the map
 * @param {Object} $.positions - positions of the leaflet map controls (use `null`/`undefined` at suitable prop to hide control)
 * @param {Object} $.search - params for `GeocodingControl` component (search)
 * @param {Number?} $.radius - radius of circle around center point (if `centerIsPin` provided), will not be shown if `undefined` passed
 * @param {String} $.locationName - name of the last searched location
 * @param {React::Component|Array[React::Component]} $.children - additional children for `react-leaflet` map
 * @param {Function} $.onSelect - calls when point on map is selected
 * @param {Boolean} $.active - if map is clickable (able to place new MapPins)
 * @param {Boolean} $.centerIsPin - use coords in `center` prop as a pin coords (also will trigger `onSelect` when center of map changes)
 * @param {Boolean} $.forceRefUpdate - rerender component after refs were mounted (if search control has not appeared)
 * @param {Function} $.onLeafletRef - leaflet js map instance was created (passes as an argument to `onLeafletRef` callback)
 */
class Map extends Component {
  /**
   * Ref to the root `.Map` element of the component
   * @type {React::Ref}
   */
  rootRef = React.createRef();

  /**
   * Ref to the root `.MapSearchControl` element of the component
   * @type {React::Ref}
   */
  searchRef = React.createRef();

  /**
   * Height in `px` of the marker following mouse when `MapPositionSelector` os active
   * @type {Number}
   */
  selectorHeight = 54;

  /**
   * Checks if any click on map will choose button (no position select control active)
   * @param {Object} positions - positions of the controls from `props`
   * @returns {Boolean}
   */
  isManualClick = (positions) => {
    return !!(positions && positions.pos);
  };

  /**
   * Inner state of the component
   * @type {Object}
   * @prop {Number} zoom - zoom coefficient value
   * @prop {Boolean} mapIsDragging - if map is currently moving by dragging
   * @prop {String} tileSetId - id of the set of MapBox tiles will be displayed on map
   */
  state = {
    zoom: this.props.zoom || 16,
    mapIsDragging: false,
    refsForced: false,
    clickable: !this.isManualClick(this.props.positions),
    tileSetId: getTileSetId(this.props.intl.locale),
  };

  componentDidMount() {
    const { onMapAPI = () => {} } = this.props;
    onMapAPI({
      togglePositionSelection: this.togglePositionSelection,
    });
  }

  /**
   * Resetting `clickable` value by valuing manual triggering position selection on map with click
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    const { positions = {}, zoom, coords, intl = {} } = this.props;
    const newClickable = !this.isManualClick(positions);
    const prevClickable = !this.isManualClick(prevProps.positions);

    if (newClickable !== prevClickable) {
      this.setState({ clickable: newClickable });
    }
    if (
      coords.lat !== prevProps.coords.lat ||
      coords.lon !== prevProps.coords.lon ||
      zoom !== prevProps.zoom
    ) {
      this.handleChangeWithoutSelect({ ...coords, zoom });
    }
    if (intl.locale !== prevProps.intl.locale) {
      this.setState({ tileSetId: getTileSetId(intl.locale) });
    }
  }

  /**
   * Gets DOM listeners for `LeafletMap` component suitable with current layout (desktop or mobile)
   * @param {Boolean} isDesktop - if current layput is desktop one
   * @returns {Object} - event listeners will be spread on `LeafletMap`
   */
  getHandlers(isDesktop) {
    if (!isDesktop) {
      return {
        onDrag: this.handleMapMove,
        onDragEnd: this.handleDragEnd,
      };
    }
    return {
      onClick: this.handleClick,
    };
  }

  /**
   * Updates refs from `LeafletMap` component
   * @param {React::Ref} map - ref with `leaflet-map` vanilla map component
   */
  setMapRefs = (map) => {
    const { forceRefUpdate, onLeafletRef = () => {} } = this.props;
    this.map = map;
    if (map) {
      onLeafletRef(map.leafletElement);
    }

    if (forceRefUpdate) {
      this.setState({ refsForced: true });
    }
  };

  /**
   * Sets search ref
   * Calls `props::searchRef` callback or sets search ref
   * @param {Element} searchElement
   */
  setSearchRef = (searchElement) => {
    const { searchRef } = this.props;
    if (typeof searchRef === 'function') {
      searchRef(searchElement);
    } else if (hasProp(searchRef, 'current')) {
      searchRef.current = searchElement;
    }

    this.searchRef = { current: searchElement };
  };

  /**
   * Handles selection of the new point on map
   * Trigger `onSelect` according to `centerIsPin` prop
   * @prop {MapPoint} point - selected point
   * @prop {Boolean} isCenter - if this point is a center of a map (not selected one)
   */
  handleSelect = (point, isCenter) => {
    const { centerIsPin, onSelect } = this.props;
    if (centerIsPin || !isCenter) {
      onSelect(point);
    }
  };

  /**
   * Handles click on map:
   * - resolves lat and lon of click
   * - calls `handleSelect`
   * @param {LeafletMap::Event} e - event of click on `LeafletMap`
   */
  handleClick = (e) => {
    const { active = true, positions } = this.props;
    const { clickable } = this.state;

    if (clickable && active) {
      let { lat, lng: lon } = e.latlng;

      if (this.isManualClick(positions)) {
        const map = this.map.leafletElement;
        lat = appendPixelOffset(map, latLng(lat, lon), {
          x: 0,
          y: this.selectorHeight / 2 + 3,
        })[0];
        this.setState({ clickable: false });
      }

      this.handleSelect({ lat, lon, address: undefined });
    }
  };

  /**
   * Handles map moving process:
   * - sets component in dragging state
   */
  handleMapMove = () => {
    this.setState((prev) => {
      return {
        ...prev,
        mapIsDragging: true,
      };
    });
  };

  /**
   * Handles end of map movement:
   * - updates map center coordinate
   * - calls `handleSelect` with new center point
   */
  handleDragEnd = () => {
    if (!this.map || !this.map.leafletElement) {
      return;
    }

    const { lat, lng: lon } = this.map.leafletElement.getCenter();
    this.setState((prev) => {
      return {
        ...prev,
        mapIsDragging: false,
      };
    });

    this.handleSelect({ lat, lon, address: undefined }, true);
  };

  /**
   * Updates zoom value
   */
  setZoom = () => {
    const { _zoom: zoom } = this.map.leafletElement;
    this.setState((prev) => {
      return {
        ...prev,
        zoom,
      };
    });
  };

  /**
   * Handles change of the coordinates
   * - moves map to the new center point with new zoom cause panTo interrupted by new zoom (setZoom?)
   * @param {MapPoint} $ - new point with zoom
   */
  handleChangeWithoutSelect = ({ lat, lon, zoom }) => {
    if (!this.map || !this.map.leafletElement) {
      return;
    }

    const map = this.map.leafletElement;
    const { panOffset } = this.props;

    map.flyTo(appendPixelOffset(map, latLng(lat, lon), panOffset), zoom, {
      animate: false,
    });
  };

  /**
   * Handles change of the coordinates
   * - moves map to the new center point
   * - calls `handleSelect` with new location
   * @param {MapPoint} $ - new point description
   */
  handleChange = ({ lat, lon, address }) => {
    if (!this.map || !this.map.leafletElement) {
      return;
    }

    const map = this.map.leafletElement;
    const { panOffset } = this.props;

    map.panTo(appendPixelOffset(map, latLng(lat, lon), panOffset));
    this.handleSelect({ lat, lon, address });
  };

  /**
   * Toggles `MapPositionSelector` if position control installed
   */
  togglePositionSelection = () => {
    if (this.isManualClick(this.props.positions)) {
      this.setState({ clickable: !this.state.clickable });
    }
  };

  render() {
    const {
      coords,
      theme,
      radius,
      children,
      positions,
      centerIsPin,
      leafletMap = {},
      intl,
    } = this.props;
    const { zoom, mapIsDragging, clickable, tileSetId } = this.state;
    const controlsPos = merge({}, DEFAULT_POSITIONS, positions);

    return (
      <Desktop>
        {(isDesktop) => {
          const handlers = this.getHandlers(isDesktop);
          return (
            <div
              ref={this.rootRef}
              className={classNames('map Map', theme && `Map--${theme}`)}
            >
              <LeafletMap
                {...handlers}
                ref={this.setMapRefs}
                animate={false}
                center={[coords.lat, coords.lon]}
                zoom={zoom}
                zoomControl={false}
                scrollWheelZoom={false}
                doubleClickZoom={false}
                onZoomend={this.setZoom}
                {...leafletMap}
              >
                <LayersControl position="bottomright">
                  <LayersControl.BaseLayer checked name="Map">
                    <TileLayer
                      url={TILESET_URL}
                      id={tileSetId}
                      tileSize={512}
                      zoomOffset={-1}
                      attribution={ATTRIBUTION}
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Satellite">
                    <TileLayer
                      url={TILESET_URL}
                      id="mapbox/satellite-streets-v11"
                      tileSize={512}
                      zoomOffset={-1}
                      attribution={ATTRIBUTION}
                    />
                  </LayersControl.BaseLayer>
                </LayersControl>
                {centerIsPin ? (
                  <MapPin
                    theme={mapIsDragging ? 'invisible' : null}
                    coords={[coords.lat, coords.lon]}
                    radius={radius}
                  />
                ) : null}
                {children}
                <GeolocationControl
                  position={controlsPos.geo}
                  onSuccess={this.handleChange}
                />
                <ZoomControl position={controlsPos.zoom} />
                {controlsPos.search ? (
                  <MapSearchControl
                    searchRef={this.setSearchRef}
                    position={controlsPos.search}
                    disabled={clickable}
                    mapPositionProps={{
                      isSelecting: clickable,
                      onClicked: () => this.togglePositionSelection(),
                    }}
                    intl={intl}
                    onLocation={this.handleChange}
                  />
                ) : null}
              </LeafletMap>
              {controlsPos.pos && clickable ? (
                <MapPositionSelector
                  fieldRef={this.rootRef}
                  leafletElement={get(this.map, 'leafletElement.container')}
                  height={this.selectorHeight}
                />
              ) : null}
            </div>
          );
        }}
      </Desktop>
    );
  }
}

export default injectIntl(Map);
export { Map as PureMap };
