export default class Storage {
  static getItem(key) {
    try {
      const string = window.localStorage.getItem(key);
      return JSON.parse(string);
    } catch (error) {
      throw new Error('Not Object');
    }
  }

  static setItem(key, value) {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    return window.localStorage.setItem(key, value);
  }

  static removeItem(key, value) {
    return window.localStorage.removeItem(key);
  }
}
