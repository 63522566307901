import { ALL_CATEGORY_TICKET_LABELS } from '../../constants/prices';
import { round10 } from '../round10';

/**
 * Return all tickets in product
 * @param {Array} attractions - attractions list
 * @returns {Array[Object]} array of tickets
 */
export const getAllTickets = (attractions) => {
  return attractions.map((a) => a.tickets).flat();
};

/**
 * Checks if attractions hasn't entrance object
 * @param {Array} attractions - attractions list
 * @returns {Boolean} is attractions without entrance object
 */
export const isAttractionsWithoutEntrance = (attractions) => {
  return attractions.map((a) => a.entrance).every((elem) => elem === false);
};

/**
 * Checks if attractions contain tickets
 * @param {Array} attractions - attractions list
 * @returns {Boolean} product attractions contain tickets or not
 */
export const checkContainsTickets = (attractions) => {
  return attractions.some((a) => !!a.tickets.length);
};

/**
 * Returns currency convert rate for passed pricing category
 * @param {Array} attractions - attractions list
 * @param {Object} pricingCategory - pricing category object for which the convert rate is calculated
 * @param {Object} defaultCurrency - default currency of product
 * @returns {Array[Object]} array of tickets
 */
const getConvertRate = (attractions, pricingCategory, defaultCurrency) => {
  const allTickets = getAllTickets(attractions);

  if (allTickets.length) {
    const currentTicket = allTickets.find((t) =>
      t.pricingCategories.some(
        (pc) => pc.pricingCategoryId === pricingCategory.pricingCategoryId
      )
    );
    if (currentTicket && currentTicket.currency)
      return (
        defaultCurrency.rate /
        allTickets.find((t) =>
          t.pricingCategories.some(
            (pc) => pc.pricingCategoryId === pricingCategory.pricingCategoryId
          )
        ).currency.rate
      );
    return 1;
  }
};

/**
 * Checks if attractions contain tickets
 * @param {Array} attractions - attractions list
 * @param {Boolean?} withTicketId - `true` if you want returned pricing categories with their parent ticket id
 * @returns {Array[Object]} all pricing categories objects
 */
export const getAllPricingCategories = (attractions, withTicketId = false) => {
  return getAllTickets(attractions)
    .map((ticket) =>
      withTicketId
        ? ticket.pricingCategories.map((pricingCategory) => ({
            ticketId: ticket.id,
            ...pricingCategory,
          }))
        : ticket.pricingCategories
    )
    .flat(2);
};

/**
 * Find pricingCategory object by id
 * @param {String} id - pricingCategoryId attribute
 * @returns {Object} pricingCategory object
 */
const getPricingCategoryById = (id, attractions) => {
  return getAllPricingCategories(attractions).find(
    (pc) => pc.pricingCategoryId === id
  )
    ? getAllPricingCategories(attractions).find(
        (pc) => pc.pricingCategoryId === id
      )
    : { totalRetailPrice: 0 };
};

/**
 * Finds the sum of pricingCategories with the same label
 * @param {String} defaultCurrency - product default currency
 * @param {String} label - pricingCategory label
 * @param {Array} attractions - product attractions
 * @param {Array} uniquePricingCategoriesLabels - unique labels
 * @param {Boolean} withAllTickets - true if you want to merge "All tickets" label price with others
 * @returns {Number} sum of pricingCategories with the same label
 */
export const getPriceBlockCategorySum = (
  defaultCurrency,
  label,
  attractions,
  uniquePricingCategoriesLabels,
  withAllTickets = false
) => {
  let sum = 0;
  if (attractions.length) {
    attractions.forEach((a) =>
      a.tickets.forEach((t) =>
        t.pricingCategories.forEach((pc) => {
          let pricingCategory;
          if (pc.label === label) {
            if (
              withAllTickets &&
              (uniquePricingCategoriesLabels.length > 1 ||
                ALL_CATEGORY_TICKET_LABELS.includes(label))
            ) {
              pricingCategory = getPricingCategoryById(
                pc.pricingCategoryId,
                attractions
              );
              sum =
                Number(
                  ALL_CATEGORY_TICKET_LABELS.reduce(function (
                    accumulator,
                    currentValue
                  ) {
                    return (
                      accumulator +
                      getPriceBlockCategorySum(
                        defaultCurrency,
                        currentValue,
                        attractions,
                        uniquePricingCategoriesLabels
                      )
                    );
                  },
                  0)
                ) +
                Number(pricingCategory.totalRetailPrice) *
                  getConvertRate(
                    attractions,
                    pricingCategory,
                    defaultCurrency
                  ) +
                sum;
            } else {
              pricingCategory = getPricingCategoryById(
                pc.pricingCategoryId,
                attractions
              );
              sum =
                Number(pricingCategory.totalRetailPrice) *
                  getConvertRate(
                    attractions,
                    pricingCategory,
                    defaultCurrency
                  ) +
                sum;
            }
          }
        })
      )
    );
  }
  return Number(round10(sum));
};

/**
 * Returns array with totalRetailPrice sum for tickets
 * @param {Array} attractions - attractions list
 * @returns {Array[Object]} - objects with ticketId and totalRetailPriceSum
 */
export const getSumTotalRetailPricesForTickets = (attractions) => {
  return getAllPricingCategories(attractions, true).reduce(
    (accumulator, currentValue) => {
      const found = accumulator.find(
        (pricingCategory) => pricingCategory.ticketId === currentValue.ticketId
      );
      if (found) {
        found.totalRetailPriceSum += currentValue.totalRetailPrice;
      } else {
        accumulator.push({
          ticketId: currentValue.ticketId,
          totalRetailPriceSum: currentValue.totalRetailPrice,
        });
      }
      return accumulator;
    },
    []
  );
};

/**
 * Returns array of final categories labels
 * @param {Array} uniquePricingCategoriesLabels - current unique pricing categories labels
 * @returns {Array[String]} - array of final categories labels
 */
export const getFinalCategories = (uniquePricingCategoriesLabels) => {
  let finalCategories;
  if (
    uniquePricingCategoriesLabels.some((uniquePricingCategoriesLabel) =>
      ALL_CATEGORY_TICKET_LABELS.includes(uniquePricingCategoriesLabel)
    ) &&
    uniquePricingCategoriesLabels.length !== 1
  )
    finalCategories = uniquePricingCategoriesLabels.filter(
      (uniquePricingCategoriesLabel) =>
        !ALL_CATEGORY_TICKET_LABELS.includes(uniquePricingCategoriesLabel)
    );
  else finalCategories = uniquePricingCategoriesLabels;
  return finalCategories;
};
