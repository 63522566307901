/**
 * Rotates given image
 * @param {Element} img - DOM image element
 * @param {Number} angleExt - rotation angle (must be divisible on `90` without reminder) in degrees
 * @param {Object?} options - extra options
 * @param {String?} options.type - image file type to encode `base64` string (default is `"image/png"`)
 * @returns {Promise<Object>} - resolves with object with `base64` prop with base64 of the new string
 */
export default function rotate(img, angleExt, { type = 'image/png' } = {}) {
  const angle =
    -((angleExt < 0 ? 360 + (angleExt % 360) : angleExt % 360) / 180) * Math.PI;

  return new Promise(function (resolve, reject) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const [imgWidth, imgHeight] = [img.naturalWidth, img.naturalHeight];

    canvas.width = imgHeight;
    canvas.height = imgWidth;

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(angle);
    ctx.drawImage(img, -imgWidth / 2, -imgHeight / 2);

    resolve({ base64: canvas.toDataURL(type) });
  });
}
