import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

/**
 * JSS styles for `Examples` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  checkIcon: {
    color: (props) => (props.checkIconColor ? props.checkIconColor : '#4CAF50'),
    marginRight: (props) => props.marginRight,
  },
  closeIcon: {
    color: (props) => (props.closeIconColor ? props.closeIconColor : '#F44336'),
    marginRight: (props) => props.marginRight,
  },
  good: {
    position: 'relative',
    top: -6.8,
  },
  bad: {
    position: 'relative',
    top: -6.9,
  },
  goodWord: {
    color: '#4CAF50',
    marginBottom: -24,
    marginLeft: -88,
  },
  badWord: {
    color: '#F44336',
    marginBottom: -24,
    marginLeft: -88,
  },
  goodWordVertical: {
    color: '#4CAF50',
  },
  badWordVertical: {
    color: '#F44336',
  },
  examples: {
    paddingLeft: 88,
  },
  row: {
    marginBottom: 5,
  },
  withPhotosContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  img: {
    borderRadius: '50%',
    maxWidth: 50,
    width: 50,
    height: 50,
    marginRight: 10,
  },
  withPhotosGoodWord: {
    color: '#4CAF50',
  },
  withPhotosText: {
    fontSize: 12,
    width: 135,
  },
  withPhotosExample: {
    display: 'flex',
    marginBottom: 5,
  },
  withPhotosBadWord: {
    color: '#F44336',
  },
  examplesVertical: {
    display: 'flex',
    gap: 16,
    flexWrap: 'wrap',
  },
  examplesColumn: {
    maxWidth: 340,
    display: 'flex',
    gap: 11,
    flexDirection: 'column',
  },
}));

/**
 * Component for displaying correct and / or incorrect examples
 * @param {Object} $
 * @param {Array[String]|Array[Object]} $.good - good examples (with CheckIcon)
 * @param {Array[String]|Array[Object]} $.bad - bad examples (with CloseIcon)
 * @param {Boolean?} $.withWords - if true icons switch to words
 * @param {Boolean?} $.withPhotos - if true gets examples with photos (should pass objects to good and bad)
 * @param {String?} $.orientation - orientation of examples, not works with photos
 */
const Examples = ({
  good = [],
  bad = [],
  withWords = false,
  withPhotos = false,
  orientation = 'horizontal',
  ...props
}) => {
  const classes = useStyles(props);
  const goodWord = (
    <FormattedMessage
      id="StudioCreateProduct.GoodExamples"
      defaultMessage="GOOD"
    />
  );
  const badWord = (
    <FormattedMessage
      id="StudioCreateProduct.BadExamples"
      defaultMessage="BAD"
    />
  );

  if (orientation === 'vertical') {
    return (
      <div className={classes.examplesVertical}>
        <div className={classes.examplesColumn}>
          {good.map((goodExample, index) => (
            <div key={index} className={classes.row}>
              <Typography className={classes.goodWordVertical} variant="body1">
                {goodWord}
              </Typography>
              <Typography variant="body2">{goodExample}</Typography>
            </div>
          ))}
        </div>
        <div className={classes.examplesColumn}>
          {bad.map((badExample, index) => (
            <div key={index} className={classes.row}>
              <Typography className={classes.badWordVertical} variant="body1">
                {badWord}
              </Typography>
              <Typography variant="body2">{badExample}</Typography>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (withPhotos)
    return (
      <div className={classes.withPhotosContainer}>
        {good.map((goodExample) => (
          <div key={goodExample.src} className={classes.withPhotosExample}>
            <img
              className={classes.img}
              src={goodExample.src}
              alt="good example"
            />
            <div>
              <Typography
                className={classes.withPhotosGoodWord}
                variant="body1"
              >
                {goodWord}
              </Typography>
              <Typography className={classes.withPhotosText} variant="body2">
                {goodExample.text}
              </Typography>
            </div>
          </div>
        ))}
        {bad.map((badExample) => (
          <div key={badExample.src} className={classes.withPhotosExample}>
            <img
              className={classes.img}
              src={badExample.src}
              alt="good example"
            />
            <div>
              <Typography className={classes.withPhotosBadWord} variant="body1">
                {badWord}
              </Typography>
              <Typography className={classes.withPhotosText} variant="body2">
                {badExample.text}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    );

  return (
    <div className={withWords ? classes.examples : ''}>
      {good.map((goodExample, index) => (
        <div key={index} className={withWords ? classes.row : ''}>
          {withWords ? (
            index === 0 && (
              <Typography className={classes.goodWord} variant="body1">
                {goodWord}
              </Typography>
            )
          ) : (
            <CheckIcon className={classes.checkIcon} />
          )}
          <Typography
            className={withWords ? '' : classes.good}
            variant="body2"
            display="inline"
          >
            {!withWords && ' '}
            {goodExample}
          </Typography>
        </div>
      ))}
      {bad.map((badExample, index) => (
        <div key={index} className={withWords ? classes.row : ''}>
          {withWords ? (
            index === 0 && (
              <Typography className={classes.badWord} variant="body1">
                {badWord}
              </Typography>
            )
          ) : (
            <CloseIcon className={classes.closeIcon} />
          )}
          <Typography
            className={withWords ? '' : classes.bad}
            variant="body2"
            display="inline"
          >
            {!withWords && ' '}
            {badExample}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default Examples;
