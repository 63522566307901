import { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

const STATE_TO_PASS = ['values', 'errors'];
const METHODS_TO_PASS = [
  'setValue',
  'setValues',
  'getValue', // TODO: depricate?
  'getErrors', // TODO: depricate?
  'setError',
  'removeError',
  'getAllErrors',
  'validate',
  'isValid',
];

class Form extends Component {
  constructor(props, context) {
    super(props, context);
    const { defaultValues } = props;
    this.state = {
      values: defaultValues || {},
      errors: {},
    };
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillReceiveProps(nextProps) {
    const { defaultValues } = this.props;
    const { defaultValues: nextDefaultValues } = nextProps;
    if (isEqual(defaultValues, nextDefaultValues)) return;

    this.setState((prev) => ({
      ...prev,
      values: nextDefaultValues,
      errors: {},
    }));
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  get propsToPass() {
    return {
      ...METHODS_TO_PASS.reduce((obj, key) => {
        return { ...obj, [key]: this[key] };
      }, {}),
      ...STATE_TO_PASS.reduce((obj, key) => {
        return { ...obj, [key]: this.state[key] };
      }, {}),
    };
  }

  getValue = (key, state = this.state) => {
    return state.values[key] ? state.values[key] : '';
  };

  _setProp(prop, key, value) {
    if (!this.mounted) return;
    return this.setState((prevState) => ({
      ...prevState,
      [prop]: {
        ...prevState.values,
        [key]: value,
      },
    }));
  }

  _clearProp(prop, key) {
    if (!this.mounted) return;
    return this.setState((prevState) => {
      const cleanProp = { ...prevState[prop] };
      delete cleanProp[key];
      return {
        ...prevState,
        [prop]: cleanProp,
      };
    });
  }

  setValue = (...args) => {
    let key;
    let value;
    if (args.length === 1) {
      const e = args[0];
      if (typeof e !== 'object' || !e.target) return;
      key = e.target.getAttribute('name');
      value = e.target.value;
    } else {
      key = args[0];
      value = args[1];
      if (typeof value === 'object' && value.target) {
        value = value.target.value;
      }
    }
    this.removeError(key);
    this._setProp('values', key, value);
    if (this.props.instantlyValidate) {
      this.setState((prevState) => {
        return { ...prevState, errors: this.getAllErrors(prevState) };
      });
    }
  };

  setValues = (dict) => {
    for (const key in dict) {
      this.setValue(key, dict[key]);
    }
  };

  setError = (key, value) => {
    return this._setProp('errors', key, value);
  };

  removeError = (key) => {
    return this._clearProp('errors', key);
  };

  getErrors = (key) => {
    return this.state.errors[key] ? this.state.errors[key] : {};
  };

  getAllErrors = (state = this.state, post = false) => {
    const { validations } = this.props;
    if (isEmpty(validations)) return [];
    const { values } = state;
    const res = {};
    Object.keys(validations).forEach((key) => {
      const value = this.getValue(key, state);
      const keyValidations = validations[key];
      const errors = keyValidations
        .map(({ rule, title, animateOnPost }) => {
          if (rule(value, values)) return false;
          return { title, animateOnPost };
        })
        .filter(Boolean);
      if (!errors.length) return;
      res[key] = {
        animation:
          errors.filter(
            ({ animateOnPost }) => Boolean(animateOnPost) === Boolean(post)
          ).length > 0,
        errors: errors.map((item) => item.title),
      };
    });
    return res;
  };

  validate = (post = false) => {
    if (!this.mounted) return;
    this.setState((prevState) => {
      return {
        ...prevState,
        errors: this.getAllErrors(prevState, post),
      };
    });
    setTimeout(
      () =>
        this.setState((prevState) => {
          const cleanErrors = {};
          Object.keys(prevState.errors).forEach((key) => {
            cleanErrors[key] = {
              ...prevState.errors[key],
              animation: false,
            };
          });
          return {
            ...prevState,
            errors: cleanErrors,
          };
        }),
      500
    );
  };

  isValid = () => {
    const errors = this.getAllErrors();
    for (const key in errors) {
      if (errors[key].errors && !errors[key].errors.length) continue;
      return false;
    }
    return true;
  };

  render() {
    const { render } = this.props;
    return render(this.propsToPass);
  }
}

Form.propTypes = {
  instantlyValidate: PropTypes.bool,
  defaultValues: PropTypes.object,
  render: PropTypes.func.isRequired,
  validations: PropTypes.object,
};

export default Form;

// Validation rules
/**
 * Validation function
 *
 * @callback validation
 * @param {*} current field value
 * @param {Object} form values
 */

/**
 * Creates validation rule for Form `validations` prop
 * @param {string} name for validation, e.g. `isEmail`
 * @param {validation} function which validate
 * @param {bool} use validation only on post
 */
export function createValidationRule(title, rule, animateOnPost = false) {
  return {
    rule,
    title,
    animateOnPost,
  };
}

export function createChangedValidation(
  title,
  previosValue,
  animateOnPost = false
) {
  return createValidationRule(
    title,
    (value) => value !== previosValue,
    animateOnPost
  );
}

export const notEmpty = createValidationRule(
  'notEmpty',
  (value) => value.toString().length > 0,
  true
);

export const greaterThanNull = createValidationRule(
  'greaterThanNull',
  (value) => parseInt(value, 10) > 0,
  true
);

export const isEmail = createValidationRule('isEmail', (value) =>
  /(.+)@(.+){2,}\.(.+){2,}/.test(value)
);

export function createValidationRange(title, [start, end]) {
  if (start === null && end === null)
    return new Error('Can not validate range of Infinity');

  if (start === null) {
    return createValidationRule(title, (value) => value < end);
  }
  if (end === null) {
    return createValidationRule(title, (value) => value > start);
  }

  return createValidationRule(title, (value) => value > start && value < end);
}
