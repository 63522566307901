import merge from 'lodash/merge';
import { createTheme } from '@material-ui/core/styles';
import materialColors from '../constants/branding/materialColors';
import materialTypo from '../constants/branding/materialTypo';

/**
 * Composes material theme from "branding" constants and templates
 * @param {Object} overload - will be merged with returned theme to extend default one (need for e2e testing)
 * @return {MaterialUI::Theme}
 */
export default function composeTheme(overload = {}) {
  return createTheme(
    merge(
      {
        palette: materialColors,
        typography: materialTypo,
      },
      overload
    )
  );
}
