import { useRef } from 'react';

/**
 * Pops data from cookie when is mounted
 * Updates localStorage string with returned `saveProp` callback
 * @param {String} id - key of the localStorage prop to write
 * @param {Function} preprocess - parses string value got from cookie (returned cookie value will be affected)
 * @returns {Array} - cookie value (updates only on remounting component) and function to update it
 */
export default function useSavedProp(id, preprocess = (v) => v) {
  /**
   * Cookie value got on first component render
   * @type {React::Ref}
   */
  const savedPropRef = useRef(preprocess(localStorage.getItem(id)));

  /**
   * Updates cookie value
   * @param {String} value - new value to write to localStorage
   */
  const saveProp = (value) => {
    localStorage.setItem(id, value);
  };

  return [savedPropRef.current, saveProp];
}
