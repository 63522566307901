import React from 'react';
import { withRouter } from 'react-router-dom';
import { defineMessages } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Control from 'react-leaflet-control';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { toggleMuseum } from '../../../actions/quests';
import { makeGetCurrentQuest } from '../../../selectors';
import composeMaterialTheme from '../../../functions/materialTheme';
import { isCoordsString } from '../../../functions/map/stringCoords';
import withCoords from '../../PlacesAutocomplete/withCoords';
import PurePlacesAutocomplete from '../../PlacesAutocomplete';
import MapPositionControl from '../MapPositionControl';

/**
 * `PlacesAutocomplete` with ability to pass coordinates in dropdown
 * @type {React::Component}
 */
const PlacesAutocomplete = withCoords(PurePlacesAutocomplete);

/**
 * Messages for `MapSearchControl` component
 * @type {Object}
 */
const messages = defineMessages({
  labelSearch: {
    id: 'MapSearchControl.labelSearch',
    defaultMessage: 'Find place with search',
  },
  placeholderSearch: {
    id: 'MapSearchControl.placeholderSearch',
    defaultMessage: 'Start typing',
  },
  noOptionsSearch: {
    id: 'MapSearchControl.noOptionsSearch',
    defaultMessage: 'No options',
  },
  labelPosition: {
    id: 'MapSearchControl.labelPosition',
    defaultMessage: '...or point on map',
  },
  checkboxMuseum: {
    id: 'MapSearchControl.checkboxMuseum',
    defaultMessage: 'Museum tour',
  },
});

/**
 * JSS styles for `MapSearchControl` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  root: {
    width: '608px',
    maxWidth: 'calc(100% - 20px)',
    padding: '17px 24px 15px',
    background: fade(theme.palette.background.main, 0.85),
    backdropFilter: 'blur(15px)',
  },
  engine: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    rowGap: '8px',
    columnGap: '32px',
  },
  search: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
  },
  searchInput: {
    paddingRight: '0',
    transform: 'translateY(-2px)',
  },
  searchIcon: {
    color: theme.palette.text.secondary,
  },
  searchPopper: {
    zIndex: '100000',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0 0 11px',
    '& > *:not(:last-child)': {
      marginRight: '16px',
    },
  },
}));

/**
 * Search bar with options for `Map` component
 * @param {Object} $
 * @param {Object} $.intl - manually passed from paren component `react-intl` prop (cause this component in DOM is installed separately)
 * @param {Object} $.mapPositionProps - props for child `MapPositionControl` element
 * @param {String} $.questId - id of the quest (for toggling `isMuseum` quest prop in JSON)
 * @param {String} $.position - position in `react-leaflet` map instance
 * @param {Function} $.onLocation - calls when location was selected in autocomplete search bar
 * @param {Function} $.toggleMuseum - redux action for toggling quest's `isMuseum` prop
 * @param {Boolean} $.disabled - is search input is disabled
 * @param {Boolean} $.isMuseumQuest - value of the "Museum tour" checkbox
 */
function MapSearchControl({
  position,
  disabled,
  questId,
  isMuseumQuest,
  searchRef,
  toggleMuseum = () => {},
  mapPositionProps = {},
  onLocation = () => {},
  intl: { formatMessage },
  intl,
}) {
  const styles = useStyles();

  /**
   * User selected location from `Autocomplete`
   * Calls `onLocation` function from props with selected place address and coordinates in object
   * @param {Event} event - DOM event caused `Autocomplete` to trigger `onChange`
   * @param {Object} value - selected value from `results` state
   */
  const onLocationSelected = (event, value) => {
    if (value) {
      onLocation({
        lat: value.lat,
        lon: value.lng,
        address: !isCoordsString(value.description)
          ? value.description
          : undefined,
      });
    }
  };

  return (
    <Control position={position}>
      <ThemeProvider theme={composeMaterialTheme()}>
        <div ref={searchRef} className={styles.root}>
          <div className={styles.engine}>
            <div className={styles.search}>
              <InputLabel>
                <Typography variant="body2">
                  {formatMessage(messages.labelSearch)}
                </Typography>
              </InputLabel>
            </div>
            <div className={styles.position}>
              <InputLabel>
                <Typography variant="body2">
                  {formatMessage(messages.labelPosition)}
                </Typography>
              </InputLabel>
            </div>
            <PlacesAutocomplete
              classes={{ popper: styles.searchPopper }}
              disabled={disabled}
              noOptionsText={formatMessage(messages.noOptionsSearch)}
              intl={intl}
              renderInput={(params) => (
                <TextField
                  type="locations"
                  placeholder={formatMessage(messages.placeholderSearch)}
                  {...params}
                  variant="standard"
                />
              )}
              onChange={onLocationSelected}
            />
            <MapPositionControl
              control={false}
              margin={false}
              variant="outlined"
              intl={intl}
              {...mapPositionProps}
            />
          </div>
          <div className={styles.info}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  size="small"
                  checked={isMuseumQuest}
                  onChange={() =>
                    toggleMuseum({ questId, isMuseum: !isMuseumQuest })
                  }
                />
              }
              label={
                <Typography variant="body2">
                  {formatMessage(messages.checkboxMuseum)}
                </Typography>
              }
            />
          </div>
        </div>
      </ThemeProvider>
    </Control>
  );
}

/**
 * Adds redux store data into component props
 * @param {Object} state - current Redux state
 * @returns {Object} - params to be spread
 */
function mapStateToProps(state, props) {
  const currentQuest = makeGetCurrentQuest()(state, props) || {};
  return {
    questId: parseInt(currentQuest.id, 10),
    isMuseumQuest: currentQuest.isMuseum || false,
    locale: state.publishing.receivedPublishData.locale,
  };
}

/**
 * Adds redux action creators into props
 * @param {Function} dispatch
 */
function mapDispatchToProps(dispatch) {
  return {
    toggleMuseum: bindActionCreators(toggleMuseum, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MapSearchControl)
);
