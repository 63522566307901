import React, { useState } from 'react';
import get from 'lodash/get';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { findQuest } from '../../reducers/quests';
import { addRoutePointWithEvent, editRoutePoint } from '../../actions/route';
import { addEvent, deleteEvent } from '../../actions/events';
import PopupRemoveEvent from '../Popup/withRemoveEvent';
import TourContentFooterButtons from '../TourContentFooter/TourContentFooterButtons';
import RouteMap from './RouteMap';
import RoutePointsAmount from './RoutePointsAmount';
import './RoutePage.css';

/**
 * View with map for selecting tour route
 * @param {Object} $
 * @param {Number} $.questId - id of the quest which rout will be edited
 * @param {Array[Object]} $.quests - quests descriptions from server
 */
function RoutePage({
  questId,
  quests = [],
  syncingRoute,
  isMuseumQuest,
  addRoutePointWithEvent,
  editRoutePoint,
  deleteEvent,
  currentQuest,
}) {
  /**
   * Local state to work with points and remove event popup component
   * @param {Boolean} isPopupOpen - is popup open marker
   * @param {String} lastPointId - current editing point id
   */
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [lastPointId, _setLastPointId] = useState('');
  const setLastPointId = (pointId) => {
    if (pointId !== null) {
      _setLastPointId(pointId);
    }
  };

  const quest = findQuest(quests, Number(questId));
  if (!quest) {
    return null;
  }

  const points = get(quest, 'routes', []);

  /**
   * Edits marker in the store by adding address
   * @param {String} id - route point id
   * @param {RoutePoint} data - part of the route point description from map
   */
  const editPin = (id, { description, ...data }) => {
    editRoutePoint({
      ...data,
      questId,
      event: { description },
      guid: id,
      isSync: false,
    });
  };

  /**
   * Adds new route point to the store
   * @param {String} id - route point id
   * @param {RoutePoint} data - part of the route point description from map
   */
  const addPin = (id, data) => {
    addRoutePointWithEvent({
      questId,
      eventId: id,
      routePoint: { guid: id, events: [], ...data },
      isSync: false,
    });
  };

  /**
   * Delete pin with event
   * @param {Number} questId - quest id
   * @param {String} eventId - event or route-point id
   */
  const deletePin = (questId, eventId) => {
    deleteEvent({
      questId,
      eventId,
    });
    setIsPopupOpen(false);
  };

  const currentEvent = currentQuest.events.find((x) => x.id === lastPointId);

  return (
    <section className="App__main RoutePage">
      <PopupRemoveEvent
        event={currentEvent}
        type="point"
        theme="material"
        isOpen={isPopupOpen}
        toggleOpen={setIsPopupOpen}
        deleteItem={() => deletePin(Number(questId), lastPointId)}
      />
      <RouteMap
        className="RoutePage__map"
        questId={questId}
        points={points}
        events={quest.events}
        syncingPoints={syncingRoute}
        setIsPopupOpen={setIsPopupOpen}
        isPopupOpen={isPopupOpen}
        setCurrentPointId={setLastPointId}
        onAdded={addPin}
        onEdited={editPin}
      />
      <TourContentFooterButtons
        theme="black"
        leftLink={`/quest/${questId}/description`}
        rightLink={`/quest/${questId}/events/0/`}
      >
        <RoutePointsAmount
          amount={points.length}
          isMuseumQuest={isMuseumQuest}
        />
      </TourContentFooterButtons>
    </section>
  );
}

/**
 * Adds redux store data into component props
 * @param {Object} state - current Redux state
 * @returns {Object} - params to be spread
 */
function mapStateToProps(state, props) {
  const currentQuest =
    findQuest(state.quests.quests, Number(props.questId)) || {};
  return {
    quests: state.quests.quests,
    syncingRoute: state.quests.syncingRoute || [],
    isMuseumQuest: currentQuest.isMuseum || false,
    currentQuest,
  };
}

/**
 * Adds redux action creators into props
 * @param {Function} dispatch
 */
function mapDispatchToProps(dispatch) {
  return {
    addRoutePointWithEvent: bindActionCreators(
      addRoutePointWithEvent,
      dispatch
    ),
    editRoutePoint: bindActionCreators(editRoutePoint, dispatch),
    addEvent: bindActionCreators(addEvent, dispatch),
    deleteEvent: bindActionCreators(deleteEvent, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutePage);
