import {
  FirstSteps,
  Workspace,
  FillingEvent,
} from '../constants/tutorials/TutorialsName';
import FirstStepsTutorial from '../constants/tutorials/FirstStepsTutorial';
import WorkspaceTutorial from '../constants/tutorials/WorkspaceTutorial';
import FillingEventTutorial from '../constants/tutorials/FillingEventTutorial';

export default function getTutorial(props) {
  switch (props.name) {
    case FirstSteps:
      return FirstStepsTutorial(props);
    case Workspace:
      return WorkspaceTutorial(props);
    case FillingEvent:
      return FillingEventTutorial(props);
    default:
      return { run: false, name: 'Default', steps: [] };
  }
}
