import {
  UPLOAD_ADD,
  UPLOAD_START,
  UPLOAD_PROGRESS,
  UPLOAD_FAILURE,
  UPLOAD_SUCCESS,
  UPLOAD_ABORT_SUCCESS,
  READ_FAILURE,
  READ_SUCCESS,
  REMOVE_EVENT_IMAGE,
  DRAG_EVENT_IMAGE,
  REORDER_EVENT_STICKER,
  REORDER_PRODUCT_IMAGES,
  REMOVE_EVENT_IMAGES,
} from '../constants/ActionTypes';
import incrementMediaId from '../functions/id/incrementMediaId';
import operateMediaId from '../functions/uploads/operateMediaId';
import removeMediaId from '../functions/uploads/removeMediaId';

export default function uploads(
  state = {
    uploads: [],
    isSending: false,
  },
  action
) {
  switch (action.type) {
    case UPLOAD_ADD: {
      const {
        meta: { id, file, form, type, contentType, eventPK, mediaId, endpoint },
      } = action;

      return {
        ...state,
        uploads: [
          ...operateMediaId(state.uploads, mediaId, (uploadMediaId) =>
            uploadMediaId.index > mediaId.index
              ? incrementMediaId(uploadMediaId, 1)
              : uploadMediaId
          ),
          {
            id,
            progress: 0,
            failed: false,
            form,
            file,
            type,
            eventPK,
            contentType,
            mediaId,
            endpoint,
          },
        ],
      };
    }

    case UPLOAD_START: {
      const {
        meta: { id },
        xhr,
      } = action;
      return {
        ...state,
        uploads: state.uploads.map((upload) => {
          if (upload.id !== id) return upload;
          return { ...upload, xhr };
        }),
        isSending: true,
      };
    }

    case UPLOAD_PROGRESS: {
      const {
        payload: progress,
        meta: { id },
      } = action;
      return {
        ...state,
        uploads: state.uploads.map((upload) => {
          if (upload.id !== id) return upload;
          return {
            ...upload,
            progress,
          };
        }),
        isSending: true,
      };
    }

    case UPLOAD_FAILURE: {
      const {
        payload: error,
        meta: { id },
      } = action;
      return {
        ...state,
        uploads: state.uploads.map((upload) => {
          if (upload.id !== id) return upload;
          return {
            ...upload,
            failed: true,
            error,
          };
        }),
        isSending: false,
      };
      // TODO: think about error place and upload lifecycle
    }

    case UPLOAD_ABORT_SUCCESS: {
      const {
        meta: { id },
      } = action;
      const toRemove = state.uploads.find((upload) => upload.id === id);
      return {
        ...state,
        uploads: removeMediaId(state.uploads, toRemove.mediaId).filter(
          (upload) => upload.id !== id
        ),
        isSending: false,
      };
    }

    case UPLOAD_SUCCESS: {
      const {
        meta: { id },
      } = action;
      return {
        ...state,
        uploads: state.uploads.filter((upload) => upload.id !== id),
        isSending: false,
      };
    }

    case READ_SUCCESS: {
      const {
        meta: { id },
        payload: dataURL,
      } = action;
      return {
        ...state,
        uploads: state.uploads.map((upload) => {
          if (upload.id !== id) return upload;
          return {
            ...upload,
            dataURL,
          };
        }),
      };
    }

    case READ_FAILURE: {
      const {
        meta: { id },
        payload: readError,
      } = action;
      return {
        ...state,
        uploads: state.uploads.map((upload) => {
          if (upload.id !== id) return upload;
          return {
            ...upload,
            readError,
          };
        }),
      };
    }

    case REMOVE_EVENT_IMAGE: {
      const { mediaId } = action;

      return {
        ...state,
        uploads: removeMediaId(state.uploads, mediaId),
      };
    }

    case REMOVE_EVENT_IMAGES: {
      const { mediaIds } = action;
      return {
        ...state,
        uploads: mediaIds.reduce(
          (uploads, mediaId) => removeMediaId(uploads, mediaId),
          state.uploads
        ),
      };
    }

    case REORDER_PRODUCT_IMAGES:
    case REORDER_EVENT_STICKER:
    case DRAG_EVENT_IMAGE: {
      const { mediaId, newMediaId, isUpload } = action;

      return {
        ...state,
        uploads: operateMediaId(state.uploads, mediaId, (uploadMediaId) => {
          const from = mediaId.index;
          const to = newMediaId.index;
          const current = uploadMediaId.index;

          if (isUpload && current === from) {
            return newMediaId;
          } else if (current <= to && current > from) {
            return incrementMediaId(uploadMediaId, -1);
          } else if (current >= to && current < from) {
            return incrementMediaId(uploadMediaId, 1);
          }

          return uploadMediaId;
        }),
      };
    }

    default:
      return state;
  }
}
