import React, { Component } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import Helmet from 'react-helmet';
import { favicons } from '../../constants/favicons';
import { BRAND_NAME } from '../../constants/branding';

class HelmetTitle extends Component {
  render() {
    const {
      message,
      value,
      intl: { formatMessage },
    } = this.props;
    return (
      <Helmet
        titleTemplate={`%s · ${BRAND_NAME}`}
        title={formatMessage(message, { title: value })}
      >
        {favicons}
      </Helmet>
    );
  }
}

HelmetTitle.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(HelmetTitle);
