import * as types from '../constants/ActionTypes';

export const uploadBatch = (eventPK, files) => ({
  type: types.UPLOAD_BATCH,
  eventPK,
  files,
});

export const uploadStart = (meta, xhr) => ({
  type: types.UPLOAD_START,
  meta,
  xhr,
});

export const uploadProgress = (meta, progress) => ({
  type: types.UPLOAD_PROGRESS,
  payload: progress,
  meta,
});

export const uploadSuccess = (meta, json) => ({
  type: types.UPLOAD_SUCCESS,
  meta,
  json,
});

export const uploadFailure = (meta, err) => ({
  type: types.UPLOAD_FAILURE,
  payload: err,
  meta,
});

export const uploadAbort = (id) => ({
  type: types.UPLOAD_ABORT,
  meta: { id },
});

export const readSuccess = (meta, data) => ({
  type: types.READ_SUCCESS,
  payload: data,
  meta,
});

export const readFailure = (meta, err) => ({
  type: types.READ_FAILURE,
  payload: err,
  meta,
});
