import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import './InputWithButtons.css';

class InputWithButtons extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { focused: false };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  handleFocus(e) {
    this.setState({ focused: true });
  }

  handleBlur(e) {
    this.setState({ focused: false });
  }

  render() {
    const props = { ...this.props, children: null };
    delete props.onRef;
    delete props.before;
    delete props.full;
    const { children, before, className, full, onRef } = this.props;
    const { focused } = this.state;
    const ref = onRef || null;
    const handleFocus = function (e) {
      if (this.props.onFocus) this.props.onFocus(e);
      this.handleFocus.bind(this)();
    };
    const handleBlur = function (e) {
      if (this.props.onBlur) this.props.onBlur(e);
      this.handleBlur.bind(this)();
    };
    return (
      <div
        className={classNames(
          'InputWithButtons__wrapper',
          'InputWithButtons__wrapper--line',
          className,
          focused && 'focus'
        )}
      >
        {before}
        <div
          className={classNames(
            'InputWithButtons__input-wrapper',
            full && 'InputWithButtons__input-wrapper--full'
          )}
        >
          <input
            {...props}
            ref={ref}
            className={classNames(
              'InputWithButtons__input',
              before && 'InputWithButtons__input--before'
            )}
            onFocus={handleFocus.bind(this)}
            onBlur={handleBlur.bind(this)}
          />
          <div className="InputWithButtons__view" />
        </div>
        <div className="InputWithButtons__buttons">
          {children
            ? React.Children.map(children, (child) => (
                <div className="InputWithButtons__child">{child}</div>
              ))
            : ''}
        </div>
      </div>
    );
  }
}

export default InputWithButtons;
