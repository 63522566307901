/* eslint-disable react/no-deprecated */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ToastContainer, toast } from 'react-toastify';
import { resetNetworkError } from '../../actions/network';
import { isDesktop } from '../../functions/queries';
import * as networkErrors from '../../constants/NetworkErrors';
import refreshIcon from '../../img/refresh.svg';
import errorAndRefreshIcon from '../../img/error-and-refresh.svg';
import errorIcon from '../../img/error-white.svg';
import wegotripLogo from '../../img/wegotrip-logo-w.svg';
import './ReactToastify.css';
import './ErrorBadge.css';

function getBackgroundColor(error) {
  switch (error) {
    case networkErrors.accessDenied:
      return 'red';
    case networkErrors.serverError:
      return 'yellow';
    default:
      return 'default';
  }
}

const ErrorIcon = styled.div`
  width: 1.8em;
  height: 1.8em;
  margin-right: 1em;
  flex-shrink: 0;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.icon});
`;

const CloseButton = ({ closeToast }) => (
  <button className="ErrorBadge__close-button" onClick={closeToast} />
);

class ErrorBadge extends Component {
  componentDidMount() {
    const {
      network: { errors },
    } = this.props;
    this.renderErrorMessage(errors);
  }

  componentWillReceiveProps(nextProps) {
    if (isEqual(this.props.network, nextProps.network)) return;

    const {
      network: { errors },
    } = nextProps;

    this.renderErrorMessage(errors);
  }

  getErrorMessageIcon = (error) => {
    switch (error) {
      case networkErrors.accessDenied:
        return <ErrorIcon icon={errorAndRefreshIcon} />;
      case networkErrors.serverError:
        return <ErrorIcon icon={errorIcon} />;
      case networkErrors.PAYOUT_SUCCESS_MESSAGE:
        return <ErrorIcon icon={wegotripLogo} />;
      default:
        return <ErrorIcon icon={refreshIcon} />;
    }
  };

  getErrorMessageText = (error) => {
    switch (error) {
      case networkErrors.unauthorized:
        return (
          <FormattedMessage
            id="StatusBadge.unauthorized"
            defaultMessage="You aren't authorized{br}Please sign in"
            values={{
              br: <br />,
            }}
          />
        );
      case networkErrors.accessDenied:
        return (
          <FormattedMessage
            id="StatusBadge.accessDenied"
            defaultMessage="Changes haven't been saved{br}Please try again or contact the support"
            values={{
              br: <br />,
            }}
          />
        );
      case networkErrors.conflictError:
        return (
          <FormattedMessage
            id="StatusBadge.conflictError"
            defaultMessage="A product card has been created for this tour, it cannot be deleted"
          />
        );
      case networkErrors.serverError:
        return (
          <FormattedMessage
            id="StatusBadge.serverError"
            defaultMessage="Internal server error"
          />
        );
      case networkErrors.badRequest:
        return (
          <FormattedMessage
            id="StatusBadge.badRequest"
            defaultMessage="Error in form data"
          />
        );
      case networkErrors.NO_REQUISITES:
        return (
          <FormattedMessage
            id="StatusBadge.noRequisites"
            defaultMessage="Please enter banking details"
          />
        );
      case networkErrors.PAYOUT_IN_PROCESS:
        return (
          <FormattedMessage
            id="StatusBadge.payoutInProcess"
            defaultMessage="The request for payout is being processed"
          />
        );
      case networkErrors.PAYOUT_SUCCESS_MESSAGE:
        return (
          <FormattedMessage
            id="StatusBadge.payoutStartProcess"
            defaultMessage="Payout in processing"
          />
        );
      case networkErrors.INSUFFICIENT_FUNDS:
        return (
          <FormattedMessage
            id="StatusBadge.insufficientFunds"
            defaultMessage="Insufficient funds to withdraw"
          />
        );
      default:
        return (
          <FormattedMessage
            id="StatusBadge.defaultMessage"
            defaultMessage="Server error{br}Your data has not been saved"
            values={{
              br: <br />,
            }}
          />
        );
    }
  };

  getErrorMessage = (error) => {
    return (
      <Fragment>
        {this.getErrorMessageIcon(error)}
        {this.getErrorMessageText(error)}
      </Fragment>
    );
  };

  renderErrorMessage = (errors) => {
    const { dispatch } = this.props;
    if (!errors) return;
    const notShowingErrors = errors.filter((error) => !toast.isActive(error));

    if (notShowingErrors.length) {
      notShowingErrors.forEach((error) => {
        toast.info(this.getErrorMessage(error), {
          className: classNames(
            'ErrorBadge__wrapper',
            `ErrorBadge__wrapper--${getBackgroundColor(error)}`
          ),
          onClose: () => dispatch(resetNetworkError(error)),
          toastId: error,
        });
      });
    }
  };

  render() {
    const toastContainerWidth = isDesktop() ? { width: '340px' } : null;
    return (
      <ToastContainer
        style={toastContainerWidth}
        toastClassName="ErrorBadge__wrapper"
        bodyClassName="ErrorBadge__body"
        closeButton={<CloseButton />}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    network: state.network,
  };
}

ErrorBadge.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  network: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ErrorBadge);
