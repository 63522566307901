import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import * as UserActions from '../../actions/user';
import * as QuestActions from '../../actions/quests';
import * as EventActions from '../../actions/events';
import * as publishingActions from '../../actions/publishing';
import { makeGetCurrentQuest } from '../../selectors';

import Logo from '../Logo';
import HamburgerIcon from '../HamburgerIcon';
import DemoButton from '../DemoButton';
import QuestShare from '../QuestShare';
import User from '../User';
import { Mobile, Desktop } from '../../functions/queries';
import WithQuestStatus from '../WithQuestStatus';
import getUserStatus from '../../functions/getUserStatus';
import {
  FirstSteps,
  Workspace,
  FillingEvent,
} from '../../constants/tutorials/TutorialsName';
import './Header.css';

const profilePaths = [
  '/',
  '',
  '/settings',
  '/reports',
  '/reviews',
  '/welcome',
  '/settings/',
  '/reports/',
  '/reviews/',
  '/welcome/',
];

class Header extends Component {
  getHeaderClass() {
    const statuses = getUserStatus(this.props.user);
    return [statuses.map((s) => `Header--${s[0]}`)[0]]
      .filter(Boolean)
      .join(' ');
  }

  handleHeaderOverlay = () => {
    const {
      tutorial: { run: isTutorialRun, name: tutorialName, stepIndex },
    } = this.props;

    if (!isTutorialRun) {
      return false;
    } else if (tutorialName === FirstSteps) {
      return false;
    } else if (tutorialName === Workspace && stepIndex !== 0) {
      return true;
    } else if (tutorialName === FillingEvent) {
      return true;
    }
    return false;
  };

  render() {
    const {
      user,
      partner,
      userActions,
      currentQuest,
      currentPlans,
      publishingActions,
      questActions,
      isSidebarOpen,
      toggleSidebar,
      isDemoOpen,
      toggleDemo,
      params,
      location: { pathname },
      params: { eventOrder },
      isPopupOpen,
      openPopup,
      closePopup,
      closeSidebar,
      isResizeBlock,
      tutorial,
      tutorialActions,
      publishing,
      quests,
      isQuestCreating,
    } = this.props;
    const inConstructor = currentQuest && !isEmpty(currentQuest);
    const isEnableHeaderOverlay = this.handleHeaderOverlay();
    const isProfile = profilePaths.some((elem) => elem === pathname);

    return (
      <WithQuestStatus
        quest={currentQuest}
        render={({ isQuestValid }) => (
          <Fragment>
            <Mobile>
              <div
                className={classNames(
                  'Header',
                  isSidebarOpen && this.getHeaderClass(),
                  isResizeBlock && isSidebarOpen && 'Header--hide'
                )}
              >
                <HamburgerIcon
                  isSidebarOpen={isSidebarOpen}
                  toggleSidebar={toggleSidebar}
                />
                <Link to="/">
                  <div className="Header__icon" />
                </Link>
                {/* {inConstructor && eventOrder ? (
                  <DemoButton
                    isDemoOpen={isDemoOpen}
                    toggleDemo={toggleDemo}
                    isSidebarOpen={isSidebarOpen}
                  />
                ) : null} */}
                <div
                  className={classNames(
                    'Header__user',
                    isSidebarOpen && 'Header__user--active'
                  )}
                >
                  <User
                    user={user}
                    userActions={userActions}
                    statuses={getUserStatus(user)}
                    isResizeBlock={isResizeBlock}
                    closeSidebar={closeSidebar}
                  />
                </div>
              </div>
            </Mobile>
            <Desktop>
              <div
                className={classNames(
                  'Header',
                  isProfile && 'Header__profile',
                  inConstructor && 'Header__studio',
                  isEnableHeaderOverlay && 'Header__tutorial'
                )}
              >
                <Logo />
                <QuestShare
                  user={user}
                  quests={quests}
                  partner={partner}
                  currentQuest={currentQuest}
                  currentPlans={currentPlans}
                  publishing={publishing}
                  publishingActions={publishingActions}
                  params={params}
                  questActions={questActions}
                  isPopupOpen={isPopupOpen}
                  openPopup={openPopup}
                  closePopup={closePopup}
                  isQuestValid={isQuestValid}
                  closeSidebar={closeSidebar}
                  isProfile={isProfile}
                  isQuestCreating={isQuestCreating}
                />
                {/* {!isProfile && (
                  <User
                    className={classNames(
                      'Header__block Header__block--user',
                      isProfile &&
                        'Header__profile Header__block Header__block--user'
                    )}
                    currentQuest={currentQuest}
                    user={user}
                    userActions={userActions}
                    statuses={getUserStatus(user)}
                    tutorial={tutorial}
                    tutorialActions={tutorialActions}
                  />
                )} */}
              </div>
            </Desktop>
          </Fragment>
        )}
      />
    );
  }
}

Header.propTypes = {
  user: PropTypes.object.isRequired,
  currentQuest: PropTypes.any,
  currentPlans: PropTypes.array.isRequired,
  publishingActions: PropTypes.objectOf(PropTypes.func).isRequired,
  userActions: PropTypes.objectOf(PropTypes.func).isRequired,
};

function makeMapStateToProps() {
  const getCurrentQuest = makeGetCurrentQuest();
  const mapStateToProps = (state, props) => {
    return {
      user: state.user,
      partner: state.partner,
      quests: state.quests,
      currentQuest: getCurrentQuest(state, props),
      isFetching: state.quests.isFetching,
      currentPlans: state.publishing.currentPlans,
      publishing: state.publishing,
      isQuestCreating: state.quests.isCreating,
    };
  };
  return mapStateToProps;
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
    questActions: bindActionCreators(QuestActions, dispatch),
    eventActions: bindActionCreators(EventActions, dispatch),
    publishingActions: bindActionCreators(publishingActions, dispatch),
  };
}

export default withRouter(
  connect(makeMapStateToProps, mapDispatchToProps)(Header)
);
// For unit tests
export { Header as PureHeader };
