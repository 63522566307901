import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Gap from '../../Gap';

/**
 * Gaps between items in `withContentBelow(Dropzone)`
 * @type {Array[Number]}
 */
export const DROPZONE_GAPS = [17, 0, 0, 0];

/**
 * JSS styles for `withContentBelow` mod for `Dropzone`
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
}));

/**
 * Adds ability to display loaded content under dropzone as images
 * @param {React::Component} Component - overloaded `Dropzone` component
 */
export default function withContentBelow(Component) {
  /**
   * `Dropzone` component with ability to display loaded content below
   * @param {Object} $
   * @param {React::JSX} $.children - will be displayed below the `Dropzone` component
   * @param {React::Ref} $.contentRef - ref to the container with content cheldren
   * @param {...Object} $.dropzoneProps - will be passed to `Dropzone` component
   */
  return function ({ children, contentRef, ...dropzoneProps }) {
    const styles = useStyles();

    return (
      <div>
        <Component {...dropzoneProps} />
        <Gap
          rootRef={contentRef}
          className={styles.container}
          gaps={DROPZONE_GAPS.map((g) => `${g}px`)}
        >
          {children}
        </Gap>
      </div>
    );
  };
}
