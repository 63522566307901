import { Russian, English } from '../constants/Languages';

export default function getImgBadge(locale) {
  switch (locale) {
    case Russian:
      return '-ru';
    case English:
      return '';
    default:
      return '';
  }
}
