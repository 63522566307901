import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';
import { ACTIONS, EVENTS } from 'react-joyride/es/constants';
import { defineMessages, intlShape, injectIntl } from 'react-intl';
import scrollIntoView from 'scroll-into-view-if-needed';
import { isEqual } from 'lodash';
import getTutorial from '../../functions/getTutorial';
import {
  TOOLTIP_BACKGROUND_COLOR,
  BUTTON_BACKGROUND_COLOR,
} from '../../constants/branding';
import './Tutorial.css';

const tooltipStyles = {
  options: {
    backgroundColor: TOOLTIP_BACKGROUND_COLOR,
    textColor: '#ffffff',
    arrowColor: TOOLTIP_BACKGROUND_COLOR,
    zIndex: 2600,
  },
  floater: {
    floater: {
      filter: 'none',
      zIndex: 2600,
    },
  },
  tooltip: {
    padding: 20,
    zIndex: 2600,
  },
  tooltipContainer: {
    textAlign: 'left',
  },
  tooltipTitle: {
    margin: '0',
  },
  tooltipContent: {
    padding: '10px 0px',
  },
  buttonNext: {
    backgroundColor: BUTTON_BACKGROUND_COLOR,
  },
  buttonBack: {
    color: '#ffffff',
  },
};

const messages = defineMessages({
  nextButtonText: {
    id: 'Tutorial.nextButtonText',
    defaultMessage: 'Next',
  },
  backButtonText: {
    id: 'Tutorial.backButtonText',
    defaultMessage: 'Back',
  },
  closeButtonText: {
    id: 'Tutorial.closeButtonText',
    defaultMessage: 'Close',
  },
  lastButtonText: {
    id: 'Tutorial.lastButtonText',
    defaultMessage: 'Last',
  },
  skipButtonText: {
    id: 'Tutorial.skipButtonText',
    defaultMessage: 'Skip',
  },
});

class Tutorial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.makeState(props),
    };
  }

  makeState(props) {
    const {
      tutorial: { stepIndex, name, run },
    } = props;

    return { ...getTutorial({ name, run }), stepIndex };
  }

  componentWillReceiveProps(nextProps) {
    if (isEqual(this.props.tutorial, nextProps.tutorial)) {
      return;
    }

    this.setState((prevState) => {
      return {
        ...prevState,
        ...this.makeState(nextProps),
      };
    });
  }

  handleJoyrideCallback = (data) => {
    const {
      tutorialActions: { closeTutorial, updateIndex, finishTutorial },
      toggleTutorialsMenuDesc,
    } = this.props;
    const { stepIndex: prevStepIndex, steps, name } = this.state;
    const { action, index, type, size } = data;

    const defScrollOptions = {
      scrollMode: 'always',
      behavior: 'smooth',
      inline: 'center',
    };
    const scrollToCenter = {
      ...defScrollOptions,
      block: 'center',
    };

    if (action === ACTIONS.START && this.state.run) {
      const firstStepTarget = document.querySelector(steps[0].target);
      return firstStepTarget
        ? scrollIntoView(firstStepTarget, scrollToCenter)
        : null;
    } else if (action === ACTIONS.CLOSE && this.state.run) {
      toggleTutorialsMenuDesc();
      return closeTutorial({ name });
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (prevStepIndex === nextIndex) {
      } else if (nextIndex === size) {
        toggleTutorialsMenuDesc();
        return finishTutorial({ name });
      } else {
        const el = document.querySelector(steps[nextIndex].target);

        updateIndex({ stepIndex: nextIndex });
        return el ? scrollIntoView(el, scrollToCenter) : null;
      }
    }
  };

  render() {
    const { run, stepIndex, steps, name } = this.state;
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <Joyride
        key={name}
        showProgress
        continuous
        disableScrolling
        spotlightClicks
        name={name}
        run={run}
        stepIndex={stepIndex}
        steps={steps}
        spotlightPadding={5}
        locale={{
          back: formatMessage(messages.backButtonText),
          close: formatMessage(messages.closeButtonText),
          last: formatMessage(messages.lastButtonText),
          next: formatMessage(messages.nextButtonText),
          skip: formatMessage(messages.skipButtonText),
        }}
        callback={this.handleJoyrideCallback}
        styles={tooltipStyles}
      />
    );
  }
}

Tutorial.propTypes = {
  tutorial: PropTypes.object.isRequired,
  tutorialActions: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(Tutorial);
