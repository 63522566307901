import React, { Component } from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import './Popup.css';

Modal.setAppElement(document.getElementById('root'));

/**
 * Shows popup above all UI elements
 * Additional props not listed here will be passed to '.Modal' element
 * @param {Object} $
 * @param {String} $.className - will be added to the '.Popup__wrapper' element
 * @param {String} $.theme - CSS popup theme
 * @param {String} $.title - popup content label
 * @param {Object} $.classes - class names of the `.Popup` elements
 * @param {React::JSX} $.children - content of the popup
 * @param {React::JSX} $.close - close button layout
 * @param {Boolean} $.isOpen - is popup visible now
 * @param {Boolean} $.noMargin  - do not set margin to `.Popup` element (side effect - will not be centered)
 * @param {Function} $.handleCloseClick - user clicked on close button
 */
class Popup extends Component {
  render() {
    const {
      isOpen,
      handleCloseClick = () => {},
      title,
      className,
      classes = {},
      children,
      close,
      theme = '',
      noMargin = false,
      ...props
    } = this.props;
    const hasControls = [close].some((control) => !!control);

    return (
      <Modal
        isOpen={isOpen}
        contentLabel={title}
        bodyOpenClassName="ReactModal__Body--open"
        className={{
          base: classNames(
            `Popup__modal`,
            theme && `Popup--theme-${theme}`,
            classes.modal
          ),
          afterOpen: 'Popup__modal--opened',
          beforeClose: 'Popup__modal--exit',
        }}
        overlayClassName={{
          base: 'Popup__overlay',
          afterOpen: 'Popup__overlay--opened',
          beforeClose: 'Popup__overlay--exit',
        }}
        onRequestClose={handleCloseClick}
        onAfterOpen={() => {
          document.body.classList.add('ReactModal__Body--open');
        }}
        {...props}
      >
        <div
          className={classNames(
            'Popup',
            noMargin && 'Popup--no-margin',
            className
          )}
        >
          {hasControls ? (
            <div className="Popup__controls">{close || null}</div>
          ) : null}
          {children}
        </div>
      </Modal>
    );
  }
}

export default Popup;
