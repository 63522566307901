import {
  ADD_QUEST,
  CREATE_QUEST,
  CREATE_QUEST_SUCCESS,
  CREATE_QUEST_FAILURE,
  REQUEST_QUESTS,
  RECEIVE_QUESTS,
  SEND_QUEST,
  SYNC_QUEST_SUCCESS,
  SYNC_QUEST_FAILURE,
  REQUEST_QUEST,
  RECEIVE_QUEST,
  NOT_RECEIVE_QUEST,
  RENAME_QUEST,
  DELETE_QUEST,
  DELETE_QUEST_UNDO,
  DELETE_QUEST_SUCCESS,
  DELETE_QUEST_FAILURE,
  ADD_EVENT,
  DELETE_EVENT,
  RENAME_EVENT,
  EDIT_EVENT_DIRECTIONS,
  EDIT_EVENT_GEO,
  EDIT_EVENT_TIME,
  EDIT_EVENT_TEXT,
  EDIT_EVENT_HTML,
  EDIT_EVENT_CHOICE,
  REMOVE_EVENT_IMAGE,
  REMOVE_EVENT_IMAGES,
  DRAG_EVENT_IMAGE,
  RECEIVE_IMAGES_URLS,
  UPDATE_EVENT_IMAGE_DESCRIPTION,
  RECEIVE_VIDEOS_URLS,
  REMOVE_EVENT_VIDEO,
  EDIT_EVENT_AUDIOS,
  EDIT_EVENT_AUDIO_HTML,
  REMOVE_EVENT_AUDIO,
  RECEIVE_AUDIOS_URLS,
  EDIT_EVENT_HINTS,
  EDIT_EVENT_SKIP,
  EDIT_EVENT_BONUS,
  REORDER_EVENTS,
  RECEIVE_QUEST_CODE,
  COPY_QUEST,
  COPY_QUEST_SUCCESS,
  COPY_QUEST_FAILURE,
  RECEIVE_IMAGEAR_URL,
  EDIT_EVENT_AR,
  REMOVE_AR_TARGET,
  RECEIVE_STICKERS_URL,
  REMOVE_EVENT_STICKER,
  REORDER_EVENT_STICKER,
  SELECT_QUEST,
  TOGGLE_MUSEUM,
} from '../constants/ActionTypes';
import {
  spawnImageContent,
  spawnTextContent,
  spawnHtmlContent,
  spawnAudioContent,
  spawnChoiceContent,
  spawnStickerContent,
} from '../constants/events/store';
import guid from '../functions/id/guid';
import reorder from '../functions/reorder';
import editEventContent from '../functions/event/editEventContent';
import convertEventsNextReceived from '../functions/quest/convertEventsNextReceived';
import routesReducers from './route';

/**
 * Gets quest by its id
 * @param {Array[Object]} quests - set of quests where search will be processed
 * @param {Number} questId - id of the quest to find
 * @returns {Object|Undefined}
 */
export function findQuest(quests, questId) {
  return quests.find((quest) => quest.id === questId);
}

/**
 * Copies selected by `questId` quest
 * @param {Object} state - Redux state of the `quests` store, containing `quests` array
 * @param {Number} questId - id of the targeted quest
 * @returns {Object} - quest copy
 */
function copyQuest(state, id) {
  const filtered = state.quests.filter((quest) => quest.id === id);
  if (!filtered) return;
  return { ...filtered[0] };
}

/**
 * Finds event in `quests[].events[]` store
 * @param {Array[Object]} events - where to find event
 * @param {String} id - id of the event
 * @param {Boolean} indexRequired - if position in array should be returned
 * @returns {Object|Number}
 */
export function findEvent(events, id, indexRequired) {
  if (indexRequired) {
    return events.findIndex((event) => event.id === id);
  }

  return events.find((event) => event.id === id) || null;
}

function stateWithNextQuest(state, quest) {
  const questId = quest.id;
  return {
    ...state,
    quests: state.quests.map((q) => (q.id === questId ? quest : q)),
  };
}

export default function quests(
  state = {
    isFetching: false,
    isSending: false,
    isCopying: false,
    isSaving: false,
    isSaved: false,
    isNotSaved: false,
    questsReceived: false,
    syncingRoute: [],
    quests: [],
  },
  action
) {
  let nextQuest;
  let nextState;
  const routesReducersState = routesReducers(state, action);
  if (routesReducersState) {
    return routesReducersState;
  }

  switch (action.type) {
    case ADD_QUEST:
      return {
        ...state,
        isCreating: true,
      };

    case CREATE_QUEST: {
      return {
        ...state,
        isCreating: true,
      };
    }

    case CREATE_QUEST_SUCCESS:
    case CREATE_QUEST_FAILURE: {
      return {
        ...state,
        isCreating: false,
      };
    }

    case DELETE_QUEST: {
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        isNotSaved: false,
      };
    }

    case DELETE_QUEST_FAILURE:
    case DELETE_QUEST_UNDO: {
      return {
        ...state,
        isSaving: false,
        isSaved: false,
        isNotSaved: true,
      };
    }

    case DELETE_QUEST_SUCCESS: {
      return {
        ...state,
        isSaving: false,
        isSaved: true,
        isNotSaved: false,
        quests: state.quests.filter((quest) => quest.id !== action.id),
      };
    }

    case RENAME_QUEST: {
      nextQuest = copyQuest(state, action.questId);
      nextQuest.title = action.title;
      nextQuest.lastModifiedText = new Date().toISOString();

      if (nextQuest.copies) {
        nextQuest.copies = nextQuest.copies.map((copy) => ({
          ...copy,
          synchronized: false,
        }));
      }

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case REQUEST_QUESTS: {
      return {
        ...state,
        isFetching: true,
      };
    }

    // case RECEIVE_QUESTS: {
    //   return {
    //     ...state,
    //     isFetching: false,
    //     questsReceived: true,
    //     quests: action.quests.map((quest) => ({
    //       ...convertEventsNextReceived(quest),
    //       routes: (quest.routes ? quest.routes : []).map((p) => ({
    //         guid: guid(),
    //         ...p,
    //       })),
    //       ok: true,
    //       syncing: false,
    //     })),
    //   };
    // }

    case REQUEST_QUEST: {
      nextQuest = copyQuest(state, action.id);
      nextQuest.syncing = true;
      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.id ? nextQuest : quest
        ),
        isFetching: true,
      };
    }

    case RECEIVE_QUEST: {
      const { quests } = state;
      let nextQuests = [];
      nextState = {
        ...state,
        isFetching: false,
      };
      const exist = quests.filter((quest) => quest.id === action.quest.id)
        .length;
      const { routes } = action.quest;
      const receivedQuest = convertEventsNextReceived(action.quest);
      receivedQuest.ok = true;
      receivedQuest.syncing = false;
      receivedQuest.routes = (routes || []).map((p) => ({
        guid: guid(),
        ...p,
      }));

      if (exist) {
        nextQuests = quests.map((quest) =>
          quest.id === receivedQuest.id ? receivedQuest : quest
        );
      } else {
        nextQuests = [...quests, receivedQuest];
      }

      return {
        ...state,
        quests: nextQuests,
        isFetching: false,
      };
    }

    case NOT_RECEIVE_QUEST: {
      nextQuest = copyQuest(state, action.id);
      nextQuest.syncing = false;
      nextQuest.ok = false;
      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
        isFetching: false,
      };
    }

    case RECEIVE_QUEST_CODE: {
      return {
        ...state,
        quests: state.quests.map((quest) => {
          if (quest.id !== action.id) return quest;
          return {
            ...quest,
            shownAccess: action.payload,
          };
        }),
      };
    }

    case SEND_QUEST: {
      nextQuest = copyQuest(state, action.id);
      nextQuest.syncing = true;
      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.id ? nextQuest : quest
        ),
        isSyncing: true,
      };
    }

    case SYNC_QUEST_SUCCESS: {
      nextQuest = copyQuest(state, action.id);
      nextQuest.lastModified = action.lastModified;
      nextQuest.ok = true;
      nextQuest.syncing = false;
      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.id ? nextQuest : quest
        ),
        isSyncing: false,
      };
    }

    case SYNC_QUEST_FAILURE: {
      nextQuest = copyQuest(state, action.id);
      nextQuest.ok = false;
      nextQuest.syncing = false;
      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.id ? nextQuest : quest
        ),
        isSyncing: false,
      };
    }

    case SELECT_QUEST: {
      return {
        ...state,
        currentQuestId: action.id,
      };
    }

    case ADD_EVENT: {
      let nextQuest = copyQuest(state, action.questId);
      let order = nextQuest.events.length;
      if (typeof action.order === 'number') {
        order = action.order;
      } else if (nextQuest.events.length > 1) {
        order = nextQuest.events.length - 1;
      }
      nextQuest.events = nextQuest.events.map((e, i) =>
        i >= order ? { ...e, order: e.order + 1 } : e
      );
      nextQuest.events.splice(order, 0, {
        title: action.title,
        id: action.guid ? action.guid : guid(),
        order,
        trigger: action.time === 0 ? { time: 0 } : {},
        skip: {
          allow: true,
          penalty: 0,
        },
        nextButton: '',
        bonus: 0,
        content: [
          spawnImageContent(),
          spawnTextContent(),
          spawnHtmlContent(`<p>${action.html}</p>`),
          spawnAudioContent(),
          spawnChoiceContent(),
          spawnStickerContent(),
        ],
      });

      nextQuest.lastModifiedText = new Date().toISOString();

      nextQuest.copies = (nextQuest.copies || []).map((copy) => ({
        ...copy,
        synchronized: false,
      }));

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case DELETE_EVENT: {
      let nextQuest = {
        ...state.quests.filter((quest) => quest.id == action.questId)[0],
      };
      nextQuest.events = nextQuest.events.filter(
        (event) => event.id !== action.eventId
      );

      nextQuest.events = nextQuest.events.map((event, i) => {
        event.order = i;
        return event;
      });

      nextQuest.lastModifiedText = new Date().toISOString();

      nextQuest.copies = (nextQuest.copies || []).map((copy) => ({
        ...copy,
        synchronized: false,
      }));

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case RENAME_EVENT: {
      nextQuest = {
        ...state.quests.filter((quest) => quest.id == action.questId)[0],
      };
      const event = nextQuest.events.filter(
        (event) => event.id == action.eventId
      )[0];
      event.title = action.title;

      nextQuest.lastModifiedText = new Date().toISOString();
      nextQuest.copies = (nextQuest.copies || []).map((copy) => ({
        ...copy,
        synchronized: false,
      }));

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case EDIT_EVENT_DIRECTIONS: {
      nextQuest = copyQuest(state, action.questId);
      const event = findEvent(nextQuest.events, action.eventId);
      if (!event) {
        return state;
      }

      const content = event.content || [];
      const dirContentIndex = content.findIndex(
        (item) => item.contentType === 'directions'
      );
      content[dirContentIndex > -1 ? dirContentIndex : content.length] = {
        contentType: 'directions',
        visible: action.visible,
        data: action.data,
      };
      event.content = [...content];

      nextQuest.lastModifiedText = new Date().toISOString();
      nextQuest.copies = (nextQuest.copies || []).map((copy) => ({
        ...copy,
        synchronized: false,
      }));

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case EDIT_EVENT_GEO: {
      nextQuest = {
        ...state.quests.filter((quest) => quest.id == action.questId)[0],
      };
      const event = nextQuest.events.filter(
        (event) => event.id == action.eventId
      )[0];
      event.trigger = { ...event.trigger, geo: action.geo };
      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case EDIT_EVENT_TIME: {
      nextQuest = {
        ...state.quests.filter((quest) => quest.id == action.questId)[0],
      };
      const event = nextQuest.events.filter(
        (event) => event.id == action.eventId
      )[0];
      event.trigger = { ...event.trigger, time: action.time };
      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case EDIT_EVENT_TEXT: {
      nextQuest = {
        ...state.quests.filter((quest) => quest.id == action.questId)[0],
      };
      const event = nextQuest.events.filter(
        (event) => event.id == action.eventId
      )[0];
      const text = {
        contentType: 'text',
        visible: action.visible,
        data: action.text,
      };
      if (!event) {
        return state;
      }
      if (!event.content) {
        event.content = [text];
      } else {
        event.content = event.content.filter(
          (item) => item.contentType != 'text'
        );
        event.content = [...event.content, text];
      }

      nextQuest.lastModifiedText = new Date().toISOString();
      nextQuest.copies = (nextQuest.copies || []).map((copy) => ({
        ...copy,
        synchronized: false,
      }));

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case EDIT_EVENT_HTML: {
      const index = state.quests.findIndex(
        (quest) => quest.id === action.questId
      );
      if (index === -1) return state;

      const updatedQuest = { ...state.quests[index] };
      const updatedEvents = updatedQuest.events.map((event) =>
        event.id === action.eventId ? { ...event } : event
      );
      const eventIndex = updatedEvents.findIndex(
        (event) => event.id === action.eventId
      );
      if (eventIndex !== -1) {
        const updatedEvent = { ...updatedEvents[eventIndex] };
        const contentIndex = updatedEvent.content.findIndex(
          (item) => item.contentType === 'html'
        );

        if (contentIndex !== -1) {
          updatedEvent.content[contentIndex] = {
            ...updatedEvent.content[contentIndex],
            data: action.html,
            visible: action.visible,
            lastModified: new Date().toISOString(),
          };
        } else {
          updatedEvent.content.push({
            contentType: 'html',
            visible: action.visible,
            data: action.html,
            lastModified: new Date().toISOString(),
          });
        }

        updatedEvents[eventIndex] = updatedEvent;
      }

      updatedQuest.events = updatedEvents;
      updatedQuest.lastModifiedText = new Date().toISOString();

      updatedQuest.copies = (updatedQuest.copies || []).map((copy) => ({
        ...copy,
        synchronized: false,
      }));

      const updatedQuests = [...state.quests];
      updatedQuests[index] = updatedQuest;
      return {
        ...state,
        quests: updatedQuests,
      };
    }

    case RECEIVE_IMAGES_URLS: {
      const { imageId } = action;
      nextQuest = copyQuest(state, action.questId);
      const event = findEvent(nextQuest.events, action.eventId);
      if (event) {
        event.content = editEventContent(event.content, 'image', (images) => {
          const index =
            images.data.length > imageId ? imageId : images.data.length;
          const newDesc = action.images.map((src) => '');
          images.data.splice(index, 0, ...action.images);
          if (images.captions) {
            images.captions.splice(index, 0, ...newDesc);
          }
          return images;
        });
      }

      nextQuest.lastModifiedText = new Date().toISOString();
      nextQuest.copies = (nextQuest.copies || []).map((copy) => ({
        ...copy,
        synchronized: false,
      }));

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case UPDATE_EVENT_IMAGE_DESCRIPTION: {
      nextQuest = copyQuest(state, action.questId);
      const event = findEvent(nextQuest.events, action.eventId);
      if (event && event.content) {
        event.content = editEventContent(event.content, 'image', (images) => {
          if (images && !images.hasOwnProperty('captions')) {
            images.captions = [];
          }
          if (images && images.data.length !== images.captions.length) {
            const nextCaptions = images.captions || [];
            for (let i = images.captions.length; i < images.data.length; i++) {
              nextCaptions.push('');
            }
            images.captions = nextCaptions;
          }
          images.captions[action.imageId] = String(action.description);

          return images;
        });
      }

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case DRAG_EVENT_IMAGE: {
      const { imageId, newImageId, isUpload } = action;
      nextQuest = copyQuest(state, action.questId);
      const event = findEvent(nextQuest.events, action.eventId);
      if (event && event.content && !isUpload) {
        event.content = editEventContent(event.content, 'image', (images) => {
          const { length } = images.data;

          if (length >= newImageId && length > imageId) {
            const tmpImage = images.data.splice(imageId, 1)[0];
            images.data.splice(newImageId, 0, tmpImage);
            if (images.captions) {
              const impDesc = images.captions.splice(imageId, 1)[0];
              images.captions.splice(newImageId, 0, impDesc);
            }
          }

          return images;
        });
      }

      nextQuest.lastModifiedText = new Date().toISOString();
      nextQuest.copies = (nextQuest.copies || []).map((copy) => ({
        ...copy,
        synchronized: false,
      }));

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case REMOVE_EVENT_IMAGE: {
      nextQuest = copyQuest(state, action.questId);
      const event = findEvent(nextQuest.events, action.eventId);
      if (event && event.content) {
        event.content = editEventContent(
          event.content,
          'image',
          ({ data, captions, ...images }) => ({
            ...images,
            data: data.filter((url, i) => i !== action.imageId),
            captions: (captions || []).filter(
              (desc, i) => i !== action.imageId
            ),
          })
        );
      }

      nextQuest.lastModifiedText = new Date().toISOString();
      nextQuest.copies = (nextQuest.copies || []).map((copy) => ({
        ...copy,
        synchronized: false,
      }));

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case REMOVE_EVENT_IMAGES: {
      nextQuest = copyQuest(state, action.questId);
      const event = findEvent(nextQuest.events, action.eventId);
      if (event && event.content) {
        event.content = editEventContent(
          event.content,
          'image',
          ({ data, captions, ...images }) => {
            const { imageIds } = action;
            return {
              ...images,
              data: data.filter((url, i) => !imageIds.includes(i)),
              captions: (captions || []).filter(
                (desc, i) => !imageIds.includes(i)
              ),
            };
          }
        );
      }

      nextQuest.lastModifiedText = new Date().toISOString();
      nextQuest.copies = (nextQuest.copies || []).map((copy) => ({
        ...copy,
        synchronized: false,
      }));

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case RECEIVE_VIDEOS_URLS: {
      nextQuest = {
        ...state.quests.filter((quest) => quest.id == action.questId)[0],
      };
      const event = nextQuest.events.filter(
        (event) => event.id == action.eventId
      )[0];
      const video = {
        contentType: 'video',
        visible: true,
        data: action.payload,
      };
      if (!event.content) {
        event.content = [video];
      } else {
        let currentVideos = event.content.filter(
          (item) => item.contentType == 'video'
        );
        if (currentVideos.length) {
          currentVideos = currentVideos[0];
          event.content = event.content.filter(
            (item) => item.contentType != 'video'
          );
          const currentData = currentVideos.data;
          video.data = [...currentData, ...video.data];
          event.content = [...event.content, video];
        } else if (video.data) {
          event.content = [...event.content, video];
        }
      }

      nextQuest.lastModifiedText = new Date().toISOString();
      nextQuest.copies = (nextQuest.copies || []).map((copy) => ({
        ...copy,
        synchronized: false,
      }));

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case REMOVE_EVENT_VIDEO: {
      nextQuest = {
        ...state.quests.filter((quest) => quest.id == action.questId)[0],
      };
      const event = nextQuest.events.filter(
        (event) => event.id == action.eventId
      )[0];
      if (event.content) {
        let currentVideos = event.content.filter(
          (item) => item.contentType == 'video'
        );
        if (currentVideos.length) {
          currentVideos = currentVideos[0];
          event.content = event.content.filter(
            (item) => item.contentType != 'video'
          );
          currentVideos.data = currentVideos.data.filter(
            (img, i) => i != action.id
          );
          if (currentVideos.data.length) {
            event.content = [...event.content, currentVideos];
          }
        }
      }
      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case RECEIVE_AUDIOS_URLS: {
      nextQuest = copyQuest(state, action.questId);
      const event = nextQuest.events.filter(
        (event) => event.id == action.eventId
      )[0];
      const audios = {
        contentType: 'audio',
        visible: true,
        data: action.audios,
        autoplay: true,
      };
      if (!event.content) {
        event.content = [audios];
      } else {
        let currentAudios = event.content.filter(
          (item) => item.contentType == 'audio'
        );
        if (currentAudios.length) {
          currentAudios = currentAudios[0];
          event.content = event.content.filter(
            (item) => item.contentType != 'audio'
          );
          const currentData = currentAudios.data;
          audios.data = audios.data.concat(currentData);
          audios.html = currentAudios.html;
          event.content = [...event.content, audios];
        } else if (audios.data) {
          event.content = [...event.content, audios];
        }
      }

      nextQuest.lastModifiedText = new Date().toISOString();
      nextQuest.copies = (nextQuest.copies || []).map((copy) => ({
        ...copy,
        synchronized: false,
      }));

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case REMOVE_EVENT_AUDIO: {
      nextQuest = copyQuest(state, action.questId);
      const event = nextQuest.events.filter(
        (event) => event.id == action.eventId
      )[0];
      if (event.content) {
        const currentAudios = event.content.find(
          (item) => item.contentType === 'audio'
        );
        if (currentAudios) {
          currentAudios.data = currentAudios.data.filter(
            (mp3, i) => i != action.audioId
          );
          event.content = event.content.filter(
            (item) => item.contentType !== 'audio'
          );
          event.content = [...event.content, currentAudios];
        }
      }

      nextQuest.lastModifiedText = new Date().toISOString();
      nextQuest.copies = (nextQuest.copies || []).map((copy) => ({
        ...copy,
        synchronized: false,
      }));

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case EDIT_EVENT_AUDIOS: {
      let { questId, eventId, visible, autoplay } = action;
      const nextQuest = copyQuest(state, questId);
      const event = findEvent(nextQuest.events, eventId);
      if (event.content) {
        const currentAudios = event.content.find(
          (item) => item.contentType === 'audio'
        );
        if (visible === undefined) {
          // If visible is not defined, calculate it
          visible = Boolean(currentAudios.data.length);
        }
        if (currentAudios) {
          event.content = event.content.map((item) => {
            if (item.contentType !== 'audio') return item;
            return {
              ...currentAudios,
              visible,
              autoplay,
            };
          });
        } else {
          const audioItem = {
            contentType: 'audio',
            visible: false,
            data: [],
            autoplay: true,
          };
          event.content = [...event.content, audioItem];
        }
      }

      nextQuest.lastModifiedText = new Date().toISOString();
      nextQuest.copies = (nextQuest.copies || []).map((copy) => ({
        ...copy,
        synchronized: false,
      }));

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === questId ? nextQuest : quest
        ),
      };
    }

    case EDIT_EVENT_AUDIO_HTML: {
      const { html } = action;
      nextQuest = copyQuest(state, action.questId);
      const event = findEvent(nextQuest.events, action.eventId);
      if (event) {
        event.content = editEventContent(event.content, 'audio', (audio) => {
          return { ...audio, html };
        });
      }

      nextQuest.lastModifiedText = new Date().toISOString();
      nextQuest.copies = (nextQuest.copies || []).map((copy) => ({
        ...copy,
        synchronized: false,
      }));

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case RECEIVE_IMAGEAR_URL: {
      nextQuest = copyQuest(state, action.questId);
      const event = findEvent(nextQuest.events, action.eventId);
      const { trigger } = event;

      if (!trigger) {
        return state;
      }

      let oldTargets = [];
      if (trigger.ar && trigger.ar != null) {
        const { targets = [] } = trigger.ar;
        oldTargets = targets;
      }

      const newTargets = [
        ...oldTargets,
        { url: action.image[0], width: 0, height: 0 },
      ];

      event.trigger = { ...event.trigger, ar: { targets: newTargets } };

      nextQuest.lastModifiedText = new Date().toISOString();
      nextQuest.copies = (nextQuest.copies || []).map((copy) => ({
        ...copy,
        synchronized: false,
      }));

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case EDIT_EVENT_AR: {
      nextQuest = copyQuest(state, action.questId);
      const event = findEvent(nextQuest.events, action.eventId);
      event.trigger = { ...event.trigger, ar: action.ar };

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case REMOVE_AR_TARGET: {
      nextQuest = copyQuest(state, action.questId);
      const event = findEvent(nextQuest.events, action.eventId);
      const { trigger } = event;

      if (trigger && trigger.ar) {
        const { ar } = trigger;
        const newTargets = ar.targets.filter(
          (target, idx) => idx !== action.arTargetId
        );
        event.trigger = { ...trigger, ar: { targets: newTargets } };
      }

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case EDIT_EVENT_CHOICE: {
      nextQuest = copyQuest(state, action.questId);
      const event = nextQuest.events.filter(
        (event) => event.id == action.eventId
      )[0];
      const choice = {
        contentType: 'choice',
        visible: action.visible,
        data: {
          options: action.options,
          answer: action.answer,
        },
      };
      if (!event.content) {
        event.content = [choice];
      } else {
        event.content = event.content.filter(
          (item) => item.contentType != 'choice'
        );
        event.content = [...event.content, choice];
      }

      nextQuest.lastModifiedText = new Date().toISOString();
      nextQuest.copies = (nextQuest.copies || []).map((copy) => ({
        ...copy,
        synchronized: false,
      }));

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case EDIT_EVENT_HINTS: {
      nextQuest = copyQuest(state, action.questId);
      const event = findEvent(nextQuest.events, action.eventId);
      event.hints = action.hints;
      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case EDIT_EVENT_SKIP: {
      let nextQuest = copyQuest(state, action.questId);

      const event = findEvent(nextQuest.events, action.eventId);
      event.skip = { ...event.skip, ...action.skip };

      event.prevButton =
        typeof action.button === 'string' ? action.button : event.prevButton;

      nextQuest.lastModifiedText = new Date().toISOString();

      nextQuest.copies = (nextQuest.copies || []).map((copy) => ({
        ...copy,
        synchronized: false,
      }));

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case EDIT_EVENT_BONUS: {
      nextQuest = copyQuest(state, action.questId);
      const event = findEvent(nextQuest.events, action.eventId);
      event.bonus = action.bonus;
      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case RECEIVE_STICKERS_URL: {
      const { stickerId } = action;
      nextQuest = copyQuest(state, action.questId);
      const event = findEvent(nextQuest.events, action.eventId);
      if (event) {
        event.content = editEventContent(
          event.content,
          'sticker',
          (stickers) => {
            const index =
              stickers.data.length > stickerId
                ? stickerId
                : stickers.data.length;
            stickers.data.splice(index, 0, ...action.stickers);
            return stickers;
          }
        );
      }

      nextQuest.lastModifiedText = new Date().toISOString();
      nextQuest.copies = (nextQuest.copies || []).map((copy) => ({
        ...copy,
        synchronized: false,
      }));

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case REMOVE_EVENT_STICKER: {
      nextQuest = copyQuest(state, action.questId);
      const event = findEvent(nextQuest.events, action.eventId);
      if (event && event.content) {
        event.content = editEventContent(
          event.content,
          'sticker',
          ({ data, ...sticker }) => ({
            ...sticker,
            data: data.filter((url, i) => i !== action.imageId),
          })
        );
      }

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case REORDER_EVENT_STICKER: {
      const { imageId, newImageId, isUpload } = action;
      nextQuest = copyQuest(state, action.questId);
      const event = findEvent(nextQuest.events, action.eventId);
      if (event && event.content && !isUpload) {
        event.content = editEventContent(
          event.content,
          'sticker',
          (stickers) => {
            const { length } = stickers.data;
            if (length >= newImageId && length > imageId) {
              const tmpImage = stickers.data.splice(imageId, 1)[0];
              stickers.data.splice(newImageId, 0, tmpImage);
            }

            return stickers;
          }
        );
      }

      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };
    }

    case REORDER_EVENTS: {
      const {
        ids: { questId },
        fromIndex,
        toIndex,
      } = action;
      nextQuest = copyQuest(state, questId);

      nextQuest.events = reorder(nextQuest.events, fromIndex, toIndex).map(
        (event, index, eventsList) => {
          // Update order value
          const { order } = event;
          event.order = index;

          // Position did not changed
          if (order === index) return event;

          if (index === 0) {
            // Become first
            event.trigger = { time: 0 };
          } else if (index === eventsList.length - 1) {
            // Become last
            event.content = event.content.map((item) =>
              item.contentType === 'choice'
                ? {
                    contentType: 'choice',
                    data: {
                      options: [],
                      answer: -1,
                    },
                    visible: false,
                  }
                : item
            );
          } else if (order === 0) {
            // Lose first place
            event.trigger = {};
          } else {
            const prev = eventsList[index - 1];
            const choiceVisible = Boolean(
              prev.content.find(
                (item) => item.contentType === 'choice' && item.visible
              )
            );
            // Clear trigger if choice set in prev event
            if (choiceVisible) {
              event.trigger = {};
            }
          }

          return event;
        }
      );

      return stateWithNextQuest(state, nextQuest);
    }

    case COPY_QUEST: {
      return {
        ...state,
        isCopying: true,
      };
    }

    case COPY_QUEST_SUCCESS:
    case COPY_QUEST_FAILURE: {
      return {
        ...state,
        isCopying: false,
      };
    }

    case TOGGLE_MUSEUM:
      nextQuest = copyQuest(state, action.questId);
      nextQuest.isMuseum = action.isMuseum;
      return {
        ...state,
        quests: state.quests.map((quest) =>
          quest.id === action.questId ? nextQuest : quest
        ),
      };

    default:
      return state;
  }
}
