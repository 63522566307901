import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MediaPlayer from '../MediaPlayer';
import ProgressCircle from '../ProgressCircle';
import './VideoPlayer.css';

class VideoPlayer extends Component {
  render() {
    const {
      src,
      name,
      children,
      className,
      wrapperClassName,
      theme,
      ...otherProps
    } = this.props;
    const themed = theme ? `VideoPlayer--theme-${theme}` : '';

    return (
      <MediaPlayer
        className={classNames('VideoPlayer__wrapper', themed, wrapperClassName)}
        src={src}
        render={({ status, progress, controls: { togglePlaying } }) => (
          <div
            className={classNames(
              'VideoPlayer',
              status === 'loading' && 'VideoPlayer--loading',
              status === 'error' && 'VideoPlayer--error',
              className
            )}
            {...otherProps}
          >
            <div className="VideoPlayer__inner">
              <div className="VideoPlayer__controls">
                {status === 'loading' ? (
                  <ProgressCircle
                    className="VideoPlayer__loader"
                    progress={66}
                    size={50}
                  />
                ) : (
                  <button
                    className={classNames(
                      'VideoPlayer__toggle',
                      `VideoPlayer__toggle--${status}`
                    )}
                    onClick={togglePlaying}
                  />
                )}
              </div>
              {(status === 'play' || status === 'pause') && (
                <div className="VideoPlayer__progress-wrapper">
                  <div
                    className="VideoPlayer__progress"
                    style={{ width: `${progress}%` }}
                  />
                </div>
              )}
              <div className="VideoPlayer__children">{children}</div>
            </div>
          </div>
        )}
      />
    );
  }
}

VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
};

export class VideoPlayerMock extends Component {
  render() {
    const { name, children, className, ...otherProps } = this.props;
    return (
      <div
        className={classNames('VideoPlayer', 'VideoPlayer--mock', className)}
        {...otherProps}
      >
        <div className="VideoPlayer__inner">
          <div className="VideoPlayer__controls">
            <span className="VideoPlayer__name">{name}</span>
          </div>
          <div className="VideoPlayer__children">{children}</div>
        </div>
      </div>
    );
  }
}

export default VideoPlayer;
