import Api from '../../../functions/Api';

export async function FetchCity(lang, countryId, page = 1) {
  let city;

  try {
    const response = await Api.get(
      `v2/cities/?country=${countryId}&preorder=true&published=false&page=${page}`,
      {
        headers: {
          'Accept-Language': lang,
        },
      }
    );
    city = response.data.results;
  } catch (e) {
    if (!city) {
      return { statusCode: 404 };
    }
  }

  return city;
}
