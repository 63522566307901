import TableFilterText, {
  filterText,
  TableFilterTextInfo,
} from '../../components/Table/TableFilterText';
import TableFilterOptions, {
  filterOptions,
  TableFilterOptionsInfo,
} from '../../components/Table/TableFilterOptions';
import TableFilterDate, {
  filterDate,
  TableFilterDateInfo,
} from '../../components/Table/TableFilterDate';

/**
 * Default table filters
 * For `Table::withFilters::props::filters` prop
 * @type {Object<String, TableColumnFilter>}
 */
export default {
  string: [TableFilterText, filterText, TableFilterTextInfo],
  options: [TableFilterOptions, filterOptions, TableFilterOptionsInfo],
  date: [TableFilterDate, filterDate, TableFilterDateInfo],
};
