import React from 'react';
import useSavedProp from '../../../functions/react/useSavedProp';
import defaultToggledColumns from '../../../functions/table/defaultToggledColumns';

/**
 * Adds ability to save and restore `Table::withColumnsToggle` state (hidden columns) in cookie
 * @param {React::Component} Component - component to overload
 */
export default function withToggledColumnsSaving(Component) {
  /**
   * `Table::withColumnsToggle` with ability to save hidden columns in cookie
   * @param {Object} $
   * @param {String} $.id - table id (must be unique), uses as name of the localStorage prop
   */
  return function TableToggledColumnSaving({
    id,
    header,
    onColumnToggled = () => {},
    ...tableProps
  }) {
    const [hiddenCols, setHiddenCols] = useSavedProp(
      `table-${id}-hidden-cols`,
      (v) => (typeof v === 'string' ? v.split(',') : [])
    );

    return (
      <Component
        header={defaultToggledColumns(header, hiddenCols)}
        onColumnToggled={(col, isToggled, hidden) => {
          setHiddenCols(hidden.join());
          onColumnToggled(col, isToggled, hidden);
        }}
        {...tableProps}
      />
    );
  };
}
