import React from 'react';
import { FormattedMessage } from 'react-intl';

export const PaymentMethods = [
  // {title: <FormattedMessage id="PaymentMethods.creditCard" defaultMessage="Credit card"/>, value: 'AC'},
  // {title: <FormattedMessage id="PaymentMethods.yandexMoney" defaultMessage="Yandex.Money"/>, value: 'PC'},
  // {title: <FormattedMessage id="PaymentMethods.free" defaultMessage="Free"/>, value: 'FC'},
  // {title: <span>WeGoTrip</span>, value: 'WE'},
  // {title: <span>iOS</span>, value: 'IP'},
  // {title: <span>Andriod</span>, value: 'AB'},
  // {title: <span>Viator</span>, value: 'VI'},
  // {title: <span>Expedia</span>, value: 'EX'},
  // {title: <span>Klook</span>, value: 'KL'},
  // {title: <span>Musement</span>, value: 'MU'},
  // {title: <span>Bokun</span>, value: 'BO'}
  { title: <span>Web</span>, value: 'WE' },
  { title: <span>iOS</span>, value: 'IS' },
  { title: <span>Andriod</span>, value: 'AS' },
  { title: <span>OTA</span>, value: 'OT' },
  {
    title: (
      <FormattedMessage id="PaymentMethods.unknown" defaultMessage="Unknown" />
    ),
    value: 'UN',
  },
];
