import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import './TourPublish.css';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { Step, StepLabel, makeStyles, Paper, Stepper } from '@material-ui/core';
import * as publishingActions from '../../../actions/publishing';

/**
 * JSS styles for `TourPublish` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 24,
    marginTop: 40,
    marginBottom: -15,
  },
  container: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
  },
  paperLast: {
    padding: 24,
    marginTop: 40,
  },
  text: {
    marginTop: 40,
  },
  textFieldSm: {
    maxWidth: 160,
    display: 'block',
    marginBottom: 25,
  },
  stepperText: {
    maxWidth: 160,
    display: 'block',
  },
}));

/**
 * Gets step title
 * @returns {Array[Object]} - steps titles
 */
function getSteps() {
  return [
    <FormattedMessage
      id="StudioCreateProduct.submitForModeration"
      defaultMessage="You have created a tour and everything filled"
    />,
    <FormattedMessage
      id="StudioCreateProduct.checkingTour"
      defaultMessage="We'll check everything"
    />,
    <FormattedMessage
      id="StudioCreateProduct.startOfSales"
      defaultMessage="Start of sales!"
    />,
  ];
}

/**
 * Gets step subtitle
 * @param {Number} step - step number
 * @returns {Object} - step subtitle
 */
function getStepContent(step) {
  switch (step) {
    case 0:
      return (
        <FormattedMessage
          id="StudioCreateProduct.greatJob"
          defaultMessage="Great job!"
        />
      );
    case 1:
      return (
        <FormattedMessage
          id="StudioCreateProduct.onModeration"
          defaultMessage="Will take up to 14 days. Maybe ask for small changes"
        />
      );
    case 2:
      return;
    default:
      return 'Unknown step';
  }
}

const TourPublish = ({
  intl,
  publishing: {
    receivedPublishData: { status },
  },
}) => {
  /**
   * JSS styles for `TourPublish` element
   * @type {React::Hook}
   */
  const classes = useStyles();
  /**
   * Current active step
   * @type {Number}
   */
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  /**
   * Updating current step
   */

  useEffect(() => {
    if (status)
      switch (status) {
        case 'draft':
          return;
        case 'waiting_for_moderation':
          return setActiveStep(1);
        case 'on_moderation':
          return setActiveStep(1);
        case 'changes_are_needed':
          return setActiveStep(1);
        case 'approved':
          return setActiveStep(2);
        default:
          return 'Unknown step';
      }
  }, [status]);

  return (
    <section className="App__main TourPublish__main">
      <Container className={classes.container}>
        <Typography gutterBottom className={classes.text} variant="h4">
          <FormattedMessage
            id="StudioCreateProduct.tourIsBeingModerated"
            defaultMessage="Hooray, your tour is being moderated!"
          />
        </Typography>

        <Paper elevation={0} className={classes.paper}>
          <Typography gutterBottom variant="h5">
            <FormattedMessage
              id="StudioCreateProduct.whatsNext"
              defaultMessage="What's next?"
            />
          </Typography>

          <Stepper
            activeStep={activeStep}
            orientation={window.innerWidth < 755 ? 'vertical' : 'horizontal'}
          >
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>
                  {label}
                  <Typography className={classes.stepperText} variant="caption">
                    {getStepContent(index)}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Paper>
      </Container>
    </section>
  );
};

function mapStateToProps() {
  const mapStateToProps = (state, props) => {
    return {
      publishing: state.publishing,
    };
  };
  return mapStateToProps;
}

function mapDispatchToProps(dispatch) {
  return {
    publishingActions: bindActionCreators(publishingActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(TourPublish)));
