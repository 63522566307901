/**
 * Returns message if it exists in `messages`, in other case - returns `defaultMessage`
 * @param {Function} formatMessage - `Intl::formatMessage` from `react-intl`
 * @param {Object} messages - defined messages where quired message can exist
 * @param {String} id - possible key of the message in `messages` arg
 * @param {String} defaultMessage - will be returned if `messages[id]` does not exists
 * @returns {String} - resulted translated message
 */
export default function messageById(
  formatMessage,
  messages,
  id,
  defaultMessage
) {
  return messages[id] ? formatMessage(messages[id]) : defaultMessage;
}
