import React, { useState, Fragment, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { defineMessages } from 'react-intl';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardActions';
import CardActions from '@material-ui/core/CardActions';
import DeleteIcon from '@material-ui/icons/Delete';
import composeMaterialTheme from '../../../functions/materialTheme';
import { EVENT_MAX_TITLE_LENGTH } from '../../../constants/limits';

/**
 * Messages for `RoutePointEditorAddress` component
 * @type {Object}
 */
const addressMessages = defineMessages({
  loading: {
    id: 'RoutePointEditorAddress.loading',
    defaultMessage: 'Loading address...',
  },
  error: {
    id: 'RoutePointEditorAddress.errorLoading',
    defaultMessage: 'Failed loading address',
  },
  retry: {
    id: 'RoutePointEditorAddress.retry',
    defaultMessage: 'Retry',
  },
});

/**
 * JSS styles for `RoutePointEditorAddress` element
 * @type {React::Hook}
 */
const useSubtitleStyles = makeStyles((theme) => ({
  root: {
    marginTop: '4px !important',
    color: theme.palette.text.secondary,
    fontSize: '12px',
    lineHeight: '1.67',
    letterSpacing: '0.4px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  error: {
    color: theme.palette.error.main,
  },
  retry: {
    textDecoration: 'underline',
    cursor: 'pointer',
    transition: 'opacity 0.3s ease',
    '&:hover': {
      opacity: 0.5,
    },
  },
}));

/**
 * Subtitle in the `RoutePointEditor`
 * Manages address and supports showing errors
 * @param {Object} $
 * @param {Object?} $.error - error text shown instead of address, will be put into `ReactIntl::formatMessage` as argument
 * @param {String?|Boolean} $.address - address of the point (`undefined|null` if loading, string if loaded, `false` if failed)
 * @param {Function} $.onRequested - address reloading was requested
 */
function RoutePointEditorSubtitle({
  address,
  error,
  onRequested,
  intl: { formatMessage },
}) {
  let content = formatMessage(addressMessages.loading);
  let containerProps = {};

  const styles = useSubtitleStyles();

  if (error) {
    containerProps = { className: `${styles.root} ${styles.error}` };
    content = formatMessage(error);
  } else if (typeof address === 'string') {
    content = address;
  } else if (address === false) {
    containerProps = { className: `${styles.root} ${styles.error}` };
    content = (
      <Fragment>
        {formatMessage(addressMessages.error)}
        {'. '}
        <span className={styles.retry} onClick={onRequested}>
          {formatMessage(addressMessages.retry)}
        </span>
      </Fragment>
    );
  }

  return (
    <Typography className={styles.root} component="div" {...containerProps}>
      {content}
    </Typography>
  );
}

/**
 * Messages for `RoutePointEditor` component
 * @type {Object}
 */
const messages = defineMessages({
  addressError: {
    id: 'RoutePointEditor.addressError',
    defaultMessage: 'Please, wait until address loaded or reload it',
  },
  namePlaceholder: {
    id: 'RoutePointEditor.namePlaceholder',
    defaultMessage: 'Name of the place',
  },
  nameError: {
    id: 'RoutePointEditor.nameError',
    defaultMessage: 'Please, enter name of the place',
  },
  actionAdd: {
    id: 'RoutePointEditor.actionAdd',
    defaultMessage: 'Add point',
  },
  actionEdit: {
    id: 'RoutePointEditor.actionEdit',
    defaultMessage: 'Save',
  },
  actionToEvent: {
    id: 'RoutePointEditor.actionToEvent',
    defaultMessage: 'Show event',
  },
  actionClose: {
    id: 'RoutePointEditor.actionClose',
    defaultMessage: 'Close',
  },
  actionCancel: {
    id: 'RoutePointEditor.actionCancel',
    defaultMessage: 'Cancel',
  },
});

/**
 * JSS styles for `RoutePointEditor` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    boxShadow: 'none',
  },
  header: {
    display: 'flex',
    alignItems: 'unset',
  },
  headerText: {
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    padding: '4px 0 9px',
    '&:not(:focus)': {
      textOverflow: 'ellipsis',
    },
  },
  cardActions: {
    justifyContent: 'space-between',
  },
  actionButton: {
    minWidth: 'unset',
    letterSpacing: '1px',
  },
  deleteIcon: {
    marginTop: 4,
    marginRight: -5,
    cursor: 'pointer',
    marginLeft: '2px !important',
  },
}));

/**
 * Form for editing route point
 * @param {Object} $
 * @param {String} $.name - name of the route point
 * @param {String?|Boolean} $.address - address of the point (`undefined|null` if loading, string if loaded, `false` if failed)
 * @param {Boolean} $.locked - if action buttons (including "Close" are disabled) (`false` by default)
 * @param {Boolean} $.allowSave - if there are no inner errors, but nothing changes, enables 'Edit'/'Add' button
 * @param {Function?} $.onAdded - calls when point was added
 * @param {Function?} $.onCancelled - calls when close button pressed
 * @param {Function?} $.onRemoved - calls when remove button pressed
 * @param {Function?} $.onAddressRequested - address was requested
 * @param {Function?} $.onToEventRequested - redirect to event editing page was requested, if not passed - button will not shown
 */
export default function RoutePointEditor({
  name,
  address,
  locked = false,
  onToEventRequested,
  allowSave = false,
  onAdded = () => {},
  onCancelled = () => {},
  onRemoved = () => {},
  onAddressRequested = () => {},
  intl,
  setIsPopupOpen,
  isPopupOpen,
}) {
  const { formatMessage } = intl;

  /**
   * State object for the name input field
   * @type {Object}
   * @prop {String} value - point name in the input field
   */
  const [nameField, updateName] = useState({ value: name });

  /**
   * Id of the error message from `messages`
   * @type {String}
   */
  const [error, setError] = useState(null);

  /**
   * Checking if point description was changed
   */
  const initVals = { name };
  const currentVals = { name: nameField.value };
  const wasChanged = !isEqual(initVals, currentVals);

  const isAddEditDisabled = (!allowSave && !wasChanged) || locked;

  /**
   * Sets selected field value by given `Event` data
   * @param {Event} event - `changed` DOM event
   * @param {String} field - field name (`"desc"` or `name`)
   */
  const setValue = (event, field) => {
    const updaters = {
      name: [nameField, updateName],
    };

    updaters[field][1]({ ...updaters[field][0], value: event.target.value });
  };

  /**
   * "Add" button was clicked
   */
  const handleAdd = () => {
    const name = nameField.value.trim();
    if (name !== nameField.value) {
      setValue({ target: { value: name } }, 'name');
    }

    if (!name) {
      setError('nameError');
      return;
    }
    if (!address) {
      setError('addressError');
      return;
    }

    onAdded(name, null);
  };

  const handleOpenDeletePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    onRemoved();
  };

  const styles = useStyles();

  /**
   * Address was loaded:
   * - resetting address error
   */
  useEffect(() => {
    if (address && error == 'addressError') {
      setError(null);
    }
  }, [address]);

  return (
    <ThemeProvider theme={composeMaterialTheme()}>
      <Card className={styles.root}>
        <CardContent className={styles.header}>
          <div className={`${styles.headerText} grow`}>
            <Input
              autoFocus
              fullWidth
              classes={{ input: styles.name }}
              disabled={locked}
              value={nameField.value ? nameField.value : ''}
              placeholder={formatMessage(messages.namePlaceholder)}
              inputProps={{ maxLength: EVENT_MAX_TITLE_LENGTH }}
              onChange={(e) => {
                setValue(e, 'name');
                setError(null);
              }}
            />
            <RoutePointEditorSubtitle
              address={address}
              error={error ? messages[error] : null}
              intl={intl}
              onRequested={onAddressRequested}
            />
          </div>
          <DeleteIcon
            color="disabled"
            className={styles.deleteIcon}
            onClick={handleOpenDeletePopup}
          />
        </CardContent>
        <CardActions className={styles.cardActions}>
          <Button
            className={styles.actionButton}
            size="small"
            onClick={onCancelled}
          >
            {isAddEditDisabled
              ? formatMessage(messages.actionClose)
              : formatMessage(messages.actionCancel)}
          </Button>
          {onToEventRequested ? (
            <Button
              className={styles.actionButton}
              color="primary"
              size="small"
              onClick={onToEventRequested}
            >
              {formatMessage(messages.actionToEvent)}
            </Button>
          ) : null}
          <Button
            disabled={isAddEditDisabled}
            className={styles.actionButton}
            variant={isAddEditDisabled ? 'outlined' : 'contained'}
            color={error ? 'secondary' : 'primary'}
            size="small"
            onClick={handleAdd}
          >
            {formatMessage(messages.actionEdit)}
          </Button>
        </CardActions>
      </Card>
    </ThemeProvider>
  );
}
