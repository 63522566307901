import React from 'react';
import grey from '@material-ui/core/colors/grey';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { leftRightCellTextAlign } from '../../Table';
import { Loader } from '../../Loader';
import Pagination from '../../Pagination';
// eslint-disable-next-line import/no-cycle
import ProfilePartnerPurchases from '../../ProfilePartner/ProfilePartnerPurchases';

const useStyles = makeStyles((theme) => ({
  statsContainer: {
    height: 'fit-content',
    marginBottom: 40,
    '& td:first-child': {
      position: 'sticky',
      left: '0',
      background: theme.palette.background.main,
    },
    ...leftRightCellTextAlign,
  },
  availableSummaryPlate: {
    color: grey[500],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 45,
  },
  bottomBlock: {
    position: 'fixed',
    bottom: 0,
    width: 'calc(100% - 355px)',
    paddingRight: 95,
    backgroundColor: 'white',
    [theme.breakpoints.down(620)]: {
      width: '100%',
    },
  },
  noToursSold: {
    textAlign: 'center',
    marginTop: 16,
  },
  pagination: {
    marginTop: '16px',
  },
}));

const ReportSales = ({
  paymentStats,
  currencyCode,
  currencySymbol,
  isPartner,
  isFetching,
  total,
  showNetworkError = () => {},
  fetchPayments = () => {},
}) => {
  const classes = useStyles();
  const { current = 1, pages = 10, count = 0, results: payments } =
    paymentStats || {};

  if (paymentStats == null || !currencyCode)
    return (
      <Loader active className="Loader__with-container-top">
        <FormattedMessage
          id="ProfilePartner.downloadPaymentStats"
          defaultMessage="Loading sales"
        />
      </Loader>
    );
  else if (payments && payments.length === 0)
    return (
      <Typography variant="body1" className={classes.noToursSold}>
        <FormattedMessage
          id="ProfileReports.noToursSold"
          defaultMessage="No tours sold"
        />
      </Typography>
    );
  return (
    <React.Fragment>
      <div className={classes.statsContainer}>
        <ProfilePartnerPurchases
          currency={currencySymbol}
          currencyCode={currencyCode}
          isPartner={isPartner}
          fullPaymentsAmount={count}
          data={payments}
          onExportError={showNetworkError}
        />
      </div>
      <div className={classes.bottomBlock}>
        <Pagination
          className={classes.pagination}
          currentPage={current}
          isLoading={isFetching}
          pages={pages}
          onChange={(page) => fetchPayments({ page })}
        />
        <div className={classes.availableSummaryPlate}>
          <Typography variant="body2">
            <FormattedMessage
              id="ReportSales.total"
              defaultMessage="Total for all time"
            />
          </Typography>
          <Typography variant="body2">
            {total}
            &nbsp;
            {currencySymbol}
          </Typography>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReportSales;
