/**
 * Checks if passed `mediaId` object has all obligatory props
 * @param {MediaId} mediaId
 * @returns {Boolean}
 */
export default function checkMediaId(mediaId) {
  return (
    mediaId &&
    typeof mediaId === 'object' &&
    typeof mediaId.arrayId === 'string' &&
    typeof mediaId.index === 'number'
  );
}
