import { defineMessages } from 'react-intl';

const widgetStatsHeadersMessages = defineMessages({
  widgetLabels: {
    id: 'WidgetStats.widgetLabels',
    defaultMessage: 'Label',
  },
  widgetShows: {
    id: 'WidgetStats.widgetVisits',
    defaultMessage: 'Visits',
  },
  widgetViews: {
    id: 'WidgetStats.widgetViews',
    defaultMessage: 'Views',
  },
  widgetClicks: {
    id: 'WidgetStats.widgetClicks',
    defaultMessage: 'Clicks',
  },
  widgetPayments: {
    id: 'WidgetStats.widgetPayments',
    defaultMessage: 'Payments',
  },
  widgetPaid: {
    id: 'WidgetStats.widgetPaid',
    defaultMessage: 'Paid',
  },
  widgetRewards: {
    id: 'WidgetStats.widgetRewards',
    defaultMessage: 'Rewards',
  },
});

const widgetStatsHeadersHelpMessages = defineMessages({
  widgetLabels: {
    id: 'WidgetStats.widgetLabelsHelpText',
    defaultMessage: 'Unique widget labels',
  },
  widgetShows: {
    id: 'WidgetStats.widgetVisitsHelpText',
    defaultMessage: 'Visits to pages with widgets',
  },
  widgetViews: {
    id: 'WidgetStats.widgetViewsHelpText',
    defaultMessage: 'Users who saw the widgets',
  },
  widgetClicks: {
    id: 'WidgetStats.widgetClicksHelpText',
    defaultMessage: 'Widget clicks',
  },
  widgetPayments: {
    id: 'WidgetStats.widgetPaymentsHelpText',
    defaultMessage: 'Total number of payments (paid and unpaid)',
  },
  widgetPaid: {
    id: 'WidgetStats.widgetPaidHelpText',
    defaultMessage: 'Paid tours',
  },
  widgetRewards: {
    id: 'WidgetStats.widgetRewardsHelpText',
    defaultMessage: 'Rewards from paid tours',
  },
});

export const WIDGET_STATS_HEADERS = [
  [
    'name',
    widgetStatsHeadersMessages.widgetLabels,
    widgetStatsHeadersHelpMessages.widgetLabels,
  ],
  [
    'shows',
    widgetStatsHeadersMessages.widgetShows,
    widgetStatsHeadersHelpMessages.widgetShows,
  ],
  [
    'views',
    widgetStatsHeadersMessages.widgetViews,
    widgetStatsHeadersHelpMessages.widgetViews,
  ],
  [
    'clicks',
    widgetStatsHeadersMessages.widgetClicks,
    widgetStatsHeadersHelpMessages.widgetClicks,
  ],
  [
    'payments',
    widgetStatsHeadersMessages.widgetPayments,
    widgetStatsHeadersHelpMessages.widgetPayments,
  ],
  [
    'paid',
    widgetStatsHeadersMessages.widgetPaid,
    widgetStatsHeadersHelpMessages.widgetPaid,
  ],
  [
    'rewards',
    widgetStatsHeadersMessages.widgetRewards,
    widgetStatsHeadersHelpMessages.widgetRewards,
  ],
];
