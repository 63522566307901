import React from 'react';
import { FormattedMessage } from 'react-intl';
import TutorialCustomTooltip from '../../components/TutorialCustomTooltip';
import { Workspace } from './TutorialsName';
import defaultStepProps from './defaultStepProps';

const WorkspaceTutorial = (props) => {
  return {
    name: Workspace,
    run: props.run,
    steps: [
      {
        title: (
          <FormattedMessage
            id="Tutorials.Workspace.Step1.title"
            defaultMessage="The tour's name"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.Workspace.Step1.text"
            defaultMessage="Let's continue our acquaintance with the constructor! The name of the new tour is displayed here. Try to change it and enter the text."
          />
        ),
        target: '.QuestTitle__input',
        placement: 'bottom-start',
        ...defaultStepProps,
      },
      {
        title: (
          <FormattedMessage
            id="Tutorials.Workspace.Step2.title"
            defaultMessage="Greeting"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.Workspace.Step2.text"
            defaultMessage="The name of the event is displayed here. Replace the text with any other and go to the next stage of training."
          />
        ),
        target: '.EventName__name',
        placement: 'bottom',
        ...defaultStepProps,
      },
      {
        title: (
          <FormattedMessage
            id="Tutorials.Workspace.Step3.title"
            defaultMessage="What is a tour event"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.Workspace.Step3.text"
            defaultMessage="We've just changed the name of the event. An event is like a small chapter or stage of your tour. Each event has its own name."
          />
        ),
        target: '.EventName__name',
        placement: 'bottom',
        ...defaultStepProps,
      },
      {
        title: (
          <FormattedMessage
            id="Tutorials.Workspace.Step4.title"
            defaultMessage="Demonstration screen"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.Workspace.Step4.text"
            defaultMessage="Take a look, the new name is displayed on the demo screen. Thanks to it you can assess the future look of your tour in the application."
          />
        ),
        target: '.Phone__device',
        placement: 'left',
        ...defaultStepProps,
      },
      {
        title: (
          <FormattedMessage
            id="Tutorials.Workspace.Step5.title"
            defaultMessage="Contents of the tour"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.Workspace.Step5.text"
            defaultMessage="To the left of the workspace, as in the contents of the book, the events of your for navigation are displayed. How to design the events of the will be described in the following lesson."
          />
        ),
        target: '.nav',
        placement: 'right',
        ...defaultStepProps,
        styles: {
          floater: {
            floater: {
              zIndex: 2700,
            },
          },
        },
        spotlightPadding: 0,
        tooltipComponent: (
          <TutorialCustomTooltip nextTutorialAnchor="fillingevent" />
        ),
      },
    ],
  };
};

export default WorkspaceTutorial;
