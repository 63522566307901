import { isGeoOrTimeAndChoiceSetRule } from './rules/isGeoOrTimeAndChoiceSet';
import { timeInPastRule } from './rules/timeInPast';
import { isEventContentFullRule } from './rules/isEventContentFull';

const validationRulesArray = [
  isGeoOrTimeAndChoiceSetRule,
  timeInPastRule,
  isEventContentFullRule,
];

const getAllErrors = (props) => {
  const { event: currentEvent, next: nextEvent } = props;
  const errors = validationRulesArray
    .map(({ title, anchor, rule }) => {
      if (rule(currentEvent, nextEvent)) {
        return false;
      }
      return { title, anchor };
    })
    .filter(Boolean);
  return errors;
};

export const validate = (props) => {
  const errors = getAllErrors(props);
  return { isEventValid: errors.length === 0, eventErrors: errors };
};
