import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { fetchRawRoutePath } from './fetchRawRoutePath';

/**
 * Gets normalized route path from MapBox API
 * Normalization is adding input `coords` points into resulted array on suitable positions
 * @param {Array[LeafletJS::LatLng]} coords - input coords passed to MapBox destination API
 * @param {Object} rawRoute - MapBox destination API answer
 * @param {Boolean} shouldNormalize - will add original (input) points to approximated path from MapBox API
 * @returns {Array[LeafletJS::LatLng]|Null} - path (objects with `lat` and `lng` props) between passed route points, including them to draw polyline, `null` if error ocurred
 */
export default function getRoutePath(
  coords = [],
  rawRoute,
  shouldNormalize = true
) {
  const path = get(rawRoute, 'routes.0.geometry.coordinates', []).map((p) => ({
    lng: p[0],
    lat: p[1],
  }));
  const waypoints = get(rawRoute, 'waypoints', []).map(({ location: p }) => ({
    lng: p[0],
    lat: p[1],
  }));

  if (!path.length || waypoints.length !== coords.length) {
    return null;
  }

  /**
   * Including into `path` input points for the mapbox request,
   * cause in `path` points are bound to objects on the map and has offsets from original `coords`
   */
  if (shouldNormalize) {
    let lastAddedIndex = 0;
    waypoints.forEach((point, i) => {
      const insertIndex = path.findIndex(
        (pathPoint, j) => lastAddedIndex <= j && isEqual(point, pathPoint)
      );

      if (insertIndex === 0) {
        path.splice(insertIndex, 0, coords[i]);
        lastAddedIndex = insertIndex + 2;
      } else if (insertIndex === waypoints.length - 1) {
        path.splice(insertIndex, 0, coords[i]);
        lastAddedIndex = insertIndex + 2;
      } else if (insertIndex > -1) {
        path.splice(insertIndex, 0, point, coords[i]);
        lastAddedIndex = insertIndex + 3;
      }
    });
  }

  return path;
}

/**
 * Constructs path via roads between `coords` point coordinates
 * Uses MapBox API
 * @param {Array[LeafletJS::LatLng]} coords - array with coords of waypoints (`lat` and `lng` props)
 * @param {String} method - target way of moving for path constructing (`"traffic"`, `"driving"`, `"walking"` or `"cycling"`)
 * @param {String} token - MapBox API access token
 * @returns {Array[LeafletJS::LatLng]|Null} - path (objects with `lat` and `lng` props) between passed route points, including them to draw polyline, `null` if error ocurred
 */
export async function fetchRoutePath(coords = [], method, token) {
  const rawData = await fetchRawRoutePath(coords, method, token);
  return getRoutePath(coords, rawData);
}
