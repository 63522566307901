import * as types from '../constants/ActionTypes';

export function requestCollections(payload) {
  return {
    type: types.REQUEST_COLLECTIONS,
    city: payload.city,
  };
}

export function fetchWidgetStats(payload) {
  return {
    type: types.FETCH_WIDGET_STATS,
    ...payload,
  };
}
