/**
 * Finds event in `quests[]::events[]` store
 * @param {Array[Object]} route - events in the `quests[]::events[]` store
 * @param {String|Function} selector - id of the event, or functional parameter for `find` methon on `events` array
 * @param {Boolean} indexRequired - if position in array should be returned
 * @returns {Object|Number}
 */
export default function findEvent(events, selector, indexRequired) {
  if (!(events instanceof Array)) return indexRequired ? -1 : undefined;
  return events[indexRequired ? 'findIndex' : 'find']((event) =>
    typeof selector === 'function' ? selector(event) : event.id === selector
  );
}
