import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../Button';
import './QuickRangeSelection.css';

class QuickRangeSelection extends Component {
  isCurrentlySelected(date) {
    const { range } = this.props;

    if (!range) {
      return false;
    }

    return date.isSame(range);
  }

  setRange = (range) => {
    this.props.onSelect(range);
  };

  renderSelections() {
    return this.props.dates.map((date, i) => {
      const { label, range } = date;
      const isSelected = this.isCurrentlySelected(range);

      return (
        <Button
          key={i}
          className={classNames(
            'QuickRangeSelection__button',
            isSelected && 'QuickRangeSelection__button--selected'
          )}
          theme="main-light"
          disabled={isSelected}
          onClick={this.setRange.bind(this, range)}
        >
          {label}
        </Button>
      );
    });
  }

  render() {
    return <Fragment>{this.renderSelections()}</Fragment>;
  }
}

QuickRangeSelection.propTypes = {
  dates: PropTypes.array.isRequired,
  range: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
};
export default QuickRangeSelection;
