import has from 'lodash/has';
import {
  takeLatest,
  takeEvery,
  put,
  call,
  all,
  select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as types from '../constants/ActionTypes';
import Api from '../functions/Api';
import providers from '../functions/payment';
import { PAYMENT_PROVIDER } from '../constants/branding';
import { smallGuid } from '../functions/id/guid';
import formatAttractionForRequest, {
  filterAndFormatAttractions,
} from '../functions/attractions/formatAttractionForRequest';
import replaceOrAdd from '../functions/array/replaceOrAdd';
import { triggerUpdateQuestData } from './quests';

/**
 * Methods for API for different operations with publishing
 * @type {Object}
 */
const API_METHODS = {
  add: 'post',
  edit: 'put',
  remove: 'delete',
};

/**
 * Methods for API for different operations with publishing with `patch` method
 * @type {Object}
 */
const API_METHODS_WITH_PATCH = {
  ...API_METHODS,
  edit: 'patch',
};

export function* fetchPlans(action) {
  const { id } = action;
  try {
    const { data: plans } = yield call(Api.get, `plans/${id}/`);
    yield put({
      type: types.RECEIVE_PLANS,
      plans: plans.map((plan) => ({
        ...plan,
        price: parseFloat(plan.price),
      })),
    });
  } catch (error) {
    yield put({
      type: types.NOT_RECEIVE_PLANS,
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* checkout(action) {
  let { data } = action;
  const { additional } = action;

  if (!has(providers, PAYMENT_PROVIDER)) {
    yield put({
      type: types.PAYMENT_FAILURE,
      error: 'No such provider',
    });
  }

  try {
    const locale = yield select((state) => state.user.locale);
    if (PAYMENT_PROVIDER === 'yandex') {
      data = {
        ...data,
        cps_email: data.cpsEmail,
        ym_merchant_receipt: data.ymMerchantReceipt,
      };
    }
    yield call(providers[PAYMENT_PROVIDER].checkout, data, additional, locale);
  } catch (error) {
    yield put({
      type: types.PAYMENT_FAILURE,
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* publishProduct({ idProduct }) {
  try {
    const response = yield call(Api.post, `v2/products/${idProduct}/publish/`);

    if (response) {
      yield put({
        type: types.PUBLISH_PRODUCT_SUCCESS,
      });
      const currentQuestId = yield select(
        (state) => state.quests.currentQuestId
      );
      yield put(push(`/quest/${currentQuestId}/publish`));
      yield put({
        type: types.SYNC_QUEST_STAT,
        questId: currentQuestId,
        status: 'waiting_for_moderation',
      });
    }
  } catch (error) {
    yield put({
      type: types.PUBLISH_PRODUCT_FAILURE,
      errors: [error],
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* sendPublishData(action) {
  try {
    const { data } = yield call(
      Api.put,
      `v3/products/${action.id}/?expand=attractions,purchases_count&currency=DEF`,
      {
        data: {
          id: action.id,
          ...action.data,
        },
        isDecamelize: false,
      }
    );

    if (data && data.id) {
      const questId = yield select((state) => state.quests.currentQuestId);
      const quests = yield select((state) => state.quests.quests);

      yield put({
        type: types.SEND_PUBLISH_DATA_SUCCESS,
        data,
      });
      const generatedByAi = quests[0].generatedByAi;
      const originalTour = quests[0].originalTour;
      if (generatedByAi === true && originalTour === null) {
        yield put(triggerUpdateQuestData(questId));
      }

      const dataForListing = {
        title: data.title,
        eventsCount: data.tour.eventsCount,
        lastModified: data.lastModified,
        reviewsCount: data.reviewsCount,
        purchasesCount: data.purchasesCount,
        status: data.status,
      };

      yield put({
        type: types.UPDATE_QUEST_STAT,
        questId: Number(questId),
        data: dataForListing,
      });
      yield put({
        type: types.FETCH_QUEST,
        id: questId,
        isForSync: true,
      });
    }
  } catch (error) {
    yield put({
      type: types.SEND_PUBLISH_DATA_FAILURE,
      errors: [error],
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* postListedData(action) {
  try {
    const { data } = yield call(
      Api.post,
      `v2/products/${action.idProduct}/${action.endpoint}/`,
      {
        data: {
          ...action.data,
        },
        isDecamelize: false,
      }
    );

    if (data && data.id && has(data, 'text')) {
      yield put({
        type: types.POST_LISTED_DATA_SUCCESS,
        data,
        attribute: action.endpoint,
      });
    }

    if (data && data.id && has(data, 'price')) {
      yield put({
        type: types.POST_LISTED_PRICE_DATA_SUCCESS,
        data,
        attribute: 'price',
      });
    }
  } catch (error) {
    yield put({
      type: types.POST_LISTED_DATA_FAILURE,
      errors: [error],
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* sendListedData(action) {
  try {
    const { data } = yield call(
      Api.put,
      `v2/products/${action.idProduct}/${action.endpoint}/${action.idElement}/`,
      {
        data: {
          ...action.data,
        },
      }
    );
    const questId = yield select((state) => state.quests.currentQuestId);
    const quests = yield select((state) => state.quests.quests);
    const generatedByAi = quests[0].generatedByAi;

    if (data && data.id && has(data, 'text')) {
      yield put({
        type: types.SEND_LISTED_DATA_SUCCESS,
        data,
        attribute: action.endpoint,
      });
      if (generatedByAi === true) {
        yield put(triggerUpdateQuestData(questId));
      }
    }

    if (data && has(data, 'cover')) {
      yield put({
        type: types.SEND_LISTED_IMAGES_DATA_SUCCESS,
        data,
        attribute: 'images',
      });
      const questId = yield select((state) => state.quests.currentQuestId);
      yield put(triggerUpdateQuestData(questId));
      yield put({
        type: types.FETCH_QUEST,
        id: questId,
        isForSync: true,
      });
    }

    if (data && has(data, 'price')) {
      yield put({
        type: types.SEND_LISTED_PRICE_DATA_SUCCESS,
        data,
        attribute: 'price',
      });
    }
  } catch (error) {
    yield put({
      type: types.SEND_LISTED_DATA_FAILURE,
      errors: [error],
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* deleteListedData(action) {
  try {
    const response = yield call(
      Api.delete,
      `v2/products/${action.idProduct}/${action.endpoint}/${action.idElement}/`
    );

    if (response && action.endpoint === 'prices') {
      yield put({
        type: types.DELETE_LISTED_DATA_SUCCESS,
        id: action.idElement,
        attribute: 'priceLevels',
      });
    }

    if (
      response &&
      action.endpoint !== 'images' &&
      action.endpoint !== 'prices'
    ) {
      yield put({
        type: types.DELETE_LISTED_DATA_SUCCESS,
        id: action.idElement,
        attribute: action.endpoint,
      });
    }

    if (response && action.endpoint === 'images') {
      const isCover = yield select(
        (state) =>
          state.publishing.receivedPublishData.images.find(
            (x) => x.id === action.idElement
          ).cover
      );
      yield put({
        type: types.DELETE_IMAGE_SUCCESS,
        id: action.idElement,
        attribute: action.endpoint,
      });

      if (isCover) {
        yield put({
          type: types.REQUEST_PRODUCT,
          id: action.idProduct,
        });
        const questId = yield select((state) => state.quests.currentQuestId);

        yield put({
          type: types.FETCH_QUEST,
          id: questId,
          isForSync: true,
        });
      }
    }
  } catch (error) {
    yield put({
      type: types.DELETE_LISTED_DATA_FAILURE,
      errors: [error],
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* deleteMultipleImages(action) {
  try {
    const { imageIds, idProduct, endpoint } = action;

    for (const idElement of imageIds) {
      yield call(deleteListedData, {
        idProduct,
        idElement,
        endpoint,
        type: types.DELETE_LISTED_DATA,
      });

      const isCover = yield select(
        (state) =>
          state.publishing.receivedPublishData.images.find(
            (x) => x.id === idElement
          )?.cover
      );

      if (isCover) {
        yield put({
          type: types.REQUEST_PRODUCT,
          id: idProduct,
        });

        const questId = yield select((state) => state.quests.currentQuestId);

        yield put({
          type: types.FETCH_QUEST,
          id: questId,
          isForSync: true,
        });
      }
    }
  } catch (error) {
    console.error('Error deleting multiple images:', error);
    yield put({
      type: types.DELETE_LISTED_DATA_FAILURE,
      errors: [error],
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* fetchProduct(action) {
  try {
    const { data } = yield call(
      Api.get,
      `v3/products/${action.id}/?expand=inclusions,exclusions,highlights,price,images,attractions&currency=DEF`
    );

    yield put({
      type: types.RECEIVE_PRODUCT,
      data,
    });
  } catch (error) {
    yield put({
      type: types.NOT_RECEIVE_PRODUCT,
      errors: [error],
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

/**
 * Sends new order of product images to server
 * @param {Object} action
 * @param {Number} action.productId - id of the product to sync images order
 */
export function* syncProductImagesOrder({ productId }) {
  const imageIds = yield select((state) =>
    state.publishing.receivedPublishData.images.map((img) => img.id)
  );

  try {
    yield call(Api.patch, `v2/products/${productId}/images/`, {
      data: { order: imageIds },
    });
  } catch (error) {
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

/**
 * Reorders images locally and syncs their order with server
 * @param {Object} action
 * @param {Number} action.productId - id of the product which images order should be synced
 * @param {...Object} - will be sent to `REORDER_PRODUCT_IMAGES` action
 */
export function* reorderProductImages({ productId, ...action }) {
  yield put({ ...action, type: types.REORDER_PRODUCT_IMAGES });

  if (!action.isUpload) {
    yield call(syncProductImagesOrder, { productId });
  }
}

/**
 * Add, remove or edit attractions by fetching `v3/attractions/` and `v3/products/`
 * @param {Object} data - attraction data -  can include `id`, `entrance` and other props
 * @param {String} purpose - which type of API to use (`"add"`, `"edit"` or `"remove"`)
 * @param {Number} idAttraction - attraction id
 */
export function* sendAttractionsData({ data, purpose }) {
  const method = API_METHODS_WITH_PATCH[purpose];

  try {
    const { receivedPublishData: product, attractions } = yield select(
      (state) => state.publishing
    );

    const attraction =
      purpose === 'add'
        ? yield call(Api[method], `v3/attractions/`, { data })
        : data;

    const isIncluded = attractions.some(({ id }) => id === attraction.id);

    if (
      (purpose === 'add' && attraction.id && !isIncluded) ||
      purpose === 'edit'
    ) {
      yield put({
        type: types.SEND_PUBLISH_DATA,
        data: {
          attractions: replaceOrAdd(
            attractions,
            purpose === 'edit' ? ({ id }) => id === attraction.id : null,
            formatAttractionForRequest({
              ...attraction,
              entrance: data.entrance,
            }),
            formatAttractionForRequest
          ),
        },
        id: product.id,
      });

      yield put({ type: types.SEND_ATTRACTIONS_DATA_SUCCESS });
    }

    if (purpose === 'remove') {
      yield put({
        type: types.SEND_PUBLISH_DATA,
        data: {
          attractions: filterAndFormatAttractions(attractions, attraction.id),
        },
        id: product.id,
      });

      yield put({
        type: types.DELETE_ATTRACTIONS_DATA_SUCCESS,
        id: attraction.id,
      });
    }
  } catch (error) {
    yield put({
      type: types.SEND_ATTRACTIONS_DATA_FAILURE,
      errors: [error],
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

/**
 * Add, remove or edit tickets
 * @param {Object} data - ticket data
 * @param {String} purpose - which type of API to use (`"add"`, `"edit"` or `"remove"`)
 * @param {Number} idAttraction - attraction id
 * @param {Number} idTicket - ticket id
 */
export function* sendTicketsData({ data, purpose, idAttraction, idTicket }) {
  const method = API_METHODS[purpose];

  try {
    let response = {};
    if (method === 'post')
      response = yield call(Api[method], `v2/tickets/`, {
        data: {
          ...data,
          attractions: [idAttraction],
        },
        isDecamelize: false,
      });
    else
      response = yield call(Api[method], `v2/tickets/${idTicket}/`, {
        data: {
          ...data,
        },
        isDecamelize: false,
      });

    if (purpose === 'add' && response.data && response.data.id) {
      const responseAttractions = yield call(
        Api.put,
        `v3/attractions/${idAttraction}/`,
        { data: { fee: true } }
      );

      if (responseAttractions.data && responseAttractions.data.id) {
        yield put({
          type: types.SEND_ATTRACTIONS_DATA_SUCCESS,
          data: responseAttractions.data,
        });

        yield put({
          type: types.SEND_TICKETS_DATA_SUCCESS,
          ticketId: response.data.id,
          attractionId: idAttraction,
          data: response.data,
        });

        yield put({
          type: types.SEND_TICKETS_CATEGORIES_DATA,
          purpose: 'add',
          data: { label: data.pricingLabel },
          idTicket: response.data.id,
        });
      }
    }

    if (purpose === 'edit' && response.data && response.data.id) {
      yield put({
        type: types.SEND_TICKETS_DATA_SUCCESS,
        ticketId: response.data.id,
        attractionId: idAttraction,
        data: response.data,
      });
    }

    if (purpose === 'remove' && response) {
      yield put({
        type: types.DELETE_TICKETS_DATA_SUCCESS,
        ticketId: idTicket,
      });
    }
  } catch (error) {
    yield put({
      type: types.SEND_TICKETS_DATA_FAILURE,
      errors: [error],
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* sendTicketCategoriesData({
  data,
  purpose,
  idTicket,
  idCategory = null,
}) {
  const method = API_METHODS[purpose];
  const pricingCategoryId = smallGuid();

  try {
    let response = {};
    if (method === 'post')
      response = yield call(Api[method], `v2/tickets/${idTicket}/categories/`, {
        data: {
          ...data,
          pricingCategoryId,
        },
        isDecamelize: false,
      });
    else
      response = yield call(
        Api[method],
        `v2/tickets/${idTicket}/categories/${idCategory}/`,
        {
          data: {
            ...data,
          },
          isDecamelize: false,
        }
      );

    if (purpose === 'add' && response.data && response.data.id) {
      yield put({
        type: types.SEND_TICKETS_CATEGORIES_DATA_SUCCESS,
        ticketId: idTicket,
        data: response.data,
      });
    }

    if (purpose === 'edit' && response.data && response.data.id) {
      yield put({
        type: types.SEND_TICKETS_CATEGORIES_DATA_SUCCESS,
        ticketId: idTicket,
        data: response.data,
      });
    }

    if (purpose === 'remove' && response) {
      yield put({
        type: types.DELETE_TICKETS_CATEGORIES_DATA_SUCCESS,
        idTicket,
        idCategory,
      });
    }
  } catch (error) {
    yield put({
      type: types.SEND_TICKETS_CATEGORIES_DATA_FAILURE,
      errors: [error],
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

/**
 * Removing tickets categories and tickets from attraction
 * @param {Number} idAttraction - attraction id
 * @param {Number} idTicket - ticket id
 * @param {boolean} withAttraction - ticket id
 */
export function* deleteTicket({ idAttraction, idTicket, withAttraction }) {
  try {
    const attractions = yield select((state) => state.publishing.attractions);
    yield all(
      attractions
        .find((a) => a.id === idAttraction)
        .tickets.find((t) => t.id === idTicket)
        .pricingCategories.map((pc) => pc.id)
        .map((value) =>
          call(sendTicketCategoriesData, {
            type: types.SEND_TICKETS_CATEGORIES_DATA,
            data: {},
            purpose: 'remove',
            idTicket,
            idCategory: value,
          })
        )
    );

    const attractionsAfter = yield select(
      (state) => state.publishing.attractions
    );

    if (
      attractionsAfter
        .find((a) => a.id === idAttraction)
        .tickets.find((t) => t.id === idTicket).pricingCategories.length === 0
    )
      yield call(sendTicketsData, {
        type: types.SEND_TICKETS_DATA,
        data: {},
        purpose: 'remove',
        idAttraction,
        idTicket,
      });

    const attractionsCleared = yield select(
      (state) => state.publishing.attractions
    );

    if (
      withAttraction &&
      attractionsCleared.find((a) => a.id === idAttraction).tickets.length === 0
    )
      yield put({
        type: types.SEND_ATTRACTIONS_DATA,
        data: { id: idAttraction },
        purpose: 'remove',
      });
  } catch (error) {
    yield put({
      type: types.SEND_TICKETS_CATEGORIES_DATA_FAILURE,
      errors: [error],
    });
    yield put({
      type: types.SEND_TICKETS_DATA_FAILURE,
      errors: [error],
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* convertAudioGuides({ idProduct, nextCurrencyId }) {
  try {
    const currencies = yield select((state) => state.currencies.items);
    const priceLevels = yield select((state) => state.publishing.priceLevels);
    const defaultCurrency = yield select(
      (state) => state.publishing.receivedPublishData.defaultCurrency
    );

    const nextCurrencyRate = currencies.find(
      (currency) => currency.id === nextCurrencyId
    ).rate;

    yield all(
      priceLevels.map((priceLevel) =>
        call(sendListedData, {
          type: types.SEND_LISTED_DATA,
          data: {
            price: priceLevel.price * (nextCurrencyRate / defaultCurrency.rate),
          },
          idProduct,
          idElement: priceLevel.id,
          endpoint: 'prices',
        })
      )
    );
  } catch (error) {
    yield put({
      type: types.CONVERT_AUDIO_GUIDES_PRICES_FAILURE,
      errors: [error],
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export default function* watchPublishing() {
  yield takeLatest(types.REQUEST_PLANS, fetchPlans);
  yield takeEvery(types.RECEIVE_PAYMENT, checkout);
  yield takeLatest(types.PUBLISH_PRODUCT, publishProduct);
  yield takeEvery(types.SEND_PUBLISH_DATA, sendPublishData);
  yield takeEvery(types.POST_LISTED_DATA, postListedData);
  yield takeEvery(types.SEND_LISTED_DATA, sendListedData);
  yield takeEvery(types.DELETE_LISTED_DATA, deleteListedData);
  yield takeEvery(types.DELETE_MULTIPLE_IMAGES, deleteMultipleImages);
  yield takeEvery(types.REQUEST_PRODUCT, fetchProduct);
  yield takeEvery(types.SEND_ATTRACTIONS_DATA, sendAttractionsData);
  yield takeEvery(types.SEND_TICKETS_DATA, sendTicketsData);
  yield takeEvery(types.SEND_TICKETS_CATEGORIES_DATA, sendTicketCategoriesData);
  yield takeEvery(types.DELETE_TICKET, deleteTicket);
  yield takeEvery(types.CONVERT_AUDIO_GUIDES_PRICES, convertAudioGuides);
  yield takeEvery(types.REORDER_PRODUCT_IMAGES_SYNC, reorderProductImages);
  yield takeLatest(types.SYNC_PRODUCT_IMAGES_ORDER, syncProductImagesOrder);
}
