import checkTriggers from '../checkTriggers';
import createValidationRule from './createValidationRule';
import { isGeoOrTimeAndChoiceSet } from './rulesName';

export const isGeoOrTimeAndChoiceSetRule = createValidationRule(
  isGeoOrTimeAndChoiceSet,
  'triggers',
  (currentEvent, nextEvent) => {
    if (nextEvent === null) {
      return true;
    }

    const {
      isGeoTriggerSet,
      isTimeTriggerSet,
      isChoiceSet,
      isARTriggerSet,
    } = checkTriggers(currentEvent, nextEvent);
    const isGeoTimeTriggersSet = [isGeoTriggerSet, isTimeTriggerSet].some(
      Boolean
    );

    return ![isGeoTimeTriggersSet, isChoiceSet, isARTriggerSet].every(Boolean);
  }
);
