/* eslint-disable no-prototype-builtins */
import React, { useState, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import './TourPrice.css';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Paper,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Select,
  FormControl,
  InputLabel,
  InputAdornment,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';

import TourContentFooterButtons from '../../TourContentFooter/TourContentFooterButtons';
import debounce from '../../../functions/debounce';
import PlacesAutocomplete from '../../PlacesAutocomplete';
import { getShortLabel } from '../../../functions/geocoding/googleProvider';
import { isStringPositiveNumber } from '../../../functions/validation/checkIsStringAreNumber';
import { formatToPrice } from '../../../functions/numbers';
import { round10 } from '../../../functions/round10';
import {
  ALL_CATEGORY_TICKET_LABELS,
  DEFAULT_PRICING_CATEGORY_ID,
} from '../../../constants/prices';
import {
  checkContainsTickets,
  getAllPricingCategories,
  getFinalCategories,
  getPriceBlockCategorySum,
  isAttractionsWithoutEntrance,
} from '../../../functions/prices';

/**
 * JSS styles for `TourPrice` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  textFieldSm: {
    maxWidth: 210,
    marginRight: 20,
  },
  textFieldSmNoMargin: {
    maxWidth: 190,
  },
  textFieldSmLast: {
    maxWidth: 190,
    marginRight: 20,
  },
  paper: {
    padding: 24,
    marginTop: 40,
    marginBottom: -15,
  },
  container: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
  },
  cardTitle: {
    marginBottom: 4,
  },
  helperFullness: {
    marginTop: 40,
    marginBottom: -25,
  },
  sightTitle: {
    fontSize: 20,
    fontWeight: 500,
  },
  siteField: {
    maxWidth: 300,
    width: '90%',
    marginRight: 20,
  },
  formControlSwitch: {
    float: 'right',
    marginTop: -5,
  },
  deleteAttractionBtn: {
    float: 'right',
    marginTop: -3,
    marginRight: 20,
  },
  deleteTicketCategoryBtn: {
    marginTop: 15,
  },
  option: {
    width: 190,
    marginRight: 20,
  },
  categoriesTitle: {
    minWidth: 175,
    maxWidth: 175,
    display: 'inline-block',
    marginTop: 20,
    marginRight: 20,
  },
  span: {
    position: 'relative',
    top: 25,
    marginLeft: 10,
    marginRight: 10,
  },
  priceBlock: {
    marginTop: 10,
    display: 'inline-flex',
    flexWrap: 'wrap',
  },
  currencySelect: {
    width: 90,
  },
  optionsBtn: {
    marginTop: 20,
  },
  ticketMain: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 20,
  },
  ticketSub: {
    display: 'flex',
    marginTop: 20,
  },
  placesAutocomplete: {
    width: '80%',
    maxWidth: 620,
    marginBottom: 10,
  },
  attractionsText: {
    marginTop: 15,
    marginBottom: 20,
  },
}));

const noOptionText = (
  <FormattedMessage
    id="StudioCreateProduct.tourPiceNotFound"
    defaultMessage="Nothing was found"
  />
);

const enterExactAddress = (
  <FormattedMessage
    id="StudioCreateProduct.tourPriceExactAddress"
    defaultMessage="Type the address"
  />
);

const deleteWord = (
  <FormattedMessage
    id="StudioCreateProduct.TourPriceDelete"
    defaultMessage="Delete"
  />
);

const currency = (
  <FormattedMessage
    id="StudioCreateProduct.TourPriceCurrency"
    defaultMessage="Currency"
  />
);

const negativePrice = (
  <FormattedMessage
    id="StudioCreateProduct.TourPriceFinalNotNegative"
    defaultMessage="The price should not be negative"
  />
);

const TourPrice = ({
  currentQuest: { products },
  currencies,
  currentQuestId,
  intl: { formatMessage },
  publishingActions,
  publishingActions: {
    sendTicketsData,
    sendTicketCategoriesData,
    sendAttractionsData,
    deleteTicket,
  },
  publishing: {
    receivedPublishData,
    priceLevels,
    receivedPublishData: { defaultCurrency },
    attractions,
  },
  checkFullness,
  location,
  ticketSites,
  setTicketSites,
  pricingCategoriesLabels,
  setPricingCategoriesLabels,
  prices,
  setPrices,
  finalPrices,
  setFinalPrices,
  uniquePricingCategoriesLabels,
}) => {
  /**
   * JSS styles for `TourPrice` element
   * @type {React::Hook}
   */
  const classes = useStyles();
  const [isSearchShow, setIsSearchShow] = useState(false);

  const allTickets = formatMessage({
    id: 'StudioCreateProduct.TourPriceAllTickets',
    defaultMessage: 'All tickets',
  });

  /**
   * Saving price for tickets category
   * @param {Number} value - audio guide price for ticket category
   * @param {String} label - ticket category label
   */
  const handleSaveLabeledPrice = (value, label) => {
    if (
      priceLevels.some(
        (pl) =>
          pl.label === label ||
          (priceLevels.length === 1 &&
            pl.pricingCategoryId === DEFAULT_PRICING_CATEGORY_ID)
      )
    ) {
      const labeledPrice =
        label === DEFAULT_PRICING_CATEGORY_ID
          ? priceLevels.find((pl) => pl.pricingCategoryId === label)
          : priceLevels.find((pl) => pl.label === label);
      publishingActions.sendListedData(
        products[0].id,
        labeledPrice.id,
        { price: value },
        'prices'
      );
    } else {
      const pricingCategoryId =
        label === DEFAULT_PRICING_CATEGORY_ID
          ? DEFAULT_PRICING_CATEGORY_ID
          : getAllPricingCategories(attractions).find(
              (pc) => pc.label === label
            ).pricingCategoryId;
      publishingActions.postListedData(
        products[0].id,
        {
          ...(label === DEFAULT_PRICING_CATEGORY_ID ? {} : { label }),
          price: value,
          pricingCategoryId,
        },
        'prices'
      );
    }
  };

  /**
   * Handle add, remove or edit ticket categories
   * @param {Object} value - ticket category data
   * @param {String} purpose - which type of API to use (`"add"`, `"edit"` or `"remove"`)
   * @param {Number} idTicket - ticket id
   * @param {Number} idCategory - ticket category id
   */
  const handleSendCategoriesData = (value, purpose, idTicket, idCategory) => {
    if (value.hasOwnProperty('totalRetailPrice'))
      if (
        Number(value.totalRetailPrice) ===
          getAllPricingCategories(attractions).find(
            (pc) => pc.id === idCategory
          ).totalRetailPrice ||
        Number(value.totalRetailPrice) < 0
      )
        return;
    if (
      value.hasOwnProperty('label') &&
      (value.label === '' ||
        value.label ===
          getAllPricingCategories(attractions).find(
            (pc) => pc.id === idCategory
          ).label)
    )
      return;
    sendTicketCategoriesData(value, purpose, idTicket, idCategory);
  };

  const firstKey = Object.keys(prices)[0];

  /**
   * Memoized and debounced wraps of
   * handleSendCategoriesData, handleSave,
   * sendTicketsData, handleSaveLabeledPrice
   */
  const debouncedHandleSendCategoriesData = useMemo(
    () => debounce(handleSendCategoriesData, 1000),
    [attractions]
  );
  const debouncedHandleSaveTicketsData = useMemo(
    () => debounce(sendTicketsData, 1000),
    [attractions]
  );
  const debouncedHandleSaveLabeledPrice = useMemo(
    () => debounce(handleSaveLabeledPrice, 1000),
    [attractions, priceLevels]
  );

  /**
   * Checking the filling of fields and returning what is not filled
   * @returns {Array[String]} - fields that are not filled
   */
  const handleFullnessText = () => {
    const entities = [];
    if (!checkFullness('price')) {
      entities.push(
        formatMessage({
          id: 'StudioCreateProduct.tourPricePrice',
          defaultMessage: 'price',
        })
      );
    }
    return entities;
  };

  const categoriesList = () => {
    const finalCategoriesList = getFinalCategories(
      uniquePricingCategoriesLabels
    );

    return finalCategoriesList.map((pcl) => {
      const priceBlockCategorySum = getPriceBlockCategorySum(
        defaultCurrency,
        pcl,
        attractions,
        uniquePricingCategoriesLabels,
        uniquePricingCategoriesLabels.some((upcl) =>
          ALL_CATEGORY_TICKET_LABELS.includes(upcl)
        ) && uniquePricingCategoriesLabels.length !== 1
      );

      return (
        <div key={pcl} className={classes.priceBlock}>
          <Typography className={classes.categoriesTitle}>{pcl}</Typography>
          <TextField
            disabled
            className={classes.textFieldSmNoMargin}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            value={priceBlockCategorySum}
            label={
              <FormattedMessage
                id="StudioCreateProduct.TourPriceSum"
                defaultMessage="Sum of all tickets:"
              />
            }
          />
          <span className={classes.span}>+</span>
          <TextField
            className={classes.textFieldSmNoMargin}
            variant="standard"
            label={
              <FormattedMessage
                id="StudioCreateProduct.TourPriceAudioGuidePrice"
                defaultMessage="Audio guide price:"
              />
            }
            error={location.highlights && prices[pcl] === undefined}
            helperText={prices[pcl] < 0 && negativePrice}
            value={prices[pcl] || ''}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              const newPrice = formatToPrice(e.target.value);
              if (isStringPositiveNumber(newPrice)) {
                setPrices({
                  ...prices,
                  [pcl]: newPrice,
                });
                if (newPrice >= 0)
                  debouncedHandleSaveLabeledPrice(newPrice, pcl);
              }
            }}
          />
          <span className={classes.span}>=</span>
          <TextField
            className={classes.textFieldSmLast}
            variant="standard"
            label={
              <FormattedMessage
                id="StudioCreateProduct.TourPriceFinalPrice"
                defaultMessage="Final price:"
              />
            }
            value={prices[pcl] >= 0 || prices[pcl] <= 0 ? finalPrices[pcl] : ''}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              const newPrice = formatToPrice(e.target.value);
              if (isStringPositiveNumber(newPrice)) {
                setFinalPrices({
                  ...finalPrices,
                  [pcl]: newPrice,
                });
                setPrices({
                  ...prices,
                  [pcl]: round10(newPrice - priceBlockCategorySum),
                });

                if (newPrice - priceBlockCategorySum >= 0)
                  debouncedHandleSaveLabeledPrice(
                    round10(newPrice - priceBlockCategorySum),
                    pcl
                  );
              }
            }}
          />
          <FormControl className={classes.currencySelect}>
            <InputLabel>
              <FormattedMessage
                id="StudioCreateProduct.TourPriceCurrency"
                defaultMessage="Currency"
              />
            </InputLabel>
            <Select
              value={defaultCurrency.id}
              MenuProps={{
                disableScrollLock: true,
              }}
              onChange={(e) => {
                publishingActions.convertAudioGuides(
                  receivedPublishData.id,
                  e.target.value
                );
                publishingActions.sendPublishData(receivedPublishData.id, {
                  currencyId: e.target.value,
                });
              }}
            >
              {currencies.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      );
    });
  };

  return (
    <section className="App__main TourPrice__main">
      <Container className={classes.container}>
        <Paper elevation={0} className={classes.paper}>
          <div
            className={
              isAttractionsWithoutEntrance(attractions)
                ? ''
                : 'TourPrice__borderline'
            }
          >
            <Typography className={classes.cardTitle} variant="h5">
              <FormattedMessage
                id="StudioCreateProduct.TourPrice"
                defaultMessage="Tour price and tickets"
              />
            </Typography>
            {isAttractionsWithoutEntrance(attractions) && (
              <Typography className={classes.attractionsText} variant="body1">
                <FormattedMessage
                  id="StudioCreateProduct.TourPriceAttractionsText"
                  defaultMessage="If this tour includes visits to attractions, please include them and the cost of tickets if they are paid. Paid tickets affect the final price of the tour."
                />
              </Typography>
            )}
          </div>
          {attractions.map(
            (a) =>
              a.entrance && (
                <div key={a.id} className="TourPrice__borderline">
                  <Button
                    className={classes.deleteAttractionBtn}
                    color="primary"
                    onClick={() => {
                      publishingActions.sendAttractionsData(
                        { id: a.id },
                        'remove'
                      );
                    }}
                  >
                    {deleteWord}
                  </Button>
                  {a.protected && (
                    <FormControlLabel
                      className={classes.formControlSwitch}
                      control={
                        <Switch disabled checked={a.fee} color="primary" />
                      }
                      label={
                        <FormattedMessage
                          id="StudioCreateProduct.TourPricePaidEntrance"
                          defaultMessage="Paid entrance"
                        />
                      }
                    />
                  )}
                  <Typography className={classes.sightTitle} variant="h6">
                    {a.name}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {a.address}
                  </Typography>
                  {a.fee &&
                    a.tickets.map((ticket) => (
                      <div key={ticket.id}>
                        <div className={classes.ticketMain}>
                          <FormControl className={classes.option}>
                            <InputLabel>
                              <FormattedMessage
                                id="StudioCreateProduct.TourPriceWhereBuyTickets"
                                defaultMessage="Where to buy tickets"
                              />
                            </InputLabel>
                            <Select
                              value={ticket.source || ''}
                              MenuProps={{ disableScrollLock: true }}
                              disabled={ticket.protected}
                              onChange={(e) =>
                                sendTicketsData(
                                  { source: e.target.value },
                                  'edit',
                                  a.id,
                                  ticket.id
                                )
                              }
                            >
                              <MenuItem value="ONLINE">
                                <FormattedMessage
                                  id="StudioCreateProduct.TourPriceOnSite"
                                  defaultMessage="Online"
                                />
                              </MenuItem>
                              <MenuItem value="ONSITE">
                                <FormattedMessage
                                  id="StudioCreateProduct.TourPriceInPlace"
                                  defaultMessage="On site"
                                />
                              </MenuItem>
                              {ticket.source === 'API' && (
                                <MenuItem value="API">API</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                          <TextField
                            className={classes.siteField}
                            disabled={
                              ticket.protected || ticket.source === 'ONSITE'
                            }
                            label={
                              <FormattedMessage
                                id="StudioCreateProduct.TourPriceSite"
                                defaultMessage="Specify website"
                              />
                            }
                            value={ticketSites[ticket.id] || ''}
                            onChange={(e) => {
                              setTicketSites({
                                ...ticketSites,
                                [ticket.id]: e.target.value,
                              });
                              debouncedHandleSaveTicketsData(
                                { checkoutUrl: e.target.value },
                                'edit',
                                a.id,
                                ticket.id
                              );
                            }}
                          />
                          <FormControl className={classes.currencySelect}>
                            <InputLabel>{currency}</InputLabel>
                            <Select
                              value={ticket.currency?.id || ''}
                              MenuProps={{ disableScrollLock: true }}
                              disabled={ticket.protected}
                              onChange={(e) => {
                                sendTicketsData(
                                  { currencyId: e.target.value },
                                  'edit',
                                  a.id,
                                  ticket.id
                                );
                              }}
                            >
                              {currencies.map((c) => (
                                <MenuItem key={c.id} value={c.id}>
                                  {c.code}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        {ticket.pricingCategories?.map((c) => (
                          <div key={c.id} className={classes.ticketSub}>
                            <Autocomplete
                              disableClearable
                              clearOnBlur
                              handleHomeEndKeys
                              freeSolo
                              disabled={ticket.protected}
                              className={classes.option}
                              value={pricingCategoriesLabels[c.id] || ''}
                              getOptionLabel={(x) => x}
                              options={[
                                allTickets,
                                formatMessage({
                                  id: 'StudioCreateProduct.TourPriceAdult',
                                  defaultMessage: 'Adult',
                                }),
                                formatMessage({
                                  id: 'StudioCreateProduct.TourPriceChildren',
                                  defaultMessage: 'Children',
                                }),
                                formatMessage({
                                  id: 'StudioCreateProduct.TourPriceSenior',
                                  defaultMessage: 'Senior',
                                }),
                                formatMessage({
                                  id: 'StudioCreateProduct.TourPriceStudent',
                                  defaultMessage: 'Student',
                                }),
                              ]}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={pricingCategoriesLabels[c.id] === ''}
                                  helperText={
                                    pricingCategoriesLabels[c.id] === '' && (
                                      <FormattedMessage
                                        id="StudioCreateProduct.shouldntBeEmpty"
                                        defaultMessage="Options"
                                      />
                                    )
                                  }
                                  label={
                                    <FormattedMessage
                                      id="StudioCreateProduct.TourPriceOptions"
                                      defaultMessage="Options"
                                    />
                                  }
                                  variant="standard"
                                />
                              )}
                              onInputChange={(_e, value) => {
                                setPricingCategoriesLabels({
                                  ...pricingCategoriesLabels,
                                  [c.id]: value,
                                });
                                debouncedHandleSendCategoriesData(
                                  { label: value },
                                  'edit',
                                  ticket.id,
                                  c.id
                                );
                              }}
                            />
                            <TextField
                              className={classes.textFieldSm}
                              variant="standard"
                              disabled={ticket.protected}
                              value={prices[c.pricingCategoryId] || 0}
                              error={
                                location.highlights &&
                                !checkFullness('price') &&
                                prices[c.pricingCategoryId] <= 0
                              }
                              helperText={
                                prices[c.pricingCategoryId] < 0 && negativePrice
                              }
                              label={
                                <FormattedMessage
                                  id="StudioCreateProduct.TourPriceCost"
                                  defaultMessage="Cost"
                                />
                              }
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => {
                                const newPrice = formatToPrice(e.target.value);
                                if (isStringPositiveNumber(newPrice)) {
                                  setPrices({
                                    ...prices,
                                    [c.pricingCategoryId]: newPrice,
                                  });
                                  debouncedHandleSendCategoriesData(
                                    { totalRetailPrice: newPrice },
                                    'edit',
                                    ticket.id,
                                    c.id
                                  );
                                }
                              }}
                            />
                            <Button
                              className={classes.deleteTicketCategoryBtn}
                              color="primary"
                              disabled={ticket.protected}
                              onClick={() =>
                                ticket.pricingCategories.length === 1
                                  ? deleteTicket(a.id, ticket.id, false)
                                  : sendTicketCategoriesData(
                                      {},
                                      'remove',
                                      ticket.id,
                                      c.id
                                    )
                              }
                            >
                              {deleteWord}
                            </Button>
                          </div>
                        ))}
                        <div className={classes.optionsBtn}>
                          <Button
                            disabled={ticket.protected}
                            color="primary"
                            onClick={() =>
                              sendTicketCategoriesData(
                                { label: allTickets },
                                'add',
                                ticket.id
                              )
                            }
                          >
                            <FormattedMessage
                              id="StudioCreateProduct.TourPriceAddOption"
                              defaultMessage="Add option"
                            />
                          </Button>
                          {ticket.pricingCategories.length > 1 && (
                            <Button
                              color="primary"
                              disabled={ticket.protected}
                              onClick={() => {
                                deleteTicket(a.id, ticket.id, false);
                              }}
                            >
                              <FormattedMessage
                                id="StudioCreateProduct.TourPriceDeleteAllOptions"
                                defaultMessage="Delete all options"
                              />
                            </Button>
                          )}
                          <Button
                            color="primary"
                            disabled={ticket.protected}
                            onClick={() => {
                              publishingActions.sendTicketsData({
                                purpose: 'remove',
                                idTicket: ticket.id,
                              });
                            }}
                          >
                            <FormattedMessage
                              id="StudioCreateProduct.TourPriceDeleteTicket"
                              defaultMessage="Delete ticket"
                            />
                          </Button>
                        </div>
                      </div>
                    ))}
                </div>
              )
          )}
          <div className="TourPrice__borderline">
            {isSearchShow ? (
              <React.Fragment>
                <Typography variant="body1">
                  <FormattedMessage
                    id="StudioCreateProduct.TourPriceAddAttractions"
                    defaultMessage="Please add attractions:"
                  />
                </Typography>
                <PlacesAutocomplete
                  onlyPlaces
                  clearAfterPick
                  disableClearable
                  className={classes.placesAutocomplete}
                  noOptionsText={noOptionText}
                  forcePopupIcon={false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      type="MuiText"
                      variant="standard"
                      label={enterExactAddress}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  onChange={(_e, value) => {
                    sendAttractionsData(
                      {
                        name: value.structured_formatting.main_text,
                        address: getShortLabel(value),
                        location: [Number(value.lng), Number(value.lat)],
                        google_place_id: value.place_id,
                        entrance: true,
                      },
                      'add',
                      null
                    );
                  }}
                />
              </React.Fragment>
            ) : (
              <Button
                disabled={products[0].id !== receivedPublishData.id}
                variant="outlined"
                onClick={() => setIsSearchShow(true)}
              >
                <FormattedMessage
                  id="StudioCreateProduct.TourPriceAddPlace"
                  defaultMessage="Add place"
                />
              </Button>
            )}
          </div>

          <Typography className={classes.sightTitle} variant="h6">
            <FormattedMessage
              id="StudioCreateProduct.TourPriceRecommendedPrice"
              defaultMessage="Recommended price for your tour"
            />
          </Typography>
          {checkContainsTickets(attractions) ? (
            categoriesList()
          ) : (
            <React.Fragment>
              <Typography gutterBottom variant="body1">
                <FormattedMessage
                  id="StudioCreateProduct.TourPriceRecommendedSubhead"
                  defaultMessage="We recommend this price, but you can change it:"
                />
              </Typography>
              <TextField
                className={classes.textFieldSm}
                variant="standard"
                value={Object.values(prices)[0] || ''}
                disabled={products[0].id !== receivedPublishData.id}
                error={
                  (!Object.values(prices)[0] && location.highlights) ||
                  Object.values(prices)[0] < 0
                }
                label={
                  <FormattedMessage
                    id="StudioCreateProduct.TourPriceLabel"
                    defaultMessage="Tour price"
                  />
                }
                helperText={
                  prices[DEFAULT_PRICING_CATEGORY_ID] < 0 ? (
                    negativePrice
                  ) : (
                    <FormattedMessage
                      id="StudioCreateProduct.TourPriceMinimum"
                      defaultMessage="Recommended price 6 euro"
                    />
                  )
                }
                onChange={(e) => {
                  const newPrice = formatToPrice(e.target.value);
                  if (isStringPositiveNumber(newPrice)) {
                    setPrices({
                      ...prices,
                      [firstKey]: newPrice,
                    });
                    if (newPrice >= 0)
                      debouncedHandleSaveLabeledPrice(
                        newPrice,
                        DEFAULT_PRICING_CATEGORY_ID
                      );
                  }
                }}
              />
              <FormControl className={classes.currencySelect}>
                <InputLabel>{currency}</InputLabel>
                <Select
                  value={defaultCurrency.id || ''}
                  disabled={products[0].id !== receivedPublishData.id}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  onChange={(e) => {
                    publishingActions.sendPublishData(receivedPublishData.id, {
                      currencyId: e.target.value,
                    });
                  }}
                >
                  {currencies.map((c) => (
                    <MenuItem key={c.id} value={c.id}>
                      {c.code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </React.Fragment>
          )}
        </Paper>
        {!checkFullness('price') && (
          <Typography className={classes.helperFullness} variant="body1">
            <FormattedMessage
              id="StudioCreateProduct.tourPriceRemainsToFill"
              defaultMessage="Remaining sections to fill in: "
            />
            {handleFullnessText()}
          </Typography>
        )}
        <TourContentFooterButtons
          leftLink={`/quest/${currentQuestId}/audience`}
          rightLink={`/quest/${currentQuestId}/check`}
          withRedirectToTourCheck={location.highlights}
          leftBtnVisible={!location.highlights}
        />
      </Container>
    </section>
  );
};

const mapStateToProps = (state) => ({
  publishing: state.publishing,
  currentQuestId: state.quests.currentQuestId,
  currencies: state.currencies.items,
});

export default withRouter(connect(mapStateToProps)(injectIntl(TourPrice)));
