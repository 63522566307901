import { GoogleProvider } from 'leaflet-geosearch';

export function getAddressComponentLongName(result, type) {
  const component = result.address_components.find(
    (c) => c.types.indexOf(type) !== -1
  );
  return component ? component.long_name : undefined;
}

export function getShortLabel(r) {
  const pointOfInterest = getAddressComponentLongName(r, 'point_of_interest');
  const streetNumber = getAddressComponentLongName(r, 'street_number');
  const route = getAddressComponentLongName(r, 'route');
  const locality = getAddressComponentLongName(r, 'locality');
  const country = getAddressComponentLongName(r, 'country');
  const sublocality = getAddressComponentLongName(r, 'sublocality');
  const output = [route, streetNumber || pointOfInterest, sublocality].filter(
    (x) => x !== undefined
  ).length
    ? [route, streetNumber || pointOfInterest, sublocality]
        .filter((x) => x !== undefined)
        .join(', ')
    : [country, locality].filter((x) => x !== undefined).join(', ');

  return output;
}

export default class BetterGoogleProvider extends GoogleProvider {
  preprocess({ query, latlng }) {
    if (query) {
      const latlngMatch = query.match(/^(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)/);
      if (latlngMatch) {
        let [, lat, , lng] = latlngMatch;
        lat = parseFloat(lat);
        lng = parseFloat(lng);
        latlng = { lat, lng };
        return { query: `${lat}, ${lng}`, latlng };
      }
      return { query };
    } else if (latlng) {
      const { lat, lng } = latlng;
      return { query: `${lat}, ${lng}`, latlng };
    }
  }

  async search(params) {
    const { query, latlng } = this.preprocess(params);
    // eslint-disable-next-line no-restricted-globals
    const protocol = ~location.protocol.indexOf('http')
      ? location.protocol
      : 'https:';
    const url = this.endpoint({ query, protocol });

    const request = await fetch(url);
    const json = await request.json();
    return this.parse({ data: json }, latlng);
  }

  parse({ data }, latlng) {
    // console.log({data, latlng});
    let results = data.results.map((r) => {
      const label = r.formatted_address;
      const shortLabel = getShortLabel(r);
      return {
        x: r.geometry.location.lng,
        y: r.geometry.location.lat,
        label,
        shortLabel,
        bounds: [
          [
            r.geometry.viewport.southwest.lat,
            r.geometry.viewport.southwest.lng,
          ], // s, w
          [
            r.geometry.viewport.northeast.lat,
            r.geometry.viewport.northeast.lng,
          ], // n, e
        ],
        raw: r,
      };
    });
    if (latlng) {
      const { lat, lng } = latlng;
      results = [
        {
          x: lng,
          y: lat,
          label: `${lat}, ${lng}`,
          shortLabel: `${lat}, ${lng}`,
          bounds: [
            [lat, lng],
            [lat, lng],
          ],
          raw: latlng,
          isLatLng: true,
        },
        ...results,
      ];
    }
    return results;
  }
}
