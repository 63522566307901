import createValidationRule from './createValidationRule';
import { timeInPast } from './rulesName';

export const timeInPastRule = createValidationRule(
  timeInPast,
  'triggers',
  (currentEvent, nextEvent) => {
    if (nextEvent === null) {
      return true;
    }

    const { trigger: { time: timeContent = null } = {} } = nextEvent;

    if (!timeContent) {
      return true;
    }

    return new Date() < timeContent;
  }
);
