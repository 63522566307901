import { pathToRegexp } from 'path-to-regexp';

export const paths = [
  [
    pathToRegexp('/quest/:questId/events/:eventOrder/:anchor?'),
    ['questId', 'eventOrder', 'anchor'],
  ],
  [pathToRegexp('/quest/:questId/codes/'), ['questId']],
  [pathToRegexp('/quest/:questId/route/'), ['questId']],
  [pathToRegexp('/quest/:questId/title/'), ['questId']],
  [pathToRegexp('/quest/:questId/description/'), ['questId']],
  [pathToRegexp('/quest/:questId/locations/'), ['questId']],
  [pathToRegexp('/quest/:questId/details/'), ['questId']],
  [pathToRegexp('/quest/:questId/highlights/'), ['questId']],
  [pathToRegexp('/quest/:questId/photos/'), ['questId']],
  [pathToRegexp('/quest/:questId/audience/'), ['questId']],
  [pathToRegexp('/quest/:questId/price/'), ['questId']],
  [pathToRegexp('/quest/:questId/check/'), ['questId']],
  [pathToRegexp('/quest/:questId/publish/'), ['questId']],
  [pathToRegexp('/quest/:questId/map/'), ['questId']],
];
