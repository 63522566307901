import * as types from '../constants/ActionTypes';
import Api from '../functions/Api';
import { deleteToken } from '../functions/tokenUtils';

export function signup(payload) {
  return {
    type: types.SIGNUP,
    payload,
  };
}

export function sendUserInfo(payload, redirect = false, locale) {
  return {
    type: types.SEND_USER_INFO,
    payload,
    redirect,
    locale,
  };
}

export function addLocation(payload) {
  return {
    type: types.ADD_LOCATION,
    payload,
  };
}

export function deleteSelectedLocation(id) {
  return {
    type: types.DELETE_SELECTED_LOCATION,
    id,
  };
}

export function addPlaceOfStay(payload) {
  return {
    type: types.ADD_PLACE_OF_STAY,
    payload,
  };
}

export function selectLanguage(payload) {
  return {
    type: types.SELECT_LANGUAGE,
    payload,
  };
}

export function deleteSelectedLanguage(id) {
  return {
    type: types.DELETE_SELECTED_LANGUAGE,
    id,
  };
}

export function selectSubcategory(payload) {
  return {
    type: types.SELECT_SUBCATEGORY,
    payload,
  };
}

export function deleteSelectedSubcategory(id) {
  return {
    type: types.DELETE_SELECTED_SUBCATEGORY,
    id,
  };
}

export function receiveUserInfo(locale) {
  return {
    type: types.RECEIVE_USER_INFO,
    locale,
  };
}

export function requestCategories(locale) {
  return {
    type: types.REQUEST_CATEGORIES,
    locale,
  };
}

export function fetchCurrencies(locale) {
  return {
    type: types.FETCH_CURRENCIES,
    locale,
  };
}

export function requestSelectedLocations(locale) {
  return {
    type: types.REQUEST_SELECTED_LOCATIONS,
    locale,
  };
}

export function login(username, password) {
  return { type: types.LOGIN, username, password };
}

export function logout() {
  deleteToken();
  return { type: types.LOGOUT, email: '' };
}

export function sendUser() {
  return { type: types.SEND_USER };
}

export function addErrors(json, response, section) {
  if (!json)
    return {
      type: types.USER_ADD_ERROR,
      error: {
        code: response.status,
        message: 'Server Error',
      },
      section,
    };
  return (dispatch) => {
    json.errors.forEach((error) =>
      dispatch({
        type: types.USER_ADD_ERROR,
        error: {
          code: response.status,
          ...error,
          section,
        },
      })
    );
  };
}

// TODO: remove unused removeErrors code in Profile
export function removeErrors(section) {
  return {
    type: types.USER_REMOVE_ERRORS,
    section,
  };
}

export function sendPassword() {
  return {
    type: types.SEND_PASSWORD,
  };
}

export function requestLogout() {
  return { type: types.REQUEST_LOGOUT };
}

export function unhijackUser() {
  return { type: types.UNHIJACK };
}

export function updatePassword() {
  return {
    type: types.UPDATE_PASSWORD,
  };
}

export function notUpdatePassword({ json, response }) {
  return (dispatch) => {
    dispatch({ type: types.NOT_UPDATE_PASSWORD });
    return dispatch(addErrors(json, response, 'password'));
  };
}

export function requestUser(options) {
  return { type: types.REQUEST_USER, ...options };
}

export function notReceiveUser(json) {
  return (dispatch) => {
    dispatch({
      type: types.NOT_RECEIVE_USER,
      message: json.message,
    });
    return dispatch(addErrors(json));
  };
}

export function fetchUser(options) {
  return requestUser(options);
}

export function logoutUser() {
  return (dispatch) => {
    dispatch(requestLogout());
    dispatch(logout());
  };
}

export function updateProfile(profile) {
  return {
    type: types.USER_UPDATE,
    payload: profile,
  };
}

export function resetUserErrors() {
  return {
    type: types.USER_RESET_ERRORS,
  };
}

export function changePassword({ oldPassword, newPassword }) {
  return (dispatch) => {
    dispatch(sendPassword());
    return Api.post(`change_password/`, {
      data: {
        oldPassword,
        newPassword,
      },
    })
      .then(() => {
        dispatch(updatePassword());
        return dispatch(fetchUser());
      })
      .catch((error) => {
        dispatch(notUpdatePassword({ json: error.response, response: error }));
      });
  };
}

export function changeLanguage(locale) {
  return {
    type: types.CHANGE_LANGUAGE,
    locale,
  };
}

export function shouldFetchUser(state) {
  return !state.user.isLogged;
}

export function fetchUserIfNeeded(options) {
  // TODO: refactor in sagas
  return (dispatch, getState) => {
    if (shouldFetchUser(getState())) {
      return dispatch(fetchUser(options));
    }
    return null;
  };
}

export function getUserEmail(state) {
  return state.user.email;
}

export function partnerApply() {
  return {
    type: types.PARTNER_APPLY,
  };
}

export function updatePartnerData(data) {
  return {
    type: types.UPDATE_PARTNER_DATA,
    data,
  };
}

export function updateRequisites(data) {
  return {
    type: types.UPDATE_REQUISITES,
    data,
  };
}

export function closeFormBioPopup() {
  return {
    type: types.CLOSE_FORM_BIO_POPUP,
  };
}

export function uploadAvatar(action) {
  return {
    type: types.UPLOAD_AVATAR,
    image: action.image,
  };
}

export function requestPayout() {
  return {
    type: types.REQUEST_PAYOUT,
  };
}

export function updateAffiliatePartnerData(data) {
  return {
    type: types.UPDATE_AFFILIATE_PARTNER,
    data,
  };
}
