import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

class CodesBalance extends Component {
  render() {
    const {
      balance,
      nextDownloads: downloads,
      maxDownloads,
      usedDownloads,
    } = this.props;

    if (!downloads) return null;

    if (downloads > balance) {
      const lack = downloads - balance;
      return (
        <div className="GenerateCodes__summary">
          <FormattedMessage
            id="CodesBalance.overBalanceText"
            defaultMessage="There is is not enough {lack} {lack, plural, =1 {download} other {downloads}} on your balance ({balance})"
            values={{
              balance,
              lack,
            }}
          />
        </div>
      );
    }

    return (
      <div className="GenerateCodes__summary">
        <FormattedMessage
          id="CodesBalance.decreaseBalanceText"
          defaultMessage="You used {used} of {maxDownloads}, now you have {balance}. It will decrease to {decrease}"
          values={{
            balance,
            downloads,
            maxDownloads,
            used: usedDownloads,
            decrease: <b>{balance - downloads}</b>,
          }}
        />
      </div>
    );
  }
}

CodesBalance.propTypes = {
  balance: PropTypes.number.isRequired,
  nextDownloads: PropTypes.number.isRequired,
};

export default CodesBalance;
