import {
  spawnAudioContent,
  spawnDirectionsContent,
  spawnImageContent,
  spawnStickerContent,
} from '../../constants/events/store';
import getEventContent from './getEventContent';

/**
 * Hash map with content type templates
 * @type {Object}
 */
const CONTENT_TEMPLATES = {
  image: spawnImageContent,
  audio: spawnAudioContent,
  directions: spawnDirectionsContent,
  sticker: spawnStickerContent,
};

/**
 * Maps `content` array and calls `callback` on item with contentType `type`
 * @param {Array[Object]?} contentUnsafe - content array (like `store::quests.quests[].evens[].content`)
 * @param {String} type - type of the content to edit (now available only `"image"`)
 * @param {Function} callback - must return new value of content with content type `type`
 * @returns {Array} - modified `content` array
 */
export default function editEventContent(contentUnsafe, type, callback) {
  const content = Array.isArray(contentUnsafe) ? contentUnsafe : [];
  if (!getEventContent(type, { content })) {
    const template = CONTENT_TEMPLATES[type] ? CONTENT_TEMPLATES[type]() : null;
    if (template) {
      content.push(template);
    } else {
      return content;
    }
  }

  return content.map((c) => {
    if (c.contentType === type) {
      return callback(c);
    }

    return c;
  });
}
