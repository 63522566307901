import React from 'react';
import classNames from 'classnames';
import { FormattedDate } from 'react-intl';

export const codeItem = (status, accessCode) => {
  if (!accessCode) return null;
  return (
    <code
      className={classNames(
        'StatsTable__code',
        status && `StatsTable__code--${status}`
      )}
    >
      {accessCode}
    </code>
  );
};

export const penaltyItem = (penaltyPoints) => {
  return (
    <span
      className={classNames(
        'StatsTable__penalty',
        !!penaltyPoints && 'StatsTable__penalty--notnull'
      )}
    >
      {penaltyPoints}
    </span>
  );
};

export const lastDoneItem = (lastDone) => {
  if (!lastDone.event) return '–';

  const date = lastDone.checkpoint.time;
  const order = lastDone.event.order + 1;
  const { title } = lastDone.event;

  return (
    <div>
      {order}, «{title}»<br />
      <sub>
        <FormattedDate
          value={date}
          month="long"
          day="numeric"
          year="numeric"
          hour="numeric"
          minute="numeric"
        />
      </sub>
    </div>
  );
};
