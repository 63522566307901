import React, { useState } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Button, TextField, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import PlacesAutocomplete from '../../PlacesAutocomplete';
import { noOptionText } from '../../TourContainer/TourLocations';
import './ProfileSettingsRequisites.css';
import Spinner from '../../Spinner';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  downloadContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: 18,
    marginBottom: 18,
  },
  getAppIcon: {
    transform: 'rotate(-180deg)',
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'inline-block',
    marginRight: 10,
  },
  downloadTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'inline-block',
  },
  requisitesContainer: {
    marginTop: 15,
    maxWidth: 600,
  },
  placesAutocompleteBlock: {
    maxWidth: 300,
    width: '90%',
    display: 'flex',
    marginBottom: 30,
  },
  requisitesTextFieldInlineBig: {
    display: 'inline-flex',
    maxWidth: 400,
    width: '90%',
    marginRight: 15,
    marginBottom: 30,
  },
  requisitesTextFieldInlineSmall: {
    display: 'inline-flex',
    maxWidth: 155,
    width: '90%',
    marginRight: 15,
    marginBottom: 30,
  },
  textFieldBlock: {
    maxWidth: 400,
    width: '90%',
    display: 'flex',
    marginBottom: 30,
  },
  payoutsPlate: {
    display: 'flex',
    width: 'calc(100% - 68px)',
    padding: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexShrink: 0,
    flexWrap: 'wrap',
    border: '1px solid #F5F5F5',
    boxSizing: 'border-box',
    borderRadius: 8,
    gap: 15,
  },
  payoutsPlateText: {
    width: 540,
  },
  placesAutocompleteBlockPopup: {
    position: 'absolute',
    top: '100%',
    left: 0,
    zIndex: 1000,
  },
}));

const ErrorText = (
  <FormattedMessage
    id="ProfileSettings.errorText"
    defaultMessage="This field is required"
  />
);

const ProfileSettingsRequisites = ({
  address,
  setAddress,
  index,
  setIndex,
  companyName,
  setCompanyName,
  inn,
  setInn,
  bankName,
  setBankName,
  bic,
  setBic,
  swift,
  setSwift,
  accountNumber,
  setAccountNumber,
  debouncedHandleSave,
  updateRequisites,
  user: { requisites, locale },
}) => {
  const classes = useStyles();

  const [fieldErrors, setFieldErrors] = React.useState({
    address: false,
    country: false,
    city: false,
    postalCode: false,
    residence: false,
    legalStatus: false,
    name: false,
    tax: false,
    bankName: false,
    bic: false,
    swift: false,
    account: false,
  });
  const [countries, setCountries] = React.useState([]);
  const [isFieldFocused, setIsFieldFocused] = React.useState(false);

  const [disableTaxNumberInput, setDisableTaxNumberInput] = React.useState(
    !requisites.hasTaxNumber
  );

  React.useEffect(() => {
    setDisableTaxNumberInput(!requisites.hasTaxNumber);
  }, [requisites.hasTaxNumber]);

  React.useEffect(() => {
    updateRequisites({
      has_tax_number: !disableTaxNumberInput,
    });
  }, [disableTaxNumberInput]);

  React.useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(
          `/api/v2/countries/?published=false&per_page=300`,
          {
            headers: {
              'Accept-Language': locale,
            },
          }
        );
        const data = await response.json();
        setCountries(data.data.results);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, [locale]);

  const [isLoading, setIsLoading] = React.useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const fromRedirect = urlParams.get('fromRedirectPayouts');
  const [status, setStatus] = useState(requisites.legalStatus);
  const validateField = (value) => {
    if (typeof value === 'string') {
      return value.trim() !== '' ? null : ErrorText;
    }
    return value != null ? null : ErrorText;
  };

  const [selectedCountry, setSelectedCountry] = React.useState(
    requisites.countryResidence && requisites.countryResidence.id
      ? requisites.countryResidence.id
      : ''
  );

  return (
    <React.Fragment>
      <div
        className={(classes.requisitesContainer, 'ProfileSettingsRequisites')}
      >
        <div className={classes.downloadContainer}>
          <GetAppIcon className={classes.getAppIcon} fontSize="small" />
          <Typography className={classes.downloadTitle} variant="body2">
            <FormattedMessage
              id="ProfileSettings.downloadRequisites"
              defaultMessage="Download requisite card"
            />
          </Typography>
        </div>
        <div className="ProfileSettingsRequisites__city-container">
          <PlacesAutocomplete
            withValueProp
            disableClearable
            className={classes.placesAutocompleteBlock}
            noOptionsText={noOptionText}
            value={requisites.country}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                value={requisites.country}
                type="MuiText"
                variant="standard"
                label={
                  <FormattedMessage
                    id="ProfileSettings.country"
                    defaultMessage="Country"
                  />
                }
                error={
                  !isLoading &&
                  !requisites.country &&
                  fieldErrors.country &&
                  !isFieldFocused
                }
                helperText={
                  (fromRedirect &&
                    !isLoading &&
                    !requisites.country &&
                    validateField(requisites.country)) ||
                  (!fromRedirect &&
                    !isLoading &&
                    validateField(requisites.country) &&
                    fieldErrors.country &&
                    ErrorText)
                }
              />
            )}
            onChange={(event, value) => {
              if (value) {
                setIsLoading(true);
                debouncedHandleSave(
                  value.description,
                  updateRequisites,
                  requisites.country,
                  'country'
                ).finally(() => {
                  setIsLoading(false);
                });
              }
              setFieldErrors((prevErrors) => ({
                ...prevErrors,
                country: false,
              }));
            }}
            onBlur={() => {
              setFieldErrors((prevErrors) => ({
                ...prevErrors,
                country: !requisites.country,
              }));
              setIsFieldFocused(false);
            }}
            onFocus={() => {
              setIsFieldFocused(true);
            }}
          />
          <PlacesAutocomplete
            withValueProp
            disableClearable
            className={classes.placesAutocompleteBlock}
            noOptionsText={noOptionText}
            value={requisites.city}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                value={requisites.city}
                type="MuiText"
                variant="standard"
                label={
                  <FormattedMessage
                    id="ProfileSettings.city"
                    defaultMessage="City"
                  />
                }
                error={
                  !isLoading &&
                  !requisites.city &&
                  fieldErrors.city &&
                  !isFieldFocused
                }
                helperText={
                  (fromRedirect &&
                    !isLoading &&
                    !requisites.city &&
                    validateField(requisites.city)) ||
                  (!fromRedirect &&
                    !isLoading &&
                    validateField(requisites.city) &&
                    fieldErrors.city &&
                    ErrorText)
                }
              />
            )}
            onChange={(event, value) => {
              if (value) {
                setIsLoading(true);
                debouncedHandleSave(
                  value.description,
                  updateRequisites,
                  requisites.city,
                  'city'
                ).finally(() => {
                  setIsLoading(false);
                });
              }

              setFieldErrors((prevErrors) => ({
                ...prevErrors,
                city: false,
              }));
            }}
            onBlur={() => {
              setFieldErrors((prevErrors) => ({
                ...prevErrors,
                city: !requisites.city,
              }));
              setIsFieldFocused(false);
            }}
            onFocus={() => {
              setIsFieldFocused(true);
            }}
          />
        </div>
        <TextField
          className={classes.requisitesTextFieldInlineBig}
          value={address}
          variant="standard"
          type="MuiText"
          label={
            <FormattedMessage
              id="ProfileSettings.address"
              defaultMessage="Address"
            />
          }
          error={!address && fieldErrors.address && !isFieldFocused}
          helperText={
            (!address && fromRedirect && validateField(address)) ||
            (!fromRedirect &&
              validateField(address) &&
              fieldErrors.address &&
              ErrorText)
          }
          onChange={({ target: { value } }) => {
            setAddress(value);

            debouncedHandleSave(
              value,
              updateRequisites,
              requisites.address,
              'address'
            );

            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              address: false,
            }));
          }}
          onBlur={() => {
            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              address: !address,
            }));
            setIsFieldFocused(false);
          }}
          onFocus={() => {
            setIsFieldFocused(true);
          }}
        />
        <TextField
          className={classes.requisitesTextFieldInlineSmall}
          value={index}
          variant="standard"
          type="MuiText"
          label={
            <FormattedMessage
              id="ProfileSettings.index"
              defaultMessage="Postal code"
            />
          }
          error={!index && fieldErrors.postalCode && !isFieldFocused}
          helperText={
            (fromRedirect && !index && validateField(index)) ||
            (!fromRedirect &&
              validateField(index) &&
              fieldErrors.postalCode &&
              ErrorText)
          }
          onChange={({ target: { value } }) => {
            setIndex(value);

            debouncedHandleSave(
              value,
              updateRequisites,
              requisites.index,
              'index'
            );

            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              postalCode: false,
            }));
          }}
          onBlur={() => {
            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              postalCode: !index,
            }));
            setIsFieldFocused(false);
          }}
          onFocus={() => {
            setIsFieldFocused(true);
          }}
        />
        <Autocomplete
          id="combo-box-country-residence"
          options={countries}
          className={classes.placesAutocompleteBlock}
          getOptionLabel={(option) => option.name}
          value={
            countries.find((country) => country.id === selectedCountry) || {}
          }
          onChange={(event, newValue) => {
            setSelectedCountry(newValue ? newValue.id : '');
            debouncedHandleSave(
              newValue ? newValue.id.toString() : '',
              updateRequisites,
              requisites.countryResidence,
              'country_residence'
            );

            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              residence: false,
            }));
          }}
          onBlur={() => {
            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              residence: !selectedCountry,
            }));
            setIsFieldFocused(false);
          }}
          onFocus={() => {
            setIsFieldFocused(true);
          }}
          error={!selectedCountry && fieldErrors.residence && !isFieldFocused}
          helperText={
            (!selectedCountry &&
              fromRedirect &&
              validateField(selectedCountry)) ||
            (!fromRedirect &&
              validateField(selectedCountry) &&
              fieldErrors.residence &&
              ErrorText)
          }
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              className: classes.placesAutocompleteBlockPopup,
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                <FormattedMessage
                  id="ProfileSettings.countryResidence"
                  defaultMessage="Country of Residence"
                />
              }
              variant="standard"
              error={!selectedCountry && fieldErrors.residence}
              helperText={!selectedCountry ? 'Country is required' : ''}
            />
          )}
        />

        {locale !== 'ru' ? (
          <TextField
            fullWidth
            select
            className={classes.placesAutocompleteBlock}
            value={status}
            variant="standard"
            label={
              <FormattedMessage
                id="ProfileSettings.employmentStatus"
                defaultMessage="Employment Status"
              />
            }
            error={!status && fieldErrors.legalStatus && !isFieldFocused}
            helperText={
              (!status && fromRedirect && validateField(status)) ||
              (!fromRedirect &&
                validateField(status) &&
                fieldErrors.legalStatus &&
                ErrorText)
            }
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                className: classes.placesAutocompleteBlockPopup,
              },
            }}
            onChange={(event) => {
              const { value } = event.target;
              setStatus(value);
              debouncedHandleSave(
                value,
                updateRequisites,
                status,
                'legal_status'
              );

              setFieldErrors((prevErrors) => ({
                ...prevErrors,
                legalStatus: false,
              }));
            }}
            onBlur={() => {
              setFieldErrors((prevErrors) => ({
                ...prevErrors,
                legalStatus: !status,
              }));
              setIsFieldFocused(false);
            }}
            onFocus={() => {
              setIsFieldFocused(true);
            }}
          >
            <MenuItem value="legal">Legal entity</MenuItem>
            <MenuItem value="natural">Individual</MenuItem>
          </TextField>
        ) : (
          <TextField
            fullWidth
            select
            className={classes.placesAutocompleteBlock}
            value={status}
            variant="standard"
            label={
              <FormattedMessage
                id="ProfileSettings.employmentStatus"
                defaultMessage="Employment Status"
              />
            }
            error={!status && fieldErrors.legalStatus && !isFieldFocused}
            helperText={
              (!status && fromRedirect && validateField(status)) ||
              (!fromRedirect &&
                validateField(status) &&
                fieldErrors.legalStatus &&
                ErrorText)
            }
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                className: classes.placesAutocompleteBlockPopup,
              },
            }}
            onChange={(event) => {
              const { value } = event.target;
              setStatus(value);
              debouncedHandleSave(
                value,
                updateRequisites,
                status,
                'legal_status'
              );

              setFieldErrors((prevErrors) => ({
                ...prevErrors,
                legalStatus: false,
              }));
            }}
            onBlur={() => {
              setFieldErrors((prevErrors) => ({
                ...prevErrors,
                legalStatus: !status,
              }));
              setIsFieldFocused(false);
            }}
            onFocus={() => {
              setIsFieldFocused(true);
            }}
          >
            <MenuItem value="ooo">ООО</MenuItem>
            <MenuItem value="ip">ИП</MenuItem>
            <MenuItem value="se">Cамозанятый</MenuItem>
            <MenuItem value="natural">Физическое лицо</MenuItem>
          </TextField>
        )}
        <TextField
          className={classes.requisitesTextFieldInlineBig}
          value={companyName}
          variant="standard"
          type="MuiText"
          label={
            <FormattedMessage
              id="ProfileSettings.companyName"
              defaultMessage="Name of the recipient"
            />
          }
          error={!companyName && fieldErrors.name && !isFieldFocused}
          helperText={
            (!companyName && fromRedirect && validateField(companyName)) ||
            (!fromRedirect &&
              validateField(companyName) &&
              fieldErrors.name &&
              ErrorText)
          }
          onChange={({ target: { value } }) => {
            setCompanyName(value);

            debouncedHandleSave(
              value,
              updateRequisites,
              requisites.name,
              'name'
            );

            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              name: false,
            }));
          }}
          onBlur={() => {
            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              name: !companyName,
            }));
            setIsFieldFocused(false);
          }}
          onFocus={() => {
            setIsFieldFocused(true);
          }}
        />
        <div className="VAT-container">
          <TextField
            className={classes.requisitesTextFieldInlineSmall}
            value={inn}
            variant="standard"
            type="MuiText"
            label={
              <FormattedMessage
                id="ProfileSettings.inn"
                defaultMessage="Tax number, personal"
              />
            }
            disabled={disableTaxNumberInput}
            error={
              !inn &&
              fieldErrors.tax &&
              !isFieldFocused &&
              !disableTaxNumberInput
            }
            helperText={
              (!inn &&
                fromRedirect &&
                !disableTaxNumberInput &&
                validateField(inn)) ||
              (!fromRedirect &&
                validateField(inn) &&
                fieldErrors.tax &&
                !disableTaxNumberInput &&
                ErrorText)
            }
            onChange={({ target: { value } }) => {
              setInn(value);

              debouncedHandleSave(
                value,
                updateRequisites,
                requisites.taxNumber,
                'taxNumber'
              );

              setFieldErrors((prevErrors) => ({
                ...prevErrors,
                tax: false,
              }));
            }}
            onBlur={() => {
              setFieldErrors((prevErrors) => ({
                ...prevErrors,
                tax: !inn,
              }));
              setIsFieldFocused(false);
            }}
            onFocus={() => {
              setIsFieldFocused(true);
            }}
          />
          {locale !== 'ru' && (
            <FormControlLabel
              control={
                <Switch
                  checked={disableTaxNumberInput}
                  name="disableTaxNumberInput"
                  color="primary"
                  onChange={() => {
                    setDisableTaxNumberInput((prev) => !prev);
                  }}
                />
              }
              label="No tax number"
            />
          )}
        </div>
        <TextField
          className={classes.requisitesTextFieldInlineBig}
          value={bankName}
          variant="standard"
          type="MuiText"
          label={
            <FormattedMessage
              id="ProfileSettings.banksName"
              defaultMessage="Bank name"
            />
          }
          error={!bankName && fieldErrors.bankName && !isFieldFocused}
          helperText={
            (!bankName && fromRedirect && validateField(bankName)) ||
            (!fromRedirect &&
              validateField(bankName) &&
              fieldErrors.bankName &&
              ErrorText)
          }
          onChange={({ target: { value } }) => {
            setBankName(value);

            debouncedHandleSave(
              value,
              updateRequisites,
              requisites.bankName,
              'bankName'
            );

            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              bankName: false,
            }));
          }}
          onBlur={() => {
            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              bankName: !bankName,
            }));
            setIsFieldFocused(false);
          }}
          onFocus={() => {
            setIsFieldFocused(true);
          }}
        />
        {locale === 'ru' ? (
          <TextField
            className={classes.requisitesTextFieldInlineSmall}
            value={bic}
            variant="standard"
            type="MuiText"
            label={
              <FormattedMessage id="ProfileSettings.bic" defaultMessage="BIC" />
            }
            error={!bic && fieldErrors.bic && !isFieldFocused}
            helperText={
              (!bic && fromRedirect && validateField(bic)) ||
              (!fromRedirect &&
                validateField(bic) &&
                fieldErrors.bic &&
                ErrorText)
            }
            onChange={({ target: { value } }) => {
              setBic(value);

              debouncedHandleSave(
                value,
                updateRequisites,
                requisites.bankBic,
                'bankBic'
              );

              setFieldErrors((prevErrors) => ({
                ...prevErrors,
                bic: false,
              }));
            }}
            onBlur={() => {
              setFieldErrors((prevErrors) => ({
                ...prevErrors,
                bic: !bic,
              }));
              setIsFieldFocused(false);
            }}
            onFocus={() => {
              setIsFieldFocused(true);
            }}
          />
        ) : (
          <TextField
            className={classes.requisitesTextFieldInlineSmall}
            value={swift}
            variant="standard"
            type="MuiText"
            label={
              <FormattedMessage
                id="ProfileSettings.swift"
                defaultMessage="SWIFT"
              />
            }
            error={!swift && fieldErrors.swift && !isFieldFocused}
            helperText={
              (!swift && fromRedirect && validateField(swift)) ||
              (!fromRedirect &&
                validateField(swift) &&
                fieldErrors.swift &&
                ErrorText)
            }
            onChange={({ target: { value } }) => {
              setSwift(value);

              debouncedHandleSave(
                value,
                updateRequisites,
                requisites.bankSwift,
                'bankSwift'
              );

              setFieldErrors((prevErrors) => ({
                ...prevErrors,
                swift: false,
              }));
            }}
            onBlur={() => {
              setFieldErrors((prevErrors) => ({
                ...prevErrors,
                swift: !swift,
              }));
              setIsFieldFocused(false);
            }}
            onFocus={() => {
              setIsFieldFocused(true);
            }}
          />
        )}
        <TextField
          className={classes.textFieldBlock}
          value={accountNumber}
          variant="standard"
          type="MuiText"
          label={
            <FormattedMessage
              id="ProfileSettings.accountNumber"
              defaultMessage="Account number"
            />
          }
          error={!accountNumber && fieldErrors.account && !isFieldFocused}
          helperText={
            (!accountNumber && fromRedirect && validateField(accountNumber)) ||
            (!fromRedirect &&
              validateField(accountNumber) &&
              fieldErrors.account &&
              ErrorText)
          }
          onChange={({ target: { value } }) => {
            setAccountNumber(value);

            debouncedHandleSave(
              value,
              updateRequisites,
              requisites.accountNumber,
              'accountNumber'
            );

            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              account: false,
            }));
          }}
          onBlur={() => {
            setFieldErrors((prevErrors) => ({
              ...prevErrors,
              account: !accountNumber,
            }));
            setIsFieldFocused(false);
          }}
          onFocus={() => {
            setIsFieldFocused(true);
          }}
        />
      </div>

      <div className={classes.payoutsPlate}>
        <div className={classes.payoutsPlateText}>
          <Typography variant="body2">
            <FormattedMessage
              id="ProfileSettingsRequisites.payoutsPlateText"
              defaultMessage="To withdraw money, go to the payment section"
            />
          </Typography>
        </div>
        <Link to="/reports/payouts">
          <Button variant="contained" color="primary" size="large">
            <FormattedMessage
              id="ProfileSettingsRequisites.toPayments"
              defaultMessage="To payments"
            />
          </Button>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default ProfileSettingsRequisites;
