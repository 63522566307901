import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { isEqual, isEmpty } from 'lodash';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import { getNameUploaded } from '../../functions/files';
import { Mobile, Desktop } from '../../functions/queries';
import { STORAGE_URL } from '../../constants/branding';
import ScrollToTop from '../../functions/ScrollTop';
import Button from '../Button';
import InputLight from '../InputLight';
import AudioPlayer from '../AudioPlayer';
import VideoPlayer from '../VideoPlayer';
import { ArtifactIcon } from '../Icon';
import PhoneSkip from './PhoneSkip';
import '../../css-init/vendors/owl.carousel.min.css';
import '../../css-init/vendors/owl.theme.default.min.css';
import './devices.css';
import './Phone.css';

const messages = defineMessages({
  inputVariousPlaceholder: {
    id: 'Phone.inputVariousPlaceholder',
    defaultMessage: 'Your answer',
  },
});

class Phone extends Component {
  state = {
    choiceError: false,
    isTrue: false,
    variousAnswerCheck: false,
    variousAnswerWrong: false,
    mapPopupClass: '',
    hints: [],
    currentHint: 0,
  };

  /**
   * Reference to the Link element
   * Needs for show green background on right choice button before redirecting
   * @type {React::Ref}
   */
  nextEvent = React.createRef();

  /**
   * Ref to the `.Phone__wrapper` element
   * @type {React::Ref}
   */
  wrapperRef = React.createRef();

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props, nextProps)) {
      const hints = nextProps.event.hints || [];
      this.setState({ hints, currentHint: hints.length });
      this.forceUpdate(); // TODO: check
    }
  }

  renderTitle(title) {
    const {
      event: { order },
      eventsAmount,
    } = this.props;
    return (
      <div className="Phone__eventName">
        <span className="Phone__eventName-count">{`STEP ${
          order + 1
        }/${eventsAmount}`}</span>
        <div className="Phone__eventName-title">{title}</div>
      </div>
    );
  }

  renderText(text) {
    return <div className="Phone__eventBlock Phone__eventText">{text}</div>;
  }

  renderHTML(html) {
    return (
      <div
        className="Phone__eventBlock Phone__eventHTML"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }

  renderVideos(videos) {
    return (
      <div className="Phone__eventBlock Phone__eventMedia Phone__eventVideo">
        <VideoPlayer src={videos[0]} className="Phone__video" theme="card" />
      </div>
    );
  }

  renderImages(images) {
    // TODO: mix videos and images
    const isSingle = images.length === 1;

    return (
      <div className="Phone__eventBlock Phone__eventMedia">
        <OwlCarousel
          key={images.join('/')}
          dots
          className="Phone__eventMedia--carousel"
          items={1}
        >
          {images.map((url) =>
            isSingle ? (
              <img key={url} src={STORAGE_URL + url} />
            ) : (
              <div
                key={url}
                className="Phone__eventMedia--img"
                style={{ backgroundImage: `url(${STORAGE_URL}${url})` }}
              />
            )
          )}
        </OwlCarousel>
      </div>
    );
  }

  renderAudios(audios) {
    return (
      <div className="Phone__eventBlock Phone__eventAudios">
        {audios.map((path) => {
          const name = getNameUploaded(path);

          return (
            <div className="Phone__eventBlock Phone__card">
              <AudioPlayer
                key={path}
                src={STORAGE_URL + path}
                name={name}
                theme="card"
                className="Phone__eventAudio"
              />
            </div>
          );
        })}
      </div>
    );
  }

  showErrorMessage = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        choiceError: true,
      };
    });

    setTimeout(() => {
      this.setState((prevState) => {
        return {
          ...prevState,
          choiceError: false,
        };
      });
    }, 2000);
  };

  fillErrorAnswer = (event) => {
    const errorBlock = event.target;
    const errorClass = 'Phone__answer-btn--error';

    return errorBlock.classList.contains(errorClass)
      ? null
      : errorBlock.classList.add(errorClass);
  };

  eraseVariousInput() {
    if (!this._variousAnswerInput) return;
    this._variousAnswerInput.value = '';
  }

  checkAnswer = (choice) => {
    const { history, questId, event } = this.props;
    const { options } = choice;

    const text = this._variousAnswerInput.value.toLowerCase();
    const isTrueAnswer = options.some((opt) => opt.toLowerCase() === text);

    if (isTrueAnswer) {
      // TODO: show complete mark for sec
      this.eraseVariousInput();
      history.push(`/quest/${questId}/events/${event.order + 1}/`);
    } else {
      this.showErrorMessage();
      this.setState({ variousAnswerWrong: true });
    }
  };

  /**
   * Renders hints block for various choice:
   * - invitation to use hint/answer
   * - showed hints
   * @param {Array|*} answers - right answers
   */
  renderTips(answers) {
    const { hints, currentHint } = this.state;
    const answer = (answers && answers[0]) || '';
    let buttonText = null;

    if (currentHint > 0) {
      buttonText = (
        <FormattedMessage
          id="Phone.choiceGetHint"
          defaultMessage="Get hint ({num} left)"
          values={{
            num: currentHint,
          }}
        />
      );
    } else {
      buttonText = (
        <FormattedMessage
          id="Phone.choiceGetAnswer"
          defaultMessage="Get answer"
        />
      );
    }

    return (
      <div>
        {hints.reduce(
          (acc, { hint }, index) =>
            index >= currentHint
              ? [
                  ...acc,
                  <div key={index} className="Phone__eventChoice-hint">
                    {hint}
                  </div>,
                ]
              : acc,
          []
        )}
        {currentHint > -1 ? (
          <button
            className="Phone__eventChoice-hint Phone__eventChoice-hint-get"
            onClick={() => this.setState({ currentHint: currentHint - 1 })}
          >
            {buttonText}
          </button>
        ) : (
          <div className="Phone__eventChoice-hint">{answer}</div>
        )}
      </div>
    );
  }

  renderEventChoiceVarious(choice) {
    const {
      intl: { formatMessage },
    } = this.props;
    const { options } = choice;
    const { variousAnswerWrong } = this.state;

    return (
      <Fragment>
        <div className="Phone__eventBlock Phone__card Phone__eventChoice--various">
          <div className="Phone__card-header">Answer the question</div>
          <InputLight
            placeholder={formatMessage(messages.inputVariousPlaceholder)}
            inputRef={(input) => (this._variousAnswerInput = input)}
            className={`Phone__answer-input${
              variousAnswerWrong ? ' Phone__answer-input--error' : ''
            }`}
            onChange={({ target }) => {
              if (target.value === '' && this.state.variousAnswerCheck) {
                this.setState({ variousAnswerCheck: false });
              } else if (
                target.value !== '' &&
                !this.state.variousAnswerCheck
              ) {
                this.setState({ variousAnswerCheck: true });
              }

              if (this.state.variousAnswerWrong) {
                this.setState({ variousAnswerWrong: false });
              }
            }}
          />
          <Button
            disabled={!this.state.variousAnswerCheck}
            className="Button Phone__answer-btn"
            theme="holo-round"
            onClick={this.checkAnswer.bind(this, choice)}
          >
            <FormattedMessage
              id="Phone.answerVariousChoiceBtnText"
              defaultMessage="Check"
            />
          </Button>
        </div>
        {this.renderTips(options)}
      </Fragment>
    );
  }

  renderEventChoiceOne(choice) {
    const { options, answer } = choice;
    const { questId, event } = this.props;

    const isNext = options.length === 1 ? '--next' : '';
    if (isNext) {
      return;
    }

    return (
      <div
        className={`Phone__eventBlock Phone__card Phone__eventChoice${isNext}`}
      >
        <div className="Phone__card-header">Answer the question</div>
        {options.map((opt, i) => {
          const isRight = answer === i;
          const key = event.id + i;
          return isRight ? (
            <Fragment key={key}>
              <Link
                ref={this.nextEvent}
                to={`/quest/${questId}/events/${event.order + 1}/`}
              />
              <Button
                className="Phone__answer-btn"
                theme="holo"
                onClick={({ target }) => {
                  if (isNext) {
                    this.nextEvent.current.click();
                    return;
                  }

                  target.classList.add('Phone__answer-btn--right');
                  setTimeout(() => {
                    this.nextEvent.current.click();
                  }, 700);
                }}
              >
                {opt}
              </Button>
            </Fragment>
          ) : (
            <Button
              key={key}
              className="Phone__answer-btn"
              theme="holo"
              onClick={(event) => {
                this.showErrorMessage();
                this.fillErrorAnswer(event);
              }}
            >
              {opt}
            </Button>
          );
        })}
      </div>
    );
  }

  renderChoice(choice) {
    const { answer } = choice;
    if (answer === -1) {
      return this.renderEventChoiceVarious(choice);
    }
    return this.renderEventChoiceOne(choice);
  }

  renderGeoText = () => {
    return (
      <div className="Phone__eventGeo-text">
        <FormattedMessage
          id="Phone.defaultDistanceText"
          defaultMessage="Go to the point"
        />
        <div className="Phone__eventGeo-text--number">
          <FormattedMessage
            id="Phone.defaultDistanceNumber"
            defaultMessage="90m left"
          />
        </div>
      </div>
    );
  };

  renderGeo = () => {
    return (
      <div className="Phone__eventBlock">
        <div className="Phone__eventGeo Phone__eventGeo-map">
          <div className="Phone__eventGeo-map--icon">
            {this.renderGeoText()}
            <div
              className="Phone__eventGeo-fullscreen"
              onClick={() =>
                this.setState({ mapPopupClass: 'Phone__screen--popup' })
              }
            />
          </div>
        </div>
      </div>
    );
  };

  renderMapPopup = () => {
    return <div className="Phone__eventGeo-popup">{this.renderGeoText()}</div>;
  };

  renderAR = () => {
    return (
      <div className="Phone__eventBlock">
        <div className="Phone__card Phone__ar">
          <div className="Phone__card-header">
            <FormattedMessage
              id="Phone.arDefaultText"
              defaultMessage="Scan the artefact"
            />
          </div>
          <ArtifactIcon />
          <Button theme="holo-round" className="Phone__ar-scan-btn">
            Scan
          </Button>
        </div>
      </div>
    );
  };

  renderContent() {
    const {
      questId,
      event: { content = [], title = '' },
      event,
      next: { prevButton: nextButton } = {},
      next,
      eventsAmount,
      routePoint,
      hasNextRoutePoint,
    } = this.props;
    const { choiceError, mapPopupClass } = this.state;

    const { data: text = '' } =
      content.find((item) => item.contentType === 'text') || {};
    const { data: html = '' } =
      content.find((item) => item.contentType === 'html') || {};
    const { data: choice = {} } =
      content.find((item) => item.contentType === 'choice') || {};
    const { data: images = [] } =
      content.find((item) => item.contentType === 'image') || {};
    const { data: videos = [] } =
      content.find((item) => item.contentType === 'video') || {};
    const { data: audios = [] } =
      content.find((item) => item.contentType === 'audio') || {};

    const geo = routePoint;
    let ar = null;
    if (next) {
      const { trigger: { ar: eventAR = null } = null } = next;
      ar = eventAR;
    }

    const isGeoSet = !isEmpty(geo);
    const isARSet = !isEmpty(ar);

    const isChoiceSet =
      choice.options &&
      choice.options.length > 1 &&
      !choice.options.every((opt) => !opt);

    const hasTriggers = [isChoiceSet, isGeoSet, isARSet].some((f) => f);

    return (
      <Fragment>
        <div className="Phone__status-bar" />
        {mapPopupClass ? this.renderMapPopup() : ''}
        <div className="Phone__header">
          <div
            className="Phone__header-close"
            onClick={() => this.setState({ mapPopupClass: '' })}
          />
        </div>
        <div ref={this.wrapperRef} className="Phone__wrapper">
          {this.renderTitle(title)}
          {videos.length ? this.renderVideos(videos) : null}
          {images.length ? this.renderImages(images) : null}
          {audios.length ? this.renderAudios(audios) : null}
          {text && !html ? this.renderText(text) : null}
          {html ? this.renderHTML(html) : null}
          {isChoiceSet ? this.renderChoice(choice, !geo) : null}
          {isGeoSet ? this.renderGeo() : null}
          {isARSet ? this.renderAR() : null}
          {next ? (
            <PhoneSkip
              theme={hasTriggers ? 'bound' : 'separate'}
              to={`/quest/${questId}/events/${event.order + 1}/`}
              requiresDefaultText={!!routePoint}
              text={nextButton}
              hasNextRoutePoint={hasNextRoutePoint}
              isPenultimate={event.order + 2 === eventsAmount}
            />
          ) : null}
        </div>
        <div
          className={
            choiceError
              ? 'Phone__choice-error Phone__choice-error--visible'
              : 'Phone__choice-error'
          }
        >
          <FormattedMessage
            id="Phone.wrongAnswer"
            defaultMessage="Wrong answer"
          />
        </div>
        <ScrollToTop scrollable={this.wrapperRef} />
      </Fragment>
    );
  }

  render() {
    const { mapPopupClass } = this.state;
    const { className, isDemoOpen } = this.props;

    return (
      <div
        className={classNames(
          'Phone__outer',
          isDemoOpen && 'Phone__outer--on',
          className
        )}
      >
        <div
          ref={(div) => (this._phone = div)}
          className={classNames('Phone', isDemoOpen && 'Phone__on')}
          onClick={this.focusOnPhone}
        >
          <Mobile>
            <div className={`Phone__screen ${mapPopupClass}`}>
              {this.renderContent()}
            </div>
          </Mobile>
          <Desktop>
            <div className="marvel-device iphone-x">
              <div className="notch">
                <div className="camera" />
                <div className="speaker" />
              </div>
              <div className="top-bar" />
              <div className="sleep" />
              <div className="bottom-bar" />
              <div className="volume" />
              <div className="overflow">
                <div className="shadow shadow--tr" />
                <div className="shadow shadow--tl" />
                <div className="shadow shadow--br" />
                <div className="shadow shadow--bl" />
              </div>
              <div className="inner-shadow" />
              <div className={`screen Phone__screen ${mapPopupClass}`}>
                {this.renderContent()}
              </div>
            </div>
          </Desktop>
        </div>
      </div>
    );
  }
}

export default withRouter(injectIntl(Phone));
