import * as types from '../constants/ActionTypes';

export function fetchQuestCodes(id) {
  return {
    type: types.FETCH_CODES,
    id,
  };
}

export function createQuestCodes(id, payload) {
  return {
    type: types.CREATE_CODES,
    id,
    payload,
  };
}

export function updateAccessCode(id, payload) {
  return {
    type: types.UPDATE_CODE,
    id,
    payload,
  };
}
