import isEqual from 'lodash/isEqual';
import { useRef, useEffect } from 'react';

/**
 * Tracks syncing route points process
 * Tracks changes in the `quests::syncingRoute` store field
 * @param {Object} $
 * @param {Array[Object]} $.syncingRoute - `quests::syncingRoute` field from redux store
 * @param {Function?} $.onChanged - called when new one of the sending points were synced
 */
export default function useRouteSyncTracking({
  syncingRoute = [],
  onChanged = () => {},
}) {
  /**
   * Check if queries in previous and current `syncingRoute` are equal
   * @param {Object} query1
   * @param {Object} query2
   */
  const detectDiff = (query1, query2) => {
    return !isEqual(query1, query2);
  };

  /**
   * Dump of the last `syncingRote` store value
   * @type {React::Ref}
   */
  const syncingRouteRef = useRef(syncingRoute);
  useEffect(() => {
    const prevSyncingRoute = syncingRouteRef.current;

    let diffIndex = -1;
    for (let i = 0; i < syncingRoute.length; i++) {
      if (detectDiff(syncingRoute[i], prevSyncingRoute[i])) {
        diffIndex = i;
        break;
      }
    }

    let diffQuery;
    let wasAdded;
    if (syncingRoute.length < prevSyncingRoute.length) {
      wasAdded = false;
      diffQuery =
        diffIndex > -1
          ? prevSyncingRoute[diffIndex]
          : prevSyncingRoute[prevSyncingRoute.length - 1];
    } else if (syncingRoute.length > prevSyncingRoute.length) {
      wasAdded = true;
      diffQuery =
        diffIndex > -1
          ? syncingRoute[diffIndex]
          : syncingRoute[syncingRoute.length - 1];
    } else if (diffIndex > -1) {
      diffQuery = syncingRoute[diffIndex];
    }

    if (diffQuery) {
      onChanged(diffQuery, wasAdded);
    }

    syncingRouteRef.current = syncingRoute;
  }, [syncingRoute]);
}
