/**
 * Adds cartesian pixel offset to current
 * @param {Object} map - `leaflet-js` Map instance
 * @param {Object} coords - `lat` and `lon` props with latitude and longitude
 * @param {Object} offset - `x` and `y` with cartesian offset in on-screen pixels
 * @returns {Array[Number]} - latitude and longitude with pixel offset
 */
export default function appendPixelOffset(map, coords, offset) {
  const polarCoords = [coords.lat, coords.lng];

  if (offset) {
    const { x, y } = map.project(polarCoords, map.getZoom());
    const polarOffset = map.unproject(
      { x: x + offset.x, y: y + offset.y },
      map.getZoom()
    );

    return [polarOffset.lat, polarOffset.lng];
  }

  return polarCoords;
}
