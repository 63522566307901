import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ProgressCircle from '../ProgressCircle';
import './Button.css';

class Button extends Component {
  render() {
    let {
      theme,
      size,
      loading,
      className,
      readOnly,
      children,
      ...props
    } = this.props;
    if (loading) {
      readOnly = true;
    }
    const circleSize = 16;
    return (
      <button
        {...props}
        readOnly={readOnly}
        className={classNames(
          'Button',
          theme && `Button--${theme}`,
          size && `Button--${size}`,
          loading && `Button--loading`,
          className
        )}
      >
        {children}
        {loading && (
          <ProgressCircle
            animated
            className="Button__loader"
            size={circleSize}
            progress={63}
            width={2}
          />
        )}
      </button>
    );
  }
}

Button.propTypes = {
  theme: PropTypes.oneOf([
    'light',
    'filled',
    'shadow',
    'disabled',
    'main-light',
    'action',
    'holo',
    'holo-round',
  ]),
  size: PropTypes.oneOf(['big']),
  loading: PropTypes.bool,
};

export default Button;
