import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import messagesNext from '../../../constants/events/intlNext';
import Button from '../../Button';
import './PhoneSkip.css';

/**
 * Messages for `PhoneSkip` component
 * @type {Object}
 */
const messages = {
  text: messagesNext.placeholder,
  textButton: messagesNext.placeholderButton,
  textButtonLast: messagesNext.placeholderPenultimate,
};

/**
 * "Next event" button for preview
 * @param {Object} $
 * @param {String} $.theme - theme of the button (`"bound"` or `"separate"`, first is default)
 * @param {Object|String} $.to - URL opened after button clicked (will be passed to `react-router-dom::history.push`)
 * @param {String} $.text - button text
 * @param {Boolean} $.requiresDefaultText - if default text must be displayed
 * @param {Boolean} $.isPenultimate - if next event is last, if `true` - text will be changed to "Finish tour"
 */
export default injectIntl(function PhoneSkip({
  theme = 'bound',
  to,
  text,
  requiresDefaultText = false,
  intl: { formatMessage },
  hasNextRoutePoint,
  isPenultimate,
}) {
  const history = useHistory();

  /**
   * Button was clicked
   * Will redirect to the `to` URL
   */
  const onClicked = () => {
    history.push(to);
  };

  const renderText = () => {
    if (isPenultimate) return formatMessage(messages.textButtonLast);
    else if (text && !requiresDefaultText) return text;
    else if (hasNextRoutePoint) return formatMessage(messages.text);
    return formatMessage(messages.textButton);
  };

  return (
    <Button
      className={classNames('PhoneSkip', `PhoneSkip--${theme}`)}
      theme={theme === 'bound' ? 'holo-round' : 'holo'}
      onClick={onClicked}
    >
      {renderText()}
    </Button>
  );
});
