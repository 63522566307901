import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TableFilterInfo from '../TableFilterInfo';
import TableFilterButton from '../TableFilterButton';

/**
 * Performs `Table` rows filtering with option as query
 * @param {String} id - id of the column to perform search on
 * @param {Array[String]} value - searching options
 * @param {Array[TableRowData]} rows - rows to filter
 * @returns {Array[TableRowData]} - filtered rows
 */
export function filterOptions(id, value, rows) {
  if (!Array.isArray(value)) {
    return rows;
  }

  return rows.filter((row) => {
    if (typeof row[id] === 'object') {
      const { content, raw } = row[id];
      return [raw, content].reduce(
        (acc, c) => (!acc && typeof c === 'string' ? value.includes(c) : acc),
        false
      );
    }
  });
}

/**
 * Info badge for displaying in `Table::withFilters()` options panel
 * @param {Object} $
 * @param {Array[String]} $.value - `Table::TableFilterOptions` value
 */
export function TableFilterOptionsInfo({ value, ...badgeProps }) {
  return <TableFilterInfo {...badgeProps} label={value.join(', ')} />;
}

/**
 * Table options filter form - sets options to search
 * Has no inner state for `value` prop
 * @param {Object}
 * @param {Array[String]} $.value - selected options descriptions
 * @param {Array[String]} $.options - available options to chow checklist
 * @param {Function} $.onChanged - filter value was changed, calls with new filter value as an argument
 * @param {Function} $.onApplied - will be called after "Apply filter" button clicked
 */
export default function TableFilterOptions({
  value: valueExt,
  options = [],
  onChanged = () => {},
  onApplied = () => {},
}) {
  const value = Array.isArray(valueExt) ? valueExt : [];

  /**
   * One of the options was toggled
   * Updates saved `value` instance
   * Calls `onChanged`
   * @param {String} option - toggled option
   */
  const toggleOption = (option) => {
    const optionIndex = value.indexOf(option);
    if (optionIndex > -1) {
      const newValue = value
        .slice(0, optionIndex)
        .concat(value.splice(optionIndex + 1));
      onChanged(newValue.length ? newValue : null);
    } else {
      onChanged([...value, option]);
    }
  };

  return (
    <div>
      <List>
        {options.map((option) => (
          <ListItem key={option} button onClick={() => toggleOption(option)}>
            <Checkbox
              color="primary"
              edge="start"
              checked={value.includes(option)}
            />
            <ListItemText primary={option} />
          </ListItem>
        ))}
      </List>
      <TableFilterButton disabled={!value.length} onApplied={onApplied} />
    </div>
  );
}
