import findEvent from '../event/findEvent';

/**
 * Searches first bound to point event before given
 * @param {Object} event - event before which search will be performed
 * @param {Array[Object]} events - where to perform search
 * @param {Array[RoutePoint]} route - route points where to search bindings
 * @returns {Object} - indexes of found `routePoint` and `event` pair (if not found, both props will be `null`/`undefined`)
 */
export default function prevBoundEvent(event, events, route) {
  let prevBoundEventIndex = null;
  for (let i = 0; i < route.length; i++) {
    const boundEventIndex = findEvent(events, route[i].guid, true);
    if (boundEventIndex > event.order) {
      return {
        event: prevBoundEventIndex || -1,
        routePoint: i - 1,
      };
    }

    prevBoundEventIndex = boundEventIndex;
  }

  return prevBoundEventIndex
    ? { event: prevBoundEventIndex, routePoint: route.length - 1 }
    : { event: -1, routePoint: -1 };
}
