import {
  YA_METRIC_API_URL,
  YA_METRIC_METRIC,
  YA_METRIC_DIMENSIONS,
} from '../../constants/branding';

export const getYAMetricURL = (params) => {
  const { metricId, filter, startTs, endTs } = params;

  return `${YA_METRIC_API_URL}?id=${metricId}&metrics=${YA_METRIC_METRIC}&filters=${filter}&dimensions=${YA_METRIC_DIMENSIONS}&date1=${startTs}&date2=${endTs}`;
};
