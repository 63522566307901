import getEventContent from '../event/getEventContent';
import editEventContent from '../event/editEventContent';

/**
 * Converts quest props to use them in Studio
 * - copies `nextButton` prop to `prevButton` of the next event in the given quest
 * - moves `direction` content to the next event
 * !!IMPORTANT: modifies original `quest` object
 * @param {Object} quest - quest to process events from
 * @returns {Object} - processed quest
 */
export default function convertEventsNextReceived(quest) {
  const eventsAmount = quest.events ? quest.events.length : 0;
  for (let i = eventsAmount - 2; i > 0; i--) {
    // Copying button texts from `quest.events[].nextButton` to `quest.events[].prevButton`
    quest.events[i].prevButton = quest.events[i - 1].nextButton;
    // Moving `direction` and `stickers` event content type to next event
    ['directions', 'sticker'].forEach((contentType) => {
      quest.events[i].content = editEventContent(
        quest.events[i].content,
        contentType,
        () => getEventContent(contentType, quest.events[i - 1])
      ).filter((c) => !!c);
    });
  }

  // Removing last copied directions
  if (eventsAmount) {
    ['directions', 'sticker'].forEach((contentType) => {
      quest.events[0].content = quest.events[0].content.filter(
        (c) => c.contentType !== contentType
      );
    });
  }

  return quest;
}
