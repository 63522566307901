/**
 * Event content type
 * @typedef {String} EventContentType
 */

/**
 * General images in events
 * @type {EventContentType}
 */
export const EVENT_CONTENT_IMAGE = 'image';

/**
 * General audios in events
 * @type {EventContentType}
 */
export const EVENT_CONTENT_AUDIO = 'audio';

/**
 * Navigational helper images
 * @type {EventContentType}
 */
export const EVENT_CONTENT_STICKER = 'sticker';

/**
 * Images shown in store
 * @type {EventContentType}
 */
export const PRODUCT_IMAGE = 'imageStore';
