import React, { useState, useEffect, useRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import './withContentDescription.css';
import Textarea from '../../Textarea';

/**
 * JSS styles for `withContentDescription` mod
 * @type {Object}
 */
const useStyles = makeStyles((theme) => ({
  root: {},
  controls: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'start',
    gap: '5px',
    justifyContent: 'space-between',
    minHeight: '100%',
    marginLeft: '16px',
  },
  textarea: {
    width: '100%',
    minHeight: '40px',
    maxHeight: '80px',
    overflowY: 'auto',
    resize: 'none',
  },
}));

/**
 * Adds `material-ui::TextField` to the `DropzoneContent` element
 * @param {React::Component} Component - component to overload
 */
export default function withContentDescription(Component) {
  /**
   * `DropzoneContent` with `material-ui::TextField` on the right by default
   * @param {Object} $
   * @param {Object?} $.InputProps - props passed to child `material-ui::TextField` component
   * @param {String?} $.value - input value
   * @param {Function?} $.onChanged - will be called when user changed input value
   * @param {...Object} $.dropzoneContentProps - will be passed to `DropzoneContent` component
   */
  return function ({
    classes: { ...dropzoneContentClasses } = {},
    value,
    children,
    onChanged = () => {},
    InputProps = {},
    ...dropzoneContentProps
  }) {
    const styles = useStyles();
    const [individStepsAudio, setIndividStepsAudioAudio] = useState(value);

    useEffect(() => {
      setIndividStepsAudioAudio(value);
    }, [value]);

    const handleIndividStepsAudioChange = (e) => {
      const value = e.target.value;
      setIndividStepsAudioAudio(value);
      onChanged(e);
    };

    const getInputClasses = (isActive) => {
      let classes = 'WithContentDescription__input ';

      if (isActive) {
        classes += 'WithContentDescription__input_active';
      }

      return classes;
    };

    return (
      <Component
        className={styles.root}
        classes={dropzoneContentClasses}
        variant="row"
        {...dropzoneContentProps}
      >
        <div className={styles.controls}>
          <div style={{ position: 'relative', width: '100%' }}>
            <div className="WithContentDescription__inputContainer">
              <Textarea
                noneDefaultStyles
                placeholder=" "
                className={`${getInputClasses(individStepsAudio)} ${
                  styles.textarea
                }`}
                value={individStepsAudio}
                onChange={handleIndividStepsAudioChange}
                maxLength={500}
              />
              <label
                htmlFor="individStepsAudioInput"
                className="WithContentDescription__label"
              >
                <FormattedMessage
                  id="StudioCreateProduct.tourPhotosDescription"
                  defaultMessage="Description"
                />
              </label>
            </div>
          </div>
          {children}
        </div>
      </Component>
    );
  };
}
