export default function (url, { async = true, repeat = false } = {}) {
  const scripts = document.querySelectorAll(`script[src="${url}"]`);

  if (!repeat && scripts.length) {
    return;
  }

  const script = document.createElement('script');
  script.src = url;
  script.async = async;

  document.body.appendChild(script);

  const promise = new Promise((resolve, reject) => {
    script.onload = () => {
      resolve();
    };

    script.onerror = () => {
      reject();
    };
  });

  return promise;
}
