import React, { Fragment, useEffect, useState } from 'react';
import keys from 'lodash/keys';
import merge from 'lodash/merge';
import { icon as imgIcon, divIcon } from 'leaflet';
import { Marker, Circle } from 'react-leaflet';
import './MapPin.css';

/**
 * Assets imports
 */
import svgMarkerIcon from '../images/marker-icon.svg';
import svgMarkerIconRed from '../images/marker-icon-red.svg';
import pngShadowIcon from '../images/marker-shadow.png';

const DEFAULT_ICON = {
  iconUrl: svgMarkerIcon,
  shadowUrl: pngShadowIcon,
  size: { medium: [25, 41], big: [38, 60] },
};

/**
 * Hash map for `MapPin` themes
 * Theme named `default` is basic and can be overloaded by other ones
 * @type {Object}
 */
const THEME = {
  default: {
    icon: DEFAULT_ICON,
    circle: { color: '#3388ff' },
    label: {
      left: '50%',
      top: '32%',
      width: '25px',
      height: '25px',
    },
  },
  invisible: {
    circle: { color: 'transparent' },
  },
  red: {
    icon: { ...DEFAULT_ICON, iconUrl: svgMarkerIconRed },
    circle: { color: '#ed2213' },
  },
};

/**
 * Default icon size
 * @type {String}
 */
const DEFAULT_ICON_SIZE = 'medium';

/**
 * Gets `MapPin` full theme object
 * Merges theme `themeName` and `default` one
 * @param {String} themeName - `MapPin` theme name
 * @returns {Object}
 */
export function getTheme(themeName) {
  return merge({}, THEME.default, THEME[themeName]);
}

/**
 * Gets `MapPin` marker icon size
 * @param {String} size - size of the icon
 * @param {String?} themeName - icon theme
 */
export function getIconSize(size = DEFAULT_ICON_SIZE, themeName) {
  const theme = merge({}, THEME.default, THEME[themeName]);
  return theme.icon.size[size];
}

/**
 * Constructs marker icon from image and extra div layer to add label if required
 * @param {Object} theme - theme object (if not `"default"` - should be merged with it as in `MapPin` component)
 * @param {String?} size - size of the icon (`"big"` available)
 * @param {Object|String} icon - icon description from `THEME[].icon` or URL
 * @param {Object?} labelPos - overload for `THEME[].label` object, position and sizes of the label frame
 * @param {String} text - text on the label
 */
function constructIcon(
  theme,
  size = DEFAULT_ICON_SIZE,
  iconUrl,
  labelPos = {},
  text
) {
  const label = merge({}, theme.label, labelPos);
  const icon = iconUrl ? { iconUrl } : theme.icon;

  icon.iconSize = [...icon.size[size]];
  if (!icon.iconAnchor) {
    icon.iconAnchor = [...icon.iconSize];
    icon.iconAnchor[0] /= 2;
  }

  if (text !== undefined) {
    const iconSize = `width:${icon.iconSize[0]}px;height:${icon.iconSize[1]}px;`;
    return divIcon({
      className: 'MapPin__leaflet',
      html:
        `<div class="MapPin__icon" style="${iconSize}">` +
        `<img style="${iconSize}" draggable="false" src="${icon.iconUrl}" />` +
        `<div class="MapPin__label" style="${keys(label).reduce(
          (acc, p) => `${acc}${p}:${label[p]};`,
          ''
        )}">${text}</div>` +
        `</div>`,
    });
  }
  return imgIcon(icon);
}

/**
 * Pin on the map
 * @param {Object} $
 * @param {String} $.theme - CSS theme of the pin (`"red"`)
 * @param {String?} $.size - size of the marker (`"big"` available), will be taken from theme
 * @param {String?} $.icon - URL of the icon to use as a pin
 * @param {String?} $.label - pin text (available in `"material"` themes)
 * @param {Object?} $.circleProps - props will be passed to `MapPin::Circle`
 * @param {Object} $.coords - `lat` and `lon` coords of the pin
 * @param {Number} $.radius - radius of the circle around the pin
 * @param {React::Ref} $.markerRef - react reference to the `ReactLeaflet::Marker` component
 * @param {React::JSX?} $.outer - another `react-leaflet` on-map components which will be installed as siblings of `MapPin`
 * @param {Boolean} $.draggable - allows drag and drop on this marker (`false` by default)
 * @param {Function?} $.onClick - `onClick` prop for `ReactLeaflet::Marker` component
 * @param {Function?} $.onDragStart - dragging process started
 * @param {Function?} $.onDrag - fires while dragging process is continuing
 * @param {Function?} $.onDragEnd - marker was dropped (dragging process stopped)
 */
export default function MapPin({
  coords,
  label,
  radius,
  size,
  icon: iconSrc,
  theme: themeName,
  children,
  markerRef,
  outer = null,
  circleProps = {},
  draggable = false,
  onClick = () => {},
  onDragStart = () => {},
  onDrag = () => {},
  onDragEnd = () => {},
}) {
  const theme = getTheme(themeName);
  const hasCircle = typeof radius === 'number';

  /**
   * Leaflet marker icon implementation
   * Updates in useEffect below only if one of related to icon params changed
   * @type {Object}
   */
  const [icon, setIcon] = useState(
    constructIcon(theme, size, iconSrc, {}, label)
  );
  useEffect(() => {
    setIcon(constructIcon(theme, size, iconSrc, {}, label));
  }, [themeName, iconSrc, label, size]);

  return (
    <Fragment>
      <Marker
        {...(markerRef ? { ref: markerRef } : {})}
        position={coords}
        icon={icon}
        draggable={draggable}
        onDragStart={onDragStart}
        onDrag={onDrag}
        onDragEnd={onDragEnd}
        onClick={onClick}
      >
        {children}
      </Marker>
      {hasCircle ? (
        <Circle
          center={coords}
          radius={radius}
          weight={1}
          {...theme.circle}
          {...circleProps}
        />
      ) : null}
      {outer || null}
    </Fragment>
  );
}
