import React, { useState, useEffect, Children } from 'react';
import classNames from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import ReactDropzone from 'react-dropzone';
import Typography from '@material-ui/core/Typography';

/**
 * Amount of milliseconds while error in dropzone will be shown
 * @type {Number}
 */
const ERROR_DISPLAYING_TIME = 5000;

/**
 * Margins between dropzone content (deployed files) in `px`
 * @type {Object}
 */
export const DROPZONE_CONTENT_MARGINS = {
  top: 10,
  right: 10,
};

/**
 * Messages for `Dropzone` component
 * @type {Object}
 */
const messages = defineMessages({
  errorSize: {
    id: 'Dropzone.errorSize',
    defaultMessage: 'File size must not exceed {maxSize}MB',
  },
  errorAmount: {
    id: 'Dropzone.errorAmount',
    defaultMessage:
      'No more than {amount, plural, =1 {one attached file} other {{amount} attached files}}',
  },
  errorType: {
    id: 'Dropzone.errorType',
    defaultMessage: 'Wrong file type',
  },
});

/**
 * JSS styles for `Dropzone` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  root: {
    border: ({ disabled }) =>
      `1px solid ${
        disabled === true ? '#1c1c1c' : theme.palette.primary.light
      }`,
    borderRadius: '4px',
    padding: '0 16px',
    background: ({ disabled }) =>
      disabled === true ? `#e0e0e0` : theme.palette.primary.background,
    cursor: 'pointer',
    transition: 'background 0.3s ease',
    '&:hover .Dropzone__info': {
      color: ({ disabled }) =>
        disabled === true ? `#1c1c1c` : theme.palette.primary.main,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  rootContent: {
    '& .Dropzone__icon': {
      display: 'none',
    },
  },
  rootError: {
    borderColor: theme.palette.error.dark,
    background: () => theme.palette.error.background,
    '& .Dropzone__info': {
      color: `${theme.palette.error.dark} !important`,
    },
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px 0',
    whiteSpace: 'pre-wrap',
    textAlign: ({ withImage }) => (withImage ? '' : 'center'),
    transition: 'color 0.3s ease',
    [theme.breakpoints.up('sm')]: {
      flexDirection: ({ withImage }) => (withImage ? 'row' : 'column'),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  iconContainer: {
    display: 'flex',
    marginBottom: '7px',
    fontSize: '24px',
  },
  icon: {
    color: ({ disabled }) => disabled === true && `#1c1c1c`,
    transition: 'color 0.3s ease',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: `-${DROPZONE_CONTENT_MARGINS.right}px`,
  },
  contentFilled: {
    paddingTop: '5px',
  },
  image: {
    width: 100,
    marginLeft: 30,
    marginRight: 50,
    borderRadius: '50%',
  },
}));

/**
 * Dropzone component
 * @param {Object?} $.classes - CSS classes for child elements (`"content"`)
 * @param {String} $.className - additional CSS class
 * @param {String} $.accepts - selectors of file, accepting by dropzone in format type/extension (e.g. "image/png, video/*")
 * @param {String?} $.title - title displayed in the dropzone
 * @param {String?} $.subtitle - subtitle displayed in the dropzone
 * @param {String?} $.error - error to be displayed in the dropzone
 * @param {Number?} $.errorTimeout - timeout while error will be shown (default is `ERROR_DISPLAYING_TIME`)
 * @param {Number?} $.maxSize - max one file size (default is `Infinity`)
 * @param {React::Component?} $.icon - material-ui icon in the dropzone (not an instance)
 * @param {Array[DropzoneItem]} $.children - content to be rendered in the dropzone
 * @param {React::Ref} $.contentRef - ref to the content displaying field
 * @param {Function} $.onDropAccepted - calls when new files appeared in the dropzone
 * @param {Function} $.onErrorReset - calls after `errorTimeout`, external errors (from `error` prop) must be cleared here
 * @param {Boolean} $.withImage - true if should pass image source to implement image in dropzone
 * @param {String} $.image - image source (if withImage is true)
 */
function Dropzone({
  classes = {},
  className,
  icon: Icon,
  title,
  subtitle,
  error: externalError,
  errorTimeout = ERROR_DISPLAYING_TIME,
  maxAmount,
  currentAmount,
  maxSize = Infinity,
  accepts,
  children,
  onDropAccepted: onDroppedExternal = () => {},
  onErrorReset = () => {},
  intl: { formatMessage },
  disabled,
  withImage = false,
  image = '',
}) {
  /**
   * Current displaying error text
   * @type {String}
   */
  const [error, setError] = useState(null);
  const [errorTimerId, setErrorTimerId] = useState(null);

  const styles = useStyles({ disabled, withImage });

  /**
   * Files was dropped into dropzone and passed all `react-dropzone` internal checks
   * Filters files by size, display "size exceeded" error if occurred
   * @param {Array[File]} files
   */
  const onDropAccepted = (files) => {
    let occurredError = null;
    const acceptedFiles = files
      /** Size check */
      .filter((file) => {
        const isValid = file.size <= maxSize;
        if (!isValid)
          occurredError = formatMessage(messages.errorSize, {
            maxSize: Math.round(maxSize / (1024 * 1024)),
          });
        return isValid;
      });

    /** Files amount check */
    if (typeof maxAmount === 'number' && typeof currentAmount === 'number') {
      const excessesAmount = currentAmount + acceptedFiles.length - maxAmount;

      if (excessesAmount > 0) {
        const toRemoveUnsafe = acceptedFiles.length - excessesAmount;
        const toRemove = toRemoveUnsafe > 0 ? toRemoveUnsafe : 0;
        const removeAmount = acceptedFiles.length - toRemove;
        acceptedFiles.splice(toRemove, removeAmount);
        occurredError = formatMessage(messages.errorAmount, {
          amount: maxAmount,
        });
      }
    }

    /** File type check */
    if (occurredError === null && !acceptedFiles.length) {
      occurredError = formatMessage(messages.errorType);
    }

    if (occurredError) setError(occurredError);

    if (acceptedFiles.length) {
      onDroppedExternal(acceptedFiles);
    }
  };

  /**
   * Sets timer for resetting error
   * Clears previous timer if it was set
   */
  useEffect(() => {
    if (typeof errorTimerId === 'number') {
      clearTimeout(errorTimerId);
      setErrorTimerId(null);
    }

    if (!error) return;

    if (disabled) {
      setErrorTimerId(
        setTimeout(() => {
          onErrorReset();
          setError(null);
        }, errorTimeout)
      );
    }
  }, [error, disabled]);
  /**
   * Syncronyzes `externalError` from props ans inner `error`
   */
  useEffect(() => setError(externalError), [externalError]);

  const hasChildren = Children.count(children);

  const DropzoneImage = withImage ? (
    <img className={styles.image} src={image} alt="dropzone_img" />
  ) : null;

  const DropzoneIcon = Icon ? (
    <div className={classNames(styles.iconContainer, 'Dropzone__icon')}>
      <Icon
        className={styles.icon}
        color={error ? 'error' : 'primary'}
        fontSize="inherit"
      />
    </div>
  ) : null;

  return (
    <ReactDropzone
      disabled={disabled || error}
      accept={accepts}
      onDropAccepted={onDropAccepted}
    >
      {(dropzone) => (
        <div
          {...dropzone.getRootProps()}
          className={classNames(
            styles.root,
            hasChildren && styles.rootContent,
            error && styles.rootError,
            className
          )}
        >
          <input {...dropzone.getInputProps()} />
          <div
            className={classNames(
              styles.content,
              hasChildren && styles.contentFilled,
              classes.content
            )}
          >
            {children}
          </div>
          <div className={classNames('Dropzone__info', styles.info)}>
            {DropzoneImage || DropzoneIcon}
            <div>
              <Typography variant="h6">{error || title}</Typography>
              <Typography variant="subtitle1">{subtitle}</Typography>
            </div>
          </div>
        </div>
      )}
    </ReactDropzone>
  );
}

export default injectIntl(Dropzone);
