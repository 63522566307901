import React from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

/**
 * Paddings for the table filter component in `px`
 * @type {Array[Number]}
 */
export const FILTER_PADDING = [16, 16];

/**
 * JSS styles for `TableFilter` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  header: {
    padding: '4px 8px 4px 16px',
    background: theme.palette.primary.main,
    color: theme.palette.background.main,
  },
  title: {
    alignItems: 'center',
  },
  close: {
    marginLeft: '24px',
    padding: '8px',
    color: 'white',
  },
  contentPadding: {
    padding: '16px',
  },
}));

/**
 * Container for `TableFilter...` component
 * @param {Object} $
 * @param {String|React::JSX} $.title - name of the filter
 * @param {React::JSX} $.children - filter controls
 * @param {Function?} $.onClosed - filter window was closed
 */
export default function TableFilter({ title, children, onClosed = () => {} }) {
  const styles = useStyles();
  return (
    <div>
      <div className={classNames('flex', styles.header)}>
        <Typography
          className={classNames('flex', 'grow', styles.title)}
          variant="subtitle2"
        >
          {title}
        </Typography>
        <IconButton className={styles.close} onClick={onClosed}>
          <CloseIcon />
        </IconButton>
      </div>
      {children ? <div className={styles.content}>{children}</div> : null}
    </div>
  );
}
