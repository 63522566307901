import { takeEvery, put, call } from 'redux-saga/effects';
import Api from '../functions/Api';
import * as types from '../constants/ActionTypes';

export function* fetchLanguages(action) {
  try {
    const { data } = yield call(Api.get, 'v2/languages/');

    yield put({
      type: types.FETCH_LANGUAGES_SUCCESS,
      payload: data,
    });
    yield put({
      type: types.ADD_LANGUAGES_FOR_USER_STATE,
      data,
    });
    yield put({
      type: types.RECEIVE_LANGUAGES,
      data,
    });
  } catch (error) {
    yield put({
      type: types.FETCH_LANGUAGES_FAILURE,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export default function* watchLanguages() {
  yield takeEvery(types.FETCH_LANGUAGES, fetchLanguages);
}
