import React, { Component } from 'react';
import classNames from 'classnames';
import { injectIntl, defineMessages } from 'react-intl';
import styled from 'styled-components';
import QuestDuplicate from '../QuestDuplicate';
import { DuplicateIcon } from '../Icon';
import { QuestType } from '../../constants/PropTypes';
import Button from '../Button';

const messages = defineMessages({
  copying: {
    id: 'QuestShare.copying',
    defaultMessage: 'Copying...',
  },
  makeCopy: {
    id: 'QuestShare.makeCopy',
    defaultMessage: 'Make a copy of the tour',
  },
});

// TODO: make it reuseable
const IconButton = styled(Button)`
  padding: 2.5px 4px;
`;

class QuestDuplicateButton extends Component {
  render() {
    const {
      quest,
      intl: { formatMessage },
      mod,
      ...other
    } = this.props;

    if (!quest) {
      return null;
    }

    return (
      <QuestDuplicate
        quest={quest}
        render={({ onCopy, isCopying }) => (
          <IconButton
            mod={mod}
            theme="main-light"
            className={classNames(isCopying && 'button--fetching')}
            title={formatMessage(
              isCopying ? messages.copying : messages.makeCopy
            )}
            readOnly={isCopying}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (isCopying) return;
              onCopy();
            }}
          >
            <DuplicateIcon />
          </IconButton>
        )}
        {...other}
      />
    );
  }
}

QuestDuplicateButton.propTypes = {
  quest: QuestType.isRequired,
};

export default injectIntl(QuestDuplicateButton);
