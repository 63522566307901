/**
 * Flips given image
 * @param {Element} img - DOM image element
 * @param {Number} direction - angle of flipping direction in degrees (flips on the line through center and to specified direction)
 * @param {Object?} options - extra options
 * @param {String?} options.type - image file type to encode `base64` string (default is `"image/png"`)
 * @returns {Promise<Object>} - resolves with object with `base64` prop with base64 of the new string
 */
export default function flip(img, direction, { type = 'image/png' } = {}) {
  const angle = direction < 0 ? 180 + (direction % 180) : direction % 180;

  return new Promise(function (resolve, reject) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const [imgWidth, imgHeight] = [img.naturalWidth, img.naturalHeight];

    canvas.width = imgWidth;
    canvas.height = imgHeight;

    const space = [0, 90].map((deg) => (deg === angle ? -1 : 1));
    ctx.translate(
      ...[imgWidth, imgHeight].map((d, i) => (space[i] === -1 ? d : 0))
    );
    ctx.scale(...space);
    ctx.drawImage(img, 0, 0);

    resolve({ base64: canvas.toDataURL(type) });
  });
}
