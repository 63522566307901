import React from 'react';
import './withClose.css';

/**
 * Adds close button to the `Popup`
 * @param {React::Component} Component - `Popup` component to overload
 */
export default function withClose(Component) {
  /**
   * `Popup` with close button
   * @param {Object} $
   * @param {Boolean} $.closable - if close button visible
   * @param {...popupProps} $.popupProps - props passed to `Popup`
   */
  return function ({
    closable = true,
    handleCloseClick = () => {},
    ...popupProps
  }) {
    return (
      <Component
        close={
          closable ? (
            <button className="Popup__close" onClick={handleCloseClick} />
          ) : null
        }
        handleCloseClick={handleCloseClick}
        {...popupProps}
      />
    );
  };
}
