export default function (pathname, paths) {
  const params = {};
  for (let i = 0; i < paths.length; i++) {
    const path = paths[i];
    const res = path[0].exec(pathname);
    if (!res) continue;
    path[1].forEach((paramName, i) => (params[paramName] = res[i + 1]));
    return params;
  }
  return {};
}
