import { TILESET_ID_EN, TILESET_ID_RU } from '../../constants/map/mapbox';

/**
 * Generates id of the tile set to append in MapBox URL for loading tiles
 * @param {String} locale - code of the locale
 * @returns {String} - id of the tile set
 */
export default function getTileSetId(locale) {
  switch (locale) {
    case 'ru':
      return TILESET_ID_RU;
    default:
      return TILESET_ID_EN;
  }
}
