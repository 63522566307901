import get from 'lodash/get';
import formURL from '../URL/formURL';
import Api from '../Api';
import rowsFromHeader from './rowFromHeader';

/**
 * Downloads full payments table data for exporting
 * Pass this with bound params to `Table::withExport::props::preExport`
 * @param {String} url - api where to request downloading table
 * @param {Object} query - additional query params for url
 * @param {Number} rowsAmount - amount of the rows in full table (this amount will be downloaded)
 * @param {TableHeaderData} header - current existing table header description
 * @param {Function} getData - function passed to `rowsFromHeader` (for constructing table from downloaded data)
 * @param {Function} onError - will be called with `Error` object if error occurred
 * @returns {Array|Null} - null, if error occurred, otherwise array with `TableHeaderData` and `Array[TableRowData]` as elements
 */
export default async function downloadTablePreExport(
  url,
  query,
  rowsAmount,
  header,
  getData,
  onError = () => {}
) {
  let rows;
  try {
    rows = get(
      await Api.get(
        formURL({
          pathname: url,
          query: { ...query, per_page: rowsAmount, page: 1 },
        })
      ),
      'data.results'
    );
  } catch (error) {
    onError(error);
  }

  return Array.isArray(rows)
    ? [header, rowsFromHeader(header, rows, getData, true)]
    : null;
}
