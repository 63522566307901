import { takeEvery, put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as types from '../constants/ActionTypes';
import Api from '../functions/Api';

export function* fetchQuestCodes(action) {
  try {
    const { id } = action;
    const { data: payload } = yield call(Api.get, `quests/${id}/accesses/`);
    yield put({
      type: types.FETCH_CODES_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({
      type: types.FETCH_CODES_FAILURE,
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* createQuestCodes(action) {
  try {
    const {
      id,
      payload: { maxPlayers, count = 1 },
    } = action;
    const { data: payload } = yield call(Api.put, `quests/${id}/accesses/`, {
      data: {
        maxPlayers,
        count,
      },
    });

    yield put({
      type: types.CREATE_CODES_SUCCESS,
      payload,
    });
    yield put({
      type: types.REQUEST_USER,
    });
    yield addRecentForTime(payload.map((item) => item.accessCode));
  } catch (error) {
    yield put({
      type: types.CREATE_CODES_FAILURE,
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* updateAccessCode(action) {
  try {
    const {
      id,
      payload: { maxPlayers },
    } = action;
    const { data: payload } = yield call(Api.put, `accesses/${id}/`, {
      data: {
        maxPlayers,
      },
    });

    yield call(delay, 500);
    yield put({
      type: types.UPDATE_CODE_SUCCESS,
      payload,
    });
    yield put({
      type: types.REQUEST_USER,
    }); // TO update balance. TODO: divide client info from profile
    yield addRecentForTime([payload.accessCode]);
  } catch (error) {
    yield put({
      type: types.UPDATE_CODE_FAILURE,
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* addRecentForTime(payload, time = 4000) {
  yield put({
    type: types.ADD_RECENT_CODES,
    payload,
  });
  yield call(delay, time);
  yield put({
    type: types.REMOVE_RECENT_CODES,
    payload,
  });
}

export default function* watchCodes() {
  yield takeEvery(types.FETCH_CODES, fetchQuestCodes);
  yield takeEvery(types.CREATE_CODES, createQuestCodes);
  yield takeEvery(types.UPDATE_CODE, updateAccessCode);
}
