import React from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { defineMessages, injectIntl } from 'react-intl';
import InputLabel from '@material-ui/core/InputLabel';
import TextForm from '../../TextForm';
import withFieldExtension from '../../TextForm/withFieldsExtension';
import Event from '../../Event';
import EventHeader from '../../Event/EventHeader';

/**
 * Text form with extendable amount of fields
 * @type {React::Component}
 */
const TextFormExt = withFieldExtension(TextForm);

/**
 * Max amount of text hints
 * @type {Number}
 */
const MAX_TEXT_HINTS = 5;

/**
 * Max amount of symbols in one hint
 * @type {Number}
 */
const MAX_HINT_LENGTH = 100;

/**
 * Messages for `EventControlsDestinations` component
 * @type {Object}
 */
const messages = defineMessages({
  title: {
    id: 'EventControlsDestinations.title',
    defaultMessage: 'Text hints - {amount} maximum',
  },
  label: {
    id: 'EventControlsDestinations.fieldLabel',
    defaultMessage:
      '{count, plural, =1 {First} =2 {Second} =3 {Third} =4 {Forth} =5 {Fifth} other {count}} hint',
  },
  placeholder: {
    id: 'EventControlsDestinations.fieldPlaceholder',
    defaultMessage: 'Hint text',
  },
});

/**
 * JSS styles for `EventControlsDestinations` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  fieldLabel: {
    width: '100%',
    fontSize: '12px',
  },
  fieldInput: {
    padding: '7px 0 10px',
  },
}));

/**
 * Event text nav hints (content type `"destinations"`) editor
 * @param {Object} $
 * @param {Number|*} $.questId - id of the quest where to edit text nav hints
 * @param {String} $.eventId - id of the event related to quest with `questId` and where text nav hints will be edited
 * @param {Array[String]} $.hints - available nav hints (mutations does not effects form values, but must be actual for verification)
 * @param {Number?} $.max - maximal amount of the text nav hints
 * @param {Number?} $.maxHintLength - maximal character amount in one nav hint
 * @param {Function?} $.onChanged - calls when nav hints were changed
 */
function EventControlsDestinations({
  questId,
  eventId,
  max = MAX_TEXT_HINTS,
  maxHintLength = MAX_HINT_LENGTH,
  hints = [],
  onChanged: onChangedExt = () => {},
  intl: { formatMessage },
}) {
  const styles = useStyles();

  /**
   * Adds `InputLabel` to field in the `TextForm` component
   * @type {TextFormOverload}
   */
  const getFormFieldProps = (
    prevFieldProps,
    extendFieldProps,
    { index, values }
  ) => {
    return {
      InputProps: { classes: { input: styles.fieldInput } },
      helperText: `${get(values, `${index}.text.length`, 0)}/${maxHintLength}`,
      startGap: (
        <InputLabel className={styles.fieldLabel}>
          {formatMessage(messages.label, { count: index + 1 })}
        </InputLabel>
      ),
    };
  };

  /**
   * Triggers `onChanged` callback from props if form was changed
   * @param {Array[String]} dataUnsafe - updated `hints[]` prop
   */
  const onChanged = (dataUnsafe) => {
    const data = (Array.isArray(dataUnsafe) ? dataUnsafe : []).filter((v) => v);
    if (!isEqual(data, hints)) {
      onChangedExt({ questId, eventId, data });
    }
  };

  /**
   * One of form fields was removed
   * @param {Number} index - index of the removed field
   * @param {Array[String]} newData - last dump of the values (before removing), element must be removed manually
   */
  const onFieldRemoved = (index, newData) => {
    newData.splice(index, 1);
    onChanged(newData);
  };

  /**
   * Form lost focus
   * @param {BlurEvent} e - one of `TextForm` inputs was unfocused
   * @param {Array[String]} newData - array with updated values from all forms
   */
  const onBlurred = (e, newData) => {
    onChanged(newData);
  };

  return (
    <Event>
      <EventHeader variant="sub">
        {formatMessage(messages.title, { amount: max })}
      </EventHeader>
      <TextFormExt
        initialValues={hints}
        fieldProps={{
          extend: getFormFieldProps,
          placeholder: formatMessage(messages.placeholder),
          inputProps: { maxLength: maxHintLength },
        }}
        min={1}
        max={max}
        onFieldRemoved={onFieldRemoved}
        onBlurred={onBlurred}
      />
    </Event>
  );
}

export default injectIntl(EventControlsDestinations);
