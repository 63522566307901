import CONTENT_ENDPOINTS from '../../constants/upload/contentUploadEndpoints';

/**
 * Description of the endpoint to upload data
 * @typedef {Object} UploadEndpoint
 * @property {UploadEndpointType} type - type of the endpoint
 * @property {String} method - HTTP method to use for uploading
 * @property {String} url - endpoint URL
 */

/**
 * Forms description of endpoint where event content should be uploaded
 *   from given type
 * @param {EventContentType} type - type of the content to upload
 * @param {Object} params - will be given to function forms uploading URL
 * @return {UploadEndpoint} - description of the endpoint to upload image
 */
export default function formContentUploadEndpoint(type, params) {
  const { formUrl, ...endpointDescription } = CONTENT_ENDPOINTS[type];
  return { type, url: formUrl(params), ...endpointDescription };
}
