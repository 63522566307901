import {
  FETCH_LANGUAGES,
  FETCH_LANGUAGES_SUCCESS,
  FETCH_LANGUAGES_FAILURE,
} from '../constants/ActionTypes';

export default function languages(
  state = {
    isFetching: false,
    items: [],
  },
  action
) {
  switch (action.type) {
    case FETCH_LANGUAGES:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_LANGUAGES_SUCCESS:
      return {
        ...state,
        items: action.payload,
        isFetching: false,
      };

    case FETCH_LANGUAGES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
}
