import { useRef, useEffect } from 'react';
import { latLngBounds } from 'leaflet';

/**
 * Will fit all `points` on `leafletMap` to screen when `leafletMap` was added or when `trigger` became truthy
 * @param {Object} $
 * @param {Object} $.leafletMap - instance of `Map` component from `leaflet`
 * @param {Object?} $.fitOptions - will be passed to `leaflet::Map::fitBounds()` as the second argument
 * @param {Array[LatLng]} $.points - geographical coords of the points to be fit on screen
 * @param {Boolean?} $.disableOnStart - when becomes `true`, fitting process will not be performed during component lifecycle, mutable while fitting process was not triggerred (e.g. while `trigger` prop is `false`)
 * @param {Boolean?} $.trigger - when becomes `true` fitting process will be performed (by default - `true`, points will be fit when component mounted in DOM)
 */
export default function usePanOnMount({
  disableOnStart = false,
  leafletMap,
  points = [],
  trigger = true,
  fitOptions = {},
}) {
  /**
   * If
   */
  const wasTriggered = useRef(!!disableOnStart);

  /**
   * Disable fitting process if it was not performed before
   */
  useEffect(() => {
    if (!wasTriggered.current) {
      wasTriggered.current = !!disableOnStart;
    }
  }, [disableOnStart]);

  /**
   * Perform fitting process if was not before
   */
  useEffect(() => {
    const bounds = latLngBounds(points);

    if (!wasTriggered.current && leafletMap && points.length && trigger) {
      wasTriggered.current = true;
      leafletMap.fitBounds(bounds, fitOptions);
    }
  }, [leafletMap, trigger]);
}
