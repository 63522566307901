/**
 * Checks, that user is author or not
 * @param  {Object} $ - user data
 * @param {Boolean} isPartner - true if user passed 2-rd step of authors onboarding
 * @param {Boolean} isSales - true if user passed 2-rd step of authors onboarding
 * @returns {Boolean} is user author or not
 */
export function isUserAuthor({ isPartner }) {
  if (isPartner) return true;
  return false;
}

/**
 * Checks, that user is partner or not
 * @param  {Object} $ - user data
 * @param {Boolean} isPartner - should be `true`
 * @param {Boolean} isSales - should be `false`
 * @returns {Boolean} is user partner or not
 */
export function isUserPartner({ isSales }) {
  if (isSales) return true;
  return false;
}

export const checkAuthorInited = (user, { data: partnerData }) => {
  return [
    user.isLogged,
    // user.phone,
    partnerData.initialTourTitle,
    partnerData.initialTourDescription,
    partnerData.initialTourLanguage,
  ].every(Boolean);
};

export const checkAuthor = (user) => {
  return [
    user.isLogged,
    user.firstName,
    user.lastName,
    user.isPartner,
    // user.phone, Not included to not to redirect users if errors with phone handling
    // partnerData.bio,
  ].every(Boolean);
};

export const checkPartner = (user) => {
  return [user.isLogged, user.isPartner].every(Boolean);
};
