import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import {
  FormattedMessage,
  defineMessages,
  intlShape,
  injectIntl,
} from 'react-intl';
import DEFAULT_CHOICE from '../../../constants/defaultContent/choice';
import debounce from '../../../functions/debounce';
import Checkbox from '../../Checkbox';
import InputWithButtons from '../../InputWithButtons';
import Event from '../../Event';
import EventHeader from '../../Event/EventHeader';
import '../../Input/Input.css';
import './EventControlsChoice.css';

const defaultState = {
  options: ['', '', ''], // or three
  answer: 0,
  visible: false,
};

const messages = defineMessages({
  oneChoicePlaceholder: {
    id: 'EventControlsChoice.oneChoicePlaceholder',
    defaultMessage: 'answer',
  },
  variousChoicePlaceholderOne: {
    id: 'EventControlsChoice.variousChoicePlaceholderOne',
    defaultMessage: 'The correct answer option',
  },
  variousChoicePlaceholderMore: {
    id: 'EventControlsChoice.variousChoicePlaceholderMore',
    defaultMessage: 'The correct answer options',
  },
});

/**
 * Multiple and open-ended quiz
 * @param {Object} $
 * @param {String} $.eventId - id of the event where quiz will pe pasted
 * @param {Object} $.params - object with `eventId` and `questId` props
 * @param {Array[Object]} $.options - options of the quiz from event description
 * @param {Number} $.answer - index of the correct option
 */
class EventControlsChoice extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ...defaultState,
    };
    this.handleSaveDebounce = debounce(this.handleChange, 500);
  }

  componentDidMount() {
    this.makeState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // TODO: fix syncing actions
    // TODO: if forceUpdateNeeded
    if (isEqual(nextProps, this.props)) return;

    /* if (
      this.props.opened &&
      !nextProps.opened &&
      this.props.params.eventId === nextProps.params.eventId
    ) {
      this.handleOff();
    } */

    if (nextProps.params.eventId !== this.props.params.eventId) {
      this.makeState(nextProps);
    }
  }

  makeState(props) {
    const { answer, options, visible } = props;
    if (!visible) {
      this.setState((prevState) => {
        return {
          ...prevState,
          ...defaultState,
        };
      });
      return;
    }
    const newState = {
      answer,
      options,
      visible,
    };
    this.setState((prevState) => {
      return {
        ...prevState,
        ...newState,
      };
    });
  }

  handleChange = (props) => {
    const { params } = this.props;
    const choiceProps = props;
    let newChoiceProps = { ...this.state, ...choiceProps };
    let { visible } = newChoiceProps;
    if (!this.state.visible) {
      visible = Boolean(
        newChoiceProps.options.length &&
          newChoiceProps.options.some((opt) => opt.length > 0)
      );
    }
    newChoiceProps = { ...newChoiceProps, visible };
    this.props.onChange({ ...newChoiceProps, ...params }); // TODO: why props backward
  };

  handleChangeOption(options, index, e) {
    const text = e.target.value;
    const { params } = this.props;
    const newOptions = options.map((opt, i) => {
      return i === index ? text : opt;
    });
    this.setState((prevState) => {
      return {
        ...prevState,
        options: newOptions,
      };
    });
    this.handleSaveDebounce({ options: newOptions, params });
  }

  // handleBlur = () => {
  //   let { options } = this.state;
  //   console.log('blur')
  //   console.log(this.state.options);

  //   let { params } = this.props;
  //   this.handleChange({ options, params });
  // }

  handleAddOption(opt = '') {
    const { options: currentOptions } = this.state;

    this.setState((prevState) => {
      return {
        ...prevState,
        options: [...currentOptions, opt],
      };
    });
    this.handleChange({ options: [...currentOptions, opt] });
  }

  handleRemoveOption(options, index) {
    const { resetHints, params } = this.props;
    let { answer } = this.state;

    if (answer == options.length - 1) {
      // move answer tick if it was last
      answer -= 1;
    }
    const newOptions = options.filter((opt, i) => i !== index);

    this.setState((prevState) => {
      return {
        ...prevState,
        options: newOptions,
        answer,
      };
    });

    this.handleChange({
      options: newOptions,
      answer,
    });

    if (
      (newOptions.length === 1 && answer === 0) ||
      (newOptions.length === 0 && answer === -1)
    ) {
      resetHints({
        ...params,
        hints: [],
      });
    }
  }

  handleSelect(answer) {
    this.setState((prevState) => {
      return {
        ...prevState,
        answer,
      };
    });

    this.handleChange({ answer });
  }

  toOne() {
    const { answer } = this.state;
    const { resetHints, params } = this.props;
    if (answer !== -1) return;
    const newState = {
      options: ['', '', ''],
      answer: 0,
      visible: true,
    };
    this.makeState(newState);
    this.handleChange({ ...newState });
    resetHints({
      ...params,
      hints: [],
    });
  }

  toVarious() {
    const { answer } = this.state;
    const { resetHints, params } = this.props;
    if (answer === -1) return;
    const newState = { options: [], answer: -1, visible: true };
    this.makeState(newState);
    this.handleChange({ ...newState });
    resetHints({
      ...params,
      hints: [],
    });
  }

  handleEnter(options, e) {
    const text = this._variousInput.value.trim();
    if (text.length === 0) return false;
    if (
      e.which === 13 ||
      String.fromCharCode(e.which) == ';' ||
      e.type == 'click'
    ) {
      e.preventDefault();
      if (!options.some((opt) => opt === text)) this.handleAddOption(text);
      this._variousInput.value = '';
    }
  }

  handleBackspace(options, e) {
    const text = e.target.value.trim();
    if (text.length === 0 && e.which === 8) {
      const newOptions = options.slice(0, -1);
      this.setState((prevState) => {
        return {
          ...prevState,
          options: newOptions,
        };
      });
      this.handleChange({ options: newOptions });
    }
  }

  handleOff() {
    this.setState(defaultState);
    this.props.onClose();
    this.handleChange(DEFAULT_CHOICE);
  }

  handleChangeFocus(state) {
    if (!state) {
      this.handleOff();
    }
  }

  renderOptions(options, answer) {
    const { formatMessage } = this.props.intl;
    return (
      <div className="EventControlsChoice__options">
        <div className="EventControlsChoice__notice">
          <FormattedMessage
            id="EventControlsChoice.oneChoiceCaption"
            defaultMessage="Tick correct answers"
          />
        </div>
        {options
          ? options.map((opt, i) => {
              return (
                <div key={i} className="EventControlsChoice__option">
                  <InputWithButtons
                    placeholder={`${i + 1} ${formatMessage(
                      messages.oneChoicePlaceholder
                    )}`}
                    value={opt}
                    full="true"
                    // onBlur={this.handleBlur}
                    onChange={this.handleChangeOption.bind(this, options, i)}
                  >
                    <Checkbox
                      checked={answer === i}
                      onChange={this.handleSelect.bind(this, i)}
                    />
                    <button
                      className="link link--red"
                      disabled={options.length < 3 ? 'disabled' : ''}
                      onClick={this.handleRemoveOption.bind(this, options, i)}
                    >
                      ×
                    </button>
                  </InputWithButtons>
                </div>
              );
            })
          : ''}
        {options && options.length < 5 ? (
          <div className="EventControlsChoice__option EventControlsChoice__option--add">
            <a
              className="link link--blue"
              onClick={this.handleAddOption.bind(this, '')}
            >
              <FormattedMessage
                id="EventControlsChoice.addAnswerText"
                defaultMessage="Add an answer option"
              />
            </a>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }

  renderVarious(options) {
    const { formatMessage } = this.props.intl;
    const befores = options
      ? options.map((opt, i) => {
          return (
            <div key={i} className="EventControlsChoice__option--various">
              {opt}
              <button
                className="link link--light EventControlsChoice__remove"
                onClick={this.handleRemoveOption.bind(this, options, i)}
              >
                ×
              </button>
            </div>
          );
        })
      : '';
    return (
      <div>
        <InputWithButtons
          className="EventControlsChoice__options"
          placeholder={
            options.length > 0
              ? formatMessage(messages.variousChoicePlaceholderMore)
              : formatMessage(messages.variousChoicePlaceholderOne)
          }
          before={befores}
          onKeyPress={this.handleEnter.bind(this, options)}
          onKeyDown={this.handleBackspace.bind(this, options)}
          onRef={(input) => {
            this._variousInput = input;
          }}
        >
          <button
            className="Input__button Input__button--enter InputWithButtons__button--enter"
            onClick={this.handleEnter.bind(this, options)}
          />
        </InputWithButtons>
        <div className="EventControlsChoice__caption">
          <FormattedMessage
            id="EventControlsChoice.variousChoiceCaptionBold"
            defaultMessage="Add Enter-separated correct answers."
          >
            {(text) => <b>{text}</b>}
          </FormattedMessage>{' '}
          <FormattedMessage
            id="EventControlsChoice.variousChoiceCaptionNormal"
            defaultMessage="For example, for the question «2 + 2 =?» write both answers «4» and «four». App shall support both answers."
          />
        </div>
      </div>
    );
  }

  render() {
    const { options, answer } = this.state;
    return (
      <Event>
        <EventHeader>
          <FormattedMessage
            id="EventControlsChoice.title"
            defaultMessage="Quiz"
          />
        </EventHeader>
        <div className="EventControls__notice">
          <FormattedMessage
            id="EventControlsChoice.caption"
            defaultMessage="Asked the question in the text, pictures, video? Enter the answers and the next event will be displayed only after the correct answer"
          />
        </div>
        <div className="EventControlsChoice__head" id="EventControlsChoice">
          <a
            className={`EventControlsChoice__view${
              answer !== -1
                ? ' EventControlsChoice__view--selected'
                : ' link link--blue'
            }`}
            onClick={this.toOne.bind(this)}
          >
            <FormattedMessage
              id="EventControlsChoice.oneChoiceText"
              defaultMessage="Multiple Choice Question (recommended)"
            />
          </a>
          <a
            className={`EventControlsChoice__view${
              answer !== -1
                ? ' link link--blue'
                : ' EventControlsChoice__view--selected'
            }`}
            onClick={this.toVarious.bind(this)}
          >
            <FormattedMessage
              id="EventControlsChoice.variousChoiceText"
              defaultMessage="Open-Ended Question"
            />
          </a>
        </div>
        {answer !== -1 ? this.renderOptions(options, answer) : ''}
        {answer === -1 ? this.renderVarious(options) : ''}
      </Event>
    );
  }
}

EventControlsChoice.propTypes = {
  onChange: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  answer: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(EventControlsChoice);
export { EventControlsChoice as PureEventChoice };
