import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  MUSEUM_POINTS_AMOUNT,
  MIN_POINTS_AMOUNT,
} from '../../../constants/routePoints';

/**
 * Messages for `RouteFooter` component
 * @type {Object}
 */
const messages = defineMessages({
  prompt: {
    id: 'RoutePage.addRoutePointsPrompt',
    defaultMessage:
      'Add at least {amount, plural, =1 {one point} other {{amount} points}}',
  },
  left: {
    id: 'RoutePage.addRoutePointsLeft',
    defaultMessage:
      'Add {amount, plural, =1 {one more point} other {{amount} more points}}',
  },
  ok: {
    id: 'RoutePage.addRoutePointsOk',
    defaultMessage:
      'Great! You have added {amount, plural, =1 {one point} other {{amount} points}}, you can add more',
  },
});

/**
 * JSS styles for `RouteFooter` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
  },
}));

/**
 * Forms string with text describing the amount of points must be added
 * @param {Number} amount - amount of existing route points
 * @param {Boolean} isMuseumQuest - if current quest takes place in museum
 * @param {Function} formatMessage - `formatMessage` function from `react-intl`
 * @returns {String}
 */
function getRoutePointsAmountText(amount, isMuseumQuest, formatMessage) {
  const minPointsAmount = isMuseumQuest
    ? MUSEUM_POINTS_AMOUNT
    : MIN_POINTS_AMOUNT;

  if (amount >= minPointsAmount) {
    return isMuseumQuest ? '' : formatMessage(messages.ok, { amount });
  } else if (amount > 0) {
    return formatMessage(messages.left, {
      amount: minPointsAmount - amount,
    });
  }
  return formatMessage(messages.prompt, { amount: minPointsAmount });
}

/**
 * Displays the amount of points left to be added
 * @param {Object} $
 * @param {Number} $.amount - amount of route points
 * @param {Boolean} $.isMuseumQuest - if current quest takes place in museum
 */
export default injectIntl(function RoutePointsAmount({
  amount,
  isMuseumQuest,
  intl: { formatMessage },
}) {
  const styles = useStyles();

  return (
    <Typography className={styles.root} variant="body2">
      {getRoutePointsAmountText(amount, isMuseumQuest, formatMessage)}
    </Typography>
  );
});
