import Api from '../../../functions/Api';

export async function FetchCountry(lang) {
  let country;

  try {
    const response = await Api.get(
      `v2/countries/?expand=meta_title,meta_description&preorder=true&published=false&per_page=300`,
      {
        headers: {
          'Accept-Language': lang,
        },
      }
    );
    country = response.data.results;
  } catch (e) {
    if (!country) {
      return { statusCode: 404 };
    }
  }

  return country;
}
