/**
 * Create cached state and compare objects as string. Based on сlosures.
 */
export const memoizedState = () => {
  let cachedState = {};
  return (newState, callback, defaultReturn = null) => {
    if (JSON.stringify(newState) !== JSON.stringify(cachedState)) {
      cachedState = newState;
      return callback();
    }
    return defaultReturn;
  };
};

/**
 * Calls the function parameter only if the state is changed(compared as a string).
 * @param {Object} newState - state
 * @param {Function} callback - if current dropdown is active
 */
export const callOnStateChange = memoizedState();
