import { useState, useRef } from 'react';

/**
 * Hook for keeping info about dragging `MapPin`
 * @param {Object} $
 * @param {Function} $.onDragging - calls while dragging in process
 * @param {Function} $.onDragFinished - dragging process finished, passes array with coordinates as arguments
 * @returns {Object} - props for `MapPin` component and `isDragging` state
 */
export default function usePinDrag({
  onDragging = () => {},
  onDragFinished = () => {},
}) {
  const [isDragging, setDragging] = useState(false);
  const coordsRef = useRef(null);

  /**
   * Drag process started
   * Toggles info about dragging
   */
  const onDragStart = () => {
    setDragging(true);
  };

  /**
   * Dragging in process
   * Updates remembered coords for dragging pin
   * @param {Leaflet::DragEvent}
   */
  const onDrag = ({ latlng }) => {
    const currentCoords = [latlng.lat, latlng.lng];
    coordsRef.current = currentCoords;
    onDragging(currentCoords);
  };

  /**
   * Drag process finished
   * Calls `onDragFinished` with array of coordinates of the drop place
   */
  const onDragEnd = () => {
    setDragging(false);
    onDragFinished(coordsRef.current);
    coordsRef.current = null;
  };

  return {
    isDragging,
    onDragStart,
    onDrag,
    onDragEnd,
    ...(isDragging ? { radius: undefined } : {}),
  };
}
