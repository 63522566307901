export default function checkTriggers(currentEvent, nextEvent) {
  const {
    trigger: {
      geo: geoContent = null,
      time: timeContent,
      ar: arContent = null,
    } = {},
  } = nextEvent || {};
  const isGeoTriggerSet = Boolean(geoContent);
  const isTimeTriggerSet = Boolean(timeContent);
  const isARTriggerSet = Boolean(arContent);

  const choiceContent = currentEvent.content.find(
    (item) => item.contentType === 'choice'
  );
  let isChoiceSet = false;
  let isNextButtonSet = false;

  if (choiceContent) {
    const { answer, options } = choiceContent.data;
    if (options.length == 1 && answer != -1) {
      isNextButtonSet = true;
    } else {
      isChoiceSet = [
        // Choice
        answer == -1 && options.some(Boolean) && Boolean(choiceContent.visible),
        // Various answers
        answer != -1 &&
          options.some(Boolean) &&
          answer < options.length &&
          Boolean(choiceContent.visible),
      ].some(Boolean);
    }
  }

  return {
    isGeoTriggerSet,
    isTimeTriggerSet,
    isChoiceSet,
    isARTriggerSet,
    isNextButtonSet,
  };
}
