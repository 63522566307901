import { getParameterByName } from '../URLparams';

// Filter metric data which contains 'label' parameter and prettify output
const getMetricDataWithLabels = (metricData, metricKey) => {
  return metricData
    .filter((data) => {
      const { dimensions } = data;
      return dimensions[0].name.includes('label=');
    })
    .map((filteringData) => {
      const { dimensions, metrics } = filteringData;
      return {
        labelName: getParameterByName('label', dimensions[0].name),
        [metricKey]: metrics[0],
      };
    });
};

// Get data for the unique label
const getLabelData = (labelName, metricDataWithLabels) => {
  return metricDataWithLabels.filter((metricData) =>
    metricData.labelName.includes(labelName)
  );
};

// Get stats data for the unique labels. If stats data have duplicate keys it will summary by the key
const getLabelsStatsData = (uniqueLabels, metricDataWithLabels, metricKey) => {
  return uniqueLabels.reduce((arr, label) => {
    const labelData = getLabelData(label, metricDataWithLabels);
    return [
      ...arr,
      {
        labelName: label,
        [metricKey]: labelData.reduce((sum, data) => sum + data[metricKey], 0),
      },
    ];
  }, []);
};

// Adapter for the YA.metrics
export const metricAdapter = (metricKey, metricResponse) => {
  const { totals, data: metricData } = metricResponse;

  const metricDataWithLabels = getMetricDataWithLabels(metricData, metricKey);

  // Get unique labels via Set
  const uniqueLabels = [
    ...new Set(
      metricDataWithLabels.map((metricData) => {
        const { labelName } = metricData;
        return labelName;
      })
    ),
  ];

  const labelsStatsData = getLabelsStatsData(
    uniqueLabels,
    metricDataWithLabels,
    metricKey
  );

  return {
    total: { [metricKey]: totals[0] },
    labels: labelsStatsData,
  };
};
