/**
 * CSS styles, applied as `"style"` attribute on the `"table"` HTML element
 * @type {String}
 */
const tableStyles = 'font-size: 12px';

/**
 * Compiles table data into XLS table
 * @param {String} tableName - title of XLS list
 * @param {String} tableHeader - header of the table in list
 * @param {String} tableBody - table data
 * @returns {String} - table in XLS format
 */
export default function xlsTemplate(
  tableName = 'Test Sheet',
  tableHeader,
  tableBody
) {
  return `<html xmlns:x="urn:schemas-microsoft-com:office:excel"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>${tableName}</x:Name><x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body><table border='1px' style="${tableStyles}"><thead>${tableHeader}</thead><tbody>${tableBody}</tbody></table></body></html>`;
}
