import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HELP_CENTER_URL } from '../../../constants/branding';
import EventAlert from '../../Event/EventAlert';

/**
 * Static audio event helper text
 * @param {Object} $
 * @param {String} $.className - additional CSS class name for the root element
 * @param {String} $.locale - current selected locale (e.g. "ru", "en", etc.)
 */
export default function EventControlsAudioGuideHelper({ className, locale }) {
  return (
    <a
      className={className}
      href={`${HELP_CENTER_URL}${locale}/audio/`}
      target="_blank"
      rel="noreferrer"
    >
      <EventAlert>
        <FormattedMessage
          id="EventControlsAudioGuideHelper.help"
          defaultMessage="How to record audio guide?"
        />
      </EventAlert>
    </a>
  );
}
