import React from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import { fade } from '@material-ui/core/styles/colorManipulator';
import makeStyles from '@material-ui/styles/makeStyles';
import overloadJss from '../../../functions/css/overloadJss';

/**
 * JSS styles for `withColor(material-ui::ToggleButton)`
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => {
  const getColor = (variant, props, wrap = (c) => c) => {
    const color = get(theme.palette, `${props.color}.${variant}`, null);
    return color ? wrap(color) : null;
  };

  return overloadJss(
    {
      root: {
        borderColor: getColor.bind(this, 'light'),
        background: (props) => (theme.palette[props.color] ? '#fff' : null),
        color: getColor.bind(this, 'main'),
        '&:hover': {
          background: (props) => getColor('main', props, (c) => fade(c, 0.02)),
        },
      },
      selected: {
        background: getColor.bind(this, 'background'),
        color: getColor.bind(this, 'main'),
        pointerEvents: 'none',
      },
    },
    'MuiToggleButton-root'
  );
});

/**
 * Adds ability to pass `color` prop as like in `material-ui::Button`
 * @param {React::Component} Component - overload `ToggleButton` component
 */
export default function withColor(Component) {
  /**
   * `ToggleButton` with ability to pass `color` prop
   * @param {Object} $
   * @param {String?} $.color - color of the button (`"primary"` or `"secondary"`)
   * @param {...Object} $.toggleProps - will be passed to overloaded component
   */
  return function ({ classes = {}, color, ...toggleProps }) {
    const styles = useStyles({ color });
    return (
      <Component
        classes={{
          ...classes,
          ...['root', 'selected'].reduce(
            (acc, n) => ({
              ...acc,
              [n]: classNames(!!color && styles[n], classes[n]),
            }),
            {}
          ),
        }}
        {...toggleProps}
      />
    );
  };
}
