import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import PlaceIcon from '@material-ui/icons/Place';
import './MapPositionSelector.css';

/**
 * JSS styles for `MapPositionControl` element
 * @type {React::Hook}
 */
const useStyles = makeStyles({
  root: {
    position: 'absolute',
    marginBottom: '5px',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none',
    zIndex: 1000,
  },
  pin: {
    width: 'auto',
    '& path': {
      fill: '#f44336',
    },
  },
});

/**
 * Converts coord values in object into CSS valid values with `px` units
 * @param {Object} plain - object with values to convert
 */
function coordsToCSS(plain) {
  const CSS = { ...plain };
  ['top', 'left', 'right', 'bottom'].forEach((prop) => {
    const v = CSS[prop];
    CSS[prop] = typeof v === 'number' ? `${v}px` : v;
  });
  return CSS;
}

/**
 * Tracks mouse button with provided element when installed in DOM
 * @param {Object} $
 * @param {React::Ref} $.fieldRef - where to drag
 * @param {Number?} $.height - height of the dragging element (auto by default) in px
 */
export default function MapPositionSelector({
  fieldRef = {},
  height = 0,
  map,
}) {
  /**
   * Current position of the cursor
   * `null` as initial position cause without moving - nothing info about mouse can be collected
   * @type {Object|Null}
   * @prop {Number} $.left - left coord in pixels
   * @prop {Number} $.top - top coord in pixel
   */
  const [position, setPosition] = useState(null);
  const styles = useStyles();

  /**
   * Recalculates coords of the item bound to the mouse cursor
   * @param {MouseEvent} event - `mousemove` event
   */
  const updateCoords = (event) => {
    if (fieldRef.current) {
      const fieldCoords = fieldRef.current.getBoundingClientRect();
      setPosition({
        left: event.clientX - fieldCoords.left,
        top: event.clientY - fieldCoords.top,
      });
    }
  };

  /**
   * Dispatches point selection (`click` event) on the `fieldRef`
   * @param {MouseEvent} event - `click` event
   */
  const dispatchSelection = (event) => {
    if (fieldRef.current && map) {
      map.dispatchEvent(
        new MouseEvent('click', {
          clientX: event.clientX,
          clientY: event.clientY + height / 2,
        })
      );
    }
  };

  /**
   * Set mouse tracking listeners when element appeared in the DOM
   * Remove them when erased from DOM
   */
  useEffect(() => {
    if (fieldRef.current) {
      fieldRef.current.addEventListener('mousemove', updateCoords);
    }

    return () => {
      if (fieldRef.current) {
        fieldRef.current.removeEventListener('mousemove', updateCoords);
      }
    };
  }, []);

  return (
    <div
      className={classNames(styles.root, 'MapPositionSelector')}
      {...(position ? { style: coordsToCSS(position) } : {})}
      onClick={dispatchSelection}
    >
      <PlaceIcon className={styles.pin} style={{ height: height || 'auto' }} />
      <div className="MapPositionSelector__cross" />
    </div>
  );
}
