import checkMediaId from '../id/checkMediaId';

/**
 * Maps through `uploads` and calls `operate` on sibling uploads (uploads with same `mediaId.arrayId`)
 * @param {Array[Object]} uploads - descriptions of current uploading stuff from redux `store::uploads.uploads[]`
 * @param {MediaId} mediaId - media id of the upload to select sibling uploads
 * @param {Function} operate - will be called on all sibling uploads in passed `uploads`, must return new media id of the upload object `operate` called on
 * @returns {Array[Object]} - updated with `operate` calls `uploads` array
 */
export default function operateMediaId(uploads, mediaId, operate) {
  if (!checkMediaId(mediaId)) {
    return uploads;
  }

  return uploads.map((upload) => ({
    ...upload,
    mediaId:
      checkMediaId(upload.mediaId) && upload.mediaId.arrayId === mediaId.arrayId
        ? operate(upload.mediaId)
        : upload.mediaId,
  }));
}
