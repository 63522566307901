import {
  actionChannel,
  all,
  take,
  takeLatest,
  takeEvery,
  put,
  call,
  select,
} from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { buffers } from 'redux-saga';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import * as types from '../constants/ActionTypes';
import Api from '../functions/Api';
import getQuestById from '../functions/getQuestById';
import { FirstSteps } from '../constants/tutorials/TutorialsName';
import CachedTutorial from '../functions/cachedTutorial';
import findRoutePoint from '../functions/route/findRoutePoint';
import enableContentVisibility from '../functions/quest/enableContentVisibility';
import convertEventsNext from '../functions/quest/convertEventsNext';
import { addEvent, passEventParams } from '../actions/events';
import {
  editRoutePoint,
  rearrangeRoutePoints,
  removeRoutePoint,
} from '../actions/route';
import { editEvent } from '../actions/quests';
import queryString from '../functions/URL/parseQuery';
import { fetchProduct, postListedData } from '../actions/publishing';
import { findQuest } from '../reducers/quests';
import { uploadBatch } from './uploads';

/**
 * Possible actions dispatched at the end of the `syncQuest` saga
 * @type {Array[String]}
 */
export const SYNC_ENDINGS = [
  types.SYNC_QUEST_SUCCESS,
  types.SYNC_QUEST_FAILURE,
];

/**
 * Possible actions dispatched at the end of the `fetchQuest` saga
 * @type {Array[String]}
 */
export const FETCH_ENDINGS = [types.RECEIVE_QUEST, types.NOT_RECEIVE_QUEST];

/**
 * Checks if action is last dispatched of the `syncQuest`
 * E.g. of usage:
 * ```js
 * // Check if `SYNC_QUEST` saga was ended
 * take(isSyncQuestEnding);
 * ```
 * @param {Object} action - current action to check
 * @returns {Boolean}
 */
export function isSyncQuestEnding(action) {
  return SYNC_ENDINGS.includes(action.type);
}

/**
 * Checks if action is last dispatched of the `fetchQuest`
 * Bahaviour and usage is same with `isSyncQuestEnding`
 * @param {Object} action - current action to check
 * @returns {Boolean}
 */
export function isFetchQuestEnding(action) {
  return FETCH_ENDINGS.includes(action.type);
}

// TODO: migrate to sagas from thunk
export function* syncQuest({ onSyncStart = () => {}, ...action }) {
  const { id, updateMediaSize } = action;
  const { routes, ...quest } = yield select((state) =>
    getQuestById(state.quests.quests, id)
  );

  yield put({ type: types.SEND_QUEST, id });
  onSyncStart({ questId: id });

  try {
    const { data } = yield call(Api.put, `quests/${id}/`, {
      data: {
        /**
         * Removing `false` visibility on images
         * ClickUp #12pda7r
         */
        ...enableContentVisibility(
          /**
           * ToDo: #cu-1t1wbze
           * Converting `quest.events[].prevButton` to `quest.events[].nextButton` of the previous event (for each event)
           */
          convertEventsNext(cloneDeep(quest)),
          'image'
        ),
        route: (routes || []).map((p) => p.guid),
        updateMediaSize,
      },
    });
    yield put({
      type: types.SYNC_QUEST_SUCCESS,
      id: data.id,
      lastModified: data.lastModified,
    });

    // if (updatePlans) {
    //   yield put({ type: types.REQUEST_PLANS, id });
    // }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    if (error.status === 400) {
      yield put({ type: types.FETCH_QUEST, id });
    }
    yield put({ type: types.SYNC_QUEST_FAILURE, id });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* fetchQuest(action) {
  const { id, isForSync = false } = action;
  const locale = yield select((state) => state.user.locale);

  try {
    yield put({ type: types.REQUEST_QUEST, id });
    const options = {
      headers: {
        'Accept-Language': locale,
      },
    };
    const { data: quest } = yield call(Api.get, `quests/${id}/`, options);
    yield put({
      type: types.RECEIVE_QUEST,
      quest,
    });

    if (!isForSync) {
      yield put({ type: types.REQUEST_CATEGORIES, locale });

      if (quest.products.length) {
        yield put(fetchProduct(quest.products[0].id));
      }
    }
  } catch (error) {
    // TODO: handle
    yield put({
      type: types.NOT_RECEIVE_QUEST,
      id,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function fetchUpdatedQuest(id) {
  return {
    type: types.FETCH_UPDATED_QUEST,
    id,
  };
}

function* updateQuestData(action) {
  const { questId } = action;
  try {
    const quest = yield select((state) =>
      state.quests.quests.find((q) => q.id === questId)
    );

    if (quest) {
      const processedQuest = enableContentVisibility(
        convertEventsNext(cloneDeep(quest)),
        'image'
      );

      const lastModifiedText = new Date().toISOString();

      const dataToUpdate = {
        ...processedQuest,
        lastModifiedText,
        copies: processedQuest.copies.map((copy) => ({
          ...copy,
          synchronized: false,
        })),

        route: (processedQuest.routes || []).map((p) => p.guid),
      };

      const response = yield call(Api.put, `quests/${questId}/`, {
        data: dataToUpdate,
      });

      if (response.ok) {
        yield put({
          type: types.RECEIVE_QUEST,
          quest: { ...quest, ...dataToUpdate },
        });
        yield call(fetchUpdatedQuest, questId);
      } else {
        throw new Error('Server failed to update quest.');
      }
    } else {
      yield put({ type: types.NOT_RECEIVE_QUEST, id: questId });
    }
  } catch (error) {
    /* empty */
  }
}

export function triggerUpdateQuestData(questId) {
  return {
    type: types.UPDATE_QUEST_DATA,
    questId,
  };
}

function* addQuest(payload) {
  const {
    title,
    eventTitles = [],
    events = [],
    redirect = {},
    selecting = true,
  } = payload;
  try {
    const {
      data: { id },
    } = yield call(Api.post, 'quests/', {
      data: {
        title: title || '',
        events,
      },
    });
    yield put({ type: types.CREATE_QUEST, id, title });
    yield call(fetchQuest, { id });

    if (!events.length) {
      yield all(
        eventTitles.map((eventTitle) =>
          put(
            addEvent({
              questId: id,
              title: eventTitle,
              time: 0,
              sync: false,
            })
          )
        )
      );
      yield call(syncQuest, { id, updatePlans: true });
    }

    let idProduct = yield select(
      (state) => getQuestById(state.quests.quests, id).products[0].id
    );

    while (!idProduct) {
      idProduct = yield select(
        (state) => getQuestById(state.quests.quests, id).products[0].id
      );
    }

    const locale = yield select((state) => state.user.locale);

    yield put(
      postListedData(
        idProduct,
        {
          text:
            locale === 'ru'
              ? 'Наушники — пожалуйста, возьмите свои'
              : 'Earphones — please bring your own',
        },
        'exclusions'
      )
    );

    if (selecting) {
      yield put({ type: types.SELECT_QUEST, id, sync: false, redirect: true });
    }
    yield put({ type: types.CREATE_QUEST_SUCCESS, redirect });
    yield put({
      type: types.ANALYTICS_TRACK_EVENT,
      event: 'create_quest',
      eventCategory: 'Authors',
      eventAction: 'Create Quest',
      title,
    });
  } catch (error) {
    yield put({ type: types.CREATE_QUEST_FAILURE, error });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

function* handleCreateQuestSuccess({ redirect }) {
  if (isEmpty(redirect)) return;
  if (redirect.isHard) {
    window.location = redirect.url;
  } else {
    yield put(push(redirect.url));
  }
}

function* selectQuest(action) {
  const { redirect, sync = true } = action;
  const id = parseInt(action.id, 10);

  try {
    if (sync) {
      yield call(fetchQuest, { id, redirect });
    }
    if (redirect) {
      yield put(push(`/quest/${id}/title/`));
    }
  } catch (error) {
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* deleteQuest(action) {
  const { id, productId } = action;
  try {
    // allow the user 5 seconds to perform undo.
    // after 5 seconds, 'archive' will be the winner of the race-condition
    // const { undo, del } = yield race({
    //   undo: take(
    //     action => action.type === types.DELETE_QUEST_UNDO && action.id === id
    //   ),
    //   del: call(delay, 5000)
    // });

    // if (undo) return;

    // make the API call to apply the changes remotely
    yield call(Api.delete, `quests/${id}/`);
    yield put({
      type: types.DELETE_QUEST_SUCCESS,
      id,
      productId,
    });
    const currentPage = yield select(
      (state) => state.stats.questsStats.current
    );
    yield put({
      type: types.REQUEST_QUESTS_STATS,
      page: currentPage,
    });
  } catch (error) {
    yield put({
      type: types.DELETE_QUEST_FAILURE,
      id,
      productId,
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* copyQuest(action) {
  const { id, title, redirect } = action;
  try {
    const {
      data: { id: copyId },
    } = yield call(Api.post, `quests/${id}/copy/`, {
      data: {
        id,
        title,
      },
    });
    yield* fetchQuest({
      id: copyId,
      redirect,
    });
    yield put({
      type: types.COPY_QUEST_SUCCESS,
      id,
    });
  } catch (error) {
    yield put({
      type: types.COPY_QUEST_FAILURE,
      id,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

function* fetchQuests() {
  try {
    yield put({ type: types.REQUEST_QUESTS });
    const { data } = yield call(Api.get, 'quests/');

    data.sort((a, b) => a.id - b.id);

    yield put({
      type: types.RECEIVE_QUESTS,
      quests: data || [],
    });
  } catch (error) {
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* receiveQuests(action) {
  const pathname = yield select((state) => state.router.location.pathname);
  const isFetching = yield select((state) => state.quests.isFetching);
  const { quests } = action;
  // ToDo: think about performance
  // let isUserPartner = yield select(state => state.user.isPartner);
  // if (isUserPartner === undefined) {
  //   yield take(types.RECEIVE_USER);
  //   isUserPartner = yield select(state => state.user.isPartner);
  // }

  try {
    if (pathname === '/' && !isFetching) {
      // if (isUserPartner) {
      //   yield take(types.FETCH_PARTNER_DATA_SUCCESS);

      //   const bio = yield select(state => state.partner.data.bio);

      //   const avatarUrl = yield select(state => state.user.avatar);

      //   if (!bio || !avatarUrl) {
      //     return yield put({
      //       type: types.OPEN_FORM_BIO_POPUP
      //     });
      //   }
      // }
      const queryParams = queryString(window.location.search);
      const { openLastTourCreated } = queryParams;
      if (openLastTourCreated && quests.length) {
        yield put({
          type: types.SELECT_QUEST,
          id: quests[quests.length - 1].id,
          redirect: true,
        });
      }
      const isSkippedOrFinished = yield call(
        CachedTutorial.getTutorialDataFromCache,
        FirstSteps
      );
      if (isSkippedOrFinished) {
        // Handle the condition when isSkippedOrFinished is true
      }
      // yield put({
      //   type: types.CREATE_TUTORIAL,
      //   name: FirstSteps
      // });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    yield put({
      type: types.RESET_TUTORIAL,
    });
  }
}

/**
 * Reorders events and removes route points bindings to moved event
 * @param {Object} action
 * @param {Object} action.ids - `questId` ans `eventId` fields with suitable ids
 * @param {Number} action.fromIndex - previous index of the event in `quest.events[]` array
 * @param {Number} action.toIndex - new index of the event in `quest.events[]` array
 */
export function* reorderEventsAndPoints(action) {
  /**
   * Checking if there are no quest syncing in progress
   * Waiting while current sync is in progress
   */
  if (yield select((state) => state.quests.isSyncing)) {
    yield take(isSyncQuestEnding);
  }

  /** Reordering events */
  const { ids: { questId, eventId } = {} } = action;
  const quest = findQuest(
    yield select((state) => state.quests.quests),
    questId
  );
  const points = quest.routes || [];
  const eventIds = quest.events.map(({ id }) => id);

  yield put({
    ...action,
    type: types.REORDER_EVENTS,
  });
  yield put({
    type: types.SYNC_QUEST,
    id: questId,
    onSyncStart: action.onSyncStart,
  });

  /** Checking if quest data was successfully sent */
  const dispatchedAction = yield take(isSyncQuestEnding);
  if (dispatchedAction.type !== types.SYNC_QUEST_SUCCESS) return;

  /**
   * If there are point bound with reordered event (point and event have same id):
   * - remove bindings to other events
   * - reorder points
   */
  const pointIndex = points.findIndex((p) => p.guid === eventId);
  if (pointIndex > -1) {
    if (get(points[pointIndex], 'events.length')) {
      yield put(
        editRoutePoint({
          questId,
          guid: eventId,
          order: points[pointIndex].order,
          events: [],
        })
      );
    }

    let nextPointIndex = points.length;
    for (let i = action.toIndex; i < eventIds.length; i += 1) {
      const pi = points.findIndex((p) => p.guid === eventIds[i]);
      if (pi > -1) {
        nextPointIndex = pi;
        break;
      }
    }

    if (nextPointIndex !== pointIndex) {
      yield put(
        rearrangeRoutePoints({
          questId,
          pointId: points[pointIndex].guid,
          newPointId: get(points[nextPointIndex], 'guid'),
        })
      );
    }
  }

  /** If reordered event was bound to the point - remove binding */
  const point = points.find((p) => p.events && p.events.includes(eventId));
  if (point) {
    const boundEvents = [...point.events];
    boundEvents.splice(eventId, 1);
    yield put(
      editRoutePoint({
        questId,
        guid: point.guid,
        order: point.order,
        events: boundEvents,
      })
    );
  }
}

/**
 * Saga updates image in event.content[] images array
 * @param {Array}  action.files   - new image data, in format same with uploadBatch
 * @param {Number} action.imageId - current image position in content[] images array
 * @param {Object} action.eventPK
 */
export function* replaceImage(action) {
  yield put({
    type: types.REMOVE_EVENT_IMAGE,
    ...action.eventPK,
    imageId: action.imageId,
  });
  yield call(uploadBatch, action);
}

/**
 * Saga changes image position in event.content[] images array
 * @param {Number} action.imageId - current image position in content[] images array
 * @param {Number} action.newImageId - new image position in content[] images array
 * @param {Number} action.isUpload   - if current image is uploading
 */
export function* dragImage(action) {
  yield put({
    type: types.DRAG_EVENT_IMAGE,
    questId: action.questId,
    eventId: action.eventId,
    newImageId: action.newImageId,
    imageId: action.imageId,
    mediaId: action.mediaId,
    newMediaId: action.newMediaId,
    isUpload: action.isUpload || false,
  });
  if (!action.isUpload) {
    yield call(syncQuest, { id: action.questId, ...action });
  }
}

/**
 * Toggles museum flag and syncs changes
 * @param {Object} $
 * @param {Number} $.questId - quest id where museum flag will be toggled
 * @param {...Object} $.action - will be passed to `TOGGLE_MUSEUM` saga
 */
// eslint-disable-next-line unused-imports/no-unused-vars
export function* toggleMuseum({ type, ...action }) {
  yield put({ type: types.TOGGLE_MUSEUM, ...action });
  yield call(syncQuest, { id: action.questId, updatePlans: true });
}

/**
 * Deletes event
 * Edits route points event bindings by decrementing event indexes bound to the route point after new added event
 * @param {Object} $
 * @param {Number} $.questId - id of the quest containing deleting event
 * @param {String} $.eventId - guid of the event to delete
 */
export function* deleteEvent({ questId, eventId }) {
  const quests = yield select((state) => state.quests.quests);
  const quest = findQuest(quests, questId);
  const point = findRoutePoint(get(quest, 'routes', []), eventId);

  yield put(
    editEvent({
      type: types.DELETE_EVENT,
      ...passEventParams({ questId, eventId }),
    })
  );

  if (point) {
    yield put(
      removeRoutePoint({
        questId,
        pointId: eventId,
        isSync: false,
        isSend: false,
      })
    );

    const endOfSyncQuest = yield take(isSyncQuestEnding);
    if (endOfSyncQuest.type === types.SYNC_QUEST_SUCCESS) {
      yield put(
        removeRoutePoint({
          questId,
          pointId: eventId,
          isSync: false,
        })
      );
    }
  }
}

export default function* watchQuests() {
  yield takeLatest(types.FETCH_QUEST, fetchQuest);
  yield takeLatest(types.FETCH_UPDATED_QUEST, fetchQuest);
  yield takeLatest(types.UPDATE_QUEST_DATA, updateQuestData);
  yield takeLatest(types.ADD_QUEST, addQuest);
  yield takeLatest(types.CREATE_QUEST_SUCCESS, handleCreateQuestSuccess);
  yield takeLatest(types.SELECT_QUEST, selectQuest);
  yield takeEvery(types.DELETE_QUEST, deleteQuest);
  yield takeEvery(types.COPY_QUEST, copyQuest);
  yield takeEvery(types.DELETE_EVENT_SYNC, deleteEvent);
  yield takeLatest(types.FETCH_QUESTS, fetchQuests);
  yield takeLatest(types.RECEIVE_QUESTS, receiveQuests);
  yield takeLatest(types.REORDER_EVENTS_AND_POINTS, reorderEventsAndPoints);
  yield takeLatest(types.REPLACE_EVENT_IMAGE, replaceImage);
  yield takeLatest(types.SYNC_DRAG_EVENT_IMAGE, dragImage);
  yield takeLatest(types.TOGGLE_MUSEUM_SAGA, toggleMuseum);
  const requestSyncChain = yield actionChannel(
    types.SYNC_QUEST,
    buffers.expanding()
  );
  while (true) {
    const action = yield take(requestSyncChain);
    yield call(syncQuest, action);
  }
}
