/**
 * Generates `TableCellData`
 * @param {String} content - table content
 * @param {Object} $1
 * @param {*} $1.rawUnsafe - raw data of the table cell (for comparing and filtering)
 * @param {String?} $1.pure - pure text data to display in `text-only` mode (such as export), required only if `content` cannot be converted to `String`
 * @returns {TableCellData}
 */
export default function tableCell(content, { raw, pure } = {}) {
  return { raw, content, pure };
}
