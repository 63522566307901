import { Russian, English } from '../constants/Languages';
import ru from './locales/ru.json';
import en from './locales/en.json';

export default function getLocaleData(locale) {
  switch (locale) {
    case Russian:
      return ru;
    case English:
      return en;
    default:
      return en;
  }
}
