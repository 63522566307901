import {
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_EMAIL,
  LOGIN_EMAIL_FAILURE,
  LOGOUT,
  REQUEST_USER,
  RECEIVE_USER,
  NOT_RECEIVE_USER,
  USER_ADD_ERROR,
  USER_REMOVE_ERRORS,
  USER_UPDATE,
  USER_UPDATE_FAILURE,
  USER_UPDATE_SUCCESS,
  CHANGE_LANGUAGE,
  CHANGE_LANGUAGE_SUCCESS,
  CHANGE_LANGUAGE_FAILURE,
  UPLOAD_AVATAR,
  UPLOAD_AVATAR_SUCCESS,
  UPLOAD_AVATAR_FAILURE,
  USER_RESET_ERRORS,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  SEND_USER_INFO,
  SEND_USER_INFO_SUCCESS,
  SEND_USER_INFO_FAILURE,
  SEND_PART_USER_INFO_SUCCESS,
  ADD_LOCATION,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_FAILURE,
  ADD_PLACE_OF_STAY,
  ADD_PLACE_OF_STAY_SUCCESS,
  ADD_PLACE_OF_STAY_FAILURE,
  RECEIVE_USER_INFO,
  RECEIVE_USER_INFO_SUCCESS,
  RECEIVE_USER_INFO_FAILURE,
  REQUEST_CATEGORIES,
  RECEIVE_CATEGORIES,
  NOT_RECEIVE_CATEGORIES,
  ADD_CURRENCIES_FOR_USER_STATE,
  REQUEST_SELECTED_LOCATIONS,
  RECEIVE_SELECTED_LOCATIONS,
  NOT_RECEIVE_SELECTED_LOCATIONS,
  FETCH_DEFAULT_COUNTRY_SUCCESS,
  SELECT_LANGUAGE,
  DELETE_SELECTED_LANGUAGE,
  SELECT_SUBCATEGORY,
  DELETE_SELECTED_SUBCATEGORY,
  DELETE_SELECTED_LOCATION,
  DELETE_SELECTED_LOCATION_SUCCESS,
  GET_SENDED_INFO,
  ADD_LANGUAGES_FOR_USER_STATE,
  DELETE_SELECTED_LANGUAGE_SUCCESS,
  SEND_PASSWORD,
  UPDATE_PASSWORD,
  NOT_UPDATE_PASSWORD,
  UPDATE_REQUISITES,
  UPDATE_REQUISITES_SUCCESS,
  UPDATE_REQUISITES_FAILURE,
  ADD_REQUISITES_SUCCESS,
  ADD_REQUISITES_FAILURE,
} from '../constants/ActionTypes';

export default function user(
  state = {
    isLogged: false,
    isLogOuting: false,
    isFetching: false,
    isInitial: true,
    isSending: false,
    isSaved: false,
    isNotSaved: false,
    deletingAttribute: '',
    errors: [],
    messages: [],
    id: null,
    email: '',
    name: '',
    surname: '',
    groups: [],
    defaultCountry: {},
    categories: [],
    currencies: [],
    languages: [],
    locale: '',
    avatar: '',
    subcategoriesSelected: [],
    locationsSelected: [],
    languagesSelected: [],
    placeOfStay: null,
    currencyId: null,
    receivedUserInfo: {
      currencyId: undefined,
      currencyCode: null,
      placeOfStay: null,
      locations: [],
      languages: [],
      subcategories: [],
    },
    requisites: {},
  },
  action
) {
  switch (action.type) {
    case LOGIN:
    case LOGIN_EMAIL:
      return { ...state, isLogOuting: false, isSending: true };

    case LOGIN_FAILURE:
    case LOGIN_EMAIL_FAILURE: {
      return {
        ...state,
        isSending: false,
        isFetching: false,
        error: action.error,
        errors: action.errors,
      };
    }

    case LOGOUT:
      return {
        ...state,
        isFetching: false,
        isLogged: false,
        isLogOuting: true,
      };

    case CHANGE_LANGUAGE:
      return {
        ...state,
        isSaved: false,
        isNotSaved: false,
        isSending: true,
      };

    case CHANGE_LANGUAGE_SUCCESS:
      return {
        ...state,
        isSaved: true,
        isNotSaved: false,
        isSending: false,
        locale: action.locale,
      };

    case CHANGE_LANGUAGE_FAILURE: {
      return {
        ...state,
        isSaved: false,
        isNotSaved: true,
        isSending: false,
      };
    }

    case SEND_PASSWORD: {
      return {
        ...state,
        errors: [],
        isSaved: false,
        isNotSaved: false,
        isSending: true,
      };
    }

    case UPDATE_PASSWORD: {
      return {
        ...state,
        errors: [],
        isSaved: true,
        isNotSaved: false,
        isSending: false,
      };
    }

    case NOT_UPDATE_PASSWORD: {
      return {
        ...state,
        isSaved: false,
        isNotSaved: true,
        isSending: false,
      };
    }

    case SIGNUP:
      return {
        ...state,
        isSending: true,
      };

    case SIGNUP_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isSending: false,
        isFetching: false,
        isPartner: true,
        errors: [],
      };

    case SIGNUP_FAILURE: {
      return {
        ...state,
        isSending: false,
        isFetching: false,
        error: action.error,
        errors: action.errors,
      };
    }

    case SEND_USER_INFO:
      return {
        ...state,
        isSending: true,
      };

    case SEND_USER_INFO_SUCCESS:
      return {
        ...state,
        receivedUserInfo: { ...action.data },
        isSending: false,
        errors: [],
      };

    case SEND_USER_INFO_FAILURE: {
      return {
        ...state,
        isSending: false,
        error: action.error,
        errors: action.errors,
      };
    }

    case SEND_PART_USER_INFO_SUCCESS:
      return {
        ...state,
        receivedUserInfo: { ...action.payload },
        isSending: false,
        deletingAttribute: '',
        errors: [],
      };

    case ADD_LOCATION:
      return {
        ...state,
        isSending: true,
      };

    case ADD_LOCATION_SUCCESS:
      return {
        ...state,
        isSending: false,
        locationsSelected: [...state.locationsSelected, action.payload],
        errors: [],
      };

    case ADD_LOCATION_FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.error,
        errors: action.errors,
      };

    case DELETE_SELECTED_LOCATION:
      return {
        ...state,
        isSending: true,
        deletingAttribute: 'location',
      };

    case DELETE_SELECTED_LOCATION_SUCCESS:
      return {
        ...state,
        isSending: false,
        locationsSelected: state.locationsSelected.filter(
          (el) => el.id !== action.id
        ),
        deletingAttribute: '',
      };

    case ADD_PLACE_OF_STAY:
      return {
        ...state,
        isSending: true,
      };

    case ADD_PLACE_OF_STAY_SUCCESS:
      return {
        ...state,
        isSending: false,
        errors: [],
      };

    case ADD_PLACE_OF_STAY_FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.error,
        errors: action.errors,
      };

    case SELECT_LANGUAGE:
      return {
        ...state,
        isSending: true,
      };

    case DELETE_SELECTED_LANGUAGE:
      return {
        ...state,
        isSending: true,
        deletingAttribute: 'language',
      };

    case DELETE_SELECTED_LANGUAGE_SUCCESS:
      return {
        ...state,
        languagesSelected: state.languagesSelected.filter(
          (el) => el.id !== action.id
        ),
        isSending: false,
        deletingAttribute: '',
      };

    case SELECT_SUBCATEGORY:
      return {
        ...state,
        isSending: true,
      };

    case DELETE_SELECTED_SUBCATEGORY:
      return {
        ...state,
        isSending: true,
        deletingAttribute: 'subcategory',
      };

    case RECEIVE_USER_INFO:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_USER_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        receivedUserInfo: action.payload,
        errors: [],
      };

    case RECEIVE_USER_INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        errors: action.errors,
      };

    case GET_SENDED_INFO:
      return {
        ...state,
        placeOfStay: state.receivedUserInfo.placeOfStay,
        subcategoriesSelected: state.categories
          .flatMap((i) => i.subcategories)
          .filter((n) => state.receivedUserInfo.subcategories.includes(n.id)),
        languagesSelected: state.languages.filter((i) =>
          state.receivedUserInfo.languages.includes(i.id)
        ),
        currencyId:
          state.currencies.find(
            (i) => i.id === state.receivedUserInfo.currencyId
          ) === undefined
            ? null
            : state.currencies.find(
                (i) => i.id === state.receivedUserInfo.currencyId
              ),
      };

    case REQUEST_CATEGORIES:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_CATEGORIES:
      return {
        ...state,
        isFetching: false,
        categories: action.data,
      };

    case NOT_RECEIVE_CATEGORIES:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        errors: action.errors,
      };

    case ADD_CURRENCIES_FOR_USER_STATE:
      return {
        ...state,
        currencies: action.data,
      };

    case ADD_LANGUAGES_FOR_USER_STATE:
      return {
        ...state,
        languages: action.data,
      };

    case REQUEST_SELECTED_LOCATIONS:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_SELECTED_LOCATIONS:
      return {
        ...state,
        isFetching: false,
        locationsSelected: action.data,
      };

    case NOT_RECEIVE_SELECTED_LOCATIONS:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        errors: action.errors,
      };

    case REQUEST_USER:
      return {
        ...state,
        isFetching: true,
        isInitial: false,
      };

    case USER_UPDATE: {
      return {
        ...state,
        isSending: true,
        isNotSaved: false,
        isSaved: false,
      };
    }

    case RECEIVE_USER:
      // eslint-disable-next-line no-case-declarations
      let newUserData;
      if (action.payload.requisites === null)
        newUserData = { ...action.payload, requisites: state.requisites };
      else newUserData = action.payload;

      return {
        ...state,
        isFetching: false,
        isLogged: true,
        errors: [],
        ...newUserData,
      };

    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        isNotSaved: false,
        isSending: false,
        isSaved: true,
        isLogged: true,
        errors: [],
        ...action.payload,
      };

    case FETCH_DEFAULT_COUNTRY_SUCCESS:
      return {
        ...state,
        defaultCountry: action.payload,
      };

    case USER_UPDATE_FAILURE: {
      return {
        ...state,
        isSending: false,
        isNotSaved: true,
        isSaved: false,
        errors: [...state.errors, action.error],
      };
    }

    case USER_RESET_ERRORS: {
      return {
        ...state,
        errors: [],
      };
    }

    case NOT_RECEIVE_USER:
      return {
        ...state,
        isFetching: false,
      };

    case USER_ADD_ERROR:
      return {
        ...state,
        errors: [...state.errors, action.error],
      };

    case USER_REMOVE_ERRORS:
      return {
        ...state,
        errors: state.errors.filter((e) => e.section !== action.section),
      };

    case UPLOAD_AVATAR:
      return {
        ...state,
        isFetching: true,
      };

    case UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        avatar: action.avatar,
        isFetching: false,
      };

    case UPLOAD_AVATAR_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case UPDATE_REQUISITES:
      return {
        ...state,
        isSending: true,
        isNotSaved: false,
        isSaved: false,
      };

    case UPDATE_REQUISITES_SUCCESS:
      return {
        ...state,
        requisites: action.payload,
        isSending: false,
        isNotSaved: false,
        isSaved: true,
      };

    case UPDATE_REQUISITES_FAILURE:
      return {
        ...state,
        errors: [...state.errors, action.error],
        isSending: false,
        isNotSaved: true,
        isSaved: false,
      };

    case ADD_REQUISITES_SUCCESS:
      return {
        ...state,
        requisites: action.payload,
      };

    case ADD_REQUISITES_FAILURE:
      return {
        ...state,
        errors: [...state.errors, action.error],
      };

    default:
      return state;
  }
}
