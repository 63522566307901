import * as types from '../constants/ActionTypes';

/**
 * Fetches statistics of the payments sent to user
 * @param {Object} $
 * @param {Number} $.page - page to fetch
 */
export function fetchPaymentsStats({ page }) {
  return {
    type: types.FETCH_PAYMENTS_STATS,
    page,
  };
}
