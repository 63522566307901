export const selectInputValue = (input) => {
  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    // create a selectable range
    const range = document.createRange();
    range.selectNodeContents(input);

    // select the range
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    input.setSelectionRange(0, 999999);
  } else {
    input.select();
  }
};

// TODO: copy to clipboard
