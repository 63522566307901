export default function isTouch() {
  return [
    'ontouchstart' in document.documentElement,
    navigator.maxTouchPoints > 0,
    navigator.msMaxTouchPoints > 0,
  ].some(Boolean);
}

export const scrollOnTouch = (event) => {
  const scrollYValue = event.changedTouches[0].pageY - 200; // 50px header height + 150 height EventControlsHTML block title and toolbar = 200px
  if (isTouch()) {
    setTimeout(() => {
      window.scroll(0, scrollYValue);
    }, 100);
  }
};
