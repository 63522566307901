import React, { useState, Fragment } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import TableChartIcon from '@material-ui/icons/TableChart';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Dropdown from 'rc-dropdown';
import withSmDialog from '../../Dropdown/withSmDialog';
import TableOptionsButton from '../TableOptionsButton';

/**
 * Dropdown with dialog shown on mobiles
 * @type {React::Component}
 */
const DialogDropdown = withSmDialog(Dropdown);

/**
 * Description of the option in `TableColumnsToggleMenu` list
 * @typedef {Object} TableColumnToggleData
 * @prop {TableHeaderData} data - header description
 * @prop {Boolean} visible - if column is marked as visible
 * @prop {Boolean} hidable - if column can be hidden
 */

/**
 * JSS styles for `TableColumnsToggleMenu` component
 * @type {React::Hook}
 */
const useMenuStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '0px',
    maxHeight: '50vh',
    background: theme.palette.background.main,
    boxShadow:
      '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
    overflow: 'auto',
  },
  header: {
    paddingTop: '8px',
  },
  item: {
    paddingRight: '25px',
  },
}));

/**
 * List of the hidable columns with checkboxes
 * @param {Object} $
 * @param {Array[TableColumnToggleData]} $.columns - table columns
 * @param {Function} $.onColumnToggled - column visibility was toggled
 */
function TableColumnsToggleMenu({ columns, onColumnToggled = () => {} }) {
  const styles = useMenuStyles();

  return (
    <List className={styles.root}>
      <ListSubheader className={styles.header}>
        <FormattedMessage
          id="TableColumnsToggleMenu.header"
          defaultMessage="Select a parameter"
        />
      </ListSubheader>
      {columns.map(({ data, visible }) => (
        <ListItem
          key={data.id}
          button
          className={styles.item}
          onClick={() => onColumnToggled(data.id, !visible)}
        >
          <ListItemText primary={data.title} />
          <Checkbox color="primary" edge="end" checked={visible} />
        </ListItem>
      ))}
    </List>
  );
}

/**
 * JSS styles for `TableColumnsToggle` component
 * @type {React::Hook}
 */
const useStyles = makeStyles(() => ({
  dropdown: {
    maxWidth: '320px',
  },
}));

/**
 * Adds "Columns" button (toggles table column visibility) to `Table::withOptions(Table)`
 * @param {React::Component} Component - `Table` component to overload
 */
export default function withColumnsToggle(Component) {
  /**
   * `Table` component with "Columns" button
   * @param {Object} $
   * @param {Array[String]} $.hidable - id of the columns from `TableHeaderData` which can be hidden (immutable, by default all are hidable)
   * @param {Function} $.onColumnToggled - calls when user toggled column visibility
   * @param {...Object} $.tableProps - will be passed to the `Table` component
   */
  return ({
    endGap,
    hidable = [],
    header,
    onColumnToggled = () => {},
    ...tableProps
  }) => {
    const styles = useStyles();

    /**
     * If all columns in the table are hidable
     * @type {Boolean}
     */
    const isAllHidable = !(Array.isArray(hidable) && hidable.length);

    /**
     * Table header descriptions with additional props for describing their visibility
     * @type {Array[TableColumnToggleData]}
     */
    const [toggledHeader, setToggledHeader] = useState(
      header.map((h) => ({
        data: h,
        visible:
          typeof h.defaultVisible === 'boolean' ? h.defaultVisible : true,
        hidable: isAllHidable || !hidable.includes(h.id),
      }))
    );

    /**
     * Toggles column visibility
     * @param {String} id - id of the toggled column
     * @param {Boolean} isVisible - if current column should be shown
     */
    const toggleColumn = (id, isVisible) => {
      const newToggledHeader = toggledHeader.map((h) =>
        h.data.id === id ? { ...h, visible: isVisible } : h
      );
      setToggledHeader(newToggledHeader);
      onColumnToggled(
        id,
        isVisible,
        newToggledHeader.filter((h) => !h.visible).map((h) => h.data.id)
      );
    };

    return (
      <Component
        endGap={
          <Fragment>
            {endGap}
            {toggledHeader.length ? (
              <DialogDropdown
                classes={{ dropdown: styles.dropdown }}
                overlay={
                  <TableColumnsToggleMenu
                    columns={toggledHeader.filter((th) => th.hidable)}
                    onColumnToggled={toggleColumn}
                  />
                }
              >
                <TableOptionsButton startIcon={<TableChartIcon />}>
                  <FormattedMessage
                    id="TableColumnsToggle.control"
                    defaultMessage="Select columns"
                  />
                </TableOptionsButton>
              </DialogDropdown>
            ) : null}
          </Fragment>
        }
        header={toggledHeader
          .filter(({ visible }) => visible)
          .map(({ data }) => data)}
        {...tableProps}
      />
    );
  };
}
