import { useState, useRef, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { fetchRoutePath } from '../../../functions/map/getRoutePath';

/**
 * Creates route for moving object from coordinates of the points on map
 * Can be passed to `ReactLeaflet::Polyline` component as `positions` prop
 * @param {Object} $
 * @param {Array[LatLng]} points - coordinates of the waypoints
 * @param {String} pathMethod - type of moving for creating path (passing to `fetchRoutePath()::method`, default is `"walking"`)
 * @returns {Array[LatLng]} - path from elliptical coordinates of points on map
 */
export default function useRoutePath({ points, pathMethod = 'walking' }) {
  /**
   * Constructed path between `points`
   * @type {Array[Object]}
   */
  const [path, setPath] = useState([]);

  /**
   * Previous `points` instance
   * @type {React::Ref}
   */
  const prevPointsRef = useRef(points);

  /**
   * Updates `path` array with new route points
   * @param {Array[LatLng]} newPoints - updated points array
   */
  const updatePath = (newPoints) => {
    prevPointsRef.current = points;
    (async () => {
      const newPath = await fetchRoutePath(newPoints, pathMethod);
      setPath(newPath || []);
    })();
  };

  /**
   * Creates path when hook called from recently installed in DOM component
   */
  useEffect(() => {
    updatePath(points);
  }, []);

  /**
   * When `points` array changes:
   * - update path
   */
  useEffect(() => {
    const prevPoints = prevPointsRef.current;
    if (!isEqual(points, prevPoints)) {
      updatePath(points);
    }
  }, [points]);

  return { path };
}
