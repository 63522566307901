import React from 'react';
import Responsive from 'react-responsive';

export const Mobile = ({ children }) => (
  <Responsive children={children} maxWidth={620} />
);
export const Tablet = ({ children }) => (
  <Responsive children={children} minWidth={621} maxWidth={900} />
);
export const Desktop = ({ children }) => (
  <Responsive children={children} minWidth={621} />
);
export const isMobile = () => window.innerWidth <= 620;
export const isDesktop = () => window.innerWidth >= 921;
export const isTablet = () => window.innerWidth <= 920;
