import React, { Component } from 'react';
import classNames from 'classnames';
import {
  FormattedMessage,
  intlShape,
  injectIntl,
  defineMessages,
} from 'react-intl';
import { EVENT_MAX_TITLE_LENGTH } from '../../constants/limits';
import { Mobile, Desktop } from '../../functions/queries';
import InputLine from '../InputLine';
import './EventAdd.css';

const messages = defineMessages({
  defEventTitle: {
    id: 'EventAdd.defEventTitle',
    defaultMessage: 'New event',
  },
  eventTitleWithNumb: {
    id: 'EventAdd.newEventTitleWithNumb',
    defaultMessage: 'New event {number}',
  },
});

class EventAdd extends Component {
  handleSave(e) {
    const { questId, onAdd } = this.props;
    const title = e.target.value.trim();
    if (e.which !== 13 || !title.length) return;

    onAdd({ questId, title });
    e.target.value = '';
  }

  handleClick(e) {
    const { questId, onAdd } = this.props;
    const title = this._input.value.trim();
    if (!title.length) return;

    onAdd({ questId, title });
    this._input.value = '';
  }

  getNewEventTitle() {
    const {
      event,
      intl: { formatMessage },
    } = this.props;
    if (!event) return formatMessage(messages.defEventTitle);
    return formatMessage(messages.eventTitleWithNumb, {
      number: event.order + 2,
    });
  }

  handleAddEvent({ questId, title }) {
    const { onAdd } = this.props;
    onAdd({ questId, title });
  }

  render() {
    const { placeholder, questId, className } = this.props;
    const title = this.getNewEventTitle();
    return (
      <div className={classNames('EventAdd', className)}>
        <Mobile>
          <div
            className="Button EventAdd__button"
            onClick={this.handleAddEvent.bind(this, { questId, title })}
          >
            <FormattedMessage
              id="EventAdd.addNewEvent"
              defaultMessage="Add a new event"
            />
          </div>
        </Mobile>
        <Desktop>
          <div className=" nav__link nav__link--add EventAdd__input">
            <InputLine
              placeholder={placeholder}
              type="enter"
              maxLength={EVENT_MAX_TITLE_LENGTH}
              onRef={(input) => (this._input = input)}
              onKeyDown={this.handleSave.bind(this)}
              onButtonClick={this.handleClick.bind(this)}
            />
          </div>
        </Desktop>
      </div>
    );
  }
}

EventAdd.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(EventAdd);
export { EventAdd as PureEventAdd };
