import React, { useState } from 'react';
import { defineMessages } from 'react-intl';
import { coordsFromString } from '../../../functions/map/stringCoords';

/**
 * Messages for `PlacesAutocomplete` modded with `withCoordinates`
 * @type {Object}
 */
const messages = defineMessages({
  subtitle: {
    id: 'PlacesAutocomplete.coordinatesSubtitle',
    defaultMessage: 'Coordinates',
  },
});

/**
 * Adds ability to select precise coordinates from `PlacesAutocomplete` component
 * E.g. - user is able to enter elliptical geographical coords into input and select them as is in the dropdown
 * @param {React::Component} - `PlacesAutocomplete` to extend
 */
export default function withCoords(Component) {
  /**
   * `PlacesAutocomplete` with ability to enter and select coordinates
   * @param {Object} $
   * @param {Object} $.intl - `react-intl` object for generating translations
   */
  return function ({
    optionsExtra = [],
    onInputChange: onInputChangeExtra = () => {},
    intl: { formatMessage } = {},
    ...autocompleteProps
  }) {
    /**
     * Value entered in the autocomplete input field
     * @type {String}
     */
    const [inputValue, setInputValue] = useState('');

    const coords = coordsFromString(inputValue);
    const coordsString = coords ? `${coords[0]}, ${coords[1]}` : null;

    /**
     * Extra options passed to autocomplete's dropdown
     * Will be empty if `inputValue` string does not contain elliptical geographical coords
     * @type {Array[PlacesAutocompleteOption]}
     */
    const coordsOptions = coordsString
      ? [
          {
            description: coordsString,
            structured_formatting: {
              main_text: coordsString,
              secondary_text: formatMessage
                ? formatMessage(messages.subtitle)
                : 'Coordinates',
              main_text_matched_substrings: [
                { length: coordsString.length, offset: 0 },
              ],
            },
            lat: coords[0],
            lng: coords[1],
            terms: [],
            types: [],
            no_coords: true,
          },
        ]
      : [];

    /**
     * User changed autocomplete's input text
     */
    const onInputChange = (newInputValue, ...args) => {
      setInputValue(newInputValue);
      onInputChangeExtra(newInputValue, ...args);
    };

    return (
      <Component
        optionsExtra={[...coordsOptions, ...optionsExtra]}
        onInputChange={onInputChange}
        {...autocompleteProps}
      />
    );
  };
}
