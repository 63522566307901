import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import formURL from '../../../functions/URL/formURL';
import { EVENT_ID, POINT_ID } from '../../../constants/routePoints/urlParams';

/**
 * Button redirecting to route map page and opens/creates button for editing
 * @param {Object} $
 * @param {String} $.questId - id of the quest route point belongs to
 * @param {String} $.eventId - is of the event for usage on map (bound point and event ID's are equal)
 * @param {Boolean} $.pointExists - if route point should be created for event when pressing on button
 *   !!IMPORTANT - if not passed correctly, will create extensive route point with ID same to existing one
 * @param {...Object} $.buttonProps - will be passed to the root `material-ui::Button` component
 */
export default function RoutePointAccessButton({
  className,
  questId,
  eventId,
  pointExists,
  onClick = () => {},
  ...buttonProps
}) {
  const history = useHistory();

  /**
   * Redirects to route map page
   */
  const toMap = () => {
    history.push(
      formURL({
        pathname: `/quest/${questId}/map`,
        query: { [pointExists ? POINT_ID : EVENT_ID]: eventId },
      })
    );
  };

  return (
    <Button
      color="primary"
      {...buttonProps}
      onClick={() => {
        onClick();
        toMap();
      }}
    >
      {pointExists ? (
        <FormattedMessage
          id="RoutePointAccessButton.edit"
          defaultMessage="Edit route point"
        />
      ) : (
        <FormattedMessage
          id="RoutePointAccessButton.create"
          defaultMessage="Bind to route point"
        />
      )}
    </Button>
  );
}
