import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { withRouter } from 'react-router';
import { Loader } from '../Loader';

class ErrorBoundary extends Component {
  state = { error: null };

  unlisten;

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (this.state.error) {
        this.setState({ error: false });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  // TODO:
  // renderDialog

  render() {
    if (this.state.error) {
      // render fallback UI
      return (
        <div className="row middle-xs center-xs" style={{ height: '100%' }}>
          <div className="col-xs">
            <Loader active error>
              {this.props.message}
            </Loader>
            <a
              className="link link--black"
              onClick={() => Sentry.showReportDialog()}
            >
              Report feedback
            </a>
          </div>
        </div>
      );
    }

    // when there's not an error, render children untouched
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
