import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

/**
 * JSS styles for `ProfileContent` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  content: {
    padding: '30px 30px',
    height: '100%',
    backgroundColor: 'white',
    position: 'relative',
    marginLeft: '0.2%',
  },
}));

/**
 * Content wrapper block for profile pages
 * @param {Object} $
 * @param {React::JSX} $.children - will be rendered inside `ProfileContent`
 * @param {Object?} $.contentClassName - custom content className
 */
const ProfileContent = ({ children, contentClassName = null }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={contentClassName || classes.content}>{children}</div>
    </div>
  );
};

export default ProfileContent;
