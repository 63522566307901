import Api from './Api';

export async function fetchTranslateTour(questId, languageCodes) {
  try {
    const response = await Api.get(
      `quests/${questId}/translateTour/?languageCodes=${languageCodes.join(
        ','
      )}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return response;
  } catch (e) {
    console.error('Error fetching translated tour:', e);
    return { statusCode: e.response?.status || 500 };
  }
}
