import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ProgressCircle.css';

class ProgressCircle extends Component {
  getRadius() {
    const { size, width = 2 } = this.props;
    return size / 2 - 2 * width;
  }

  getStrokeDashOffset() {
    let { progress } = this.props;
    const radius = this.getRadius();

    const c = 2 * Math.PI * radius;

    if (progress < 0) {
      progress = 0;
    }
    if (progress > 100) {
      progress = 100;
    }

    return ((100 - progress) / 100) * c;
  }

  render() {
    const { size, color = '#fff', width = 2, animated, className } = this.props;
    const half = size / 2;
    const radius = this.getRadius();
    return (
      <svg
        className={classNames(
          'ProgressCircle__svg',
          animated && `ProgressCircle__svg--animated`,
          className
        )}
        width={size}
        height={size}
        viewport={`0 0 ${size} ${size}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* <circle 
          r={size} 
          cx={half}
          cy={half} 
          fill="transparent" 
          stroke-dasharray="565.48" 
          stroke-dashoffset="0"
    ></circle> */}
        <circle
          id="bar"
          r={radius}
          cx={half}
          cy={half}
          fill="transparent"
          stroke={color}
          strokeWidth={`${width}px`}
          strokeDasharray={2 * Math.PI * radius}
          strokeDashoffset={this.getStrokeDashOffset()}
        />
      </svg>
    );
  }
}

ProgressCircle.propTypes = {
  size: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  color: PropTypes.string,
  width: PropTypes.number,
  animated: PropTypes.bool,
};

export default ProgressCircle;
