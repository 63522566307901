import { useState, useEffect } from 'react';
import Api from '../Api';

export const useIpCoords = () => {
  const [lat, setLatitudeIp] = useState('');
  const [lon, setLongitudeIp] = useState('');

  const handleSet = (response) => {
    setLongitudeIp(response.longitude);
    setLatitudeIp(response.latitude);
  };

  useEffect(() => {
    fetch('https://get.geojs.io/v1/ip/geo.json')
      .then((r) => r.json())
      .then((r) => handleSet(r))
      .catch(() => {
        Api.get(`/api/cities/ip/`)
          .then((r) => r.json())
          .then((r) => handleSet(r.data));
      });
  }, []);

  return { lat, lon };
};
