import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, intlShape, injectIntl } from 'react-intl';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import FormHelperText from '@material-ui/core/FormHelperText';
import { scrollOnTouch } from '../../../functions/isTouch';
import { callOnStateChange } from '../../../functions/memoizedState';
import debounce from '../../../functions/debounce';
import { TEXT_LIMIT } from '../../../constants/branding';
import { ids } from '../../../constants/PropTypes';
import EventComponent from '../../Event';
import {
  handlePastedText,
  htmlFromState,
  setStateWithContent,
} from './EditorStateUtils';

import './react-draft-wysiwyg.css';
import './EventControlsHTML.css';

/**
 * Static assets imports
 */
import svgBold from './assets/bold.svg';
import svgItalic from './assets/italic.svg';
import svgUnderline from './assets/underline.svg';
import svgLink from './assets/link.svg';
import svgListBullet from './assets/list-bullet.svg';
import svgListOrdered from './assets/list-ordered.svg';

const toolbarOptions = {
  options: ['inline', 'link', 'list'],
  inline: {
    options: ['bold', 'italic', 'underline'],
    bold: { icon: svgBold },
    italic: { icon: svgItalic },
    underline: { icon: svgUnderline },
  },
  list: {
    options: ['unordered', 'ordered'],
    unordered: { icon: svgListBullet },
    ordered: { icon: svgListOrdered },
  },
  link: { options: ['link'], link: { icon: svgLink } },
};

/**
 * Messages for `EventControlsHTML` component
 * @type {Object}
 */
const messages = defineMessages({
  placeholder: {
    id: 'EventControlsHTML.placeholder',
    defaultMessage: 'Write here',
  },
  helperText: {
    id: 'EventControlsHTML.helperText',
    defaultMessage: 'Tell the most interesting info about this place',
  },
  errorTextLength: {
    id: 'EventControlsHTML.errorTextLength',
    defaultMessage: 'Limit of {amount} characters exceeded',
  },
});

const sendChange = (onChange, { html, ...rest }) => {
  if (html) onChange({ html, visible: true, ...rest });
  else onChange({ html: '', visible: false, ...rest });
};

const getHelperText = (errorTextLength, formatMessage) => {
  if (errorTextLength) {
    return formatMessage(messages.errorTextLength, { amount: TEXT_LIMIT });
  }
  return formatMessage(messages.helperText);
};

function EventControlsHTML({
  onChange,
  params,
  html: fetchedlHtml,
  intl: { locale, formatMessage },
  errorTextLength,
}) {
  const hasError = [errorTextLength].some(Boolean);
  const [editorState, setEditorState] = useState(null);
  const [focus, setFocus] = useState(false);

  const sendChangeDebounced = useRef(
    debounce((callBack, data) => sendChange(callBack, data), 1000)
  ).current;

  // Set editorState on first render
  useEffect(() => setStateWithContent(fetchedlHtml, setEditorState), []);

  // Set editorState when eventId is changed (user clicked to another step)
  useEffect(() => {
    callOnStateChange(params?.eventId, () => {
      setStateWithContent(fetchedlHtml, setEditorState);
    });
  }, [params?.eventId, fetchedlHtml]);

  // Remove previous change on 'ctrlKey/command + z' combination
  useEffect(() => {
    const handleKeyDown = (e) => {
      e.stopImmediatePropagation();
      if ((e.metaKey || e.ctrlKey) && e.key === 'z') {
        setEditorState(EditorState.undo(editorState));
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <EventComponent onTouchEnd={scrollOnTouch}>
      <Editor
        spellCheck
        editorClassName={classNames(
          'EventControlsHTML',
          focus && 'EventControlsHTML--focus',
          hasError && 'EventControlsHTML--error'
        )}
        toolbarClassName="EventControlsHTML__toolbar"
        editorState={editorState}
        handlePastedText={handlePastedText}
        placeholder={formatMessage(messages.placeholder)}
        toolbar={toolbarOptions}
        localization={{ locale }}
        onEditorStateChange={(state) => {
          setEditorState(state);

          const newHTML = htmlFromState(state.getCurrentContent());
          if (newHTML !== fetchedlHtml) {
            sendChangeDebounced(onChange, { html: newHTML, ...params });
          }
        }}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
      />
      <FormHelperText error={hasError}>
        {getHelperText(errorTextLength, formatMessage)}
      </FormHelperText>
    </EventComponent>
  );
}

EventControlsHTML.propTypes = {
  onChange: PropTypes.func.isRequired,
  params: ids.isRequired,
  html: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(EventControlsHTML);
export { EventControlsHTML as PureEventHTML };
