import React, { Component } from 'react';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { BRAND_NAME } from '../../constants/branding';
import StructuredPopup from '../Popup/StructuredPopup';
import LoginSocial from '../LoginSocial';
import InputPassword from '../InputPassword';

import LockPicture from '../../img/lock.png';
import './PasswordPopup.css';
import Button from '../Button';

/**
 * Error messages when creating new password
 * @prop {String} length - when password is too short
 */
const passwordErrors = defineMessages({
  width: {
    id: 'NewLogin.passwordLengthError',
    defaultMessage: 'Password is too short, type at least 8 chars',
  },
});

/**
 * Popup header text
 */
const titles = defineMessages({
  offer: {
    id: 'NewLogin.passwordOfferTitle',
    defaultMessage: 'Setting up your account',
  },
  enter: {
    id: 'NewLogin.passwordEnterTitle',
    defaultMessage: 'Set a password',
  },
});

/**
 * Social nets names, links to which will be presented
 * @type {Array[String]}
 */
const socials =
  BRAND_NAME === 'Surprise Me'
    ? ['Google', 'Facebook', 'Twitter', 'VK']
    : ['Google', 'Facebook'];

/**
 * Offers user to create password or login with social net
 * Shows done screen when current page URL is '?passwordpopupmode=done' (logic for this behaviour written in App component)
 * @param {Boolean}  isOpen - shown or hidden
 * @param {String}   mode - screen shown on popup (mode, socialsor done), may be set only at component init
 * @param {Object}   userActions - functions to process user profile changess
 * @param {Function} userActions.changePassword - changes user password
 */
class PasswordPopup extends Component {
  /**
   * State of the component
   * @prop {String}  mode - influences on current shows screen and text, 'socials' or 'password'
   * @prop {String}  password - word entered in InputPassword component
   * @prop {String}  passwordError - error displaying under the input form
   * @prop {Boolean} passwordCreated - if true - popup will be closed
   */
  state = {
    mode: this.props.mode || 'socials',
    passwordCreated: false,
    password: '',
    passwordError: null,
  };

  /**
   * Gets password from input
   * @param {Event}
   */
  handlePassword = ({ target }) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        password: target.value,
        passwordError: null,
      };
    });
  };

  /**
   * Sends a new password to the server
   */
  setPassword = () => {
    const {
      userActions: { changePassword },
      intl: { formatMessage },
    } = this.props;
    const { password } = this.state;

    if (password.length > 8) {
      changePassword({ newPassword: password, oldPassword: '' });
      this.setState({ mode: 'done' });
    } else {
      this.setState({ passwordError: formatMessage(passwordErrors.width) });
    }
  };

  render() {
    const {
      isOpen,
      intl: { formatMessage, locale },
    } = this.props;
    const { mode, passwordCreated, passwordError } = this.state;
    const visible = isOpen && !passwordCreated;

    if (mode === 'password') {
      return (
        <StructuredPopup
          className="PasswordPopup"
          classNameModal="PasswordPopup__modal"
          isOpen={visible}
          title={formatMessage(titles.enter)}
        >
          <FormattedMessage
            id="NewLogin.passwordEnter"
            defaultMessage="Set password if you would like to sign in to your account with email and password."
          />
          <div className="PasswordPopup__extra">
            <div className="PasswordPopup__input">
              <InputPassword theme="card" onChange={this.handlePassword} />
              {passwordError ? (
                <div className="PasswordPopup__input-error">
                  {passwordError}
                </div>
              ) : null}
            </div>
            <a className="login__social-link" onClick={this.setPassword}>
              <img
                className="login__social-link-icon PasswordPopup__password-btn"
                src={LockPicture}
                alt="Password icon"
              />
              <span className="login__social-link-title">
                <FormattedMessage
                  id="NewLogin.savePassword"
                  defaultMessage="Save password"
                />
              </span>
            </a>
          </div>
          <div
            className="PasswordPopup__back"
            onClick={() =>
              this.setState({
                mode: 'socials',
                password: '',
                passwordError: null,
              })
            }
          >
            <FormattedMessage
              id="NewLogin.passwordEnterBack"
              defaultMessage="Back"
            />
          </div>
        </StructuredPopup>
      );
    }

    if (mode === 'done') {
      return (
        <StructuredPopup
          className="PasswordPopup"
          classNameModal="PasswordPopup__modal"
          isOpen={visible}
          title={formatMessage(titles.offer)}
        >
          <FormattedMessage
            defaultMessage="Well done! You're all set up."
            id="NewLogin.passwordCreated"
          />
          <div className="PasswordPopup__extra">
            <Button
              className="PasswordPopup__done-btn"
              theme="action"
              onClick={() => this.setState({ passwordCreated: true })}
            >
              <FormattedMessage
                defaultMessage="Continue to my tours"
                id="NewLogin.toTheTours"
              />
            </Button>
          </div>
        </StructuredPopup>
      );
    }

    return (
      <StructuredPopup
        className="PasswordPopup"
        classNameModal="PasswordPopup__modal"
        isOpen={visible}
        title={formatMessage(titles.offer)}
      >
        <FormattedMessage
          id="NewLogin.passwordOffer"
          defaultMessage="You are logged to the account by a magic link from an email. Please select which social network to link with your account for easy sign-in next time, or set a password."
        />
        <LoginSocial
          socials={socials}
          locale={locale}
          backURL="/my?passwordpopupmode=done"
        />
        <div className="PasswordPopup__extra">
          <a
            className="login__social-link"
            onClick={() => this.setState({ mode: 'password' })}
          >
            <img
              className="login__social-link-icon PasswordPopup__password-btn"
              src={LockPicture}
              alt="Password icon"
            />
            <span className="login__social-link-title">
              <FormattedMessage
                id="NewLogin.setPassword"
                defaultMessage="Set a password"
              />
            </span>
          </a>
        </div>
      </StructuredPopup>
    );
  }
}

export default injectIntl(PasswordPopup);
