import flatten from 'lodash/flatten';

/**
 * Selects `object` fields according to passed `levels` array with `object` keys as values:
 * - keys of `object` on even nesting levels of `levels` considered to be selected all ('or')
 * - on odd levels - firs key existing in object will be selected ('and')
 * @param {Object} object - object to get values from
 * @param {...Array[String]} levels - keys from `object` as values
 * @returns {Array[String]} - values from `object` selected by keys in `levels`
 */
export default function logicalSelect(object, levels) {
  if (!object) {
    return [];
  }

  return flatten(
    levels.map((level) => {
      if (Array.isArray(level)) {
        for (const sublevel of level) {
          const addressPart = Array.isArray(sublevel)
            ? logicalSelect(object, sublevel)
            : object[sublevel];
          if (addressPart) {
            return addressPart;
          }
        }
        return undefined;
      }
      return object[level] || undefined;
    })
  ).filter(Boolean);
}
