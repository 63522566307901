export const emojiHeads = [
  '🐶',
  '🐱',
  '🐰',
  '🦊',
  '🐻',
  '🐼',
  '🐨',
  '🐯',
  '🦁',
  '🐸',
  '🐵',
  '🐙',
  '🍏',
  '🍎',
  '🍐',
  '🍊',
  '🍋',
  '🍉',
  '🍓',
  '🥝',
  '🍅',
  '🙈',
  '🙉',
  '🙊',
  '🐭',
  '🐹',
  '🐙',
];
