import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import quests from './quests';
import publishing from './publishing';
import user from './user';
import stats from './stats';
import referal from './referal';
import partner from './partner';
import uploads from './uploads';
import codes from './codes';
import tutorial from './tutorial';
import network from './network';
import languages from './languages';
import currencies from './currencies';

const createRootReducer = (history) =>
  combineReducers({
    quests,
    publishing,
    user,
    stats,
    referal,
    partner,
    uploads,
    router: connectRouter(history),
    codes,
    tutorial,
    network,
    languages,
    currencies,
  });

export default createRootReducer;
