import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  FormattedNumber,
  defineMessages,
  injectIntl,
  intlShape,
} from 'react-intl';
import styled from 'styled-components';
import {
  LANDING_ASSETS_URL,
  APP_GOOGLE_PLAY,
  APP_APP_STORE,
} from '../../constants/branding';
import { TermsLink, PrivacyPolicyLink } from '../Legal';
import Checkbox from '../Checkbox';
import { PaymentMethods } from '../../constants/PaymentMethods';
import Button from '../Button';
import getImgBadge from '../../functions/getImgBadge';
import Tumbler from '../Tumbler';
import PricingRadio from '../PricingRadio';
import appStoreEn from '../../img/badges/App-store-badge.png';
import appStoreRu from '../../img/badges/App-store-badge-ru.png';
import googleEn from '../../img/badges/google-play-badge.png';
import googleRu from '../../img/badges/google-play-badge-ru.png';
import codeScreenEn from '../../img/branding/code-screen.png';
import codeScreenRu from '../../img/branding/code-screen-ru.png';
import './phone.css';
import './GetQuest.css';
import { QuestType } from '../../constants/PropTypes';

const badges = {
  google: {
    en: googleEn,
    ru: googleRu,
  },
  appStore: {
    en: appStoreEn,
    ru: appStoreRu,
  },
};

const codeScreen = {
  en: codeScreenEn,
  ru: codeScreenRu,
};

const messages = defineMessages({
  googleAltText: {
    id: 'GetQuest.googleAltText',
    defaultMessage: 'Available on Google Play',
  },
  appleAltText: {
    id: 'GetQuest.appleAltText',
    defaultMessage: 'Download on the App Store',
  },
});

const CheckboxMargin = styled(Checkbox)`
  margin-right: 10px;
`;

class GetQuest extends Component {
  constructor(context, props) {
    super(context, props);
    this.state = {
      // plans: [...this.props.plans],
      chosenPlan: this.props.plans.find((plan, i) => i == 0),
      paymentMethod: PaymentMethods[0], // TODO: set to null,
      consent: false,
    };
  }

  componentWillMount() {
    this.forceUpdate();
  }

  handleInputChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState((state) => ({
      [name]: value,
    }));
  };

  isFreePlan(plan) {
    return plan.price == 0;
  }

  renderPhone() {
    const { locale } = this.props.intl;
    return (
      <div
        className="phone__phone GetQuest__phone"
        style={{
          backgroundImage: `url(${LANDING_ASSETS_URL}img/iphone.png)`,
        }}
      >
        <div className="phone__screen phone__screen--iphone">
          <img className="phone__content" src={codeScreen[locale]} />
        </div>
      </div>
    );
  }

  getImageSrc(src) {
    const { locale } = this.props.intl;
    const currentLocale = getImgBadge(locale);
    const image = require(src.replace('{locale}', currentLocale));
    return image;
  }

  renderTutorial(code) {
    // TODO: add tutorial to send code
    const { formatMessage, locale } = this.props.intl;

    return (
      <div className="GetQuest__wrapper">
        <div className="GetQuest__text">
          <ol className="GetQuest__list">
            <li className="GetQuest__item">
              <FormattedMessage
                id="GetQuest.tutorialDownload"
                defaultMessage="Download App for free"
              />
              <div className="GetQuest__download">
                <a
                  className="GetQuest__button GetQuest__button--google-play"
                  href={APP_GOOGLE_PLAY}
                >
                  <img
                    src={badges.google[locale]}
                    alt={formatMessage(messages.googleAltText)}
                  />
                </a>
                <a
                  className="GetQuest__button GetQuest__button--app-store"
                  href={APP_APP_STORE}
                >
                  <img
                    src={badges.appStore[locale]}
                    alt={formatMessage(messages.appleAltText)}
                  />
                </a>
              </div>
            </li>
            <li className="GetQuest__item">
              <FormattedMessage
                id="GetQuest.tutorialCode"
                defaultMessage="Enter the {code} on the starting screen of the app"
                values={{
                  code: <span className="GetQuest__code">{code}</span>,
                }}
              />
            </li>
            <li className="GetQuest__item">
              <FormattedMessage
                id="GetQuest.tutorialWish"
                defaultMessage="Wait for notifications, go through tasks and have fun!"
              />
            </li>
          </ol>
        </div>
        <div
          className="phone__phone GetQuest__phone"
          style={{
            backgroundImage: `url(${LANDING_ASSETS_URL}img/iphone.png)`,
          }}
        >
          <div className="phone__screen phone__screen--iphone">
            <img className="phone__content" src={codeScreen[locale]} />
          </div>
        </div>
      </div>
    );
  }

  renderDemoTutorial(code) {
    // TODO: add tutorial to send code
    const { locale } = this.props.intl;
    return (
      <div className="GetQuest__wrapper">
        <div className="GetQuest__text">
          <ol className="GetQuest__list">
            <li className="GetQuest__item">
              Скачайте бесплатное приложение
              <div className="GetQuest__download">
                <a
                  className="GetQuest__button GetQuest__button--google-play"
                  href="https://play.google.com/store/apps/details?id=com.handicap.surpriseme&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                >
                  <img
                    alt="Доступно на Google Play"
                    src={`${LANDING_ASSETS_URL}img/google-play-badge.png`}
                  />
                </a>
                <a
                  className="GetQuest__button GetQuest__button--app-store"
                  href="https://itunes.apple.com/ru/app/surprize-me/id1054189818?l=ru&ls=1&mt=8"
                >
                  <img
                    src={`${LANDING_ASSETS_URL}img/App-store-badge.svg`}
                    alt="Загрузите в App Store"
                  />
                </a>
              </div>
            </li>
            <li className="GetQuest__item">
              Введите демо-код <span className="GetQuest__code">{code}</span> на
              начальном экране приложения
            </li>
            <li className="GetQuest__item">
              По-умолчанию, все задания будут пройдены. Протестируйте условия
              событий, для этого зайдите в нужно событие и щелкните на иконку
              замка на верхней панели.
            </li>
          </ol>
        </div>
        <div
          className="phone__phone GetQuest__phone"
          style={{
            backgroundImage: `url(${LANDING_ASSETS_URL}img/iphone.png)`,
          }}
        >
          <div className="phone__screen phone__screen--iphone">
            <img className="phone__content" src={codeScreen[locale]} />
          </div>
        </div>
      </div>
    );
  }

  renderIntro(eventsCount) {
    return (
      <p className="GetQuest__p">
        <FormattedMessage
          id="GetQuest.introText"
          defaultMessage="Congratulations! You've got a great tour from {eventsCount, plural, =1 {1 event} other {# events}}!"
          values={{
            eventsCount,
          }}
        />
      </p>
    );
  }

  renderOptions(plans) {
    const { chosenPlan } = this.state;
    return (
      <div className="GetQuest__plans">
        {plans.map((plan, i) => {
          const props = {
            ...plan,
            caption: plan.name,
            value: plan.id,
            name: 'pricing-option',
            checked: plan.id === chosenPlan.id,
          };
          return (
            <PricingRadio
              key={plan.id}
              {...props}
              onChange={this.handlePlanChoice}
            />
          );
        })}
      </div>
    );
  }

  renderPaymentMethod() {
    const { paymentMethod } = this.state;
    return (
      <div>
        {PaymentMethods.map((method, i) => {
          const options = {
            ...method,
            name: 'payment-method',
            checked: method.value == paymentMethod.value,
          };
          return (
            <Tumbler
              key={i}
              {...options}
              onChange={this.handlePaymentTypeChoice}
            />
          );
        })}
      </div>
    );
  }

  renderPublishNotice() {
    const {
      chosenPlan: { isEditableAfterPublish },
    } = this.state;
    if (isEditableAfterPublish)
      return (
        <p className="GetQuest__p GetQuest__notice">
          <FormattedMessage
            id="GetQuest.publishNoticeEditable"
            defaultMessage="After the publication the tour can be edited"
          />
        </p>
      );
    return (
      <p className="GetQuest__p GetQuest__notice">
        <FormattedMessage
          id="GetQuest.publishNoticeEditableUneditable"
          defaultMessage="After the publication the tour can be edited until someone has downloaded it with your code"
        />
      </p>
    );
  }

  handlePlanChoice = (e) => {
    const { plans } = this.props;
    const planId = e.target.value;
    const chosenPlan = plans.find((plan) => plan.id == planId);
    this.setState({ ...this.state, chosenPlan });
  };

  handlePaymentTypeChoice = (e) => {
    const paymentType = e.target.value;
    const paymentMethod = PaymentMethods.find(
      (method) => method.value == paymentType
    );
    this.setState({ ...this.state, paymentMethod });
  };

  getButtonText(state) {
    const plan = state.chosenPlan;
    const {
      price,
      currency: { code },
    } = plan;

    if (plan.price == 0) {
      return (
        <FormattedMessage
          id="GetQuest.checkQuestAndPayButtonTextFree"
          defaultMessage="Publish for free"
        />
      );
    }
    return (
      <FormattedMessage
        id="GetQuest.checkQuestAndPayButtonTextMoney"
        defaultMessage="Publish for {price}"
        values={{
          price: (
            <FormattedNumber
              value={price}
              style="currency"
              currency={code}
              currencyDisplay="symbol"
              minimumFractionDigits={0}
              maximumFractionDigits={2}
            />
          ),
        }}
      />
    );
  }

  handleSubmit() {
    const {
      quest: { id },
      publishingActions: { publishQuest },
    } = this.props;
    const { chosenPlan, paymentMethod = 'AC' } = this.state;
    publishQuest(id, chosenPlan.id, paymentMethod.value);
  }

  getInactiveItems(email) {
    // TODO: use mail
    const { formatMessage, locale } = this.props.intl;
    return [
      <li key={0} className="GetQuest__row GetQuest__row--inactive">
        <FormattedMessage
          id="GetQuest.inactiveItemsCodeExmp"
          defaultMessage="Get the code to the mail {email} {tagBr} For example {text}"
          values={{
            email,
            tagBr: <br />,
            text: <span className="GetQuest__code">HN7829</span>,
          }}
        />
      </li>,
      <li key={1} className="GetQuest__row GetQuest__row--inactive">
        <FormattedMessage
          id="GetQuest.inactiveItemsDownload"
          defaultMessage="Download the free app"
        />
        <div className="GetQuest__download">
          <a
            className="GetQuest__button GetQuest__button--google-play"
            href={APP_GOOGLE_PLAY}
          >
            <img
              src={badges.google[locale]}
              alt={formatMessage(messages.googleAltText)}
            />
          </a>
          <a
            className="GetQuest__button GetQuest__button--app-store"
            href={APP_APP_STORE}
          >
            <img
              src={badges.appStore[locale]}
              alt={formatMessage(messages.appleAltText)}
            />
          </a>
        </div>
      </li>,
      <li key={2} className="GetQuest__row GetQuest__row--inactive">
        <FormattedMessage
          id="GetQuest.inactiveItemsEnterCode"
          defaultMessage="Enter the code on the starting screen of the app"
        />
      </li>,
      <li key={3} className="GetQuest__row GetQuest__row--inactive">
        <FormattedMessage
          id="GetQuest.inactiveItemsAdvice"
          defaultMessage="Wait for the notifications, go through tasks and have fun!"
        />
      </li>,
    ];
  }

  render() {
    const { quest, code, user, plans } = this.props;
    const { chosenPlan } = this.state;
    const eventsCount = quest.events.length;
    if (code) {
      return (
        <div className="GetQuest__zeroWrapper">
          <div className="GetQuest__regular">
            {this.renderIntro(eventsCount)}
          </div>
          {this.renderTutorial(code)}
        </div>
      );
    }
    return (
      <div className="GetQuest__regular">
        <div className="GetQuest__instruction">
          {this.renderIntro(eventsCount)}
          <ol className="GetQuest__list">
            <li className="GetQuest__row">
              <h3 className="GetQuest__title">
                <FormattedMessage
                  id="GetQuest.publishQuest"
                  defaultMessage="Choose how to publish the tour"
                />
              </h3>
              {this.renderOptions(plans)}
            </li>
            {/*! this.isFreePlan(chosenPlan) ?
                <li className="GetQuest__row">
                  <h3 className="GetQuest__title">
                    <FormattedMessage
                      id="GetQuest.paymentMethod"
                      defaultMessage="Choose how to pay"
                    />
                  </h3>
                  <div className="GetQuest__payment-methods">
                      {this.renderPaymentMethod()}
                  </div>
                </li>
              : null */}
            <li className="GetQuest__row">
              <h3 className="GetQuest__title">
                <FormattedMessage
                  id="GetQuest.checkQuestAndPay"
                  defaultMessage="Check the tour and publish"
                />
              </h3>
              <div className="GetQuest__row">
                <label>
                  <CheckboxMargin
                    name="consent"
                    checked={this.state.consent}
                    onChange={this.handleInputChange}
                  />
                  <FormattedMessage
                    id="GetQuest.consent"
                    defaultMessage="I agree with {terms} and {privacy}"
                    values={{
                      terms: <TermsLink context="with" />,
                      privacy: <PrivacyPolicyLink context="with" />,
                    }}
                  />
                </label>
              </div>
              <Button
                theme="filled"
                size="big"
                readOnly={!this.state.consent}
                onClick={
                  this.state.consent ? this.handleSubmit.bind(this) : null
                }
              >
                {this.getButtonText(this.state)}
              </Button>
              {this.renderPublishNotice()}
            </li>
            {this.getInactiveItems(user.email)}
          </ol>
        </div>
        {this.renderPhone()}
      </div>
    );
  }
}

GetQuest.propTypes = {
  user: PropTypes.object.isRequired,
  quest: QuestType.isRequired,
  code: PropTypes.number.isRequired,
  plans: PropTypes.array.isRequired,
  publishingActions: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(GetQuest);
