/**
 * @typedef {Object} PaymentMethod
 * @prop {String} title - non-translated default title of the method (translations are in `"./intlMethods"`)
 * @prop {String} value - method id from server
 */

/**
 * Available payment methods
 * @type {Array[PaymentMethod]}
 */
export default [
  // {title: <FormattedMessage id="PaymentMethods.creditCard" defaultMessage="Credit card"/>, value: 'AC'},
  // {title: <FormattedMessage id="PaymentMethods.yandexMoney" defaultMessage="Yandex.Money"/>, value: 'PC'},
  // {title: <FormattedMessage id="PaymentMethods.free" defaultMessage="Free"/>, value: 'FC'},
  // {title: <span>WeGoTrip</span>, value: 'WE'},
  // {title: <span>iOS</span>, value: 'IP'},
  // {title: <span>Andriod</span>, value: 'AB'},
  // {title: <span>Viator</span>, value: 'VI'},
  // {title: <span>Expedia</span>, value: 'EX'},
  // {title: <span>Klook</span>, value: 'KL'},
  // {title: <span>Musement</span>, value: 'MU'},
  // {title: <span>Bokun</span>, value: 'BO'}
  { title: 'Web', value: 'WE' },
  { title: 'iOS', value: 'IS' },
  { title: 'Android', value: 'AS' },
  { title: 'OTA', value: 'OT' },
  { title: 'Unknown', value: 'UN' },
];
