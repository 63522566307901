import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FirstSteps } from './TutorialsName';
import TutorialsArray from './TutorialsArray';
import defaultStepProps from './defaultStepProps';

const FirstStepsTutorial = (props) => {
  return {
    name: FirstSteps,
    run: props.run,
    steps: [
      {
        title: (
          <FormattedMessage
            id="Tutorials.FirstSteps.Step1.title"
            defaultMessage="Hello!"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.FirstSteps.Step1.text"
            defaultMessage="Hello, user! We've prepared {tutorialsNumber} {tutorialsNumber, plural, =1 {lesson} other {lessons}} that can help you to study the constructor and create your first tour. Let's start!"
            values={{ tutorialsNumber: TutorialsArray.length }}
          />
        ),
        target: '.Logo',
        placement: 'bottom',
        ...defaultStepProps,
        spotlightPadding: 0,
      },
      {
        title: (
          <FormattedMessage
            id="Tutorials.FirstSteps.Step2.title"
            defaultMessage="Where is Profile?"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.FirstSteps.Step2.text"
            defaultMessage="To get to your personal account {boldText} on the name of your account."
            values={{
              boldText: (
                <strong>
                  <FormattedMessage
                    id="Tutorials.FirstSteps.Step2.boldText"
                    defaultMessage="click"
                  />
                </strong>
              ),
            }}
          />
        ),
        target: '.User',
        placement: 'bottom',
        ...defaultStepProps,
        spotlightPadding: 0,
      },
      {
        title: (
          <FormattedMessage
            id="Tutorials.FirstSteps.Step3.title"
            defaultMessage="What is a personal account?"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.FirstSteps.Step3.text"
            defaultMessage="In your personal account, you can edit profile settings, view your balance of charges and create referral links."
          />
        ),
        target: '.UserMenu',
        placement: 'left',
        ...defaultStepProps,
      },
      {
        title: (
          <FormattedMessage
            id="Tutorials.FirstSteps.Step4.title"
            defaultMessage="Help with the constructor"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.FirstSteps.Step4.text"
            defaultMessage="
            Here you will find some features which help you to study the constructor. {boldText} on the question mark."
            values={{
              boldText: (
                <strong>
                  <FormattedMessage
                    id="Tutorials.FirstSteps.Step4.boldText"
                    defaultMessage="Click"
                  />
                </strong>
              ),
            }}
          />
        ),
        target: '.TutorialsMenu__block',
        placement: 'top',
        ...defaultStepProps,
      },
      {
        title: (
          <FormattedMessage
            id="Tutorials.FirstSteps.Step5.title"
            defaultMessage="Features for help"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.FirstSteps.Step5.text"
            defaultMessage="In this menu you will find tutorials for the constructor, support chat, feedback form and guide for the authors."
          />
        ),
        target: '.TutorialsMenu__menu',
        placement: 'left',
        spotlightPadding: 0,
        ...defaultStepProps,
      },
      {
        title: (
          <FormattedMessage
            id="Tutorials.FirstSteps.Step6.title"
            defaultMessage="Create a tour or use tips"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.FirstSteps.Step6.text"
            defaultMessage="To create a new tour, {boldText}. Also you can take advantage of our advice on creating tours or study the demo tour."
            values={{
              boldText: (
                <strong>
                  <FormattedMessage
                    id="Tutorials.FirstSteps.Step6.boldText"
                    defaultMessage="click on the blue button"
                  />
                </strong>
              ),
            }}
          />
        ),
        target: '.QuestAdd',
        styles: {
          buttonNext: {
            display: 'none',
          },
        },
        placement: 'top',
        ...defaultStepProps,
      },
      {
        title: (
          <FormattedMessage
            id="Tutorials.FirstSteps.Step7.title"
            defaultMessage="How to create a new tour?"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.FirstSteps.Step7.text"
            defaultMessage="To create a new tour, {boldText}."
            values={{
              boldText: (
                <strong>
                  <FormattedMessage
                    id="Tutorials.FirstSteps.Step7.boldText"
                    defaultMessage="click on the blue button"
                  />
                </strong>
              ),
            }}
          />
        ),
        target: '.Quests__header-button',
        styles: {
          buttonNext: {
            display: 'none',
          },
        },
        placement: 'left',
        ...defaultStepProps,
      },
    ],
  };
};

export default FirstStepsTutorial;
