/**
 * Filters array of objects with a different array of objects by key
 * @param {object[]} toBeFiltered
 * @param {object[]} toBeSearched
 * @param {any} key
 * @returns {object[]}  array object that exist in both arrays
 */
const compareAndFilterArraysOfObjects = (toBeFiltered, toBeSearched, key) => {
  if (!Array.isArray(toBeFiltered) || !Array.isArray(toBeSearched) || !key) {
    return [];
  }
  return toBeFiltered.filter((f) =>
    toBeSearched.some((s) => s[key] === f[key])
  );
};

export default compareAndFilterArraysOfObjects;
