import React from 'react';
import startCase from 'lodash/startCase';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

/**
 * @typedef {Object} TableCellData
 * @prop {String|React::JSX} content - content of the cell
 * @prop {*} raw - raw content on which search/filtering will be performed (necessary only for `Table::withFilters(Table)`)
 * @prop {String?} pure - text variant of the content (necessary only for `Table::withExport(Table)`)
 */

/**
 * JSS styles for `TableCell` component
 * @type {React::Hook}
 */
export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '20vw',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '75vw',
    },
  },
  content: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  contentTypeNumber: {
    textAlign: 'right',
  },
  contentTypeDate: {
    textAlign: 'center',
  },
}));

/**
 * Cell of the `Table` component
 * @param {Object} $
 * @param {String} $.className - additional CSS styes to the root component (`<td />` tag)
 * @param {String} $.variant - setting for the `Typography` container around the content
 * @param {String} $.type - type of the data in cell
 * @param {React::JSX|String} $.children - cell content
 */
export default function TableCell({
  className,
  variant = 'body2',
  type = 'string',
  children,
  ...attributes
}) {
  const styles = useStyles();

  return (
    <td className={classNames(styles.root, className)} {...attributes}>
      <Typography
        component="div"
        variant={variant}
        className={classNames(
          styles.content,
          styles[`contentType${startCase(type)}`]
        )}
      >
        {children}
      </Typography>
    </td>
  );
}
