import get from 'lodash/get';
import {
  takeLatest,
  select,
  takeEvery,
  take,
  put,
  call,
} from 'redux-saga/effects';
import * as types from '../constants/ActionTypes';
import guid from '../functions/id/guid';
import Api from '../functions/Api';
import findEvent from '../functions/event/findEvent';
import findRoutePoint from '../functions/route/findRoutePoint';
import {
  addRoutePoint,
  removeRoutePoint,
  editRoutePoint as editRoutePointAction,
} from '../actions/route';
import {
  addEvent,
  deleteEvent,
  editEventHTML,
  renameEvent,
} from '../actions/events';
import { findQuest } from '../reducers/quests';
import {
  isSyncQuestEnding,
  isFetchQuestEnding,
  triggerUpdateQuestData,
} from './quests';

/**
 * Methods for API for differrent operetions with route points
 * @type {Object}
 */
const API_METHODS = {
  add: 'post',
  edit: 'put',
  remove: 'delete',
};

/**
 * Possible actions dispatched at the end of the `sendRoutePoint` saga
 * @type {Array[String]}
 */
export const SEND_ROUTE_POINT_ENDINGS = [
  types.SEND_ROUTE_POINT_SUCCESS,
  types.SEND_ROUTE_POINT_FAILURE,
];

/**
 * Checks if action is last dispatched of the `sendRoutePoint`
 * E.g. of usage:
 * ```js
 * // Check if `SEND_ROUTE_PONT` saga was ended
 * take(isSendRoutePointEnding);
 * ```
 * @param {Object} action - current action to check
 * @returns {Boolean}
 */
export function isSendRoutePointEnding(action, pointId) {
  return (
    SEND_ROUTE_POINT_ENDINGS.includes(action.type) &&
    (pointId ? action.routePoint === pointId : true)
  );
}

/**
 * Adds event and route point bound to it
 * If event with `eventId` already exist - will not create new one
 * @param {Object} $
 * @param {Number} $.questId - id of the quest point relate
 * @param {String?} $.eventId - id of the event to create
 * @param {RoutePoint} $.routePoint - route point data
 * @param {Boolean} $.isSync - should quest be refetched after all
 */
export function* addRoutePointWithEvent({
  questId,
  eventId,
  routePoint,
  isSync = true,
}) {
  const { routes: route = [], events } = findQuest(
    yield select((state) => state.quests.quests),
    questId
  );

  /** Data for route point actions */
  routePoint.order = routePoint.order >= 0 ? routePoint.order : route.length;
  const routeActionProps = {
    pointId: get(route[routePoint.order], 'guid'),
    questId,
    routePoint,
    isSync,
  };

  const boundEventGuid = eventId || guid();
  if (eventId && findEvent(events, boundEventGuid)) {
    yield put(addRoutePoint(routeActionProps));
    return;
  }

  /** Adding new event, which will be bound to geopoint */
  yield put(
    addEvent({
      questId,
      guid: boundEventGuid,
      title: routePoint.name,
      html: routePoint.description,
      time: 0,
    })
  );

  /** Adding geopoint locally */
  yield put(
    addRoutePoint({
      ...routeActionProps,
      isSync: false,
      isSend: false,
    })
  );

  /**
   * If event added successfully - send locally stored geopoint
   * If not - removes added geopoint
   */
  const endOfSyncQuest = yield take(isSyncQuestEnding);
  if (endOfSyncQuest.type !== types.SYNC_QUEST_SUCCESS) {
    yield put(
      removeRoutePoint({
        questId,
        pointId: routePoint.guid,
        isSync: false,
        isSend: false,
      })
    );
  } else {
    yield call(syncAddedRoutePoint, routeActionProps);
  }

  /** If geopoint was not created - will try to delete bound with it event */
  const endOfSendRoute = yield take((action) =>
    isSendRoutePointEnding(action, routePoint.guid)
  );
  if (endOfSendRoute.type !== types.SEND_ROUTE_POINT_SUCCESS) {
    yield put(deleteEvent({ questId, eventId: boundEventGuid }));
  }
}

/**
 * Triggers sending to backend newly added route point via `ADD_ROUTE_POINT` action
 * @param {Object} action
 * @param {Number} $.questId - id of the quest point relate
 * @param {RoutePoint} action.routePoint - added route point to sync
 * @param {Boolean} action.isSync - should quest be fetched after route point synced
 * @param {Boolean} action.isSend - should route point be sent to the backend
 */
export function* syncAddedRoutePoint({
  questId,
  routePoint,
  isSync,
  isSend = true,
}) {
  if (isSend) {
    console.log('syncAddedRoutePoint');
    yield put({
      type: types.SEND_ROUTE_POINT,
      questId,
      routePoint,
      purpose: 'add',
      isSync,
    });
  }
}

/**
 * Renames event and route point
 * @param {Object} action
 * @param {String|*} action.questId - id of the quest containing quest to rename
 * @param {String} action.eventId - id of the event to rename
 * @param {String} action.pointId - guid of the route point to rename
 * @param {String} action.title - event title to set
 */
export function* renameRoutePointWithEvent({
  questId,
  eventId,
  pointId,
  title,
}) {
  const route =
    findQuest(yield select((state) => state.quests.quests), questId).routes ||
    [];
  const routePoint = findRoutePoint(route, pointId);
  if (routePoint) {
    yield put(
      editRoutePointAction({
        ...routePoint,
        name: title,
        questId,
      })
    );
  }

  yield put(
    renameEvent({
      title,
      questId,
      eventId,
    })
  );
}

/**
 * Edits route point and bound to it event
 * @param {Object} action
 * @param {String} action.questId - id of the editing quest
 * @param {Object} action.routePoint - fields of the route point to change
 * @param {Object} action.event - data to be changed in the event description
 * @param {String?} action.event.description - will be added as event `html` content
 * @param {Boolean} action.isSync - should quest be refetched after all
 */
function* syncEditedRoutePoint({
  questId,
  routePoint,
  event = {},
  isSync = true,
}) {
  const eventId = routePoint.guid;
  const boundEvent = findQuest(
    yield select((state) => state.quests.quests),
    questId
  ).events.find((e) => e.id === eventId);
  yield put({
    type: types.SEND_ROUTE_POINT,
    questId,
    routePoint,
    purpose: 'edit',
    isSync,
  });

  if (boundEvent) {
    const html = get(
      boundEvent.content.find((c) => c.contentType === 'html'),
      'data',
      ''
    ).replace(/<(.|\n)*?>/g, '');

    if (boundEvent.title !== routePoint.name && routePoint.name)
      yield put(renameEvent({ title: routePoint.name, questId, eventId }));
    if (typeof event.description === 'string' && !html)
      yield put(
        editEventHTML({
          html: event.description,
          visible: true,
          questId,
          eventId,
        })
      );
  }
  yield put(triggerUpdateQuestData(questId));
}

/**
 * Triggers sending to backend recently removed route point via `REMOVE_ROUTE_POINT` action
 * @param {Object} action
 * @param {Number} $.questId - id of the quest point relate
 * @param {RoutePoint} action.routePoint - added route point to sync
 * @param {Boolean} action.isSync - should quest be fetched after route point synced
 * @param {Boolean} action.isSend - should route point be deleted on the backend
 */
export function* syncRemovedRoutePoint({
  questId,
  routePoint,
  isSync,
  isSend = true,
}) {
  if (isSend) {
    yield put({
      type: types.SEND_ROUTE_POINT,
      questId,
      routePoint,
      purpose: 'remove',
      isSync,
    });
  }
}

/**
 * Sends new/edited route point to the server
 * @param {Object} $
 * @param {String} $.purpose - which type of API to use (`"add"`, `"edit"` or `"remove"`)
 * @param {Number} $.questId - id of the quest to sync route points
 * @param {RoutePoint} $.routePoint - point to add/edit
 * @param {Boolean} $.isSync - should quest be refetched after all
 */
export function* sendRoutePoint({
  questId,
  routePoint,
  purpose = 'add',
  isSync = true,
}) {
  const sendData = { questId, routePoint, purpose };

  const method = API_METHODS[purpose];
  try {
    yield call(Api[method], `v2/quests/${questId}/point/`, {
      data: routePoint,
    });
  } catch (error) {
    yield put({ type: types.SEND_ROUTE_POINT_FAILURE, ...sendData });
    yield put({ type: types.NETWORK_ERROR, error });
    yield put({ type: types.FETCH_QUEST, id: questId });
    return;
  }

  if (isSync) {
    yield put({ type: types.FETCH_QUEST, id: questId });

    /**
     * Checking if quest with updated route points was correctly fetched
     */
    const dispatchedAction = yield take(isFetchQuestEnding);
    if (dispatchedAction.type !== types.RECEIVE_QUEST) {
      return;
    }
  }
  yield put({ type: types.SEND_ROUTE_POINT_SUCCESS, ...sendData });
}

export default function* watchRoute() {
  yield takeEvery(types.ADD_ROUTE_POINT_WITH_EVENT, addRoutePointWithEvent);
  yield takeEvery(
    types.RENAME_ROUTE_POINT_WITH_EVENT,
    renameRoutePointWithEvent
  );
  yield takeEvery(types.ADD_ROUTE_POINT, syncAddedRoutePoint);
  yield takeEvery(types.EDIT_ROUTE_POINT, syncEditedRoutePoint);
  yield takeEvery(types.REMOVE_ROUTE_POINT, syncRemovedRoutePoint);
  yield takeLatest(types.SEND_ROUTE_POINT, sendRoutePoint);
}
