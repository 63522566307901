import { callOnStateChange } from '../functions/memoizedState';
import attractionsWithFilteredTickets from '../functions/attractions/attractionsWithFilteredTickets';

import {
  REQUEST_PLANS,
  RECEIVE_PLANS,
  NOT_RECEIVE_PLANS,
  RECEIVE_PAYMENT,
  PUBLISH_PRODUCT,
  START_PUBLISH_PRODUCT,
  PUBLISH_PRODUCT_SUCCESS,
  PUBLISH_PRODUCT_FAILURE,
  RESET_PUBLISH_PRODUCT_ERRORS,
  SEND_PUBLISH_DATA,
  SEND_PUBLISH_DATA_SUCCESS,
  SEND_PUBLISH_DATA_FAILURE,
  REQUEST_PRODUCT,
  RECEIVE_PRODUCT,
  NOT_RECEIVE_PRODUCT,
  SEND_LISTED_DATA,
  SEND_LISTED_DATA_SUCCESS,
  SEND_LISTED_IMAGES_DATA_SUCCESS,
  SEND_LISTED_PRICE_DATA_SUCCESS,
  SEND_LISTED_DATA_FAILURE,
  POST_LISTED_DATA,
  POST_LISTED_DATA_SUCCESS,
  POST_LISTED_PRICE_DATA_SUCCESS,
  POST_LISTED_DATA_FAILURE,
  DELETE_LISTED_DATA,
  DELETE_LISTED_DATA_SUCCESS,
  DELETE_LISTED_DATA_FAILURE,
  RECEIVE_IMAGESTORE_URL,
  REORDER_PRODUCT_IMAGES,
  DELETE_IMAGE_SUCCESS,
  SEND_ATTRACTIONS_DATA,
  SEND_ATTRACTIONS_DATA_FAILURE,
  SEND_ATTRACTIONS_DATA_SUCCESS,
  SEND_TICKETS_DATA,
  SEND_TICKETS_DATA_FAILURE,
  SEND_TICKETS_DATA_SUCCESS,
  SEND_TICKETS_CATEGORIES_DATA,
  SEND_TICKETS_CATEGORIES_DATA_SUCCESS,
  SEND_TICKETS_CATEGORIES_DATA_FAILURE,
  DELETE_ATTRACTIONS_DATA_SUCCESS,
  DELETE_TICKET,
  DELETE_TICKETS_CATEGORIES_DATA_SUCCESS,
  DELETE_TICKETS_DATA_SUCCESS,
  CONVERT_AUDIO_GUIDES_PRICES,
  CONVERT_AUDIO_GUIDES_PRICES_FAILURE,
} from '../constants/ActionTypes';

export const updateAttractions = (product, currentAttractions) =>
  callOnStateChange(
    product.attractions,
    () => attractionsWithFilteredTickets(product),
    currentAttractions
  );

export default function publishing(
  state = {
    currentPlans: [],
    paymentData: {},
    errors: [],
    isFetching: false,
    isPublishing: false,
    isSaving: false,
    isSaved: false,
    isNotSaved: false,
    skeletonsCounter: [],
    receivedPublishData: {
      id: null,
      title: '',
      description: '',
      tour: {
        eventsCount: null,
      },
      images: [],
      address: '',
      locationGeo: {
        coordinates: [null, null],
      },
      finishAddress: '',
      finishLocationGeo: {
        coordinates: [null, null],
      },
      startLocation: '',
      finishLocation: '',
      distance: '',
      duration: '',
      durationMax: '',
      durationMin: '',
      locale: '',
      currency: '',
      defaultCurrency: {
        id: '',
        rate: '',
        code: '',
        symbol: '',
        name: '',
      },
      subcategories: [],
      inclusions: [],
      exclusions: [],
      price: 0,
      status: 'draft',
    },
    inclusions: [],
    exclusions: [],
    highlights: [],
    priceLevels: [],
    attractions: [],
    tickets: [],
  },
  action
) {
  let nextAttractions = [];
  let nextPriceLevels = [];

  switch (action.type) {
    case REQUEST_PLANS:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_PLANS:
      return {
        ...state,
        isFetching: false,
        currentPlans: action.plans,
      };

    case NOT_RECEIVE_PLANS:
      return {
        ...state,
        isFetching: false,
        currentPlans: [],
      };

    case RECEIVE_PAYMENT:
      return {
        ...state,
        // paymentData: action.paymentData,
        isFetching: false,
      };

    case START_PUBLISH_PRODUCT:
      return {
        ...state,
        isPublishing: true,
      };

    case PUBLISH_PRODUCT:
      return {
        ...state,
        isSaving: true,
        isNotSaved: false,
        isSaved: false,
      };

    case PUBLISH_PRODUCT_SUCCESS:
      return {
        ...state,
        receivedPublishData: {
          ...state.receivedPublishData,
          status: 'waiting_for_moderation',
        },
        isSaving: false,
        isNotSaved: false,
        isSaved: true,
      };

    case PUBLISH_PRODUCT_FAILURE:
      return {
        ...state,
        isSaving: false,
        isSaved: false,
        errors: [...state.errors, ...action.errors],
        isNotSaved: true,
      };

    case RESET_PUBLISH_PRODUCT_ERRORS:
      return {
        ...state,
        isPublishing: false,
        errors: [],
      };

    case SEND_PUBLISH_DATA:
      return {
        ...state,
        isSaving: true,
        isSaved: false,
      };

    case SEND_PUBLISH_DATA_SUCCESS:
      return {
        ...state,
        receivedPublishData: { ...action.data },
        attractions: updateAttractions(action.data, state.attractions),
        isSaving: false,
        isSaved: true,
        isNotSaved: false,
      };

    case SEND_PUBLISH_DATA_FAILURE:
      return {
        ...state,
        isSaving: false,
        isSaved: false,
        errors: [...state.errors, ...action.errors],
        isNotSaved: true,
      };

    case DELETE_LISTED_DATA:
      return {
        ...state,
        isSaving: true,
        isSaved: false,
      };

    case DELETE_LISTED_DATA_SUCCESS:
      return {
        ...state,
        [action.attribute]: state[action.attribute].filter(
          (x) => x.id !== action.id
        ),
        isSaving: false,
        isSaved: true,
        isNotSaved: false,
      };

    case DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        receivedPublishData: {
          ...state.receivedPublishData,
          [action.attribute]: state.receivedPublishData[
            action.attribute
          ].filter((x) => x.id !== action.id),
        },
        isSaving: false,
        isSaved: true,
        isNotSaved: false,
      };

    case DELETE_LISTED_DATA_FAILURE:
      return {
        ...state,
        isSaving: false,
        isSaved: false,
        errors: [...state.errors, ...action.errors],
        isNotSaved: true,
      };

    case SEND_LISTED_DATA:
      return {
        ...state,
        isSaving: true,
        isSaved: false,
      };

    case SEND_LISTED_DATA_SUCCESS:
      return {
        ...state,
        [action.attribute]: state[action.attribute].map((x) =>
          x.id === action.data.id ? action.data : x
        ),
        isSaving: false,
        isSaved: true,
        isNotSaved: false,
      };

    case SEND_LISTED_IMAGES_DATA_SUCCESS:
      return {
        ...state,
        receivedPublishData: {
          ...state.receivedPublishData,
          [action.attribute]: state.receivedPublishData[action.attribute]
            .map((x) => {
              if (x.id === action.data.id) {
                return action.data;
              }
              if (action.data.cover) {
                return { ...x, cover: false };
              }
              return x;
            })
            .sort((a, b) => a.id - b.id)
            .sort((a, b) => b.cover - a.cover),
        },
        isSaving: false,
        isSaved: true,
        isNotSaved: false,
      };

    case SEND_LISTED_PRICE_DATA_SUCCESS:
      nextPriceLevels = state.priceLevels.some(
        (plv) => plv.id === action.data.id
      )
        ? state.priceLevels.map((pl) =>
            pl.id === action.data.id ? action.data : pl
          )
        : [...state.priceLevels, action.data];

      return {
        ...state,
        receivedPublishData: {
          ...state.receivedPublishData,
          [action.attribute]: nextPriceLevels
            .map((pl) => pl.price)
            .reduce((a, b) => a + b),
        },
        priceLevels: nextPriceLevels,
        isSaving: false,
        isSaved: true,
        isNotSaved: false,
      };

    case SEND_LISTED_DATA_FAILURE:
      return {
        ...state,
        isSaving: false,
        isSaved: false,
        errors: [...state.errors, ...action.errors],
        isNotSaved: true,
      };

    case POST_LISTED_DATA:
      return {
        ...state,
        isSaving: true,
        isSaved: false,
      };

    case POST_LISTED_DATA_SUCCESS:
      return {
        ...state,
        [action.attribute]: [...state[action.attribute], action.data],
        isSaving: false,
        isSaved: true,
        isNotSaved: false,
      };

    case POST_LISTED_PRICE_DATA_SUCCESS:
      return {
        ...state,
        receivedPublishData: {
          ...state.receivedPublishData,
          [action.attribute]: action.data.price,
        },
        priceLevels: [...state.priceLevels, action.data],
        isSaving: false,
        isSaved: true,
        isNotSaved: false,
      };

    case POST_LISTED_DATA_FAILURE:
      return {
        ...state,
        isSaving: false,
        isSaved: false,
        errors: [...state.errors, ...action.errors],
        isNotSaved: true,
      };

    case RECEIVE_IMAGESTORE_URL: {
      const { imageId } = action;
      const images = [...state.receivedPublishData.images];
      const index = images.length > imageId ? imageId : images.length;
      images.splice(index, 0, ...action.images);

      return {
        ...state,
        receivedPublishData: {
          ...state.receivedPublishData,
          images,
        },
        isSaving: false,
        isSaved: true,
        isNotSaved: false,
      };
    }

    case REORDER_PRODUCT_IMAGES: {
      const { imageId, newImageId, isUpload } = action;
      if (isUpload) {
        return state;
      }

      const images = [...state.receivedPublishData.images];
      if (images.length >= newImageId && images.length > imageId) {
        const tmpImage = images.splice(imageId, 1)[0];
        images.splice(newImageId, 0, tmpImage);
      }

      return {
        ...state,
        receivedPublishData: {
          ...state.receivedPublishData,
          images,
        },
      };
    }

    case REQUEST_PRODUCT:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVE_PRODUCT:
      return {
        ...state,
        isFetching: false,
        receivedPublishData: {
          ...action.data,
          subcategories: action.data.subcategories.map((x) => x.id),
        },
        inclusions: action.data.inclusions,
        exclusions: action.data.exclusions,
        highlights: action.data.highlights,
        priceLevels: action.data.priceLevels,
        attractions: updateAttractions(action.data, state.attractions),
        tickets: action.data.tickets,
      };

    case NOT_RECEIVE_PRODUCT:
      return {
        ...state,
        isFetching: false,
        errors: [...state.errors, ...action.errors],
      };

    case SEND_ATTRACTIONS_DATA:
    case SEND_TICKETS_DATA:
    case SEND_TICKETS_CATEGORIES_DATA:
      return {
        ...state,
        isSaving: true,
      };

    case SEND_ATTRACTIONS_DATA_SUCCESS: {
      return {
        ...state,
        isSaving: false,
        isSaved: true,
        isNotSaved: false,
      };
    }

    case DELETE_ATTRACTIONS_DATA_SUCCESS:
      return {
        ...state,
        attractions: state.attractions.filter((x) => x.id !== action.id),
        isSaving: false,
        isSaved: true,
        isNotSaved: false,
      };

    case SEND_ATTRACTIONS_DATA_FAILURE:
    case SEND_TICKETS_DATA_FAILURE:
    case CONVERT_AUDIO_GUIDES_PRICES_FAILURE:
      return {
        ...state,
        isSaving: false,
        errors: [...state.errors, ...action.errors],
        isNotSaved: true,
      };

    case SEND_TICKETS_DATA_SUCCESS:
      nextAttractions = state.attractions.map((a) => {
        if (a.id === action.attractionId) {
          if (a.tickets.some((t) => t.id === action.ticketId)) {
            return {
              ...a,
              tickets: a.tickets.map((t) =>
                t.id === action.ticketId ? action.data : t
              ),
            };
          }
          return { ...a, tickets: [...a.tickets, action.data] };
        }
        return a;
      });

      return {
        ...state,
        attractions: nextAttractions,
        isSaving: false,
        isSaved: true,
        isNotSaved: false,
      };

    case DELETE_TICKETS_DATA_SUCCESS:
      nextAttractions = state.attractions.map((a) =>
        a.tickets.some((t) => t.id === action.ticketId)
          ? {
              ...a,
              tickets: a.tickets.filter((t) => t.id !== action.ticketId),
            }
          : a
      );

      return {
        ...state,
        attractions: nextAttractions,
        isSaving: false,
        isSaved: true,
        isNotSaved: false,
      };

    case SEND_TICKETS_CATEGORIES_DATA_SUCCESS:
      nextAttractions = state.attractions.map((a) =>
        a.tickets.some((t) => t.id === action.ticketId)
          ? {
              ...a,
              tickets: a.tickets.map((tc) =>
                tc.id === action.ticketId
                  ? {
                      ...tc,
                      pricingCategories: tc.pricingCategories.some(
                        (pc) => pc.id === action.data.id
                      )
                        ? tc.pricingCategories.map((pct) =>
                            pct.id === action.data.id ? action.data : pct
                          )
                        : [...tc.pricingCategories, action.data],
                    }
                  : tc
              ),
            }
          : a
      );

      return {
        ...state,
        attractions: nextAttractions,
        isSaving: false,
        isSaved: true,
        isNotSaved: false,
      };

    case DELETE_TICKET:
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        isNotSaved: false,
      };

    case DELETE_TICKETS_CATEGORIES_DATA_SUCCESS:
      nextAttractions = state.attractions.map((a) =>
        a.tickets.some((t) => t.id === action.idTicket)
          ? {
              ...a,
              tickets: a.tickets.map((t) =>
                t.id === action.idTicket
                  ? {
                      ...t,
                      pricingCategories: t.pricingCategories.some(
                        (pc) => pc.id === action.idCategory
                      )
                        ? t.pricingCategories.filter(
                            (pc) => pc.id !== action.idCategory
                          )
                        : t.pricingCategories,
                    }
                  : t
              ),
            }
          : a
      );

      return {
        ...state,
        attractions: nextAttractions,
        isSaving: false,
        isSaved: true,
        isNotSaved: false,
      };

    case SEND_TICKETS_CATEGORIES_DATA_FAILURE:
      return {
        ...state,
        isSaving: false,
        isSaved: false,
        errors: [...state.errors, ...action.errors],
        isNotSaved: true,
      };

    case CONVERT_AUDIO_GUIDES_PRICES:
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        isNotSaved: false,
      };

    default:
      return state;
  }
}
