/**
 * @typedef {Object} PaymentStatus
 * @prop {String} title - non-translated default title of the status (translations are in `"./intlStatuses"`)
 * @prop {String} value - method id from server
 */

/**
 * Available payment statuses
 * @type {Array[PaymentStatus]}
 */
export default [
  { title: 'Paid up', value: 'paid' },
  { title: 'In\u00A0processing', value: 'processing' },
  { title: 'Cancelled', value: 'cancelled' },
];
