import React from 'react';
import './RadioButton.css';

export const RadioButton = ({ label, value, checked, onChange }) => {
  return (
    <label className="RadioButton__radiobutton">
      <input
        className="RadioButton__radiobutton_input"
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className="RadioButton__radiobutton_custom"></span>
      {label}
    </label>
  );
};
