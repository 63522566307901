import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import overloadJss from '../../../functions/css/overloadJss';
import withClose from '../../Popup/withClose';
import Popup from '../../Popup';

/**
 * Popup with close button
 * @type {React::Component}
 */
const ClosablePopup = withClose(Popup);

/**
 * JSS styles for `withPopup` mod
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  modal: {
    width: '100%',
    transition: 'unset',
    transform: 'unset',
    [theme.breakpoints.up('sm')]: {
      padding: '50px',
    },
  },
  ...overloadJss(
    {
      popup: {
        margin: 0,
        height: '100%',
        width: '100%',
      },
    },
    'Popup'
  ),
}));

/**
 * Adds ability to open `PictureEditor` in `withClose(Popup)`
 * @param {React::Component} Component - component to overload
 */
export default function withPopup(Component) {
  return function ({
    visible,
    onEdited: onEditedExt = () => {},
    ...editorProps
  }) {
    const styles = useStyles();

    /**
     * Popup was closed
     * Triggers parent component to clear `imageData` buffer
     */
    const onPopupClosed = () => {
      onEditedExt(editorProps.imageData, false, true);
    };

    return (
      <ClosablePopup
        theme="material"
        className={styles.popup}
        classes={{ modal: styles.modal }}
        isOpen={visible}
        handleCloseClick={onPopupClosed}
      >
        <Component onEdited={onEditedExt} {...editorProps} />
      </ClosablePopup>
    );
  };
}
