import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PRIVACY_POLICY_LINK, TERMS_LINK } from '../../constants/branding';

export function TermsLink({ context = 'none' }) {
  return (
    <a
      className="link link--blue"
      href={TERMS_LINK}
      target="_blank"
      rel="noreferrer"
    >
      <FormattedMessage
        id="Legal.termsOfService"
        defaultMessage="{context, select, with {Terms & Conditions} none {Terms & Conditions}}"
        values={{
          context,
        }}
      />
    </a>
  );
}

export function PrivacyPolicyLink({ context = 'none' }) {
  return (
    <a
      className="link link--blue"
      href={PRIVACY_POLICY_LINK}
      target="_blank"
      rel="noreferrer"
    >
      <FormattedMessage
        id="Legal.privacyPolicy"
        defaultMessage="{context, select, with {Privacy Policy} none {Privacy Policy}}"
        values={{
          context,
        }}
      />
    </a>
  );
}
