import React from 'react';
import classNames from 'classnames';
import './Gap.css';

/**
 * Adds gap with specified sizes between component elements
 * To use only CSS of the component:
 * 1. Add ".Box" CSS class to the container with elements between which gap is desired
 * 2. Add ".BoxItem" CSS class to the elements between which gaps are desired
 * 3. Set params in CSS of the component child with ".Box" class, e.g.:
 * ```css
 * .Component {
 *   --box-gap-t: 10px; // top gap
 *   --box-gap-r: 10px; // right gap
 *   --box-gap-b: 10px; // bottom gap
 *   --box-gap-l: 10px; // left gap
 *
 *   // Other Component CSS code
 * }
 * ```
 * @param {Object} $
 * @param {String} $.className - additional CSS (does not recommended to use, do not add CSS `margin` if this prop in use)
 * @param {Object?} $.style - style attribute for root `div` element
 * @param {React::Ref} $.rootRef - ref to the root `div`
 * @param {Array[String]} $.gaps - gap margins (top, right, bottom and left) between elements with CSS measure units (`px`, `em`, etc.)
 * @param {React::JSX} $.children - elements to be gapped, wrapped into `GapItem` components
 * @param {Boolean} $.flex - `display: flex` for the root component
 * @param {...Object} $.divProps - will be passed to root `div` element
 */
export default function Gap({
  className,
  flex = true,
  gaps = [0, 0, 0, 0],
  children,
  rootRef,
  style = {},
  ...divProps
}) {
  return (
    <div
      className={classNames('Gap', flex && 'Gap--flex', className)}
      style={{
        '--box-gap-t': gaps[0],
        '--box-gap-r': gaps[1],
        '--box-gap-b': gaps[2],
        '--box-gap-l': gaps[3],
        ...style,
      }}
      {...(rootRef ? { ref: rootRef } : {})}
      {...divProps}
    >
      {children}
    </div>
  );
}
