import React, { useEffect } from 'react';
import get from 'lodash/get';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import checkChoiceSet from '../../../functions/choice/checkChoiceSet';
import DEFAULT_CHOICE from '../../../constants/defaultContent/choice';
import ButtonToggle from '../../Button/ButtonToggle';
import AccordionMultiple, {
  useAccordionState,
} from '../../Accordion/AccordionMultiple';
import Event from '../../Event';
import EventHeader from '../../Event/EventHeader';
import EventControlsChoice from '../EventControlsChoice';

/**
 * Messages for `EventControlsAdditional` component
 * @type {Object}
 */
const messages = defineMessages({
  title: {
    id: 'EventControlsAdditional.title',
    defaultMessage: 'Add additional content',
  },
  quiz: {
    id: 'EventControlsAdditional.quiz',
    defaultMessage: 'Quiz',
  },
});

/**
 * JSS styles for `EventControlsAdditional` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((_theme) => ({
  root: {
    marginBottom: '0px',
  },
  eventMargin: {
    marginBottom: '40px',
  },
  buttonIcon: {
    fontSize: '18px',
  },
}));

/**
 * Additional content selector
 * Contains hidable `EventControls...` elements: `EventControlsChoice`
 * @param {Object} $
 * @param {String|Number} $.questId - id of the current quest
 * @param {String} $.eventId - id of the current event
 * @param {Object} $.choice - content of the `"choice"` event content type, will be spread to the `EventControlsChoice` component
 * @param {Object} $.eventActions - reducers for managing event content
 * @param {Function} $.eventActions.editEventChoice - reducer for editing `"choice"` content
 */
export default injectIntl(function EventControlsAdditional({
  questId,
  eventId,
  choice = {},
  eventActions = {},
}) {
  const styles = useStyles();

  /**
   * Active (existing) additional content according to quest JSON in current event
   * @type {Array[Number]}
   */
  const initialActive = [checkChoiceSet(choice) ? 0 : null].filter(
    (i) => typeof i === 'number'
  );

  /**
   * Props for `AccordionMultiple` imitating its state
   * @type {Object}
   */
  const accordionState = useAccordionState({
    initialActive,
  });

  /**
   * Description of the list of hidable components
   * Uses for rendering and removing content from JSON after deactivating component
   * @type {Array[Object]}
   */
  const hidableComponents = [
    {
      icon: QuestionAnswerIcon,
      title: messages.quiz,
      resetReducer: () =>
        eventActions?.editEventChoice({
          questId,
          eventId,
          answer: -1,
          options: [],
          visible: false,
        }),
      resetParams: { questId, eventId, ...DEFAULT_CHOICE },
      component: (
        <EventControlsChoice
          key="choice"
          eventId={eventId}
          params={{ questId, eventId }}
          answer={get(choice, 'data.answer')}
          options={get(choice, 'data.options')}
          visible={choice?.visible}
          resetHints={eventActions?.editEventHints}
          onChange={eventActions?.editEventChoice}
        />
      ),
    },
  ];

  /**
   * One of buttons was toggled by user
   * Deletes additional content when button was deactivated
   * @param {Number} i - index of the toggled button
   * @param {Boolean} isShown - state of the toggled button
   */
  const onAdditionalToggled = (i, isShown) => {
    if (!isShown && i < hidableComponents.length) {
      hidableComponents[i].resetReducer(hidableComponents[i].resetParams);
    }

    accordionState.onChanged(i, isShown);
  };

  /**
   * Reset shown tabs in `AccordionMultiple` if `eventId` or `questId` changed
   * User navigated to another quest or event
   */
  useEffect(() => accordionState.resetActive(initialActive), [
    eventId,
    questId,
  ]);

  return (
    <Event className={styles.root}>
      <EventHeader>
        <FormattedMessage {...messages.title} />
      </EventHeader>
      <AccordionMultiple
        active={accordionState.active}
        classes={{ headerActive: styles.eventMargin }}
        buttons={hidableComponents.map(({ icon: Icon, title }) => (
          <ButtonToggle key={title.id} variant="text">
            <Icon className={styles.buttonIcon} />
            <FormattedMessage {...title} />
          </ButtonToggle>
        ))}
        onChanged={onAdditionalToggled}
      >
        {hidableComponents.map(({ component }) => component)}
      </AccordionMultiple>
    </Event>
  );
});
