import { useRef } from 'react';
import guid from './guid';
import formMediaId from './formMediaId';

/**
 * Generates unique mediaId for component and keeps it while it's in DOM
 * @param {String?} initialArrayId - if passed will be used as array id of the created media id
 * @returns {Object} - object with generated mediaId `arrayId` and `componentMediaId(index)` function fields
 */
export default function useMediaId(initialArrayId) {
  const mediaIdPrefixRef = useRef(initialArrayId || guid());
  return {
    arrayId: mediaIdPrefixRef.current,
    componentMediaId: (index) => formMediaId(mediaIdPrefixRef.current, index),
  };
}
