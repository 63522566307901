import {
  FETCH_PARTNER_STATS,
  FETCH_PARTNER_STATS_SUCCESS,
  FETCH_PARTNER_STATS_FAILURE,
  FETCH_PARTNER_DATA,
  FETCH_PARTNER_DATA_SUCCESS,
  FETCH_PARTNER_DATA_FAILURE,
  UPDATE_PARTNER_DATA,
  UPDATE_PARTNER_DATA_SUCCESS,
  UPDATE_PARTNER_DATA_FAILURE,
  OPEN_FORM_BIO_POPUP,
  CLOSE_FORM_BIO_POPUP,
  FETCH_PAYMENTS_STATS,
  FETCH_PAYMENTS_STATS_SUCCESS,
  FETCH_PAYMENTS_STATS_FAILURE,
  FETCH_AFFILIATE_PARTNER,
  FETCH_AFFILIATE_PARTNER_SUCCESS,
  FETCH_AFFILIATE_PARTNER_FAILURE,
  UPDATE_AFFILIATE_PARTNER_SUCCESS,
  UPDATE_AFFILIATE_PARTNER,
  UPDATE_AFFILIATE_PARTNER_FAILURE,
  FETCH_TOTAL_INCOME_SUCCESS,
  FETCH_TOTAL_INCOME_FAILURE,
} from '../constants/ActionTypes';
import getPaginatedData from '../functions/getPaginatedData';

const blankSummary = [
  ['week', 0],
  ['month', 0],
  ['available', 0],
];

function createSummary(stats) {
  // TODO: month and week totals
  return [
    ['week', stats.week.toFixed(2)],
    ['month', stats.month.toFixed(2)],
    ['available', stats.available.toFixed(2)],
  ];
}

export default function partner(
  state = {
    unpaid: [],
    paid: [],
    summary: [...blankSummary],
    paymentStats: null,
    errors: [],
    data: {
      rating: null,
    },
    affiliate: {
      id: null,
      sites: '',
      description: '',
    },
    isFetching: false,
    isSending: false,
    isSaved: false,
    isNotSaved: false,
    isFormBioPopupOpen: false,
  },
  action
) {
  switch (action.type) {
    case FETCH_PARTNER_STATS:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_PARTNER_STATS_SUCCESS:
      return {
        ...state,
        summary: createSummary(action.stats),
        unpaid: action.products,
        paidTill: action.paidTill,
        currencyCode: action.currencyCode,
        isFetching: false,
      };

    case FETCH_PARTNER_STATS_FAILURE:
      return {
        ...state,
        error: [...state.errors, action.error],
        isFetching: false,
      };

    case FETCH_PARTNER_DATA:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_PARTNER_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
      };

    case FETCH_PARTNER_DATA_FAILURE:
      return {
        ...state,
        error: [...state.errors, action.error],
        isFetching: false,
      };

    case UPDATE_PARTNER_DATA:
      return {
        ...state,
        isSending: true,
        isNotSaved: false,
        isSaved: false,
      };

    case UPDATE_PARTNER_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSending: false,
        isNotSaved: false,
        isSaved: true,
      };

    case UPDATE_PARTNER_DATA_FAILURE:
      return {
        ...state,
        error: [...state.errors, action.error],
        isSending: false,
        isNotSaved: true,
        isSaved: false,
      };

    case OPEN_FORM_BIO_POPUP:
      return {
        ...state,
        isFormBioPopupOpen: true,
      };

    case CLOSE_FORM_BIO_POPUP:
      return {
        ...state,
        isFormBioPopupOpen: false,
      };

    case FETCH_PAYMENTS_STATS:
    case FETCH_AFFILIATE_PARTNER:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_PAYMENTS_STATS_SUCCESS:
      return {
        ...state,
        paymentStats: getPaginatedData(action.payload),
        isFetching: false,
      };

    case FETCH_PAYMENTS_STATS_FAILURE:
    case FETCH_AFFILIATE_PARTNER_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case UPDATE_AFFILIATE_PARTNER:
      return {
        ...state,
        isSending: true,
        isNotSaved: false,
        isSaved: false,
      };

    case FETCH_AFFILIATE_PARTNER_SUCCESS:
      return {
        ...state,
        affiliate: action.data,
        isFetching: false,
      };

    case UPDATE_AFFILIATE_PARTNER_FAILURE:
      return {
        ...state,
        error: [...state.errors, action.error],
        isSending: false,
        isNotSaved: true,
        isSaved: false,
      };

    case UPDATE_AFFILIATE_PARTNER_SUCCESS:
      return {
        ...state,
        isSending: false,
        isNotSaved: false,
        isSaved: true,
      };

    case FETCH_TOTAL_INCOME_SUCCESS:
      return {
        ...state,
        totalEarned: action.totalIncome,
      };

    case FETCH_TOTAL_INCOME_FAILURE:
      return {
        ...state,
        error: [...state.errors, action.error],
      };

    default:
      return state;
  }
}
