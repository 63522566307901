import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';
import Button from '../Button';
import { selectInputValue } from '../../functions/input';
import { isMobile } from '../../functions/queries';
import { DuplicateIcon } from '../Icon';
import InputWithButtons from '../InputWithButtons';
import './InputCopy.css';

const messages = defineMessages({
  copyButtonText: {
    id: 'InputCopy.copyButtonText',
    defaultMessage: 'Copy',
  },
  copiedButtonText: {
    id: 'InputCopy.copiedButtonText',
    defaultMessage: 'Copied',
  },
});

class InputCopy extends Component {
  state = {
    isCopied: false,
  };

  copy() {
    if (!this._input) {
      return;
    }

    selectInputValue(this._input);

    try {
      const result = document.execCommand('copy');
      this.setState({ isCopied: true });
      // Display success message "Copied!"
    } catch (error) {
      // Display error message
    }
  }

  render() {
    const { isCopied } = this.state;
    const {
      className,
      onChange = () => {},
      children,
      intl: { formatMessage },
      ...props
    } = this.props;
    return (
      <div className={classNames('InputCopy', className)}>
        <InputWithButtons
          className="InputCopy__input"
          onRef={(input) => (this._input = input)}
          onChange={(event) => {
            event.persist();
            onChange(event);
            this.setState({
              isCopied: false,
            });
          }}
          {...props}
        >
          <Button
            className="InputCopy__copy"
            theme="main-light"
            onClick={() => {
              setTimeout(() => {
                this.copy();
              }, 0);
            }}
          >
            {isCopied ? (
              isMobile() ? (
                <DuplicateIcon
                  title={formatMessage(messages.copiedButtonText)}
                />
              ) : (
                formatMessage(messages.copiedButtonText)
              )
            ) : isMobile() ? (
              <DuplicateIcon title={formatMessage(messages.copyButtonText)} />
            ) : (
              formatMessage(messages.copyButtonText)
            )}
          </Button>
          {children}
        </InputWithButtons>
      </div>
    );
  }
}

InputCopy.propTypes = {
  id: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

export default injectIntl(InputCopy);
