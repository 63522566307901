import React, { Component } from 'react';

export const SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

export default class SortHeaderCell extends Component {
  onSortChange = (e) => {
    e.preventDefault();
    const { onSortChange, columnKey, sortDir } = this.props;
    if (!onSortChange) {
      return;
    }
    onSortChange(
      columnKey,
      sortDir ? reverseSortDirection(sortDir) : SortTypes.ASC
    );
  };

  render() {
    const { sortDir, columnKey, children, onSortChange, ...props } = this.props;
    return (
      <th {...props}>
        <a
          className="StatsTable__cell StatsTable__cell--clickable"
          onClick={this.onSortChange}
        >
          {children} {sortDir ? (sortDir === SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </th>
    );
  }
}
