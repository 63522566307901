import React from 'react';
import merge from 'lodash/merge';
import classNames from 'classnames';
import useBreakpoint from '../../functions/layout/useBreakpoint';
import './Event.css';

/**
 * Bottom margins for `Event` component
 * @type {Object}
 */
const MARGINS = {
  default: {
    xs: 32,
    sm: 40,
  },
  sub: {
    xs: 24,
    sm: 32,
  },
};

/**
 * Gets margins for `Event` component from its variant and layout
 * @param {String} variant - variant of the component (see `variant` prop of the `Event`)
 * @param {String} layout - current layout bottom material-ui breakpoint
 * @returns {Number} - bottom margin of the component
 */
function getEventMargin(variant, layout) {
  const layoutMargins = merge({}, MARGINS.default, MARGINS[variant]);
  return layoutMargins[layout];
}

/**
 * Forms JSS style object for differrent layouts of `variant` variant
 * @param {MaterialUI::Theme} theme - current `material-ui` theme (from `makeStyles`)
 * @param {String} variant - variant of the `Event` component (see `variant` prop of the `Event`)
 * @param {String?} propName - name of the JSS prop to write (default is `"marginBottom"`)
 */
export function getEventMarginStyles(
  theme,
  variant,
  formProp = (v) => v,
  propName = 'marginBottom'
) {
  const layoutMargins = merge({}, MARGINS.default, MARGINS[variant]);
  return Object.keys(layoutMargins).reduce(
    (acc, l) => ({
      ...acc,
      [theme.breakpoints.up(l)]: {
        [propName]: formProp(`${layoutMargins[l]}px`),
      },
    }),
    {}
  );
}

/**
 * Root component for mapping one type of the content of the event (e.g. - `Pictures`, `Audio`, etc.)
 * @param {Object} $
 * @param {String} $.className - additional CSS class names
 * @param {String} $.variant - variants of the component (`"default"` or `"sub"`)
 * @param {React::Component|Array[React::Component]} $.children - one type content displaying and editind components
 * @param {Boolean} $.margin - bottom margin of the component
 * @param {Function} $.onTouchEnd - end of the touch event on the root `HTMLElement`
 */
export default function Event({
  children,
  className,
  variant,
  margin = true,
  onTouchEnd = () => {},
}) {
  const layout = useBreakpoint(['sm']);
  const marginValue = getEventMargin(variant, layout);

  return (
    <div
      style={{ '--event-row-margin': `${marginValue}px` }}
      className={classNames(
        'Event',
        margin && 'Event--margin',
        variant && `Event--${variant}`,
        className
      )}
      onTouchEnd={onTouchEnd}
    >
      {children}
    </div>
  );
}
