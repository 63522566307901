import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import WidgetStats from '../../WidgetStats';

const useStyles = makeStyles((theme) => ({
  widgetStatsContainer: {
    marginTop: 16,
    overflowX: 'auto',
    minHeight: 400,
  },
}));

const ReportViews = ({
  locale,
  widgetStats,
  referalActions,
  referralErrors,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.widgetStatsContainer}>
      <WidgetStats
        locale={locale}
        widgetStats={widgetStats}
        fetchWidgetStats={referalActions.fetchWidgetStats}
        isFetchingWidgetStatsError={referralErrors.includes(
          'fetchingWidgetStatsError'
        )}
      />
    </div>
  );
};

export default ReportViews;
