import React, { useRef, useState, useEffect } from 'react';
import { isArray, throttle } from 'lodash';
import parse from 'autosuggest-highlight/parse';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import loadScript from '../../functions/dom/loadScript';

const autocompleteService = { current: null };
const geocoder = { current: null };

/**
 * @typedef {Object} PlacesAutocompleteOption
 * @prop {String} description - description of the place (will be shown in `PlacesAutocomplete` as option name)
 * @prop {String?} place_id - id of the place in GoogleMaps API
 * @prop {Boolean?} no_coords - disables coords fetching before dispatching `onChange` in `PlacesAutocomplete`
 */

/**
 * JSS styles for `PlacesAutocomplete`
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

/**
 * Component implimentation of the google AutocompleteService API
 * @param {Object} $
 * @param {String?} $.noOptionsText - will be displayed instead of items if none of them found
 * @param {Array[PlacesAutocompleteOption]} $.optionsExtra - will be added to dropdown as additional selectable options
 * @param {Boolean?} $.clearOnBlur - will clear autocomplete value when focus was lost
 * @param {Boolean?} $.clearAfterPick - will clear autocomplete value after picking result
 * @param {Boolean?} $.onlyPlaces - search will output only places (without countries and cities)
 * @param {Boolean?} $.withValueProp - should be true if you pass the value prop
 * @param {Function?} $.onInputChangeExtra - calls when user changed text in autocomplete's input
 * @param {Function?} $.onChange - calls when user selected something from autocomplete and coords of the place were loaded
 * @param {...Object} $.autocompleteProps - will be spread to original material-ui `Autocomplete` component
 */
export default function PlacesAutocomplete({
  noOptionsText,
  clearOnBlur,
  clearAfterPick,
  withValueProp,
  onlyPlaces,
  onlyCities,
  optionsExtra = [],
  onInputChange: onInputChangeExtra = () => {},
  onChange = () => {},
  ...autocompleteProps
}) {
  const classes = useStyles();
  const [value, setValue] = useState(autocompleteProps.multiple ? [] : null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([...optionsExtra]);
  const loaded = useRef(false);

  /**
   * Checking out if Google Maps API was loaded
   */
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAWr88v7aEfDqUwhr7xXpCBAJLosyj_4Ms&libraries=places',
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  /**
   * Throttled function for fetching matched location names from `AutocompleteService` API
   * @type {React::Ref}
   */
  const fetch = useRef(
    throttle((request, callback) => {
      autocompleteService.current.getPlacePredictions(request, callback);
    }, 200)
  );

  /**
   * Gets coords of the place by it's `placeId`
   * @param {String} placeId - ID of the place in GoogleMaps
   * @returns {Object} - answer from google `Geocoder::geocode()` Google API method
   */
  const getPlaceCoords = async (placeId) => {
    // eslint-disable-next-line no-return-await
    return await geocoder.current.geocode({ placeId });
  };

  /**
   * User wrote something in autocomplete's input
   * @param {Object} event - original DOM event
   * @param {String} newInputValue - string entered in autocomplete's input
   */
  const onInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    onInputChangeExtra(newInputValue);
  };

  /**
   * User selected item from autocomplete component
   * @param {Object} event - original DOM event
   * @param {PlacesAutocompleteOption} newValue - description of selected value from autocomplete
   */
  const onAutocompleteChanged = async (event, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    if (!clearOnBlur && !withValueProp) setValue(newValue);

    let additional = {};
    if (newValue && newValue.place_id && !newValue.no_coords) {
      const coords = await getPlaceCoords(newValue.place_id);
      additional = {
        ...coords.results[0],
        lat: coords.results[0].geometry.location.lat(),
        lng: coords.results[0].geometry.location.lng(),
      };
    }

    if (isArray(newValue)) {
      const withAdditional = await Promise.all(
        newValue.map(async (place) => {
          if (place.place_id && !place.no_coords && !place.lat && !place.lng) {
            const geo = await getPlaceCoords(place.place_id).then(
              ({ results }) => results[0]
            );
            return {
              ...place,
              ...geo,
              lat: geo.geometry.location.lat(),
              lng: geo.geometry.location.lng(),
            };
          }
          return place;
        })
      );

      onChange(event, withAdditional);
    } else {
      onChange(event, {
        ...newValue,
        ...additional,
      });
    }
  };

  /**
   * User selected option and gets label in autocomplete's input (setValue didn't clear input if we want)
   * @param {Object} option - selected option
   * @returns {String} - string for autocomplete's input
   */
  const getOptionLabel = (option) => {
    if (clearAfterPick) {
      return '';
    }
    if (typeof option === 'string') return option;
    return option.description || option.name;
  };

  /**
   * Initialize google services and updating options
   */
  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && !geocoder.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      geocoder.current = new window.google.maps.Geocoder();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch.current(
      { input: inputValue, types: onlyCities && ['(cities)'] },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }
          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch.current]);

  /**
   * Gets filtered results of autocompleteService
   * @returns {Array[Object]} - filtered results
   */
  const getOnlyPlaces = () => {
    return [...optionsExtra, ...options].filter(
      (x) =>
        !(
          x.terms.length < 4 &&
          (x.types.includes('locality') || x.types.includes('country'))
        )
    );
  };

  return (
    <Autocomplete
      autoComplete
      includeInputInList
      filterSelectedOptions
      getOptionLabel={getOptionLabel}
      filterOptions={(x) => x}
      options={
        onlyPlaces
          ? getOnlyPlaces()
          : [...optionsExtra, ...options.filter((o) => o.length !== 0)] // remore empty array option
      }
      noOptionsText={noOptionsText || 'No options'}
      value={value}
      onChange={onAutocompleteChanged}
      onInputChange={onInputChange}
      {...autocompleteProps}
      renderOption={(option) => {
        let matches = [];
        let parts = [];
        if (option.structured_formatting?.main_text_matched_substrings) {
          matches = option.structured_formatting?.main_text_matched_substrings;
          parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );
        }

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting?.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
