import React from 'react';
import merge from 'lodash/merge';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

/**
 * JSS styles for `PopupButtons` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  controls: {
    alignSelf: 'flex-end',
    marginTop: '20px',
    '& > *:not(:last-child)': {
      marginRight: '25px',
    },
  },
}));

/**
 * Default `PopupButtons` variant name (from `variants` hash map)
 * @type {String}
 */
const DEFAULT_VARIANT = 'outlinedAction';

/**
 * Hash map for `PopupButtons` variants
 * @type {Object}
 */
const variants = {
  outlinedAction: {
    buttons: {
      default: { variant: 'outlined', color: 'primary' },
      action: { variant: 'contained', color: 'primary' },
      actionPosition: (b = []) => b.length - 1,
    },
  },
  outlinedActionSecondary: {
    buttons: { action: { color: 'secondary' } },
  },
};

/**
 * Converts variant name to props for `PopupButtons` children
 * @param {String} variantName - `PopupButtons` variant name from `variants` keys
 * @param {Number} buttons - props of buttons to render
 * @returns {Object}
 */
function getVariantProps(variantName, buttons = []) {
  const variant = merge({}, variants[DEFAULT_VARIANT], variants[variantName]);
  const actionPosition = variant.buttons.actionPosition(buttons);

  return {
    buttons: buttons.map((b, i) =>
      i === actionPosition ? variant.buttons.action : variant.buttons.default
    ),
  };
}

/**
 * Extends `Popup` component by adding buttons to it's bottom
 * @param {Object} $
 * @param {String} $.variant - component variant name (`"outlinedAction"` available)
 * @param {Array[Object]} $.buttons - array with additional props for `material-ui::Button` components
 */
export default function PopupButtons({
  variant: variantName = 'outlinedAction',
  buttons = [],
}) {
  const styles = useStyles();
  const variant = getVariantProps(variantName, buttons);

  return buttons && buttons.length ? (
    <div className={styles.controls}>
      {buttons.map((buttonProps, i) => (
        <Button
          key={buttonProps.children}
          {...variant.buttons[i]}
          {...buttonProps}
        />
      ))}
    </div>
  ) : null;
}
