import React from 'react';
import grey from '@material-ui/core/colors/grey';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';

/**
 * JSS styles for `TableFilterButton` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `1px solid ${grey[300]}`,
    padding: '8px',
    textAlign: 'right',
  },
}));

/**
 * Button for applying filtering criteria
 * @param {Object} $
 * @param {Boolean} $.disabled - if button is disabled
 * @param {Function} $.onApplied - calls after user pressed button
 */
export default function TableFilterButton({ disabled, onApplied }) {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Button
        disabled={disabled}
        color="primary"
        variant="text"
        onClick={onApplied}
      >
        <FormattedMessage id="TableFilterButton.apply" defaultMessage="Apply" />
      </Button>
    </div>
  );
}
