/* eslint-disable global-require */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import createRootReducer from '../reducers';
import getSagas, { createDynamicSaga } from '../sagas';
import createLogger from './logger';

const DEBUG = process.env.NODE_ENV !== 'production';

const sagaMiddleware = createSagaMiddleware();
const logger = createLogger();

export default function configureStore(history, initialState) {
  const middleware = [
    thunk,
    sagaMiddleware,
    routerMiddleware(history),
    DEBUG && logger,
  ].filter(Boolean);

  let composeEnhancers = compose;
  // if (DEBUG && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  //   composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  // }
  if (DEBUG) {
    composeEnhancers = composeWithDevTools;
  }

  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );
  sagaMiddleware.run(createDynamicSaga('SET_SAGAS', getSagas()));

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers/index');
      store.replaceReducer(nextReducer);
    });

    module.hot.accept('../sagas', () => {
      const getNewSagas = require('../sagas/index');
      store.dispatch({ type: 'SET_SAGAS', sagas: getNewSagas() });
    });
  }

  return store;
}
