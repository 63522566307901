import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Tooltip from 'react-tooltip';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import ru from 'react-intl/locale-data/ru';
import getParams from '../../functions/getParams';
import { paths } from '../../constants/paths';
import queryString from '../../functions/URL/parseQuery';

import * as UserActions from '../../actions/user';
import * as QuestActions from '../../actions/quests';
import * as PublishingActions from '../../actions/publishing';
import * as TutorialActions from '../../actions/tutorial';

import Hijacked from '../Hijacked';
import ErrorBoundary from '../ErrorBoundary';
import Authenticated from '../Authenticated';
import Tutorial from '../Tutorial';
import ErrorBadge from '../ErrorBadge';
import Header from '../Header';
import EventPage from '../EventPage';
import Profile from '../Profile';
import PasswordPopup from '../PasswordPopup';
import MovedUserPopup from '../MovedUserPopup';
import { isDesktop } from '../../functions/queries';

import getLocaleData from '../../i18n';
import { APP_BASE } from '../../constants/branding';
import { PreviewContext } from './AppPreviewContext';
import './App.css';
import preventFocusOnClick from '../../functions/preventFocusOnClick';

addLocaleData([...en, ...ru]);

const language =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

/**
 * Height of `Header` component used in `App`
 * @type {String}
 */
export const APP_HEADER_HEIGHT = '60px';

class App extends Component {
  state = {
    isSidebarOpen: false,
    isDemoOpen: false,
    isPopupOpen: false,
    isResizeBlock: false,
    isTutorialsMenuDescVisible: false,
    isTourCardOpen: false,
  };

  toggleSidebar = () => {
    this.setState((prevState) => {
      const isOpen = !prevState.isSidebarOpen;
      return {
        ...prevState,
        isSidebarOpen: !prevState.isSidebarOpen,
        isDemoOpen: isOpen ? false : prevState.isDemoOpen,
      };
    });
  };

  toggleDemo = () => {
    this.setState((prevState) => {
      const isOpen = !prevState.isDemoOpen;
      return {
        ...prevState,
        isDemoOpen: !prevState.isDemoOpen,
        isSidebarOpen: isOpen ? false : prevState.isSidebarOpen,
      };
    });
  };

  toggleResizeBlock = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        isResizeBlock: !prevState.isResizeBlock,
      };
    });
  };

  closeSidebar = () => {
    this.setState({
      ...this.state,
      isSidebarOpen: false,
    });
  };

  resetState = () => {
    this.setState({
      ...this.state,
      isSidebarOpen: false,
      isDemoOpen: false,
      isPopupOpen: false,
      isResizeBlock: false,
      isTourCardOpen: false,
    });
  };

  openPopup = () => {
    const {
      publishingActions: { openPublishPopup },
    } = this.props;

    const { isSidebarOpen } = this.state;
    if (isSidebarOpen) {
      this.closeSidebar();
    }

    openPublishPopup();
    this.setState({ isPopupOpen: true });
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  toggleTutorialsMenuDesc = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        isTutorialsMenuDescVisible: true,
      };
    });

    setTimeout(() => {
      return this.setState((prevState) => {
        return {
          ...prevState,
          isTutorialsMenuDescVisible: false,
        };
      });
    }, 2000);
  };

  componentDidMount() {
    const {
      userActions: { fetchUserIfNeeded },
      questActions: { fetchQuests },
    } = this.props;
    fetchUserIfNeeded({ nextLink: window.location.href });
    fetchQuests();
    preventFocusOnClick();

    // TODO: handle update
  }

  render() {
    const {
      isSidebarOpen,
      isDemoOpen,
      isPopupOpen,
      isResizeBlock,
      isTutorialsMenuDescVisible,
    } = this.state;
    const {
      user,
      userActions,
      userActions: { unhijackUser, deleteQuest, addQuest },
      user: {
        locale = languageWithoutRegionCode,
        isHijacked = false,
        isStaff = false,
        isSocialSet,
        isPasswordSet,
        groups = [],
      },
      tutorial,
      tutorialActions,
      location,
      location: { pathname },
      quests,
      stats,
    } = this.props;
    const queryParams = queryString(this.props.location.search);
    const passPopupMode = queryParams.passwordpopupmode;

    const isPasswordPopupOpen = () => {
      if (isHijacked || isStaff) return false;
      if (isPasswordSet || isSocialSet) return passPopupMode === 'done';
      return true;
    };

    return (
      <ErrorBoundary
        message={
          <p>
            Oops. Error occured, we received a report about this. Please try
            again later.{' '}
            <a href={APP_BASE} className="link link--blue">
              Go to all tours
            </a>
          </p>
        }
      >
        <IntlProvider
          key={locale}
          locale={locale}
          messages={getLocaleData(locale)}
        >
          <div className={`App${isHijacked ? ' App--hijacked' : ''}`}>
            <Authenticated user={user}>
              <PasswordPopup
                isOpen={isPasswordPopupOpen()}
                mode={passPopupMode}
                locale={locale}
                userActions={userActions}
              />
              {/* <TutorialsMenu
                quests={quests}
                tutorial={tutorial}
                tutorialActions={tutorialActions}
                user={user}
                isTutorialsMenuDescVisible={isTutorialsMenuDescVisible}
                params={getParams(pathname, paths)}
              /> */}
              <MovedUserPopup isOpen={groups.includes('Moved')} user={user} />
              {isDesktop() ? (
                <Tutorial
                  tutorial={tutorial}
                  toggleTutorialsMenuDesc={this.toggleTutorialsMenuDesc}
                  tutorialActions={tutorialActions}
                />
              ) : null}
              <ErrorBadge />
              <Route
                path="/"
                render={({ location: { pathname } }) => (
                  <Fragment>
                    {isHijacked ? (
                      <Hijacked userName={user.email} release={unhijackUser} />
                    ) : null}
                    <Header
                      isSidebarOpen={isSidebarOpen}
                      toggleSidebar={this.toggleSidebar}
                      isDemoOpen={isDemoOpen}
                      toggleDemo={this.toggleDemo}
                      isPopupOpen={isPopupOpen}
                      openPopup={this.openPopup}
                      closePopup={this.closePopup}
                      closeSidebar={this.closeSidebar}
                      isResizeBlock={isResizeBlock}
                      tutorial={tutorial}
                      tutorialActions={tutorialActions}
                      params={getParams(pathname, paths)}
                    />
                  </Fragment>
                )}
              />
              <Switch>
                {/* <Route
                  path="/"
                  render={() => (
                    <ProfileTours
                      deleteQuest={deleteQuest}
                      quests={quests}
                      stats={stats}
                    />
                  )}
                /> */}
                {/* <Route
                  exact
                  path={pathname === '/firststeps' ? '/:anchor' : '/'}
                  render={() => (
                    <Quests
                      closeSidebar={this.closeSidebar}
                      tutorial={tutorial}
                      tutorialActions={tutorialActions}
                    />
                  )}
                /> */}
                <Route
                  path="/quest/:questId/"
                  render={({ location: { pathname } }) => (
                    <PreviewContext.Provider
                      value={{
                        togglePreview: this.toggleDemo,
                        isPreviewShown: isDemoOpen,
                      }}
                    >
                      <EventPage
                        locale={locale}
                        params={getParams(pathname, paths)}
                        isSidebarOpen={isSidebarOpen}
                        toggleSidebar={this.toggleSidebar}
                        closeSidebar={this.closeSidebar}
                        isPopupOpen={isPopupOpen}
                        openPopup={this.openPopup}
                        closePopup={this.closePopup}
                        isResizeBlock={isResizeBlock}
                        toggleResizeBlock={this.toggleResizeBlock}
                        resetState={this.resetState}
                      />
                    </PreviewContext.Provider>
                  )}
                />
                <Route
                  path="/"
                  render={() => (
                    <Profile
                      closeSidebar={this.closeSidebar}
                      isSidebarOpen={isSidebarOpen}
                      isResizeBlock={isResizeBlock}
                    />
                  )}
                />
                {/* <Redirect from="stats" to="stats/all"/> */}
                {/* <Redirect exact path="/profile/" to="/profile/personal/" /> */}

                <Route render={() => <Redirect to="/" />} />
              </Switch>
            </Authenticated>
            <Tooltip place="bottom" />
          </div>
        </IntlProvider>
      </ErrorBoundary>
    );
  }
}

App.propTypes = {
  questActions: PropTypes.objectOf(PropTypes.func).isRequired,
  userActions: PropTypes.objectOf(PropTypes.func).isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    stats: state.stats,
    quests: state.quests.quests,
    tutorial: state.tutorial,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
    questActions: bindActionCreators(QuestActions, dispatch),
    publishingActions: bindActionCreators(PublishingActions, dispatch),
    tutorialActions: bindActionCreators(TutorialActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
