import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import MicIcon from '@material-ui/icons/Mic';
import { MAX_AUDIO_SIZE } from '../../../constants/limits';
import Dropzone from '..';

/**
 * Messages for `DropzoneAudios` component
 * @type {Object}
 */
const messages = defineMessages({
  title: {
    id: 'DropzoneAudios.title',
    defaultMessage:
      'Drag audio file, or click here to select.\nNo more than {size}MB: MP3, M4V',
  },
});

/**
 * Dropzone for handling audios
 * @param {Object} $
 * @param {...Object} - will be passed into `Dropzone` component
 */
export default injectIntl(function DropzoneAudio({
  maxAmount = 1,
  maxSize = MAX_AUDIO_SIZE,
  children,
  intl: { formatMessage },
  ...dropzoneProps
}) {
  return (
    <Dropzone
      icon={MicIcon}
      title={formatMessage(messages.title, {
        size: Math.round(maxSize / (1024 * 1024 * 2)),
      })}
      accepts="audio/mpeg, audio/mp3, audio/x-m4a, audio/m4a"
      maxSize={maxSize / 2}
      maxAmount={maxAmount}
      {...dropzoneProps}
    >
      {children}
    </Dropzone>
  );
});
