import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import './DemoButton.css';

class DemoButton extends Component {
  render() {
    const { isDemoOpen, toggleDemo, isSidebarOpen } = this.props;
    return (
      <div
        className={classNames(
          'DemoButton__block',
          isDemoOpen && 'DemoButton__block--on',
          isSidebarOpen && 'DemoButton__block--off'
        )}
        onClick={toggleDemo}
      >
        <div className="DemoButton__icon" />
        <div className="DemoButton__text">
          <FormattedMessage id="DemoButton.text" defaultMessage="DEMO" />
        </div>
      </div>
    );
  }
}

export default DemoButton;
