import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Desktop } from '../../functions/queries';
import { HELP_CENTER_URL } from '../../constants/branding';
import './UserMenu.css';

class UserMenu extends Component {
  render() {
    const {
      active,
      logoutUser,
      closeSidebar,
      userName = null,
      user,
    } = this.props;
    return (
      <div className={classNames('UserMenu', active && 'UserMenu--active')}>
        <nav className="UserMenu__nav">
          {userName}
          <Link
            to="/profile/reports/views"
            className="UserMenu__actions"
            onClick={closeSidebar}
          >
            <FormattedMessage id="UserMenu.reports" defaultMessage="Reports" />
          </Link>
          <Link to="/" className="UserMenu__actions" onClick={closeSidebar}>
            <FormattedMessage id="UserMenu.tours" defaultMessage="Tours" />
          </Link>
          <Link
            to="/profile/reviews"
            className="UserMenu__actions"
            onClick={closeSidebar}
          >
            <FormattedMessage id="UserMenu.reviews" defaultMessage="Reviews" />
          </Link>
          <a
            className="UserMenu__actions"
            href={`${HELP_CENTER_URL}${user.locale}/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              id="UserMenu.helpCentre"
              defaultMessage="Help center"
            />
          </a>
          <Link
            to="/profile/settings"
            className="UserMenu__actions"
            onClick={closeSidebar}
          >
            <FormattedMessage
              id="UserMenu.settings"
              defaultMessage="Settings"
            />
          </Link>
          <Desktop>
            <a className="UserMenu__actions" onClick={logoutUser}>
              <FormattedMessage id="UserMenu.logout" defaultMessage="Quit" />
            </a>
          </Desktop>
        </nav>
      </div>
    );
  }
}

export default UserMenu;
