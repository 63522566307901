import { useRef } from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

/**
 * Returns bottom breakpoint of the current layout:
 * - `xs`: mobile device
 * - `sm`: other devices
 * Requires `material-ui::ThemeProvider` installed in parent part of the DOM
 * @param {Array[String]?} toWatchExt - breakpoints to choose from (by default - all enabled), immutable,
 *   must be listed in ascendant order, does not include `xs` - it checks by default
 * @returns {String}
 */
export default function useBreakpoint(toWatchExt = ['sm']) {
  const toWatch = useRef(toWatchExt);

  const theme = useTheme();
  let chosenBreakpoint = 'xs';

  toWatch.current.forEach((breakpoint) => {
    if (useMediaQuery(theme.breakpoints.up(breakpoint))) {
      chosenBreakpoint = breakpoint;
    }
  });

  return chosenBreakpoint;
}
