import PropTypes from 'prop-types';

export const ids = PropTypes.shape({
  eventId: PropTypes.string,
  questId: PropTypes.number,
});

export const GeoType = PropTypes.shape({
  lat: PropTypes.number.isRequired,
  lon: PropTypes.number.isRequired,
  radius: PropTypes.number.isRequired,
  address: PropTypes.string,
});

export const skip = PropTypes.shape({
  allow: PropTypes.bool,
  penalty: PropTypes.number,
});

export const ContentItemType = PropTypes.shape({
  contentType: PropTypes.oneOf([
    'image',
    'video',
    'audio',
    'text',
    'html',
    'choice',
    'directions',
    'sticker',
  ]).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string), // media
    PropTypes.string, // text, html
    PropTypes.shape({
      answer: PropTypes.number,
      options: PropTypes.arrayOf(PropTypes.string),
      // TODO: add hints
    }), // choice
  ]).isRequired,
});

export const EventType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  trigger: PropTypes.shape({
    geo: GeoType,
    time: PropTypes.number,
  }).isRequired,
  skip: skip.isRequired,
  content: PropTypes.arrayOf(ContentItemType).isRequired,
});

export const ProductType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  hidden: PropTypes.bool.isRequired,
  slug: PropTypes.string.isRequired,
});

// export const QuestType = PropTypes.object;

export const QuestType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(EventType).isRequired,
  isEditable: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(ProductType),
  lastModified: PropTypes.string.isRequired,
  mediaSize: PropTypes.number.isRequired,
  shownAccess: PropTypes.string,
});
