import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function getUserStatus(user) {
  const statuses = [];
  if (user.isSuperuser)
    statuses.push([
      'superuser',
      <FormattedMessage
        id="UserStatus.administrator"
        defaultMessage="Administrator"
      />,
    ]);
  if (user.isPartner)
    statuses.push([
      'partner',
      <FormattedMessage id="UserStatus.partner" defaultMessage="Author" />,
    ]);
  if (user.isSales)
    statuses.push([
      'sales',
      <FormattedMessage
        id="UserStatus.referralPartner"
        defaultMessage="Partner"
      />,
    ]);
  if (!statuses.length)
    statuses.push([
      'creator',
      <FormattedMessage id="UserStatus.creator" defaultMessage="Creator" />,
    ]);
  return statuses;
}
