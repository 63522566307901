import {
  takeEvery,
  takeLatest,
  fork,
  select,
  call,
  put,
} from 'redux-saga/effects';
import * as types from '../constants/ActionTypes';
import { BRAND_NAME, INTERCOM_APP_ID } from '../constants/branding';
import sendEvent from '../functions/analytics';
import nullAsDefault from '../functions/nullAsDefault';

const clearGTMKeys = (meta) =>
  Object.keys(meta)
    .filter((key) => !key.includes('gtm'))
    .reduce((dict, key) => ({ ...dict, [key]: meta[key] }), {});

class Analytics {
  static dataLayerPush(event) {
    if (!window.dataLayer) {
      console.log('Error pushing to dataLayer: no dataLayer');
      return;
    }
    window.dataLayer.push(event);
  }

  static sendEvent(eventObj) {
    // TODO: refactor this shit
    if (typeof eventObj !== 'object' || !eventObj.event) {
      throw new Error('You need to pass { event: , ...meta} to sendEvent');
    }
    Analytics.dataLayerPush(eventObj);

    let { event: eventName, ...meta } = eventObj;

    meta = clearGTMKeys(meta);

    if (window.Intercom) {
      try {
        window.Intercom('trackEvent', eventName, meta);
      } catch (error) {
        console.log('Failed to send an event to Intercom');
      }
    }
  }

  static sendEventWithMeta(eventName, meta = {}) {
    // TODO: refactor
    Analytics.dataLayerPush({ event: eventName, ...meta });
    if (meta && typeof meta !== 'object') {
      throw new Error('"meta" should be an object');
    }

    meta = clearGTMKeys(meta);

    if (window.Intercom) {
      try {
        window.Intercom('trackEvent', eventName, meta);
      } catch (error) {
        console.log('Failed to send an event to Intercom');
      }
    } else {
      // console.log('Error sending to Intercom: no Intercom');
    }
  }

  static setUserParams(profile) {
    const {
      id: user_id,
      email,
      phone,
      firstName,
      lastName,
      nickname,
      avatar,
      isPartner,
      client: { name: clientName } = {},
    } = nullAsDefault(profile);

    const userParams = {
      user_id,
      email,
      phone,
      name: [firstName, lastName].join(' '),
      nickname,
      avatar,
      isPartner,
      clientName,
    };

    const intercomParams = {
      name: [firstName, lastName].join(' '),
      email,
      custom: {
        isPartner,
        clientName,
      },
      user_id,
      isPartner,
      clientName,
      created_at: profile.createdAt,
    };

    if (window.Intercom) {
      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
        hide_default_launcher: true,
        ...intercomParams,
      });
    }
    Analytics.dataLayerPush({
      event: 'setUserParams',
      userParams,
    });
  }

  static updateUser(payload) {
    // TODO: filter and check setUserParams for yandex and etc
    if (window.Intercom) {
      window.Intercom('update', payload);
    }
    Analytics.dataLayerPush({
      event: 'setUserParams',
      userParams: payload,
    });
  }
}

function* authorizationCount(action) {
  const isPrevLogin =
    document.referrer.match('login') != null ||
    document.referrer.match('onboarding');
  const isNotCounted = sessionStorage.getItem('logginCounted') == null;
  if (!isPrevLogin || !isNotCounted) return;
  const loginProvider = sessionStorage.getItem('loginProvider');
  sessionStorage.setItem('logginCounted', true);
  yield put({
    type: 'ANALYTICS_TRACK_EVENT',
    event: 'authorization',
    loginProvider,
  });
  const {
    payload: { isPartner },
  } = action;
  if (isPartner) {
    yield put({
      type: 'ANALYTICS_TRACK_EVENT',
      event: 'authors_logged',
      loginProvider,
    });
  }
}

function* clearAuthorizationCounter(action) {
  sessionStorage.removeItem('logginCounted');
  if (window.Intercom) {
    yield call(window.Intercom, 'shutdown');
  }
}

function* countPublishPopup(action) {
  yield call(Analytics.sendEvent, { event: 'constructor_open_publish_popup' });
}

function* countFreePublish(action) {
  yield call(Analytics.sendEvent, { event: 'constructor_free_publish' });
}

function* countStartPayment(action) {
  yield call(Analytics.sendEvent, { event: 'constructor_init_checkout' });
}

function* countPartnerApply(action) {
  yield call(Analytics.sendEvent, { event: 'partner_apply' });
}

function* countChooseBusinessPlan(action) {
  yield call(Analytics.sendEvent, { event: 'choose_business_plan' });
}

function* countFirstQuest(action) {
  const quests = yield select((state) => state.quests.quests);

  if (!quests.length) {
    Analytics.sendEvent({ event: 'create_quest_after_zero_quests' });
  }
}

function* setUserParams(action) {
  const { payload: profile } = action;

  yield call(Analytics.setUserParams, profile);
}

function countCreateEvent({ title, questId }) {
  return function* () {
    yield put({
      type: types.ANALYTICS_TRACK_EVENT,
      event: 'create_event',
      eventCategory: 'Authors',
      eventAction: 'Create event',
      title,
      questId,
      // todo: questname
    });
  };
}

function countEvent(eventName, meta) {
  return function* () {
    yield put({ type: types.ANALYTICS_TRACK_EVENT, event: eventName, ...meta });
  };
}

function* countEventFromAction(action) {
  let { type, event: eventName, ...meta } = action;
  eventName = eventName.toLowerCase();
  yield call(Analytics.sendEvent, { event: eventName, ...meta });
}

function* updateUser(action) {
  const { type, payload } = action;
  yield call(Analytics.updateUser, payload);
}

function* sendPage(action) {
  /**
   * Analytics
   */
  yield sendEvent('page', {
    type: 'page',
    name: BRAND_NAME,
    properties: {
      title: 'WeGoTrip',
      url: window.location.href,
    },
  });
}

export default function* analyticsSaga() {
  yield takeEvery('@@router/LOCATION_CHANGE', sendPage);

  yield fork(() => takeEvery(types.RECEIVE_USER, authorizationCount));
  yield fork(() => takeLatest(types.RECEIVE_USER, setUserParams));
  yield fork(() => takeEvery(types.LOGOUT, clearAuthorizationCounter));
  yield fork(() => takeEvery(types.CREATE_QUEST, countFirstQuest));

  yield fork(() => takeEvery(types.OPEN_PUBLISH_POPUP, countPublishPopup));
  yield fork(() => takeEvery(types.PUBLISH_FREE, countFreePublish));
  yield fork(() => takeEvery(types.RECEIVE_PAYMENT, countStartPayment));
  yield fork(() => takeEvery(types.PARTNER_APPLY, countPartnerApply));
  yield fork(() =>
    takeEvery(types.CHOOSE_BUSINESS_PLAN, countChooseBusinessPlan)
  );
  yield fork(() => takeEvery(types.ADD_EVENT, countCreateEvent));

  yield fork(() =>
    takeEvery(types.UPDATE_PARTNER_DATA_SUCCESS, countEvent('update_data'))
  );
  yield fork(() =>
    takeEvery(types.RECEIVE_IMAGES_URLS, countEvent('upload_image'))
  );
  yield fork(() =>
    takeEvery(types.RECEIVE_AUDIOS_URLS, countEvent('upload_audio'))
  );
  yield fork(() =>
    takeEvery(types.PUBLISH_PRODUCT_SUCCESS, countEvent('create_product'))
  );
  yield fork(() =>
    takeEvery(types.CREATE_CODES_SUCCESS, countEvent('create_bunch_of_codes'))
  );
  yield fork(() =>
    takeEvery(types.SIGNUP_SUCCESS, countEvent('authors_signup_success'))
  );
  yield fork(() =>
    takeEvery(
      types.SEND_USER_INFO_SUCCESS,
      countEvent('authors_send_user_info_success')
    )
  );

  yield fork(() => takeEvery('ANALYTICS_TRACK_EVENT', countEventFromAction));
  yield fork(() => takeEvery('ANALYTICS_USER_UPDATE', updateUser));
}
