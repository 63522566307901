export const languages = [
  { name: 'Arabic', code: 'ar', nameRu: 'Арабский' },
  { name: 'Bulgarian', code: 'bg', nameRu: 'Болгарский' },
  { name: 'Chinese', code: 'zh', nameRu: 'Китайский' },
  { name: 'Czech', code: 'cs', nameRu: 'Чешский' },
  { name: 'Danish', code: 'da', nameRu: 'Датский' },
  { name: 'Dutch', code: 'nl', nameRu: 'Голландский' },
  { name: 'English', code: 'en', nameRu: 'Английский' },
  { name: 'Estonian', code: 'et', nameRu: 'Эстонский' },
  { name: 'Finnish', code: 'fi', nameRu: 'Финский' },
  { name: 'French', code: 'fr', nameRu: 'Французский' },
  { name: 'German', code: 'de', nameRu: 'Немецкий' },
  { name: 'Greek', code: 'el', nameRu: 'Греческий' },
  { name: 'Hungarian', code: 'hu', nameRu: 'Венгерский' },
  { name: 'Indonesian', code: 'id', nameRu: 'Индонезийский' },
  { name: 'Italian', code: 'it', nameRu: 'Итальянский' },
  { name: 'Japanese', code: 'ja', nameRu: 'Японский' },
  { name: 'Korean', code: 'ko', nameRu: 'Корейский' },
  { name: 'Latvian', code: 'lv', nameRu: 'Латышский' },
  { name: 'Lithuanian', code: 'lt', nameRu: 'Литовский' },
  { name: 'Norwegian', code: 'nb', nameRu: 'Норвежский' },
  { name: 'Polish', code: 'pl', nameRu: 'Польский' },
  { name: 'Portuguese', code: 'pt-PT', nameRu: 'Португальский' },
  { name: 'Romanian', code: 'ro', nameRu: 'Румынский' },
  { name: 'Russian', code: 'ru', nameRu: 'Русский' },
  { name: 'Slovak', code: 'sk', nameRu: 'Словацкий' },
  { name: 'Slovenian', code: 'sl', nameRu: 'Словенский' },
  { name: 'Spanish', code: 'es', nameRu: 'Испанский' },
  { name: 'Swedish', code: 'sv', nameRu: 'Шведский' },
  { name: 'Turkish', code: 'tr', nameRu: 'Турецкий' },
  { name: 'Ukrainian', code: 'uk', nameRu: 'Украинский' },
];
