import React from 'react';
import Typography from '@material-ui/core/Typography';
import './TourHighlights.css';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Button, Paper, TextField, makeStyles } from '@material-ui/core';
import * as publishingActions from '../../../actions/publishing';
import Examples from '../../Examples';
import TourContentFooterButtons from '../../TourContentFooter/TourContentFooterButtons';
import HelperLink from '../../HelperLink';
import { HELP_CENTER_URL } from '../../../constants/branding';

/**
 * JSS styles for `TourHighlights` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 24,
    marginTop: 40,
    marginBottom: -15,
  },
  container: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
  },
  textFieldM: {
    width: '88%',
    maxWidth: 950,
    marginBottom: 15,
  },
  examplesTitle: {
    marginTop: 30,
  },
  examples: {
    marginTop: 15,
    marginBottom: 35,
  },
  cardTitle: {
    marginBottom: 14,
  },
  helperFullness: {
    marginTop: 40,
    marginBottom: -25,
  },
}));

const TourHighlights = ({
  currentQuest: { products },
  currentQuestId,
  intl,
  // eslint-disable-next-line no-shadow
  publishingActions,
  publishing: { highlights },
  location,
  checkFullness,
  userLocale,
}) => {
  /**
   * JSS styles for `TourHighlight` element
   * @type {React::Hook}
   */
  const classes = useStyles();

  /**
   * Validate nextValue value and send it to server
   * @param {Array[Object]} oldValues - highlights objects array
   * @param {Object} nextValue - new highlight object
   * @param {String} text - new highlight text
   * @param {String} attribute - product attribute key name
   */
  const handleSave = (oldValues, newValue, text, attribute) => {
    // eslint-disable-next-line no-prototype-builtins
    if (newValue.hasOwnProperty('id')) {
      if (
        text === oldValues.find((e) => e.id === newValue.id).text ||
        text === ''
      )
        return;
      publishingActions.sendListedData(
        products[0].id,
        newValue.id,
        { text },
        attribute
      );
    } else {
      // eslint-disable-next-line no-unused-expressions
      text !== '' &&
        publishingActions.postListedData(products[0].id, { text }, attribute);
    }
  };

  /**
   * Checking for delete or save highlight object
   * @param {Array[Object]} oldValues - highlights objects array
   * @param {Object} nextValue - new highlight object
   * @param {String} text - new highlight text
   * @param {String} attribute - product attribute key name
   */
  const handleBlur = (oldValues, value, text, attribute) => {
    // eslint-disable-next-line no-prototype-builtins
    if (text === '' && value.hasOwnProperty('id')) {
      publishingActions.deleteListedData(products[0].id, value.id, attribute);
    }
    if (text !== '') handleSave(oldValues, value, text, attribute);
  };

  return (
    <section className="App__main TourHighlights__main">
      <Container className={classes.container}>
        <Paper elevation={0} className={classes.paper}>
          <Typography className={classes.cardTitle} variant="h5">
            <FormattedMessage
              id="StudioCreateProduct.TourHighlightTitle"
              defaultMessage="Highlights"
            />
          </Typography>

          <Typography gutterBottom variant="body1">
            <FormattedMessage
              id="StudioCreateProduct.TourHighlightSubhead"
              defaultMessage="Please indicate what makes your tour special. This way travelers can quickly understand the key points and their expectations."
            />
          </Typography>
          <br />
          <Typography gutterBottom variant="body1">
            <FormattedMessage
              id="StudioCreateProduct.TourHighlightSubhead2"
              defaultMessage="Write 3-5 short sentences describing the most exciting experiences on the tour. Begin each highlight with an action, such as «discover», «admire», or «learn»."
            />
          </Typography>

          <HelperLink
            marginBottom={2}
            text={
              <FormattedMessage
                id="StudioCreateProduct.whatAddToHighlights"
                defaultMessage="What can be added in highlights"
              />
            }
            link={`${HELP_CENTER_URL}${userLocale}/highlights/`}
          />

          <Typography
            gutterBottom
            className={classes.examplesTitle}
            variant="h6"
          >
            <FormattedMessage
              id="StudioCreateProduct.TourHighlightExamples"
              defaultMessage="Examples"
            />
          </Typography>

          <div className={classes.examples}>
            <Examples
              withWords
              good={[
                <FormattedMessage
                  key="StudioCreateProduct.TourHighlightExample1"
                  id="StudioCreateProduct.TourHighlightExample1"
                  defaultMessage="Consider the centuries-old frescoes of the Sistine Chapel"
                />,
                <FormattedMessage
                  key="StudioCreateProduct.TourHighlightExample2"
                  id="StudioCreateProduct.TourHighlightExample2"
                  defaultMessage="Admire panoramic views of the city from the observation deck"
                />,
                <FormattedMessage
                  key="StudioCreateProduct.TourHighlightExample3"
                  id="StudioCreateProduct.TourHighlightExample3"
                  defaultMessage="Explore the winding peacock clock, and if you're lucky, see it in action"
                />,
              ]}
              bad={[
                <FormattedMessage
                  key="StudioCreateProduct.TourHighlightExample4"
                  id="StudioCreateProduct.TourHighlightExample4"
                  defaultMessage="Vatican Museums, Sistine Chapel, St. Peter's Basilica"
                />,
                <FormattedMessage
                  key="StudioCreateProduct.TourHighlightExample5"
                  id="StudioCreateProduct.TourHighlightExample5"
                  defaultMessage="Stunning views!"
                />,
              ]}
            />
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              highlights:
                // eslint-disable-next-line no-nested-ternary
                highlights && highlights.length
                  ? highlights.length < 5
                    ? [...highlights, { text: '' }]
                    : [...highlights]
                  : [{ text: '' }],
            }}
          >
            {({ values }) => (
              <Form>
                <FieldArray
                  name="highlights"
                  render={(arrayHelpers) => (
                    <div>
                      {values.highlights.map((elem, index) => (
                        <div
                          // eslint-disable-next-line react/no-array-index-key
                          key={`StudioCreateProduct.TourHighlightsPlaceholder-${index}`}
                        >
                          <Field
                            className={classes.textFieldM}
                            placeholder={intl.formatMessage({
                              id:
                                'StudioCreateProduct.TourHighlightsPlaceholder',
                              defaultMessage: 'What makes your tour special',
                            })}
                            name={`highlights.${index}.text`}
                            error={
                              highlights.length < 3 &&
                              location.highlights &&
                              index === highlights.length
                            }
                            as={TextField}
                            label={
                              <FormattedMessage
                                id="StudioCreateProduct.TourHighlightsItem"
                                defaultMessage="Highlight {value}"
                                values={{
                                  value: index + 1,
                                }}
                              />
                            }
                            variant="standard"
                            type="highlights"
                            helperText={`${elem.text.length}/120`}
                            inputProps={{ maxLength: 120 }}
                            onBlur={(e) =>
                              handleBlur(
                                highlights,
                                elem,
                                e.target.value,
                                'highlights'
                              )
                            }
                          />
                          {values.highlights.length !== 1 && (
                            <Button
                              color="primary"
                              onClick={() =>
                                // eslint-disable-next-line no-prototype-builtins
                                elem.hasOwnProperty('id')
                                  ? publishingActions.deleteListedData(
                                      products[0].id,
                                      elem.id,
                                      'highlights'
                                    )
                                  : arrayHelpers.remove(index)
                              }
                            >
                              <FormattedMessage
                                id="StudioCreateProduct.TourHighlightDelete"
                                defaultMessage="Delete"
                              />
                            </Button>
                          )}
                        </div>
                      ))}
                      <Button
                        color="primary"
                        disabled={
                          values.highlights[values.highlights.length - 1]
                            .text === '' || values.highlights.length === 5
                        }
                        onClick={() =>
                          values.highlights.length < 5 &&
                          arrayHelpers.push({ text: '' })
                        }
                      >
                        <FormattedMessage
                          id="StudioCreateProduct.TourHighlightAdd"
                          defaultMessage="Add another item"
                        />
                      </Button>
                    </div>
                  )}
                />
              </Form>
            )}
          </Formik>
        </Paper>
        {!checkFullness('highlights') && (
          <Typography className={classes.helperFullness} variant="body1">
            <FormattedMessage
              id="StudioCreateProduct.TourHighlightRemainsToFill"
              defaultMessage="Remaining sections to fill in: highlights"
            />
          </Typography>
        )}
        <TourContentFooterButtons
          leftLink={`/quest/${currentQuestId}/locations`}
          rightLink={
            location.highlights
              ? `/quest/${currentQuestId}/check`
              : `/quest/${currentQuestId}/details`
          }
          withRedirectToTourCheck={location.highlights}
          leftBtnVisible={!location.highlights}
        />
      </Container>
    </section>
  );
};

function mapStateToProps() {
  return (state) => {
    return {
      publishing: state.publishing,
      currentQuestId: state.quests.currentQuestId,
      userLocale: state.user.locale,
    };
  };
}

function mapDispatchToProps(dispatch) {
  return {
    publishingActions: bindActionCreators(publishingActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(TourHighlights)));
