import React, { useState, useEffect } from 'react';
import grey from '@material-ui/core/colors/grey';
import classNames from 'classnames';
import makeStyles from '@material-ui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import Event from '../../Event';
import EventHeader from '../../Event/EventHeader';
import EventAlert from '../../Event/EventAlert';
import GapItem from '../../Gap/GapItem';
import Gap from '../../Gap';
import RoutePointAccessButton from '../../RoutePage/RoutePointAccessButton';
import EventControlsNext from '../EventControlsNext';
import EventControlsDestinations from '../EventControlsDestinations';
import EventControlsRoutePoint from '../EventControlsRoutePoint';
import EventControlsStickers from '../EventControlsStickers';

/**
 * Id's of the options in `EventControlsThisStep` component
 * @type {Object}
 */
const OPTIONS = {
  auto: 0,
  manual: 1,
};

/**
 * JSS for `EventControlsThisStepOption` component
 * @type {React::Hook}
 */
const useOptionStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '8px',
    padding: '20px',
    paddingBottom: '16px',
    background: grey[50],
    '&:not(:last-child)': {
      marginBottom: '15px',
    },
  },
  notTitle: {
    transition: 'opacity, 0.3s ease',
  },
  notTitleDisabled: {
    opacity: '0.3',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  radio: {
    marginLeft: '0px',
  },
  radioLabel: {
    marginLeft: '10px',
  },
  content: {
    margin: '10px 0 4px',
  },
  controls: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '-5px',
    '& button': {
      textAlign: 'left',
    },
  },
}));

/**
 * Option for reaching next step frame
 * @param {Object} $
 * @param {React::JSX?} $.controlsLeft - components to be placed before "Add text hints" button
 * @param {React::JSX?} $.controlsRight - components to be placed after "Add text hints" button
 * @param {React::JSX?} $.children - option content
 * @param {Boolean} $.checked - if current option is selected
 * @param {Boolean} $.textHintsDisabled - if "Add text hints" button is disabled
 * @param {...Object} $.formControlLabelProps - props will be passed to `material-ui::FormControlLabel` component
 */
function EventControlsThisStepOption({
  checked,
  controlsLeft,
  controlsRight,
  children,
  textHintsDisabled = false,
  onTextHints = () => {},
  ...formControlLabelProps
}) {
  const styles = useOptionStyles();

  return (
    <div className={styles.root}>
      <FormControlLabel
        className={styles.radio}
        classes={{ label: styles.radioLabel }}
        checked={checked}
        control={<Radio color="primary" />}
        {...formControlLabelProps}
      />
      <div
        className={classNames(
          styles.notTitle,
          !checked && styles.notTitleDisabled
        )}
      >
        <div className={styles.content}>{children}</div>
        <Gap className={styles.controls} gaps={['0px', '4px', '4px', '0px']}>
          {controlsLeft}
          <GapItem>
            <Button
              color="primary"
              size="small"
              disabled={textHintsDisabled}
              onClick={onTextHints}
            >
              <FormattedMessage
                id="EventControlsThisStep.addTextHint"
                defaultMessage="Add text hints"
              />
            </Button>
          </GapItem>
          {controlsRight}
        </Gap>
      </div>
    </div>
  );
}

/**
 * Messages for `EventControlsThisStep` component
 * @type {Object}
 */
const messages = {
  title: (
    <FormattedMessage
      id="EventControlsThisStep.title"
      defaultMessage="How traveller will reach this step"
    />
  ),
  helper: (
    <FormattedMessage
      id="EventControlsThisStep.helper"
      defaultMessage="To change the way of reaching current step, enter the name of button or specify geo point"
    />
  ),
  optionAuto: (
    <FormattedMessage
      id="EventControlsThisStep.autoRadio"
      defaultMessage="Automatically"
    />
  ),
  optionManually: (
    <FormattedMessage
      id="EventControlsThisStep.manualRadio"
      defaultMessage="After pressing a button"
    />
  ),
};

/**
 * Placeholder for `EventControlsThisStep`
 * Use only for first/last event
 * @param {Object} $
 * @param {Boolean} $.hasPrevEvent - if current event in quest is not first
 * @param {Boolean} $.hasNextEvent - if current event is not last
 */
export function EventControlsThisStepTemplate({ hasPrevEvent, hasNextEvent }) {
  return (
    <Event>
      <EventHeader>{messages.title}</EventHeader>
      {!hasPrevEvent ? (
        <FormattedMessage
          id="EventControlsThisStepTemplate.infoFirst"
          defaultMessage="Will be opened automatically after tour opening in WeGoTrip App"
        />
      ) : null}
      {!hasNextEvent ? (
        <FormattedMessage
          id="EventControlsThisStepTemplate.infoLast"
          defaultMessage={'After pressing "Finish tour" button'}
        />
      ) : null}
    </Event>
  );
}

/**
 * Settings for next event reaching UX
 * @param {Object} $
 * @param {Number|*} $.questId - id of the quest editing event related to
 * @param {String} $.eventId - current eventId
 * @param {RoutePoint?} $.routePoint - route point bound to current event if exist
 * @param {Array[String]} $.directions - text hints for reaching the current route point event
 * @param {Array[Object]} $.stickerUploads - description of uploads for `stickers` event content
 * @param {Array[String]} $.stickers - photo hints for reaching the current route point event
 * @param {Function} $.addStickers - user added new stickers
 * @param {Function} $.abortStickers - user aborted stickers media uploading
 * @param {Function} $.removeStickers - user removed sticker
 * @param {Function} $.reorderStickers - user dragged sticker to the new position
 * @param {String} $.prevButtonText - text of the "Next" button for reaching current event if exists
 * @param {String} $.prevEventName - title of the previous event
 * @param {Function?} $.editEventDirections - calls when text hints changed by `EventControlsHints` component
 * @param {Function?} $.editEventSkip - calls when "Next" button text changes
 */
export default function EventControlsThisStep({
  questId,
  eventId,
  routePoint,
  directions,
  stickerUploads,
  stickers,
  addStickers,
  abortStickers,
  removeStickers,
  reorderStickers,
  prevButtonText,
  prevEventName,
  editEventDirections = () => {},
  editEventSkip = () => {},
  removeRoutePoint = () => {},
}) {
  const assumedOption = routePoint ? OPTIONS.auto : OPTIONS.manual;
  const assumedTextHints = Array.isArray(directions) && !!directions?.length;
  const assumedStickers = Array.isArray(stickers) && !!stickers?.length;

  /**
   * Selected option value (from `OPTIONS`)
   * @type {String}
   */
  const [option, setSelectedOption] = useState(assumedOption);

  /**
   * Removes data (route point for `OPTIONS.auto`, text of the "Next step" button for `OPTIONS.manual`)
   *   of the previous selected option if it exists
   */
  const removePrevSelectedOptionData = () => {
    if (option === OPTIONS.auto && prevButtonText) {
      editEventSkip({ questId, eventId, button: '' });
    }
    if (option === OPTIONS.manual && !!routePoint) {
      removeRoutePoint({ questId, pointId: eventId, isSync: true });
    }
  };

  /**
   * If `EventControlsHints` component visible
   * @type {Boolean}
   */
  const [textHintsShown, showTextHints] = useState(assumedTextHints);

  /**
   * If `EventControlsStickers` component visible
   * @type {Boolean}
   */
  const [stickersShown, showStickers] = useState(assumedStickers);

  /**
   * Resetting component state when event was changed
   */
  useEffect(() => {
    showTextHints(assumedTextHints);
    showStickers(assumedStickers);
    setSelectedOption(assumedOption);
  }, [eventId]);

  const optionsProps = [
    {
      label: messages.optionAuto,
      value: OPTIONS.auto,
      controlsRight: (
        <GapItem>
          <RoutePointAccessButton
            size="small"
            questId={questId}
            eventId={eventId}
            pointExists={!!routePoint}
            onClick={() => removePrevSelectedOptionData()}
          />
        </GapItem>
      ),
      children: (
        <EventControlsRoutePoint
          questId={questId}
          eventId={eventId}
          routePoint={routePoint}
        />
      ),
    },
    {
      label: messages.optionManually,
      value: OPTIONS.manual,
      children: (
        <EventControlsNext
          buttonText={prevButtonText}
          prevEventName={prevEventName}
          hasRoutePoint={!!routePoint}
          resetOnChange={eventId}
          onChanged={(button) => {
            if (button !== prevButtonText) {
              removePrevSelectedOptionData();
              editEventSkip({ questId, eventId, button });
            }
          }}
        />
      ),
      controlsLeft: (
        <GapItem>
          <Button
            color="primary"
            size="small"
            disabled={stickersShown}
            onClick={() => showStickers(true)}
          >
            <FormattedMessage
              id="EventControlsThisStep.addStickers"
              defaultMessage="Add photo hints"
            />
          </Button>
        </GapItem>
      ),
    },
  ];

  return (
    <Event>
      <EventHeader>{messages.title}</EventHeader>
      <Event>
        <EventAlert>{messages.helper}</EventAlert>
        <div>
          {optionsProps.map(({ value, ...props }) => (
            <EventControlsThisStepOption
              key={value}
              checked={option === value}
              textHintsDisabled={textHintsShown}
              onTextHints={() => showTextHints(true)}
              onChange={() => setSelectedOption(value)}
              {...props}
            />
          ))}
        </div>
      </Event>
      {stickersShown ? (
        <EventControlsStickers
          questId={questId}
          eventId={eventId}
          uploads={stickerUploads}
          stickers={stickers}
          onAdded={addStickers}
          onAborted={abortStickers}
          onRemoved={removeStickers}
          onReordered={reorderStickers}
        />
      ) : null}
      {textHintsShown ? (
        <EventControlsDestinations
          questId={questId}
          eventId={eventId}
          hints={directions}
          onChanged={editEventDirections}
        />
      ) : null}
    </Event>
  );
}
