import toValidHtml from './toValidHtml';

export function removeWrapper(element) {
  element.parentNode.insertAdjacentHTML('beforeEnd', element.innerHTML);
  element.remove();
}

export function wrapIntoTag(element, tag) {
  // eslint-disable-next-line no-param-reassign
  element.innerHTML = `<${tag}>${element.innerHTML}</${tag}>`;
}

/**
 * Removes the extra <b> wrapper and
 * optionally wraps styled elements in <i>, <b>, <u> tags to preserve the original formatting
 * @param {String} htmlString - to be parsed into DOM while converting
 * @param {Function?} onError - calls if it is not possible to parse `text`
 */
export default function convertStylesToTags(htmlString, onError) {
  const {
    body,
    body: { firstChild },
  } = new DOMParser().parseFromString(
    toValidHtml(htmlString.replace(/<meta[^>]*?>/g, ''), onError),
    'text/html'
  );

  // Some text editors, such as Google Docs, wrap text in a <b> tag.
  const withBoldTagWrapper =
    firstChild.tagName === 'B' && firstChild.style.fontWeight === 'normal';

  if (withBoldTagWrapper) removeWrapper(firstChild);

  const boldFontWieghtSelectors = Array.from(
    [5, 6, 7, 8, 9],
    (x) => `[style*="font-weight:${x * 100}"]`
  ).toString();

  [
    ['b', body.querySelectorAll(boldFontWieghtSelectors)],
    ['i', body.querySelectorAll('[style*="font-style:italic"]')],
    ['u', body.querySelectorAll('[style*="text-decoration:underline"]')],
  ].forEach(([tag, nodes]) => nodes.forEach((node) => wrapIntoTag(node, tag)));

  return body.innerHTML;
}
