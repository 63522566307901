import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

/**
 * Unified `ListItem` - with icon and text
 * @param {Object} $
 * @param {React::Component|String} $.primary - inner text
 * @param {React::Component} $.icon - icon class (e.g. - `<ListPoint icon={MapIcon}/>`)
 * @param {...Object} $.itemProps - props for `MaterialUI::ListItem` component
 */
export default function ListPoint({
  primary,
  icon: Icon,
  classes: { icon: iconClassName, ...classes } = {},
  children,
  ...itemProps
}) {
  return (
    <ListItem classes={{ ...classes, text: undefined }} {...itemProps}>
      {Icon ? (
        <ListItemIcon className={iconClassName}>
          <Icon />
        </ListItemIcon>
      ) : null}
      {primary ? (
        <ListItemText
          primary={primary}
          {...(classes.text ? { classes: classes.text } : {})}
        />
      ) : null}
      {children}
    </ListItem>
  );
}
