/**
 * Replaces item in array it exist or add if not.
 * @param {object[]} list - array
 * @param {Function} predicate - function that is used to find index of element in array.
 * @param {any} newItem - item to add or replace
 * @returns {object[]}  array with changed element
 */
const replaceOrAdd = (
  list,
  predicate,
  newItem,
  itemPropsFilter = (item) => item
) => {
  let isReplaced = false;

  const updatedList = (list || []).map((item) => {
    if (typeof predicate === 'function' && predicate(item)) {
      isReplaced = true;
      return newItem;
    }
    return itemPropsFilter(item);
  });

  if (!isReplaced) updatedList.push(newItem);

  return updatedList;
};

export default replaceOrAdd;
