/* eslint-disable func-names */
import React, { Component } from 'react';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import AvatarEditor from 'react-avatar-edit';
import Modal from 'react-modal';
import loadImage from 'blueimp-load-image';
import * as UserActions from '../../actions/user';
import { MAX_IMAGE_SIZE } from '../../constants/limits';
import Button from '../Button';
import Avatar from '../Avatar';
import StructuredPopup from '../Popup/StructuredPopup';
import PictureDropzone from '../PictureDropzone';
import './AvatarEditor.css';

export function fromCanvasToBlob(canvas, filetype) {
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        // reject(new Error('Canvas is empty'));
        console.error('Canvas is empty');
        return;
      }

      // Get image url
      const fileUrl = URL.createObjectURL(blob);
      resolve(fileUrl);
    }, `image/${filetype}`);
  });
}

export function loadImagePromise(file, options) {
  const { type } = file;

  return new Promise((resolve, reject) => {
    // TODO: use promise instead of callback and handle errors

    loadImage(
      file,
      async function (canvas) {
        if (canvas.type === 'error') {
          reject(canvas);
        }
        const fileUrl = await fromCanvasToBlob(canvas, type);
        resolve(fileUrl);
      },
      options
    );
  });
}

export async function getImageRotatedRight(file) {
  if (file.size > MAX_IMAGE_SIZE) {
    return;
  }

  // eslint-disable-next-line consistent-return, no-return-await
  return await loadImagePromise(file, { canvas: true, orientation: true });
}

export function createImage(src) {
  if (!src) {
    return null;
  }

  const img = new Image();
  img.src = src;

  return img;
}

Modal.setAppElement('#root');
const messages = defineMessages({
  textareaPlaceholder: {
    id: 'avatarEditor2.textareaPlaceholder',
    defaultMessage:
      'I live in Tbilisi, I know every street of this city with all secrets. I tried to show all the breathtaking places I know!',
  },
  uploaderText: {
    id: 'avatarEditor2.uploaderText',
    defaultMessage: 'Choose an image',
  },
  saveButtonText: {
    id: 'avatarEditor2.saveButtonText',
    defaultMessage: 'Save',
  },
  chooseAnotherButtonText: {
    id: 'avatarEditor2.chooseAnotherButtonText',
    defaultMessage: 'Upload another image',
  },
  cancelButtonText: {
    id: 'avatarEditor2.cancelButtonText',
    defaultMessage: 'Cancel',
  },
  friendWord: {
    id: 'avatarEditor2.friendWord',
    defaultMessage: 'friend',
  },
});

const avatarEditorStyles = {
  labelStyle: {
    fontSize: '18px',
    fontWeight: '700',
    fontFamily: "'Helvetica Neue', Arial, sans-serif",
  },
  borderStyle: {
    borderColor: '#adadad',
  },
};

class InlineAvatarEditor extends Component {
  constructor(props) {
    super(props);
    let dropped = null;
    let avatarPreview = null;

    const {
      user: { avatar },
    } = this.props;

    if (avatar) {
      dropped = avatar;
      avatarPreview = avatar;
    }

    this.state = {
      dropped,
      defaultDropped: dropped,
      avatar,
      avatarPreview,
      defaultPreview: avatarPreview,
      isAvatarEditorEnabled: false,
    };
  }

  // eslint-disable-next-line react/no-typos, class-methods-use-this
  getDerivedStateFromProps(nextProps, prevState) {
    const {
      user: { avatar },
    } = nextProps;

    if (avatar === prevState.avatar) return;

    let dropped = null;
    let avatarPreview = null;

    if (avatar) {
      dropped = avatar;
      avatarPreview = avatar;
    }

    // eslint-disable-next-line consistent-return
    return {
      dropped,
      defaultDropped: dropped,
      avatar,
      avatarPreview,
      defaultPreview: avatarPreview,
      isAvatarEditorEnabled: false,
    };
  }

  toggleAvatarEditor = (nextState) => {
    if (this.state.isAvatarEditorEnabled === nextState) return;
    this.setState((prevState) => {
      return {
        ...prevState,
        isAvatarEditorEnabled: nextState || !prevState.isAvatarEditorEnabled,
        avatarPreview: nextState
          ? prevState.avatarPreview
          : prevState.defaultPreview,
        dropped: nextState ? prevState.dropped : prevState.defaultDropped,
      };
    });
  };

  onCropAvatar = (avatarPreview) => {
    this.setState({ avatarPreview });
  };

  onCloseAvatarEditor = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        avatarPreview: null,
        dropped: null,
      };
    });
  };

  saveAvatar = () => {
    const { avatarPreview } = this.state;
    const { userActions } = this.props;
    this.setState((prevState) => {
      return {
        ...prevState,
        avatar: prevState.avatarPreview,
        avatarPreview,
        isAvatarEditorEnabled: false,
        dropped: avatarPreview,
      };
    });
    userActions.uploadAvatar({ image: avatarPreview });
  };

  render() {
    const { isAvatarEditorEnabled, avatarPreview, dropped } = this.state;
    const {
      user,
      user: { email },
      intl: { formatMessage },
      className,
    } = this.props;

    // parentSelector={() => document.querySelector('#login-main')}

    return (
      <div
        className={classNames(`avatar-editor`, className)}
        onClick={() => this.toggleAvatarEditor(true)}
      >
        <div className="avatar-editor__preview-wrapper">
          <Avatar
            normal
            email={email}
            avatar={user.avatar}
            className="avatar-editor__img"
          />
          <button type="button" className="avatar-editor__edit-btn-editor">
            <FormattedMessage
              id="avatarEditor2.avatarEdit"
              defaultMessage="Edit"
            />
          </button>
        </div>

        <StructuredPopup
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          isOpen={isAvatarEditorEnabled}
          handleCloseClick={() => this.toggleAvatarEditor(false)}
          title={
            <FormattedMessage
              id="avatarEditor2.avatarTitle"
              defaultMessage="Avatar"
            />
          }
        >
          <div className="avatar-editor__main">
            <div className="avatar-editor__wrapper">
              {!dropped && (
                <PictureDropzone
                  onDropAccepted={async (files) => {
                    this.setState({
                      dropped: await getImageRotatedRight(files[0]),
                    });
                  }}
                />
              )}
              {dropped && (
                <AvatarEditor
                  exportAsSquare
                  img={createImage(this.state.dropped)}
                  width={200}
                  height={200}
                  imageWidth={200}
                  exportSize={200}
                  label={formatMessage(messages.uploaderText)}
                  labelStyle={avatarEditorStyles.labelStyle}
                  borderStyle={{ textAlign: 'center' }}
                  onCrop={this.onCropAvatar}
                  onClose={this.onCloseAvatarEditor}
                />
              )}
              {avatarPreview && (
                <div className="avatar-editor__live-preview-wrapper">
                  <img src={avatarPreview} alt="Preview" />
                  <div>
                    <Button theme="action" onClick={this.saveAvatar}>
                      {formatMessage(messages.saveButtonText)}
                    </Button>{' '}
                    <Button
                      theme="main-light"
                      onClick={() => {
                        this.setState((prevState) => {
                          return {
                            ...prevState,
                            avatarPreview: null,
                            dropped: null,
                          };
                        });
                      }}
                    >
                      {formatMessage(messages.cancelButtonText)}
                    </Button>
                  </div>
                </div>
              )}
            </div>
            {!avatarPreview && (
              <Button
                theme="action"
                className="avatar-editor__button"
                onClick={() => this.toggleAvatarEditor(false)}
              >
                {formatMessage(messages.cancelButtonText)}
              </Button>
            )}
          </div>
        </StructuredPopup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

// export default InlineAvatarEditor;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(InlineAvatarEditor));
