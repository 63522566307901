import isEqual from 'lodash/isEqual';

/**
 * @typedef {Object} Rectangle
 * @prop {Number} x - `x` coordinate of the rectangle top left vertex
 * @prop {Number} y - `y` coordinate of the rectangle top left vertex
 * @prop {Number} width - rectangle width
 * @prop {Number} height - rectangle height
 */

/**
 * Crops given image
 * @param {Element} img - DOM image element
 * @param {Rectangle} rect - cropping frame coords in `%` units
 * @param {Object?} options - extra options
 * @param {String?} options.type - image file type to encode `base64` string (default is `"image/png"`)
 * @returns {Promise<Object>} - resolves with object with `base64` prop with base64 of the new string
 */
export default function rotate(
  img,
  { x, y, width, height },
  { type = 'image/png' } = {}
) {
  return new Promise(function (resolve, reject) {
    if (isEqual([x, y, width, height], [0, 0, 100, 100])) {
      resolve({});
    }
    if (!width || !height) {
      resolve({});
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const scale = {
      x: img.naturalWidth / img.width,
      y: img.naturalHeight / img.height,
    };

    canvas.width = width * scale.x;
    canvas.height = height * scale.y;

    ctx.drawImage(
      img,
      x * scale.x,
      y * scale.y,
      width * scale.x,
      height * scale.y,
      0,
      0,
      width * scale.x,
      height * scale.y
    );

    resolve({ base64: canvas.toDataURL(type) });
  });
}
