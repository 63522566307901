import React, { Component } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from 'react-tooltip';
import { isUserAuthor, isUserPartner } from '../../functions/user';
import { Desktop } from '../../functions/queries';
import { DOMAIN_URL, HELP_CENTER_URL } from '../../constants/branding';
import AvatarEditor from '../AvatarEditor';
import './ProfileAside.css';

// User statuses: [creator, partner, sales]

export const messages = defineMessages({
  ratingTooltip: {
    id: 'ProfileAside.ratingTooltip',
    defaultMessage:
      'The rating is formed from the sum of all ratings of your tours by users.',
  },
});

class ProfileAside extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getHeaderClass() {
    const { statuses } = this.props;
    return [
      'ProfileAside__header',
      statuses.map((s) => `ProfileAside__header--${s[0]}`)[0],
    ]
      .filter(Boolean)
      .join(' ');
  }

  getStatusClass() {
    const { statuses } = this.props;
    return [
      'ProfileAside__status',
      statuses.some((s) => s[0] === 'creator') && 'ProfileAside__status--blue',
    ]
      .filter(Boolean)
      .join(' ');
  }

  getNavLinks() {
    const {
      user,
      user: { locale },
      location,
      logoutUser,
    } = this.props;

    const isAuthor = isUserAuthor(user);
    const isPartner = isUserPartner(user);

    return [
      {
        title: (
          <FormattedMessage
            id="ProfileAside.reports"
            defaultMessage="Reports"
          />
        ),
        to: `/reports/sales`,
        className: /\b(reports)\b/i.test(location.pathname)
          ? 'ProfileAside__nav-link--active'
          : '',
      },
      isAuthor && {
        title: (
          <FormattedMessage id="ProfileAside.tours" defaultMessage="Tours" />
        ),
        to: `/`,
        className:
          location.pathname === '/' || location.pathname === ''
            ? 'ProfileAside__nav-link--active'
            : '',
      },
      // {
      //   title: (
      //     <FormattedMessage id="ProfileAside.reviews" defaultMessage="Reviews" />
      //   ),
      //   to: `/reviews`,
      //   className: /\b(reviews)\b/i.test(location.pathname)
      //    ? "ProfileAside__nav-link--active"
      //    : ''
      // },
      isPartner && {
        title: (
          <FormattedMessage
            id="ProfileAside.partnerAccount"
            defaultMessage="Partner Account"
          />
        ),
        href: `${DOMAIN_URL}${locale}/affiliate/widgets/`,
      },
      {
        title: (
          <FormattedMessage
            id="ProfileAside.helpCentre"
            defaultMessage="Help center"
          />
        ),
        href: `${HELP_CENTER_URL}${locale}/`,
      },
      {
        title: (
          <FormattedMessage
            id="ProfileAside.navLinkSettings"
            defaultMessage="Settings"
          />
        ),
        to: `/settings/profile`,
        className: /\b(settings)\b/i.test(location.pathname)
          ? 'ProfileAside__nav-link--active'
          : '',
      },
      {
        title: (
          <span className="ProfileAside__logout_text">
            <FormattedMessage id="ProfileAside.quit" defaultMessage="Log out" />
          </span>
        ),
        to: '',
        className: 'ProfileAside__logout',
        onClick: logoutUser,
      },
    ].filter(Boolean);
  }

  render() {
    const {
      user,
      intl: { formatMessage },
      partner: {
        data: { rating },
      },
      closeSidebar,
    } = this.props;

    return (
      <div className="ProfileAside__inner">
        <Desktop>
          <header className={this.getHeaderClass()}>
            <AvatarEditor />
            <Typography className="ProfileAside__text" variant="subtitle2">
              {`${user.firstName} ${user.lastName}`}
            </Typography>
            <Typography
              className="ProfileAside__text"
              data-tip={formatMessage(messages.ratingTooltip)}
              data-place="bottom"
              variant="caption"
              color="text.secondary"
            >
              {rating ? (
                <FormattedMessage
                  id="ProfileAside.rating"
                  defaultMessage="Rating {value}"
                  values={{
                    value: rating,
                  }}
                />
              ) : (
                <FormattedMessage
                  id="ProfileAside.noRating"
                  defaultMessage="No rating"
                />
              )}
            </Typography>
            <Tooltip />
          </header>
        </Desktop>
        <nav className="ProfileAside__nav">
          <List disablePadding component="nav">
            {this.getNavLinks().map((link) => {
              if (Object.prototype.hasOwnProperty.call(link, 'href'))
                return (
                  <a target="_blank" href={link.href} rel="noopener noreferrer">
                    <ListItem button>
                      <ListItemText primary={link.title} />
                    </ListItem>
                  </a>
                );

              return (
                <NavLink
                  key={link.to}
                  exact
                  to={link.to}
                  onClick={closeSidebar}
                >
                  <ListItem
                    button
                    {...(Object.prototype.hasOwnProperty.call(link, 'onClick')
                      ? { onClick: link.onClick }
                      : {})}
                    className={link.className}
                  >
                    <ListItemText primary={link.title} />
                  </ListItem>
                </NavLink>
              );
            })}
          </List>
        </nav>
      </div>
    );
  }
}

ProfileAside.propTypes = {
  statuses: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
  ).isRequired,
};

export default withRouter(ProfileAside);
