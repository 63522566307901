/**
 * Converts `TableHeaderData::title` from message object to it's translation in table header description
 * @param {Array[TableHeaderData]} header - `Table` component header description
 * @param {Function} formatMessage - `react-intl::Intl::firmatMessage()` function
 * @returns {TableHeaderData} - table header with translated `TableHeaderData::title` fields
 */
export default function formatTableHeader(header, formatMessage = () => {}) {
  return header.map(({ title, ...h }) => ({
    type: 'string',
    ...h,
    title: formatMessage(title),
  }));
}
