/**
 * Parts of the address string grouped by the size
 * @type {Object}
 */
export const openStreetMap = {
  countryPart: ['region', 'state', 'state_district', 'county'],
  settlement: ['municipality', 'city', 'town', 'village'],
  settlementPartH: [
    'city_district',
    'district',
    'borough',
    'suburb',
    'subdivision',
  ],
  settlementPartL: ['neighbourhood', 'allotments', 'quarter'],
  building: ['house_number', 'house_name'],
  place: [
    'emergency',
    'historic',
    'military',
    'natural',
    'landuse',
    'place',
    'railway',
    'man_made',
    'aerialway',
    'boundary',
    'amenity',
    'aeroway',
    'club',
    'craft',
    'leisure',
    'office',
    'mountain_pass',
    'shop',
    'tourism',
    'bridge',
    'tunnel',
    'waterway',
  ],
};
