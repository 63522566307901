/**
 * Gets file name (without file format)
 * @param {String} fullName - file fill name with it's format (from `File::name`)
 * @returns {String} - pure file name
 */
export default function getFileName(fullName) {
  return fullName.includes('.')
    ? fullName.split('.').slice(0, -1).join('.')
    : fullName;
}
