import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'events.contentTitle',
    defaultMessage: 'event title',
  },
  audio: {
    id: 'events.contentAmountAudio',
    defaultMessage:
      '{amount, plural, =1 {audio guide} other {{amount} audio guides}}',
  },
  image: {
    id: 'events.contentAmountImage',
    defaultMessage:
      '{amount, plural, =1 {picture} other {{amount} pictures}} of the place',
  },
  text: {
    id: 'events.contentText',
    defaultMessage: 'audio guide text',
  },
});
