import React, { Fragment } from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ProgressCircle from '../../ProgressCircle';

/**
 * JSS styles for `withContentLoader` mod
 * @type {Object}
 */
const useStyles = makeStyles((theme) => ({
  progress: {
    display: 'inline-block',
    margin: 'auto',
  },
  shade: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

/**
 * Adds progress spinner to the image of the `DropzoneContent`
 * @param {React::Component} Component - component to overload
 */
export default function withContentLoader(Component) {
  /**
   * `DropzoneContent` with spinner above it's image
   * @param {Object} $
   * @param {Number?} $.progress - progress of the uploading, if not number - nothing will be shown
   * @param {...Object} $.dropzoneContentProps - will be passed to the `DropzoneContent` component
   */
  return function ({ progress, imageGap, ...dropzoneContentProps }) {
    const styles = useStyles();

    const progressItems =
      typeof progress === 'number' ? (
        <Fragment>
          <div className={classNames('modal', styles.shade)} />
          <ProgressCircle
            className={classNames('modal', styles.progress)}
            color="#fff"
            size={50}
            progress={progress * 100}
          />
        </Fragment>
      ) : null;

    return (
      <Component
        imageGap={
          <Fragment>
            {progressItems}
            {imageGap}
          </Fragment>
        }
        {...dropzoneContentProps}
      />
    );
  };
}
