import {
  FETCH_CURRENCIES,
  FETCH_CURRENCIES_SUCCESS,
  FETCH_CURRENCIES_FAILURE,
} from '../constants/ActionTypes';

export default function currencies(
  state = {
    isFetching: false,
    items: [],
  },
  action
) {
  switch (action.type) {
    case FETCH_CURRENCIES:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_CURRENCIES_SUCCESS:
      return {
        ...state,
        items: action.payload,
        isFetching: false,
      };

    case FETCH_CURRENCIES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
}
