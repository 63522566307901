import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import Likely, {
  Facebook,
  Twitter,
  Vkontakte as VK,
  Telegram,
} from 'react-ilyabirman-likely';
import { Mobile, Desktop } from '../../functions/queries';
import InputCopy from '../InputCopy';
import Button from '../Button';
import { BRAND_NAME, STORE_INDEX } from '../../constants/branding';
import './likely.css';
import './ShareLink.css';

const messages = defineMessages({
  invitation: {
    id: 'ShareLink.inviteTitle',
    defaultMessage: 'You are invited to try the self-guided tour {title}',
  },
});

export function getShareLink(code) {
  return `${STORE_INDEX}to/${code}/`;
}

class ShareLink extends Component {
  render() {
    const {
      title,
      code,
      email,
      isTestAccess,
      intl: { formatMessage },
    } = this.props;
    const shareLink = getShareLink(code);

    return (
      <div>
        <InputCopy readOnly className="ShareLink__section" value={shareLink}>
          <Desktop>
            <a target="_blank" href={shareLink} rel="noreferrer">
              <Button theme="main-light">
                <FormattedMessage id="ShareLink.open" defaultMessage="Open" />
              </Button>
            </a>
            {!isTestAccess ? (
              <Likely
                size="big"
                data-url={shareLink}
                data-title={formatMessage(messages.invitation, { title })}
              >
                <Facebook />
                <Twitter via="surpriseme" />
                <VK />
                <Telegram text="text example" />
              </Likely>
            ) : null}
          </Desktop>
        </InputCopy>
        <Mobile>
          <div className="ShareLink__section ShareLink__row">
            <a target="_blank" href={shareLink} rel="noreferrer">
              <Button theme="main-light">
                <FormattedMessage id="ShareLink.open" defaultMessage="Open" />
              </Button>
            </a>
            <Likely
              size="big"
              data-url={shareLink}
              data-title={formatMessage(messages.invitation, { title })}
            >
              <Facebook />
              <Twitter via="surpriseme" />
              <VK />
              <Telegram text="text example" />
            </Likely>
          </div>
        </Mobile>
        <div>
          {isTestAccess ? (
            <FormattedMessage
              id="ShareLink.testNote"
              defaultMessage="To test the route, simply open this link on your mobile or you can enter code {code} in the {brand} app. The link works only for your account ({user})."
              values={{
                user: <b>{email}</b>,
                code: <code className="GetQuest__code">{code}</code>,
                brand: BRAND_NAME,
              }}
            />
          ) : (
            <FormattedMessage
              id="ShareLink.liveNote"
              defaultMessage="Send this link to your friends or client. They can start your tour by simply open this link or they can enter code {code} in the {brand} app."
              values={{
                code: <code className="GetQuest__code">{code}</code>,
                brand: BRAND_NAME,
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

ShareLink.propTypes = {
  code: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isTestAccess: PropTypes.bool,
  email: PropTypes.string,
};

export default injectIntl(ShareLink);
