/* eslint-disable import/extensions, global-require */
import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import createHistory from 'history/createBrowserHistory';
import * as Sentry from '@sentry/browser';
import { APP_BASE, EXTRA_SCRIPTS, SENTRY_DSN } from './constants/branding';

import isTouch from './functions/isTouch';
import isIOS from './functions/isIOS';
import loadScript from './functions/script';
import './css-init/flexboxgrid.min.css';

import Root from './Root';

const DEBUG = process.env.NODE_ENV !== 'production';

if (DEBUG) {
  require('./css-init/default.css');
  require('./css-init/branding/fonts.css');
  require('./css-init/start.css');
  require('./css-init/layout.css');
}

if (isTouch()) {
  document.body.className = 'touch ';
  // initReactFastclick();
  if (isIOS()) {
    document.body.className += 'is-ios';
  }
} else {
  document.body.className = 'no-touch';
}

if (EXTRA_SCRIPTS && EXTRA_SCRIPTS.length) {
  loadScript(EXTRA_SCRIPTS);
}

const render = (Component, props) => {
  ReactDOM.render(
    <AppContainer>
      <Component {...props} />
    </AppContainer>,
    document.getElementById('root')
  );
};

Sentry.init({
  enabled: ['production', 'development'].includes(process.env.NODE_ENV),
  dsn: SENTRY_DSN,
  environment: process.env.NODE_ENV,

  integrations: [Sentry.browserTracingIntegration()],

  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'http://localhost:3000',
    /^https:\/\/wegotrip\.com\/.*/,
    /^https:\/\/wegotrip\.ru\/.*/,
    /^https:\/\/wegotrip\.site\/.*/,
  ],
});

const history = createHistory({ basename: APP_BASE });

function runApp() {
  render(Root, { history });
}

if (!window.Intl) {
  require.ensure(
    ['intl', 'intl/locale-data/jsonp/en.js', 'intl/locale-data/jsonp/ru.js'],
    function (require) {
      require('intl');
      require('intl/locale-data/jsonp/en.js');
      require('intl/locale-data/jsonp/ru.js');
      runApp();
    }
  );
} else {
  runApp();
}

if (module.hot) {
  module.hot.accept('./Root', () => {
    render(Root, { history });
  });
}
