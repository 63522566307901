import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from '@material-ui/core/Button';
import Gap from '../Gap';
import GapItem from '../Gap/GapItem';

/**
 * JSS styles for `TourContentFooterButtons` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  gap: {
    flexWrap: 'wrap',
  },
  themeBlue: {
    marginTop: 50,
    paddingBottom: 30,
  },
  themeBlack: {
    padding: '16px',
  },
  before: {
    margin: '24px 0',
    [theme.breakpoints.down('md')]: {
      margin: '15px 0',
    },
  },
  beforeGap: {
    width: '100%',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: '1',
  },
  contentEmpty: {
    margin: '0 !important',
  },
}));

/**
 * Component for displaying correct and / or incorrect examples
 * @param {Object} $
 * @param {String} $.className - additional CSS class name
 * @param {String} $.theme - CSS theme of the component (`"blue"` or `"black"`, first by default)
 * @param {String} $.leftBtnLabel - left button label
 * @param {String} $.rightBtnLabel - right button label
 * @param {String} $.leftLink - left button link
 * @param {String} $.rightLink - right button link
 * @param {React::JSX|Array[React::JSX]} $.children - children to be rendered between buttons
 * @param {Boolean} $.leftBtnVisible - for left button visible
 * @param {Boolean} $.withRedirectToTourCheck - for change state after redirect from TourCheck
 */
const TourContentFooterButtons = ({
  className,
  theme = 'blue',
  leftBtnLabel,
  rightBtnLabel,
  leftLink,
  rightLink,
  leftBtnVisible = true,
  rightBtnDisable = false,
  rightBtnFunc,
  withRedirectToTourCheck = false,
  before,
  children,
}) => {
  const classes = useStyles();
  const materialTheme = useTheme();
  const isMobile = useMediaQuery(materialTheme.breakpoints.down('md'));
  const gaps = !isMobile
    ? ['0px', '16px', '0px', '16px']
    : ['0px', '5px', '5px', '5px'];

  return (
    <div
      {...(before ? { style: { marginTop: 0 } } : {})}
      className={classNames(
        classes[`theme${theme[0].toUpperCase() + theme.slice(1)}`],
        className
      )}
    >
      <Gap className={classes.gap} gaps={gaps}>
        {before ? (
          <GapItem className={classes.beforeGap}>
            <div className={classes.before}>{before}</div>
          </GapItem>
        ) : null}
        {leftBtnVisible && (
          <GapItem>
            <NavLink to={leftLink}>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                {...(theme === 'blue' || 'SmallMargin'
                  ? { color: 'primary' }
                  : {})}
              >
                {leftBtnLabel || (
                  <FormattedMessage
                    id="StudioCreateProduct.TourFooterBack"
                    defaultMessage="Back"
                  />
                )}
              </Button>
            </NavLink>
          </GapItem>
        )}
        <GapItem
          className={classNames(
            classes.content,
            !children && classes.contentEmpty
          )}
        >
          {children}
        </GapItem>
        <GapItem>
          <NavLink to={rightLink}>
            <Button
              disabled={rightBtnDisable}
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIcon />}
              onClick={(e) => rightBtnFunc && rightBtnFunc(e)}
            >
              {withRedirectToTourCheck ? (
                <FormattedMessage
                  id="StudioCreateProduct.TourFooterBackToCheck"
                  defaultMessage="Next"
                />
              ) : (
                rightBtnLabel || (
                  <FormattedMessage
                    id="StudioCreateProduct.TourFooterNext"
                    defaultMessage="next"
                  />
                )
              )}
            </Button>
          </NavLink>
        </GapItem>
      </Gap>
    </div>
  );
};

export default TourContentFooterButtons;
