import React from 'react';

/**
 * Context for spreading state `isMenuShown` into props
 * @type {Object}
 * @prop {Boolean} isDropdownVisible - if dropdown component open
 */
export const VisibleContext = React.createContext({
  isDropdownVisible: false,
});

/**
 * Spreads `VisibleContext` into props
 * @param {React::Component} Component
 */
export default function withVisible(Component) {
  return function (props) {
    return (
      <VisibleContext.Consumer>
        {(value) => {
          return <Component {...value} {...props} />;
        }}
      </VisibleContext.Consumer>
    );
  };
}
