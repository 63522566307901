import { ACCESS_TOKEN } from '../../constants/map/mapbox';
import formURL from '../URL/formURL';

/**
 * Constructs path via roads between `coords` point coordinates
 * Uses MapBox API
 * @param {Array[LeafletJS::LatLng]} coords - array with coords of waypoints (`lat` and `lng` props)
 * @param {String} method - target way of moving for path constructing (`"traffic"`, `"driving"`, `"walking"` or `"cycling"`)
 * @param {String} token - MapBox API access token
 * @returns {Object} - raw mapbox API answer
 */
export async function fetchRawRoutePath(
  coords = [],
  method,
  token = ACCESS_TOKEN
) {
  const queryCoords = coords.map(({ lat, lng }) => `${lng},${lat}`).join(';');
  return (
    await fetch(
      formURL({
        pathname: `https://api.mapbox.com/directions/v5/mapbox/${method}/${encodeURIComponent(
          queryCoords
        )}`,
        query: { geometries: 'geojson', access_token: token },
      })
    )
  ).json();
}
