import React, { Fragment } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { FormattedMessage } from 'react-intl';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';

/**
 * Static event helper prefix text
 * @type {React::JSX}
 */
export const eventAlertPrefix = (
  <Fragment>
    <FormattedMessage
      id="EventAlert.infoCenter"
      defaultMessage="Information center"
    />
    {': '}
  </Fragment>
);

/**
 * JSS styles for `EventControlsAudioInfo` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '&,& a': {
      color: theme.palette.info.textDark,
    },
  },
  margin: {
    marginBottom: '16px',
  },
  icon: {
    marginRight: '8px',
  },
}));

/**
 * Info texts for `EventControlsAudio` component
 * @param {Object} $
 * @param {String?} $.className - additional CSS class names
 * @param {Boolean} $.noMargin - removes bottom margin
 */
export default function EventAlert({ className, noMargin = false, children }) {
  const styles = useStyles();

  return (
    <div
      className={classNames(styles.root, !noMargin && styles.margin, className)}
    >
      <InfoIcon className={styles.icon} color="primary" />
      <Typography variant="body2">{children}</Typography>
    </div>
  );
}
