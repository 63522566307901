import formQuery from './formQuery';

/**
 * Forms URL from its pathname and query object
 * @param {Object} $
 * @param {String} $.pathname - URL pathname, if not string will return empty string
 * @param {Object} $.query - query object, will form query URL string
 * @returns {String} - formed URL
 */
export default function formURL({ pathname, query = {} }) {
  if (typeof pathname !== 'string') {
    console.error(
      `Not valid pathname, expected String, given ${typeof pathname}`
    );
    return '';
  }

  return `${pathname}${formQuery(query, true)}`;
}
