import React from 'react';
import grey from '@material-ui/core/colors/grey';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { GAP, ROW_STYLES } from '..';
import TableCell from '../TableCell';

/**
 * @typedef {Object} TableHeaderData
 * @prop {String} id - unique id of the column
 * @prop {String|React::JSX} title - title of the column
 * @prop {String} type - type of data contained in the column
 * @prop {Object} attributes - any valid HTML attribute (will be applied to the whole column)
 * @prop {Object?} filter - description of the filter to use on this column (necessary only for `Table::withFilters(Table)`)
 *   if not passed - filter based on `type` will be applied
 * @prop {String} filter.id - id of the filter to use on this column passed in `Table::withFilters::filters`
 * @prop {Object?} filter.props - will be passed to the filtering component passed in `Table::withFilters::filters`
 */

/**
 * JSS styles for `TableHeader` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: ROW_STYLES.borderBottom,
    padding: `16px ${GAP[1]}px`,
    color: grey[600],
  },
}));

/**
 * Header for `Table` component
 * @param {Object} $
 * @param {String} $.className - additional CSS class
 * @param {Array[TableHeaderData]} $.cells - description of the header of the table
 */
export default function TableHeader({ className, cells = [] }) {
  const styles = useStyles();

  return (
    <thead>
      <tr>
        {cells.map(({ title, id, type, attributes = {} }) => (
          <TableCell
            key={id}
            className={classNames(styles.root, className)}
            variant="subtitle2"
            type={type}
            {...attributes}
          >
            {title}
          </TableCell>
        ))}
      </tr>
    </thead>
  );
}
