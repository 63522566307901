import { useState } from 'react';

/**
 * Queue with it's methods wrapped in state hook
 * @returns {Object} - `push` and `take` methods to controls queue and `queue` copy
 */
export default function useQueue() {
  const [queue, setQueue] = useState([]);

  /**
   * Adds items to `queue`
   * @param  {...Any} items - items to push in queue
   */
  const push = (...items) => setQueue([...queue, ...items]);

  /**
   * Removes first item from queue
   * @returns {*} - previous first item from queue
   */
  const take = () => {
    const first = queue[0];
    setQueue(queue.slice(1));
    return first;
  };

  /**
   * Removes everything from queue
   */
  const flush = () => setQueue([]);

  /**
   * Returns length of the queue
   * @returns {Number}
   */
  const length = () => queue.length;

  return { push, take, flush, length, queue: [...queue] };
}
