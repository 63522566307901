import React, { useState } from 'react';
import toPairs from 'lodash/toPairs';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

/**
 * State for the `AccordionMultiple` component
 * Spread return from this hook to the `AccordionMultiple` props in case of basic usage
 * @param {Object} $
 * @param {Array[Number]} $.initialActive - initial `active` prop for `AccordionMultiple`, immutable
 * @returns {Object} - `onChanged` and `active` props to be spread on `AccordionMultiple` component props
 */
export function useAccordionState({ initialActive = [] }) {
  /**
   * Converts array of values (as in `active` props) to hash map (as in `selected` prop)
   * @param {Array[Number]} array - array with toggle button values to be displayed
   * @returns {Object} - hash map as in `selected` format
   */
  const arrayToHash = (array) =>
    array.reduce((acc, i) => ({ ...acc, [i]: true }), {});

  /**
   * Hash boolean map with indexes of tabs as keys and states (shown/hidden) as values
   * @type {Object}
   */
  const [selected, setSelected] = useState(arrayToHash(initialActive));

  /**
   * Currently shown component ids
   * @type {Array[Number]}
   */
  const selectedValues = toPairs(selected).reduce(
    (acc, pair) => (pair[1] ? [...acc, Number(pair[0])] : acc),
    []
  );

  /**
   * Resets shown tabs to `newActive` array
   * @param {Array[Number]} newActive - new `active` prop
   */
  const resetActive = (newActive) => {
    setSelected(arrayToHash(newActive));
  };

  /**
   * Button in the header was toggled
   * @param {Number} value - index of the toggled button
   */
  const onChanged = (value) => {
    const newValue = !selected[value];
    setSelected({ ...selected, [value]: newValue });
  };

  return {
    active: selectedValues,
    resetActive,
    onChanged,
  };
}

/**
 * JSS styles for `AccordionMultiple` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    '& > *:not(:last-child)': {
      marginRight: '16px',
    },
  },
}));

/**
 * Hybrid of tabs and accordion components
 * Will show components passed as children if toggle buttons associated with them are active
 * @param {Object} $
 * @param {String} $.className - additional CSS class name
 * @param {String} $.buttonType - type of the buttons (`"toggle"` or `"regular"`, first is default)
 *   - if `"toggle"` - `onChange` will be listened and `selected` prop on button will be changed
 *   - if `"regular"` - `onClick` will be listened and button will be removed if selected
 * @param {Object} $.classes - CSS classes for children and modifiers (`"headerActive"`)
 * @param {Array[Number]} $.active - currently active buttons
 * @param {Array[MaterialUI::ToggleButton]} $.buttons - buttons toggle components visibility
 * @param {Array[React::Component]} $.children - components to be shown
 * @param {Function} $.onChanged - user changed state of one of toggle buttons, calls with index of button in `buttons` prop and new value of the button
 */
export default function AccordionMultiple({
  className,
  buttonType = 'toggle',
  classes = {},
  active = [],
  buttons = [],
  children = [],
  onChanged = () => {},
}) {
  const styles = useStyles();

  const childrenArray = React.Children.toArray(children);
  const childrenAmount = childrenArray.length;
  const availableActive = active.filter(
    (value) => typeof value === 'number' && value < childrenAmount
  );

  return (
    <div className={className}>
      <div
        className={classNames(
          styles.buttonGroup,
          classes.header,
          availableActive.length && classes.headerActive
        )}
      >
        {buttons.map((button, i) => {
          const selected = active.includes(i);

          switch (buttonType) {
            case 'regular': {
              return !selected
                ? React.cloneElement(button, {
                    onClick: () => onChanged(i, !selected),
                  })
                : null;
            }
            case 'toggle': {
              return React.cloneElement(button, {
                value: i,
                selected,
                onChange: () => onChanged(i, !selected),
              });
            }
          }
        })}
      </div>
      <div>
        {availableActive.map((value) => (
          <div key={value}>{childrenArray[value]}</div>
        ))}
      </div>
    </div>
  );
}
