export default function getPaginatedData(paginatedData) {
  if (Array.isArray(paginatedData))
    return {
      count: 0,
      pages: null,
      current: null,
      next: null,
      results: [],
    };
  return paginatedData;
}
