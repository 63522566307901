import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import InputLight from '../InputLight';
import './SmartInput.css';

class SmartInput extends Component {
  getErrorAnimation() {
    const { errors: { animation } = {} } = this.props;
    return animation;
  }

  cleanProps() {
    const exclude = [
      `reason`,
      `errors`,
      `className`,
      `children`,
      `errorMessages`,
    ];
    const propsCopy = { ...this.props };
    exclude.forEach((prop) => delete propsCopy[prop]);
    return propsCopy;
  }

  renderInput() {
    // let { children } = this.props;
    // TODO: deal with children buttons
    const { inputRef, ...props } = this.cleanProps();
    return (
      <InputLight
        inputRef={inputRef}
        className={classNames(this.getErrorAnimation() && 'SmartInput--error')}
        {...props}
      />
    );
  }

  getErrorMessages() {
    const { errors, errorMessages } = this.props;
    // TODO: subject to change
    if (!errors || isEmpty(errors)) return [];
    if (!errorMessages) return [];
    const messages = errors.errors.map((key) => {
      if (!errorMessages.hasOwnProperty(key)) return;
      return errorMessages[key];
    });
    return messages;
  }

  getSuccessMessage() {
    const { success, successMessage } = this.props;
    if (!success || !successMessage) {
      return;
    }
    return successMessage;
  }

  renderReasonOrErrorOrSuccess() {
    const { reason } = this.props;
    const errorMessages = this.getErrorMessages();
    if (errorMessages.length)
      return (
        <p className="SmartInput__reason SmartInput__error">
          {errorMessages.map((error) => error)}
        </p>
      );
    const successMessage = this.getSuccessMessage();
    if (successMessage) {
      return (
        <p className="SmartInput__reason SmartInput__success">
          {successMessage}
        </p>
      );
    }
    if (!reason) {
      return null;
    }
    return <p className="SmartInput__reason">{reason}</p>;
  }

  render() {
    const { className } = this.props;
    return (
      <div className={classNames('SmartSmartInput__wrapper', className)}>
        {this.renderInput()}
        {this.renderReasonOrErrorOrSuccess()}
      </div>
    );
  }
}

SmartInput.propTypes = {
  reason: PropTypes.string,
  errorMessages: PropTypes.objectOf(PropTypes.object),
  errors: PropTypes.shape({
    // animation: PropTypes.bool.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default SmartInput;
