import React from 'react';
import Button from '../../Button';
import Popup from '../../Popup';
import Spinner from '../../Spinner';
import { translations } from '../translations';

const SpinnerPopup = ({
  isOpen,
  handleCloseClick,
  handleManualClose,
  title = 'Processing...',
  generationProgress,
  showGenerationProgress = false,
  showAlert = false,
  handleSubmit,
  formatMessage,
}) => {
  const contentTitle = formatMessage(translations.generationOccurs);
  const contentSubtitle = formatMessage(translations.popupDescription);
  const isError = generationProgress === 400;

  return (
    <Popup
      className="GenerateAiTourForm__popup-steps"
      isOpen={isOpen}
      handleCloseClick={handleCloseClick}
      title={title}
      theme="info"
    >
      <div className="GenerateAiTourForm__popup-steps_content">
        {isError ? (
          <>
            <div className="GenerateAiTourForm__popup-steps_text">
              <div className="GenerateAiTourForm__popup-steps_title">
                {formatMessage(translations.helperErrorTitle)}{' '}
              </div>
              <div className="GenerateAiTourForm__popup-steps_subtitle">
                {formatMessage(translations.helperErrorDiscription)}
              </div>
            </div>
            <div className="GenerateAiTourForm__popup-steps_button-block">
              <Button
                className="GenerateAiTourForm__generate-button"
                size="medium"
                theme="error"
                onClick={() => handleManualClose(true)}
              >
                {formatMessage(translations.closeButton)}
              </Button>
            </div>
          </>
        ) : showAlert ? (
          <>
            <div className="GenerateAiTourForm__popup-steps_text">
              <div className="GenerateAiTourForm__popup-steps_title">
                {formatMessage(translations.makeChangesTitle)}
              </div>
              <div className="GenerateAiTourForm__popup-steps_subtitle">
                {formatMessage(translations.popupDescriptionHelper)}
              </div>
            </div>
            <div className="GenerateAiTourForm__popup-steps_button-block">
              <Button
                className="GenerateAiTourForm__generate-button"
                size="medium"
                onClick={handleCloseClick}
              >
                {formatMessage(translations.cancelButton)}
              </Button>
              <Button
                className="GenerateAiTourForm__generate-button"
                size="medium"
                onClick={async () => {
                  await handleSubmit();
                  handleCloseClick();
                }}
              >
                {formatMessage(translations.generateButton)}
              </Button>
            </div>
          </>
        ) : (
          <>
            {showGenerationProgress && (
              <div className="GenerateAiTourForm__popup-steps_progress">
                {generationProgress}%
              </div>
            )}

            <Spinner
              className={`GenerateAiTourForm__popup-steps_spinner ${
                showGenerationProgress
                  ? 'GenerateAiTourForm__popup-steps_spinner-progress'
                  : ''
              }`}
            />
            <div className="GenerateAiTourForm__popup-steps_text">
              <div className="GenerateAiTourForm__popup-steps_title">
                {contentTitle}
              </div>
              <div className="GenerateAiTourForm__popup-steps_subtitle">
                {contentSubtitle}
              </div>
            </div>
          </>
        )}
      </div>
    </Popup>
  );
};

export default SpinnerPopup;
