import { NETWORK_ERROR, RESET_NETWORK_ERROR } from '../constants/ActionTypes';

export function resetNetworkError(error) {
  return {
    type: RESET_NETWORK_ERROR,
    error,
  };
}

export function showNetworkError(error) {
  return {
    type: NETWORK_ERROR,
    error,
  };
}
