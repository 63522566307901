import React from 'react';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage, defineMessages } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import IPhoneIcon from '@material-ui/icons/PhoneIphone';
import Button from '@material-ui/core/Button';
import { Desktop } from '../../../functions/queries';
import ButtonToggle from '../../Button/ButtonToggle';
import Event from '../../Event';

/**
 * Messages for `EventControlsHeader` component
 * @type {Object}
 */
const messages = defineMessages({
  step: {
    id: 'EventControlsHeader.step',
    defaultMessage: 'Step',
  },
  preview: {
    id: 'EventControlsHeader.preview',
    defaultMessage: 'AI tools',
  },
  remove: { id: 'EventControlsHeader.remove', defaultMessage: 'Remove' },
});

/**
 * JSS styles for `EventControlsHeader` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  line: {
    '--buttons-spacing': '0px',
    [theme.breakpoints.up('md')]: {
      '--buttons-spacing': '10px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    [theme.breakpoints.up('md')]: {
      marginRight: '5px !important',
      fontSize: '16px',
    },
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    margin:
      '0 calc(-1 * var(--buttons-spacing)) calc(-1 * var(--buttons-spacing)) 20px',
  },
  button: {
    minWidth: 'unset',
    margin: '0 var(--buttons-spacing) var(--buttons-spacing) 0',
    backgroundColor: 'var(--fuck-color)',
    color: theme.palette.text.disabled,
  },
}));

/**
 * Header for `EventControls` event content page
 * @param {Object} $
 * @param {String|Number} $.questId - id of the quest
 * @param {String} $.className - additional CSS class name
 * @param {String} $.eventId - id of the current editing event
 * @param {Number} $.stepOrder - index of the step will be shown in the header
 * @param {Number} $.stepsAmount - number of events will be shown in header
 * @param {Boolean} $.isPreview - if event preview mode activated
 * @param {Boolean} $.hasMapLink - if link to the route map should be displayed in tabs
 * @param {Function?} $.onRemoveRequested - "remove event" button was clicked, if `undefined` - no button will be shown
 * @param {Function?} $.onPreviewToggled - "preview event" button was toggled
 */
export default function EventControlsHeader({
  className,
  stepOrder,
  stepsAmount,
  isPreview = false,
  onRemoveRequested,
  onPreviewToggled,
  hasAITourGroup,
  products,
  isTablet,
}) {
  const styles = useStyles();
  const creationTime = new Date(products[0].creationTime);

  const comparisonDate = new Date('2024-03-03T00:00:00.000Z');

  const showOnDemandToursForm = creationTime > comparisonDate;
  return (
    <Event>
      <div className={className}>
        <div className={styles.line}>
          <Typography variant="h4">
            <FormattedMessage {...messages.step} /> {stepOrder}/{stepsAmount}
          </Typography>
          <div className={styles.buttonGroup}>
            {showOnDemandToursForm &&
            hasAITourGroup &&
            onPreviewToggled &&
            !isTablet ? (
              <ButtonToggle
                className={styles.button}
                variant="text"
                value="preview"
                size="small"
                selected={isPreview}
                onChange={onPreviewToggled}
              >
                <IPhoneIcon className={styles.icon} />
                <Desktop>
                  <FormattedMessage {...messages.preview} />
                </Desktop>
              </ButtonToggle>
            ) : null}
            {onRemoveRequested ? (
              <Button
                className={styles.button}
                size="small"
                onClick={onRemoveRequested}
              >
                <DeleteIcon className={styles.icon} />
                <Desktop>
                  <FormattedMessage {...messages.remove} />
                </Desktop>
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </Event>
  );
}
