import React, { useState, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import IconButton from '@material-ui/core/IconButton';
import rotate from '../../../functions/canvas/rotate';
import unite from '../../../functions/function/unite';
import { DEFAULT_TYPE } from '..';
import PictureEditorTool from '../PictureEditorTool';

/**
 * Rotation controls descriptions
 * @type {Array[Object]}
 */
const ANGLES = [
  { Icon: RotateLeftIcon, angle: 90 },
  { Icon: RotateRightIcon, angle: -90 },
];

/**
 * Messages for `PictureEditor::withRotate` mod
 * @type {Object}
 */
const messages = {
  title: (
    <FormattedMessage id="PictureEditorRotate.title" defaultMessage="Rotate" />
  ),
};

/**
 * Adds "rotate" tool to `PictureEditor`
 * @param {React::Component} Component - componenet to overload
 */
export default function withRotate(Component) {
  /**
   * `PictureEditor` component with "rotate" tool
   * @param {Object} $
   * @param {...Object} $.editorProps - props for `PictureEditor` component
   */
  return function ({
    fileType = DEFAULT_TYPE,
    onApi: onApiExt,
    children,
    ...editorProps
  }) {
    /**
     * API of the original `PictureEditor` class instance
     * @type {React::Ref}
     */
    const [editorApi, setEditorApi] = useState(null);
    const onApiComposition = unite(setEditorApi, onApiExt);

    /**
     * Requests `PictureEditor` API to save rotated canvas
     * @param {Number} angle - angles from `ANGLES` const
     */
    const rotateCanvas = (angle) => {
      if (editorApi) {
        editorApi.update(
          rotate(editorApi.image, angle, { type: fileType }).then(
            ({ base64 }) => base64
          ),
          'withRotate'
        );
      }
    };

    return (
      <Component fileType={fileType} onApi={onApiComposition} {...editorProps}>
        <PictureEditorTool
          title={messages.title}
          controls={
            <Fragment>
              {ANGLES.map(({ Icon, angle }) => (
                <IconButton
                  key={angle}
                  disabled={editorApi && !!editorApi.operation}
                  onClick={() => rotateCanvas(angle)}
                >
                  <Icon />
                </IconButton>
              ))}
            </Fragment>
          }
        />
        {children}
      </Component>
    );
  };
}
