import { useEffect } from 'react';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

/**
 * Scrolls component to top when page pathname changes
 * @param {Object} $
 * @param {React::Ref?} $.scrollable - ref to the element which must be scrolled to top, if not given - scrolls window
 */
export default function ScrollToTop({ scrollable }) {
  const { pathname } = useLocation();

  useEffect(() => {
    const toScroll = get(scrollable, 'current.scrollTo')
      ? scrollable.current
      : window;
    toScroll.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
