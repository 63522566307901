import getEventContent from './getEventContent';

/**
 * Pops audios from event
 * @param  {Object} event - description of event from store
 * @return {Array[String]} - array of audio URLs
 */
export default function getEventAudios(event) {
  const item = getEventContent('audio', event);
  if (!item) return [];
  return item.data;
}
