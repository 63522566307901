import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Gap from '../../Gap';
import GapItem from '../../Gap/GapItem';

/**
 * JSS styles for `PictureEditorTool` component
 * @type {React::Hook}
 */
export const useStyles = makeStyles((theme) => ({
  tool: {
    '--button-padding': '8px',
    display: 'block',
    marginBottom: '32px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '32px',
      '&:last-child': {
        marginRight: '32px',
      },
    },
  },
  controls: {
    display: 'inline-block',
    marginTop: 'calc(-1 * var(--button-padding))',
    marginLeft: 'calc(-1 * var(--button-padding))',
    borderRadius: '4px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '& > *': {
      borderRadius: 0,
      padding: 'var(--button-padding)',
    },
  },
}));

/**
 * Tool for `PictureEditor`
 * @param {Object} $
 * @param {React::JSX} $.title - tool name
 * @param {React::JSX} $.controls - `material-ui::IconButton` components
 * @param {React::JSX} $.children - will be displayed under title and controls, can add `GapItem` to add margin
 */
export default function PictureEditorTool({ title, controls, children }) {
  const styles = useStyles();

  return (
    <Gap className={styles.tool} gaps={['0px', '0px', '8px', '0px']}>
      <GapItem>
        <Typography>{title}</Typography>
      </GapItem>
      {controls ? (
        <div>
          <div className={styles.controls}>{controls}</div>
        </div>
      ) : null}
      {children}
    </Gap>
  );
}
