import checkMediaId from './checkMediaId';

/**
 * Appends `increment` to index of `mediaId` if last one was not broken
 * @param {MediaId} mediaId - media id to operate
 * @param {Number} increment - number to increment (if negative - will decrement)
 * @returns {MediaId|*} - incremented `mediaId` object, or not changed `mediaId` if it was broken or `increment` is not a number
 */
export default function incrementMediaId(mediaId, increment) {
  if (
    checkMediaId(mediaId) &&
    typeof increment === 'number' &&
    !isNaN(increment)
  ) {
    mediaId.index += increment;
  }

  return mediaId;
}
