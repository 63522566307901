import * as types from '../constants/ActionTypes';

export function requestStats(questId) {
  return {
    type: types.REQUEST_STATS,
    questId,
  };
}

export function recieveStats(questId, json) {
  return {
    type: types.RECEIVE_STATS,
    payload: json.data,
    questId,
  };
}

export function notRecieveStats(error) {
  return {
    type: types.NOT_RECEIVE_STATS,
    ...error,
  };
}

export function alreadyReceiveStats() {
  return {
    type: types.ALREADY_RECEIVE_STATS,
  };
}

export function requestQuestsStats(page) {
  return {
    type: types.REQUEST_QUESTS_STATS,
    page,
  };
}

export function fetchPayouts(page) {
  return {
    type: types.FETCH_PAYOUTS,
    page,
  };
}
