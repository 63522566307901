import isNumber from '../types/isNumber';

/**
 * Template of the separator between coords in string
 * @type {RegExp}
 */
const coordsStringTemplate = /,?\s+/;

/**
 * Checks if `input` string consists of two float/int numbers
 * @param {String} input
 * @returns {Boolean}
 */
export function isCoordsString(input) {
  const nums = input.trim().split(coordsStringTemplate);
  return nums.length === 2 && nums.every((num) => isNumber(num));
}

/**
 * Gets coords as two numbers from `input` string
 * Coords can be divided by some amount of space symbols or space symbols with comma on start
 * @param {String} input
 * @returns {Array[Number]|Null} - array with coords or `null` if string does not contain them
 */
export function coordsFromString(input) {
  return isCoordsString(input)
    ? input
        .trim()
        .split(coordsStringTemplate)
        .map((coord) => parseFloat(coord))
    : null;
}
