import { defineMessages } from 'react-intl';

/**
 * Messages describing payout statuses
 * @type {Object}
 */
export default defineMessages({
  cancelled: {
    id: 'payout.cancelled',
    defaultMessage: 'cancelled',
  },
  processing: {
    id: 'payout.processing',
    defaultMessage: 'in\u00A0processing',
  },
  done: {
    id: 'payout.done',
    defaultMessage: 'done',
  },
});
