import {
  CREATE_TUTORIAL,
  UPDATE_TUTORIAL_INDEX,
  CLOSE_TUTORIAL,
  FINISH_TUTORIAL,
  RESET_TUTORIAL,
  UPDATE_UNFINISHED_TUTORIALS_COUNT,
} from '../constants/ActionTypes';

export default function tutorial(
  state = {
    name: '',
    run: false,
    stepIndex: 0,
    skipped: false,
    finished: false,
    unFinishedTutorialsCount: 0,
  },
  action
) {
  switch (action.type) {
    case CREATE_TUTORIAL:
      return { ...state, name: action.name, run: true };

    case UPDATE_TUTORIAL_INDEX:
      return { ...state, stepIndex: action.stepIndex };

    case CLOSE_TUTORIAL:
      return { ...state, run: false, skipped: action.skipped, stepIndex: 0 };

    case RESET_TUTORIAL:
      return {
        ...state,
        run: false,
        stepIndex: 0,
        name: 'Default',
        skipped: false,
        finished: false,
      };

    case FINISH_TUTORIAL:
      return {
        ...state,
        name: action.name,
        run: false,
        finished: action.finished,
        stepIndex: 0,
      };

    case UPDATE_UNFINISHED_TUTORIALS_COUNT:
      return {
        ...state,
        unFinishedTutorialsCount: action.unFinishedTutorialsCount,
      };
    default:
      return state;
  }
}
