import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HELP_CENTER_URL } from '../../../constants/branding';
import { EVENT_CONTENT_IMAGE } from '../../../constants/upload/contentTypes';
import {
  MAX_CONTROLS_IMAGE_AMOUNT,
  MAX_CONTROLS_IMAGE_SIZE,
} from '../../../constants/limits';
import EventHeader from '../../Event/EventHeader';
import EventAlert from '../../Event/EventAlert';
import EventComponent from '../../Event';
import withContentLoader from '../../Dropzone/withContentLoader';
import withContentDescription from '../../Dropzone/withContentDescription';
import DropzoneContent from '../../Dropzone/DropzoneContent';
import withPictureEditor from '../../Dropzone/withPictureEditor';
import PictureDropzonePure from '../../Dropzone/PictureDropzone';
import DefaultPictureEditor from '../../PictureEditor/DefaultPictureEditor';

/**
 * `DropzoneContent` with text input near the field
 * @type {React::Component}
 */
const DropzonePicturesContent = withContentDescription(
  withContentLoader(DropzoneContent)
);

/**
 * `PictureDropzone` with `PictureEditor`
 * @type {React::Component}
 */
const PictureDropzone = withPictureEditor(
  PictureDropzonePure,
  DefaultPictureEditor
);

/**
 * Static pictures event helper text
 * @type {React::JSX}
 */
const helperText = (locale) => (
  <a
    href={`${HELP_CENTER_URL}${locale}/photo/`}
    target="_blank"
    rel="noreferrer"
  >
    <FormattedMessage
      id="EventControlsPictures.info"
      defaultMessage="Which photos could be added?"
    />
  </a>
);

/**
 * Form for loading pictures to event content of "image" type
 * @param {Object} $
 * @param {String} $.locale - locale for forming URLs (`"en"` or `"ru"`)
 * @param {...Object} $.props - obligatory props for `Dropzone::PictureDropzone`
 */
export default function EventControlsPictures({
  locale,
  onAdded,
  onRemovedAll,
  onRemoved,
  ...props
}) {
  const { images: uploadedImages } = props;
  return (
    <EventComponent>
      <EventHeader margin={false}>
        <FormattedMessage
          id="EventControlsPictures.titlePictures"
          defaultMessage="Pictures of the place (max. {max})"
          values={{
            max: MAX_CONTROLS_IMAGE_AMOUNT,
          }}
        />
      </EventHeader>
      <EventAlert>{helperText(locale)}</EventAlert>
      <PictureDropzone
        infoCompressing
        generatedPhotos
        maxSize={MAX_CONTROLS_IMAGE_SIZE}
        contentType={EVENT_CONTENT_IMAGE}
        disabled={uploadedImages?.length >= MAX_CONTROLS_IMAGE_AMOUNT}
        maxAmount={MAX_CONTROLS_IMAGE_AMOUNT}
        currentAmount={uploadedImages?.length}
        ContentPreviewComponent={DropzonePicturesContent}
        descriptionInputProps={{ inputProps: { maxLength: '150' } }}
        onAdded={(metas) => onAdded(props?.params, metas)}
        onRemoved={onRemoved}
        onRemovedAll={onRemovedAll}
        {...props}
      />
    </EventComponent>
  );
}
