/* eslint-disable no-unused-expressions */
import React, { useState, useMemo } from 'react';
import './TourTitle.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import {
  Paper,
  makeStyles,
  TextField,
  Typography,
  Container,
  Grid,
  Button,
  Link,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as publishingActions from '../../../actions/publishing';
import TourContentFooterButtons from '../../TourContentFooter/TourContentFooterButtons';
import Examples from '../../Examples';
import debounce from '../../../functions/debounce';
import { PRODUCT_TITLE_CHARACTER_LIMIT } from '../../../constants/limits';
import { HELP_CENTER_URL } from '../../../constants/branding';
import { getCurrentLanguage } from '../../../functions/languages/getCurrentLanguage';

/**
 * Good title examples
 * @type {Array[Intl::Object]}
 */
const good = [
  <FormattedMessage
    key="StudioCreateProduct.titleGoodExample1"
    id="StudioCreateProduct.titleGoodExample1"
    defaultMessage="Louvre Museum: audio tour of the state rooms and main masterpieces with tickets"
  />,
  <FormattedMessage
    key="StudioCreateProduct.titleGoodExample2"
    id="StudioCreateProduct.titleGoodExample2"
    defaultMessage="Instagram-walk from Rivoli to Seine embankments in Paris"
  />,
  <FormattedMessage
    key="StudioCreateProduct.titleGoodExample3"
    id="StudioCreateProduct.titleGoodExample3"
    defaultMessage="A quest tour of abandoned estates by car"
  />,
];

/**
 * Bad title examples
 * @type {Array[Intl::Object]}
 */
const bad = [
  <FormattedMessage
    key="StudioCreateProduct.titleBadExample1"
    id="StudioCreateProduct.titleBadExample1"
    defaultMessage="BEST VIEWS OF PARIS ON AN AMAZING ROUTE"
  />,
  <FormattedMessage
    key="StudioCreateProduct.titleBadExample2"
    id="StudioCreateProduct.titleBadExample2"
    defaultMessage="Sightseeing tour of Berlin from Maria"
  />,
];

/**
 * JSS styles for `TourTitle` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 24,
    marginTop: 40,
    marginBottom: -15,
  },
  textFieldSm: {
    maxWidth: 190,
  },
  container: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
  },
  cardTitle: {
    marginBottom: 14,
  },
  helperFullness: {
    marginTop: 40,
    marginBottom: -25,
  },
  text: {
    marginBottom: 25,
  },
  allRecommendations: {
    paddingLeft: 88,
    color: theme.palette.primary.main,
  },
  grid: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 25,
    },
  },
  titleField: {
    width: '100%',
    maxWidth: 760,
  },
  subtitle2: {
    color: '#757575',
  },
  or: {
    color: '#bdbdbd',
  },
}));

const TourTitle = ({
  currentQuest,
  currentQuest: { products },
  currentQuestId,
  checkFullness,
  intl,
  // eslint-disable-next-line no-shadow
  publishingActions,
  publishing: {
    receivedPublishData,
    receivedPublishData: { locale },
  },
  location,
  userLocale,
  title,
  languages,
  setTitle,
}) => {
  /**
   * Error state of title field
   * @type {Boolean}
   */
  const [error, setError] = useState(false);

  /**
   * JSS styles for `TourTitle` element
   * @type {React::Hook}
   */
  const classes = useStyles();

  /**
   * Prevent Enter key press
   * @param {Event} e - `keyDown` DOM event
   */
  const preventPressEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  /**
   * Validate nextValue sting and send it to server
   * @param {String} nextValue - product value for passed attribute
   * @param {String} attribute - product attribute key name
   */
  const handleSave = (nextValue, attribute) => {
    // eslint-disable-next-line no-param-reassign
    nextValue = nextValue.trim();
    if (!nextValue) {
      setError(true);
      return;
    }
    if (nextValue === receivedPublishData[attribute]) return;
    publishingActions.sendPublishData(products[0].id, {
      [attribute]: nextValue,
    });
  };

  /**
   * Memoized and debounced wrap of handleSave
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSave = useMemo(() => debounce(handleSave, 1000), [
    receivedPublishData,
  ]);

  /**
   * Sends to server nextValue by attribute
   * @param {Function} nextValue - function to update local state
   * @param {String} attribute - product attribute key name
   */
  const handleChangeLocale = (nextValue, attribute) => {
    publishingActions.sendPublishData(currentQuest.products[0].id, {
      [attribute]: nextValue,
    });
  };

  /**
   * Checking the filling of fields and returning what is not filled
   * @returns {String} - fields that are not filled
   */
  const handleFullnessText = () => {
    const entities = [];

    !receivedPublishData.title &&
      entities.push(
        intl.formatMessage({
          id: 'StudioCreateProduct.TourTitleTitle',
          defaultMessage: 'tour title',
        })
      );
    !locale &&
      entities.push(
        intl.formatMessage({
          id: 'StudioCreateProduct.TourTitleLanguage',
          defaultMessage: 'language',
        })
      );

    return entities.join(', ');
  };

  /**
   * Update local state and save value with wrapped handleSave
   * @param {Function} func - function to update local state
   * @param {String} attribute - product attribute key name
   * @param {Event} event - DOM event caused trigger `onChange`
   */
  const handleChange = (func, attribute) => (event) => {
    setError(false);
    func(event.target.value);
    debouncedHandleSave(event.target.value, attribute);
  };

  return (
    <section className="App__main TourTitle__main">
      <Container className={classes.container}>
        <Grid container>
          <Grid item xs={12} md={8} lg={8}>
            <Paper elevation={0} className={classes.paper}>
              <Typography
                gutterBottom
                className={classes.cardTitle}
                variant="h5"
              >
                <FormattedMessage
                  id="StudioCreateProduct.tourTitle"
                  defaultMessage="Tour title"
                />
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                className={classes.subtitle2}
              >
                <FormattedMessage
                  id="StudioCreateProduct.subheadings2"
                  defaultMessage="Optimal title format:"
                />
              </Typography>
              <Typography gutterBottom variant="body1">
                <FormattedMessage
                  id="StudioCreateProduct.subheadings2-2"
                  defaultMessage="«Location: type of activity {word} point of interest with more information»"
                  values={{
                    word: (
                      <i className={classes.or}>
                        <FormattedMessage
                          id="StudioCreateProduct.TourTitleOr"
                          defaultMessage="or"
                        />
                      </i>
                    ),
                  }}
                />
              </Typography>

              <TextField
                multiline
                className={classes.titleField}
                label={
                  <FormattedMessage
                    id="StudioCreateProduct.tourTitle2"
                    defaultMessage="Tour title"
                  />
                }
                error={
                  (!receivedPublishData.title && location.highlights) || error
                }
                placeholder={intl.formatMessage({
                  id: 'StudioCreateProduct.titlePlaceholder',
                  defaultMessage:
                    'Louvre Museum: State Halls & Masterpieces Audio Tour',
                })}
                variant="standard"
                value={title}
                inputProps={{ maxLength: PRODUCT_TITLE_CHARACTER_LIMIT }}
                helperText={
                  (!receivedPublishData.title && location.highlights) ||
                  error ? (
                    <FormattedMessage
                      id="StudioCreateProduct.TourTitleCantBeEmpty"
                      defaultMessage="The field cannot be empty"
                    />
                  ) : (
                    <FormattedMessage
                      id="StudioCreateProduct.charsLeft"
                      defaultMessage="Characters {value} left"
                      values={{
                        value: PRODUCT_TITLE_CHARACTER_LIMIT - title.length,
                      }}
                    />
                  )
                }
                onKeyDown={preventPressEnter}
                onChange={handleChange(setTitle, 'title')}
              />

              <div className="TourTitle__main-examples">
                <Typography gutterBottom variant="h6">
                  <FormattedMessage
                    id="StudioCreateProduct.examplesTitle"
                    defaultMessage="Examples"
                  />
                </Typography>
              </div>
              <Examples withWords good={good} bad={bad} />
              <Link
                target="_blank"
                href={`${HELP_CENTER_URL}${userLocale}/title/`}
                rel="noopener noreferrer"
              >
                <Typography
                  variant="body2"
                  className={classes.allRecommendations}
                >
                  <FormattedMessage
                    id="StudioCreateProduct.TourTitleAllRecommendations"
                    defaultMessage="ALL RECOMMENDATIONS"
                  />
                </Typography>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4} className={classes.grid}>
            <Paper elevation={0} className={classes.paper}>
              <Typography variant="h5" className={classes.text}>
                <FormattedMessage
                  id="StudioCreateProduct.TourTitleIdeas"
                  defaultMessage="Want some cool ideas?"
                />
              </Typography>
              <Typography variant="body1" className={classes.text}>
                <FormattedMessage
                  id="StudioCreateProduct.TourTitleIdeasSubtitle"
                  defaultMessage="Over the past 4 years we have understood the preferences of WeGoTrip customers and selected 30 best ideas for you"
                />
              </Typography>
              <a
                target="_blank"
                href={
                  userLocale === 'ru'
                    ? 'https://airtable.com/shrOscioxFYVgAXIE/tbl2giWA6jSDR7iJu'
                    : 'https://airtable.com/shrc4fEdL344N4rIH'
                }
                rel="noopener noreferrer"
              >
                <Button variant="outlined" color="primary">
                  <FormattedMessage
                    id="StudioCreateProduct.TourTitleButton"
                    defaultMessage="Pick up an idea"
                  />
                </Button>
              </a>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <Paper elevation={0} className={classes.paper}>
              <Typography className={classes.cardTitle} variant="h5">
                <FormattedMessage
                  id="StudioCreateProduct.TourAudienceLanguage"
                  defaultMessage="Tour language"
                />
              </Typography>

              <Autocomplete
                disableClearable
                options={languages}
                getOptionLabel={(option) => option.name}
                className={classes.textFieldSm}
                value={getCurrentLanguage(locale, languages)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="select"
                    error={!locale && location.highlights}
                    label={
                      <FormattedMessage
                        id="StudioCreateProduct.selectLanguage"
                        defaultMessage="Select language"
                      />
                    }
                    variant="standard"
                  />
                )}
                onChange={(event, value) =>
                  handleChangeLocale(value.id, 'localeId')
                }
              />
            </Paper>
          </Grid>
        </Grid>
        {!checkFullness('title') && (
          <Typography className={classes.helperFullness} variant="body1">
            <FormattedMessage
              id="StudioCreateProduct.TourTitleRemainsToFill"
              defaultMessage="Remaining sections to fill in: "
            />
            {handleFullnessText()}
          </Typography>
        )}
        <TourContentFooterButtons
          leftBtnVisible={false}
          rightLink={
            location.highlights
              ? `/quest/${currentQuestId}/check`
              : `/quest/${currentQuestId}/description`
          }
          withRedirectToTourCheck={location.highlights}
        />
      </Container>
    </section>
  );
};

function makeMapStateToProps() {
  const mapStateToProps = (state) => {
    return {
      publishing: state.publishing,
      currentQuestId: state.quests.currentQuestId,
      languages: state.languages.items,
      userLocale: state.user.locale,
    };
  };
  return mapStateToProps;
}

function mapDispatchToProps(dispatch) {
  return {
    publishingActions: bindActionCreators(publishingActions, dispatch),
  };
}

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(TourTitle)));
