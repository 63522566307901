import React from 'react';
import { FormHelperText } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

export default function InfoFileCompressing() {
  return (
    <FormHelperText style={{ width: '100%', marginRight: 40 }}>
      <FormattedMessage
        id="InfoFileCompressing.text"
        defaultMessage="Large files may delay the tour download time. Use {CompressLink} or {OnlineconverterLink} to compress the file"
        values={{
          CompressLink: (
            <a
              href="https://www.compresss.com"
              target="_blank"
              rel="noreferrer"
            >
              compress.com
            </a>
          ),
          OnlineconverterLink: (
            <a
              href="http://onlineconverter.com"
              target="_blank"
              rel="noreferrer"
            >
              onlineconverter.com
            </a>
          ),
        }}
      />
    </FormHelperText>
  );
}
