import { defineMessages } from 'react-intl';

export default defineMessages({
  button: {
    id: 'events.triggersButton',
    defaultMessage: 'button',
  },
  geo: {
    id: 'events.triggersGeo',
    defaultMessage: 'geopoint {index}',
  },
  automatically: {
    id: 'events.triggersAutomatically',
    defaultMessage: 'automatically',
  },
});
