/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable consistent-return */
/* eslint-disable import/no-named-as-default */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, NavLink, Route, withRouter } from 'react-router-dom';
import { isEmpty, isEqual } from 'lodash';
import Tooltip from 'react-tooltip';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import {
  Typography,
  Button as ButtonMui,
  CircularProgress,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/Error';
import Button from '../Button';
import StructuredPopup from '../Popup/StructuredPopup';
import GetQuest from '../GetQuest';
import QuestDuplicateButton from '../QuestDuplicateButton';
import filterGeoEvents from '../../functions/filterGeoEvents';
import Loader from '../Loader';
import { Mobile, Desktop } from '../../functions/queries';
import nullAsDefault from '../../functions/nullAsDefault';
import ShareLink from '../ShareLink';
import calcQuestMeasureParam from '../../functions/directions/calcQuestMeasureParam';
import getDirectionsProps from '../../functions/directions';
import './QuestShare.css';
import { HELP_CENTER_URL } from '../../constants/branding';
import { getTourTitleString } from '../../constants/quests/questsConstants';

export const ActionButton = ({ disabled, onClick, children }) => {
  return !disabled ? (
    <Button className="QuestShare__button" theme="main-light" onClick={onClick}>
      {children}
    </Button>
  ) : (
    <Fragment>
      <Mobile>
        {(isMobile) => (
          <Button
            data-tip
            className="QuestShare__button"
            data-event={isMobile ? 'click' : null}
            data-for="QuestShare__button"
            theme="disabled"
          >
            {children}
          </Button>
        )}
      </Mobile>
      <Tooltip
        id="QuestShare__button"
        place="bottom"
        effect="solid"
        class="QuestShare__tooltip"
      >
        <FormattedMessage
          id="QuestShare.validationNote"
          defaultMessage="Fill in the contents of the tour events, they should all be ticked"
        />
      </Tooltip>
    </Fragment>
  );
};

const ActionButtonMui = ({ disabled, onClick, children }) => {
  return !disabled ? (
    <ButtonMui
      style={{ minWidth: 140 }}
      variant="outlined"
      color="primary"
      className="QuestShare__button"
      theme="main-light"
      onClick={onClick}
    >
      {children}
    </ButtonMui>
  ) : (
    <Fragment>
      <Mobile>
        {(isMobile) => (
          <ButtonMui
            data-tip
            disableRipple
            style={{ minWidth: 140 }}
            data-event={isMobile ? 'click' : null}
            data-for="QuestShare__button"
            variant="outlined"
          >
            {children}
          </ButtonMui>
        )}
      </Mobile>
      <Tooltip
        id="QuestShare__button"
        place="bottom"
        effect="solid"
        class="QuestShare__tooltip"
      >
        <FormattedMessage
          id="QuestShare.validationNote"
          defaultMessage="Fill in the contents of the tour events, they should all be ticked"
        />
      </Tooltip>
    </Fragment>
  );
};

const messages = defineMessages({
  distanceInKilometersText: {
    id: 'QuestShare.distanceInKilometersText',
    defaultMessage: 'km',
  },
  distanceInMetresText: {
    id: 'QuestShare.distanceInMetresText',
    defaultMessage: 'm',
  },
  durationInHourText: {
    id: 'QuestShare.durationInHourText',
    defaultMessage: '{hours, plural, one {hour} other {hours}}',
  },
  durationInMinText: {
    id: 'QuestShare.durationInMinText',
    defaultMessage: '{minutes, plural, one {minute} other {minutes}}',
  },
});

class QuestShare extends Component {
  state = {
    showPublishingErrorMessage: false,
  };

  componentWillReceiveProps(nextProps) {
    const {
      publishing: { errors },
      publishingActions: { resetPublishProductErrors },
    } = nullAsDefault(this.props);

    const {
      publishing: { errors: nextErrors },
    } = nullAsDefault(nextProps);

    if (!isEqual(errors, nextErrors) && nextErrors.length > 0) {
      this.setState((prevState) => {
        return {
          ...prevState,
          showPublishingErrorMessage: true,
        };
      });

      setTimeout(() => {
        this.setState((prevState) => {
          return {
            ...prevState,
            showPublishingErrorMessage: false,
          };
        });

        resetPublishProductErrors();
      }, 3000);
    }
  }

  buildUrl(relativePath) {
    const {
      params: { questId, eventOrder = 0 },
    } = nullAsDefault(this.props);

    return `/quest/${questId}/events/${eventOrder}/${relativePath}`;
  }

  isInWorkspace() {
    const { currentQuest = null } = nullAsDefault(this.props);
    return currentQuest && !isEmpty(currentQuest);
  }

  isInMyQuestPath() {
    const {
      location: { pathname },
    } = this.props;

    return pathname.includes('/quest/');
  }

  renderTitle() {
    const {
      currentQuest,
      publishing: {
        receivedPublishData: { id, title },
      },
      intl: { formatMessage },
    } = this.props;

    if (!this.isInMyQuestPath() || !title) return;

    return (
      <h1 className="QuestShare__heading">
        <div className="QuestShare__heading-title">
          <Typography variant="overline">
            <FormattedMessage
              id="QuestShare.tourCreation"
              defaultMessage="Creation of the tour:"
            />
          </Typography>
          <span>
            <Typography style={{ marginTop: -7 }} variant="body1">
              {title
                ? getTourTitleString(title, formatMessage)
                : getTourTitleString('', formatMessage)}
            </Typography>
          </span>
        </div>
        <Mobile>
          <QuestDuplicateButton
            className="QuestShare__duplicate"
            quest={currentQuest}
          />
        </Mobile>
      </h1>
    );
  }

  formatDistance = (distance) => {
    const {
      intl: { formatMessage },
    } = this.props;

    return distance > 1000
      ? `${Math.round(distance / 1000)} ${formatMessage(
          messages.distanceInKilometersText
        )}`
      : `${distance} ${formatMessage(messages.distanceInMetresText)}`;
  };

  formatDuration = (duration) => {
    const {
      intl: { formatMessage },
    } = this.props;

    if (duration > 3600) {
      const hours = Math.ceil(duration / 3600);
      return `${hours} ${formatMessage(messages.durationInHourText, {
        hours,
      })}`;
    }
    const minutes = Math.floor(duration / 60);
    return `${minutes} ${formatMessage(messages.durationInMinText, {
      minutes,
    })}`;
  };

  getDirectionsParams = (legs) => {
    const distance = this.formatDistance(
      calcQuestMeasureParam(legs, 'distance')
    );
    const duration = this.formatDuration(
      calcQuestMeasureParam(legs, 'duration')
    );

    return { distance, duration };
  };

  async publishProduct() {
    const {
      currentQuest: { id: questId, title, events },
      publishingActions: { startPublishProduct, publishProduct },
    } = this.props;

    const filteringGeoEvents = filterGeoEvents(events);
    let startLocationGeo = [];
    if (filteringGeoEvents.length > 0) {
      const {
        geo: { lat, lon },
      } = filterGeoEvents(events)[0].trigger;
      startLocationGeo = [lon, lat];
    }

    // EnablingFetch
    startPublishProduct();

    const {
      encodingGeoTriggersCoords,
      waypoints,
      directionsService,
    } = await getDirectionsProps(events);

    if (encodingGeoTriggersCoords.length > 1) {
      directionsService.route(
        {
          origin: encodingGeoTriggersCoords[0],
          destination:
            encodingGeoTriggersCoords[encodingGeoTriggersCoords.length - 1],
          travelMode: 'WALKING',
          waypoints,
        },
        (response, status) => {
          if (status === 'OK') {
            const { distance, duration } = this.getDirectionsParams(
              response.routes['0'].legs
            );

            publishProduct({
              questId,
              title,
              distance,
              duration,
              startLocationGeo,
            });
          } else {
            // If google directions service is failed, quest will be publish with default distance and duration
            // eslint-disable-next-line no-console
            console.log(`Directions request failed due to ${status}`);
            publishProduct({
              questId,
              title,
              distance: 0,
              duration: 0,
              startLocationGeo,
            });
          }
        }
      );
    } else {
      // if the quest does not have more than one geo event
      publishProduct({
        questId,
        title,
        distance: 0,
        duration: 0,
        startLocationGeo,
      });
    }
  }

  renderProductLink() {
    const { showPublishingErrorMessage } = this.state;

    const { user, currentQuest = null, isQuestValid } = this.props;

    if (!currentQuest || !user.isPartner || user.isClient) {
      return null;
    }

    const { currentQuest: { products = [] } = {} } = nullAsDefault(this.props);

    if (products.length) {
      return null;
    }

    return (
      <div
        className="QuestShare__button-wrapper"
        onClick={isQuestValid ? this.publishProduct.bind(this) : null}
      >
        <ActionButton disabled={!isQuestValid}>
          <FormattedMessage
            id="QuestShare.createProduct"
            defaultMessage="Publish the tour"
          />
        </ActionButton>
        <div
          className={classNames(
            'QuestShare__error-message',
            showPublishingErrorMessage && 'QuestShare__error-message--active'
          )}
        >
          <FormattedMessage
            id="QuestShare.publishingError"
            defaultMessage="Error with publishing tour"
          />
        </div>
      </div>
    );
  }

  getLink = ({ message, urlPath }) => {
    const { isQuestValid } = this.props;

    const getActionButton = (
      <ActionButtonMui disabled={!isQuestValid} color="primary">
        {message}
      </ActionButtonMui>
    );

    return isQuestValid ? (
      <Link className="QuestShare__button-wrapper" to={this.buildUrl(urlPath)}>
        {getActionButton}
      </Link>
    ) : (
      <span className="QuestShare__button-wrapper">{getActionButton}</span>
    );
  };

  renderActions() {
    const {
      user,
      currentQuest = null,
      closeSidebar = {},
      currentPlans,
    } = nullAsDefault(this.props);

    if (!this.isInMyQuestPath && !this.isInWorkspace()) {
      return null;
    }

    const shareMessage = (
      <FormattedMessage id="QuestShare.share" defaultMessage="Share the tour" />
    );

    if (currentQuest && user.client && !user.isPartner) {
      return (
        <Link
          className="QuestShare__button-wrapper"
          to={`/quest/${currentQuest.id}/codes`}
          onClick={closeSidebar}
        >
          <ActionButton>{shareMessage}</ActionButton>
        </Link>
      );
    }

    if (!currentPlans || !currentPlans.length) {
      return null;
    }

    const { maxDownloads = 0, downloads = 0 } = user.client || {};
    const balance = maxDownloads - downloads;

    if (balance <= 0) {
      return this.getLink({ message: shareMessage, urlPath: 'publish' });
    }

    return null;
  }

  renderTestButton() {
    const {
      currentQuest = {},
      // params: { eventOrder },
    } = nullAsDefault(this.props);

    if (!this.isInWorkspace()) {
      return null;
    }

    if (!currentQuest.testAccess) {
      return;
    }

    const testMessage = (
      <FormattedMessage id="QuestShare.test" defaultMessage="Test the tour" />
    );

    return this.getLink({ message: testMessage, urlPath: 'test' });
  }

  renderTestPopup() {
    const {
      user,
      params: { eventOrder = 0 },
      currentQuest = null,
      currentQuest: { testAccess = '' } = {},
      history,
    } = nullAsDefault(this.props);

    return (
      <StructuredPopup
        isOpen
        className="QuestShare__test-popup"
        handleCloseClick={() => {
          history.push(`/quest/${currentQuest.id}/events/${eventOrder}/`);
        }}
        title={
          <FormattedMessage
            id="QuestShare.test"
            defaultMessage="Test the tour"
          />
        }
        noPadding={false}
      >
        <ShareLink
          isTestAccess
          email={user.email}
          code={testAccess}
          // title={currentQuest.title}
        />
      </StructuredPopup>
    );
  }

  renderPublishPopup() {
    const {
      user,
      params: { eventOrder },
      currentQuest = null,
      currentQuest: { shownAccess = '' } = {},
      currentPlans,
      publishingActions,
      history,
    } = nullAsDefault(this.props);

    return (
      <StructuredPopup
        isOpen
        noPadding
        noMargin
        handleCloseClick={() => {
          history.push(`/quest/${currentQuest.id}/events/${eventOrder}/`);
        }}
        title={
          <FormattedMessage
            id="QuestShare.share"
            defaultMessage="Share the tour"
          />
        }
      >
        <GetQuest
          user={user}
          quest={currentQuest}
          code={shownAccess}
          plans={currentPlans}
          publishingActions={publishingActions}
        />
      </StructuredPopup>
    );
  }

  renderHeaderStatuses() {
    const {
      currentQuest: { ok, syncing } = {},
      user,
      quests,
      partner = {},
      publishing,
      isProfile = false,
      isQuestCreating,
    } = nullAsDefault(this.props);

    const isInMyQuestPath = this.isInMyQuestPath();

    return (
      <div className="QuestShare__status-container">
        {(isProfile || isInMyQuestPath) &&
          (user?.isNotSaved ||
            partner?.isNotSaved ||
            quests?.isNotSaved ||
            publishing?.isNotSaved ||
            ok === false) &&
          !isQuestCreating &&
          !quests?.isFetching &&
          !user?.isFetching &&
          !user?.isSending &&
          !partner?.isFetching &&
          !partner?.isSending &&
          !syncing &&
          !publishing?.isSaving && (
            <React.Fragment>
              <ErrorIcon
                fontSize="small"
                style={{ marginRight: 8, color: '#F44336' }}
              />
              <Typography
                variant="body1"
                style={{ width: 105, color: '#801313' }}
              >
                <FormattedMessage
                  id="QuestShare.notSaved"
                  defaultMessage="Not saved"
                />
              </Typography>
            </React.Fragment>
          )}

        {((isProfile && user.isSaved) ||
          (isProfile && partner.isSaved) ||
          (isProfile && quests.isSaved) ||
          (isInMyQuestPath &&
            publishing.isSaving === false &&
            !syncing &&
            isProfile &&
            !isQuestCreating &&
            !user.isFetching &&
            !user.isSending &&
            !partner.isFetching &&
            !partner.isSending &&
            !quests.isNotSaved &&
            !quests.isSaving &&
            !user.isNotSaved &&
            !partner.isNotSaved &&
            !publishing.isNotSaved) ||
          (ok === true && syncing === false && publishing.isSaving === false) ||
          (syncing === false &&
            publishing.isSaving === false &&
            publishing.isSaved)) && (
          <React.Fragment>
            <CheckCircleOutlineIcon
              fontSize="small"
              style={{ marginRight: 8, color: '#4CAF50' }}
            />
            <Typography variant="body1" style={{ color: '#212121' }}>
              <FormattedMessage id="QuestShare.saved" defaultMessage="Saved" />
            </Typography>
          </React.Fragment>
        )}
        {(isProfile || isInMyQuestPath) &&
          (user?.isFetching ||
            user?.isSending ||
            partner?.isFetching ||
            partner?.isSending ||
            quests?.isSaving ||
            isQuestCreating ||
            syncing ||
            publishing?.isSaving) && (
            <React.Fragment>
              <CircularProgress
                color="primary"
                size={17}
                style={{ marginRight: 10, marginLeft: 1 }}
              />
              <Typography variant="body1" color="primary">
                <FormattedMessage
                  id="QuestShare.save"
                  defaultMessage="Save..."
                />
              </Typography>
            </React.Fragment>
          )}
      </div>
    );
  }
  renderHeaderActions() {
    const { user, isProfile } = nullAsDefault(this.props);

    return (
      <React.Fragment>
        {this.renderHeaderStatuses()}
        {!isProfile && (
          <React.Fragment>
            <Typography variant="body1">
              <a
                target="_blank"
                href={`${HELP_CENTER_URL}${user.locale}/`}
                rel="noopener noreferrer"
                className="QuestShare__help-link"
              >
                <FormattedMessage id="QuestShare.help" defaultMessage="Help" />
              </a>
            </Typography>
            {this.renderTestButton()}
            <NavLink to="/">
              <ButtonMui
                variant="contained"
                color="primary"
                style={{ marginRight: 30 }}
              >
                <FormattedMessage
                  id="QuestShare.close"
                  defaultMessage="Close"
                />
              </ButtonMui>
            </NavLink>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  render() {
    const {
      publishing: { isPublishing },
      isProfile,
    } = nullAsDefault(this.props);

    return (
      <Fragment>
        <Loader active={isPublishing}>
          <FormattedMessage
            id="QuestShare.publishProduct"
            defaultMessage="Tour is published"
          />
        </Loader>
        <Mobile>
          <div className="QuestShare__title">{this.renderTitle()}</div>
        </Mobile>
        <Desktop>{this.renderTitle()}</Desktop>
        <div className="QuestShare__actions">
          <Desktop>
            {(this.isInMyQuestPath() || this.isInWorkspace() || isProfile) &&
              this.renderHeaderActions()}
          </Desktop>
          {this.renderActions()}
          {this.renderProductLink()}
        </div>
        <Route
          path="/quest/:questId/events/:eventOrder/:anchor(publish|test)"
          render={({
            match: {
              params: { anchor },
            },
          }) => {
            anchor === 'test'
              ? this.renderTestPopup()
              : this.renderPublishPopup();
          }}
        />
      </Fragment>
    );
  }
}

QuestShare.propTypes = {
  user: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  isPopupOpen: PropTypes.bool.isRequired,
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  currentQuest: PropTypes.object,
  // TODO: complete
};

export default injectIntl(withRouter(injectIntl(QuestShare)));
