import getValidationResults from './getValidationResults';

export function checkQuestValid(events) {
  return events
    .map((event) => {
      const next = event.order < events.length ? events[event.order + 1] : null;
      return getValidationResults(event, next);
    })
    .every((result) => result.isEventValid === true);
}
