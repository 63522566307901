import React, { useRef, useState, useCallback, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import debounce from '../../../functions/debounce';
import checkMediaId from '../../../functions/id/checkMediaId';
import useMediaId from '../../../functions/id/useMediaId';
import useDragAndDrop from '../../DragAndDrop/useDragAndDrop';
import withContentBelow from '../withContentBelow';
import DropzoneContent from '../DropzoneContent';
import Dropzone from '../withPictures';
import usePhotoFull from '../../../functions/photo/usePhotoFull';
import InfoFileCompressing from '../../InfoFileCompressing';
import getElementsIndexes from './getElementsIndexes';
import { DeleteBinIcon } from '../../Icon';
import Popup from '../../Popup';
import Button from '../../Button';
import './EventControlsPictures.css';
import { translations } from '../translations';
import { FormattedMessage } from 'react-intl';

/**
 * `DropzonePictures` with ability to add content below it
 * @type {React::Component}
 */
const DropzonePictures = withContentBelow(Dropzone);

/**
 * JSS styles for `EventControlsPictures` component
 * @type {Object}
 */
const useStyles = makeStyles(() => ({
  dropzone: {
    marginBottom: 32,
    padding: '3%',
    '& .Dropzone__info': {
      justifyContent: 'center',
    },
  },
  imgButton: {
    width: '48px',
    height: '48px',
    padding: '6px 8px 6px 7px;',
    gap: '4px',
    borderRadius: '8px',
    border: '1px solid var(--tone-300)',
    marginLeft: '5px',
  },
  title: {
    fontWeight: '600',
    fontSize: '22px',
    color: 'var(--tone-700)',
    marginTop: '10px',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(84px, 1fr))',
    gap: '8px',
    marginTop: '16px',
    marginBottom: '5px',
    maxWidth: '100%',
    boxSizing: 'border-box',
  },
  gridImage: {
    width: '100%',
    height: 'auto',
    aspectRatio: '1',
    borderRadius: '16px',
    cursor: 'pointer',
    position: 'relative',
    objectFit: 'cover',
  },
  selectButton: {
    position: 'absolute',
    top: '0',
    right: '0',
    width: '32px',
    height: '32px',
    borderRadius: '16px',
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
    textAlign: 'center',
    lineHeight: '30px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '4px',
      left: '4px',
      right: '4px',
      bottom: '4px',
      borderRadius: '50%',
      border: '1px solid white',
    },
  },
  confirmButton: {
    width: '183px',
    background: 'var(--tone-700)',
    border: 0,
    color: 'var(--white)',
    height: '40px',
    borderRadius: '8px',
    transition: 'background-color 0.3s ease, color 0.3s ease',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '18.75px',
    '&:disabled': {
      backgroundColor: 'var(--tone-300)',
      color: 'var(--tone-400)',
    },
  },
  popup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    borderRadius: '16px',
  },
  popupImage: {
    maxWidth: '100%',
    maxHeight: '80vh',
  },
  textPopupHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '16px',
  },
  textPopupCloseButton: {
    background: 'none',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
  },
  gridImageWrapper: {
    position: 'relative',
    maxWidth: '200px',
  },
}));

function renderPictureDropzoneContent(Component, props) {
  return <Component key={props.src} {...props} />;
}

/**
 * Prebuilt dropzone for uploading images with:
 * - drag and drop
 * - content preview thumbs
 * - image editor
 * @param {Object} $
 * @param {String} $.contentType - content type will be added to object returned by `onAdded` (default is `"image"`)
 * @param {Object} $.params - object with `eventId` and `questId` props
 * @param {Array[Object]?} $.uploads - uploading images descriptions (in format as in `store::uploads.uploads[]`)
 * @param {Array[String]?} $.images - URLs of the loaded images
 * @param {Array[String]?} $.descriptions - image descriptions
 * @param {Object?} $.descriptionInputProps - additional props spread to `TextField` component using as image description input
 * @param {Boolean?} $.disabled - if dropzone disabled
 * @param {Function?} $.onAborted - upload was aborted by user
 * @param {Function?} $.onAdded - will be called when user added new image
 * @param {Function?} $.onRemoved - will be called when user removed image (also for uploading one's)
 * @param {Function?} $.onDragStarted - user clicked element to start dragging it (return `false` to stop dragging)
 * @param {Function?} $.onDragged - user moved element to another (return `false` to prevent dropping on this position)
 * @param {Function?} $.onReordered - image or upload was dragged to another position
 * @param {Function?} $.onDescription - description of the image was changed
 */
export default function PicturesDropzone({
  disabled = false,
  generatedPhotos = false,
  tourPhotosContent = false,
  overviewPhotos = false,
  maxAmount,
  currentAmount,
  params = {},
  contentType = 'image',
  uploads = [],
  images = [],
  descriptions: descriptionsExt = [],
  descriptionInputProps = {},
  onAdded = () => {},
  onRemoved = () => {},
  onRemovedAll = () => {},
  onAborted = () => {},
  onDragStarted = () => true,
  onDragged = () => true,
  onReordered = () => {},
  onDescription = () => {},
  ContentPreviewComponent = DropzoneContent,
  renderContent = renderPictureDropzoneContent,
  maxSize,
  infoCompressing,
}) {
  const styles = useStyles();

  const paramsRef = useRef(params);
  useEffect(() => {
    paramsRef.current = params;
  }, [params.eventId, params.questId]);

  const { componentMediaId } = useMediaId();

  const allImages = images.map((src, i) => ({ src, originalIndex: i }));
  uploads.forEach((upload, i) => {
    const mediaId = checkMediaId(upload.mediaId)
      ? upload.mediaId
      : componentMediaId(i);
    allImages.splice(mediaId.index, 0, {
      src: upload.dataURL,
      mediaId,
      originalIndex: i,
      isUpload: true,
    });
  });
  for (let i = 0; i < allImages.length; i += 1) {
    const image = allImages[i];
    image.mediaId = image.mediaId ? image.mediaId : componentMediaId(i);
  }
  const allImagesRef = useRef(allImages);
  allImagesRef.current = allImages;

  const [descriptions, setDescriptions] = useState(
    images.map((src, i) => descriptionsExt[i] || '')
  );

  const { PhotoFullComponent, handleSetPhoto } = usePhotoFull();

  const setDescription = (i, newValue) => {
    const newDescriptions = [...descriptions];
    newDescriptions[i] = newValue;
    setDescriptions(newDescriptions);
  };

  useEffect(() => {
    setDescriptions(images.map((src, i) => descriptionsExt[i] || ''));
  }, [JSON.stringify(images)]);

  const onDescriptionDebounced = useCallback(debounce(onDescription, 1000), [
    onDescription,
  ]);

  const addImages = (files) => {
    onAdded(
      files.map((blob, i) => ({
        contentType,
        file: blob,
        mediaId: componentMediaId(images.length + uploads.length + i),
      }))
    );
  };

  const [isRemovePopupOpen, setIsRemovePopupOpen] = useState(false);
  const [indexToRemove, setIndexToRemove] = useState(null);

  const confirmRemoveImage = () => {
    if (indexToRemove !== null) {
      const imageDesc = allImages[indexToRemove];
      if (imageDesc) {
        onRemoved({
          imageId: imageDesc.originalIndex,
          mediaId: imageDesc.mediaId,
          ...params,
        });
      }
    }
    setIsRemovePopupOpen(false);
    setIndexToRemove(null);
  };

  const handleRemoveImage = (index) => {
    if (generatedPhotos) {
      setIndexToRemove(index);
      setIsRemovePopupOpen(true);
    } else {
      const imageDesc = allImages[index];
      if (imageDesc) {
        onRemoved({
          imageId: imageDesc.originalIndex,
          mediaId: imageDesc.mediaId,
          ...params,
        });
      }
    }
  };

  const abortUpload = (index) => {
    const uploadDesc = allImages[index];
    if (uploadDesc && uploads[uploadDesc.originalIndex]) {
      onAborted(uploads[uploadDesc.originalIndex].id);
    }
  };

  const updateDescription = (index, newValue) => {
    const imageDesc = allImages[index];
    if (imageDesc && images[imageDesc.originalIndex]) {
      const imageId = imageDesc.originalIndex;
      if (newValue !== descriptions[imageId]) {
        setDescription(index, newValue);
        onDescriptionDebounced({
          description: newValue,
          imageId,
          ...params,
        });
      }
    }
  };

  const reorderImage = (fromIndex, toIndexUnsafe) => {
    const toIndex =
      toIndexUnsafe > fromIndex ? toIndexUnsafe - 1 : toIndexUnsafe;
    const currAllImages = allImagesRef.current;
    const imageDesc = currAllImages[fromIndex];
    const siblingImageDesc = currAllImages[toIndex];

    if (imageDesc) {
      let lastImage = toIndex > -1 ? toIndex : currAllImages.length;
      while (
        lastImage < currAllImages.length &&
        currAllImages[lastImage].isUpload
      ) {
        lastImage += 1;
      }

      const newImageId =
        lastImage < currAllImages.length
          ? currAllImages[lastImage].originalIndex
          : images.length;

      onReordered({
        imageId: imageDesc.originalIndex,
        mediaId: imageDesc.mediaId,
        newImageId,
        newMediaId: siblingImageDesc
          ? siblingImageDesc.mediaId
          : componentMediaId(images.length + uploads.length),
        isUpload: imageDesc.isUpload,
        ...paramsRef.current,
      });
    }
  };

  const draggableClassName = 'EventControlsPictures__draggable';
  const { getDragAndDropRoot } = useDragAndDrop({
    onDropped: (element, target, source, sibling) =>
      reorderImage(...getElementsIndexes(element, sibling)),
    moves: (e, s, h, sibling) => {
      return (
        onDragStarted(...getElementsIndexes(e, sibling)) &&
        (tourPhotosContent ? h.classList.contains(draggableClassName) : true)
      );
    },
    accepts: (e, t, s, sibling) => {
      return onDragged(...getElementsIndexes(e, sibling));
    },
  });

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupImageSrc, setPopupImageSrc] = useState('');
  const [selectedImages, setSelectedImages] = useState([]);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [isSelectionConfirmed, setIsSelectionConfirmed] = useState(false);

  const handleImageClick = (src) => {
    setPopupImageSrc(src);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setPopupImageSrc('');
  };

  const handleSelectImage = (src, isUpload, originalIndex) => {
    setSelectedImages((prevSelectedImages) => {
      const isSelected = prevSelectedImages.some((image) => image.src === src);
      const maxImages = overviewPhotos ? 10 : 5;

      if (isSelected) {
        return prevSelectedImages.filter((image) => image.src !== src);
      } else if (prevSelectedImages.length < maxImages) {
        return [...prevSelectedImages, { src, isUpload, originalIndex }];
      } else {
        return prevSelectedImages;
      }
    });
  };

  const getSelectionNumber = (src) => {
    const index = selectedImages.findIndex((image) => image.src === src);
    return index === -1 ? null : index + 1;
  };

  const handleConfirmClose = () => {
    setIsConfirmPopupOpen(false);
  };

  const handleCancelClose = () => {
    setIsConfirmPopupOpen(false);
  };

  const showPopup = () => {
    setIsConfirmPopupOpen(true);
  };

  const handleConfirmSelection = () => {
    const selectedIndexes = selectedImages.map((image) => image.originalIndex);
    const imagesToRemove = allImages.filter(
      (image) => !selectedIndexes.includes(image.originalIndex)
    );
    const mediaIdsToRemove = imagesToRemove.map((image) => image.mediaId);
    const imageIdsToRemove = imagesToRemove.map((image) => image.originalIndex);

    mediaIdsToRemove.forEach((mediaId, index) => {
      if (imagesToRemove[index].isUpload) {
        onAborted(uploads[imagesToRemove[index].originalIndex].id);
      }
    });

    if (mediaIdsToRemove.length > 0) {
      onRemovedAll({
        imageIds: imageIdsToRemove,
        mediaIds: mediaIdsToRemove,
        ...params,
      });
    }

    setIsSelectionConfirmed(true);
    setIsConfirmPopupOpen(false);
  };

  return (
    <div>
      {PhotoFullComponent}
      <DropzonePictures
        infoCompressing
        maxSize={maxSize}
        contentRef={
          !isSelectionConfirmed &&
          ((overviewPhotos && images.length <= 10) ||
            (!overviewPhotos && images.length <= 5)) &&
          getDragAndDropRoot
        }
        className={styles.dropzone}
        disabled={disabled}
        maxAmount={maxAmount}
        currentAmount={currentAmount}
        accept="image/*"
        onDropAccepted={addImages}
      >
        {generatedPhotos &&
          !isSelectionConfirmed &&
          ((overviewPhotos && images.length > 10) ||
            (!overviewPhotos && images.length > 5)) && (
            <>
              <div className={styles.gridContainer}>
                {allImages.map(({ src, isUpload, originalIndex }, i) => (
                  <div
                    key={i}
                    className={styles.gridImageWrapper}
                    draggable={
                      !isSelectionConfirmed &&
                      ((overviewPhotos && images.length <= 10) ||
                        (!overviewPhotos && images.length <= 5))
                    }
                  >
                    <img
                      src={src}
                      alt={`img-${i}`}
                      className={styles.gridImage}
                      onClick={() => handleImageClick(src)}
                    />
                    <div
                      className={styles.selectButton}
                      onClick={() =>
                        handleSelectImage(src, isUpload, originalIndex)
                      }
                    >
                      {getSelectionNumber(src)}
                    </div>
                  </div>
                ))}
              </div>
              <Button
                className={styles.confirmButton}
                size="medium"
                onClick={showPopup}
                disabled={!selectedImages.length}
              >
                <FormattedMessage {...translations.confirmYourChoice} />
              </Button>
            </>
          )}

        {infoCompressing ? <InfoFileCompressing /> : null}

        {generatedPhotos &&
          isSelectionConfirmed &&
          ((overviewPhotos && images.length > 10) ||
            (!overviewPhotos && images.length > 5)) && (
            <>
              <h2 className={styles.title}>
                <FormattedMessage {...translations.selectedPhotos} />
              </h2>
              {selectedImages.map(({ src, isUpload, originalIndex }, i) =>
                renderContent(
                  ContentPreviewComponent,
                  {
                    key: src || i,
                    src,
                    progress: isUpload ? uploads[originalIndex].progress : null,
                    value: !isUpload ? descriptions[originalIndex] || '' : '',
                    InputProps: {
                      disabled: isUpload,
                      ...descriptionInputProps,
                    },
                    onPreviewClicked: () => {},
                    onChanged: !isUpload
                      ? (e) => updateDescription(i, e.target.value)
                      : () => {},
                    children: (
                      <button
                        className={styles.imgButton}
                        onClick={() =>
                          (isUpload ? abortUpload : handleRemoveImage)(i)
                        }
                      >
                        <DeleteBinIcon />
                      </button>
                    ),
                  },
                  { originalIndex, isUpload }
                )
              )}
            </>
          )}

        {((generatedPhotos &&
          ((overviewPhotos && images.length <= 10) ||
            (!overviewPhotos && images.length <= 5))) ||
          !generatedPhotos) && (
          <>
            {allImages.map(({ src, isUpload, originalIndex }, i) =>
              renderContent(
                ContentPreviewComponent,
                {
                  key: src || i,
                  classes: { img: tourPhotosContent ? draggableClassName : '' },
                  src,
                  'data-index': i,
                  progress: isUpload ? uploads[originalIndex].progress : null,
                  value: !isUpload ? descriptions[originalIndex] || '' : '',
                  InputProps: {
                    disabled: isUpload,
                    ...descriptionInputProps,
                  },
                  onPreviewClicked: () => handleImageClick(src),

                  onChanged: !isUpload
                    ? (e) => updateDescription(i, e.target.value)
                    : () => {},
                  children: (
                    <button
                      className={styles.imgButton}
                      onClick={() =>
                        (isUpload ? abortUpload : handleRemoveImage)(i)
                      }
                    >
                      <DeleteBinIcon />
                    </button>
                  ),
                },
                { originalIndex, isUpload }
              )
            )}
          </>
        )}
      </DropzonePictures>

      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          handleCloseClick={handleClosePopup}
          title={<FormattedMessage {...translations.imagePreview} />}
          className={styles.popup}
        >
          <div className={styles.textPopupHeader}>
            <div>
              <FormattedMessage {...translations.imagePreview} />
            </div>
            <button
              className={styles.textPopupCloseButton}
              onClick={handleClosePopup}
            >
              ×
            </button>
          </div>
          <img
            src={popupImageSrc}
            alt="Full size"
            className={styles.popupImage}
          />
        </Popup>
      )}

      <Popup
        theme="warning"
        title={<FormattedMessage {...translations.confirmTheChoice} />}
        isOpen={isConfirmPopupOpen}
        handleCloseClick={handleConfirmClose}
        className="GenerateAiTourForm__confirmPopup"
      >
        <div className="GenerateAiTourForm__confirmPopup_content">
          <div className="GenerateAiTourForm__confirmPopup_text">
            <div className="GenerateAiTourForm__confirmPopup_title">
              <FormattedMessage {...translations.confirmTheChoice} />
            </div>
            <div className="GenerateAiTourForm__confirmPopup_subtitle">
              <FormattedMessage {...translations.changesNotSaved} />
            </div>
          </div>
          <div className="GenerateAiTourForm__confirmPopup_footer">
            <Button
              theme="gray-light"
              size="medium"
              onClick={handleCancelClose}
            >
              <FormattedMessage {...translations.cancel} />
            </Button>
            <Button
              className="GenerateAiTourForm__generate-button GenerateAiTourForm__confirmPopup_button"
              size="medium"
              onClick={handleConfirmSelection}
            >
              <FormattedMessage {...translations.confirm} />
            </Button>
          </div>
        </div>
      </Popup>

      <Popup
        theme="warning"
        title={<FormattedMessage {...translations.deletePhoto} />}
        isOpen={isRemovePopupOpen}
        handleCloseClick={() => setIsRemovePopupOpen(false)}
        className="GenerateAiTourForm__confirmPopup"
      >
        <div className="GenerateAiTourForm__confirmPopup_content">
          <div className="GenerateAiTourForm__confirmPopup_text">
            <div className="GenerateAiTourForm__confirmPopup_title">
              <FormattedMessage {...translations.deletePhoto} />
            </div>
            <div className="GenerateAiTourForm__confirmPopup_subtitle">
              <FormattedMessage {...translations.photoRemoved} />
            </div>
          </div>
          <div className="GenerateAiTourForm__confirmPopup_footer">
            <Button
              theme="gray-light"
              size="medium"
              onClick={() => setIsRemovePopupOpen(false)}
            >
              <FormattedMessage {...translations.cancel} />
            </Button>
            <Button
              className="GenerateAiTourForm__generate-button GenerateAiTourForm__confirmPopup_button"
              size="medium"
              onClick={confirmRemoveImage}
            >
              <FormattedMessage {...translations.delete} />
            </Button>
          </div>
        </div>
      </Popup>
    </div>
  );
}
