import React from 'react';
import classNames from 'classnames';
import './GapItem.css';

/**
 * Adds margin gaps between children of the `Gap` component
 * @param {Object} $
 * @param {String} $.className - additional CSS (does not recommended to use, do not add CSS `margin` if this prop in use)
 * @param {React::JSX} $.children - elements in the gapped element
 * @param {...Object} $.divProps - will be passed to root `div` element
 */
export default function GapItem({ className, children, ...divProps }) {
  return (
    <div className={classNames('GapItem', className)} {...divProps}>
      {children}
    </div>
  );
}
