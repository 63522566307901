/**
 * Default data of the event content of type `choice`
 * With this state choice will be hidden
 * @type {Object}
 */
export default {
  options: [],
  answer: -1,
  visible: false,
};
