import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { grey } from '@material-ui/core/colors';
import get from 'lodash/get';
import classNames from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { matchPath, withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CreateIcon from '@material-ui/icons/Create';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import CheckIcon from '@material-ui/icons/Check';
import MapIcon from '@material-ui/icons/Map';
import Fab from '@material-ui/core/Fab';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import getEventInfo from '../../../functions/event/getEventInfo';
import getValidationResults from '../../../functions/validation/getValidationResults';
import { textLimitExceeded } from '../../../functions/validation/rules/rulesName';
import nullAsDefault from '../../../functions/nullAsDefault';
import messagesEventTriggers from '../../../constants/events/intlTriggers';
import messagesEvent from '../../../constants/events/intl';
import ListPoint from '../../ListPoint';
import ListPointLink from '../../ListPoint/withNavLink';
import getEventTrigger from '../../../functions/event/getEventTrigger';
import eventTriggersString from '../../../functions/event/eventTriggersString';
import eventContentString from '../../../functions/event/eventContentString';
import Icon, { CopyIcon } from '../../Icon';
import Popup from '../../Popup';
import Button from '../../Button';
import { performAction } from '../../GenerateAiTourForm/PerformAction';
import { fetchUpdatedQuest } from '../../../sagas/quests';

/**
 * Translations for `SidebarSublistEventsItem` component
 * @type {Object}
 */
const messagesItem = {
  tooltipAdd: (
    <FormattedMessage
      id="SidebarEventsItem.tooltipAdd"
      defaultMessage="Add step"
    />
  ),
};

/**
 * JSS styles for `SidebarSublistEventsItem` elements
 * @type {React::Hook}
 */
const useItemStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '&:hover .SidebarSublistEventsItem__insert': {
      display: 'inline-block',
    },
  },
  listItem: {
    padding: '14px 16px',
    background: theme.palette.background.main,
    '&:not(:hover) .SidebarSublistEventsItem__pencilIcon': {
      display: 'none',
    },
    position: 'relative',
  },
  highlighted: {
    background: theme.palette.primary.background,
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '100%',
    minWidth: '0',
  },
  title: {
    display: 'flex',
  },
  titleText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    alignSelf: 'flex-start',
    width: '16px',
    height: '16px',
  },
  pencilIcon: {
    display: 'flex',
    margin: '0 3px',
    fontSize: '24px',
    '& path': {
      fill: grey[400],
    },
  },
  flagIcon: {
    marginRight: '3px',
    fontSize: '16px',
  },
  add: {
    display: 'none',
    position: 'absolute',
    top: '100%',
    right: '28px',
    borderRadius: '50%',
    background: '#fff',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    zIndex: '1',
    '& path': {
      fill: grey[400],
    },
  },
  eventSyncStatus: {
    background: 'rgba(248, 219, 120, 1)',
    height: '8px',
    width: '8px',
    borderRadius: '50px',
    position: 'absolute',
    top: '50%',
  },
  iconsBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '15px',
    marginBottom: 'auto',
    justifyContent: 'space-between',
  },
}));

const EventSyncStatus = ({ events }) => {
  const [syncStatus, setSyncStatus] = useState([]);
  const styles = useItemStyles();

  useEffect(() => {
    const checkSynchronization = () => {
      const status = events.map((event) => {
        const audioContent = event.content.find(
          (item) => item.contentType === 'audio'
        );
        const htmlContent = event.content.find(
          (item) => item.contentType === 'html'
        );

        if (
          audioContent &&
          htmlContent &&
          audioContent.lastModified &&
          htmlContent.lastModified &&
          (!Array.isArray(audioContent.data) || audioContent.data.length > 0)
        ) {
          const isSynchronized =
            new Date(htmlContent.lastModified).getTime() >
            new Date(audioContent.lastModified).getTime();
          return { eventId: event.id, isSynchronized };
        }
        return { eventId: event.id, isSynchronized: false };
      });

      setSyncStatus(status);
    };

    checkSynchronization();
  }, [events]);

  return (
    <>
      {syncStatus.map((status) =>
        status.isSynchronized ? (
          <div key={status.eventId} className={styles.eventSyncStatus}></div>
        ) : null
      )}
    </>
  );
};

/**
 * Displays event in route list
 * @param {Object} $
 * @param {String} $.title - name of the event
 * @param {Number} $.index - index of the item to display
 * @param {Function?} $.onSelected - item was clicked
 * @param {Function?} $.onAdd - "plus" button was clicked
 * @param {Function?} $.onClicked - event link was clicked
 * @param {Boolean?} $.showAdd - show "plus" button
 * @param {Boolean?} $.showFlag - show "flag" on the start of subtitle
 * @param {Boolean?} $.showOrder - show event order in list before title (`true` by default)
 * @param {Object} $.validation - validation results
 */
function SidebarSublistEventsItem({
  className,
  highlighted,
  questId,
  order,
  title,
  subtitle,
  showAdd,
  showFlag,
  events,
  showOrder = true,
  onAdd = () => {},
  onClicked = () => {},
  validation,
}) {
  const styles = useItemStyles();

  const event = events.find((event) => event.order === order);

  return (
    <div className={styles.root}>
      <ListPointLink
        button
        className={classNames(
          styles.listItem,
          highlighted && styles.highlighted,
          className
        )}
        to={`/quest/${questId}/events/${order}/`}
        onClick={onClicked}
      >
        <div className={styles.text}>
          <Typography className={styles.title} variant="body1">
            <span className={styles.titleText}>
              {`${showOrder ? `${order + 1}. ` : ''}${title}`}
              &nbsp;
            </span>
            <CreateIcon
              className={classNames(
                'SidebarSublistEventsItem__pencilIcon',
                styles.pencilIcon
              )}
            />
          </Typography>
          <Typography
            className={styles.subtitle}
            variant="body2"
            color="textSecondary"
          >
            {showFlag ? <EmojiFlagsIcon className={styles.flagIcon} /> : null}
            {subtitle}
          </Typography>
        </div>
        <div className={styles.iconsBlock}>
          {validation
            ? validation.isEventValid && (
                <CheckIcon className={styles.icon} color="primary" />
              )
            : null}
          {event && <EventSyncStatus events={[event]} />}{' '}
        </div>
      </ListPointLink>
      {showAdd ? (
        <Tooltip arrow title={messagesItem.tooltipAdd} placement="right">
          <AddCircleIcon
            className={`SidebarSublistEventsItem__insert ${styles.add}`}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onAdd(order + 1);
            }}
          />
        </Tooltip>
      ) : null}
    </div>
  );
}

/**
 * Messages for `SidebarSublistEvents` component
 * @type {Object}
 */
const messages = {
  new: messagesEvent.new,
  ...defineMessages({
    map: { id: 'SidebarEvents.map', defaultMessage: 'Route creation' },
    add: { id: 'SidebarEvents.addEvent', defaultMessage: 'Add step' },
    importStep: {
      id: 'SidebarEvents.importStep',
      defaultMessage: 'Import Step',
    },
    stepNumber: {
      id: 'SidebarEvents.stepNumber',
      defaultMessage: 'Step Number',
    },
    import: { id: 'SidebarEvents.import', defaultMessage: 'Import' },
    tourId: { id: 'SidebarEvents.tourId', defaultMessage: 'Tour ID' },
    errorText: {
      id: 'SidebarEvents.errorText',
      defaultMessage: 'Enter a step between Intro and Outro.',
    },
    helperText: {
      id: 'SidebarEvents.helperText',
      defaultMessage: 'Enter the correct value.',
    },
  }),
};

/**
 * JSS styles for `SidebarSublistEvents` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  highlighted: {
    background: theme.palette.primary.background,
  },
  addEvent: {
    background: theme.palette.primary.background,
    color: theme.palette.primary.main,
    fontSize: '14px',
    letterSpacing: '0.4px',
    boxShadow: 'unset',
    '&:active': {
      boxShadow: 'unset',
    },
  },
  iconPoint: {
    minWidth: '36px',
  },
  addEventIcon: {
    marginRight: '8px',
  },
  itemDragging: {
    boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.1)',
  },
  popup: {
    borderRadius: '32px',
    padding: '24px',
    gap: '12px',
    maxWidth: '430px',
    maxHeight: '254px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  textPopupHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '21.09px',
    marginBottom: '10px',
  },
  textPopupCloseButton: {
    background: 'var(--tone-200)',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 1,
    color: 'var(--tone-500)',
    position: 'relative',
    '&:hover': {
      background: 'var(--tone-400)',
    },
  },
  inputsBlock: {
    position: 'relative',
    width: '100%',
  },
  inputContainer: {
    position: 'relative',
    marginBottom: '16px',
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  input: {
    borderRadius: '8px',
    padding: '12px 12px 12px 12px',
    width: '100%',
    fontFamily: 'Roboto',
    height: '40px',
    border: '1px solid var(--tone-300)',
    color: 'var(--tone-400)',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '18px',
    '&:focus': {
      border: '1px solid var(--tone-900)',
      outline: 'none',
    },
  },
  activeInput: {
    border: '1px solid var(--tone-900)',
    fontSize: '16px',
    fontWeight: 400,
    color: 'var(--tone-900)',
    '& ~ $label': {
      top: '-10px',
      padding: '0 1px',
      left: '10px',
      height: 'auto',
      backgroundColor: 'var(--white)',
      fontSize: '12px',
      color: 'var(--tone-400)',
    },
  },
  label: {
    position: 'absolute',
    top: '11px',
    transition: 'all 0.3s ease',
    pointerEvents: 'none',
    color: 'var(--tone-400)',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '18px',
    paddingLeft: '12px',
    fontFamily: 'Roboto',
  },
  textPopupFooter: {
    display: 'flex',
    justifyContent: 'end',
  },
  generateButton: {
    background: 'var(--tone-700)',
    border: 0,
    color: 'var(--white)',
    height: '40px',
    width: '100%',
    borderRadius: '8px',
    transition: 'background-color 0.3s ease, color 0.3s ease',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '18.75px',
    '&:disabled': {
      backgroundColor: 'var(--tone-300)',
      color: 'var(--tone-400)',
    },
  },
  errorText: {
    color: 'var(--red)',
    fontSize: '12px',
    marginTop: '-15px',
  },
}));

/**
 * Forms message string with amounts and types of event content
 * @param {Object} event - event description from redux store containing content
 * @param {Array[RoutePoint]} $.route - route points quest to which event related to
 * @param {Object} $.validationResults - results of the quest validation from `validation::getValidationResults()`
 * @param {Function} $.formatMessage - `formatMessage` from `react-intl`
 * @returns {String} - translated string
 */
function getEventSubtitle(event, route, validationResults, formatMessage) {
  const eventInfo = getEventInfo(['image', 'audio', 'text'], event);
  validationResults.eventErrors.forEach((e) => {
    if (e.title === textLimitExceeded) {
      eventInfo.text = 0;
      eventInfo.html = 0;
    }
  });

  return [
    event.order === 0
      ? [formatMessage(messagesEventTriggers.automatically)]
      : eventTriggersString(getEventTrigger(event, route), formatMessage),
    eventContentString(eventInfo, formatMessage),
  ]
    .map((c) => c.join(', '))
    .filter((c) => c)
    .join(' • ');
}

/**
 * Sublist showing available events
 * With ability to drag them
 * @param {Object} $
 * @param {String} $.questId - id of the current loaded quest
 * @param {String} $.itemClassName - CSS class for list item
 * @param {Array[RoutePoint]} $.route - quest route points
 * @param {Array[Object]} $.events - events from current quest
 * @param {Object} $.questActions - store actions for operating with quests
 * @param {Function} $.questActions.reorderEvents - reorders events
 * @param {Object} $.eventActions - store actions for operating with events
 * @param {Function} $.eventActions.addEvent - event adding store action
 * @param {Function?} $.onClicked - link to event or to map was clicked
 * @param {Boolean} $.shown - if list is visible
 */
function SidebarSublistEvents({
  questId,
  itemClassName,
  route = [],
  events = [],
  shown,
  eventActions = {},
  questActions = {},
  onClicked = () => {},
  intl: { formatMessage },
  history,
  user,
  currentQuest,
  fetchUpdatedQuest,
}) {
  const styles = useStyles();
  const match = matchPath(history.location.pathname, {
    path: '/quest/:questId/events/:eventOrder/:anchor?',
    exact: true,
    strict: false,
  });
  const matchMap = matchPath(history.location.pathname, {
    path: '/quest/:questId/map',
    exact: true,
    strict: false,
  });
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [tourId, setTourId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [stepNumber, setStepNumber] = useState('');
  const [activeFields, setActiveFields] = useState({
    tourId: false,
    stepNumber: false,
  });
  const hasTourSupplyManagerGroup = user.groups.includes('Tour Supply Manager');

  const unsafeEventOrder = get(match, 'params.eventOrder', null);
  const shownEventOrder = parseInt(unsafeEventOrder, 10);

  const startEvent = events.length > 0 ? events[0] : null;
  const finishEvent = events.length > 1 ? events[events.length - 1] : null;
  const flexibleEvents = events.slice(1, events.length - 1);

  const onAddEventClicked = (order) => {
    eventActions.addEvent({
      questId: parseInt(questId, 10),
      title: formatMessage(messages.new),
      onSyncStart: () => history.push(`/quest/${questId}/events/${order}/`),
      order,
    });
  };

  const onEventDropped = (result) => {
    const {
      draggableId: eventId,
      source: { index: fromIndex } = {},
      destination: { index: toIndex = null } = {},
    } = nullAsDefault(result);

    if (toIndex == null) {
      return;
    }

    questActions.reorderEvents({
      ids: { questId: parseInt(questId, 10), eventId },
      fromIndex: fromIndex + 1,
      toIndex: toIndex + 1,
      onSyncStart: () => {
        if (fromIndex + 1 === shownEventOrder) {
          history.push(`/quest/${questId}/events/${toIndex + 1}/`);
        }

        if (![fromIndex, toIndex].every((i) => i + 1 < shownEventOrder)) {
          if (fromIndex + 1 <= shownEventOrder) {
            history.push(`/quest/${questId}/events/${shownEventOrder - 1}/`);
          } else if (toIndex + 1 <= shownEventOrder) {
            history.push(`/quest/${questId}/events/${shownEventOrder + 1}/`);
          }
        }
      },
    });
  };

  const validationResults = events.map((e, i) =>
    getValidationResults(e, events[i + 1] || null)
  );

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
    setTourId('');
    setStepNumber('');
    setErrorMessage('');
  };

  const handleTourIdChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setTourId(value);
    }
  };
  const handleFocus = (field) => {
    setActiveFields((prevState) => ({ ...prevState, [field]: true }));
  };

  const handleBlur = (field) => {
    setActiveFields((prevState) => ({ ...prevState, [field]: false }));
  };

  const isActive = (field) =>
    activeFields[field] || !!(field === 'tourId' ? tourId : stepNumber);

  const handleStepNumberChange = (event) => {
    let value = event.target.value;
    value = value.replace(/[^0-9,]|(,){2,}/g, '');

    if (!/^(\d+,?)+$/.test(value)) {
      setErrorMessage(formatMessage(messages.helperText));
    } else {
      const steps = value.split(',').map((step) => parseInt(step, 10));
      if (steps.includes(1)) {
        setErrorMessage(formatMessage(messages.errorText));
      } else {
        setErrorMessage('');
      }
    }
    setStepNumber(value);
  };

  const handleActionClick = async (actionType, options = {}) => {
    if (stepNumber) {
      options.ordersSteps = stepNumber
        .split(',')
        .map((step) => parseInt(step, 10) - 1)
        .filter((num) => !isNaN(num));
    }

    const result = await performAction(currentQuest, actionType, options);
    if (result && result !== 0) {
      fetchUpdatedQuest(currentQuest.id);
    }

    setPopupOpen(false);
    setTourId('');
    setStepNumber('');
    setErrorMessage('');
  };

  return (
    <Collapse unmountOnExit in={shown} timeout="auto">
      <List disablePadding component="div">
        <ListPointLink
          button
          to={`/quest/${questId}/map`}
          className={classNames(itemClassName, matchMap && styles.highlighted)}
          classes={{ icon: styles.iconPoint }}
          primary={formatMessage(messages.map)}
          icon={MapIcon}
          onClick={onClicked}
        />
        {startEvent ? (
          <SidebarSublistEventsItem
            events={events}
            showFlag
            showAdd
            className={itemClassName}
            highlighted={shownEventOrder === 0}
            questId={questId}
            order={startEvent.order}
            title={startEvent.title}
            subtitle={getEventSubtitle(
              startEvent,
              route,
              validationResults[0],
              formatMessage
            )}
            validation={validationResults[0]}
            onAdd={onAddEventClicked}
            onClicked={onClicked}
          />
        ) : null}
        <DragDropContext onDragEnd={onEventDropped}>
          <Droppable droppableId="eventsList">
            {(provided) => (
              <div ref={provided.innerRef}>
                {flexibleEvents.map((event, i) => (
                  <Fragment key={event.id}>
                    <Draggable draggableId={event.id}>
                      {(provided, snapshot) => (
                        <Fragment>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={
                              snapshot.isDragging ? styles.itemDragging : ''
                            }
                            style={provided.draggableStyle}
                          >
                            <SidebarSublistEventsItem
                              events={events}
                              className={itemClassName}
                              highlighted={shownEventOrder === event.order}
                              questId={questId}
                              order={event.order}
                              title={event.title}
                              subtitle={getEventSubtitle(
                                event,
                                route,
                                validationResults[i + 1],
                                formatMessage
                              )}
                              validation={validationResults[i + 1]}
                              showAdd={i + 1 !== flexibleEvents.length}
                              onClicked={onClicked}
                              onAdd={onAddEventClicked}
                            />
                          </div>
                          {provided.placeholder}
                        </Fragment>
                      )}
                    </Draggable>
                  </Fragment>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <ListPoint className={itemClassName}>
          <Fab
            className={styles.addEvent}
            size="small"
            variant="extended"
            onClick={() => onAddEventClicked(events.length - 1)}
          >
            <AddCircleIcon className={styles.addEventIcon} color="primary" />
            {formatMessage(messages.add)}
          </Fab>
        </ListPoint>
        {hasTourSupplyManagerGroup && (
          <ListPoint className={itemClassName}>
            <Fab
              className={styles.addEvent}
              size="small"
              variant="extended"
              onClick={handleOpenPopup}
            >
              <CopyIcon className={styles.addEventIcon} />
              {formatMessage(messages.importStep)}
            </Fab>
          </ListPoint>
        )}
        {finishEvent ? (
          <SidebarSublistEventsItem
            events={events}
            showFlag
            className={itemClassName}
            highlighted={shownEventOrder === finishEvent.order}
            questId={questId}
            order={finishEvent.order}
            title={finishEvent.title}
            validation={validationResults[events.length - 1]}
            subtitle={getEventSubtitle(
              finishEvent,
              route,
              validationResults[events.length - 1],
              formatMessage
            )}
            onClicked={onClicked}
          />
        ) : null}
      </List>
      <Popup
        isOpen={isPopupOpen}
        handleCloseClick={handleClosePopup}
        title={formatMessage(messages.importStep)}
        className={styles.popup}
      >
        <div className={styles.textPopupHeader}>
          <div>{formatMessage(messages.importStep)}</div>
          <button
            className={styles.textPopupCloseButton}
            onClick={handleClosePopup}
          >
            ×
          </button>
        </div>
        <div className={styles.inputsBlock}>
          <div className={styles.inputContainer}>
            <input
              id="tourId"
              value={tourId}
              onChange={handleTourIdChange}
              onFocus={() => handleFocus('tourId')}
              onBlur={() => handleBlur('tourId')}
              className={classNames(styles.input, {
                [styles.activeInput]: isActive('tourId'),
              })}
            />
            <label htmlFor="tourId" className={styles.label}>
              {formatMessage(messages.tourId)}
            </label>
          </div>
          <div className={styles.inputContainer}>
            <input
              id="stepNumber"
              value={stepNumber}
              onChange={handleStepNumberChange}
              onFocus={() => handleFocus('stepNumber')}
              onBlur={() => handleBlur('stepNumber')}
              className={classNames(styles.input, {
                [styles.activeInput]: isActive('stepNumber'),
              })}
            />
            <label htmlFor="stepNumber" className={styles.label}>
              {formatMessage(messages.stepNumber)}
            </label>
          </div>
          {errorMessage && (
            <div className={styles.errorText}>{errorMessage}</div>
          )}
        </div>
        <div className={styles.textPopupFooter}>
          <Button
            size="medium"
            className={styles.generateButton}
            disabled={!(stepNumber && tourId) || !!errorMessage}
            onClick={() =>
              handleActionClick('moveSteps', {
                sourceQuestId: tourId,
              })
            }
          >
            {formatMessage(messages.import)}
          </Button>
        </div>
      </Popup>
    </Collapse>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchUpdatedQuest: (id) => dispatch(fetchUpdatedQuest(id)),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(injectIntl(SidebarSublistEvents)));
