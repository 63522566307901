import React, { useState } from 'react';
import { Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Link, Route, Switch } from 'react-router-dom';
import ProfileContent from '../ProfileContent';
import { getCurrencySymbol } from '../../constants/Currencies';
import HelmetTitle from '../HelmetTitle';
// eslint-disable-next-line import/no-cycle
import ReportPayouts from './ReportPayouts';
import ReportViews from './ReportViews';
// eslint-disable-next-line import/no-cycle
import ReportSales from './ReportSales';

const useStyles = makeStyles((theme) => ({
  h5: {
    marginBottom: 16,
  },
  tabs: {
    [theme.breakpoints.down('md')]: {
      '& .MuiTabs-flexContainer': {
        display: 'flex',
        flexWrap: 'wrap',
      },
      '& .MuiTabs-scroller': {
        position: 'unset',
      },
    },
  },

  statsContainer: {
    marginTop: 16,
  },
  content: {
    padding: '30px 30px',
    height: '100%',
    backgroundColor: 'white',
    position: 'relative',
    marginLeft: '0.2%',
  },
}));

export const messages = defineMessages({
  reportsTitle: {
    id: 'ProfileReports.reportsTitle',
    defaultMessage: 'Reports',
  },
});

const ProfileReports = ({
  partner: {
    paymentStats,
    currencyCode: partnerCurrencyCode,
    totalEarned,
    isFetching: isPartnerFetching,
  },
  stats,
  intl: { formatMessage },
  referal: { widgetStats, errors: referralErrors },
  referalActions,
  statsActions: { fetchPayouts },
  partnerActions: { fetchPaymentsStats },
  showNetworkError,
  user: { locale, isPartner, currencyCode: userCurrencyCode },
  userActions,
}) => {
  const classes = useStyles();
  const currencyCode = partnerCurrencyCode || userCurrencyCode;
  const currencySymbol = getCurrencySymbol(currencyCode);

  const [isSummaryAvailable, setIsSummaryAvailable] = useState(false);
  const [totalToPayout, setTotalToPayout] = useState('');

  return (
    <ProfileContent contentClassName={classes.content}>
      <HelmetTitle message={messages.reportsTitle} />
      <Typography className={classes.h5} variant="h5">
        <FormattedMessage
          id="ProfileReports.reports"
          defaultMessage="Reports"
        />
      </Typography>
      <Tabs
        className={classes.tabs}
        value={window.location.pathname}
        indicatorColor="primary"
        textColor="primary"
      >
        {/* <Tab
          disabled
          value="/my/reports/summary"
          label={
            <FormattedMessage
              id="ProfileReports.summary"
              defaultMessage="Summary"
            />
          }
          to="/reports/summary"
          component={Link}
        /> */}
        <Tab
          value="/my/reports/sales"
          label={
            <FormattedMessage
              id="ProfileReports.sales"
              defaultMessage="Sales"
            />
          }
          to="/reports/sales"
          component={Link}
        />
        {/* <Tab
          disabled
          value="/my/reports/downloads"
          label={
            <FormattedMessage
              id="ProfileReports.installations"
              defaultMessage="Downloads"
            />
          }
          to="/reports/downloads"
          component={Link}
        /> */}
        <Tab
          value="/my/reports/payouts"
          label={
            <FormattedMessage
              id="ProfileReports.payouts"
              defaultMessage="Payouts"
            />
          }
          to="/reports/payouts"
          component={Link}
        />
        <Tab
          value="/my/reports/views"
          label={
            <FormattedMessage
              id="ProfileReports.views"
              defaultMessage="Views"
            />
          }
          to="/reports/views"
          component={Link}
        />
      </Tabs>
      <Switch>
        <Route
          path="/reports/sales"
          render={() => (
            <ReportSales
              paymentStats={paymentStats}
              currencySymbol={currencySymbol}
              currencyCode={currencyCode}
              isFetching={isPartnerFetching}
              isPartner={isPartner}
              total={totalEarned}
              fetchPayments={fetchPaymentsStats}
              showNetworkError={showNetworkError}
            />
          )}
        />
        <Route
          path="/reports/views"
          render={() => (
            <ReportViews
              locale={locale}
              widgetStats={widgetStats}
              referalActions={referalActions}
              referralErrors={referralErrors}
            />
          )}
        />
        <Route
          path="/reports/payouts"
          render={() => (
            <ReportPayouts
              stats={stats}
              formatMessage={formatMessage}
              currencySymbol={currencySymbol}
              currencyCode={currencyCode}
              fetchPayouts={fetchPayouts}
              userActions={userActions}
              showNetworkError={showNetworkError}
              isSummaryAvailable={isSummaryAvailable}
              setIsSummaryAvailable={setIsSummaryAvailable}
              totalToPayout={totalToPayout}
              setTotalToPayout={setTotalToPayout}
            />
          )}
        />
      </Switch>
    </ProfileContent>
  );
};

export default ProfileReports;
