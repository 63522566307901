import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  FormattedMessage,
  intlShape,
  injectIntl,
  defineMessages,
} from 'react-intl';
import Button from '../Button';
import InputWithButtons from '../InputWithButtons';
import QuestDuplicate from '../QuestDuplicate';
import './NotEditableNotification.css';
import { QuestType } from '../../constants/PropTypes';

const messages = defineMessages({
  defaultTitle: {
    id: 'NotEditableNotification.defaultQuestTitle',
    defaultMessage: 'Copy of {title}',
  },
});

class NotEditableNotification extends Component {
  copyTitle(title) {
    const { formatMessage } = this.props.intl;
    return formatMessage(messages.defaultTitle, { title });
  }

  render() {
    const {
      quest,
      quest: { isEditable },
      className,
    } = this.props;

    if (isEditable) {
      return null;
    }

    return (
      <div
        className={classNames('NotEditableNotification__wrapper', className)}
      >
        <div className="NotEditableNotification__text">
          <FormattedMessage
            id="NotEditableNotification.text"
            defaultMessage="You published the tour and it was already downloaded. Now the tour can not be edited.{tag} To make changes create a copy of the tour and publish it again."
            values={{
              tag: <br />,
            }}
          />
        </div>
        <div className="NotEditableNotification__row">
          <InputWithButtons
            className="NotEditableNotification__input"
            placeholder={this.copyTitle(quest.title)}
            defaultValue={this.copyTitle(quest.title)}
            onRef={(input) => (this._input = input)}
          />
          <QuestDuplicate
            quest={quest}
            render={({ onCopy, isCopying }) => (
              <Button
                theme="filled"
                className={classNames(isCopying && 'button--fetching')}
                disabled={isCopying}
                onClick={() => {
                  const title = this._input.value;
                  if (!title) {
                    this._input.focus();
                    return;
                  }
                  onCopy(title);
                }}
              >
                {isCopying ? (
                  <FormattedMessage
                    id="NotEditableNotification.copying"
                    defaultMessage="Copying..."
                  />
                ) : (
                  <FormattedMessage
                    id="NotEditableNotification.addCopyOfQuest"
                    defaultMessage="Create copy of the tour"
                  />
                )}
              </Button>
            )}
          />
        </div>
      </div>
    );
  }
}

NotEditableNotification.propTypes = {
  quest: QuestType.isRequired,
  onCopy: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(NotEditableNotification);
