/**
 * Key of the URL query string param, containing route point `guid`
 * @type {String}
 */
export const POINT_ID = 'point_id';

/**
 * Key of the URL query string param, containing event id
 * @type {String}
 */
export const EVENT_ID = 'event_id';
