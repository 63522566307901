import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import composeMaterialTheme from './functions/materialTheme';
import App from './components/App';
import configureStore from './store';

class Root extends Component {
  constructor(props) {
    super(props);
    this.store = configureStore(props.history);
  }

  render() {
    return (
      <Provider store={this.store}>
        <ConnectedRouter history={this.props.history}>
          <ThemeProvider theme={composeMaterialTheme()}>
            <App />
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default Root;
