import { takeEvery, put, call, select, all, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as types from '../constants/ActionTypes';
import Api from '../functions/Api';
import {
  YA_METRIC_STORE_ID,
  YA_METRIC_WIDGET_ID,
  YA_METRIC_SHOWS_FILTER,
  YA_METRIC_VIEWS_FILTER,
  YA_METRIC_CLICKS_FILTER,
} from '../constants/branding';
import fetchMetric from '../functions/YAMetricHelpers/fetchMetric';
import { metricAdapter } from '../functions/YAMetricHelpers/metricAdapter';
import { mergeStats } from '../functions/YAMetricHelpers/metricMerger';

export function* requestReferalData(action) {
  try {
    const { data } = yield call(Api.get, 'referal/data/');

    yield put({
      type: types.RECEIVE_REFERAL_DATA,
      data,
    });
  } catch (error) {
    yield put({
      type: types.NOT_RECEIVE_REFERAL_DATA,
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* requestReferalSales(action) {
  try {
    const { data } = yield call(Api.get, 'referal/stats/');

    yield put({
      type: types.RECEIVE_REFERAL_SALES,
      ...data,
    });
  } catch (error) {
    yield put({
      type: types.NOT_RECEIVE_REFERAL_SALES,
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* requestCities(action) {
  try {
    const { data } = yield call(Api.get, 'cities/');

    yield put({
      type: types.RECEIVE_CITIES,
      data,
    });
  } catch (error) {
    yield put({
      type: types.NOT_RECEIVE_CITIES,
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* requestCollections(action) {
  try {
    const citySlug = action.city;
    const { data } = yield call(Api.get, `collections/?city=${citySlug}`);

    yield put({
      type: types.RECEIVE_COLLECTIONS,
      data,
    });
  } catch (error) {
    yield put({
      type: types.NOT_RECEIVE_COLLECTIONS,
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

function* getReferralId() {
  return yield select((state) => state.referal.id);
}

function* fetchWidgetStats(payload) {
  // If referral data doesn't fetch yet, saga will wait
  let referralId = yield call(getReferralId);
  if (!referralId) {
    yield take(types.RECEIVE_REFERAL_DATA);
    referralId = yield call(getReferralId);
  }

  const { startTs, endTs } = payload;

  try {
    // Fetch widget stats form YA metric and surprise me
    const [showsStats, viewsStats, clickStats, paymentsStats] = yield all([
      call(fetchMetric, {
        metricId: YA_METRIC_WIDGET_ID,
        filter: YA_METRIC_SHOWS_FILTER(referralId),
        startTs,
        endTs,
      }),
      call(fetchMetric, {
        metricId: YA_METRIC_WIDGET_ID,
        filter: YA_METRIC_VIEWS_FILTER(referralId),
        startTs,
        endTs,
      }),
      call(fetchMetric, {
        metricId: YA_METRIC_STORE_ID,
        filter: YA_METRIC_CLICKS_FILTER(referralId),
        startTs,
        endTs,
      }),
      call(
        Api.get,
        `referal/widget_stats/?start_ts=${startTs}&end_ts=${endTs}`
      ),
    ]);

    // Adapted YA metric stats data to the pretty view
    const showsStatsAdapted = metricAdapter('shows', showsStats);
    const viewsStatsAdapted = metricAdapter('views', viewsStats);
    const clicksStatsAdapted = metricAdapter('clicks', clickStats);

    // Merge stats
    const widgetStats = mergeStats([
      showsStatsAdapted,
      viewsStatsAdapted,
      clicksStatsAdapted,
      paymentsStats,
    ]);

    yield put({
      type: types.FETCH_WIDGET_STATS_SUCCESS,
      ...widgetStats,
    });
  } catch (error) {
    yield put({
      type: types.FETCH_WIDGET_STATS_FAILURE,
      error: 'fetchingWidgetStatsError',
    });
    yield put({ type: types.NETWORK_ERROR, error });
    yield call(delay, 10000);
    yield put({
      type: types.RESET_REFERAL_ERRORS,
    });
  }
}

export default function* watchReferal() {
  yield takeEvery(types.REQUEST_REFERAL_DATA, requestReferalData);
  yield takeEvery(types.REQUEST_REFERAL_SALES, requestReferalSales);
  yield takeEvery(types.REQUEST_CITIES, requestCities);
  yield takeEvery(types.REQUEST_COLLECTIONS, requestCollections);
  yield takeEvery(types.FETCH_WIDGET_STATS, fetchWidgetStats);
}
