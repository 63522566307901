import React, { useState, useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { DEFAULT_COORDS } from '../../constants/branding';
import RoutePage from '../RoutePage';
import { useIpCoords } from '../../functions/geocoding/useLocation';
import { convertToHoursAndMinutes } from '../../functions/tourDuration/convertTime';
import getUniquePricingCategoriesLabels from '../../functions/prices/getUniquePricingCategoriesLabels';
import { round10 } from '../../functions/round10';
import {
  ALL_CATEGORY_TICKET_LABELS,
  DEFAULT_PRICING_CATEGORY_ID,
} from '../../constants/prices';
import {
  getAllPricingCategories,
  getPriceBlockCategorySum,
  getFinalCategories,
} from '../../functions/prices';

import TourTitle from './TourTitle';
import TourDescription from './TourDescription';
import TourCheck from './TourCheck';
import TourLocations from './TourLocations';
import TourDetails from './TourDetails';
import TourHighlights from './TourHighlights';
import TourPhotos from './TourPhotos';
import TourAudience from './TourAudience';
import TourPrice from './TourPrice';
import TourPublish from './TourPublish';

const TourContainer = ({
  currentQuest,
  currentQuest: { products },
  publishing: {
    receivedPublishData,
    receivedPublishData: { defaultCurrency },
    priceLevels,
    attractions,
    isSaved,
    isSaving,
  },
  checkFullness,
  locale,
  publishingActions,
  prices,
  setPrices,
}) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [distance, setDistance] = useState('');
  const [durationMin, setDurationMin] = useState('');
  const [durationMax, setDurationMax] = useState('');
  const [address, setAddress] = useState('');
  const [finishAddress, setFinishAddress] = useState('');
  const [startLocation, setStartLocation] = useState('');
  const [finishLocation, setFinishLocation] = useState('');
  const { lat = DEFAULT_COORDS.lat, lon = DEFAULT_COORDS.lon } = useIpCoords();
  const [ticketSites, setTicketSites] = useState({});
  const [pricingCategoriesLabels, setPricingCategoriesLabels] = useState({});
  const [finalPrices, setFinalPrices] = useState({});
  const uniquePricingCategoriesLabels = getUniquePricingCategoriesLabels(
    attractions
  );
  const finalCategories = getFinalCategories(uniquePricingCategoriesLabels);
  const allPricingCategories = getAllPricingCategories(attractions);

  useEffect(() => {
    if (products[0].id === receivedPublishData.id) {
      setTitle(receivedPublishData.title);
      setDescription(receivedPublishData.description);
      setDistance(receivedPublishData.distance);
      setDurationMin(convertToHoursAndMinutes(receivedPublishData.durationMin));
      setDurationMax(convertToHoursAndMinutes(receivedPublishData.durationMax));
      setStartLocation(receivedPublishData.startLocation);
      setFinishLocation(receivedPublishData.finishLocation);
    }
    setTicketSites({
      ...ticketSites,
      ...Object.fromEntries(
        attractions
          .map((a) => a.tickets.map((t) => [t.id, t.checkoutUrl]))
          .flat()
      ),
    });
    setPricingCategoriesLabels({
      ...Object.fromEntries(
        attractions
          .map((a) =>
            a.tickets.map((t) =>
              t.pricingCategories.map((pc) => [pc.id, pc.label])
            )
          )
          .flat(2)
      ),
    });
    setPrices({
      ...Object.fromEntries(priceLevels.map((pl) => [pl.label, pl.price])),
      ...Object.fromEntries(
        attractions
          .map((a) =>
            a.tickets.map((t) =>
              t.pricingCategories.map((pc) => [
                pc.pricingCategoryId,
                pc.totalRetailPrice,
              ])
            )
          )
          .flat(2)
      ),
    });
  }, [receivedPublishData.id]);

  useEffect(() => {
    if (products[0].id === receivedPublishData.id) {
      setPrices({
        ...Object.fromEntries(priceLevels.map((pl) => [pl.label, pl.price])),
        ...Object.fromEntries(
          attractions
            .map((a) =>
              a.tickets.map((t) =>
                t.pricingCategories.map((pc) => [
                  pc.pricingCategoryId,
                  pc.totalRetailPrice,
                ])
              )
            )
            .flat(2)
        ),
      });
    }
  }, [
    priceLevels.length,
    getAllPricingCategories(attractions).length,
    receivedPublishData.defaultCurrency.id,
  ]);

  useEffect(() => {
    if (products[0].id === receivedPublishData.id) {
      setPricingCategoriesLabels({
        ...Object.fromEntries(
          attractions
            .map((a) =>
              a.tickets.map((t) =>
                t.pricingCategories.map((pc) => [pc.id, pc.label])
              )
            )
            .flat(2)
        ),
      });
    }
  }, [allPricingCategories.length]);

  // For updating prices without force FLUX on current field
  useEffect(() => {
    setFinalPrices({
      ...finalPrices,
      ...Object.fromEntries(
        uniquePricingCategoriesLabels.map((pcl) => [
          pcl,
          round10(
            Number(
              getPriceBlockCategorySum(
                defaultCurrency,
                pcl,
                attractions,
                uniquePricingCategoriesLabels,
                uniquePricingCategoriesLabels.some((upcl) =>
                  ALL_CATEGORY_TICKET_LABELS.includes(upcl)
                ) && uniquePricingCategoriesLabels.length !== 1
              )
            ) + (prices[pcl] ? Number(prices[pcl]) : 0)
          ),
        ])
      ),
    });
  }, [JSON.stringify(prices), isSaved, isSaving]);

  return (
    <React.Fragment>
      <Route
        path="/quest/:questId/title/"
        render={() => (
          <TourTitle
            title={title}
            setTitle={setTitle}
            checkFullness={checkFullness}
            currentQuest={currentQuest}
          />
        )}
      />
      <Route
        path="/quest/:questId/description/"
        render={() => (
          <TourDescription
            description={description}
            setDescription={setDescription}
            checkFullness={checkFullness}
            currentQuest={currentQuest}
          />
        )}
      />
      <Route
        path="/quest/:questId/locations/"
        render={() => (
          <TourLocations
            distance={distance}
            setDistance={setDistance}
            durationMin={durationMin}
            setDurationMin={setDurationMin}
            durationMax={durationMax}
            setDurationMax={setDurationMax}
            address={address}
            setAddress={setAddress}
            finishAddress={finishAddress}
            setFinishAddress={setFinishAddress}
            startLocation={startLocation}
            setStartLocation={setStartLocation}
            finishLocation={finishLocation}
            setFinishLocation={setFinishLocation}
            lat={lat}
            lon={lon}
            checkFullness={checkFullness}
            currentQuest={currentQuest}
          />
        )}
      />
      <Route
        path="/quest/:questId/details/"
        render={() => (
          <TourDetails
            checkFullness={checkFullness}
            currentQuest={currentQuest}
          />
        )}
      />
      <Route
        path="/quest/:questId/highlights/"
        render={() => (
          <TourHighlights
            checkFullness={checkFullness}
            currentQuest={currentQuest}
          />
        )}
      />
      <Route
        path="/quest/:questId/photos/"
        render={() => (
          <TourPhotos
            checkFullness={checkFullness}
            currentQuest={currentQuest}
          />
        )}
      />
      <Route
        path="/quest/:questId/audience/"
        render={() => (
          <TourAudience
            checkFullness={checkFullness}
            currentQuest={currentQuest}
          />
        )}
      />
      <Route
        path="/quest/:questId/price/"
        render={() => (
          <TourPrice
            checkFullness={checkFullness}
            currentQuest={currentQuest}
            ticketSites={ticketSites}
            setTicketSites={setTicketSites}
            pricingCategoriesLabels={pricingCategoriesLabels}
            setPricingCategoriesLabels={setPricingCategoriesLabels}
            prices={prices}
            setPrices={setPrices}
            finalPrices={finalPrices}
            setFinalPrices={setFinalPrices}
            uniquePricingCategoriesLabels={uniquePricingCategoriesLabels}
            publishingActions={publishingActions}
          />
        )}
      />
      <Route
        path="/quest/:questId/check/"
        render={() => (
          <TourCheck
            prices={prices}
            checkFullness={checkFullness}
            currentQuest={currentQuest}
            finalPrices={finalPrices}
            uniquePricingCategoriesLabels={uniquePricingCategoriesLabels}
          />
        )}
      />
      <Route path="/quest/:questId/publish/" component={TourPublish} />
      <Route
        path="/quest/:questId/map/"
        render={({ match: { params } }) => (
          <RoutePage locale={locale} questId={params.questId} />
        )}
      />
    </React.Fragment>
  );
};

export default withRouter(TourContainer);
