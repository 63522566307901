/**
 * Descriptions of available language
 * @type {Array[Object]}
 */
export const languages = [
  { code: 'en', name: 'English' },
  { code: 'ru', name: 'Russian' },
  { code: 'de', name: 'German' },
  // { code: "it", name: "Italiano" },
  // { code: "fr", name: "Français" },
  // { code: "nl", name: "Nederlands" },
  // { code: "es", name: "Español" },
  // { code: "pl", name: "Polski" },
];

/**
 * Languages, available for selecting by user
 * @type {Array[Object]}
 */
export const availableLangs = ['en', 'ru'].map((code) =>
  languages.find((lang) => lang.code === code)
);

/**
 * Codes of available languages
 * @type {Array[String]}
 */
const codes = languages.map(({ code }) => code);

/**
 * Gets current language code from link
 * @param {String} url - current link
 * @returns {String} - current used language code
 */
export function currentLanguage(url) {
  if (!url) return 'en';

  const parsed = url.split('/');

  if (codes.indexOf(parsed[3]) === -1) {
    return 'en';
  }

  return parsed[3];
}
