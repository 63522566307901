export const STORAGE_URL = '';
export const APP_BASE = '/my';
export const API_URL = '/api';
export const DOMAIN_URL = `${
  window.location.origin || 'https://wegotrip.com'
}/`;
export const HELP_CENTER_URL = 'https://help.wegotrip.com/';
// DEPRECATED. TODO: remove
export const LANDING_ASSETS_URL = 'https://app.surprizeme.ru/static/';

export const TEXT_TO_SPEACH_GOOGLE =
  'https://cloud.google.com/text-to-speech/docs/voices';

export const BRAND_NAME = 'WeGoTrip';

export const APP_GOOGLE_PLAY = `${DOMAIN_URL}android`;
export const APP_APP_STORE = `${DOMAIN_URL}ios`;

export const HOST_NAME = window.location.hostname
  .split('.')
  .slice(-2)
  .join('.');
export const PROTOCOL = 'https';
export const APP_URL = `${PROTOCOL}://app.${HOST_NAME}`;

export const SITE_NAME = HOST_NAME;
export const STORE_INDEX = `https://${HOST_NAME}/`;
export const STORE_PROFILE_LINK = `https://${HOST_NAME}/profile`;

export const REGEXP_DOMAIN_URL = /(https:\/\/)?([a-z-]*\.{1})?(wegotrip\.com)/;
export const REGEXP_SITE_URL = /^(https:\/\/)?([a-z-]*\.{1})?(wegotrip\.com)([a-z0-9.\-/!#_]*)$/g;
export const REGEXP_REFERRAL_URL = /^(https:\/\/)?([a-z-]*\.{1})?(wegotrip\.com)([a-z0-9.\-/!#_]*)(\?coupon=[0-9]*){1}(&label=.*)?$/g;
export const REGEXP_NICKNAME = /^[a-z0-9-_]+$/g;
export const PPRODUCT_RELATIVE = '/admin/products/ppartner/';
export const SITE_LINK = STORE_INDEX;
export const SUPPORT_EMAIL = 'studio-support@wegotip.com';
export const INFO_EMAIL = 'hello@wegotrip.com';
export const APPLY_EMAIL = INFO_EMAIL;
export const SIGNUP_AUTHORS_LINK = `${DOMAIN_URL}authors/onboarding/`;
export function getSignupReferralLink(lang) {
  return `${DOMAIN_URL}${lang}/partners/`;
}

export const TERMS_LINK = `${SITE_LINK}terms/`;
export const PRIVACY_POLICY_LINK = `${SITE_LINK}privacy-policy/`;

export const PAYMENT_PROVIDER = 'yandex';
export const EXTRA_SCRIPTS = [];

export const TEXT_LIMIT = 5000;

// MSK
export const DEFAULT_COORDS = {
  lat: 59.939095,
  lon: 30.315868,
};

// Main color
export const MAIN_COLOR = '#22ccd8';

// Tutorial guide colors
export const TOOLTIP_BACKGROUND_COLOR = MAIN_COLOR;
export const BUTTON_BACKGROUND_COLOR = '#1ba3ad';

export const AUTHORS_REFERENCE = `${SITE_LINK}help/`;

// DEMO quest
export const DEMO_QUEST_REFERENCE_RU = `${APP_URL}/ru/to/DEMO/`;
export const DEMO_QUEST_REFERENCE_EN = `${APP_URL}/en/to/DEMO/`;

export const TUTORIAL_LINK = `${SITE_LINK}video-tutorial`;
export const AR_TUTORIAL_LINK = `${SITE_LINK}ar-tutorial`;

export const INTERCOM_APP_ID = 'i3w94jxt';

export const DEFAULT_CURRENCY_SYMBOL = '€';

export const SENTRY_DSN =
  'https://8397fc674cd646f597493c7cdb4bf363@sentry.wegotrip.com/6';

// TODO: handle it
// YA Metric
export const YA_METRIC_API_URL = '/ym/stat/v1/data';
export const YA_METRIC_ACCESS_TOKEN = 'AQAEA7qjIJZKAAeuPpSdfzyk6kaei0ttPd8y2gY'; // Auth token
export const YA_METRIC_WIDGET_ID = '83895544'; // YA metrics id for the widget
export const YA_METRIC_STORE_ID = '57352666'; // YA metrics id for the surprise me store
export const YA_METRIC_METRIC = 'ym:pv:pageviews'; // Page views metric
export const YA_METRIC_SHOWS_FILTER = (couponId) =>
  `ym:pv:URL=~'\\\\bcoupon=${couponId}\\\\b'`; // Filter for the shows metric
export const YA_METRIC_VIEWS_FILTER = (couponId) =>
  `ym:pv:URL=~'\\\\bcoupon=${couponId}\\\\b' AND ym:s:goal==223653017`; // Filter for the views metric
export const YA_METRIC_CLICKS_FILTER = (couponId) =>
  `ym:pv:UTMSource=='widget' AND ym:pv:URL=~'\\\\bcoupon=${couponId}\\\\b'`; // Filter for the clicks metric
export const YA_METRIC_DIMENSIONS = 'ym:pv:URL'; // How to group metrics data
