import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import avatarBase from '../../img/avatar-base.svg';
import { emojiHeads } from '../../constants/EmojiHeads';
import './Avatar.css';

const Avatar = styled.div`
  width: ${(props) => (props.normal ? '100px' : '30px')};
  height: ${(props) => (props.normal ? '100px' : '30px')};
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${(props) => props.url});
  border: 1px solid #e6e4e4;
`;

const EmojiAvatar = styled(Avatar)`
  text-align: center;
  display: inline-block;
  font-size: ${(props) => (props.normal ? '60px' : '11px')};
  line-height: ${(props) => (props.normal ? '70px' : '24px')};
  background-size: contain;
  background-image: url(${avatarBase});
`;

const AvatarMock = ({ email, normal, className, ...props }) => {
  const nick = email.split('@')[0];
  const lastChar = nick.split('').reverse()[0];
  const charCode = lastChar.charCodeAt(0);
  let index;
  if (charCode >= 48 && charCode <= 57) {
    index = charCode - 48;
  } else {
    index = charCode - 97;
  }
  const head = emojiHeads[index];
  return (
    <EmojiAvatar
      className={classNames(
        'Avatar__emoji',
        `Avatar__emoji--normal`,
        className
      )}
      normal={normal}
      {...props}
    >
      {head}
    </EmojiAvatar>
  );
};

export default ({ email, avatar, className, ...props }) => {
  if (!avatar && email) {
    return (
      <AvatarMock
        className={classNames('AvatarMock', className)}
        email={email}
        {...props}
      />
    );
  }
  return (
    <Avatar
      className={classNames('Avatar', className)}
      url={avatar}
      {...props}
    />
  );
};
