import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import './TourPhotos.css';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { bindActionCreators } from 'redux';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { uploadBatch, uploadAbort } from '../../../actions/uploads';
import * as PublishingActions from '../../../actions/publishing';
import withContentLoader from '../../Dropzone/withContentLoader';
import withContentDescription from '../../Dropzone/withContentDescription';
import DropzoneContent from '../../Dropzone/DropzoneContent';
import PictureDropzone from '../../Dropzone/PictureDropzone';
import TourContentFooterButtons from '../../TourContentFooter/TourContentFooterButtons';
import HelperLink from '../../HelperLink';
import photoExample1 from '../../../img/photoExamples/1.jpg';
import photoExample2 from '../../../img/photoExamples/2.jpg';
import photoExample3 from '../../../img/photoExamples/3.jpg';
import { HELP_CENTER_URL } from '../../../constants/branding';
import { MAX_PRODUCT_IMAGE_AMOUNT } from '../../../constants/limits';

/**
 * `DropzoneContent` with text input near the field
 * @type {React::Component}
 */
const DropzonePicturesContent = withContentDescription(
  withContentLoader(DropzoneContent)
);

/**
 * Coponent messages to be translated
 * @type {Object}
 */
const MESSAGES = defineMessages({
  coverImage: {
    id: 'StudioCreateProduct.tourPhotosCover',
    defaultMessage: 'Cover',
  },
});

/**
 * JSS styles for `TourPhotos` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 24,
    marginTop: 40,
    marginBottom: -15,
  },
  container: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
  },
  img: {
    width: '100%',
    borderRadius: 4,
    cursor: 'pointer',
  },
  coverImg: {
    width: '100%',
    borderRadius: 4,
    border: '4px solid',
    borderColor: theme.palette.primary.main,
    cursor: 'pointer',
  },
  coverTitle: {
    position: 'absolute',
    right: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    textAlign: 'center',
    padding: '5px 10px',
    color: 'white',
  },
  imageNumber: {
    position: 'absolute',
    right: '10%',
    top: '10%',
    width: 25,
    lineHeight: '25px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    color: 'white',
    textAlign: 'center',
  },
  gridImg: {
    marginBottom: 30,
    borderBottom: 'solid 0.5px #f0f0f0',
    paddingBottom: '30px !important',
  },
  gridImgExamples: {
    position: 'relative',
    height: '80%',
  },
  alert: {
    position: 'absolute',
    right: 3.5,
  },
  dropzone: {
    marginBottom: 24,
    padding: '3%',
    '& .Dropzone__info': {
      justifyContent: 'center',
    },
  },
  checkControl: {
    marginBottom: 18,
  },
  cardTitleSm: {
    marginBottom: 17,
  },
  cardTitleMd: {
    marginBottom: 24,
  },
  helperFullness: {
    marginTop: 40,
    marginBottom: -25,
  },
  photoDescription: {
    marginLeft: 8,
    width: '100%',
  },
  relativeGrid: {
    position: 'relative',
  },
  fullnessAlert: {
    marginTop: 25,
    marginBottom: 25,
    maxWidth: 'max-content',
  },
  contentButtons: {
    display: 'flex',
    '& > *': {
      '--img-b-padding': '6px',
      // margin: '8px 8px 0 calc(-1 * var(--img-b-padding))',
      paddingLeft: 'var(--img-b-padding)',
      // textAlign: 'left',
    },
  },
  Button: {
    padding: 0,
    border: '1px solid',
    maxHeight: '48px',
  },
}));

const TourPhotos = ({
  currentQuest: { products },
  intl,
  location,
  currentQuestId,
  // eslint-disable-next-line no-shadow
  publishingActions,
  publishing: {
    receivedPublishData: { images },
  },
  uploads,
  uploadActions,
  checkFullness,
  userLocale,
}) => {
  /**
   * JSS styles for `TourPhotos` element
   * @type {React::Hook}
   */
  const classes = useStyles();

  /**
   * State for checking user confirms that he has the rights to use the photos
   * @type {Boolean}
   */
  const [confirm, setConfirm] = useState(false);

  /**
   * Validate nextValue value and send it to server
   * @param {Array[Object]} oldValues - old listed objects array
   * @param {Object} nextValue - new object
   * @param {String} text - new object text
   * @param {String} attribute - product attribute key name
   */
  const handleDescription = ({ imageId, description }) => {
    publishingActions.sendListedData(
      products[0].id,
      images[imageId].id,
      { description },
      'images'
    );
  };

  /**
   * Checks if the user has clicked to confirm ownership of the photo
   */
  useEffect(() => {
    images.length ? setConfirm(true) : setConfirm(false);
  }, [images]);

  /**
   * Sends images to server
   * @param {Array[Object]} files - descriptions of files to send
   */
  const handleImages = (metas) => {
    uploadActions.uploadBatch(
      { productId: products[0].id },
      metas.map((meta) => ({
        ...meta,
        endpoint: `v2/products/${products[0].id}/images/`,
      }))
    );
  };

  /**
   * Deleting image
   * @param {Object} $ - photo description
   * @param {Number} $.imageId - index of the image
   */
  const handleDeleteImage = ({ imageId }) => {
    publishingActions.deleteListedData(
      products[0].id,
      images[imageId].id,
      'images'
    );
  };

  const handleDeleteAllImages = ({ imageIds, mediaIds }) => {
    const idElements = imageIds.map((imageId) => images[imageId].id);
    publishingActions.deleteMultipleImages(
      products[0].id,
      idElements,
      'images'
    );
  };
  /**
   * Assigns image as cover
   * @param {Object} $
   * @param {Number} $.imageId - image index
   */
  const assignCover = ({ imageId }) => {
    const image = images[imageId];
    if (image.cover === false) {
      publishingActions.sendListedData(
        products[0].id,
        image.id,
        { cover: true },
        'images'
      );
    }
  };

  /**
   * Checking the filling of fields and returning what is not filled
   * @returns {Array[String]} - fields that are not filled
   */
  const handleFullnessText = () => {
    const entities = [];

    if (!images.length < 6) {
      entities.push(
        intl.formatMessage({
          id: 'StudioCreateProduct.tourPhotosPhotos',
          defaultMessage: 'photos',
        })
      );
    }

    return entities.join(', ');
  };

  const goodWord = (
    <FormattedMessage
      id="StudioCreateProduct.tourPhotosGood"
      defaultMessage="Good"
    />
  );

  return (
    <section className="App__main TourPhotos__main">
      <Container className={classes.container}>
        <Paper elevation={0} className={classes.paper}>
          <Typography className={classes.cardTitleMd} variant="h5">
            <FormattedMessage
              id="StudioCreateProduct.tourPhotosExamples"
              defaultMessage="Examples of high-quality photos"
            />
          </Typography>
          <Grid container spacing={1}>
            <Grid item className={classes.gridImgExamples} xs={12} lg={4}>
              <Alert
                className={classes.alert}
                iconMapping={{
                  success: <CheckCircleOutlineIcon fontSize="inherit" />,
                }}
                severity="success"
              >
                {goodWord}
              </Alert>
              <img className={classes.img} alt="" src={photoExample1} />
              <Typography gutterBottom variant="body1">
                <FormattedMessage
                  id="StudioCreateProduct.tourPhotosHint"
                  defaultMessage="Vivid, crisp, color, natural"
                />
              </Typography>
            </Grid>

            <Grid item className={classes.gridImgExamples} xs={12} lg={4}>
              <Alert
                className={classes.alert}
                iconMapping={{
                  success: <CheckCircleOutlineIcon fontSize="inherit" />,
                }}
                severity="success"
              >
                {goodWord}
              </Alert>
              <img className={classes.img} alt="" src={photoExample2} />
              <Typography gutterBottom variant="body1">
                <FormattedMessage
                  id="StudioCreateProduct.tourPhotosHint2"
                  defaultMessage="Illustrate the excursion, show places, participants, convey emotions"
                />
              </Typography>
            </Grid>

            <Grid item className={classes.gridImgExamples} xs={12} lg={4}>
              <Alert className={classes.alert} severity="error">
                <FormattedMessage
                  id="StudioCreateProduct.tourPhotosBad"
                  defaultMessage="Bad"
                />
              </Alert>
              <img className={classes.img} alt="" src={photoExample3} />
              <Typography gutterBottom variant="body1">
                <FormattedMessage
                  id="StudioCreateProduct.tourPhotosHint3"
                  defaultMessage="No filters, superimposed text, graphics or logos. Collages are prohibited"
                />
              </Typography>
            </Grid>

            <HelperLink
              marginBottom={2}
              text={
                <FormattedMessage
                  id="StudioCreateProduct.tourPhotosHelper"
                  defaultMessage="How to choose the right photos"
                />
              }
              link={`${HELP_CENTER_URL}${userLocale}/photodesc/`}
            />
          </Grid>
        </Paper>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.cardTitleSm}>
            <Typography display="inline" variant="h5">
              <FormattedMessage
                id="StudioCreateProduct.tourPhotosRequirements"
                defaultMessage="At least 6 photos and a maximum of {max}"
                values={{
                  max: MAX_PRODUCT_IMAGE_AMOUNT,
                }}
              />
            </Typography>
            <Typography
              variant="h6"
              display="inline"
              style={{ float: 'right' }}
            >
              {images.length > 6 ? (
                <FormattedMessage
                  id="StudioCreateProduct.TourPhotosUploadedMore"
                  defaultMessage="{value} photos uploaded"
                  values={{
                    value: images.length,
                  }}
                />
              ) : (
                <FormattedMessage
                  id="StudioCreateProduct.TourPhotosUploaded"
                  defaultMessage="Uploaded {value} of {max}"
                  values={{
                    value: images.length,
                    max: MAX_PRODUCT_IMAGE_AMOUNT,
                  }}
                />
              )}
            </Typography>
          </div>
          {images.length < 6 && location.highlights && (
            <Alert severity="error" className={classes.fullnessAlert}>
              <FormattedMessage
                id="StudioCreateProduct.TourPhotosAlert"
                defaultMessage="Please add at least 6 photos"
              />
            </Alert>
          )}
          <Typography gutterBottom variant="body2">
            <FormattedMessage
              id="StudioCreateProduct.tourPhotosSubheading1"
              defaultMessage="The more photos you upload, the better the tour will sell."
            />
          </Typography>

          <FormControlLabel
            className={classes.checkControl}
            control={
              <Checkbox
                checked={confirm}
                color="primary"
                onChange={() => !images.length && setConfirm(!confirm)}
              />
            }
            label={
              <FormattedMessage
                id="StudioCreateProduct.tourPhotosConfirm"
                defaultMessage="I confirm that I have the rights to use the photos"
              />
            }
          />
          <PictureDropzone
            ContentPreviewComponent={DropzonePicturesContent}
            generatedPhotos
            overviewPhotos
            disabled={!confirm || images.length >= MAX_PRODUCT_IMAGE_AMOUNT}
            maxAmount={MAX_PRODUCT_IMAGE_AMOUNT}
            currentAmount={images.length}
            contentType="imageStore"
            params={{ productId: products[0].id }}
            uploads={uploads.imageStore}
            images={images.map((image) => image.full)}
            descriptions={images.map((image) => image.description)}
            // tourPhotosContent
            renderContent={(
              Content,
              { index, children, ...contentProps },
              { originalIndex, isUpload }
            ) => (
              <Content
                key={contentProps.src}
                {...contentProps}
                {...(!isUpload && images[originalIndex].cover
                  ? { tag: intl.formatMessage(MESSAGES.coverImage) }
                  : { index })}
              >
                <div className={classes.contentButtons}>
                  {!isUpload && !images[originalIndex].cover ? (
                    <Button
                      color="primary"
                      onClick={() => assignCover({ imageId: originalIndex })}
                      className={classes.Button}
                    >
                      <FormattedMessage
                        id="StudioCreateProduct.tourPhotosAssignCover"
                        defaultMessage="Assign cover"
                      />
                    </Button>
                  ) : null}
                  {children}
                </div>
              </Content>
            )}
            onDescription={handleDescription}
            onAdded={handleImages}
            onAborted={uploadActions.uploadAbort}
            onRemoved={handleDeleteImage}
            onRemovedAll={handleDeleteAllImages}
            onDragStarted={(currentIndex) => currentIndex !== 0}
            onDragged={(prevIndex, nextIndex) => nextIndex !== 0}
            onReordered={publishingActions.reorderProductImages}
          />
        </Paper>
        {!checkFullness('photos') && (
          <Typography className={classes.helperFullness} variant="body1">
            <FormattedMessage
              id="StudioCreateProduct.tourPhotosRemainsToFill"
              defaultMessage="Remaining sections to fill in: "
            />
            {handleFullnessText()}
          </Typography>
        )}
        <TourContentFooterButtons
          leftLink={`/quest/${currentQuestId}/details`}
          rightLink={
            location.highlights
              ? `/quest/${currentQuestId}/check`
              : `/quest/${currentQuestId}/audience`
          }
          withRedirectToTourCheck={location.highlights}
          leftBtnVisible={!location.highlights}
        />
      </Container>
    </section>
  );
};

function mapStateToProps() {
  return (state) => {
    return {
      publishing: state.publishing,
      currentQuestId: state.quests.currentQuestId,
      userLocale: state.user.locale,
      uploads: {
        imageStore: state.uploads.uploads.filter(
          (u) => u.contentType === 'imageStore'
        ),
      },
    };
  };
}

function mapDispatchToProps(dispatch) {
  return {
    uploadActions: bindActionCreators({ uploadAbort, uploadBatch }, dispatch),
    publishingActions: bindActionCreators(PublishingActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(TourPhotos)));
