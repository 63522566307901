import React, { Component } from 'react';
import classNames from 'classnames';
import { injectIntl, defineMessages } from 'react-intl';
import { ShopWhiteIcon } from '../Icon';
import { STORE_INDEX } from '../../constants/branding';
import './ProductIcon.css';

const StoreLink = ({ slug, collectionSlug, citySlug, onClick, children }) => (
  <a
    className="link link--blue ProductIcon__link"
    href={`${STORE_INDEX}${citySlug}/${collectionSlug}/${slug}/`}
    target="_blank"
    rel="noreferrer"
    onClick={onClick}
  >
    {children}
  </a>
);

const EditLink = ({ slug, onClick, children }) => (
  <a
    className="link link--blue ProductIcon__link"
    href={`${STORE_INDEX}edit/${slug}`}
    target="_blank"
    rel="noreferrer"
    onClick={onClick}
  >
    {children}
  </a>
);

const messages = defineMessages({
  editLinkTitle: {
    id: 'ProductIcon.editLinkTitle',
    defaultMessage: 'Link to change «{productName}»',
  },
  storeLinkTitle: {
    id: 'ProductIcon.storeLinkTitle',
    defaultMessage: 'Link to the tour «{productName}» in the store',
  },
});

class ProductIcon extends Component {
  preventPropagation = (event) => {
    event.stopPropagation();
  };

  render() {
    const {
      quest: { products = [] },
      className,
      intl: { formatMessage },
      min,
    } = this.props;

    if (!products.length) {
      return null;
    }

    const {
      name,
      location,
      slug,
      citySlug,
      collectionSlug,
      status,
    } = products[0];

    const isQuestInStore = location.includes('store') && status === 'approved';

    const shopIcon = (
      <ShopWhiteIcon
        className="ProductIcon__icon ProductIcon__icon--white"
        title={formatMessage(messages.editLinkTitle, {
          productName: name,
        })}
      />
    );

    return (
      <div
        className={classNames(
          'ProductIcon',
          !isQuestInStore && 'ProductIcon--hidden',
          min && 'ProductIcon--min',
          className
        )}
      >
        {isQuestInStore ? (
          <StoreLink
            slug={slug}
            citySlug={citySlug}
            collectionSlug={collectionSlug}
            onClick={this.preventPropagation}
          >
            {shopIcon}
          </StoreLink>
        ) : (
          <EditLink slug={slug} onClick={this.preventPropagation}>
            {shopIcon}
          </EditLink>
        )}
      </div>
    );
  }
}

ProductIcon.propTypes = {};

export default injectIntl(ProductIcon);
