import { useRef } from 'react';
import dragula from 'dragula';
import './Dragula.css';

/**
 * Adds drag'n'drop ability for children of the `getDragAndDropRoot` component
 * It's recommended to use `data-*` attributes on draggable items for their indexes identification
 * @param {Object} $
 * @param {Function?} $.onDropped - calls when item was dropped
 * @returns {Object} - object with `getDragAndDropRoot`, which must be attached as ref to the component which children should have dragging options
 */
export default function useDragAndDrop({
  indexes = true,
  onDropped: onDroppedExt = () => {},
  ...options
}) {
  const dragulaRef = useRef();

  /**
   * Child of the `getDragAndDropRef` ref was dropped
   * Calls external `onDropped` function
   * @param {Element} element - dragging element
   * @param {Element} target -
   * @param {Element} source -
   * @param {Element} sibling - element hear which `element` was dropped (`sibling` will be at right from `element`)
   */
  const onDropped = (element, target, source, sibling) =>
    onDroppedExt(element, target, source, sibling);

  /**
   * Attaches `react-dragula` to `containerRef`
   * @param {React::Ref} containerRef - ref to the container with draggable items
   */
  const getDragAndDropRoot = (containerRef) => {
    if (!dragulaRef.current) {
      dragulaRef.current = dragula([containerRef], options);
      dragulaRef.current.on('drop', onDropped);
    } else {
      dragulaRef.current.containers = [containerRef];
    }
  };

  return { getDragAndDropRoot };
}
