import * as types from '../constants/ActionTypes';
import Api from '../functions/Api';

export function requestPlans(id) {
  return {
    type: types.REQUEST_PLANS,
    id,
  };
}

export function notReceivePlans() {
  return {
    type: types.NOT_RECEIVE_PLANS,
    // message: response.json().message,
    // status_code: response.status
  };
}

export function fetchPlans(questId) {
  if (questId === -1) return notReceivePlans();
  return requestPlans(questId);
}

export function receiveQuestCode(json, id) {
  return {
    type: types.RECEIVE_QUEST_CODE,
    payload: json.data.accessCode,
    id,
  };
}

export function publishFree({ data: { accessCode } }, id) {
  return {
    type: types.PUBLISH_FREE,
    payload: accessCode,
    id,
  };
}

export function openPublishPopup(id) {
  return {
    type: types.OPEN_PUBLISH_POPUP,
    id,
  };
}

export function receivePayment({ data, additional }) {
  return {
    type: types.RECEIVE_PAYMENT,
    data,
    additional,
  };
}

export function publishQuest(id, planId, paymentType) {
  return (dispatch) => {
    return Api.post(`quests/${id}/publish/`, {
      data: {
        plan: planId,
        paymentType,
      },
    })
      .then((json) => {
        if (json.data.accessCode) {
          // Free plan
          dispatch(publishFree(json, id));
          return dispatch(receiveQuestCode(json, id));
        }
        // Paid plan
        return dispatch(receivePayment(json));
      })
      .catch((error) => console.error(error)); // TOOD: handle error
  };
}

export function startPayment() {
  return {
    type: types.START_PAYMENT,
  };
}

export function notreceivePayment() {
  return {
    type: types.NOT_RECEIVE_PAYMENT,
  };
}

export function chooseBusinessPlan() {
  return {
    type: types.CHOOSE_BUSINESS_PLAN,
  };
}

export function startPublishProduct() {
  return {
    type: types.START_PUBLISH_PRODUCT,
  };
}

export function publishProduct(idProduct) {
  return {
    type: types.PUBLISH_PRODUCT,
    idProduct,
  };
}

export function resetPublishProductErrors() {
  return {
    type: types.RESET_PUBLISH_PRODUCT_ERRORS,
  };
}

/**
 * Reorders product images and save new order on the server
 * @param {Object} action
 * @param {Number} action.imageId - old image index in array of already uploaded images (if image is not uploading)
 * @param {Number} action.newImageId - new image index in array of already uploaded images (if image is not uploading)
 * @param {MediaId} action.mediaId - description of old image position in array mixed from uploading and uploaded images
 * @param {MediaId} action.newMediaId - description of new image position in array mixed from uploading and uploaded images
 */
export function reorderProductImages(action) {
  return {
    type: types.REORDER_PRODUCT_IMAGES_SYNC,
    ...action,
  };
}

/**
 * Sends new order of product images to server
 * @param {Object} action
 * @param {Number} action.productId - id of the product to sync images order
 */
export function syncProductImagesOrder(productId) {
  return {
    type: types.SYNC_PRODUCT_IMAGES_ORDER,
    productId,
  };
}

export function sendPublishData(id, payload) {
  return {
    type: types.SEND_PUBLISH_DATA,
    data: payload,
    id,
  };
}

export function postListedData(idProduct, payload, endpoint) {
  return {
    type: types.POST_LISTED_DATA,
    idProduct,
    data: payload,
    endpoint,
  };
}

export function sendListedData(idProduct, idElement, payload, endpoint) {
  return {
    type: types.SEND_LISTED_DATA,
    idProduct,
    idElement,
    data: payload,
    endpoint,
  };
}

export function deleteListedData(idProduct, idElement, endpoint) {
  return {
    type: types.DELETE_LISTED_DATA,
    idProduct,
    idElement,
    endpoint,
  };
}

export const deleteMultipleImages = (idProduct, imageIds, endpoint) => ({
  type: types.DELETE_MULTIPLE_IMAGES,
  idProduct,
  imageIds,
  endpoint,
});

export function fetchProduct(id) {
  return {
    type: types.REQUEST_PRODUCT,
    id,
  };
}

export function sendAttractionsData(data, purpose) {
  return {
    type: types.SEND_ATTRACTIONS_DATA,
    data,
    purpose,
  };
}

export function sendTicketsData(data, purpose, idAttraction, idTicket = null) {
  return {
    type: types.SEND_TICKETS_DATA,
    data,
    purpose,
    idAttraction,
    idTicket,
  };
}

export function sendTicketCategoriesData(
  data,
  purpose,
  idTicket,
  idCategory = null
) {
  return {
    type: types.SEND_TICKETS_CATEGORIES_DATA,
    data,
    purpose,
    idTicket,
    idCategory,
  };
}

export function deleteTicket(idAttraction, idTicket, withAttraction = false) {
  return {
    type: types.DELETE_TICKET,
    idAttraction,
    idTicket,
    withAttraction,
  };
}

export function convertAudioGuides(idProduct, nextCurrencyId) {
  return {
    type: types.CONVERT_AUDIO_GUIDES_PRICES,
    idProduct,
    nextCurrencyId,
  };
}
