import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router';
import ProfileContent from '../ProfileContent';

/**
 * Messages for `ProfileWelcome` component
 * @type {Object}
 */
const useStyles = makeStyles((theme) => ({
  inner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '5%',
  },
  containerIframe: {
    paddingLeft: 'min(215px, 20%)',
    paddingRight: 'min(215px, 20%)',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  responsiveIframe: {
    float: 'none',
    clear: 'both',
    width: '100%',
    position: 'relative',
    paddingBottom: '56.25%',
    height: 0,
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
  createTourBtn: {
    marginTop: 22,
    marginBottom: 30,
  },
}));

/**
 * ProfileWelcome component
 * @param {Object} $.user - user data from store
 * @param {Function} $.handleAddQuest - function for handle quest creating
 * @param {String} $.questsLength - length of `store::stats.questsStats.results` quests stats
 */
const ProfileWelcome = ({ user: { locale }, handleAddQuest, questsLength }) => {
  const classes = useStyles();

  if (questsLength > 0) return <Redirect to="" />;

  return (
    <ProfileContent>
      <div className={classes.inner}>
        <Typography variant="h5">
          <FormattedMessage
            id="ProfileWelcome.welcomeTitle"
            defaultMessage="Welcome to the community of digital-guides"
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            id="ProfileWelcome.subTitle"
            defaultMessage="Create your first product in WeGoTrip Studio"
          />
        </Typography>
        <Button
          className={classes.createTourBtn}
          variant="contained"
          color="primary"
          onClick={() => handleAddQuest()}
        >
          <FormattedMessage
            id="ProfileWelcome.createTour"
            defaultMessage="Create a tour"
          />
        </Button>
      </div>
      <div className={classes.containerIframe}>
        <div className={classes.responsiveIframe}>
          <iframe
            allowFullScreen
            width="560"
            height="315"
            src={
              locale === 'ru'
                ? 'https://www.youtube.com/embed/uMMb5S_MfA0'
                : 'https://www.youtube.com/embed/csjn6OuZD-o'
            }
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </div>
      </div>
    </ProfileContent>
  );
};

export default ProfileWelcome;
