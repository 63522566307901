import Api from '../../../functions/Api';

export async function FetchCategory(lang) {
  let category;

  try {
    const response = await Api.get(`v2/categories/`, {
      locale: lang,
    });
    category = response.data;
  } catch (e) {
    if (!category) {
      return { statusCode: 404 };
    }
  }

  return category;
}
