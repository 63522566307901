import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import { MAX_IMAGE_SIZE } from '../../../constants/limits';
import Dropzone from '..';

/**
 * Messages for `DropzonePictures` component
 * @type {Object}
 */
const messages = defineMessages({
  title: {
    id: 'DropzonePictures.title',
    defaultMessage:
      'Drag photos here, or click to select. No more than {size}MB per file: JPEG, PNG',
  },
});

/**
 * Dropzone for handling images
 * @param {Object} $
 * @param {Number?} $.maxAmount - max amount of files, used here only for displaying in subtitle as info
 * @param {...Object} - will be passed into `Dropzone` component
 */
export default injectIntl(function DropzonePictures({
  maxAmount = 5,
  currentAmount,
  maxSize = MAX_IMAGE_SIZE,
  children,
  intl: { formatMessage },
  disabled,
  ...dropzoneProps
}) {
  return (
    <Dropzone
      disabled={disabled}
      icon={BurstModeIcon}
      title={formatMessage(messages.title, {
        size: Math.round(maxSize / (1024 * 1024)),
      })}
      accepts="image/*"
      maxSize={maxSize}
      maxAmount={maxAmount}
      currentAmount={currentAmount}
      {...dropzoneProps}
    >
      {children}
    </Dropzone>
  );
});
