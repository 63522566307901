/**
 * Creates wrapper for functions
 * @param {...Array[Function]} functions - will be called with wrapper arguments when wrapper called
 * @returns {Function} - wrapper
 */
export default function unite(...functionsUnsafe) {
  const functions = functionsUnsafe.filter((f) => typeof f === 'function');

  return function (...args) {
    functions.forEach((f) => f(...args));
  };
}
