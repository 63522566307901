import normalizeQueryObject from './normalizeQueryObject';
import queryObjectToString from './queryObjectToString';

/**
 * Forms query for URL
 * @param {Object} query - query params
 * @param {Boolean} mustNormalize - if set, will preprocess `query` with `normalizeQueryObject` function (`true` by default)
 * @returns {String} - URL query string with `?` on start if params are existing
 */
export default function formQuery(query, mustNormalize = true) {
  return [
    ...(mustNormalize ? [normalizeQueryObject] : []),
    queryObjectToString,
  ].reduce((acc, preprocessor) => preprocessor(acc), query);
}
