import { REQUISITE_NAMES } from '../../constants/requisites';

/**
 * Checks if all required payout requisites are filled
 * @param {Object} requisites - `user.requisites` object from server
 * @returns {Boolean}
 */
export default function checkRequisites(requisites) {
  const bankCodeTypes = ['bankBic', 'bankSwift'];
  return (
    bankCodeTypes.some((r) => !!requisites[r]) &&
    REQUISITE_NAMES.filter((r) => !bankCodeTypes.includes(r)).every(
      (r) => !!requisites[r]
    )
  );
}
