import Api from './Api';

export const checkTaskStatus = async (taskId, currentQuestId) => {
  if (!taskId) return { error: true };

  try {
    const response = await Api.get(`quests/taskResult/?taskId=${taskId}`);
    const status = response.status;

    if (status === 'SUCCESS' || status === 'FAILURE') {
      localStorage.removeItem(`taskId-${currentQuestId}`);
    }

    return { status, error: status === 'FAILURE' };
  } catch (error) {
    console.error('Error checking task status:', error);
    return { error: true };
  }
};
