import { buffers, eventChannel, END } from 'redux-saga';

export default function creatFileReadChannel(file) {
  return eventChannel((emitter) => {
    const reader = new FileReader();
    const onFailure = (e) => {
      emitter({ err: new Error('Read failed!') });
      emitter(END);
    };
    const onSuccess = (e) => {
      emitter({ data: e.target.result });
      emitter(END);
    };
    reader.addEventListener('error', onFailure);
    reader.addEventListener('abort', onFailure);
    reader.addEventListener('load', onSuccess);
    reader.readAsDataURL(file);
    return () => {
      reader.removeEventListener('error', onFailure);
      reader.removeEventListener('abort', onFailure);
      reader.removeEventListener('load', onSuccess);
      reader.abort();
    };
  }, buffers.sliding(2));
}
