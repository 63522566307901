import React, { useState, useEffect, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import './TourLocation.css';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { bindActionCreators } from 'redux';
import {
  FormControl,
  FormControlLabel,
  Paper,
  Switch,
  TextField,
  FormHelperText,
  Button,
  makeStyles,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { Alert } from '@material-ui/lab';
import * as publishingActionCreators from '../../../actions/publishing';
import Map from '../../Map';
import TourContentFooterButtons from '../../TourContentFooter/TourContentFooterButtons';
import debounce from '../../../functions/debounce';
import { DEFAULT_CENTER } from '../../../constants/GeoParams';
import MapPin from '../../Map/MapPin';
import PlacesAutocomplete from '../../PlacesAutocomplete';
import loadScript from '../../../functions/dom/loadScript';
import { getShortLabel } from '../../../functions/geocoding/googleProvider';
import {
  convertToHoursAndMinutes,
  convertToMinutes,
} from '../../../functions/tourDuration/convertTime';
import { PRODUCT_DISTANCE_CHARACTER_LIMIT } from '../../../constants/limits';

/**
 * JSS styles for `TourLocations` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  textFieldSm: {
    marginRight: 30,
    maxWidth: 190,
    width: '90%',
    marginBottom: 10,
  },
  textFieldM: {
    width: '80%',
    maxWidth: 620,
    marginBottom: 25,
  },
  paper: {
    padding: 24,
    marginTop: 40,
    marginBottom: -15,
    '& .Map': {
      marginBottom: 0,
    },
    '& .Map:before': {
      display: 'none',
    },
  },
  container: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
  },
  formControlSwitch: {
    float: 'right',
  },
  formControlSwitchDetails: {
    float: 'right',
    marginTop: -5,
  },
  formControlSwitchFinish: {
    float: 'right',
    marginTop: -4,
  },
  cardTitle: {
    marginBottom: 18,
  },
  helperFullness: {
    marginTop: 40,
    marginBottom: -25,
  },
  formHelperText: {
    marginTop: -5,
  },
  maskedInputNotTouched: {
    background:
      'linear-gradient(to right, #c9c9c9 0% 14%, black 14% 20%, #c9c9c9 22% 32%, black 39% 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  attractionLabel: {
    fontSize: 20,
    fontWeight: 500,
  },
  attractionSubtitle: {
    marginBottom: 25,
  },
  attractionSubtitleLast: {
    marginBottom: 1,
  },
  deleteAttractionBtn: {
    float: 'right',
    marginTop: -3,
    marginRight: 20,
  },
  fullnessAlert: {
    marginTop: 25,
    marginBottom: 25,
    maxWidth: 'max-content',
  },
}));

/**
 * Messages for `TourLocation` component
 * @type {Object}
 */
const messages = defineMessages({
  address: {
    id: 'StudioCreateProduct.tourLocationsAddress',
    defaultMessage: 'tour start address',
  },
  startLocation: {
    id: 'StudioCreateProduct.tourLocationsStartLocation',
    defaultMessage: 'tour start location',
  },
  finishAddress: {
    id: 'StudioCreateProduct.tourLocationsFinishAddress',
    defaultMessage: 'tour end address',
  },
  finishLocation: {
    id: 'StudioCreateProduct.tourLocationsFinishAddressLocation',
    defaultMessage: 'tour end location',
  },
  attractions: {
    id: 'StudioCreateProduct.tourLocationsSights',
    defaultMessage: 'sights',
  },
  distance: {
    id: 'StudioCreateProduct.tourLocationsDistance',
    defaultMessage: 'route length',
  },
  duration: {
    id: 'StudioCreateProduct.tourLocationsDuration2',
    defaultMessage: 'duration',
  },
});

/**
 * Object for implement google PlacesService
 * @type {Object}
 */
const placesService = { current: null };

/**
 * Object for implement google Geocoder
 * @type {Object}
 */
const geocoder = { current: null };

/**
 * Object for implement google Map (PlacesService don't work without googl Map entity)
 * @type {Object}
 */
let map = null;

function TextMaskCustom(props) {
  const { inputRef, hoursWord, minutesWord, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      showMask
      mask={[
        /[1]/,
        /\d/,
        /\d/,
        ' ',
        hoursWord,
        ' ',
        /[1-5]/,
        /\d/,
        ' ',
        minutesWord,
      ]}
      placeholderChar="0"
    />
  );
}

export const noOptionText = (
  <FormattedMessage
    id="StudioCreateProduct.tourLocationsNotFound"
    defaultMessage="Nothing was found"
  />
);

const TourLocations = ({
  currentQuest: { products, events },
  intl,
  currentQuestId,
  publishingActions: { sendAttractionsData, sendPublishData },
  publishing,
  publishing: {
    receivedPublishData,
    receivedPublishData: { locationGeo, finishLocationGeo },
    attractions,
  },
  checkFullness,
  location,
  distance,
  setDistance,
  durationMin,
  setDurationMin,
  durationMax,
  setDurationMax,
  address,
  setAddress,
  finishAddress,
  setFinishAddress,
  startLocation,
  setStartLocation,
  finishLocation,
  setFinishLocation,
  lat,
  lon,
}) => {
  /**
   * JSS styles for `TourTitle` element
   * @type {React::Hook}
   */
  const classes = useStyles();

  /**
   * State for checking if the tour ends where it started
   * @type {Boolean}
   */
  const [sameEnd, setSameEnd] = useState(false);

  /**
   * State for checking if the tour duration is set by the range
   * @type {Boolean}
   */
  const [isTimeRange, setIsTimeRange] = useState(false);

  /**
   * Error state of duration fields
   * @type {Boolean}
   */
  const [durationError, setDurationError] = useState({
    min: false,
    max: false,
  });

  /**
   * State for showing map pin
   * @type {Boolean}
   */
  const [isPinShow, setIsPinShow] = useState(true);

  /**
   * State for checking loading google maps script
   * @type {Boolean}
   */
  const [scriptLoaded, setScriptLoaded] = useState(false);

  /**
   * Validate nextValue value and send it to server
   * @param {String|Number} nextValue - product value for passed attribute
   * @param {String} attribute - product attribute key name
   */
  const handleSave = (nextValueRaw, attribute) => {
    let nextValue = nextValueRaw;
    if (typeof nextValue === 'string') nextValue = nextValue.trim();
    if (
      nextValue === receivedPublishData[attribute] ||
      Number(nextValue) === receivedPublishData[attribute] ||
      (nextValue === 0 && receivedPublishData[attribute] === null)
    )
      return;
    if (attribute === 'duration') {
      if (Number(nextValue) === receivedPublishData.durationMax) return;
      sendPublishData(products[0].id, {
        durationMin: nextValue,
        durationMax: nextValue,
      });
      return;
    }
    if (attribute === 'sameEnd') {
      sendPublishData(products[0].id, {
        finishAddress: null,
        finishLocationGeo: null,
        finishLocation: null,
      });
      return;
    }
    sendPublishData(products[0].id, {
      [attribute]: nextValue,
    });
  };

  /**
   * Memoized and debounced wrap of handleSave
   */
  const debouncedHandleSave = useMemo(() => debounce(handleSave, 1000), [
    receivedPublishData,
  ]);

  /**
   * Validate value and send it to server or return true
   * if value should be saved in handleChange
   * @param {Function} func - function to update local state
   * @param {String} attribute - product attribute key name
   * @param {String|Number} value - passed value to save
   * @returns {Boolean} - for indicate if value should be saved
   */
  const validation = (func, attribute, value) => {
    if (
      attribute === 'duration' ||
      attribute === 'durationMin' ||
      attribute === 'durationMax'
    ) {
      setDurationError(false);
      if (
        attribute === 'durationMax' &&
        convertToMinutes(value) < convertToMinutes(durationMin) &&
        convertToMinutes(durationMin) !== 0
      ) {
        func(value);
        setDurationError({ min: false, max: true });
        return false;
      } else if (
        attribute === 'durationMin' &&
        convertToMinutes(value) > convertToMinutes(durationMax) &&
        convertToMinutes(durationMax) !== 0
      ) {
        func(value);
        setDurationError({ min: true, max: false });
        return false;
      } else if (convertToMinutes(value) > 60 * 24 * 7) {
        func(convertToHoursAndMinutes(10080));
        debouncedHandleSave(10080, attribute);
        return false;
      }
      func(value);
      debouncedHandleSave(convertToMinutes(value), attribute);
      return false;
    }
    func(value);
    return true;
  };

  /**
   * Validate and update local state and save value with wrapped handleSave
   * @param {Function} func - function to update local state
   * @param {String} attribute - product attribute key name
   * @param {Event} event - DOM event caused trigger `onChange`
   */
  const handleChange = (func, attribute) => (event) => {
    if (validation(func, attribute, event.target.value)) {
      debouncedHandleSave(event.target.value, attribute);
    }
  };

  /**
   * Change location tour start/end handler
   * @param {Object} value - location object with address, location and coords
   * @param {String} addressField - attribute name for address field
   * @param {String} locationField - attribute name for location/place field
   * @param {String} geo - attribute name for geo point
   */
  const handleChangeLocation = (value, addressField, locationField, geo) => {
    setIsPinShow(false);
    sendPublishData(products[0].id, {
      [addressField]: value.address,
      [locationField]: value.location,
      [geo]: {
        type: 'Point',
        coordinates: [Number(value.lng), Number(value.lat)],
      },
    });
  };

  /**
   * Show map pin after tour start/end location where changed
   */
  useEffect(() => {
    setIsPinShow(true);
  }, [receivedPublishData.locationGeo, receivedPublishData.finishLocationGeo]);

  /**
   * Handle "Ending where it started" switcher, makes finishAddress,
   * finishLocation and finishLocationGeo equals null
   */
  const handleSameEnd = () => {
    if (
      receivedPublishData.finishAddress !== null ||
      receivedPublishData.finishLocation !== null
    ) {
      handleSave(null, 'sameEnd');
    }
    setSameEnd(!sameEnd);
  };

  /**
   * Checking out if Google Maps API was loaded, then on loaded implementing this API
   */
  if (typeof window !== 'undefined' && !scriptLoaded) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAWr88v7aEfDqUwhr7xXpCBAJLosyj_4Ms&libraries=places',
        document.querySelector('head'),
        'google-maps'
      );
    }

    const googleScript = document.getElementById('google-maps');
    googleScript.addEventListener('load', function load() {
      map = new window.google.maps.Map(document.createElement('div'));
      placesService.current = new window.google.maps.places.PlacesService(map);
      geocoder.current = new window.google.maps.Geocoder();

      googleScript.removeEventListener('load', load);
    });
    setScriptLoaded(true);
  }

  /**
   * Handle search place with google Geocoder
   * @param {Object} value - parameters to google Geocoder
   * @returns {Object} - google Geocoder search results
   */
  const handleGoogleGeocoder = async (value) => {
    return geocoder.current.geocode(value);
  };

  /**
   * Handle search onDrag or onSelect mapPin
   * and send address and coords to server
   * @param {Object} value - lat, lon coordinates from map
   * @param {String} addressField - attribute name for address field
   * @param {String} locationField - attribute name for location/place field
   * @param {String} geo - attribute name for geo point
   */
  const fetchAddress = async (value, addressField, locationField, geo) => {
    let resultGeocoder;
    try {
      resultGeocoder = await handleGoogleGeocoder({
        location: new window.google.maps.LatLng(value.lat, value.lon),
      });

      placesService.current.getDetails(
        { placeId: resultGeocoder.results[0].place_id },
        (result) => {
          handleChangeLocation(
            {
              address: getShortLabel(resultGeocoder.results[0]),
              location: result.name,
              lat: value.lat,
              lng: value.lon,
            },
            addressField,
            locationField,
            geo
          );
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Updating start and finish addresses
   */
  useEffect(() => {
    if (products[0].id === receivedPublishData.id) {
      setAddress(receivedPublishData.address);
      setStartLocation(receivedPublishData.startLocation);
      setFinishAddress(receivedPublishData.finishAddress);
      setFinishLocation(receivedPublishData.finishLocation);
    }
  }, [receivedPublishData.address, receivedPublishData.finishAddress]);

  /**
   * Show finish location menu
   */
  useEffect(() => {
    if (
      receivedPublishData.finishAddress === null &&
      receivedPublishData.finishLocation === null
    ) {
      setSameEnd(true);
      setFinishLocation(receivedPublishData.finishLocation);
    }
  }, [receivedPublishData.finishAddress]);

  /**
   * Handle set time range switcher
   */
  useEffect(() => {
    if (receivedPublishData.durationMin !== receivedPublishData.durationMax) {
      setIsTimeRange(true);
    }
    if (receivedPublishData.durationMin === receivedPublishData.durationMax) {
      setIsTimeRange(false);
      setDurationMin(convertToHoursAndMinutes(receivedPublishData.durationMin));
    }
  }, [
    receivedPublishData.durationMin,
    receivedPublishData.durationMax,
    receivedPublishData.duration,
  ]);

  /**
   * Gets chosen start/finish coordinates or default coordinates
   * @param {String} value - attribute name for geo coordinates
   * @returns {Object} - coords, e.g. { lon: 37.617592, lat: 55.751322 }
   */
  const getCoords = (value) => {
    const {
      receivedPublishData: { [value]: geo },
    } = publishing;
    if (geo !== null)
      return { lon: geo.coordinates[0], lat: geo.coordinates[1] };

    let coords = {};
    lat !== '' && lon !== ''
      ? (coords = { lon, lat })
      : (coords = DEFAULT_CENTER);
    return coords;
  };

  /**
   * Checking the filling of fields and returning what is not filled
   * @returns {String} - fields that are not filled
   */
  const handleFullnessText = () => {
    const {
      receivedPublishData: {
        address: addressReceived,
        startLocation: startLocationReceived,
        finishAddress: finishAddressReceived,
        finishLocation: finishLocationReceived,
        distance: distanceReceived,
        duration: durationReceived,
      },
      attractions: receivedAttractions,
    } = publishing;
    const entities = [];

    if (!addressReceived) {
      entities.push(intl.formatMessage(messages.address));
    }
    if (!startLocationReceived) {
      entities.push(intl.formatMessage(messages.startLocation));
    }
    if (!finishAddressReceived && finishLocationReceived) {
      entities.push(intl.formatMessage(messages.finishAddress));
    }
    if (finishLocationReceived && !finishLocationReceived) {
      entities.push(intl.formatMessage(messages.finishLocation));
    }
    if (receivedAttractions.length < 1) {
      entities.push(intl.formatMessage(messages.attractions));
    }
    if (!distanceReceived) {
      entities.push(intl.formatMessage(messages.distance));
    }
    if (!durationReceived) {
      entities.push(intl.formatMessage(messages.duration));
    }

    return entities.join(', ');
  };

  const minutesWord = intl.formatMessage({
    id: 'StudioCreateProduct.tourLocationsMinutes',
    defaultMessage: 'min',
  });

  const hoursWord = intl.formatMessage({
    id: 'StudioCreateProduct.tourLocationsHours',
    defaultMessage: 'h',
  });

  const enterExactAddress = (
    <FormattedMessage
      id="StudioCreateProduct.tourLocationsExactAddress"
      defaultMessage="Type the address"
    />
  );

  const enterAttractionName = (
    <FormattedMessage
      id="StudioCreateProduct.tourLocationsAttractionName"
      defaultMessage="Enter full attraction name"
    />
  );

  const locationName = (
    <FormattedMessage
      id="StudioCreateProduct.tourLocationsLocationName"
      defaultMessage="Location name"
    />
  );

  const helperText = (
    <FormattedMessage
      id="StudioCreateProduct.tourLocationsHelperText"
      defaultMessage="Describe a specific place, for example: “Times Square” or “Big Ben”"
    />
  );

  const durationErrorText = (
    <FormattedMessage
      id="StudioCreateProduct.tourLocationsDurationError"
      defaultMessage="Please enter the correct time range"
    />
  );
  const attractionsList = attractions.map((attraction, index) => (
    <div
      key={attraction.id}
      className={
        index + 1 === attractions.length ? '' : 'TourLocations__borderline'
      }
    >
      <Typography className={classes.attractionLabel} variant="h6">
        {attraction.name}
        <FormControlLabel
          className={classes.formControlSwitch}
          control={
            <Switch
              checked={attraction.entrance}
              disabled={attraction.protected}
              color="primary"
              size="small"
              onChange={() => {
                sendAttractionsData(
                  { entrance: !attraction.entrance, id: attraction.id },
                  'edit'
                );
              }}
            />
          }
          label={
            <FormattedMessage
              id="StudioCreateProduct.tourLocationsAttractionEntrance"
              defaultMessage="Entrance inside"
            />
          }
        />
        <Button
          className={classes.deleteAttractionBtn}
          color="primary"
          onClick={() => {
            sendAttractionsData({ id: attraction.id }, 'remove');
          }}
        >
          <FormattedMessage
            id="StudioCreateProduct.TourLocationDelete"
            defaultMessage="Delete"
          />
        </Button>
      </Typography>
      <Typography
        className={
          index + 1 === attractions.length
            ? classes.attractionSubtitleLast
            : classes.attractionSubtitle
        }
        color="textSecondary"
        variant="body2"
      >
        {attraction.address}
      </Typography>
    </div>
  ));

  return (
    <section className="App__main TourLocation__main">
      <Container className={classes.container}>
        <Paper elevation={0} className={classes.paper}>
          <Typography className={classes.cardTitle} variant="h5">
            <FormattedMessage
              id="StudioCreateProduct.tourLocationsStart"
              defaultMessage="Starting point of the tour"
            />
          </Typography>

          <PlacesAutocomplete
            withValueProp
            onlyPlaces
            disableClearable
            className={classes.textFieldM}
            value={address || ''}
            noOptionsText={noOptionText}
            forcePopupIcon={false}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                value={address || ''}
                error={!receivedPublishData.address && location.highlights}
                type="MuiText"
                variant="standard"
                label={enterExactAddress}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            onChange={(_event, value) => {
              if (value) {
                handleChangeLocation(
                  {
                    address: getShortLabel(value),
                    location: value.structured_formatting.main_text,
                    lat: value.lat,
                    lng: value.lng,
                  },
                  'address',
                  'startLocation',
                  'locationGeo'
                );
              }
            }}
          />

          <TextField
            className={classes.textFieldM}
            error={!receivedPublishData.startLocation && location.highlights}
            value={startLocation || ''}
            variant="standard"
            type="MuiText"
            label={locationName}
            helperText={helperText}
            onChange={handleChange(setStartLocation, 'startLocation')}
          />

          {(locationGeo === null || locationGeo.coordinates[0] !== null) && (
            <Map
              zoom={receivedPublishData.address ? 16 : 3}
              positions={{
                search: null,
              }}
              coords={getCoords('locationGeo')}
              onSelect={(e) =>
                fetchAddress(e, 'address', 'startLocation', 'locationGeo')
              }
            >
              {isPinShow && (
                <MapPin
                  draggable
                  coords={getCoords('locationGeo')}
                  onDragEnd={(e) =>
                    fetchAddress(
                      {
                        // eslint-disable-next-line no-underscore-dangle
                        lat: e.target._latlng.lat,
                        // eslint-disable-next-line no-underscore-dangle
                        lon: e.target._latlng.lng,
                      },
                      'address',
                      'startLocation',
                      'locationGeo'
                    )
                  }
                />
              )}
            </Map>
          )}
        </Paper>

        <Paper elevation={0} className={classes.paper}>
          <Typography className={sameEnd ? '' : classes.cardTitle} variant="h5">
            <FormattedMessage
              id="StudioCreateProduct.tourLocationsEnd"
              defaultMessage="Finish point of the tour"
            />

            <FormControlLabel
              className={classes.formControlSwitchFinish}
              control={
                <Switch
                  checked={sameEnd}
                  color="primary"
                  onChange={() => handleSameEnd()}
                />
              }
              label={
                <FormattedMessage
                  id="StudioCreateProduct.tourLocationsSameEnd"
                  defaultMessage="Ending where it started"
                />
              }
            />
          </Typography>
          {!sameEnd && (
            <React.Fragment>
              <PlacesAutocomplete
                onlyPlaces
                withValueProp
                disableClearable
                className={classes.textFieldM}
                value={finishAddress || ''}
                noOptionsText={noOptionText}
                forcePopupIcon={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    value={finishAddress || ''}
                    error={
                      !receivedPublishData.finishAddress && location.highlights
                    }
                    type="MuiText"
                    variant="standard"
                    label={enterExactAddress}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                onChange={(event, value) => {
                  if (value) {
                    handleChangeLocation(
                      {
                        address: getShortLabel(value),
                        location: value.structured_formatting.main_text,
                        lat: value.lat,
                        lng: value.lng,
                      },
                      'finishAddress',
                      'finishLocation',
                      'finishLocationGeo'
                    );
                  }
                }}
              />

              <TextField
                className={classes.textFieldM}
                error={
                  !receivedPublishData.finishLocation && location.highlights
                }
                value={finishLocation || ''}
                variant="standard"
                type="MuiText"
                label={locationName}
                helperText={helperText}
                onChange={handleChange(setFinishLocation, 'finishLocation')}
              />

              {(finishLocationGeo === null ||
                finishLocationGeo.coordinates[0] !== null) && (
                <Map
                  zoom={receivedPublishData.finishAddress ? 16 : 3}
                  positions={{
                    search: null,
                  }}
                  coords={getCoords('finishLocationGeo')}
                  onSelect={(e) =>
                    fetchAddress(
                      e,
                      'finishAddress',
                      'finishLocation',
                      'finishLocationGeo'
                    )
                  }
                >
                  {isPinShow && (
                    <MapPin
                      draggable
                      coords={getCoords('finishLocationGeo')}
                      onDragEnd={(e) =>
                        fetchAddress(
                          {
                            // eslint-disable-next-line no-underscore-dangle
                            lat: e.target._latlng.lat,
                            // eslint-disable-next-line no-underscore-dangle
                            lon: e.target._latlng.lng,
                          },
                          'finishAddress',
                          'finishLocation',
                          'finishLocationGeo'
                        )
                      }
                    />
                  )}
                </Map>
              )}
            </React.Fragment>
          )}
        </Paper>

        <Paper elevation={0} className={classes.paper}>
          <div
            className={attractions.length ? 'TourLocations__borderline' : ''}
          >
            <Typography variant="h5" className={classes.cardTitle}>
              <FormattedMessage
                id="StudioCreateProduct.tourLocationsAttractions"
                defaultMessage="Sights"
              />
            </Typography>
            {attractions.length < 1 && location.highlights && (
              <Alert severity="error" className={classes.fullnessAlert}>
                <FormattedMessage
                  id="StudioCreateProduct.TourLocationAttractionsAlert"
                  defaultMessage="Add at least 1 sight"
                />
              </Alert>
            )}
            <Typography gutterBottom variant="body1">
              <FormattedMessage
                id="StudioCreateProduct.tourLocationsPleaseAddSights"
                defaultMessage="Please add points of interest:"
              />
            </Typography>
            <PlacesAutocomplete
              onlyPlaces
              clearAfterPick
              disableClearable
              className={classes.textFieldM}
              noOptionsText={noOptionText}
              forcePopupIcon={false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={attractions.length === 0 && location.highlights}
                  value={address || ''}
                  type="MuiText"
                  variant="standard"
                  label={enterAttractionName}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              onChange={(_e, value) => {
                sendAttractionsData(
                  {
                    name: value.structured_formatting.main_text,
                    address: getShortLabel(value),
                    location: [Number(value.lng), Number(value.lat)],
                    google_place_id: value.place_id,
                  },
                  'add'
                );
              }}
            />
          </div>
          {attractionsList}
        </Paper>

        <Paper elevation={0} className={classes.paper}>
          <Typography className={classes.cardTitle} variant="h5">
            <FormattedMessage
              id="StudioCreateProduct.tourLocationsDetails"
              defaultMessage="Details"
            />
            <FormControlLabel
              className={classes.formControlSwitchDetails}
              control={
                <Switch
                  checked={isTimeRange}
                  color="primary"
                  onChange={() => setIsTimeRange(!isTimeRange)}
                />
              }
              label={
                <FormattedMessage
                  id="StudioCreateProduct.tourLocationsTimeRange"
                  defaultMessage="Time range"
                />
              }
            />
          </Typography>

          <TextField
            className={classes.textFieldSm}
            error={!receivedPublishData.distance && location.highlights}
            value={distance || ''}
            variant="standard"
            type="MuiText"
            inputProps={{ maxLength: PRODUCT_DISTANCE_CHARACTER_LIMIT }}
            label={
              <FormattedMessage
                id="StudioCreateProduct.tourLocationsLength"
                defaultMessage="Tour route length"
              />
            }
            onChange={handleChange(setDistance, 'distance')}
          />
          {isTimeRange ? (
            <React.Fragment>
              <FormControl>
                <InputLabel>
                  <FormattedMessage
                    id="StudioCreateProduct.tourLocationsMinimumDuration"
                    defaultMessage="Min. duration"
                  />
                </InputLabel>
                <Input
                  className={classes.textFieldSm}
                  error={
                    (!receivedPublishData.durationMin && location.highlights) ||
                    durationError.min
                  }
                  value={durationMin || ''}
                  variant="standard"
                  inputComponent={TextMaskCustom}
                  inputProps={{
                    ...(durationMax === '000 ' || durationMax === '000 ч 00 мин'
                      ? { className: classes.maskedInputNotTouched }
                      : {}),
                    hoursWord,
                    minutesWord,
                  }}
                  onChange={handleChange(setDurationMin, 'durationMin')}
                />
                {durationError.min && (
                  <FormHelperText error className={classes.formHelperText}>
                    {durationErrorText}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl>
                <InputLabel>
                  <FormattedMessage
                    id="StudioCreateProduct.tourLocationsMaximumDuration"
                    defaultMessage="Max. duration"
                  />
                </InputLabel>
                <Input
                  className={classes.textFieldSm}
                  error={
                    (!receivedPublishData.durationMax && location.highlights) ||
                    durationError.max
                  }
                  value={durationMax || ''}
                  variant="standard"
                  inputComponent={TextMaskCustom}
                  inputProps={{
                    ...(durationMax === '000 ' || durationMax === '000 ч 00 мин'
                      ? { className: classes.maskedInputNotTouched }
                      : {}),
                    hoursWord,
                    minutesWord,
                  }}
                  onChange={handleChange(setDurationMax, 'durationMax')}
                />
                {durationError.max && (
                  <FormHelperText error className={classes.formHelperText}>
                    {durationErrorText}
                  </FormHelperText>
                )}
              </FormControl>
            </React.Fragment>
          ) : (
            <FormControl>
              <InputLabel>
                <FormattedMessage
                  id="StudioCreateProduct.tourLocationsDuration"
                  defaultMessage="Duration"
                />
              </InputLabel>
              <Input
                className={classes.textFieldSm}
                error={!receivedPublishData.duration && location.highlights}
                value={durationMax || ''}
                variant="standard"
                inputComponent={TextMaskCustom}
                inputProps={{
                  ...(durationMax === '000 ' || durationMax === '000 ч 00 мин'
                    ? { className: classes.maskedInputNotTouched }
                    : {}),
                  hoursWord,
                  minutesWord,
                }}
                onChange={handleChange(setDurationMax, 'duration')}
              />
            </FormControl>
          )}
        </Paper>
        {!checkFullness('locations') && (
          <Typography className={classes.helperFullness} variant="body1">
            <FormattedMessage
              id="StudioCreateProduct.tourLocationsRemainsToFill"
              defaultMessage="Remaining sections to fill in: "
            />
            {handleFullnessText()}
          </Typography>
        )}
        <TourContentFooterButtons
          leftLink={`/quest/${currentQuestId}/events/${events.length - 1}`}
          rightLink={
            location.highlights
              ? `/quest/${currentQuestId}/check`
              : `/quest/${currentQuestId}/highlights`
          }
          withRedirectToTourCheck={location.highlights}
          leftBtnVisible={!location.highlights}
        />
      </Container>
    </section>
  );
};

function mapStateToProps() {
  return (state) => {
    return {
      publishing: state.publishing,
      currentQuestId: state.quests.currentQuestId,
    };
  };
}

function mapDispatchToProps(dispatch) {
  return {
    publishingActions: bindActionCreators(publishingActionCreators, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(TourLocations)));
