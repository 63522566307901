import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FillingEvent } from './TutorialsName';
import defaultStepProps from './defaultStepProps';

const FillingEventTutorial = (props) => {
  return {
    name: FillingEvent,
    run: props.run,
    steps: [
      {
        title: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step1.title"
            defaultMessage="Add the media files"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step1.text"
            defaultMessage="You can add the images (no more than 10 pictures) or animation to the event. We recommend such services as {tinypicName} to reduce the «total weight» of the tour."
            values={{
              tinypicName: (
                <a href="http://tinypic.com" target="_blank" rel="noreferrer">
                  <b style={{ color: '#ffffff' }}>Tinypic</b>
                </a>
              ),
            }}
          />
        ),
        target: '#img',
        placement: 'top',
        ...defaultStepProps,
      },
      {
        title: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step2.title"
            defaultMessage="Text"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step2.text"
            defaultMessage="In the column «Text» you tell the stories, give instructions, where to go and what to do, ask questions and describe assignments. Try writing something and using the text editor."
          />
        ),
        target: '#text',
        placement: 'right',
        ...defaultStepProps,
      },
      {
        title: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step3.title"
            defaultMessage="Limit by the number of characters"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step3.text"
            defaultMessage="It's inconvenient for users to read large texts. This identifier helps the author to track the number of typed/recommended letters or symbols."
          />
        ),
        target: '.TextLimit__block',
        placement: 'bottom',
        ...defaultStepProps,
      },
      {
        title: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step4.title"
            defaultMessage="Audio"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step4.text"
            defaultMessage="Add audio (up to 10 MB). Maybe it will be your favorite song?"
          />
        ),
        target: '#audio',
        placement: 'top',
        ...defaultStepProps,
      },
      {
        title: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step6.title"
            defaultMessage="It's time to create the next event"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step6.text"
            defaultMessage="Enter the name of the next event and press {enterText}"
            values={{
              enterText: <b>Enter</b>,
            }}
          />
        ),
        target: '.EventControls__triggers--add',
        placement: 'top',
        ...defaultStepProps,
      },
      {
        title: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step9.title"
            defaultMessage="The conditions for the next event - «At the certain time»"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step9.text"
            defaultMessage="Under this condition, the following event occurs at the time you specified. Suitable for games, not suitable for tours."
          />
        ),
        target: '.EventTime__module',
        placement: 'top',
        ...defaultStepProps,
      },
      {
        title: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step10.title"
            defaultMessage="The conditions for the next event - «After answering the question»"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step10.text"
            defaultMessage="Also, to open the next event, the user can answer your question. There are two types of questions."
          />
        ),
        target: '.EventChoice__module',
        placement: 'top',
        ...defaultStepProps,
      },
      {
        title: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step11.title"
            defaultMessage="Multiple choice questions"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step11.text"
            defaultMessage="The first type is the multiple choice questions. The user selects one of the answers that you offer. Create several answers to the question you have formulated and tick the correct one."
          />
        ),
        target:
          '.EventChoice__module>.EventModule__content--on>.EventChoice__head>.EventChoice__view:first-child',
        placement: 'top',
        ...defaultStepProps,
      },
      {
        title: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step12.title"
            defaultMessage="Free answer in the text field"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step12.text"
            defaultMessage="The second type of questions — free answer in the text field that the user enters himself. Write down in the line of the options for writing the correct answer via {enterText}. Case insensitive."
            values={{
              enterText: <b>Enter</b>,
            }}
          />
        ),
        target:
          '.EventChoice__module>.EventModule__content--on>.EventChoice__head>.EventChoice__view:last-child',
        placement: 'top',
        ...defaultStepProps,
      },
      {
        title: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step13.title"
            defaultMessage="The events appear in the content"
          />
        ),
        content: (
          <FormattedMessage
            id="Tutorials.FillingEvent.Step13.text"
            defaultMessage="Excellent, your skill level is growing! Connecting events with different conditions and parameters, you can create a unique script for the tour. As you can see, new events appear in the contents of the tour."
          />
        ),
        target: '.nav',
        placement: 'right',
        ...defaultStepProps,
        styles: {
          floater: {
            floater: {
              zIndex: 2700,
            },
          },
        },
        spotlightPadding: 0,
      },
    ],
  };
};

export default FillingEventTutorial;
