import React from 'react';

/**
 * Context for spreading state `isDemoOpen` prop
 * @type {Object}
 * @prop {Boolean} isPreviewShown - is demo open
 * @prop {Function} togglePreview - manages toggling `isPreviewShown` prop
 */
export const PreviewContext = React.createContext({
  isPreviewShown: false,
  togglePreview: () => {},
});

/**
 * Spreads `PreviewContext` into props
 * @param {React::Component} Component
 */
export default function withPreview(Component) {
  return function (props) {
    return (
      <PreviewContext.Consumer>
        {(value) => {
          return <Component {...value} {...props} />;
        }}
      </PreviewContext.Consumer>
    );
  };
}
