import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  mainSetsettings: {
    id: 'GenerateAiTourForm.language.setsettings',
    defaultMessage: 'Main setsettings',
  },
  englishLanguage: {
    id: 'GenerateAiTourForm.language.English',
    defaultMessage: 'English',
  },
  countryPlaceholder: {
    id: 'GenerateAiTourForm.placeholder.country',
    defaultMessage: 'Country',
  },
  cityPlaceholder: {
    id: 'GenerateAiTourForm.placeholder.city',
    defaultMessage: 'City',
  },
  categoryPlaceholder: {
    id: 'GenerateAiTourForm.placeholder.category',
    defaultMessage: 'Product category',
  },
  numberOfStopsPlaceholder: {
    id: 'GenerateAiTourForm.placeholder.numberOfStops',
    defaultMessage: 'Number of stops (steps with geopoint)',
  },
  numberOfStoriesPlaceholder: {
    id: 'GenerateAiTourForm.placeholder.numberOfStories',
    defaultMessage: 'Number of stories (steps without geopoint)',
  },
  durationHoursPlaceholder: {
    id: 'GenerateAiTourForm.placeholder.durationHours',
    defaultMessage: 'Duration, h',
  },
  routeLengthPlaceholder: {
    id: 'GenerateAiTourForm.placeholder.routeLength',
    defaultMessage: 'Route length',
  },
  topicPlaceholder: {
    id: 'GenerateAiTourForm.placeholder.topic',
    defaultMessage: 'Places, theme, mood, etc.',
  },
  stepNumberPlaceholder: {
    id: 'GenerateAiTourForm.placeholder.stepNumber',
    defaultMessage: 'Step number',
  },
  textOptionAllText: {
    id: 'GenerateAiTourForm.option.allText',
    defaultMessage: 'All text',
  },
  textOptionNavigation: {
    id: 'GenerateAiTourForm.option.navigation',
    defaultMessage: 'Navigation',
  },
  otherTextsPlaceholder: {
    id: 'GenerateAiTourForm.placeholder.otherTexts',
    defaultMessage: 'Text',
  },
  otherTextOptionAllOtherText: {
    id: 'GenerateAiTourForm.option.allOtherText',
    defaultMessage: 'All other text',
  },
  otherTextOptionIntroduction: {
    id: 'GenerateAiTourForm.option.introduction',
    defaultMessage: 'Introduction',
  },
  otherTextOptionConclusion: {
    id: 'GenerateAiTourForm.option.conclusion',
    defaultMessage: 'Conclusion',
  },
  otherTextOptionHighlights: {
    id: 'GenerateAiTourForm.option.highlights',
    defaultMessage: 'Highlights',
  },
  otherTextOptionOverview: {
    id: 'GenerateAiTourForm.option.overview',
    defaultMessage: 'Overview',
  },
  otherTextOptionTitle: {
    id: 'GenerateAiTourForm.option.title',
    defaultMessage: 'Title',
  },
  otherTextOptionDescription: {
    id: 'GenerateAiTourForm.option.description',
    defaultMessage: 'Description',
  },
  generateButton: {
    id: 'GenerateAiTourForm.option.GenerateButton',
    defaultMessage: 'Generate',
  },
  cancelButton: {
    id: 'GenerateAiTourForm.popup.CancelButton',
    defaultMessage: 'Cancel',
  },

  closeButton: {
    id: 'GenerateAiTourForm.popup.closeButton',
    defaultMessage: 'Close',
  },

  generationOccurs: {
    id: 'GenerateAiTourForm.popup.generationOccurs',
    defaultMessage: 'Generation occurs',
  },

  popupDescription: {
    id: 'GenerateAiTourForm.popup.popupDescription',
    defaultMessage: 'It can take some time',
  },

  popupDescriptionHelper: {
    id: 'GenerateAiTourForm.popup.popupDescriptionHelper',
    defaultMessage:
      'After the update, the information you previously entered will be deleted',
  },
  stepsLabel: {
    id: 'GenerateAiTourForm.toggleButton.steps',
    defaultMessage: '1. Steps',
  },
  storiesLabel: {
    id: 'GenerateAiTourForm.toggleButton.stories',
    defaultMessage: '2. Stories',
  },
  stepTextsLabel: {
    id: 'GenerateAiTourForm.toggleButton.stepTexts',
    defaultMessage: '3. Step Texts',
  },
  stepPhotosLabel: {
    id: 'GenerateAiTourForm.toggleButton.Photos',
    defaultMessage: '4. Photos',
  },
  otherTextsLabel: {
    id: 'GenerateAiTourForm.toggleButton.otherTexts',
    defaultMessage: '5. Other Texts',
  },
  stepAudioLabel: {
    id: 'GenerateAiTourForm.toggleButton.stepAudio',
    defaultMessage: '6. Audio',
  },
  musicLabel: {
    id: 'GenerateAiTourForm.toggleButton.music',
    defaultMessage: '7. Music',
  },
  createStopsTitle: {
    id: 'GenerateAiTourForm.createStops.title',
    defaultMessage: 'Create stops',
  },
  createStopsDescription: {
    id: 'GenerateAiTourForm.createStops.description',
    defaultMessage: 'All stops in the tour will be generated from scratch',
  },
  createStoriesTitle: {
    id: 'GenerateAiTourForm.createStories.title',
    defaultMessage: 'Create stories',
  },
  createStoriesDescription: {
    id: 'GenerateAiTourForm.createStories.description',
    defaultMessage: 'All stories in the tour will be generated from scratch',
  },
  createTextTitle: {
    id: 'GenerateAiTourForm.createText.title',
    defaultMessage: 'Create text',
  },
  createPhotosTitle: {
    id: 'GenerateAiTourForm.createPhotos.title',
    defaultMessage: 'Photos',
  },
  createPhotosDescription: {
    id: 'GenerateAiTourForm.createPhotos.description',
    defaultMessage:
      'Add new photos to the tour steps. The function is available for steps with geopoints. Generation only for an individual step.',
  },
  createTextDescription: {
    id: 'GenerateAiTourForm.createText.description',
    defaultMessage: 'The main text of all steps will be generated from scratch',
  },
  createTextDescriptionSeparate: {
    id: 'GenerateAiTourForm.createText.descriptionSeprate',
    defaultMessage:
      'The main text of the audio guide has been created in steps. Navigation will only be created for individual steps',
  },
  additionalTextTitle: {
    id: 'GenerateAiTourForm.additionalText.title',
    defaultMessage: 'Additional text',
  },
  additionalTextDescription: {
    id: 'GenerateAiTourForm.additionalText.description',
    defaultMessage:
      'Create new texts for Overview, Introduction, Conclusion and Highlights',
  },
  createAudioTitle: {
    id: 'GenerateAiTourForm.createAudio.title',
    defaultMessage: 'Create Audio',
  },
  createAudioDescription: {
    id: 'GenerateAiTourForm.createAudio.description',
    defaultMessage: 'Create voiceover for the steps',
  },
  musicTitle: {
    id: 'GenerateAiTourForm.music.title',
    defaultMessage: 'Music',
  },
  musicDescription: {
    id: 'GenerateAiTourForm.music.description',
    defaultMessage: 'Create background music for steps',
  },
  allSteps: {
    id: 'GenerateAiTourForm.music.allSteps',
    defaultMessage: 'All steps',
  },
  BasedStopName: {
    id: 'GenerateAiTourForm.music.BasedStopName',
    defaultMessage: 'Based on stop name',
  },
  BasedStopCoordinates: {
    id: 'GenerateAiTourForm.music.BasedStopCoordinates',
    defaultMessage: 'Based on stop coordinates',
  },
  separateSteps: {
    id: 'GenerateAiTourForm.music.separateSteps',
    defaultMessage: 'Separate steps',
  },
  individualSteps: {
    id: 'GenerateAiTourForm.music.individualSteps',
    defaultMessage: 'Individual steps',
  },
  voiceActing: {
    id: 'GenerateAiTourForm.music.voiceActing',
    defaultMessage: 'Voice acting',
  },
  previewOfTheVoice: {
    id: 'GenerateAiTourForm.music.previewOfTheVoice',
    defaultMessage: 'Preview of the voice',
  },

  makeChangesTitle: {
    id: 'GenerateAiTourForm.content.makeChanges.title',
    defaultMessage: 'Make changes?',
  },
  makeChangesDescription: {
    id: 'GenerateAiTourForm.content.makeChanges.description',
    defaultMessage:
      'You want to make changes to the main parameters of the tour, this will update all tour information',
  },
  tourGeneratedTitle: {
    id: 'GenerateAiTourForm.content.tourGenerated.title',
    defaultMessage: 'Tour generated',
  },
  tourGeneratedDescription: {
    id: 'GenerateAiTourForm.content.tourGenerated.description',
    defaultMessage:
      'Your tour has been generated, check the generated steps and select suitable photos',
  },
  fillInTheFieldsTitle: {
    id: 'GenerateAiTourForm.content.fillInTheFields.title',
    defaultMessage: 'Fill in the fields',
  },
  fillInTheFieldsDescription: {
    id: 'GenerateAiTourForm.content.fillInTheFields.description',
    defaultMessage:
      'Generate your tour with the parameters you specify. You cannot change the tour parameters while it is being generated.',
  },
  stepTextHelper: {
    id: 'GenerateAiTourForm.content.fillInTheFields.stepTextHelper',
    defaultMessage: 'Last two paragraphs with navigation',
  },
  fieldRequired: {
    id: 'GenerateAiTourForm.validation.fieldRequired',
    defaultMessage: 'This field is required.',
  },
  valueCannotBeMoreThan: {
    id: 'GenerateAiTourForm.validation.valueCannotBeMoreThan',
    defaultMessage: 'Value cannot be more than ',
  },
  valueCannotBeZero: {
    id: 'GenerateAiTourForm.validation.valueCannotBeZero',
    defaultMessage: 'The value cannot be 0.',
  },
  helperErrorTitle: {
    id: 'GenerateAiTourForm.content.helperError',
    defaultMessage: 'An error has occurred',
  },
  helperErrorDiscription: {
    id: 'GenerateAiTourForm.content.helperErrorDiscription',
    defaultMessage: 'Content cant be generated. Please try again.',
  },
  AItoolsButtonQuestForm: {
    id: 'TranslationForm.content.AItoolsButtonQuest',
    defaultMessage: 'AI Form',
  },
  AItoolsButtonQuestTranslation: {
    id: 'TranslationForm.content.AItoolsButtonQuestTranslation',
    defaultMessage: 'Translation',
  },
  sourceLanguage: {
    id: 'TranslationForm.content.sourceLanguage',
    defaultMessage: 'Source language',
  },
  tourLanguage: {
    id: 'TranslationForm.content.tourLanguage',
    defaultMessage: 'Tour Language',
  },
  translatedLanguages: {
    id: 'TranslationForm.content.translatedLanguages',
    defaultMessage: 'Translated Languages',
  },
  Synchronized: {
    id: 'TranslationForm.content.Synchronized',
    defaultMessage: 'Synchronized',
  },
  noSynchronized: {
    id: 'TranslationForm.content.noSynchronized',
    defaultMessage: 'No Synchronized',
  },
  addTranslation: {
    id: 'TranslationForm.content.addTranslation',
    defaultMessage: 'Add Translation',
  },
  seeMore: {
    id: 'TranslationForm.content.seeMore',
    defaultMessage: 'See more',
  },
  seeLess: {
    id: 'TranslationForm.content.seeLess',
    defaultMessage: 'See less',
  },
  update: {
    id: 'TranslationForm.content.update',
    defaultMessage: 'Update',
  },
  stepAudio: {
    id: 'TranslationForm.content.stepAudio',
    defaultMessage: 'Step audio',
  },
  validationIntroAndOutro: {
    id: 'TranslationForm.content.validationIntroAndOutro',
    defaultMessage: 'Enter a step between Intro and Outro.',
  },
  ChangeSource: {
    id: 'GenerateAiTourForm.content.ChangeSource',
    defaultMessage: 'Change source',
  },
  AddSource: {
    id: 'GenerateAiTourForm.content.AddSource',
    defaultMessage: 'Add source',
  },
  EnterTextHere: {
    id: 'GenerateAiTourForm.content.EnterTextHere',
    defaultMessage: 'Enter text here...',
  },
  ConfirmTheChoice: {
    id: 'GenerateAiTourForm.content.ConfirmTheChoice',
    defaultMessage: 'Confirm the choice?',
  },
  ChangesMadeWillNotBeSaved: {
    id: 'GenerateAiTourForm.content.ChangesMadeWillNotBeSaved',
    defaultMessage: 'Changes made will not be saved',
  },
  ConfirmButton: {
    id: 'GenerateAiTourForm.content.ConfirmButton',
    defaultMessage: 'Confirm',
  },
  GoogleSource: {
    id: 'GenerateAiTourForm.content.GoogleSource',
    defaultMessage: 'Google',
  },
  TripadvisorSource: {
    id: 'GenerateAiTourForm.content.TripadvisorSource',
    defaultMessage: 'Tripadvisor',
  },
  UnsplashSource: {
    id: 'GenerateAiTourForm.content.UnsplashSource',
    defaultMessage: 'Unsplash photo',
  },
  PhotoSource: {
    id: 'GenerateAiTourForm.content.PhotoSource',
    defaultMessage: 'Photo source',
  },
  TextSource: {
    id: 'GenerateAiTourForm.content.TextSource',
    defaultMessage: 'Text source',
  },
});
