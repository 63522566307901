import TutorialsArray from '../constants/tutorials/TutorialsArray';
import Storage from './Storage';

const TUTORIAL_CACHE_ITEM_NAME = '__tutorial_cache';

export default class CachedTutorial {
  static async getCache() {
    try {
      return await Storage.getItem(TUTORIAL_CACHE_ITEM_NAME);
    } catch (error) {
      throw new Error(`Error loading cache${error}`);
    }
  }

  static getCacheSync() {
    try {
      return Storage.getItem(TUTORIAL_CACHE_ITEM_NAME);
    } catch (error) {
      throw new Error(`Error loading cache${error}`);
    }
  }

  static getFinishedTutorialFromCache(name) {
    const cache = CachedTutorial.getCacheSync();
    if (!cache) {
      return null;
    } else if (cache[name]) {
      return !!cache[name].finished;
    }
    return false;
  }

  static getUnFinishedTutorialsCountFromCache() {
    const cache = CachedTutorial.getCacheSync();
    if (!cache) {
      return TutorialsArray.length;
    }
    return TutorialsArray.reduce((count, tutorial) => {
      if (cache[tutorial.name]) {
        if (cache[tutorial.name].finished) {
          return (count -= 1);
        }
        return count;
      }
      return count;
    }, TutorialsArray.length);
  }

  static async getTutorialDataFromCache(key) {
    const cache = await CachedTutorial.getCache();
    if (!cache || !cache[key]) {
      return null;
    }
    return cache[key].skipped || cache[key].finished;
  }

  static async getFromCache(key) {
    const cache = await CachedTutorial.getCache();
    if (!cache || !cache[key]) {
      return null;
    }
    return cache[key];
  }

  static async saveToCache(tutorial) {
    const { name, ...value } = tutorial;
    const cachedResult = await CachedTutorial.getFromCache(name);
    if (!cachedResult) {
      const cache = await CachedTutorial.getCache();

      Storage.setItem(TUTORIAL_CACHE_ITEM_NAME, {
        ...cache,
        [name]: value,
      });
    } else {
      const cache = await CachedTutorial.getCache();
      Storage.setItem(TUTORIAL_CACHE_ITEM_NAME, {
        ...cache,
        [name]: { ...cache[name], ...value },
      });
    }
  }
}
