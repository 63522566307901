import React from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { CONTROL_PADDING } from '../withOptions';

/**
 * JSS styles for `TableOptionsButton` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 'unset',
    padding: `${CONTROL_PADDING[0]}px ${CONTROL_PADDING[1]}px`,
    color: theme.palette.text.secondary,
    textTransform: 'none',
  },
}));

/**
 * Button, displayed in the `withOption` bar
 * @param {Object} $
 * @param {React::JSX?} $.startIcon - will be shown on all layouts
 * @param {React::JSX} $.children - on "sm" breakpoint will be hidden if `$.startIcon` presents
 * @param {...Object} $.buttonProps - props passed to `material-ui::Button` component
 */
export default function TableOptionsButton({
  className,
  startIcon,
  children,
  ...buttonProps
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles();

  return (
    <Button
      className={classNames(styles.root, className)}
      size="small"
      {...(isMobile ? {} : { startIcon })}
      {...buttonProps}
    >
      {isMobile ? (
        startIcon
      ) : (
        <Typography variant="body2">{children}</Typography>
      )}
    </Button>
  );
}
