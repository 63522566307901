/**
 * Default pricingCategoryId if product has only one priceLevel
 */
export const DEFAULT_PRICING_CATEGORY_ID = '1';

/**
 * Array of all category ticket labels translation strings
 * for correct merging with other categories
 * their cost adds up with other tickets if needed
 */
export const ALL_CATEGORY_TICKET_LABELS = ['All tickets', 'Все билеты'];
