import React from 'react';
import classNames from 'classnames';
import './Spinner.css';

export default ({ className }) => (
  <div className={classNames('Spinner', className)}>
    <div />
    <div />
    <div />
    <div />
  </div>
);
