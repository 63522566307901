import React from 'react';
import './Hijacked.css';

export default ({ userName, release }) => (
  <div className="Hijacked">
    <span className="Hijacked__alert">
      You are working on behalf of <b>{userName}</b>
    </span>
    <button className="Hijacked__release" onClick={release}>
      Release
    </button>
  </div>
);
