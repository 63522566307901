import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import PopupButtons from '../PopupButtons';

/**
 * Messages for `PopupRemove` component
 * @type {Object}
 */
const messages = defineMessages({
  cancel: { id: 'PopupRemove.cancel', defaultMessage: 'Cancel' },
});

/**
 * Adds to `Popup` component "Cancel" and "Action" buttons
 * @param {React::Component} Component - modified `Popup` component
 * @returns {React::Component}
 */
export default function withAction(Component) {
  /**
   * `Popup` component with "Cancel" and "Action" buttons
   * @param {Object} $
   * @param {String} $.variant - `PopupButtons` variant prop
   * @param {React::JSX} $.actionText - content of the "Action" button
   * @param {React::JSX} $.children - components to be rendered between title and buttons
   * @param {Function} $.toggleOpen - toggles popup `isOpen` state
   * @param {Function?} $.onAction - user clicked on "Action" button
   */
  return injectIntl(function PopupAction({
    actionText,
    variant = 'outlinedAction',
    children,
    toggleOpen = () => {},
    onAction: onActionExternal = () => {},
    intl: { formatMessage },
    ...popupProps
  }) {
    /**
     * Cancel button was clicked by user
     */
    const onCancel = () => {
      toggleOpen(!popupProps.isOpen);
    };

    /**
     * Action button was clicked by user
     */
    const onAction = () => {
      onActionExternal();
    };

    return (
      <Component
        shouldCloseOnEsc
        handleCloseClick={() => toggleOpen(!popupProps.isOpen)}
        {...popupProps}
      >
        <Typography variant="body1">{children}</Typography>
        <PopupButtons
          variant={variant}
          buttons={[
            { onClick: onCancel, children: formatMessage(messages.cancel) },
            { onClick: onAction, children: actionText },
          ]}
        />
      </Component>
    );
  });
}
