/**
 * Checks if event content of type `type` has field `visibility: true`
 * @param {Object} event - event to check
 * @param {String} type - type of event content (available are `"image"`, `"html"`, `"text"`, `"choice"`, `"audio"`)
 * @returns {Array[Number]} - array with indexes of content with `visibility: false`
 */
export default function checkContentVisibility(event, type) {
  const found = [];
  event.content.forEach((content, contentIndex) => {
    if (content.contentType === type && !content.visible) {
      found.push(contentIndex);
    }
  });

  return found;
}
