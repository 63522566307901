import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import getValidationResults from '../../functions/validation/getValidationResults';
import { DragPointIcon, ErrorValidationIcon } from '../Icon';
import { Mobile } from '../../functions/queries';
import getParams from '../../functions/getParams';
import { paths } from '../../constants/paths';
import checkTriggers from '../../functions/validation/checkTriggers';
import './EventNavLink.css';

function checkChoiceSet(event) {
  const { content } = event;
  const { data: choice = {} } =
    content.find((item) => item.contentType === 'choice') || {};
  const { answer } = choice;
  return (
    choice.options &&
    choice.options.length &&
    !choice.options.every((opt) => !opt) &&
    answer !== -1
  );
}

function getEventImages(event) {
  const item = event.content.find(
    (item) => item.contentType === 'image' && item.visible
  );
  if (!item) return [];
  return item.data;
}

function checkOptChoiceSet(event) {
  const { content } = event;
  const { data: choice = {} } =
    content.find((item) => item.contentType === 'choice') || {};
  const { answer } = choice;
  return (
    choice.options &&
    choice.options.length &&
    !choice.options.every((opt) => !opt) &&
    answer === -1
  );
}

const Link = ({ children, snapshot = {}, event = {}, ...props }) => {
  return (
    <NavLink
      className={classNames(
        'nav__link EventNavLink',
        snapshot.isDragging && 'EventNavLink--dragging'
      )}
      activeClassName="nav__link--active"
      isActive={(match, { pathname }) =>
        parseFloat(getParams(pathname, paths).eventOrder) === event.order
      }
      {...props}
    >
      <div className="EventNavLink__wrapper">
        {children}
        {!isEmpty(snapshot) && <DragPointIcon />}
      </div>
      <div className="EventNavLink__images">
        {getEventImages(event).map((img) => (
          <img
            key={img}
            className="EventNavLink__image-preview"
            src={img}
            alt=""
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '3px',
            }}
          />
        ))}
      </div>
    </NavLink>
  );
};

class EventNavLink extends Component {
  getStyle(draggableStyle, isDragging) {
    return draggableStyle;
  }

  renderErrorIcon = () => {
    const { event, next } = this.props;
    const validationResults = getValidationResults(event, next);
    if (!validationResults.isEventValid) {
      return <ErrorValidationIcon />;
    }
  };

  getLink = (questId, order, event, next) => {
    const link = `/quest/${questId}/events/${order}/`;
    const validationResults = getValidationResults(event, next);
    if (!validationResults.isEventValid) {
      const notValidBlocks = validationResults.eventErrors;
      return `${link + notValidBlocks[0].anchor}`;
    }
    return link;
  };

  getTrigger = (event, next) => {
    let triggerName;

    if (!next || !event) {
      return null;
    }

    const {
      isGeoTriggerSet,
      isTimeTriggerSet,
      isChoiceSet,
      isARTriggerSet,
      isNextButtonSet,
    } = checkTriggers(event, next);

    if (isGeoTriggerSet) {
      triggerName = 'geo';
    } else if (isARTriggerSet) {
      triggerName = 'ar';
    } else if (isTimeTriggerSet) {
      triggerName = 'time';
    } else if (isChoiceSet) {
      if (checkOptChoiceSet(event)) {
        triggerName = 'opt';
      } else if (checkChoiceSet(event)) {
        triggerName = 'ch';
      }
    } else if (isNextButtonSet) {
      triggerName = 'nx';
    }

    return (
      <span
        style={{
          fontSize: '10px',
          textTransform: 'uppercase',
          marginRight: '5px',
          fontWeight: 'bold',
        }}
      >
        {triggerName}
      </span>
    );
  };

  render() {
    const {
      questId,
      event,
      event: { id, order, title },
      isDraggable,
      next,
      onClick,
      ...otherProps
    } = this.props;
    const link = this.getLink(questId, order, event, next);

    if (!isDraggable) {
      return (
        <Link to={link} event={event}>
          {title}
          {this.renderErrorIcon()}
        </Link>
      );
    }

    return (
      <Mobile>
        {(isMobile) =>
          isMobile ? (
            <Link to={link} event={event} onClick={onClick}>
              {title}
              {this.renderErrorIcon()}
            </Link>
          ) : (
            <Draggable draggableId={id}>
              {(provided, snapshot) => (
                <Fragment>
                  <div
                    ref={provided.innerRef}
                    {...provided.dragHandleProps}
                    style={this.getStyle(
                      provided.draggableStyle,
                      snapshot.isDragging
                    )}
                  >
                    <Link
                      to={link}
                      snapshot={snapshot}
                      event={event}
                      {...otherProps}
                    >
                      <div className="EventNavLink__text">
                        <span className="EventNavLink__order">{order + 1}</span>
                        {this.getTrigger(event, next)}
                        {title}
                      </div>
                      {this.renderErrorIcon()}
                    </Link>
                  </div>
                  {provided.placeholder}
                </Fragment>
              )}
            </Draggable>
          )
        }
      </Mobile>
    );
  }
}

EventNavLink.propTypes = {
  questId: PropTypes.number.isRequired,
  event: PropTypes.shape({
    // TODO: create shared shape of event
    id: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
};

export default EventNavLink;
