import { defineMessages } from 'react-intl';

/**
 * Messages related to the "Next event" button
 * @type {Object}
 */
export default defineMessages({
  placeholder: {
    id: 'events.nextPlaceholder',
    defaultMessage: "I'm here!",
  },
  placeholderButton: {
    id: 'events.nextPlaceholderButton',
    defaultMessage: 'Next step',
  },
  placeholderPenultimate: {
    id: 'events.nextPlaceholderPenultimate',
    defaultMessage: 'Finish tour',
  },
});
