import { buffers, eventChannel, END } from 'redux-saga';
import { getCookie } from './manageCookie';
import Api from './Api';

export default function createUploadFormChannel(endpoint, form) {
  return eventChannel((emitter) => {
    const xhr = Api.createXHR(endpoint.method, endpoint.url);
    const onProgress = (e) => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };
    const onFailure = (err) => {
      emitter({ err });
      emitter(END);
    };
    xhr.setRequestHeader('X-CSRFToken', getCookie('csrftoken'));
    xhr.withCredentials = true;
    xhr.upload.addEventListener('progress', onProgress);
    xhr.upload.addEventListener('error', onFailure);
    xhr.upload.addEventListener('abort', onFailure);
    xhr.onreadystatechange = () => {
      const { readyState, status, responseText } = xhr;
      if (readyState === 4) {
        if (status === 200) {
          emitter({ success: true, json: JSON.parse(responseText) });
          emitter(END);
        } else {
          onFailure(null);
        }
      }
    };
    xhr.send(form);
    emitter({ xhr });
    return () => {
      xhr.upload.removeEventListener('progress', onProgress);
      xhr.upload.removeEventListener('error', onFailure);
      xhr.upload.removeEventListener('abort', onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
}
