/**
 * Helps to overload JSS when overloading `jss` are put at the bottom of the CSS inheritance list
 * @param {Object} jss - JSS style object to take upper in inheritance list
 * @param {String} className - CSS class name of the overloading element
 * @returns {Object} - JSS with stronger classes
 */
export default function overloadJss(jss, className) {
  return Object.keys(jss).reduce(
    (acc, name) => ({ ...acc, [name]: { [`&.${className}`]: jss[name] } }),
    {}
  );
}
