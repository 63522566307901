import React, { Fragment } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import { newFieldValue, toFieldValues } from '..';
import useBreakpoint from '../../../functions/layout/useBreakpoint';

/**
 * JSS styles for `TextForm` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  removeIcon: {
    minWidth: 0,
    color: theme.palette.text.disabled,
  },
  addButton: {
    '--add-button-padding-h': '8px',
    minWidth: 0,
    marginTop: '-4px',
    marginLeft: 'calc(-1 * var(--add-button-padding-h))',
    paddingLeft: 'var(--add-button-padding-h)',
    paddingRight: 'var(--add-button-padding-h)',
  },
}));

/**
 * Mod for `TextForm` adding ability to add/remove fields automatically
 * @param {React::Component} Component - component description for overloading
 */
export default function withFieldsExtension(Component) {
  /**
   * `TextForm` component with ability to add/remove fields automatically
   * @param {Object} $
   * @param {Number} $.min - min amount of the fields (default is `0`)
   * @param {Number} $.max - max amount of fields can be added (default amount is `5`)
   * @param {Function} $.onFieldRemoved - `TextForm` `formik::Field` element was added
   * @param {...Object} $.textFormProps - props passed to the original `TextForm` component
   */
  return function ({
    min = 0,
    max = 5,
    initialValues: initialValuesUnsafe = [],
    fieldProps: { extend: extendField, ...fieldProps } = {},
    onFieldRemoved = () => {},
    children = () => null,
    ...textFormProps
  }) {
    const styles = useStyles();
    const layout = useBreakpoint(['sm']);

    /**
     * Converting `initialValuesUnsafe` values for usage in `formik::Formik`
     * Appending to the array extra fields to fit `min` fields amount
     */
    const initialValues = toFieldValues(initialValuesUnsafe);
    if (initialValues.length < min) {
      initialValues.splice(
        initialValues.length,
        0,
        ...Array(Number(min) - initialValues.length).fill(newFieldValue(''))
      );
    }

    const removeField = (index, arrayHelpers, callback) => {
      arrayHelpers.remove(index);
      callback(index);
    };

    /**
     * Renders field with "Delete" button
     * @param {Object} textFormProps - props for `formik::Field` generated by `TextForm` component
     * @param {Object} arrayHelpers - `formik::FieldArray` helpers
     */
    const extendThisField = (prevFieldProps, extendFieldProps, helpers) => {
      let endGap = null;

      if (helpers.values.length > min) {
        const { index, arrayHelpers, values } = helpers;
        const buttonProps = {
          disabled: values.length <= min,
          onClick: (e) =>
            removeField(index, arrayHelpers, () =>
              onFieldRemoved(
                index,
                values.map((v) => v.text)
              )
            ),
        };
        endGap =
          layout !== 'xs' ? (
            <Button color="primary" {...buttonProps}>
              <FormattedMessage
                id="TextFormFieldsExtension.remove"
                defaultMessage="Remove"
              />
            </Button>
          ) : (
            <Button className={styles.removeIcon} size="small" {...buttonProps}>
              <DeleteIcon />
            </Button>
          );
      }

      return extendFieldProps({ endGap }, extendField, helpers);
    };

    return (
      <Component
        initialValues={initialValues}
        fieldProps={{ ...fieldProps, extend: extendThisField }}
        {...textFormProps}
      >
        {(helpers) => {
          const { values, arrayHelpers } = helpers;
          const allFieldsFilled = values.reduce(
            (acc, v) => (acc ? !!v.text : acc),
            true
          );

          return (
            <Fragment>
              {children(helpers)}
              <Button
                className={styles.addButton}
                size="small"
                color="primary"
                disabled={values.length >= max || !allFieldsFilled}
                onClick={() =>
                  values.length < max && arrayHelpers.push(newFieldValue(''))
                }
              >
                <FormattedMessage
                  id="TextFormFieldsExtension.add"
                  defaultMessage="Add more"
                />
              </Button>
            </Fragment>
          );
        }}
      </Component>
    );
  };
}
