import sum from '../sum';

export default function calcQuestMeasureParam(legs, type) {
  const params = legs.reduce((legsMeasure, leg) => {
    const { value } = leg[type];

    return [...legsMeasure, value];
  }, []);

  return sum(params);
}
