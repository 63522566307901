/* eslint-disable react/sort-comp */
import React, { Fragment } from 'react';
import grey from '@material-ui/core/colors/grey';
import get from 'lodash/get';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactCrop from 'react-image-crop';
import crop from '../../functions/canvas/crop';
import '../ImageCrop/ReactCrop.css';
import './PictureEditor.css';

/**
 * Test for acceptable types by editor
 * @type {RegExp}
 */
export const ACCEPTABLE_TYPES = /^image\/(?!gif)/;

/**
 * Default type of `base64` encoding
 * @type {String}
 */
export const DEFAULT_TYPE = 'image/png';

/**
 * JSS styles for `PictureEditor` component
 * @type {Function}
 */
function generateStyles(theme) {
  return {
    root: {
      display: 'flex',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    toolbar: {
      display: 'flex',
      flexDirection: 'column',
      padding: '32px',
      background: '#fff',
      '& > .grow': {
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          flexDirection: 'row',
          margin: '0 -32px',
          overflow: 'auto',
        },
      },
    },
    workspace: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px 40px 36px 29px',
      background: grey[50],
      [theme.breakpoints.down('sm')]: {
        padding: '20px',
        paddingBottom: '10px',
      },
    },
    title: {
      width: '100%',
      minHeight: '20px',
    },
    pictureContainer: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: '100%',
      minHeight: 0,
      margin: '70px 0 51px',
      [theme.breakpoints.down('sm')]: {
        margin: '20px 0 20px',
      },
    },
    picture: {
      display: 'flex',
      position: 'absolute',
      minHeight: 0,
    },
    status: {
      width: '100%',
      minHeight: '24px',
      lineHeight: '24px',
    },
    progress: {
      marginRight: '8px',
    },
  };
}

/**
 * Creates object for describe operation in the editor
 * @param {Object} name - operation id
 */
function createOperation(name) {
  return { name };
}

/**
 * Picture editor in popup
 * @param {Object} $
 * @param {*} $.resetOnChange - when this prop changes, state of the component will be reset to default (image - to `imageData`)
 * @param {String} $.imageData - base64 image to edit, if not given - popup with block is hidden
 * @param {String} $.fileType - type of the file to encode `base64` (default is `"image/png"`)
 * @param {React::JSX} $.children - items to be rendered in the tools bar
 */
class PictureEditor extends React.Component {
  /**
   * Ref to the block with available space for image to grow
   * @type {React::Ref}
   */
  pictureFrameRef = React.createRef();

  /**
   * Image element with image loaded
   * @type {Element}
   */
  image = null;

  constructor(props) {
    super(props);
    this.state = this.initialState();
  }

  /**
   * Generates empty base64 image to be shown in the editor while `imageData` will not be passed
   * Takes in count current `PictureEditor::props::fileType`
   * @returns {String}
   */
  templateData() {
    const { fileType = DEFAULT_TYPE } = this.props;
    return `data:${fileType};base64,`;
  }

  /**
   * Sets crop field size to fill image
   * @return {Object}
   */
  initialCrop() {
    const aspect = this.props.cropAspect;
    return { unit: '%', x: 0, y: 0, width: 100, height: 100, aspect };
  }

  /**
   * Keeps initial state of the component
   * @return {Object}
   */
  initialState() {
    return {
      imageData: this.props.imageData || this.templateData(),
      operation: null,
      crop: this.initialCrop(),
    };
  }

  /**
   * Calls when window of browser was resized
   */
  onWindowResize = () => {
    this.setState({ crop: this.initialCrop() });
  };

  /**
   * Loads new image to editor
   * @param {String} base64 - new image string
   * @param {Object?} newState - additional stuff to add to state
   */
  loadImageData = (base64, newState = {}) => {
    this.setState({
      ...newState,
      imageData: base64,
      operation: createOperation('loading'),
    });
  };

  /**
   * Updates image
   * @param {String} promise - current operation
   * @param {String} newOperation - operation id (if equal to operation in progress - update will be performed)
   * @param {Boolean} urgent - if operation must be performed (other operations will be flushed)
   */
  operateImageData = (promise, newOperation, urgent) => {
    const { operation } = this.state;

    return new Promise((resolve, reject) => {
      if (!operation || urgent) {
        this.setState({ operation: createOperation(newOperation) });
        promise.then((base64) => {
          if (get(this.state, 'operation.name') === newOperation && base64) {
            this.loadImageData(base64);
            resolve({ base64 });
          } else {
            reject(new Error('Some error message'));
          }
        });
      } else {
        reject(new Error('Some error message'));
      }
    });
  };

  /**
   * Image was loaded into cropper block
   * Signals that operation ended - resets operation
   * @param {Element} image
   */
  onImageLoaded = (img) => {
    this.setState({ operation: null });
    this.image = img;
  };

  /**
   * Ends editing process:
   * - crops image
   * - calls `$.onEdited` callback
   */
  endEditing = () => {
    const { fileType = DEFAULT_TYPE } = this.props;
    this.operateImageData(
      crop(this.image, this.state.crop, { type: fileType }).then(
        ({ base64 }) => base64
      ),
      'crop',
      true
    ).then(({ base64 }) => {
      const { onEdited = () => {} } = this.props;
      onEdited(base64 || this.state.imageData, base64 !== this.props.imageData);
    });
  };

  /**
   * Component was installed in DOM
   */
  componentDidMount() {
    const { onApi = () => {} } = this.props;
    const { crop: initialCrop } = this.state;

    /**
     * Listen for window size changes for:
     * - preventing crop frame from increasing
     * - updating size of the picture
     */
    window.addEventListener('resize', this.onWindowResize);
    this.setState({ crop: this.initialCrop() });

    /**
     * Passing API to parent components and mods
     */
    const api = {};
    api.update = (base64, op) => this.operateImageData(base64, op);
    api.aspect = (aspect) =>
      this.setState({ crop: { ...initialCrop, aspect } });
    Object.defineProperty(api, 'base64', { get: () => this.state.imageData });
    Object.defineProperty(api, 'image', { get: () => this.image });
    Object.defineProperty(api, 'operation', {
      get: () => this.state.operation,
    });
    onApi(api);
  }

  /**
   * Component props were updated
   * @param {Object} prevProps - props from previous render
   */
  componentDidUpdate(prevProps) {
    const {
      imageData: imageDataUnsafe,
      resetOnChange,
      cropAspect,
    } = this.props;
    const imageData = imageDataUnsafe || this.templateData();

    /**
     * Reset component state to initial
     * If `$.resetOnChange` was changed
     */
    if (resetOnChange !== prevProps.resetOnChange) {
      this.loadImageData(imageData, this.initialState());
    }

    /**
     * Update image on canvas
     * If `$.imageData` was updated - update inner state
     */
    if (imageDataUnsafe !== prevProps.imageData) {
      this.loadImageData(imageData, this.initialState());
    }

    /**
     * Updating crop aspect
     */
    if (cropAspect !== prevProps.cropAspect) {
      const { crop: currentCrop } = this.state;
      const imageWidth = this.image ? this.image.width : 0;
      const imageHeight = this.image ? this.image.height : 0;

      let newWidth = 0;
      let newHeight = 0;

      if (cropAspect === 1) {
        newWidth = imageHeight;
        newHeight = imageHeight;
      } else if (cropAspect === 0.8) {
        newWidth = imageHeight * (4 / 5);
        newHeight = imageHeight;
      } else {
        newWidth = imageWidth;
        newHeight = imageHeight * (4 / 5);
      }

      const x = (imageWidth - newWidth) / 2;
      const y = (imageHeight - newHeight) / 2;
      this.setState({
        crop: {
          ...currentCrop,
          aspect: cropAspect,
          width: newWidth,
          height: newHeight,
          x,
          y,
        },
      });
    }
  }

  componentWillUnmount() {
    const { onApi = () => {} } = this.props;

    /**
     * Removing listeners set in `componentDidMount`
     */
    window.removeEventListener('resize', this.onWindowResize);

    /**
     * Removing API instances
     */
    onApi(null);
  }

  render() {
    const { classes, title, status, children } = this.props;
    const { operation } = this.state;
    const pictureMaxHeight = this.pictureFrameRef.current
      ? this.pictureFrameRef.current.getBoundingClientRect().height
      : 0;
    return (
      <div className={classNames('PictureEditor', classes.root)}>
        <div className={classNames('flex', 'grow', classes.workspace)}>
          <div className={classes.title}>{title || null}</div>
          <div ref={this.pictureFrameRef} className={classes.pictureContainer}>
            <ReactCrop
              className={classes.picture}
              src={this.state.imageData}
              crop={this.state.crop}
              imageStyle={{ maxHeight: `${pictureMaxHeight}px` }}
              onImageLoaded={this.onImageLoaded}
              onChange={(newCrop) => this.setState({ crop: newCrop })}
            />
          </div>
          <Typography
            className={classes.status}
            variant="body2"
            component="div"
          >
            {operation ? (
              <Fragment>
                <CircularProgress
                  className={classes.progress}
                  color="primary"
                  size="14px"
                />
                <FormattedMessage
                  id="PictureEditor.operation"
                  defaultMessage="Operation is in progress"
                />
              </Fragment>
            ) : null}
            {status && !operation ? status : null}
          </Typography>
        </div>
        <div className={classes.toolbar}>
          <div className="grow">{children}</div>
          <Button
            disabled={!!operation}
            color="primary"
            variant="contained"
            size="large"
            endIcon={<ArrowForwardIcon />}
            onClick={this.endEditing}
          >
            <FormattedMessage
              id="PictureEditor.continue"
              defaultMessage="Continue"
            />
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(generateStyles, { withTheme: true })(PictureEditor);
