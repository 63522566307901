import * as types from '../constants/ActionTypes';

export function alreadyReceiveQuests() {
  return (dispatch) => {
    return new Promise((resolve) => {
      resolve();
    }).then(() => dispatch({ type: types.ALREADY_RECEIVE_QUESTS }));
  };
}

export function fetchQuests() {
  return {
    type: types.FETCH_QUESTS,
  };
}

export function sendQuest() {
  return {
    type: types.SEND_QUEST,
  };
}

export function createQuest({ title, id }) {
  return {
    type: types.CREATE_QUEST,
    title,
    id,
  };
}

export function approveQuest(json) {
  return {
    type: types.APPROVE_QUEST,
    questId: json.data.id,
    lastModified: json.data.lastModified,
  };
}

export function shouldFetchQuests(state) {
  const { quests } = state;
  if (!quests.quests.length) {
    return true;
  } else if (quests.isFetching) {
    return false;
  }
}

export function fetchQuestsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchQuests(getState())) {
      return dispatch(fetchQuests());
    }
    return dispatch(alreadyReceiveQuests());
  };
}

/**
 * Trigers quest adding saga
 * @param {String} title - quest title
 * @param {Array[String]} eventTitles - titles for events to be added into new quest
 * @param {Array[Object]} events - event objects to be added into quest
 * @param {Boolean} select - if created quest must be selected as current opened
 * @param {Object} redirect - where to redirect after quest created successfully
 */
export function addQuest(
  title,
  eventTitles = [],
  events = [],
  selecting = true,
  redirect = {}
) {
  return {
    type: types.ADD_QUEST,
    title,
    eventTitles,
    events,
    selecting,
    redirect,
  };
}

export function copyQuest(id, title, redirect) {
  return {
    type: types.COPY_QUEST,
    id,
    title,
    redirect,
  };
}

export function deleteQuest(id, productId) {
  return {
    type: types.DELETE_QUEST,
    id,
    productId,
  };
}

export function renameQuest(questId, title) {
  return (dispatch) => {
    dispatch({
      type: types.RENAME_QUEST,
      questId,
      title,
    });
    dispatch(syncQuest(questId));
  };
}

export function syncQuest(
  questId,
  updatePlans = false,
  updateMediaSize = false,
  onSyncStart = () => {}
) {
  return {
    type: types.SYNC_QUEST,
    id: questId,
    updatePlans,
    updateMediaSize,
    onSyncStart,
  };
}

export function editEvent(action) {
  return (dispatch, getState) => {
    dispatch(action);
    const updatePlans =
      [types.DELETE_EVENT, types.ADD_EVENT].indexOf(action.type) !== -1;
    const updateMediaSize =
      [
        types.RECEIVE_IMAGES_URLS,
        types.REMOVE_EVENT_IMAGE,
        types.REMOVE_EVENT_IMAGES,
        types.RECEIVE_AUDIOS_URLS,
        types.REMOVE_EVENT_AUDIO,
        types.RECEIVE_IMAGEAR_URL,
        types.REMOVE_AR_TARGET,
      ].indexOf(action.type) !== -1;
    dispatch(
      syncQuest(
        action.questId,
        updatePlans,
        updateMediaSize,
        action.onSyncStart
      )
    );
  };
}

export function selectQuest(questId) {
  return {
    type: types.SELECT_QUEST,
    id: questId,
  };
}

/**
 * Calls saga for reordering events and managing route points bindings
 * @param {Object} action - will be passed to `REORDER_EVENTS_AND_POINTS` saga
 */
export function reorderEvents(action) {
  return {
    type: types.REORDER_EVENTS_AND_POINTS,
    ...action,
  };
}

/**
 * Calls saga for saving museum quest flag
 * @param {Object} action
 * @param {Number} action.questId - id of the quest
 * @param {Boolean} action.isMuseum - if current quest is museum one
 */
export function toggleMuseum({ questId, isMuseum }) {
  return {
    type: types.TOGGLE_MUSEUM_SAGA,
    questId: parseInt(questId, 10),
    isMuseum,
  };
}
