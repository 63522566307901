import React from 'react';

// https://github.com/formatjs/react-intl/issues/401
export default function linesToParagraphs(...nodes) {
  return nodes
    .map((node) =>
      typeof node === 'string'
        ? node.split('\n').map((text) => <p>{text}</p>)
        : node
    )
    .reduce((nodes, node) => nodes.concat(node), []);
}
