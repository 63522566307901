import React, { Component } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import AutosizeInput from 'react-input-autosize';
import './InputLight.css';

export default class InputLight extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props);
  }

  render() {
    const { className, autoresize, inputRef, ...props } = this.props;
    const passProps = { ...props, children: null };

    if (autoresize) {
      return (
        <AutosizeInput
          {...passProps}
          inputRef={inputRef}
          className={className}
          inputClassName="InputLight__input"
        />
      );
    }

    return (
      <input
        {...passProps}
        ref={inputRef}
        className={classNames('InputLight__input', className)}
      />
    );
  }
}
