import { defineMessages } from 'react-intl';

/**
 * Messages describing payment statuses
 * @type {Object}
 */
export default defineMessages({
  paid: {
    id: 'PaymentStatuses.paidUp',
    defaultMessage: 'Paid up',
  },
  processing: {
    id: 'PaymentStatuses.inProcessing',
    defaultMessage: 'In\u00A0processing',
  },
  cancelled: {
    id: 'PaymentStatuses.cancelled',
    defaultMessage: 'Cancelled',
  },
});
