/**
 * Makes columns be hidden by default
 * For use with `Table::withColumnsToggle`
 * @param {Array[TableHeaderData]} header - table header
 * @param {Array[String]} hidden - ids of the hidden columns
 * @returns {Array[TableHeaderData]}
 */
export default function defaultToggledColumns(header, hidden) {
  return hidden.length
    ? header.map((h) => ({
        ...h,
        defaultVisible: !hidden.includes(h.id),
      }))
    : header;
}
