import messagesEventTriggers from '../../constants/events/intlTriggers';

/**
 * Forms string with info about event content
 * @param {Array[String]} eventTriggers - event triggers from `getEventTriggers` function
 * @param {Function} formatMessage - `formatMessage` function from `react-intl`
 * @returns {Array[String]}
 */
export default function eventTriggersString(eventTriggers, formatMessage) {
  return Object.keys(eventTriggers)
    .map((triggerType) =>
      formatMessage(
        messagesEventTriggers[triggerType],
        triggerType === 'geo' ? { index: eventTriggers[triggerType] + 1 } : {}
      )
    )
    .filter((c) => c);
}
