import React, { Fragment } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';

/**
 * Margins between child elements in `EventControlsRoutePoint` component
 * @type {Object}
 */
const ITEM_MARGINS = {
  sm: 8,
  md: 16,
};

/**
 * JSS styles for `EventControlsRoutePoint` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  info: {
    marginBottom: '10px',
  },
  subtitle: {
    color: theme.palette.text.secondary,
  },
}));

/**
 * Select for binding route point to event
 * @param {Object} $
 * @param {String} $.className - additional CSS class
 * @param {String|Number} $.questId - id of the quest `eventId` relate to
 * @param {String} $.eventId - id of the event to edit
 * @param {Array[RoutePoint]} $.routePoint - route point bound to the current event
 */
export default function EventControlsRoutePoint({ className, routePoint }) {
  const styles = useStyles();
  return (
    <div className={className}>
      <Typography className={styles.info} variant="body1">
        {routePoint ? (
          <FormattedMessage
            id="EventControlsRoutePoint.infoHasGeopoint"
            defaultMessage="Audio guide will start when traveler will reach the route point:"
          />
        ) : (
          <FormattedMessage
            id="EventControlsRoutePoint.infoNoGeopoint"
            defaultMessage="Audio guide will start automatically if this step will be bound to step:"
          />
        )}
      </Typography>
      {routePoint ? (
        <Fragment>
          <Typography variant="body1">{routePoint.name}</Typography>
          <Typography className={styles.subtitle} variant="body2">
            {routePoint.address}
          </Typography>
        </Fragment>
      ) : null}
    </div>
  );
}
