import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MediaPlayer from '../MediaPlayer';
import ProgressCircle from '../ProgressCircle';
import { timeString } from '../../functions/dateUtils';
import './AudioPlayer.css';

const AudioPlayer = ({
  src,
  name,
  children,
  className,
  theme,
  ...otherProps
}) => {
  const themed = theme ? ` AudioPlayer--theme-${theme}` : '';

  return (
    <MediaPlayer
      src={src}
      render={({ status, progress, controls, info }) => (
        <div
          className={classNames(
            'AudioPlayer',
            status === 'error' && 'AudioPlayer--error',
            themed,
            className
          )}
          {...otherProps}
        >
          <div className="AudioPlayer__progress-container">
            <div
              className="AudioPlayer__progress-bar"
              onClick={({ target, clientX }) => {
                const frame = target.getBoundingClientRect();
                const newProgress = (clientX - frame.x) / frame.width;

                controls.setTime(newProgress * info.duration);
              }}
            />
            <div
              className="AudioPlayer__progress"
              style={{ width: `${progress}%` }}
            />

            <span className="AudioPlayer__progress-left">
              {timeString(info.time)}
            </span>
            <span className="AudioPlayer__progress-right">
              {timeString(info.duration - info.time)}
            </span>
          </div>
          <div className="AudioPlayer__inner">
            <div className="AudioPlayer__controls">
              {status === 'loading' ? (
                <div className="AudioPlayer__toggle AudioPlayer__toggle--loader">
                  <ProgressCircle
                    className="AudioPlayer__loader"
                    progress={66}
                    size={20}
                  />
                </div>
              ) : (
                <Fragment>
                  <button
                    className="AudioPlayer__rewind--left"
                    onClick={() => controls.setTime(info.time - 5)}
                  />
                  <button
                    className={classNames(
                      'AudioPlayer__toggle',
                      `AudioPlayer__toggle--${status}`
                    )}
                    onClick={controls.togglePlaying}
                  />
                  <button
                    className="AudioPlayer__rewind--right"
                    onClick={() => controls.setTime(info.time + 5)}
                  />
                </Fragment>
              )}
              <span className="AudioPlayer__name">
                {status === 'error' ? `Ошибка при загрузке (${name})` : name}
              </span>
            </div>
            <div className="AudioPlayer__children">{children}</div>
          </div>
        </div>
      )}
    />
  );
};

AudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export const AudioPlayerMock = ({
  name,
  children,
  before,
  className,
  ...otherProps
}) => (
  <div
    className={classNames('AudioPlayer', 'AudioPlayer--mock', className)}
    {...otherProps}
  >
    <div className="AudioPlayer__inner">
      <div className="AudioPlayer__before">{before}</div>
      <div className="AudioPlayer__controls">
        <span className="AudioPlayer__name">{name}</span>
      </div>
      <div className="AudioPlayer__children">{children}</div>
    </div>
  </div>
);

export default AudioPlayer;
