import React from 'react';
import Typography from '@material-ui/core/Typography';
import './TourAudience.css';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import {
  makeStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Checkbox,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import TourContentFooterButtons from '../../TourContentFooter/TourContentFooterButtons';
import * as publishingActions from '../../../actions/publishing';
import { MAX_SELECTED_SUBCATEGORIES } from '../../../constants/limits';

/**
 * JSS styles for `TourAudience` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 24,
    marginTop: 40,
    marginBottom: -15,
  },
  container: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
  },
  formControl: {
    margin: theme.spacing(2),
  },
  grid: {
    maxWidth: 250,
  },
  formControlLabel: {
    marginLeft: -10,
    marginTop: theme.spacing(1.5),
    minWidth: 250,
  },
  cardTitle: {
    marginBottom: 18,
  },
  cardTitleSm: {
    marginBottom: 4,
  },
  helperFullness: {
    marginTop: 40,
    marginBottom: -25,
  },
  alert: {
    marginTop: 25,
    marginBottom: 25,
    maxWidth: 'max-content',
  },
}));

const TourAudience = ({
  currentQuest,
  intl,
  location,
  currentQuestId,
  publishingActions,
  publishing: {
    isSaving,
    receivedPublishData: { subcategories, locale },
  },
  languages,
  user,
  checkFullness,
}) => {
  /**
   * JSS styles for `TourAudience` element
   * @type {React::Hook}
   */
  const classes = useStyles();

  /**
   * Sends to server not checked subcategories, delete checked subcategories
   * @param {Number} id - subcategory id
   */
  const handleCheck = (id) => {
    if (isChecked(id)) {
      publishingActions.sendPublishData(currentQuest.products[0].id, {
        subcategories: subcategories
          .filter((x) => x !== id)
          .map((a) => a.id || a),
      });
    } else {
      subcategories.length < MAX_SELECTED_SUBCATEGORIES &&
        publishingActions.sendPublishData(currentQuest.products[0].id, {
          subcategories: [...subcategories.map((a) => a.id || a), id],
        });
    }
  };

  /**
   * Checks if subcategory chosen or not
   * @param {Number} id - subcategory id
   * @returns {Boolean} - subcategory checked or not
   */
  const isChecked = (id) => {
    return subcategories.some((n) => n.id === id || n === id);
  };

  /**
   * Checking the filling of fields and returning what is not filled
   * @returns {String} - fields that are not filled
   */
  const handleFullnessText = () => {
    const entities = [];

    !subcategories.length &&
      entities.push(
        intl.formatMessage({
          id: 'StudioCreateProduct.tourAudienceSubcategories',
          defaultMessage: 'categories',
        })
      );
    return entities.join(', ');
  };

  return (
    <section className="App__main TourAudience__main">
      <Container className={classes.container}>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.cardTitleSm}>
            <Typography variant="h5" display="inline">
              <FormattedMessage
                id="StudioCreateProduct.TourAudienceCategories"
                defaultMessage="Tour category"
              />
            </Typography>
            <Typography
              variant="h6"
              display="inline"
              style={{ float: 'right' }}
            >
              <FormattedMessage
                id="StudioCreateProduct.TourAudienceSelected"
                defaultMessage="Selected {value} of {max}"
                values={{
                  value: subcategories.length,
                  max: MAX_SELECTED_SUBCATEGORIES,
                }}
              />
            </Typography>
          </div>
          {subcategories.length < MAX_SELECTED_SUBCATEGORIES &&
            location.highlights && (
              <Alert severity="error" className={classes.alert}>
                <FormattedMessage
                  id="StudioCreateProduct.TourAudienceAlert"
                  defaultMessage="Please select at least {max} categories"
                  values={{ max: MAX_SELECTED_SUBCATEGORIES }}
                />
              </Alert>
            )}
          <Grid container spacing={2}>
            {user.categories
              .filter((x) => x.subcategories.length !== 0)
              .map((i) => (
                <Grid
                  key={i.id}
                  item
                  xs={6}
                  sm={3}
                  md={3}
                  className={classes.grid}
                >
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel component="legend">{i.title}</FormLabel>
                    <FormGroup>
                      {i.subcategories.map((n) => (
                        <FormControlLabel
                          key={n.id}
                          className={classes.formControlLabel}
                          control={
                            <Checkbox
                              name={n.title}
                              color="primary"
                              checked={isChecked(n.id)}
                              disabled={
                                !isChecked(n.id) &&
                                subcategories.length ===
                                  MAX_SELECTED_SUBCATEGORIES
                              }
                              onChange={() => !isSaving && handleCheck(n.id)}
                            />
                          }
                          label={n.title}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Grid>
              ))}
          </Grid>
        </Paper>
        {!checkFullness('audience') && (
          <Typography className={classes.helperFullness} variant="body1">
            <FormattedMessage
              id="StudioCreateProduct.tourAudienceRemainsToFill"
              defaultMessage="Remaining sections to fill in: "
            />
            {handleFullnessText()}
          </Typography>
        )}
        <TourContentFooterButtons
          leftLink={`/quest/${currentQuestId}/photos`}
          rightLink={
            location.highlights
              ? `/quest/${currentQuestId}/check`
              : `/quest/${currentQuestId}/price`
          }
          withRedirectToTourCheck={location.highlights}
          leftBtnVisible={!location.highlights}
        />
      </Container>
    </section>
  );
};

function mapStateToProps() {
  const mapStateToProps = (state) => {
    return {
      user: state.user,
      publishing: state.publishing,
      languages: state.languages.items,
      currentQuestId: state.quests.currentQuestId,
    };
  };
  return mapStateToProps;
}

function mapDispatchToProps(dispatch) {
  return {
    publishingActions: bindActionCreators(publishingActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(TourAudience)));
