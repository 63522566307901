import { createSelector } from 'reselect';
import getQuestById from '../functions/getQuestById';

const getQuests = (state) => state.quests.quests;
const getQuestId = (state, props) => {
  let {
    match: { params: { questId: routerQuestId } = {} } = {},
    params: { questId } = {},
  } = props;
  if (!questId) questId = routerQuestId;
  return questId;
};

export function makeGetCurrentQuest() {
  return createSelector([getQuests, getQuestId], (quests, questId) => {
    return getQuestById(quests, questId);
  });
}

const getEventOrder = (state, props) => {
  let {
    match: { params: { eventOrder: routerEventOrder } = {} } = {},
    params: { eventOrder } = {},
  } = props;
  if (!eventOrder) eventOrder = routerEventOrder;
  return parseInt(eventOrder, 10);
};

export function makeGetCurrentEvent() {
  return createSelector(
    [getQuests, getQuestId, getEventOrder],
    (quests, questId, eventOrder) => {
      const quest = getQuestById(quests, questId);
      return quest.events.find((event) => event.order === eventOrder) || null;
    }
  );
}

const getUploads = (state) => state.uploads.uploads;
const getEventId = (state, props) => props.eventId;
const getNextEventId = (state, props) => (props.next ? props.next.id : '');

export function makeGetUploadsByType(contentType) {
  return createSelector(
    [getUploads, contentType === 'imageAR' ? getNextEventId : getEventId],
    (uploads, eventId) => {
      return uploads.filter(
        ({
          contentType: uploadContentType,
          eventPK: { eventId: uploadEventId },
        }) => uploadContentType === contentType && uploadEventId === eventId
      );
    }
  );
}
