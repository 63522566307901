import compose from '../../../functions/react/compose';
import withBlobInput from '../withBlobInput';
import withFormat from '../withFormat';
import withRotate from '../withRotate';
import withFlip from '../withFlip';
import withPopup from '../withPopup';
import PictureEditorPure from '..';

/**
 * `PictureEditor` with ability to pass it `File` objects
 * @type {React::Component}
 */
const DefaultPictureEditor = compose(
  PictureEditorPure,
  withBlobInput,
  withFlip,
  withRotate,
  withFormat,
  withPopup
);

export default DefaultPictureEditor;
