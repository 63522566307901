import React, { Fragment, useState, useEffect } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import makeStyles from '@material-ui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import FilterListIcon from '@material-ui/icons/FilterList';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Dropdown from 'rc-dropdown';
import withSmDialog from '../../Dropdown/withSmDialog';
import withVisible from '../../Dropdown/DropdownVisibleContext';
import checkFilter from '../../../functions/table/checkFilter';
import TableOptionsButton from '../TableOptionsButton';
import TableFilter from '../TableFilter';
import TableFilterInfo from '../TableFilterInfo';

/**
 * Dropdown with dialog shown on mobiles
 * @type {React::Component}
 */
const DialogDropdown = withSmDialog(Dropdown);

/**
 * Description of the column filter
 * @typedef {Array} TableColumnFilter
 * @prop {React::Component} [0] - filter component (e.g. `Table::TableFilterText`)
 * @prop {Function} [1] - filter function (e.g. `Table::TableFilterText::filterText`) called with
 *   1. `TableHeaderData::id` of column where to perform search
 *   2. Query from the filter component to perform
 *   3. `Array[TableRowsData]` with rows description
 *   Must return filtered array
 * @prop {React::Component?} [3] - info badge, displayed in the `Table::withFilter()` options bar
 *   when filter is active
 */

/**
 * Description of the option in `TableFiltersMenu` list
 * @typedef {Object} TableColumnFilterData
 * @prop {TableHeaderData} data - header description
 * @prop {*} value - filter, applied to column
 */

/**
 * JSS styles for `TableColumnsToggleMenu` component
 * @type {React::Hook}
 */
const useMenuStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.main,
    boxShadow:
      '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
  },
  filterPadding: {
    padding: '16px',
  },
}));

/**
 * List of the column names available to filtering
 * @param {Object} $
 * @param {Object} $.components - keys are data types, values - components to use on them
 * @param {Array[TableColumnFilterData]} $.columns - table columns to show
 * @param {Function} $.onApplied - filter was applied
 * @param {Function} $.onClosed - filter was closed
 */
const TableFiltersMenu = withVisible(function ({
  isDropdownVisible,
  components,
  columns,
  onClosed = () => {},
  onApplied = () => {},
}) {
  const styles = useMenuStyles();

  /**
   * Description of current filter
   * @type {TableColumnFilterData}
   */
  const [active, setActive] = useState(null);

  /**
   * Toggles visibility of the filter
   * @param {TableColumnFilterData} header - selected column header description
   */
  const toggleFilter = (header) => {
    setActive(header ? { ...header, value: header.value || null } : null);
  };

  /**
   * Updates value of the shown filter
   * @param {*} newValue - new filter value
   */
  const updateFilter = (newValue) => {
    if (active) {
      setActive({ ...active, value: newValue });
    }
  };

  /**
   * Description of the filter for the component
   * @type {Array}
   */
  const filterDescription = components[get(active, 'type')] || [];

  /**
   * Filter component for selected column
   * @type {React::Component}
   */
  const Filter = filterDescription[0];

  /**
   * Additional filter component option passed to props from `TableHeaderData`
   * @type {Object?}
   */
  const filterOptions = get(active, 'data.filter.props');

  /**
   * Hide filter component when menu is closed
   */
  useEffect(() => toggleFilter(null), [isDropdownVisible]);

  return (
    <div className={styles.root}>
      {active ? (
        <TableFilter
          title={get(active, 'data.title')}
          onClosed={() => {
            toggleFilter(null);
            onClosed();
          }}
        >
          {Filter ? (
            <Filter
              {...(typeof filterOptions === 'object' ? filterOptions : {})}
              value={active.value}
              onChanged={updateFilter}
              onApplied={() => (active ? onApplied(active) : null)}
            />
          ) : null}
        </TableFilter>
      ) : (
        <List>
          <ListSubheader>
            <FormattedMessage
              id="TableFiltersMenu.header"
              defaultMessage="Filter"
            />
          </ListSubheader>
          {columns.map(({ data = {}, ...h }) => (
            <ListItem
              key={data.id}
              button
              onClick={() => toggleFilter({ ...h, data })}
            >
              <ListItemText primary={data.title} />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
});

/**
 * JSS styles for `Table::withFilters` component
 * @type {React::Hook}
 */
const useStyles = makeStyles(() => ({
  controlMargin: {
    marginRight: '8px !important',
  },
  controlInfo: {
    marginTop: '4px',
    marginBottom: '4px',
  },
  buttonIconNoMargin: {
    marginRight: '0',
  },
  menu: {
    zIndex: '10000 !important',
  },
  dialog: {
    margin: '32px auto',
  },
}));

/**
 * Adds filters to `Table::withOptions(Table)` component
 * @param {React::Component} Component - overloaded `Table` component
 */
export default function withFilters(Component) {
  /**
   * `Table::withOptions(Table)` component with filters
   * @param {Object} $
   * @param {Array[String]} $.filterable - ids of the columns on which filtering can be performed (will be shown in filters list tooltip)
   * @param {Object<String, React::Component>} $.filters - keys are `TableHeaderData::type`/`TableHeaderData::filter`, values are components representing filters
   * @param {...Object} $.tableProps - props will be passed to overloaded `Table` component
   */
  return function ({
    filterable = [],
    filters: filterComponents = {},
    startGap,
    header = [],
    rows = [],
    preExport,
    ...tableProps
  }) {
    const styles = useStyles();

    /**
     * If all columns in the table are filterable
     * @type {Boolean}
     */
    const isAllFilterable = !(Array.isArray(filterable) && filterable.length);

    /**
     * Available filters for table columns
     * @type {Array[TableColumnFilterData]}
     */
    const [filters, setFilters] = useState(
      (isAllFilterable
        ? header
        : header.filter((h) => filterable.includes(h.id))
      )
        .map((h) => ({
          value: null,
          data: h,
          type: get(h, 'filter.id') || h.type,
        }))
        .filter((f) => checkFilter(filterComponents[f.type]))
    );

    /**
     * Updates filter value in `filters`
     * @param {TableColumnFilterData} $0 - filter description with new value
     */
    const setFilter = ({ value: newValue, data: newData = {} }) => {
      if (newData.id) {
        setFilters(
          filters.map(({ data = {}, value, ...f }) => ({
            data,
            value: data.id === newData.id ? newValue : value,
            ...f,
          }))
        );
      }
    };

    /**
     * Filters given rows
     * @param {Array[TableRowData]} tableRows - rows to filter
     * @returns {Array[TableRowsData]} - rows filtered according to settings
     */
    const filterRows = (tableRows) => {
      return filters
        .filter(({ value }) => value !== null)
        .reduce(
          (acc = [], { value, type, data = {} }) =>
            filterComponents[type][1](data.id, value, acc),
          tableRows
        );
    };

    /**
     * Filters table rows before exporting
     * @param {TableHeader} $1
     * @param {Array[TableRowData]} $2
     * @returns {Array} - header of the table and filtered rows
     */
    const fiterBeforeExport = async (...tableData) => {
      let fullTableData = tableData;
      if (typeof preExport === 'function') {
        fullTableData = await preExport(...tableData);
      }

      fullTableData[1] = filterRows(fullTableData[1]);
      return fullTableData;
    };

    /**
     * Controlling visibility of menu with available filter
     * @type {Boolean}
     */
    const [isMenuShown, showMenu] = useState(false);

    /**
     * Filtered rows
     * @type {Array[TableRowData]}
     */
    const filteredRows = filterRows(rows);

    /**
     * Currently applied filters descriptions
     * @type {Array[TableColumnFilterData]}
     */
    const usedFilters = filters.filter((f) => !!f.value);

    /**
     * If there are filters applied
     * @type {Boolean}
     */
    const hasFilters = !!usedFilters.length;

    return (
      <Component
        startGap={
          <Fragment>
            {startGap}
            {filters.length ? (
              <Fragment>
                <DialogDropdown
                  className={styles.menu}
                  classes={{ dialog: { paper: styles.dialog } }}
                  visible={isMenuShown}
                  overlay={
                    <TableFiltersMenu
                      columns={filters}
                      components={filterComponents}
                      onClosed={() => showMenu(false)}
                      onApplied={(newFilterData) => {
                        setFilter(newFilterData);
                        showMenu(false);
                      }}
                    />
                  }
                  onVisibleChange={showMenu}
                >
                  <TableOptionsButton
                    className={hasFilters ? styles.controlMargin : ''}
                    classes={{
                      startIcon: hasFilters && styles.buttonIconNoMargin,
                    }}
                    startIcon={<FilterListIcon />}
                  >
                    {hasFilters ? null : (
                      <FormattedMessage
                        id="TableFilters.control"
                        defaultMessage="Search by filters"
                      />
                    )}
                  </TableOptionsButton>
                </DialogDropdown>
                {usedFilters.map((f, i) => {
                  const InfoComponent =
                    filterComponents[f.type][2] || TableFilterInfo;
                  return (
                    <InfoComponent
                      key={f.type}
                      className={classNames(
                        styles.controlInfo,
                        i + 1 !== usedFilters.length && styles.controlMargin
                      )}
                      value={f.value}
                      onDeleted={() => setFilter({ ...f, value: null })}
                    />
                  );
                })}
              </Fragment>
            ) : null}
          </Fragment>
        }
        header={header}
        rows={filteredRows}
        preExport={fiterBeforeExport}
        {...tableProps}
      />
    );
  };
}
