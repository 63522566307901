import getEventContent from './getEventContent';

/**
 * Pops stickers (photo navigational hints) from event
 * @param {Object} event - description of event from store
 * @returns {Array[String]} - array of photo navigational hints
 */
export default function getEventStickers(event) {
  const item = getEventContent('sticker', event);
  if (!item) return [];
  return item.data;
}
