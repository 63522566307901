import { takeEvery, put, call, select, fork } from 'redux-saga/effects';
import { format } from 'date-fns';
import Api from '../functions/Api';
import * as types from '../constants/ActionTypes';
import formQuery from '../functions/URL/formQuery';

/**
 * Request paginated quests stats
 * @param {Object} $
 * @param {Number} $.page - number of requested page (1 by default)
 */
export function* requestQuestsStats({ page = 1 }) {
  try {
    const { data } = yield call(
      Api.get,
      `v2/products/stats/${formQuery({ per_page: 10, page })}`
    );

    yield put({
      type: types.RECEIVE_QUESTS_STATS,
      data,
    });
  } catch (error) {
    yield put({
      type: types.NOT_RECEIVE_QUESTS_STATS,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

/**
 * Syncing quests stats listed on client when it is edited
 * @param {ReduxAction::Object} action -
 */
export function* syncQuestsStats(action) {
  let quests;
  let quest;
  let newDate;

  if (action.hasOwnProperty('quest')) {
    quest = action.quest;
    newDate = format(new Date(action.quest.lastModified), 'yyyy-MM-dd HH:mm');
  } else {
    quests = yield select((state) => state.quests.quests);
    quest = quests.find((q) => q.id === action.id);
    newDate = format(new Date(action.lastModified), 'yyyy-MM-dd HH:mm');
  }

  yield put({
    type: types.SYNC_QUEST_STAT,
    image: quest.products[0].previewImage,
    questId: quest.id,
    eventsCount: quest.events.length,
    lastModified: newDate,
  });
}

/**
 * Updating current quests stats listing after create quest
 */
export function* updateQuestsStats() {
  const currentPage = yield select((state) => state.stats.questsStats.current);

  yield put({
    type: types.REQUEST_QUESTS_STATS,
    page: currentPage || 1,
  });
}

/**
 * Request paginated payout
 * @param {Object} $
 * @param {Number} $.page - number of requested page (1 by default)
 */
export function* fetchPayouts({ page = 1 }) {
  try {
    const { data } = yield call(
      Api.get,
      `v2/payouts/${formQuery({ per_page: 10, page })}`
    );

    yield put({
      type: types.FETCH_PAYOUTS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: types.FETCH_PAYOUTS_FAILURE,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

/**
 * Updating current payouts stats after success request payout
 */
export function* updatePayoutsStats() {
  const currentPage = yield select((state) => state.stats.payouts.current);

  yield put({
    type: types.FETCH_PAYOUTS,
    page: currentPage || 1,
  });
}

export default function* watchStats() {
  yield takeEvery(types.REQUEST_QUESTS_STATS, requestQuestsStats);
  yield fork(() =>
    takeEvery([types.SYNC_QUEST_SUCCESS, types.RECEIVE_QUEST], syncQuestsStats)
  );
  yield takeEvery(types.CREATE_QUEST_SUCCESS, updateQuestsStats);
  yield takeEvery(types.FETCH_PAYOUTS, fetchPayouts);
  yield takeEvery(types.REQUEST_PAYOUT_SUCCESS, updatePayoutsStats);
}
