import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { HELP_CENTER_URL } from '../../constants/branding';

/**
 * JSS styles for `HelperLink` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  alert: {
    maxWidth: 'max-content',
    marginTop: (props) => (props.marginTop ? props.marginTop : 20),
    marginBottom: (props) => (props.marginBottom ? props.marginBottom : 30),
    '& a': {
      color: theme.palette.info.textDark,
      textDecoration: 'underline',
    },
  },
}));

export default function HelperLink({
  text,
  locale,
  link = `${HELP_CENTER_URL}${locale}/description/`,
  ...props
}) {
  const classes = useStyles(props);

  return (
    <Alert className={classes.alert} severity="info">
      <FormattedMessage
        id="StudioCreateProduct.help2"
        defaultMessage="Help center: "
      />
      <a target="_blank" href={link} rel="noopener noreferrer">
        {text}
      </a>
    </Alert>
  );
}
