import React from 'react';
import classNames from 'classnames';
import { defineMessages } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Control from 'react-leaflet-control';

/**
 * JSS styles for `MapPositionControl` element
 * @type {React::Hook}
 */
const useStyles = makeStyles({
  button: {
    zIndex: '100000',
  },
  buttonMargin: {
    margin: '26px 6px 0 0',
  },
});

/**
 * Translations for texts in the component
 * @type {Object}
 */
const messages = defineMessages({
  cancel: {
    id: 'MapPositionControl.cancel',
    defaultMessage: 'Cancel selection',
  },
  select: {
    id: 'MapPositionControl.select',
    defaultMessage: 'Add point',
  },
});

/**
 * Button for toggling ability of selecting location by click on map
 * Has no logic - everything in `Map` class
 * @param {Object} $
 * @param {Object} $.intl - manually passed from paren component `react-intl` prop (cause this component in DOM is installed separately)
 * @param {Function} $.onClicked - button was clicked
 * @param {Boolean} $.position - position on the `leaflet` map
 * @param {Boolean} $.disabled - button is disabled
 * @param {Boolean} $.isSelecting - if selecting mode is toggled (wil change button text)
 * @param {Boolean} $.control - if must be rendered as control on `react-leaflet` map (as direct child of it)
 * @param {Boolean} $.margin - add margin around button for better look as control on `react-leaflet` map (`true` by default)
 */
export default function MapPositionControl({
  position,
  disabled,
  isSelecting,
  control = true,
  margin = true,
  onClicked = () => {},
  intl: { formatMessage },
  ...buttonProps
}) {
  const styles = useStyles();
  const button = (
    <Button
      className={classNames(styles.button, margin && styles.buttonMargin)}
      color="primary"
      variant="contained"
      size="medium"
      disabled={disabled}
      onClick={onClicked}
      {...buttonProps}
    >
      {formatMessage(messages[isSelecting ? 'cancel' : 'select'])}
    </Button>
  );

  return !control ? (
    button
  ) : (
    <Control className="MapPositionControl" position={position}>
      {button}
    </Control>
  );
}
