import React from 'react';
import { FormattedMessage } from 'react-intl';

import { toast } from 'react-toastify';
import * as networkErrors from '../../../constants/NetworkErrors';

import Api from '../../../functions/Api';

export async function performAction(currentQuest, actionType, options = {}) {
  let url = '';
  let method = 'GET';
  let data = null;

  switch (actionType) {
    case 'regenerateStops': {
      const { textSource } = options;
      url = `quests/aitours/${currentQuest.aitourId}/regenerateStops/?chatbot=${textSource}`;
      break;
    }
    case 'regenerateStories': {
      const { textSource } = options;
      url = `quests/aitours/${currentQuest.aitourId}/regenerateStories/?chatbot=${textSource}`;
      break;
    }
    case 'regenerateSteps': {
      const { textSource } = options;
      url = `quests/aitours/${currentQuest.aitourId}/regenerateSteps/?chatbot=${textSource}`;
      break;
    }
    case 'regenerateStepText': {
      const { textSource } = options;
      url = `quests/aitours/${currentQuest.aitourId}/regenerateStepText/?chatbot=${textSource}`;
      method = 'POST';
      data = {
        stepNumber: options.stepNumber,
        changeType: options.changeType,
        customPrompt: options.customPrompt,
      };
      break;
    }
    case 'regenerateOtherTexts': {
      const { textSource } = options;
      url = `quests/aitours/${currentQuest.aitourId}/regenerateOtherTexts/?chatbot=${textSource}`;
      break;
    }
    case 'regenerateIntroduction': {
      const { textSource } = options;
      url = `quests/aitours/${currentQuest.aitourId}/regenerateIntroduction/?chatbot=${textSource}`;
      break;
    }
    case 'regenerateConclusion': {
      const { textSource } = options;
      url = `quests/aitours/${currentQuest.aitourId}/regenerateConclusion/?chatbot=${textSource}`;
      break;
    }
    case 'regenerateOverview': {
      const { textSource } = options;
      url = `quests/aitours/${currentQuest.aitourId}/regenerateOverview/?chatbot=${textSource}`;
      break;
    }
    case 'regenerateTitle': {
      const { textSource } = options;
      url = `quests/aitours/${currentQuest.aitourId}/regenerateTitle/?chatbot=${textSource}`;
      break;
    }
    case 'regenerateDescription': {
      const { textSource } = options;
      url = `quests/aitours/${currentQuest.aitourId}/regenerateDescription/?chatbot=${textSource}`;
      break;
    }
    case 'regenerateHighlights': {
      const { textSource } = options;
      url = `quests/aitours/${currentQuest.aitourId}/regenerateHighlights/?chatbot=${textSource}`;
      break;
    }
    case 'regenerateStepPhotos': {
      const { stepNumberPhotos, sourceType, searchType } = options;
      url = `quests/aitours/${currentQuest.aitourId}/regenerateStepPhotos/?searchType=${searchType}&source=${sourceType}`;
      if (
        stepNumberPhotos !== undefined &&
        stepNumberPhotos !== null &&
        stepNumberPhotos !== ''
      ) {
        url += `&stepNumber=${stepNumberPhotos}`;
      }
      break;
    }
    case 'generateVoice': {
      const { voiceId, stepNumberVoice } = options;
      url = `quests/${currentQuest.id}/generateVoice/?voice=${voiceId}`;
      if (stepNumberVoice !== undefined && stepNumberVoice !== null) {
        url += `&stepNumber=${stepNumberVoice}`;
      }
      break;
    }
    case 'moveSteps': {
      const { sourceQuestId, ordersSteps } = options;
      url = `quests/${currentQuest.id}/moveSteps/`;
      method = 'PATCH';
      data = {
        source_quest_id: sourceQuestId,
        orders: ordersSteps,
      };
      break;
    }
    default:
      console.error('Unknown action type:', actionType);
      return null;
  }

  try {
    let response;
    if (method === 'GET') {
      response = await Api.get(url);
    } else if (method === 'POST') {
      response = await Api.post(url, { data, isDecamelize: false });
    } else if (method === 'PATCH') {
      response = await Api.patch(url, { data, isDecamelize: false });
    }
    return response;
  } catch (error) {
    console.error('Error performing action:', error);
    if (error.response) {
      console.error('Response data:', error.status);
      if (error.status === 400) {
        toast.info(
          <FormattedMessage
            id="StatusBadge.badRequest"
            defaultMessage="Error in form data"
          />,
          {
            className: 'ErrorBadge__wrapper ErrorBadge__wrapper--yellow',
            toastId: networkErrors.badRequest,
          }
        );
      }
    }
    return null;
  }
}
