import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  FormattedMessage,
  intlShape,
  injectIntl,
  defineMessages,
} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { isMobile } from '../../functions/queries';
import getEventInfo from '../../functions/event/getEventInfo';
import findRoutePoint from '../../functions/route/findRoutePoint';
import getValidationResults from '../../functions/validation/getValidationResults';
import eventContentString from '../../functions/event/eventContentString';
import withPreview from '../App/AppPreviewContext';
import EventAdd from '../EventAdd';
import EventControls from '../EventControls';
import Phone from '../Phone';
import TourContentFooterButtons from '../TourContentFooter/TourContentFooterButtons';
import NotEditableNotification from '../NotEditableNotification';
import './EventWorkSpace.css';
import routePointToGeoTrigger from '../../functions/route/routePointToGeoTrigger';
import OnDemandToursForm from '../GenerateAiTourForm';
import TranslationForm from '../TranslationForm';
import { translations } from '../GenerateAiTourForm/translations';

/**
 * Messages for `EventWorkSpace` component
 * @type {Object}
 */
const messages = defineMessages({
  placeholder: {
    id: 'EventWorkSpace.eventAddPlaceholder',
    defaultMessage: 'Event name',
  },
});

/**
 * Messages for `EventWorkSpace` component
 * Will be passed to the `eventContentString` function separately
 * @type {Object}
 */
const contentMessages = defineMessages({
  title: {
    id: 'EventWorkSpace.footerContentTitle',
    defaultMessage: 'event title',
  },
  image: {
    id: 'EventWorkSpace.footerContentImage',
    defaultMessage: 'pictures of the place',
  },
  audio: {
    id: 'EventWorkSpace.footerContentAudio',
    defaultMessage: 'audio guide',
  },
  text: {
    id: 'EventWorkSpace.footerContentHtml',
    defaultMessage: 'audio guide text',
  },
});

/**
 * Forms string with info about not added obligatory event content
 * @param {Object} event - event description from quest JSON
 * @param {Object} messages - `react-intl` messages with `title`, `image`, `audio`, `text` props with suitable messages
 * @param {Function} formatMessage - `formatMessage` function from `react-intl`
 * @returns {String}
 */
function formMissingContentInfo(event, messages, formatMessage) {
  const eventContent = getEventInfo(['image', 'audio', 'text', 'title'], event);
  const eventMissingContent = Object.keys(eventContent).reduce(
    (acc, t) => ({ ...acc, [t]: !eventContent[t] }),
    {}
  );

  return eventContentString(eventMissingContent, formatMessage, messages).join(
    ', '
  );
}

class EventWorkSpace extends Component {
  state = {
    activeSection: 'AI tools',
  };

  setActiveSection = (section) => {
    this.setState({ activeSection: section });
  };
  handleCopyQuest = (id, title) => {
    const {
      questActions: { copyQuest },
    } = this.props;
    copyQuest(id, title);
  };

  render() {
    let {
      user,
      currentQuest,
      currentQuest: { events, isEditable, products },
      currentEvent: event,
      match: {
        params: { questId, anchor },
      },
      eventActions,
      handleAddEvent,
      isSidebarOpen,
      isPreviewShown,
      togglePreview,
      isPopupOpen,
      intl: { formatMessage },
    } = this.props;
    questId = parseInt(questId, 10);
    const { activeSection } = this.state;
    const hasAITourGroup = user.groups.includes('AI Tour');
    const creationTime = new Date(products[0].creationTime);

    const comparisonDate = new Date('2024-03-03T00:00:00.000Z');

    const showOnDemandToursForm = creationTime > comparisonDate;
    if (!event) {
      return (
        <div className="EventPage__main">
          <div className="event__error">
            <h2 className="event__error--heading">
              <FormattedMessage
                id="EventWorkSpace.addFirstEvent"
                defaultMessage="First of all add an event"
              />
            </h2>
            <EventAdd
              questId={questId}
              placeholder={formatMessage(messages.placeholder)}
              onAdd={handleAddEvent}
            />
          </div>
        </div>
      );
    }
    const prev = event.order > 0 ? events[event.order - 1] : null;
    const next = event.order < events.length ? events[event.order + 1] : null;
    const validationResults = getValidationResults(event, next);

    const routePoint = findRoutePoint(currentQuest.routes || [], event.id);
    const eventMissingContentString = formMissingContentInfo(
      event,
      contentMessages,
      formatMessage
    );

    const triggerGeo = next
      ? routePointToGeoTrigger(
          findRoutePoint(currentQuest.routes || [], next.id)
        )
      : null;
    const showTranslationButton =
      !(
        currentQuest.generatedByAi === false && currentQuest.events.length === 2
      ) &&
      ((currentQuest.language && currentQuest.language.code === 'en') ||
        (currentQuest.originalTour && currentQuest.originalTour.id));

    const showBothButtons =
      (currentQuest.generatedByAi === true && currentQuest.aitourId !== null) ||
      !showTranslationButton;

    const showForm = showOnDemandToursForm && hasAITourGroup && isPreviewShown;

    return (
      <div
        ref={(div) => (this._main = div)}
        className={classNames(
          'App__main',
          (isPreviewShown || isSidebarOpen || isPopupOpen) &&
            isMobile() &&
            'App__main--static'
        )}
      >
        <div className="EventWorkSpace">
          <NotEditableNotification
            className="App__notification"
            quest={currentQuest}
            onCopy={this.handleCopyQuest}
          />
          <div
            className={classNames(
              'overlay',
              (isPreviewShown || isSidebarOpen) && isMobile() && 'overlay--on'
            )}
          />
          <div className="EventWorkSpace__content">
            <EventControls
              prev={prev}
              next={next}
              routePoint={routePoint}
              quest={currentQuest}
              questId={questId}
              eventId={event.id}
              eventsAmount={events.length}
              anchor={anchor}
              isEditable={isEditable}
              validationResults={validationResults}
              triggerGeo={triggerGeo}
            />
            {showForm ? (
              <div className="EventWorkSpace__content_forms">
                {showBothButtons && (
                  <div className="EventWorkSpace__content_buttons-block">
                    {showTranslationButton && (
                      <button
                        className={`EventWorkSpace__content_button ${
                          activeSection === 'AI tools' ? 'activeButton' : ''
                        }`}
                        onClick={() => this.setActiveSection('AI tools')}
                      >
                        {formatMessage(translations.AItoolsButtonQuestForm)}
                      </button>
                    )}
                    {showTranslationButton && (
                      <button
                        className={`EventWorkSpace__content_button ${
                          activeSection === 'Translation' ? 'activeButton' : ''
                        }`}
                        onClick={() => this.setActiveSection('Translation')}
                      >
                        {formatMessage(
                          translations.AItoolsButtonQuestTranslation
                        )}
                      </button>
                    )}
                  </div>
                )}
                <>
                  {activeSection === 'AI tools' && showBothButtons ? (
                    <OnDemandToursForm
                      currentQuest={currentQuest}
                      products={products}
                      user={user}
                      formatMessage={formatMessage}
                    />
                  ) : showTranslationButton ? (
                    <TranslationForm
                      currentQuest={currentQuest}
                      products={products}
                      user={user}
                      formatMessage={formatMessage}
                    />
                  ) : null}
                </>
              </div>
            ) : null}
          </div>
          <TourContentFooterButtons
            className="EventWorkSpace__footer"
            before={
              eventMissingContentString ? (
                <Typography variant="body1">
                  <span className="EventWorkSpace__footerTitle">
                    <FormattedMessage
                      id="EventWorkSpace.footerTitle"
                      defaultMessage="Left to fill"
                    />
                    {': '}
                  </span>
                  {eventMissingContentString}
                </Typography>
              ) : null
            }
            leftLink={
              prev
                ? `/quest/${questId}/events/${prev.order}/`
                : `/quest/${questId}/map/`
            }
            rightLink={
              next
                ? `/quest/${questId}/events/${next.order}/`
                : `/quest/${questId}/locations/`
            }
          />
        </div>
      </div>
    );
  }
}

EventWorkSpace.propTypes = {
  user: PropTypes.object.isRequired,
  questActions: PropTypes.object.isRequired,
  eventActions: PropTypes.object.isRequired,
  currentEvent: PropTypes.object.isRequired,
  currentQuest: PropTypes.object.isRequired,
  handleAddEvent: PropTypes.func.isRequired,
  isPreviewShown: PropTypes.bool.isRequired,
  togglePreview: PropTypes.func.isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  isPopupOpen: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(withPreview(EventWorkSpace));
