import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { defineMessages, injectIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import { FILTER_PADDING } from '../TableFilter';
import TableFilterInfo from '../TableFilterInfo';
import TableFilterButton from '../TableFilterButton';

/**
 * Messages for `TableFilterText` component
 * @type {Object}
 */
const messages = defineMessages({
  searchLabel: {
    id: 'TableFilterText.searchLabel',
    defaultMessage: 'Search query',
  },
});

/**
 * Performs `Table` rows filtering with text string as query
 * @param {String} id - id of the column to perform search on
 * @param {String} value - searching value
 * @param {Array[TableRowData]} rows - rows to filter
 * @returns {Array[TableRowData]} - filtered rows
 */
export function filterText(id, value, rows) {
  if (typeof value !== 'string' || !value) {
    return rows;
  }

  const searchValue = value.toLowerCase();
  return rows.filter((row) => {
    if (typeof row[id] === 'object') {
      const { content, raw } = row[id];
      return [raw, content].reduce(
        (acc, c) =>
          !acc && typeof c === 'string'
            ? c.toLowerCase().includes(searchValue)
            : acc,
        false
      );
    }
  });
}

/**
 * Info badge for displaying in `Table::withFilters()` options panel
 * @param {Object} $
 * @param {String} $.value - `Table::TableFilterText` value
 */
export function TableFilterTextInfo({ value, ...badgeProps }) {
  return <TableFilterInfo {...badgeProps} label={value} />;
}

/**
 * JSS styles for `TableFilterText` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${FILTER_PADDING[0]}px ${FILTER_PADDING[1]}px`,
  },
}));

/**
 * Table text filter form - sets text to search
 * Has no inner state for `value` prop
 * @param {Object}
 * @param {String} $.value - input search field value
 * @param {Function} $.onChanged - filter value was changed, calls with new filter value as an argument
 * @param {Function} $.onApplied - will be called after "Apply filter" button clicked
 */
export default injectIntl(function TableFilterText({
  value: valueExt,
  onChanged = () => {},
  onApplied = () => {},
  intl = {},
}) {
  const styles = useStyles();
  const { formatMessage = () => {} } = intl;
  const value = valueExt || '';

  /**
   * Checks if filter form is submittable
   * @returns {Boolean} - true if errors exist
   */
  const checkErrors = () => value === '';

  /**
   * Applies filter after user pressed "Enter" button
   *   if "Apply filter" button is not disabled
   * @param {Event} - key up event
   */
  const applyOnEnter = (event) => {
    if (checkErrors()) {
      return;
    }

    if (event.key === 'Enter' || event.keyCode === 13) {
      onApplied();
    }
  };

  return (
    <div>
      <div className={styles.root}>
        <TextField
          value={value}
          label={formatMessage(messages.searchLabel)}
          onKeyUp={applyOnEnter}
          onChange={(e) => onChanged(e.target.value)}
        />
      </div>
      <TableFilterButton disabled={checkErrors()} onApplied={onApplied} />
    </div>
  );
});
