export const getFilenameParts = (filename) => {
  const parts = filename.split('.');
  return {
    name: parts.slice(0, -1).join(''),
    extention: parts.slice(-1).join(''),
  };
};

export const getNameUploaded = (path) => {
  const filename = path.split('/').slice(-1)[0];
  const { name } = getFilenameParts(filename);
  const withouthId = name.slice(0, -4);
  return withouthId.split('-').join(' ');
};
