import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import InputWithButtons from '../InputWithButtons';
import './InputPassword.css';

class InputPassword extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { hidden: true };
  }

  getType(hidden) {
    return hidden ? 'password' : 'text';
  }

  toggleHidden() {
    const { hidden } = this.state;
    this.setState({ ...this.state, hidden: !hidden });
  }

  render() {
    const { hidden } = this.state;
    const { theme } = this.props;
    const props = { ...this.props, type: this.getType(hidden) };
    let CSS = `InputPassword${hidden ? '--hidden' : ''}`;
    CSS = theme ? `${CSS} InputPassword--theme-${theme}` : CSS;
    props.className = classNames(CSS, props.className);

    return (
      <InputWithButtons {...props}>
        <button className="link--blue" onClick={this.toggleHidden.bind(this)}>
          {this.getType(hidden) == 'password' ? (
            <FormattedMessage
              id="InputPassword.showPassword"
              defaultMessage="Show"
            />
          ) : (
            <FormattedMessage
              id="InputPassword.hidePassword"
              defaultMessage="Hide"
            />
          )}
        </button>
      </InputWithButtons>
    );
  }
}

export default InputPassword;
