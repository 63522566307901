import React from 'react';
import styled, { css } from 'styled-components';

const width = '15';
const height = '15';
const viewBox = '0 0 15 15';

const getDimensions = () => ({
  height,
  width,
});

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`;

const Image = styled.svg`
  ${({ noStyles }) => (!noStyles ? getDimensionsCss() : null)}
`;

const defaultProps = {
  children: [
    <path
      key="key-0"
      fill="#000"
      fillRule="nonzero"
      d="M12.458 5.872l1.641-2.616a.911.911 0 0 0 0-.95.975.975 0 0 0-.83-.465H9.076V0H1.127C.494 0 0 .504 0 1.105V15h1.582V8.236h2.847v1.667h8.86c.336 0 .672-.174.83-.465a.904.904 0 0 0-.02-.969l-1.641-2.597z"
    />,
  ],
  viewBox,
};

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'Flag',
});
