import { fork, all, take, cancel } from 'redux-saga/effects';
import watchPartner from './partner';
import watchAnalytics from './analytics';
import watchUploads from './uploads';
import watchQuests from './quests';
import watchRoute from './route';
import watchPublishing from './publishing';
import watchUsers from './users';
import watchCodes from './codes';
import watchReferal from './referal';
import watchTutorial from './tutorial';
import watchNetwork from './network';
import watchLanguages from './languages';
import watchCurrencies from './currencies';
import watchStats from './stats';

export default () =>
  all([
    fork(watchPartner),
    fork(watchAnalytics),
    fork(watchUploads),
    fork(watchQuests),
    fork(watchRoute),
    fork(watchPublishing),
    fork(watchUsers),
    fork(watchCodes),
    fork(watchReferal),
    fork(watchTutorial),
    fork(watchNetwork),
    fork(watchLanguages),
    fork(watchCurrencies),
    fork(watchStats),
  ]);

export function createDynamicSaga(changeActionType, startingSagas) {
  function* _start(sagas) {
    try {
      yield sagas;
    } catch (e) {
      console.error('error', e);
    }
  }
  return function* dynamicSaga() {
    let action;
    let rootTask = yield fork(_start, startingSagas);
    while ((action = yield take(changeActionType))) {
      yield cancel(rootTask);
      rootTask = yield fork(_start, action.sagas);
    }
  };
}
