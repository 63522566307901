export default {
  h4: {
    fontSize: '34px',
    lineHeight: '1.23',
  },
  h5: {
    fontSize: '24px',
    lineHeight: '1.33',
  },
  h6: {
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: '1.5',
    letterSpacing: '0.15px',
  },
  subtitle1: {
    fontSize: '14px',
    lineHeight: '1.43',
  },
  body2: {
    fontSize: '14px',
    lineHeight: '1.43',
    letterSpacing: '0.15px',
  },
};
