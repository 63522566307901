import getEventContent from './getEventContent';

/**
 * Pops directions (text navigational hints) from event
 * @param {Object} event - description of event from store
 * @returns {Array[String]} - array of text navigational hints
 */
export default function getEventDirections(event) {
  const item = getEventContent('directions', event);
  if (!item) return [];
  return item.data;
}
