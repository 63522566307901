import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { isEmpty, isEqual } from 'lodash';
import DataListWrapper from '../../functions/DataListWrapper';
import SortHeaderCell, { SortTypes } from '../SortHeaderCell';
import DateRange from '../DateRange';
import {
  getWidgetStatsRanges,
  getDefaultWidgetStatsRange,
} from '../../constants/dateRanges';
import { WIDGET_STATS_HEADERS } from '../../constants/WidgetStatsHeaders';
import HelpTooltip from '../HelpTooltip';
import '../StatsTable/StatsTable.css';
import './WidgetStats.css';

const METRICS_PARAMS = [
  'shows',
  'views',
  'clicks',
  'payments',
  'paid',
  'reward',
];

class WidgetStats extends Component {
  constructor(props) {
    super(props);

    const {
      widgetStats: { labels = null },
      locale,
    } = props;
    this._defaultIndexes = [...Array(labels.length)].map((_, index) => index);
    this._dataList = this.getCleanRows(props);

    this.state = {
      colSortDirs: {},
      rows: new DataListWrapper(this._defaultIndexes, this._dataList),
    };

    this.defaultRange = getDefaultWidgetStatsRange(locale);
  }

  componentDidMount() {
    const { fetchWidgetStats } = this.props;
    const {
      momentDateObject: { start, end },
    } = this.defaultRange;

    fetchWidgetStats({
      startTs: start.format('YYYY-MM-DD'),
      endTs: end.format('YYYY-MM-DD'),
    });
  }

  componentWillReceiveProps(nextProps) {
    const {
      widgetStats: { total = {}, labels = [] },
    } = this.props;
    const {
      widgetStats: { total: nextTotal = {}, labels: nextLabels = [] },
    } = nextProps;

    const rowsNeedsUpdate = [
      !isEqual(total, nextTotal),
      labels.length !== nextLabels.length,
    ].some(Boolean);

    if (!rowsNeedsUpdate) {
      return;
    }
    this._dataList = this.getCleanRows(nextProps);

    this.setState({
      rows: new DataListWrapper(
        [...Array(nextLabels.length)].map((_, index) => index),
        this._dataList
      ),
    });
  }

  prettifyStatsData(data) {
    let newData = data;
    METRICS_PARAMS.forEach((metric) => {
      // Check data, if data doesn`t exist some METRICS_PARAMS, it will be '\u2014' (em dash)
      if (!data.hasOwnProperty(metric) || data[metric] === 0) {
        return (newData = { ...newData, [metric]: '\u2014' });
      }
    });
    return newData;
  }

  getCleanRows(props) {
    const {
      widgetStats: { labels },
    } = props;
    return labels.reduce((arr, labelData) => {
      return [...arr, this.prettifyStatsData(labelData)];
    }, []);
  }

  onSortChange = (columnKey, sortDir) => {
    const { rows } = this.state;
    const sortIndexes = rows._indexMap.slice();
    sortIndexes.sort((indexA, indexB) => {
      const valueA = this._dataList[indexA][columnKey];
      const valueB = this._dataList[indexB][columnKey];
      let sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }
      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal *= -1;
      }

      return sortVal;
    });

    this.setState({
      rows: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  };

  renderHead() {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <thead>
        <tr className="StatsTable__head">
          {WIDGET_STATS_HEADERS.map(([key, text, helpText]) => {
            const sortDir = this.state.colSortDirs[key];
            return (
              <SortHeaderCell
                key={key}
                columnKey={key}
                sortDir={sortDir}
                onSortChange={this.onSortChange}
              >
                {formatMessage(text)}
                <HelpTooltip>{formatMessage(helpText)}</HelpTooltip>
              </SortHeaderCell>
            );
          })}
        </tr>
      </thead>
    );
  }

  calculateConversion(a, b) {
    if (typeof a !== 'number' || typeof b !== 'number') {
      return null;
    }
    return (
      <span className="WidgetStats__cell--conversion">
        {Math.round((a / b) * 1000) / 10}%
      </span>
    );
  }

  renderTotalValuesRow(props) {
    const {
      widgetStats: { total, labels },
      isFetchingWidgetStatsError,
    } = this.props;

    if (isFetchingWidgetStatsError) {
      return (
        <tr className="WidgetStats__row">
          <td
            className="WidgetStats__cell--no-data WidgetStats__cell--error"
            colSpan={7}
          >
            <FormattedMessage
              id="WidgetStats.fetchError"
              defaultMessage="Error loading widget stats data"
            />
          </td>
        </tr>
      );
    }

    if (isEmpty(total) || !Object.values(total).some(Boolean)) {
      return (
        <tr className="WidgetStats__row">
          <td className="WidgetStats__cell--no-data" colSpan={7}>
            <FormattedMessage
              id="WidgetStats.noData"
              defaultMessage="No stats data for your widgets"
            />
          </td>
        </tr>
      );
    }

    const prettyTotalStatsData = this.prettifyStatsData(total);
    return (
      <tr
        className={classNames(
          'WidgetStats__row',
          !isEmpty(labels) && 'WidgetStats__row-total'
        )}
      >
        <td />
        {METRICS_PARAMS.map((param, index) => {
          const currentMetricValue = prettyTotalStatsData[param];
          return (
            <td key={index}>
              <span className="WidgetStats__cell--total">
                {currentMetricValue}
              </span>
              {index !== 0 && index !== METRICS_PARAMS.length - 1
                ? this.calculateConversion(
                    currentMetricValue,
                    prettyTotalStatsData[METRICS_PARAMS[index - 1]]
                  )
                : null}
            </td>
          );
        })}
      </tr>
    );
  }

  renderRow(rowProps) {
    const { labelName } = rowProps;

    if (isEmpty(rowProps)) {
      return null;
    }

    return (
      <tr className="WidgetStats__row WidgetStats__row-label">
        <td className="WidgetStats__cell--label-name">{labelName}</td>
        {METRICS_PARAMS.map((param, index) => {
          const currentMetricValue = rowProps[param];
          return (
            <td key={index} className="WidgetStats__cell">
              <span>{currentMetricValue}</span>{' '}
              {index !== 0 && index !== METRICS_PARAMS.length - 1
                ? this.calculateConversion(
                    currentMetricValue,
                    rowProps[METRICS_PARAMS[index - 1]]
                  )
                : null}
            </td>
          );
        })}
      </tr>
    );
  }

  renderNoLabelsRow() {
    const {
      widgetStats: { total, labels },
    } = this.props;
    const prettyTotalStatsData = this.prettifyStatsData(total);

    const NoLabelsRow = Object.keys(prettyTotalStatsData).reduce(
      (obj, metricKey) => {
        // Extract the metric value of the total value
        const metricOfTotalValue = prettyTotalStatsData[metricKey];

        // Check the metric value is it a number.
        // This is necessary to calculate the difference between the metric value of the total value and the metric values of the labels values
        if (typeof metricOfTotalValue === 'number') {
          // Extract current metric values of the labels values
          const labelsValuesWithCurrentMetric = labels
            .filter((label) => typeof label[metricKey] === 'number')
            .map((filteringLabel) => filteringLabel[metricKey]);

          // Get the difference between metric value of the total value and the metric values of the labels values
          const diffValue =
            metricOfTotalValue -
            labelsValuesWithCurrentMetric.reduce((a, b) => a + b, 0);

          // return new object
          return { ...obj, [metricKey]: diffValue > 0 ? diffValue : '\u2014' };
        }
        return { ...obj, [metricKey]: prettyTotalStatsData[metricKey] };
      },
      {}
    );

    // If noLabelsRow have some number metric value then render
    return Object.keys(NoLabelsRow).some(
      (metric) => typeof NoLabelsRow[metric] === 'number'
    ) ? (
      <tr className="WidgetStats__row WidgetStats__row-label">
        <td>
          <FormattedMessage
            id="WidgetStats.withoutLabels"
            defaultMessage="without labels"
          />
        </td>
        {METRICS_PARAMS.map((param, index) => {
          const currentMetricValue = NoLabelsRow[param];
          return (
            <td key={index}>
              <span className="WidgetStats__cell">{currentMetricValue}</span>{' '}
              {index !== 0 && index !== METRICS_PARAMS.length - 1
                ? this.calculateConversion(
                    currentMetricValue,
                    NoLabelsRow[METRICS_PARAMS[index - 1]]
                  )
                : null}
            </td>
          );
        })}
      </tr>
    ) : null;
  }

  render() {
    const { rows } = this.state;
    const {
      className,
      widgetStats: { labels },
      fetchWidgetStats,
      locale,
    } = this.props;
    return (
      <section className={classNames('WidgetStats', className)}>
        <DateRange
          enableQuickRanges
          defaultRange={this.defaultRange}
          quickRanges={getWidgetStatsRanges(locale)}
          locale={locale}
          fetchData={fetchWidgetStats}
        />
        <table className="StatsTable__table WidgetStats__table" cellSpacing={0}>
          {this.renderHead()}
          <tbody>
            {this.renderTotalValuesRow()}
            {[...Array(rows.getSize())].map((_, index) => {
              return this.renderRow({ ...rows.getObjectAt(index), index });
            })}
            {!isEmpty(labels) ? this.renderNoLabelsRow() : null}
          </tbody>
        </table>
      </section>
    );
  }
}

WidgetStats.propTypes = {
  widgetStats: PropTypes.shape({
    total: PropTypes.object,
    labels: PropTypes.array,
  }),
  fetchWidgetStats: PropTypes.func.isRequired,
  locale: PropTypes.string,
  intl: intlShape.isRequired,
};

export default injectIntl(WidgetStats);
export { WidgetStats as PureWidgetStats };
