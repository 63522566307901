import { useState, useRef } from 'react';

/**
 * Keeps value in state and ref for updating component and accessing new value from 3rd party functions (e.g. `setTimeout` callback)
 * @param {*} initial - initial value of state and ref
 * @returns {Array} - first element is current value, second is ref to it, third - function to update it
 */
export default function useStateRef(initial) {
  const valueRef = useRef(initial);
  const [value, _setValue] = useState(initial);
  const setValue = (newValue) => {
    valueRef.current = newValue;
    _setValue(newValue);
  };

  return [value, valueRef, setValue];
}
