export const MAX_MEDIA_SIZE = 10000000;

export const MAX_VIDEO_SIZE = MAX_MEDIA_SIZE;
export const MAX_IMAGE_SIZE = 5 * 1024 * 1024;
export const MAX_CONTROLS_IMAGE_SIZE = 3 * 1024 * 1024;
export const MAX_AUDIO_SIZE = MAX_MEDIA_SIZE;

export const MAX_PRODUCT_IMAGE_AMOUNT = 10;
export const MAX_CONTROLS_IMAGE_AMOUNT = 5;

export const AR_TARGET_LIMIT = 5;

export const TOUR_MAX_TITLE_LENGTH = 70;
export const EVENT_MAX_TITLE_LENGTH = 70;

export const PRODUCT_TITLE_CHARACTER_LIMIT = 120;
export const PRODUCT_DESCRIPTION_CHARACTER_LIMIT = 1500;
export const PRODUCT_DISTANCE_CHARACTER_LIMIT = 50;
export const MAX_SELECTED_SUBCATEGORIES = 3;

export const ABOUT_USER_CHARACTER_LIMIT = 400;
export const ABOUT_PARTNER_CHARACTER_LIMIT = 300;
export const PARTNER_RESOURCE_CHARACTER_LIMIT = 225;
