import get from 'lodash/get';

/**
 * Tries to convert given text (possibly xml) string to valid HTML
 * @param {String} text - text to convert to HTML
 * @param {Function?} onError - calls if it is not possible to parse `text`
 * @returns {String|*} - HTML or return of `onError` (empty string by defult) if not success
 */
export default function toValidHtml(text, onError = () => '') {
  if (get(document, 'implementation.createHTMLDocument')) {
    const doc = document.implementation.createHTMLDocument('title');
    doc.documentElement.innerHTML = text;
    return doc.getElementsByTagName('body')[0].innerHTML;
  }
  return onError();
}
