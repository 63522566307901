import React from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import './EventHeader.css';

/**
 * JSS styles for `Event` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  name: {
    flexShrink: '1',
    padding: '0 0 0 0',
    color: theme.palette.text.primary,
    transition: '.5s',
  },
  subName: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.15px',
  },
}));

/**
 * `Event` component header
 * @param {Object} $
 * @param {React::Component|Array[React::Component]} $.head - components to display on the same line with `name`
 * @param {React::Component|String} $.children - title of the component
 * @param {Boolean} $.margin - use default bottom margin (`true` by default)
 */
export default function ({ variant, children, head, margin = true }) {
  const styles = useStyles();

  return (
    <div
      className={classNames(
        'EventHeader',
        margin && 'EventHeader--margin',
        variant && `EventHeader--${variant}`
      )}
    >
      <Typography
        className={classNames(styles.name, variant && styles[`${variant}Name`])}
        variant="h5"
      >
        {children}
      </Typography>
      <div className="EventHeader__head">{head}</div>
    </div>
  );
}
