import React from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { GAP } from '..';
import TableCell from '../TableCell';

/**
 * @typedef {Object} TableRowData
 * @prop {TableCellData} [id] - cell description, where `id` is `TableHeaderData::id`
 */

/**
 * JSS styles for `TableRow` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${GAP[0]}px ${GAP[1]}px`,
  },
}));

/**
 * Displays table row
 * @param {Object} $
 * @param {String} $.className - additional CSS class
 * @param {Array[TableHeaderData]} $.header - table column headers descriptions
 * @param {TableRowData} $.row - data for displaying in the row of the table (only cells with ids mentioned in `$.header` will be shown)
 */
export default function TableRow({ className, header = [], row = {} }) {
  const styles = useStyles();

  return (
    <tr>
      {header.map(({ id, type, attributes = {} }) => (
        <TableCell
          key={id}
          className={classNames(styles.root, className)}
          type={type}
          {...attributes}
        >
          {get(row, `${id}.content`, '')}
        </TableCell>
      ))}
    </tr>
  );
}
