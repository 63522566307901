import React, { useRef, useState, useMemo, useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { FormattedMessage, injectIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import debounce from '../../../functions/debounce';
import messagesNext from '../../../constants/events/intlNext';

/**
 * Max amount of symbols in the "Next" button text
 * @type {Number}
 */
const BUTTON_TEXT_MAX_LENGTH = 40;

/**
 * Messages for `EventControlsNext` component
 * @type {Object}
 */
const messages = {
  placeholder: messagesNext.placeholder,
  placeholderButton: messagesNext.placeholderButton,
};

/**
 * JSS styles for `EventControlsNext` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  info: {
    marginBottom: '6px',
  },
  inputWrapper: {
    width: '100%',
    '& input': {
      paddingBottom: '9px',
    },
  },
}));

/**
 * Event 'To the next event' button text
 * @param {Object} $
 * @param {String} $.buttonText - initial value of the input field
 * @param {String} $.prevEventName - title of the previous event
 * @param {Boolean} $.hasRoutePoint - if current event with geo trigger
 * @param {*} $.resetOnChange - if this value was changed - value in the fieldwill be reset to one stored in `buttonText`
 * @param {Function} $.onChanged - will be called with debounce 1s when value changes
 */
export default injectIntl(function EventControlsNext({
  buttonText: initialText = '',
  prevEventName = '',
  hasRoutePoint,
  resetOnChange,
  onChanged = () => {},
  intl: { formatMessage },
}) {
  const styles = useStyles();

  /**
   * Ref to the input element for next event button text
   * @type {React::Ref}
   */
  const inputRef = useRef();

  /**
   * Text of the passing to the next event button
   * @type {String}
   */
  const [buttonText, setButtonText] = useState(initialText);

  /**
   * Debounced `onChanged` callback with 1000 sec timeout
   * @type {Function}
   */
  const debouncedOnChanged = useMemo(() => debounce(onChanged, 1000), [
    onChanged,
  ]);

  /**
   * Changes text of the passing to the next event button
   * Triggers sending data to the server
   * @param {Event} - HTMLInputElement `change` event
   */
  const onButtonTextChanged = (event) => {
    const newButtonText = event.target.value;
    setButtonText(newButtonText);
    debouncedOnChanged(newButtonText);
  };

  /**
   * If value of `resetOnChange` changed - reset field value to initial one
   */
  useEffect(() => {
    setButtonText(initialText);
    if (inputRef && inputRef.current) {
      inputRef.current.blur();
    }
  }, [resetOnChange]);

  return (
    <div>
      <Typography className={styles.info} variant="body1">
        <FormattedMessage
          id="EventControlsNext.info"
          defaultMessage={
            'Traveller will press the button on the "{eventName}" step:'
          }
          values={{ eventName: prevEventName }}
        />
      </Typography>
      <TextField
        className={styles.inputWrapper}
        placeholder={
          hasRoutePoint
            ? formatMessage(messages.placeholder)
            : formatMessage(messages.placeholderButton)
        }
        value={buttonText}
        helperText={`${buttonText.length}/${BUTTON_TEXT_MAX_LENGTH}`}
        inputProps={{ maxLength: BUTTON_TEXT_MAX_LENGTH, ref: inputRef }}
        onChange={onButtonTextChanged}
      />
    </div>
  );
});
