import { takeEvery, put, call, fork, select, take } from 'redux-saga/effects';
import * as types from '../constants/ActionTypes';
import formQuery from '../functions/URL/formQuery';
import Api from '../functions/Api';

// TODO: refactor
export const currencyMapping = {
  en: 'EUR',
  ru: 'RUB',
};

export function* getPartnerCurrency() {
  let currency;
  let currencyId = yield select(
    (state) => state.user.receivedUserInfo.currencyId
  );

  while (currencyId === undefined) {
    yield take();
    // eslint-disable-next-line require-atomic-updates
    currencyId = yield select(
      (state) => state.user.receivedUserInfo.currencyId
    );
  }

  if (currencyId !== null) {
    currency = yield select((state) => state.currencies.items);

    while (!currency.length) {
      yield take();
      currency = yield select((state) => state.currencies.items);
    }

    currency = currency.find((x) => x.id === currencyId).code;
  } else {
    const locale = yield select((state) => state.user.locale) || 'en';
    currency = currencyMapping[locale];
  }

  return currency;
}

export function* fetchPartnerStats() {
  try {
    const currency = yield getPartnerCurrency();

    const { data } = yield call(Api.get, `partner/stats/?currency=${currency}`);

    // TODO: check required fields
    yield put({
      type: types.FETCH_PARTNER_STATS_SUCCESS,
      ...data,
      currencyCode: currency,
    });
  } catch (error) {
    yield put({
      type: types.FETCH_PARTNER_STATS_FAILURE,
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* fetchPartnerData() {
  try {
    const { data: payload } = yield call(Api.get, 'partner/');
    yield put({
      type: types.FETCH_PARTNER_DATA_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({
      type: types.FETCH_PARTNER_DATA_FAILURE,
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* updatePartnerData({ data }) {
  try {
    const { data: payload } = yield call(Api.put, 'partner/', {
      data,
    });
    yield put({
      type: types.UPDATE_PARTNER_DATA_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({
      type: types.UPDATE_PARTNER_DATA_FAILURE,
      error,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* fetchPaymentStats({ page = 1 }) {
  try {
    const currency = yield getPartnerCurrency();

    const { data } = yield call(
      Api.get,
      `v2/payments/stats/${formQuery({ currency, page, per_page: 10 })}`
    );

    yield put({
      type: types.FETCH_PAYMENTS_STATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: types.FETCH_PAYMENTS_STATS_FAILURE,
    });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* fetchAffiliatePartner() {
  try {
    const { data } = yield call(Api.get, `v2/affiliate/`);
    yield put({ type: types.FETCH_AFFILIATE_PARTNER_SUCCESS, data });
  } catch (error) {
    yield put({ type: types.FETCH_AFFILIATE_PARTNER_FAILURE, error });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* updateAffiliatePartner({ data }) {
  const affiliateId = yield select((state) => state.partner.affiliate.id);
  const method = affiliateId ? 'put' : 'post';
  try {
    const { data: payload } = yield call(Api[method], `v2/affiliate/`, {
      data,
    });
    yield put({ type: types.UPDATE_AFFILIATE_PARTNER_SUCCESS, payload });
  } catch (error) {
    yield put({ type: types.UPDATE_AFFILIATE_PARTNER_FAILURE, error });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export function* fetchTotalIncome() {
  try {
    const currency = yield getPartnerCurrency();

    const { totalIncome } = yield call(
      Api.get,
      `v2/payments/income/${formQuery({ currency })}`
    );

    yield put({ type: types.FETCH_TOTAL_INCOME_SUCCESS, totalIncome });
  } catch (error) {
    // yield put({ type: types.FETCH_TOTAL_INCOME_FAILURE, error });
    yield put({ type: types.NETWORK_ERROR, error });
  }
}

export default function* watchPartner() {
  yield fork(() =>
    takeEvery(
      [types.FETCH_PARTNER_STATS, types.CHANGE_LANGUAGE_SUCCESS],
      fetchPartnerStats
    )
  );
  yield takeEvery(types.FETCH_PARTNER_DATA, fetchPartnerData);
  yield takeEvery(types.UPDATE_PARTNER_DATA, updatePartnerData);
  yield takeEvery(types.FETCH_PAYMENTS_STATS, fetchPaymentStats);
  yield takeEvery(types.UPDATE_AFFILIATE_PARTNER, updateAffiliatePartner);
  yield takeEvery(types.FETCH_AFFILIATE_PARTNER, fetchAffiliatePartner);
  yield takeEvery(types.FETCH_TOTAL_INCOME, fetchTotalIncome);
}
