import React, { Fragment } from 'react';
import PictureEditor from '../../PictureEditor';
import usePictureEditor from './usePictureEditor';

/**
 * Opens `PictureEditor` when new image was added to `PictureDropzone`
 * @param {React::Component} Component - modified `PictureDropzone` component to overload
 * @param {React::Component} PictureEditorComponent - modified `PictureEditor` component to use
 */
export default function withPictureEditor(
  Component,
  PictureEditorComponent = PictureEditor
) {
  /**
   * `PictureDropzone` with ability to open `PictureEditor` on each added picture in dropzone
   * @params {Object} $
   */
  return function EditablePictureDropzone({ onAdded, ...dropzoneProps }) {
    const { editImages, editorProps } = usePictureEditor({ onEdited: onAdded });

    return (
      <Fragment>
        <PictureEditorComponent {...editorProps} />
        <Component {...dropzoneProps} onAdded={editImages} />
      </Fragment>
    );
  };
}
