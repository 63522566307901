/**
 * Formats attraction item for `v3/products`.
 * @returns {object} attraction as { "attraction": attraction.id, "admittance": attraction.entrance }
 */
export default function formatAttractionForRequest({ id, entrance }) {
  return {
    attraction: id,
    admittance: entrance,
  };
}

/**
 * Filters and formats attractions for `v3/products`.
 * @param {object[]} list - array of attractions
 * @param {any} attractionId - item remove
 * @returns {object[]}  array of attractions formated as attraction as { "attraction": id, "admittance": boolean }
 */
export function filterAndFormatAttractions(list, attractionId) {
  return list.reduce((filtered, item) => {
    if (item.id !== attractionId)
      filtered.push(formatAttractionForRequest(item));
    return filtered;
  }, []);
}
