import Api from '../../../functions/Api';

export async function FetchVoices(lang, questId) {
  let voices;

  try {
    const response = await Api.get(`quests/${questId}/listVoices/`, {
      headers: {
        'Accept-Language': lang,
      },
    });
    voices = response;
  } catch (e) {
    if (!voices) {
      return { statusCode: 404 };
    }
  }

  return voices;
}
