import getEventContent from './getEventContent';

/**
 * Pops images from event
 * @param  {Object} event - description of event from store
 * @return {Array[String]} - array of image URLs
 */
export default function getEventImages(event) {
  const item = getEventContent('image', event);
  if (!item || !item.visible) return [];
  return item.data;
}
