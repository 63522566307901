import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink, withRouter, Redirect } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import EventNavLink from '../EventNavLink';
import QuestShare from '../QuestShare';
import WithQuestStatus from '../WithQuestStatus';
import { CodesIcon } from '../Icon';
import debounce from '../../functions/debounce';
import { Mobile, Desktop } from '../../functions/queries';
import nullAsDefault from '../../functions/nullAsDefault';
import '../EventPage/EventPage.css';
import './Sidebar.css';
import SidebarItems from './SidebarItems';

const isMobileViewport = () => Boolean(window.innerWidth < 620);
const isDraggingClassName = 'is-dragging';

const getContentOverflow = (el, offset = 5) => {
  return {
    up: el.scrollTop >= offset,
    down: el.scrollTop <= el.scrollHeight - el.offsetHeight - offset,
  };
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isResizeListenerSet: false,
      isShadowListenerSet: false,
      redirect: false,
      contentOverflow: {
        up: false,
        down: false,
      },
    };
    this.handleResizeTrottled = debounce(this.handleResize, 10);
  }

  renderRedirect(path) {
    this.setState({ redirect: false });
    return <Redirect to={path} />;
  }

  componentDidMount() {
    const { isResizeListenerSet, isShadowListenerSet } = this.state;
    const {
      location: { pathname },
      params: { questId },
    } = this.props;

    if (localStorage.getItem('WGTStudioRoute')) {
      const path = localStorage.getItem('WGTStudioRoute').split('.');

      if (
        path[0] !== '/' &&
        path[0] !== '' &&
        path[0] !== pathname &&
        questId === path[1]
      ) {
        this.setState({ redirect: true });
      }
    }

    this.handleShadowScroll();

    if (!isResizeListenerSet && !isShadowListenerSet && isMobileViewport()) {
      this.nav.addEventListener('scroll', this.handleResizeTrottled);
      this.nav.addEventListener('scroll', this.handleShadowScroll);
      this.setState((prevState) => {
        return {
          ...prevState,
          isResizeListenerSet: true,
          isShadowListenerSet: true,
        };
      });
    } else if (!isShadowListenerSet) {
      this.nav.addEventListener('scroll', this.handleShadowScroll);
      this.setState((prevState) => {
        return {
          ...prevState,
          isShadowListenerSet: true,
        };
      });
    }
  }

  componentDidUpdate() {
    const {
      location: { pathname },
      params: { questId },
    } = this.props;

    if (localStorage.getItem('WGTStudioRoute') !== `${pathname}.${questId}`)
      localStorage.setItem('WGTStudioRoute', `${pathname}.${questId}`);
  }

  componentWillUnmount() {
    if (isMobileViewport()) {
      this.nav.removeEventListener('scroll', this.handleResizeTrottled);
    }
    this.nav.removeEventListener('scroll', this.handleShadowScroll);
  }

  handleResize = () => {
    const { toggleResizeBlock, isResizeBlock } = this.props;
    const { nav } = this;

    if (nav.scrollTop > 120 && !isResizeBlock) {
      toggleResizeBlock();
    } else if (nav.scrollTop < 10 && isResizeBlock) {
      toggleResizeBlock();
    }
  };

  handleShadowScroll = () => {
    this.setState((prev) => ({
      ...prev,
      contentOverflow: getContentOverflow(this.nav),
    }));
  };

  handleEventDragStart = () => {
    document.body.classList.add(isDraggingClassName);
  };

  handleEventDragEnd = (result) => {
    const {
      questActions: { reorderEvents },
      params: { eventOrder },
      currentQuest: { id: questId },
      history,
    } = this.props;

    document.body.classList.remove(isDraggingClassName);

    const {
      draggableId: eventId,
      source: { index: fromIndex } = {},
      destination: { index: toIndex = null } = {},
    } = nullAsDefault(result);

    // dropped outside the list
    if (toIndex == null) {
      return;
    }

    reorderEvents({
      ids: {
        questId,
        eventId,
      },
      fromIndex,
      toIndex,
    });

    if (fromIndex === parseInt(eventOrder, 10)) {
      history.push(`/quest/${questId}/events/${toIndex}/`);
    }
  };

  getEventsListStyle(isDragginOver) {
    return {};
  }

  renderEvents() {
    const {
      currentQuest: { events, id: questId, isEditable },
      closeSidebar,
    } = this.props;
    if (!events.length) return null;

    return (
      <DragDropContext
        onDragStart={this.handleEventDragStart}
        onDragEnd={this.handleEventDragEnd}
      >
        <Droppable droppableId="eventsList">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={this.getEventsListStyle(snapshot.isDraggingOver)}
            >
              {events.map((event) => {
                const next =
                  event.order < events.length ? events[event.order + 1] : null;
                return (
                  <EventNavLink
                    key={event.id}
                    event={event}
                    next={next}
                    questId={questId}
                    isDraggable={isEditable}
                    onClick={closeSidebar}
                  />
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  checkTrigger(event, events) {
    const prev = events.filter((evt) => evt.order == event.order - 1).length
      ? events.filter((evt) => evt.order == event.order - 1)[0]
      : null;
    let complete = [
      event.order == 0,
      event.trigger && event.trigger.geo,
      event.trigger && event.trigger.time,
      event.trigger && event.trigger.ar,
    ].some(Boolean);
    if (!complete && prev) {
      complete =
        prev.content.filter((event) => {
          return event.contentType == 'choice' && event.visible == true;
        }).length > 0;
    }
    return complete;
  }

  getEventAddTip(event, events, questId) {
    const { closeSidebar } = this.props;
    let notCompleted = false;
    events.forEach((event) => {
      const check = this.checkTrigger(event, events);
      if (!check) {
        notCompleted = event;
      }
    });
    const prev =
      notCompleted.order != 0 ? events[notCompleted.order - 1] : null;
    const prevLink = prev ? (
      <Link
        to={`/quest/${questId}/events/${prev.order}/triggers`}
        className="EventPage__EventLink"
        onClick={closeSidebar}
      >
        <FormattedMessage id="Sidebar.add" defaultMessage="set" />
      </Link>
    ) : null;
    if (!notCompleted) return;
    // if (!prev)
    // return <span>Чтобы добавить новое событие, задайте условия наступления в {currentLink}</span>;
    return (
      <div className="Sidebar__EventAddTip">
        <FormattedMessage
          id="Sidebar.eventAddTipText"
          defaultMessage="To add a new event, {prevLink} the conditions for the event «{title}» to occur"
          values={{
            prevLink,
            title: notCompleted.title,
          }}
        />
      </div>
    );
  }

  handleAddEvent(options) {
    const {
      currentQuest: { events },
      eventActions,
      params: { questId },
      history,
    } = this.props;
    eventActions.addEvent(options);
    history.push(`/quest/${questId}/events/${events.length - 1}/`);
  }

  renderEventsHeading() {
    const {
      currentQuest: { events },
    } = this.props;
    return (
      <div className="nav__heading">
        {events.length > 0 ? (
          <FormattedMessage
            id="Sidebar.eventsHeading"
            defaultMessage="List of the tour events"
          />
        ) : (
          <FormattedMessage
            id="Sidebar.noEventsHeading"
            defaultMessage="There aren't events yet"
          />
        )}
      </div>
    );
  }

  renderCodesLink() {
    const {
      user,
      params: { questId },
      closeSidebar,
    } = this.props;
    if (!user.client) return null;
    return (
      <NavLink
        to={`/quest/${questId}/codes`}
        className="nav__link"
        activeClassName="nav__link--active"
        onClick={closeSidebar}
      >
        <CodesIcon />
        <FormattedMessage id="Sidebar.codesText" defaultMessage="Codes" />
      </NavLink>
    );
  }

  renderSidebarItems() {
    const {
      match: {
        params: { questId },
      },
      currentQuest,
      closeSidebar,
      eventActions,
      publishing,
      questActions,
      checkFullness,
      prices,
      user,
    } = this.props;

    return (
      <SidebarItems
        user={user}
        questId={questId}
        currentQuest={currentQuest}
        closeSidebar={closeSidebar}
        questActions={questActions}
        eventActions={eventActions}
        publishing={publishing}
        checkFullness={checkFullness}
        prices={prices}
      />
    );
  }

  renderDesktopSidebar() {
    const { contentOverflow } = this.state;
    return (
      <nav
        ref={(n) => {
          this.nav = n;
        }}
        className={classNames(
          'nav',
          ...Object.keys(contentOverflow)
            .filter((side) => !contentOverflow[side])
            .map((side) => `nav--hide-${side}`)
        )}
      >
        {this.renderSidebarItems()}
      </nav>
    );
  }

  renderMobileSidebar() {
    const { contentOverflow } = this.state;
    const {
      user,
      currentQuest,
      currentPlans,
      publishingActions,
      params,
      questActions,
      isPopupOpen,
      openPopup,
      closePopup,
      closeSidebar,
      publishing,
    } = this.props;
    return (
      <nav
        ref={(n) => {
          this.nav = n;
        }}
        className={classNames(
          'nav',
          Object.keys(contentOverflow)
            .filter((side) => !contentOverflow[side])
            .map((side) => `nav--hide-${side}`)
        )}
      >
        <WithQuestStatus
          quest={currentQuest}
          render={({ isQuestValid }) => (
            <QuestShare
              user={user}
              currentQuest={currentQuest}
              currentPlans={currentPlans}
              publishing={publishing}
              publishingActions={publishingActions}
              params={params}
              questActions={questActions}
              isPopupOpen={isPopupOpen}
              openPopup={openPopup}
              closePopup={closePopup}
              closeSidebar={closeSidebar}
              isQuestValid={isQuestValid}
            />
          )}
        />
        {this.renderSidebarItems()}
      </nav>
    );
  }

  render() {
    const {
      currentQuest,
      isResizeBlock,
      params: { questId },
      isSidebarOpen,
      currentEvent: event,
      intl: { formatMessage },
      prices,
    } = this.props;
    const { events, isEditable } = currentQuest;
    const eventAddTip = this.getEventAddTip(event, events, questId);

    return (
      <aside
        className={classNames(
          'App__aside',
          isSidebarOpen && 'App__aside--on',
          isResizeBlock && 'App__aside--resize'
        )}
      >
        {this.state.redirect &&
          this.renderRedirect(
            localStorage.getItem('WGTStudioRoute').split('.')[0]
          )}
        <Mobile>{this.renderMobileSidebar()}</Mobile>
        <Desktop>{this.renderDesktopSidebar()}</Desktop>
        {/* {!eventAddTip && isEditable ? (
          <EventAdd
            className="Sidebar__EventAdd"
            questId={questId}
            onAdd={this.handleAddEvent.bind(this)}
            placeholder={formatMessage(message.placeholder)}
            event={event}
          />
        ) : isEditable ? (
          eventAddTip
        ) : null} */}
      </aside>
    );
  }
}

Sidebar.propTypes = {
  userActions: PropTypes.objectOf(PropTypes.func).isRequired,
  intl: intlShape.isRequired,
  currentEvent: PropTypes.object.isRequired,
};

export default withRouter(injectIntl(Sidebar));
