import React from 'react';
import Typography from '@material-ui/core/Typography';
import './TourDetails.css';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Button, Paper, TextField, makeStyles } from '@material-ui/core';
import * as publishingActionsPure from '../../../actions/publishing';
import Examples from '../../Examples';
import TourContentFooterButtons from '../../TourContentFooter/TourContentFooterButtons';
import HelperLink from '../../HelperLink';
import { HELP_CENTER_URL } from '../../../constants/branding';

/**
 * JSS styles for `TourDetails` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 24,
    marginTop: 40,
    marginBottom: -15,
  },
  container: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
  },
  textFieldM: {
    width: '88%',
    maxWidth: 950,
    marginBottom: 15,
  },
  examplesTitle: {
    marginTop: 30,
  },
  examples: {
    marginTop: 15,
    marginBottom: 35,
  },
  cardTitle: {
    marginBottom: 14,
  },
  helperFullness: {
    marginTop: 40,
    marginBottom: -25,
  },
}));

/**
 * Exclusions sentences, that must be disabled
 */
const DISABLED_EXCLUSIONS = [
  'Наушники — пожалуйста, возьмите свои',
  'Earphones — please bring your own',
];

/**
 * Messages for `TourDetails` component
 * @type {Object}
 */
const messages = defineMessages({
  whatsIncluded: {
    id: 'StudioCreateProduct.whatsIncluded',
    defaultMessage: "What's included?",
  },
  whatsNotIncluded: {
    id: 'StudioCreateProduct.whatsNotIncluded',
    defaultMessage: "What's not included?",
  },
  inclusions: {
    id: 'StudioCreateProduct.TourDetailsInclusions',
    defaultMessage: 'what is included in the tour',
  },
  exclusions: {
    id: 'StudioCreateProduct.TourDetailsExclusions',
    defaultMessage: 'what is not included in the tour',
  },
});

const TourDetails = ({
  currentQuest: { products },
  currentQuestId,
  intl,
  publishingActions,
  publishing: { inclusions, exclusions },
  checkFullness,
  location,
  userLocale,
}) => {
  /**
   * JSS styles for `TourDetails` element
   * @type {React::Hook}
   */
  const classes = useStyles();

  /**
   * Validate nextValue value and send it to server
   * @param {Array[Object]} oldValues - old listed objects array
   * @param {Object} nextValue - new object
   * @param {String} text - new object text
   * @param {String} attribute - product attribute key name
   */
  const handleSave = (oldValues, newValue, text, attribute) => {
    if (Object.getOwnPropertyDescriptor(newValue, 'id')) {
      if (
        text === oldValues.find((e) => e.id === newValue.id).text ||
        text === ''
      )
        return;
      publishingActions.sendListedData(
        products[0].id,
        newValue.id,
        { text },
        attribute
      );
    } else if (text !== '') {
      publishingActions.postListedData(products[0].id, { text }, attribute);
    }
  };

  /**
   * Checking for delete or save listed object
   * @param {Array[Object]} oldValues - listed objects array
   * @param {Object} nextValue - new object
   * @param {String} text - new object text
   * @param {String} attribute - product attribute key name
   */
  const handleBlur = (oldValues, value, text, attribute) => {
    if (text === '' && Object.getOwnPropertyDescriptor(value, 'id')) {
      publishingActions.deleteListedData(products[0].id, value.id, attribute);
    }
    if (text !== '') handleSave(oldValues, value, text, attribute);
  };

  /**
   * Checking the filling of fields and returning what is not filled
   * @returns {String} - fields that are not filled
   */
  const handleFullnessText = () => {
    const entities = [];

    if (!inclusions.length) {
      entities.push(intl.formatMessage(messages.inclusions));
    }
    if (!exclusions.length) {
      entities.push(intl.formatMessage(messages.exclusions));
    }

    return entities.join(', ');
  };

  const examples = (
    <Typography gutterBottom className={classes.examplesTitle} variant="h6">
      <FormattedMessage
        id="StudioCreateProduct.TourDetailsExamples"
        defaultMessage="Examples"
      />
    </Typography>
  );

  const deleteWord = (
    <FormattedMessage
      id="StudioCreateProduct.tourDetailsDelete"
      defaultMessage="Delete"
    />
  );

  const addItem = (
    <FormattedMessage
      id="StudioCreateProduct.tourDetailsAddItem"
      defaultMessage="Add another item"
    />
  );

  return (
    <section className="App__main TourDetails__main">
      <Container className={classes.container}>
        <Paper elevation={0} className={classes.paper}>
          <Typography className={classes.cardTitle} variant="h5">
            <FormattedMessage
              id="StudioCreateProduct.tourDetailIncluded"
              defaultMessage="What's included in tour"
            />
          </Typography>

          <Typography gutterBottom variant="body1">
            <FormattedMessage
              id="StudioCreateProduct.IncludeSubhead"
              defaultMessage="Describe in three short phrases what is included in the tour."
            />
          </Typography>

          <HelperLink
            marginBottom={2}
            text={
              <FormattedMessage
                id="StudioCreateProduct.whatCanBeIncluded"
                defaultMessage="What can be included in the tour"
              />
            }
            link={
              userLocale === 'ru'
                ? `${HELP_CENTER_URL}ru/inclusions#inclusions/`
                : `${HELP_CENTER_URL}${userLocale}/inclusions/`
            }
          />

          {examples}

          <div className={classes.examples}>
            <Examples
              withWords
              good={[
                <FormattedMessage
                  key="good1"
                  id="StudioCreateProduct.IncludedExample1"
                  defaultMessage="Life hack, how to get to the greenhouse of the Tavrichesky Garden for free"
                />,
                <FormattedMessage
                  key="good2"
                  id="StudioCreateProduct.IncludedExample2"
                  defaultMessage="Recommendations for bars and pubs for a great evening"
                />,
                <FormattedMessage
                  key="good3"
                  id="StudioCreateProduct.IncludedExample3"
                  defaultMessage="Access to the secret observation deck"
                />,
              ]}
              bad={[
                <FormattedMessage
                  key="bad1"
                  id="StudioCreateProduct.IncludedExample4"
                  defaultMessage="2 hour city walk"
                />,
                <FormattedMessage
                  key="bad2"
                  id="StudioCreateProduct.IncludedExample5"
                  defaultMessage="Audio guide from a local"
                />,
              ]}
            />
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              included:
                // eslint-disable-next-line no-nested-ternary
                inclusions && inclusions.length
                  ? inclusions.length < 5
                    ? [...inclusions, { text: '' }]
                    : [...inclusions]
                  : [{ text: '' }],
            }}
          >
            {({ values }) => (
              <Form>
                <FieldArray
                  name="included"
                  render={(arrayHelpers) => (
                    <div>
                      {values.included.map((elem, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index}>
                          <Field
                            className={classes.textFieldM}
                            placeholder={intl.formatMessage(
                              messages.whatsIncluded
                            )}
                            name={`included.${index}.text`}
                            error={
                              !inclusions.length &&
                              location.highlights &&
                              index === 0
                            }
                            as={TextField}
                            label={
                              <FormattedMessage
                                id="StudioCreateProduct.TourDetailInclusionsItem"
                                defaultMessage="Item {value}"
                                values={{
                                  value: index + 1,
                                }}
                              />
                            }
                            variant="standard"
                            type="inclusions"
                            helperText={`${elem.text.length}/100`}
                            inputProps={{ maxLength: 100 }}
                            onBlur={(e) =>
                              handleBlur(
                                inclusions,
                                elem,
                                e.target.value,
                                'inclusions'
                              )
                            }
                          />{' '}
                          {values.included.length !== 1 && (
                            <Button
                              color="primary"
                              onClick={() =>
                                Object.getOwnPropertyDescriptor(elem, 'id')
                                  ? publishingActions.deleteListedData(
                                      products[0].id,
                                      elem.id,
                                      'inclusions'
                                    )
                                  : arrayHelpers.remove(index)
                              }
                            >
                              {deleteWord}
                            </Button>
                          )}
                        </div>
                      ))}
                      <Button
                        color="primary"
                        disabled={
                          values.included[values.included.length - 1].text ===
                            '' || values.included.length === 5
                        }
                        onClick={() =>
                          values.included.length < 5 &&
                          arrayHelpers.push({ text: '' })
                        }
                      >
                        {addItem}
                      </Button>
                    </div>
                  )}
                />
              </Form>
            )}
          </Formik>
        </Paper>

        <Paper elevation={0} className={classes.paper}>
          <Typography className={classes.cardTitle} variant="h5">
            <FormattedMessage
              id="StudioCreateProduct.tourDetailNotIncluded"
              defaultMessage="What's not included in tour"
            />
          </Typography>

          <Typography gutterBottom variant="body1">
            <FormattedMessage
              id="StudioCreateProduct.notIncludeSubhead"
              defaultMessage="Describe in three short phrases what is not included in the tour."
            />
          </Typography>

          <HelperLink
            marginBottom={2}
            text={
              <FormattedMessage
                id="StudioCreateProduct.whatNotToInclude"
                defaultMessage="What not to include in the tour"
              />
            }
            link={
              userLocale === 'ru'
                ? `${HELP_CENTER_URL}ru/inclusions#exclusions/`
                : `${HELP_CENTER_URL}${userLocale}/inclusions/`
            }
          />

          {examples}

          <div className={classes.examples}>
            <Examples
              withWords
              good={[
                <FormattedMessage
                  key="good1"
                  id="StudioCreateProduct.exclusionsExample1"
                  defaultMessage="Food and drinks - purchased on your own at will"
                />,
                <FormattedMessage
                  key="good2"
                  id="StudioCreateProduct.exclusionsExample2"
                  defaultMessage="Church concerts and performances"
                />,
                <FormattedMessage
                  key="good3"
                  id="StudioCreateProduct.exclusionsExample3"
                  defaultMessage="Photo services and souvenirs"
                />,
              ]}
            />
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              notIncluded:
                // eslint-disable-next-line no-nested-ternary
                exclusions && exclusions.length
                  ? exclusions.length < 5
                    ? [...exclusions, { text: '' }]
                    : [...exclusions]
                  : [{ text: '' }],
            }}
          >
            {({ values }) => (
              <Form>
                <FieldArray
                  name="notIncluded"
                  render={(arrayHelpers) => (
                    <div>
                      {values.notIncluded.map((elem, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index}>
                          <Field
                            className={classes.textFieldM}
                            placeholder={intl.formatMessage(
                              messages.whatsNotIncluded
                            )}
                            name={`notIncluded.${index}.text`}
                            disabled={
                              index === 0 &&
                              DISABLED_EXCLUSIONS.includes(elem.text)
                            }
                            as={TextField}
                            label={
                              <FormattedMessage
                                id="StudioCreateProduct.TourDetailExclusionsItem"
                                defaultMessage="Item {value}"
                                values={{
                                  value: index + 1,
                                }}
                              />
                            }
                            variant="standard"
                            type="exclusions"
                            helperText={`${elem.text.length}/100`}
                            inputProps={{ maxLength: 100 }}
                            onBlur={(e) =>
                              handleBlur(
                                exclusions,
                                elem,
                                e.target.value,
                                'exclusions'
                              )
                            }
                          />
                          {values.notIncluded.length !== 1 &&
                            !(
                              index === 0 &&
                              DISABLED_EXCLUSIONS.includes(elem.text)
                            ) && (
                              <Button
                                color="primary"
                                onClick={() =>
                                  Object.getOwnPropertyDescriptor(elem, 'id')
                                    ? publishingActions.deleteListedData(
                                        products[0].id,
                                        elem.id,
                                        'exclusions'
                                      )
                                    : arrayHelpers.remove(index)
                                }
                              >
                                {deleteWord}
                              </Button>
                            )}
                        </div>
                      ))}
                      <Button
                        color="primary"
                        disabled={
                          values.notIncluded[values.notIncluded.length - 1]
                            .text === '' || values.notIncluded.length === 5
                        }
                        onClick={() =>
                          values.notIncluded.length < 5 &&
                          arrayHelpers.push({ text: '' })
                        }
                      >
                        {addItem}
                      </Button>
                    </div>
                  )}
                />
              </Form>
            )}
          </Formik>
        </Paper>

        {!checkFullness('details') && (
          <Typography className={classes.helperFullness} variant="body1">
            <FormattedMessage
              id="StudioCreateProduct.tourDetailsRemainsToFill"
              defaultMessage="Remaining sections to fill in: "
            />
            {handleFullnessText()}
          </Typography>
        )}
        <TourContentFooterButtons
          leftLink={`/quest/${currentQuestId}/highlights`}
          rightLink={
            location.highlights
              ? `/quest/${currentQuestId}/check`
              : `/quest/${currentQuestId}/photos`
          }
          withRedirectToTourCheck={location.highlights}
          leftBtnVisible={!location.highlights}
        />
      </Container>
    </section>
  );
};

function mapStateToProps() {
  return (state) => {
    return {
      publishing: state.publishing,
      currentQuestId: state.quests.currentQuestId,
      userLocale: state.user.locale,
    };
  };
}

function mapDispatchToProps(dispatch) {
  return {
    publishingActions: bindActionCreators(publishingActionsPure, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(TourDetails)));
