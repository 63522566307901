/**
 * Id of the array item (media) relates to and index of the item in this array
 * @typedef {Object} MediaId
 * @prop {String} arrayId - id of the array media relates to
 * @prop {Number} index - index of the media in related array
 */

/**
 * Creates object of `MediaId` type
 * @param {String} arrayId - id of the array media relates to
 * @param {Number} index - index of the media in related array
 * @returns {MediaId}
 */
export default function fomMediaId(arrayId, index) {
  return { arrayId, index };
}
