import React, { useState } from 'react';
import StructuredPopup from '../../components/Popup/StructuredPopup';

/**
 * Hook that displays a modal window with a given image
 */
export default function usePhotoFull() {
  /**
   * States for showing and closing popup with photo
   * @type {Boolean}
   */
  const [isShowPhotoPopup, setIsShowPhotoPopup] = useState(false);

  /**
   * States for current clicked photo src
   * @type {String}
   */
  const [currentPhoto, setCurrentPhoto] = useState('');

  const handleSetPhoto = (img) => {
    setCurrentPhoto(img);
    setIsShowPhotoPopup(true);
  };

  return {
    PhotoFullComponent: (
      <StructuredPopup
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        theme="photo"
        isOpen={isShowPhotoPopup}
        handleCloseClick={() => setIsShowPhotoPopup(false)}
      >
        <img src={currentPhoto} alt="photo_full" />
      </StructuredPopup>
    ),
    handleSetPhoto,
  };
}
