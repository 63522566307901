import React from 'react';
import uuid from 'react-uuid';
import GetAppIcon from '@material-ui/icons/GetApp';

import classNames from '../../functions/classNames';
import {
  DoneIcon,
  SettingsErrorIcon,
  SettingsIcon,
  SpinnerIcon,
} from '../Icon';

import './ImportantNote.css';

export default ({ title, items = [], className, props, theme, icon = '' }) => {
  const Icon =
    icon ||
    (theme === 'helper-error' ? (
      <SettingsErrorIcon />
    ) : theme === 'done' ? (
      <DoneIcon />
    ) : theme === 'helper' ? (
      <SettingsIcon />
    ) : theme === 'spinner-error' ? (
      <SpinnerIcon />
    ) : (
      ''
    ));

  return (
    !!items.length && (
      <div
        className={classNames(
          'ImportantNote',
          className,
          theme && `ImportantNote--${theme}`
        )}
        {...props}
      >
        <div className="ImportantNote__content">
          {Icon}
          {title && <p className="ImportantNote__title">{title}</p>}
        </div>
        <ul className="ImportantNote__list">
          {items.map((item) => (
            <li key={uuid()} className="ImportantNote__item">
              {item}
            </li>
          ))}
        </ul>
      </div>
    )
  );
};
