import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FirstSteps, Workspace, FillingEvent } from './TutorialsName';

const TutorialsArray = [
  {
    name: FirstSteps,
    anchor: 'firststeps',
    formattedName: (
      <FormattedMessage
        id="TutorialsArray.FirstStepsName"
        defaultMessage="First Steps"
      />
    ),
  },
  {
    name: Workspace,
    anchor: 'workspace',
    formattedName: (
      <FormattedMessage
        id="TutorialsArray.WorkspaceName"
        defaultMessage="Workspace"
      />
    ),
  },
  {
    name: FillingEvent,
    anchor: 'fillingevent',
    formattedName: (
      <FormattedMessage
        id="TutorialsArray.FillingEventName"
        defaultMessage="How to create an event"
      />
    ),
  },
];

export default TutorialsArray;
