import { Component } from 'react';
import { isEqual } from 'lodash';
import nullAsDefault from '../../functions/nullAsDefault';
import { checkQuestValid } from '../../functions/validation/checkQuestValid';
import { QuestType } from '../../constants/PropTypes';

class WithQuestStatus extends Component {
  state = {
    isQuestValid: true,
  };

  makeState(events) {
    if (events === null) {
      return;
    }
    this.setState({
      isQuestValid: checkQuestValid(events),
    });
  }

  componentDidMount() {
    if (!this.props.quest) {
      return;
    }
    const {
      quest: { events },
    } = nullAsDefault(this.props);
    this.makeState(events);
  }

  componentWillReceiveProps(nextProps) {
    if (isEqual(this.props, nextProps)) {
      return;
    }
    if (!nextProps.quest) {
      return;
    }

    const { quest: { events } = {} } = nullAsDefault(nextProps);
    this.makeState(events);
  }

  render() {
    const { render } = this.props;
    const { isQuestValid } = this.state;
    return render({ isQuestValid });
  }
}

WithQuestStatus.propTypes = {
  quest: QuestType,
};

export default WithQuestStatus;
