import compareAndFilterArraysOfObjects from '../array/compareAndFilterArraysOfObjects';

/**
 * Filters attractions tiskets
 * @param {object} product - info about quest(product) from server
 * @returns {object[]}  array of attractions
 */
export default function attractionsWithFilteredTickets(product) {
  return product.attractions
    .sort((a, b) => a.attraction?.id - b.attraction?.id)
    .map(({ attraction, admittance }) => ({
      ...attraction,
      entrance: admittance,
      tickets: compareAndFilterArraysOfObjects(
        attraction?.tickets,
        product.tickets,
        'id'
      ),
    }));
}
