import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Control from 'react-leaflet-control';
import classNames from 'classnames';
import './GeolocationControl.css';
import { defineMessages, intlShape, injectIntl } from 'react-intl';

const HIDE_AFTER = 5000;

const message = defineMessages({
  text: {
    id: 'GeolocationControl.errorText',
    defaultMessage: 'An error of the location definition',
  },
});

class GeolocationControl extends Component {
  state = {
    status: null,
  };

  changeStatus = (status) => {
    this.setState((prev) => {
      return { ...prev, status };
    });
  };

  handleLocate = () => {
    const { onSuccess, onError = () => {} } = this.props;

    this.changeStatus('fetching');

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const {
          coords: { latitude, longitude },
        } = position;
        this.changeStatus('success');
        setTimeout(() => {
          this.changeStatus(null);
        }, HIDE_AFTER);
        onSuccess({ lat: latitude, lon: longitude, address: undefined });
      },
      (error) => {
        this.changeStatus('error');
        onError(error);

        setTimeout(() => {
          this.changeStatus(null);
        }, HIDE_AFTER);
      }
    );
  };

  render() {
    const {
      position,
      intl: { formatMessage },
    } = this.props;
    const { status } = this.state;
    const isError = status === 'error';
    return (
      <Control position={position} className="leaflet-bar GeolocationControl">
        <div className="GeolocationControl__wrapper">
          <div
            className={classNames(
              'GeolocationControl__link',
              `GeolocationControl__link--${status}`
            )}
            onTouchEnd={this.handleLocate}
            onClick={this.handleLocate}
          />
          <div
            className={classNames(
              'GeolocationControl__message',
              isError && 'GeolocationControl__message--active'
            )}
          >
            {formatMessage(message.text)}
          </div>
        </div>
      </Control>
    );
  }
}

GeolocationControl.propTypes = {
  position: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func,
  intl: intlShape,
};

export default injectIntl(GeolocationControl);
export { GeolocationControl as PureGeolocationControl };
