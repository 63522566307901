import React, { Component } from 'react';
import classNames from 'classnames';
import './Input.css';

/**
 * Just input field
 * @param {String} wrapperClassName - class name added for input container
 * @param {String} theme - component theme name
 */
class Input extends Component {
  render() {
    const { theme = '', wrapperClassName = '', ...props } = this.props;

    return (
      <div
        className={classNames(
          'Input__wrapper',
          theme ? `Input--theme-${theme}` : '',
          wrapperClassName
        )}
      >
        <input className="Input__input" {...props} />
        <div className="Input__view" />
        {props.children}
      </div>
    );
  }
}

export default Input;
