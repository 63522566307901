/**
 * Returns not negative numbers
 * @param {String} value - number in string
 * @return {Number} - parsed not negative number
 */
export const getNotNegativeNumber = (value) => {
  let newValue;
  if (value !== '-') newValue = Number(value);
  else newValue = 0;
  return newValue;
};

/**
 * Strips leading zeros from string
 * @param {String} value - number in string
 * @return {String} - stripped string
 */
export const stripLeadingZeros = (value) => {
  let nextValue = value;
  if (nextValue !== 0 && nextValue.startsWith('0') && nextValue.length > 1)
    nextValue = nextValue.replace(/^0+/, '');
  return nextValue;
};

/**
 * Replaces comma to dot
 * @param {String} value - number in string
 * @return {String} - stripped string
 */
export const replaceCommaToDot = (value) => {
  let nextValue = value;
  nextValue = nextValue.replace(/,+/, '.');
  nextValue = nextValue.replace(/,/g, '');
  return nextValue;
};

/**
 * Formats numbers in string to price format (eg. 123.45)
 * @param {String} value - number in string
 * @return {String} - stripped string
 */
export const formatToPrice = (value) => {
  if (!value) return 0;
  let nextValue = value;
  nextValue = replaceCommaToDot(nextValue);
  nextValue = stripLeadingZeros(nextValue);
  return nextValue;
};
