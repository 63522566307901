import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  intlShape,
  injectIntl,
  defineMessages,
  FormattedMessage,
} from 'react-intl';
import Dropdown from 'rc-dropdown';
import { camelize } from 'humps';
import { QuestType } from '../../constants/PropTypes';
import ProductIcon from '../ProductIcon';
import Button from '../Button';
import Input from '../Input';
import StructuredPopup from '../Popup/StructuredPopup';
import QuestStatus from '../QuestStatus';

import 'rc-dropdown/assets/index.css';
import './Quest.css';
import { getTourTitleString } from '../../constants/quests/questsConstants';

/**
 * Messages from action popups
 * @type {Object}
 */
const actionsMessages = defineMessages({
  clone: {
    id: 'Quest.actionsClone',
    defaultMessage: 'Clone',
  },
  cloneTitle: {
    id: 'Quest.actionsCloneTitle',
    defaultMessage: 'Clone "{title}"?',
  },
  cloning: {
    id: 'Quest.actionCloning',
    defaultMessage: 'Cloning...',
  },
  delete: {
    id: 'Quest.actionsDelete',
    defaultMessage: 'Delete',
  },
  deleteTitle: {
    id: 'Quest.actionsDeleteTitle',
    defaultMessage: 'Delete "{title}"?',
  },
  deleteMessage: {
    id: 'Quest.actionsDeleteMessage',
    defaultMessage:
      'Your tour includes {content}. Are you sure you want to delete it? This action will be undone.',
  },
  cloneMessage: {
    id: 'Quest.actionsCloneMessage',
    defaultMessage: 'Write a name for a copy of the tour.',
  },
  event: {
    id: 'Quest.amountEvents',
    defaultMessage:
      '{amount, plural, =0 {0 events} =1 {1 event} other {{amount} events}}',
  },
  image: {
    id: 'Quest.amountImages',
    defaultMessage: '{amount, plural, =1 {1 image} other {{amount} images}}',
  },
  audio: {
    id: 'Quest.amountAudios',
    defaultMessage: '{amount, plural, =1 {1 audio} other {{amount} audios}}',
  },
});

/**
 * Errors appearing below input in copying dialog
 * @type {Object}
 */
const cloningErrors = defineMessages({
  empty: {
    id: 'Quest.actionsCloneErrorEmpty',
    defaultMessage: 'Tour title must not be empty',
  },
  equal: {
    id: 'Quest.actionsCloneErrorEqual',
    defaultMessage: 'Cloned tour title must not be same with original one',
  },
});

/**
 * Shows popup on respond to the action selected in actions tooltip
 * @param {String} $.type - type of action
 */
export function QuestActionsPopup({
  type,
  formatMessage,
  questId,
  quests,
  productId,
  cloneTitle = '',
  cloningError = '',
  setCloningError = () => {},
  deleteQuest = () => {},
  cloneQuest = () => {},
  handleClose = () => {},
  setCloneTitle = () => {},
}) {
  const { events = [], title = '' } = quests.find(
    (quest) => quest.id === questId
  );
  let header = null;
  let middle = null;
  let buttons = null;
  /**
   * Forms string with each type of content tour has
   * @return {String}
   */
  const getQuestContentDescription = () => {
    const eventAmount = formatMessage(actionsMessages.event, {
      amount: events.length,
    });

    const amounts = ['image', 'audio'].reduce((acc, type) => {
      const amount = events.reduce((acc, event) => {
        const data = event.content.find(
          (content) => content.contentType === type
        );
        acc += data && data.data ? data.data.length : 0;
        return acc;
      }, 0);

      if (amount > 0) {
        acc.push(formatMessage(actionsMessages[type], { amount }));
      }

      return acc;
    }, []);

    amounts.splice(0, 0, eventAmount);
    return amounts.join(', ');
  };

  /**
   * Deletes quest
   * Hides deleting popup
   */
  const handleRemove = () => {
    handleClose();
    deleteQuest(questId, productId);
  };

  /**
   * Clones quest
   * Hides clone popup and clears clone action input field
   */
  const handleClone = () => {
    if (cloneTitle === '') {
      setCloningError(formatMessage(cloningErrors.empty));
      return;
    }
    if (cloneTitle === title) {
      setCloningError(formatMessage(cloningErrors.equal));
      return;
    }

    handleClose();
    setCloneTitle('');
    cloneQuest(questId, cloneTitle, false);
  };

  /**
   * Tracks changes in clone action popup input
   * @param {String} value - new clone title
   */
  const handleCloneTitle = ({ target: { value } }) => {
    setCloneTitle(value);
    setCloningError(null);
  };

  switch (type) {
    case 'delete':
      header = formatMessage(actionsMessages.deleteTitle, {
        title: getTourTitleString(title, formatMessage),
      });
      middle = formatMessage(actionsMessages.deleteMessage, {
        content: getQuestContentDescription(),
      });
      buttons = (
        <Button
          className="Quest__actions-popup-btn--delete"
          theme="action"
          onClick={handleRemove}
        >
          {formatMessage(actionsMessages.delete)}
        </Button>
      );
      break;
    case 'clone':
      header = formatMessage(actionsMessages.cloneTitle, {
        title,
      });
      middle = (
        <Fragment>
          {formatMessage(actionsMessages.cloneMessage)}
          <Input
            wrapperClassName="Quest__actions-input--clone"
            theme="card"
            placeholder={title}
            onChange={handleCloneTitle}
          />
          {cloningError ? (
            <div className="Quest__actions-input--error">{cloningError}</div>
          ) : null}
        </Fragment>
      );
      buttons = (
        <Button theme="action" onClick={handleClone}>
          {formatMessage(actionsMessages.clone)}
        </Button>
      );
      break;
    default:
      header = formatMessage(actionsMessages.deleteTitle, {
        title,
      });
      middle = formatMessage(actionsMessages.deleteMessage, {
        content: getQuestContentDescription(),
      });
      buttons = (
        <Button
          className="Quest__actions-popup-btn--delete"
          theme="action"
          onClick={handleRemove}
        >
          {formatMessage(actionsMessages.delete)}
        </Button>
      );
      break;
  }

  return (
    <StructuredPopup
      isOpen
      title={header}
      theme="card"
      handleCloseClick={() => {
        handleClose();
        setCloneTitle('');
      }}
    >
      {middle}
      <div className="Quest__actions-popup-btns">
        <Button
          className="Quest__actions-popup-btn"
          theme="action"
          onClick={() => {
            handleClose();
            setCloneTitle('');
          }}
        >
          <FormattedMessage id="Quest.actionsCancel" defaultMessage="Cancel" />
        </Button>
        {buttons}
      </div>
    </StructuredPopup>
  );
}

/**
 * Shows card with info about quest
 * @param {Object} quest - description of the quest
 * @param {Function} deleteQuest - deletes current quest
 * @param {Function} cloneQuest  - adds a copy of current quest to the tours list
 */
class Quest extends Component {
  /**
   * State of the component
   * @prop {String} cloneTitle - name of the cloned quest (string tracks input field changes in clone action popup)
   * @prop {String} cloningError - error displaying below input in cloning dialog
   * @prop {String|Null} actionsPopup - popup shown to respond on the action
   */
  state = {
    actionsPopup: null,
    cloningError: null,
    cloneTitle: '',
  };

  setCloningError(value) {
    this.setState({ cloningError: value });
  }

  setCloningTitle(value) {
    this.setState({ cloneTitle: value });
  }

  /**
   * Tracks changes in clone action popup input
   * @param {String} value - new clone title
   */
  handleCloneTitle = ({ target: { value } }) =>
    this.setState({ cloneTitle: value, cloningError: null });

  /**
   * Renders info, about amount of evens in the quest
   * @param {Number} number - amount of the events in the quest
   */
  renderCaption(number) {
    if (number === 0)
      return (
        <FormattedMessage
          id="Quest.captionDef"
          defaultMessage="No events yet"
        />
      );
    return (
      <FormattedMessage
        id="Quest.captionNumberOfQuests"
        defaultMessage="{number} {number, plural, =0 {events} =1 {event} other {events}}"
        values={{
          number,
        }}
      />
    );
  }

  /**
   * Displays cover for quest
   * If no image found in the tour, uses default one
   */
  renderCover() {
    const { quest } = this.props;
    let withImage = false;
    if (
      quest.products.length &&
      quest.products[0].bgImage !== '' &&
      quest.products[0].bgImage !==
        'https://dev.surprizeme.ru/media/store/placeholder.jpg' &&
      quest.products[0].bgImage !==
        'https://app.wegotrip.com/media/CACHE/images/store/placeholder/376ade56532436696b87476131d424f5.jpg'
    )
      withImage = quest.products[0].bgImage;
    let background = {};
    let noImgCSSClass = 'Quest__cover--no-img';

    if (withImage) {
      background = {
        backgroundImage: `url(${quest.products[0].previewImage})`,
      };
      noImgCSSClass = '';
    }

    return (
      <div
        className={classNames('Quest__cover', noImgCSSClass)}
        style={background}
      />
    );
  }

  /**
   * Shows context menu with actions (e.g copy, delete, ...)
   */
  renderActionsTooltip() {
    const {
      isCloning,
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="Quest__actions-tooltip">
        <div
          className={classNames(
            'Quest__actions-option',
            isCloning ? 'Quest__actions-option--disabled' : ''
          )}
          onClick={() => this.setState({ actionsPopup: 'clone' })}
        >
          {isCloning
            ? formatMessage(actionsMessages.cloning)
            : formatMessage(actionsMessages.clone)}
        </div>
        <div
          className={classNames(
            'Quest__actions-option',
            'Quest__actions-option--alert'
          )}
          onClick={() => this.setState({ actionsPopup: 'delete' })}
        >
          {formatMessage(actionsMessages.delete)}
        </div>
      </div>
    );
  }

  /**
   * Displays info about quest status
   * @param {Object} product - info about this quest in shop
   * @param {String|Undefined} product.status - quest shop status ('on_moderation', 'approved' etc.)
   */
  getProductStatus = (product) => {
    let status = (product && product.status) || '';
    status = camelize(status);

    switch (status) {
      case 'waitingForModeration':
        return (
          <div className="Quest__status Quest__status--yellow">
            <FormattedMessage
              id="Quest.waitingForModeration"
              defaultMessage="Waiting for a review"
            />
          </div>
        );
      case 'onModeration':
        return (
          <div className="Quest__status Quest__status--yellow">
            <FormattedMessage
              id="Quest.onModeration"
              defaultMessage="On review"
            />
          </div>
        );
      case 'changesAreNeeded':
        return (
          <div className="Quest__status Quest__status--red">
            <FormattedMessage
              id="Quest.changesAreNeeded"
              defaultMessage="changes are needed"
            />
          </div>
        );
      case 'approved':
        return (
          <div className="Quest__status Quest__status--green">
            <FormattedMessage
              id="Quest.approved"
              defaultMessage="Ready for sale"
            />
          </div>
        );
      default:
        return (
          <div className="Quest__status Quest__status--yellow">
            <FormattedMessage id="Quest.draft" defaultMessage="draft" />
          </div>
        );
    }
  };

  render() {
    const {
      quest,
      quest: { id, title, events = [], isEditable, products, lastModified },
      intl: { formatMessage },
    } = this.props;
    const { actionsPopup } = this.state;

    return (
      <div className="App__inner Quest">
        <Link to={`/quest/${id}/title/`}>
          <div className="Quest__inner-top">
            {this.renderCover()}
            {this.getProductStatus(products[0])}
            <div
              className="Quest__actions"
              onClick={(event) => event.preventDefault()}
            >
              <Dropdown overlay={this.renderActionsTooltip()}>
                <button className="Quest__actions-btn" />
              </Dropdown>
              {actionsPopup ? (
                <QuestActionsPopup
                  type={actionsPopup}
                  formatMessage={formatMessage}
                  quest={quest}
                  cloneTitle={this.state.cloneTitle}
                  cloningError={this.state.cloningError}
                  setCloningError={this.setCloningError}
                  deleteQuest={this.props.deleteQuest}
                  cloneQuest={this.props.cloneQuest}
                  handleClose={() => this.setState({ actionsPopup: null })}
                  setCloneTitle={this.setCloningTitle}
                />
              ) : null}
            </div>
            <ProductIcon quest={quest} min={false} />
          </div>
          <div className="Quest__inner">
            <div className="Quest__count">
              {this.renderCaption(events.length)}
            </div>
            <div className="Quest__header">
              <h2 className="Quest__heading">
                {getTourTitleString(title, formatMessage)}
              </h2>
            </div>
            <div className="Quest__last-modified">
              <QuestStatus full quest={quest} />
            </div>
          </div>
          {!isEditable ? (
            <div className="Quest__downloaded">
              <FormattedMessage
                id="Quest.publishDownload"
                defaultMessage="Published and downloaded"
              />
            </div>
          ) : null}
        </Link>
      </div>
    );
  }
}

/**
 * Copies props from redux state into component props
 * @param {Object} state - redux state
 */
function mapStateToProps(state) {
  return {
    isCloning: state.quests.isCopying,
  };
}

Quest.propTypes = {
  quest: QuestType.isRequired,
  deleteQuest: PropTypes.func.isRequired,
  cloneQuest: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default connect(mapStateToProps)(injectIntl(Quest));
