/**
 * Converts number to string with locale took in count
 * @param {String} locale - locale name (e.g. `"en"`, `"ru"`, etc.)
 * @param {Number} number - number to convert
 * @returns {String} - for `"ru"` locale `"."` will be replaced with `","`
 */
export default function localeNumber(locale, number) {
  let toDisplay = String(number);
  if (locale === 'ru') {
    toDisplay = toDisplay.replace(/\./g, ',');
  }

  return toDisplay;
}
