import React from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { GAP, ROW_STYLES } from '..';

/**
 * Gap between control buttons in pixels
 * @type {Number}
 */
const CONTROLS_GAP = 16;

/**
 * Vertical and horizontal paddings of the control element in `Table::withOptions` controls bar in pixels
 * @type {Array[Number]}
 */
export const CONTROL_PADDING = [4, 5];

/**
 * JSS styles for `Table::withOptions` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  outline: {
    borderTop: ROW_STYLES.borderBottom,
  },
  bar: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    margin: `0px -${CONTROL_PADDING[1]}px 0px -${CONTROL_PADDING[1]}px`,
    '& > *:not(:last-child)': {
      marginRight: `${CONTROLS_GAP}px`,
    },
    padding: `${GAP[1] - CONTROL_PADDING[0]}px 0px`,
  },
}));

/**
 * Adds controls panel to `Table` component
 * @param {Object} Component - overloaded `Table` component
 */
export default function withOptions(Component) {
  /**
   * `Table` component with controls bar on top
   * @param {Object} $
   * @param {React::JSX?} $.startGap - elements to be added on the left side of the options panel
   * @param {React::JSX?} $.endGap - elements to be added on the right side of the options panel
   * @param {...Object} $.tableProps - stuff passed down to `Table` component
   */
  return function ({ startGap, endGap, ...tableProps }) {
    const styles = useStyles();
    const hasControls = startGap || endGap;

    return (
      <div className="TableOptions">
        <div className={styles.outline} />
        {hasControls ? (
          <div className={classNames('flex', styles.bar)}>
            {startGap}
            <div className="flex grow" />
            {endGap}
          </div>
        ) : null}
        <Component {...tableProps} />
      </div>
    );
  };
}
