import { getCookie, setCookie } from './manageCookie';

export function getToken() {
  return getCookie('token') || localStorage.getItem('token');
}

export function deleteToken() {
  if (!window) {
    return;
  }

  let maxCallIndex = 10;
  while (getCookie('token') && maxCallIndex > 0) {
    maxCallIndex--;
    setCookie('token', '', {
      // TODO: remove token in cookie at all
      path: '/',
      domain: `.${window.location.host}`,
      expires: -1,
    });
  }
}
