import * as types from '../constants/ActionTypes';
import { editEvent } from './quests';

export function passEventParams({ questId, eventId }) {
  questId = parseInt(questId, 10);
  return {
    questId,
    eventId,
  };
}

/**
 * Adds event to the quest on specified position or to the end
 * Edits route event bindings if `$.order` specified by incrementing event indexes bound to the route point after new added event
 * @param {Object} $
 * @param {String} $.title - event title
 * @param {Number} $.questId - id of the quest to add event
 * @param {Number?} $.order - new event index (if `undefined` - event will be added to the end)
 * @param {String?} $.html - initial text data in the event
 * @param {String?} $.guid - guid of the event (if `undefined` - will be generated in reducer)
 * @param {Number?} $.time - time of the event activation (if `undefined` - event will not be bound to the time)
 * @param {Function} $.onSyncStart - will be called when quest will be synced (`sync: false` will destroy this behaviour)
 * @param {Boolean} $.sync - is it necessary to sync quest object with server (`true` by default)
 */
export function addEvent({
  questId,
  title,
  html = '',
  order,
  guid,
  time,
  sync = true,
  onSyncStart = () => {},
}) {
  return (dispatch) => {
    /**
     * Action object for redux reducer and `editEvent` action
     * @type {Object}
     */
    const addEventAction = {
      type: types.ADD_EVENT,
      questId,
      title,
      html: html || '',
      order,
      guid,
      time,
      onSyncStart,
    };

    if (sync) {
      dispatch(editEvent(addEventAction));
    } else {
      dispatch(addEventAction);
    }
  };
}

/**
 * Deletes event
 * Edits route points event bindings by decrementing event indexes bound to the route point after new added event
 * @param {Object} $
 * @param {Number} $.questId - id of the quest containing deleting event
 * @param {String} $.eventId - guid of the event to delete
 */
export function deleteEvent({ questId, eventId }) {
  return {
    type: types.DELETE_EVENT_SYNC,
    questId,
    eventId,
  };
}

export function renameEvent(props) {
  return editEvent({
    type: types.RENAME_EVENT,
    title: props.title,
    ...passEventParams(props),
  });
}

export function editEventGeo(props) {
  return editEvent({
    type: types.EDIT_EVENT_GEO,
    geo: props.geo,
    ...passEventParams(props),
  });
}

export function editEventTime(props) {
  return editEvent({
    type: types.EDIT_EVENT_TIME,
    time: props.time,
    ...passEventParams(props),
  });
}

export function editEventText(props) {
  return editEvent({
    type: types.EDIT_EVENT_TEXT,
    text: props.text,
    visible: props.visible,
    ...passEventParams(props),
  });
}

export function editEventDirections({ questId, eventId, data, visible }) {
  return editEvent({
    type: types.EDIT_EVENT_DIRECTIONS,
    questId: parseInt(questId),
    visible: typeof visible === 'boolean' ? visible : !!data.length,
    data,
    ...passEventParams({ questId, eventId }),
  });
}

export function editEventHTML(props) {
  return editEvent({
    type: types.EDIT_EVENT_HTML,
    html: props.html,
    visible: props.visible,
    ...passEventParams(props),
  });
}

export function editEventChoice(props) {
  return editEvent({
    type: types.EDIT_EVENT_CHOICE,
    options: props.options,
    answer: props.answer,
    visible: props.visible,
    ...passEventParams(props),
  });
}

export function removeEventImage(props) {
  return editEvent({
    type: types.REMOVE_EVENT_IMAGE,
    imageId: props.imageId,
    mediaId: props.mediaId,
    ...passEventParams(props),
  });
}

export function removeEventImages(props) {
  return editEvent({
    type: types.REMOVE_EVENT_IMAGES,
    imageIds: props.imageIds,
    mediaIds: props.mediaIds,
    ...passEventParams(props),
  });
}

export function replaceEventImage(eventPK, files, imageId) {
  return {
    type: types.REPLACE_EVENT_IMAGE,
    eventPK,
    files,
    imageId,
  };
}

export function dragEventImage(props) {
  return {
    type: types.DRAG_EVENT_IMAGE,
    imageId: props.imageId,
    newImageId: props.newImageId,
    isUpload: props.isUpload,
    ...passEventParams(props),
  };
}

export function updateEventImageDescription(props) {
  return editEvent({
    type: types.UPDATE_EVENT_IMAGE_DESCRIPTION,
    imageId: props.imageId,
    description: props.description,
    ...passEventParams(props),
  });
}

export function syncDragEventImage(props) {
  return {
    type: types.SYNC_DRAG_EVENT_IMAGE,
    imageId: props.imageId,
    newImageId: props.newImageId,
    mediaId: props.mediaId,
    newMediaId: props.newMediaId,
    questId: props.questId,
    eventId: props.eventId,
    isUpload: props.isUpload,
  };
}

export function removeEventVideo(props) {
  return editEvent({
    type: types.REMOVE_EVENT_VIDEO,
    id: props.id,
    ...passEventParams(props),
  });
}

export function editEventAudios(props) {
  return editEvent({
    type: types.EDIT_EVENT_AUDIOS,
    visible: props.visible,
    autoplay: props.autoplay,
    ...passEventParams(props),
  });
}

export function editEventAudioHTML(props) {
  return editEvent({
    type: types.EDIT_EVENT_AUDIO_HTML,
    html: typeof props.html === 'string' ? props.html : '',
    ...passEventParams(props),
  });
}

export function removeEventAudio(props) {
  return editEvent({
    type: types.REMOVE_EVENT_AUDIO,
    audioId: props.audioId,
    ...passEventParams(props),
  });
}

export function editEventHints(props) {
  return editEvent({
    type: types.EDIT_EVENT_HINTS,
    hints: props.hints,
    ...passEventParams(props),
  });
}

export function editEventSkip(props) {
  return editEvent({
    type: types.EDIT_EVENT_SKIP,
    skip: props.skip,
    button: props.button,
    ...passEventParams(props),
  });
}

export function editEventBonus(props) {
  return editEvent({
    type: types.EDIT_EVENT_BONUS,
    bonus: props.bonus,
    ...passEventParams(props),
  });
}

export function editEventAR(props) {
  return editEvent({
    type: types.EDIT_EVENT_AR,
    ar: props.ar,
    ...passEventParams(props),
  });
}

export function removeARTarget(props) {
  return editEvent({
    type: types.REMOVE_AR_TARGET,
    arTargetId: props.arTargetId,
    ...passEventParams(props),
  });
}

export function removeEventSticker(props) {
  return editEvent({
    type: types.REMOVE_EVENT_STICKER,
    imageId: props.imageId,
    mediaId: props.mediaId,
    ...passEventParams(props),
  });
}

export function reorderEventSticker(props) {
  return editEvent({
    type: types.REORDER_EVENT_STICKER,
    newImageId: props.newImageId,
    imageId: props.imageId,
    mediaId: props.mediaId,
    newMediaId: props.newMediaId,
    isUpload: props.isUpload || false,
    ...passEventParams(props),
  });
}
