import React, { Fragment, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import FlipIcon from '@material-ui/icons/Flip';
import IconButton from '@material-ui/core/IconButton';
import flip from '../../../functions/canvas/flip';
import unite from '../../../functions/function/unite';
import { DEFAULT_TYPE } from '..';
import PictureEditorTool from '../PictureEditorTool';

/**
 * Rotation controls descriptions
 * @type {Array[Object]}
 */
const FLIPS = [
  { Icon: FlipIcon, direction: 0 },
  { Icon: FlipIcon, direction: 90 },
];

/**
 * Messages for `PictureEditor::withFlip` mod
 * @type {Object}
 */
const messages = {
  title: (
    <FormattedMessage id="PictureEditorFlip.title" defaultMessage="Flip" />
  ),
};

/**
 * JSS styles for `withFlip(PictureEditor)` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  ...FLIPS.reduce(
    (acc, { direction }) => ({
      ...acc,
      [`control${direction}`]: {
        transform: `rotate(${direction}deg)`,
      },
    }),
    {}
  ),
}));

/**
 * Adds "flip" tool to `PictureEditor`
 * @param {React::Component} Component - componenet to overload
 */
export default function withFlip(Component) {
  /**
   * `PictureEditor` component with "flip" tool
   * @param {Object} $
   * @param {...Object} $.editorProps - props for `PictureEditor` component
   */
  return function ({
    fileType = DEFAULT_TYPE,
    onApi: onApiExt,
    children,
    ...editorProps
  }) {
    const styles = useStyles();

    /**
     * API of the original `PictureEditor` class instance
     * @type {React::Ref}
     */
    const [editorApi, setEditorApi] = useState(null);
    const onApiComposition = unite(setEditorApi, onApiExt);

    /**
     * Requests `PictureEditor` API to save flipped canvas
     * @param {Number} dir - direction from `FLIPS` const
     */
    const flipCanvas = (dir) => {
      if (editorApi) {
        editorApi.update(
          flip(editorApi.image, dir, { type: fileType }).then(
            ({ base64 }) => base64
          ),
          'withFlip'
        );
      }
    };

    return (
      <Component fileType={fileType} onApi={onApiComposition} {...editorProps}>
        <PictureEditorTool
          title={messages.title}
          controls={
            <Fragment>
              {FLIPS.map(({ Icon, direction }) => (
                <IconButton
                  key={direction}
                  disabled={editorApi && !!editorApi.operation}
                  className={styles[`control${direction}`]}
                  onClick={() => flipCanvas(direction)}
                >
                  <Icon />
                </IconButton>
              ))}
            </Fragment>
          }
        />
        {children}
      </Component>
    );
  };
}
