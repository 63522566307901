import React from 'react';
import { ChevronDownIcon, ChevronUpIcon, LockIcon } from '../../Icon';

export const ToggleButton = ({
  id,
  label,
  isGenerated,
  isOpen,
  toggleVisibility,
  isLocked,
  onButtonClick,
}) => {
  const handleClick = () => {
    if (!isLocked) {
      if (onButtonClick) {
        onButtonClick();
      }
      toggleVisibility(id);
    }
  };

  const buttonClass = `GenerateAiTourForm__button-fields ${
    !isGenerated ? 'GenerateAiTourForm__button-fields_disabled' : ''
  }`;

  return (
    <button className={buttonClass} onClick={handleClick}>
      {label}
      {isGenerated ? (
        isOpen ? (
          <ChevronUpIcon />
        ) : (
          <ChevronDownIcon />
        )
      ) : (
        <LockIcon />
      )}
    </button>
  );
};
