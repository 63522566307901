import React from 'react';
import { NavLink } from 'react-router-dom';
import ListPoint from '..';

/**
 * `ListPoint` with `NavLink`
 * @param {Object} $
 * @param {String} $.linkClassName - CSS class name of the `NavLink`
 * @param {String} $.activeClassName - CSS class name of the activated `NavLink`
 * @param {Object|String} $.to - `to` prop for the `NavLink`
 * @param {...Object} $.pointProps - props for the `ListPoint` component
 */
export default function ({
  linkClassName,
  activeClassName,
  to,
  ...pointProps
}) {
  return (
    <NavLink
      className={linkClassName}
      activeClassName={activeClassName}
      to={to}
    >
      <ListPoint {...pointProps} />
    </NavLink>
  );
}
