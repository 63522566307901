import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import debounce from '../../../functions/debounce';
import { EVENT_MAX_TITLE_LENGTH } from '../../../constants/limits';
import Event from '../../Event';
import EventHeader from '../../Event/EventHeader';

/**
 * Messages for `EventControlsName` component
 * @type {Object}
 */
const messages = defineMessages({
  title: {
    id: 'EventControlsName.title',
    defaultMessage: 'Title',
  },
  placeholder: {
    id: 'EventControlsName.placeholder',
    defaultMessage: 'Enter the step title',
  },
  helper: {
    id: 'EventControlsName.helperText',
    defaultMessage: 'For traveler',
  },
  rename: {
    id: 'EventControlsName.renameEvent',
    defaultMessage: 'Rename the event',
  },
});

/**
 * JSS styles for `EventControlsName` component
 * @type {Function}
 */
const generateStyles = (theme) => ({
  root: {
    display: 'flex',
  },
  nameWrapper: {
    display: 'flex',
    flexGrow: '1',
    '& input': {
      paddingBottom: '10px',
    },
  },
});

/**
 * Event title input
 * @param {Object} $
 * @param {String} $.title - title of the event (will be inserted in the input as initial value)
 * @param {Number} $.questId - id of the quest where title of editing event stored
 * @param {String} $.eventId - id of the event which title is editing
 * @param {Function} $.onRename - calls when input value (event title) was changed with 1sec period
 * @param {Boolean} $.isEditable - if input value is not disabled (`true` by default)
 */
class EventControlsName extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { title: this.props.title };
    this.handleSaveDebounced = debounce(this.handleSave, 1000);
  }

  /**
   * Link to the `EventControlsName__name` element
   * @type {React::Ref}
   */
  nameRef = React.createRef();

  /**
   * Updates initial title value if event/quest changed
   * @param {Object} nextProps
   */
  componentWillReceiveProps(nextProps) {
    const { eventId, questId } = this.props;
    if (eventId !== nextProps.eventId || questId !== nextProps.questId) {
      this.setState({
        ...this.state,
        title: nextProps.title,
      });
    }
  }

  handleEnter = (e) => {
    const { questId, eventId } = this.props;
    if (e.which === 13) {
      const text = e.target.value;
      this.handleSave({ questId, eventId, text });
    }
  };

  handleBlur = () => {
    const { title } = this.state;
    const { questId, eventId } = this.props;
    this.handleSave({ questId, eventId, text: title });
  };

  handleSave = (nextProps) => {
    const { title, onRename } = this.props;
    let { questId, eventId, text: nextTitle } = nextProps;
    nextTitle = nextTitle.trim();
    if (!nextTitle || nextTitle === title) return;
    onRename({
      questId,
      eventId,
      title: nextTitle,
    });
  };

  handleChange = (e) => {
    const { questId, eventId } = this.props;
    const text = e.target.value;
    this.setState({ ...this.state, title: text });
    this.handleSaveDebounced({ questId, eventId, text });
  };

  /**
   * Manually trigger focus on the `nameRef` input element
   */
  focusInput = () => {
    if (this.nameRef && this.nameRef.current) {
      this.nameRef.current.focus();
    }
  };

  render() {
    const {
      classes,
      isEditable = true,
      intl: { formatMessage },
    } = this.props;
    return (
      <Event>
        <EventHeader>{formatMessage(messages.title)}</EventHeader>
        <div className={classes.root} data-tip={formatMessage(messages.rename)}>
          <TextField
            inputRef={this.nameRef}
            classes={{ root: classes.nameWrapper }}
            placeholder={formatMessage(messages.placeholder)}
            helperText={formatMessage(messages.helper)}
            disabled={!isEditable}
            value={this.state.title}
            inputProps={{ maxLength: EVENT_MAX_TITLE_LENGTH }}
            onChange={this.handleChange}
            onKeyDown={this.handleEnter}
            onBlur={this.handleBlur}
          />
        </div>
      </Event>
    );
  }
}

EventControlsName.propTypes = {
  eventId: PropTypes.string.isRequired,
  questId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  onRename: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  intl: intlShape,
};

export { EventControlsName as PureEventName };
export default withStyles(generateStyles, { withTheme: true })(
  withRouter(injectIntl(EventControlsName))
);
