import React from 'react';
import classNames from 'classnames';
import './withStructure.css';

/**
 * Adds header, paddings and other stuff to the `Popup`
 * @param {React::Component} Component - `Popup` component to overload
 */
export default function withStructure(Component) {
  /**
   * `Popup` with structured elements
   * @param {Object} $
   * @param {String} title - popup header text
   * @param {Boolean} noPadding - do not show paddings in `.Popup__content` element (visible window)
   * @param {...popupProps} $.popupProps - props passed to `Popup`
   */
  return function ({
    title,
    close,
    children,
    noPadding = true,
    ...popupProps
  }) {
    return (
      <Component title={title} {...popupProps}>
        <div className="Popup__wrapper">
          <div className="Popup__header">
            <h4 className="Popup__title">{title}</h4>
            {close}
          </div>
          <div
            className={classNames(
              'Popup__content',
              noPadding && 'Popup__content--no-padding'
            )}
          >
            {children}
          </div>
        </div>
      </Component>
    );
  };
}
