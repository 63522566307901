// Merge labels stats data to the single array
const mergeLabels = (rootLabelsArray, sourceLabelsArray) => {
  // Copy value to a new variable
  let newRootLabelsArray = rootLabelsArray;

  // Get labels list from root array
  const rootLabelsList = rootLabelsArray.map(
    (rootLabelData) => rootLabelData.labelName
  );

  // Iterate the labels from the source array. If root array have some label from source array, stats data from source will be merged with label stats data from root array. If root array doesn't have some label from source array, stats data from source will be pushed to root array
  sourceLabelsArray.forEach((sourceLabelData) => {
    const { labelName, ...stats } = sourceLabelData;
    if (rootLabelsList.includes(labelName)) {
      newRootLabelsArray = newRootLabelsArray.map((rootLabelData) =>
        rootLabelData.labelName === labelName
          ? { ...rootLabelData, ...stats }
          : rootLabelData
      );
    } else {
      return newRootLabelsArray.push(sourceLabelData);
    }
  });
  return newRootLabelsArray;
};

// Merge stats of shows, views, clicks and etc
export const mergeStats = (arrayOfStats) => {
  return arrayOfStats.reduce((mergedStats, metricStats) => {
    const { total = {}, labels = [] } = mergedStats;
    const { total: totalMetricStats, labels: labelsMetricStats } = metricStats;

    // Get total stats and labels stats of widgets
    const newTotalStats = { ...total, ...totalMetricStats };
    const newLabelsStats = mergeLabels(labels, labelsMetricStats);

    return {
      total: newTotalStats,
      labels: newLabelsStats,
    };
  }, {});
};
