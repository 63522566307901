import checkContentVisibility from '../event/checkContentVisibility';

/**
 * Sets `visible: true` for the quest's events content of given type
 * @param {Object} quest - quest to process
 * @param {String} type - event content type
 * @returns {Object} - processed quest with all
 */
export default function enableContentVisibility(quest, type) {
  const invisible = [];
  quest.events.forEach((event, eventIndex) => {
    const found = checkContentVisibility(event, type);
    if (found.length) {
      invisible.push([eventIndex, found]);
    }
  });

  if (invisible.length) {
    const questCopy = { ...quest };
    invisible.forEach(([eventIndex, contentIndexes]) => {
      contentIndexes.forEach((contentIndex) => {
        questCopy.events[eventIndex].content[contentIndex].visible = true;
      });
    });

    return questCopy;
  }

  return quest;
}
