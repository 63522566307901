import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import GoogleIcon from './icons/google.svg';
import FbIcon from './icons/fb.svg';
import TwitterIcon from './icons/twitter.svg';
import VkIcon from './icons/vk.svg';
import './links.css';
import './LoginSocial.css';

/**
 * Available social nets to login with
 * @type {Array}
 */
const socialNets = {
  google: {
    link: 'google-oauth2',
    icon: GoogleIcon,
  },
  facebook: {
    link: 'facebook',
    icon: FbIcon,
  },
  twitter: {
    link: 'twitter',
    icon: TwitterIcon,
  },
  vk: {
    link: 'vk-oauth2',
    icon: VkIcon,
  },
};

/**
 * Log with social net buttons
 * @param {String} backURL - url of the page showed displayed after login
 * @param {Array[String]} socials - social nets to be showed
 */
class LoginSocial extends Component {
  state = {
    loading: null,
  };

  /**
   * Displays "Loading" on the given service
   * @param {String} service - social net id
   */
  setLoading = (service) => {
    this.setState({ loading: service });
  };

  render() {
    const {
      intl: { locale },
      backURL,
      socials = [],
    } = this.props;
    const { loading } = this.state;

    return (
      <div className="LoginSocial">
        {socials.map((social) => {
          const socialId = social.toLowerCase();
          return (
            <a
              key={socialId}
              className={`login__social-link login__social-link_${
                socialId === 'facebook' ? 'fb' : socialId
              }`}
              href={`/login/${socialNets[socialId].link}/?lang=${locale}&next=/${backURL}`}
              onClick={() => this.setLoading(socialId)}
            >
              <img
                className="login__social-link-icon"
                src={socialNets[socialId].icon}
                alt={`${social} icon`}
              />
              <span className="login__social-link-title">
                {loading === socialId ? (
                  <FormattedMessage
                    id="LoginSocial.connecting"
                    defaultMessage="Connecting..."
                  />
                ) : (
                  <FormattedMessage
                    id="LoginSocial.continueWith"
                    defaultMessage="Continue with {vendor}"
                    values={{ vendor: social }}
                  />
                )}
              </span>
            </a>
          );
        })}
      </div>
    );
  }
}

export default injectIntl(LoginSocial);
