import React, { useMemo } from 'react';
import {
  Typography,
  makeStyles,
  TextField,
  Paper,
  Grid,
  Button,
} from '@material-ui/core';
import './TourDescription.css';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import * as publishingActions from '../../../actions/publishing';
import TourContentFooterButtons from '../../TourContentFooter/TourContentFooterButtons';
import debounce from '../../../functions/debounce';
import HelperLink from '../../HelperLink';
import { PRODUCT_DESCRIPTION_CHARACTER_LIMIT } from '../../../constants/limits';
import { HELP_CENTER_URL } from '../../../constants/branding';

/**
 * JSS styles for `TourDescription` element
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 24,
    marginTop: 40,
    marginBottom: -15,
  },
  container: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
  },
  cardTitle: {
    marginBottom: 14,
  },
  helperFullness: {
    marginTop: 40,
    marginBottom: -25,
  },
  text: {
    marginBottom: 25,
  },
  grid: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 25,
    },
  },
}));

const TourDescription = ({
  currentQuest: { products },
  currentQuestId,
  publishing: { receivedPublishData },
  // eslint-disable-next-line no-shadow
  publishingActions,
  location,
  userLocale,
  description,
  setDescription,
}) => {
  /**
   * JSS styles for `TourDescription` element
   * @type {React::Hook}
   */
  const classes = useStyles();

  /**
   * Validate nextValue sting and send it to server
   * @param {String} nextValue - product value for passed attribute
   * @param {String} attribute - product attribute key name
   */
  const handleSave = (nextValue, attribute) => {
    // eslint-disable-next-line no-param-reassign
    nextValue = nextValue.trim();
    if (nextValue === receivedPublishData[attribute]) return;
    publishingActions.sendPublishData(products[0].id, {
      [attribute]: nextValue,
    });
  };

  /**
   * Memoized and debounced wrap of handleSave
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSave = useMemo(() => debounce(handleSave, 1000), [
    receivedPublishData,
  ]);

  /**
   * Update local state and save value with wrapped handleSave
   * @param {Function} func - function to update local state
   * @param {String} attribute - product attribute key name
   * @param {Event} event - DOM event caused trigger `onChange`
   */
  const handleChange = (func, attribute) => (event) => {
    func(event.target.value);
    debouncedHandleSave(event.target.value, attribute);
  };

  return (
    <section className="App__main TourDescription__main">
      <Container className={classes.container}>
        <Grid container>
          <Grid item xs={12} md={8} lg={8}>
            <Paper elevation={0} className={classes.paper}>
              <Typography
                gutterBottom
                className={classes.cardTitle}
                variant="h5"
              >
                <FormattedMessage
                  id="StudioCreateProduct.tourDescription"
                  defaultMessage="Tour description"
                />
              </Typography>
              <Typography gutterBottom variant="body1">
                <FormattedMessage
                  id="StudioCreateProduct.subheadings3"
                  defaultMessage="Describe what travelers will see or do during your activity, what impressions they will get."
                />
                <br />
                <br />
                <FormattedMessage
                  id="StudioCreateProduct.subheadings4"
                  defaultMessage={`What will they see first? What then? Where and how will it end?
        Do not write «we», «our», your company name and do not copy descriptions
        from other sites. Come up with a unique description.`}
                />
              </Typography>

              <HelperLink
                text={
                  <FormattedMessage
                    id="StudioCreateProduct.helperLink2"
                    defaultMessage="How to make a catchy description"
                  />
                }
                link={`${HELP_CENTER_URL}${userLocale}/fulldescription/`}
                locale={userLocale}
              />

              <TextField
                multiline
                className="TourDescription__descriptionField"
                label={
                  <FormattedMessage
                    id="StudioCreateProduct.tourDescription2"
                    defaultMessage="Tour description"
                  />
                }
                rows={1}
                rowsMax={40}
                variant="standard"
                value={description}
                error={!receivedPublishData.description && location.highlights}
                inputProps={{ maxLength: PRODUCT_DESCRIPTION_CHARACTER_LIMIT }}
                helperText={
                  !receivedPublishData.description && location.highlights ? (
                    <FormattedMessage
                      id="StudioCreateProduct.tourDescriptionCantBeEmpty"
                      defaultMessage="The field cannot be empty"
                    />
                  ) : (
                    <FormattedMessage
                      id="StudioCreateProduct.charsLeft2"
                      defaultMessage="Characters {value} left"
                      values={{
                        value:
                          PRODUCT_DESCRIPTION_CHARACTER_LIMIT -
                          description.length,
                      }}
                    />
                  )
                }
                onChange={handleChange(setDescription, 'description')}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4} className={classes.grid}>
            <Paper elevation={0} className={classes.paper}>
              <Typography variant="h5" className={classes.text}>
                <FormattedMessage
                  id="StudioCreateProduct.TourDescriptionIdeas"
                  defaultMessage="Want some cool ideas?"
                />
              </Typography>
              <Typography variant="body1" className={classes.text}>
                <FormattedMessage
                  id="StudioCreateProduct.TourDescriptionIdeasSubtitle"
                  defaultMessage="Over the past 4 years we have understood the preferences of WeGoTrip customers and selected 30 best ideas for you"
                />
              </Typography>
              <a
                target="_blank"
                href={
                  userLocale === 'ru'
                    ? 'https://airtable.com/shrOscioxFYVgAXIE/tbl2giWA6jSDR7iJu'
                    : 'https://airtable.com/shrc4fEdL344N4rIH'
                }
                rel="noopener noreferrer"
              >
                <Button variant="outlined" color="primary">
                  <FormattedMessage
                    id="StudioCreateProduct.TourDescriptionButton"
                    defaultMessage="Pick up an idea"
                  />
                </Button>
              </a>
            </Paper>
          </Grid>
        </Grid>
        {!receivedPublishData.description && (
          <Typography className={classes.helperFullness} variant="body1">
            <FormattedMessage
              id="StudioCreateProduct.TourDescriptionRemainsToFill"
              defaultMessage="Remaining sections to fill in: tour description"
            />
          </Typography>
        )}
        <TourContentFooterButtons
          leftLink={`/quest/${currentQuestId}/title`}
          rightLink={
            location.highlights
              ? `/quest/${currentQuestId}/check`
              : `/quest/${currentQuestId}/map`
          }
          withRedirectToTourCheck={location.highlights}
          leftBtnVisible={!location.highlights}
        />
      </Container>
    </section>
  );
};

function makeMapStateToProps() {
  const mapStateToProps = (state) => {
    return {
      publishing: state.publishing,
      currentQuestId: state.quests.currentQuestId,
      userLocale: state.user.locale,
    };
  };
  return mapStateToProps;
}

function mapDispatchToProps(dispatch) {
  return {
    publishingActions: bindActionCreators(publishingActions, dispatch),
  };
}

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(TourDescription)));
