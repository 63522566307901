/**
 * MapBox API token for accessing tile sets
 * @type {String}
 */
export const ACCESS_TOKEN =
  'pk.eyJ1Ijoid2Vnb3RyaXAiLCJhIjoiY2s1NmkybXd6MDU1YjNrbW5ta204OW1tMSJ9.H1YHLJfbmeZcQquJMHrDQw';

/**
 * URL to MapBox to reference tile set origin
 * @type {String}
 */
export const ATTRIBUTION =
  'Map data <a href="http://openstreetmap.org">OpenStreetMap</a>, <a href="http://mapbox.com">Mapbox</a>';

/**
 * ID of the MapBox tile set with Russian place names
 * @type {String}
 */
export const TILESET_ID_RU = 'wegotrip/ckwm6ufktagbb14ocmnb7ljnc';

/**
 * ID of the MapBox tile set with English place names
 * @type {String}
 */
export const TILESET_ID_EN = 'wegotrip/ckwm6szr66rrr14ns3k187k6l';

/**
 * Template of the URL to MapBox tile set
 * Includes control sequences detecting by `react-leaflet::Map` component
 * @type {String}
 */
export const TILESET_URL = `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${ACCESS_TOKEN}`;
