import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import { VisibleContext } from '../DropdownVisibleContext';

/**
 * Add ability to show `material-ui::Dialog` instead of `rc-dropdown` on small layouts
 * @param {React::Component} Component - overloaded `rc-dropdown`
 */
export default function withSmDialog(Component) {
  /**
   * `rc-dropdown` with ability to be chenged to `material-ui::Dialog`
   * @param {Object} $
   * @param {String} $.className - will be added to the root element no matter it is `rc-dropdown` or `material-ui::Dialog`
   * @param {Object} $.classes - CSS classes of the inner elements
   * @param {Object} $.DialogProps - props for `material-ui::Dialog`
   * @param {Object} $.DropdownProps - props for `rc-dropdown`
   * @param {React::JSX} $.overlay - content of the `rc-dropdown`|`material-ui::Dialog`
   * @param {React::JSX} $.children - element after click on which dropdown|dialog will be shown
   * @param {Function?} $.onVisibleChange - calls when visibility of the `rc-dropdown`|`material-ui::Dialog` changing
   * @param {Boolean?} $.visible - if dropdown/popup is shown (if not - component will run in uncontrolled way)
   */
  return function ({
    className,
    classes = {},
    visible: visibleExt,
    DropdownProps = {},
    DialogProps = {},
    overlay,
    children,
    onVisibleChange = () => {},
  }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    /**
     * If component is running in controlled way
     * @type {Boolean}
     */
    const hasExternalVisibility = typeof visibleExt === 'boolean';

    /**
     * If dialog with `TableColumnsToggleMenu` is shown
     * @type {Boolean}
     */
    const [isMenuShown, setMenuShown] = useState(
      hasExternalVisibility ? visibleExt : false
    );

    /**
     * Toggles `rc-dropdown`|`material-ui::Dialog` visibility
     * @param {Boolean} visible - if component is visible
     * @param {Boolean?} shouldNotAct - should component toggle visibility or just call `props::onVisibleChanged`
     */
    const toggleShown = (visible, shouldNotAct) => {
      if (!shouldNotAct) {
        setMenuShown(visible);
      }
      onVisibleChange(visible);
    };

    let Container = Component;
    let containerProps = {
      visible: isMenuShown,
      overlayClassName: classNames(className, classes.dropdown),
      trigger: ['click'],
      overlay,
      ...DropdownProps,
      onVisibleChange: (newVisible) =>
        toggleShown(newVisible, hasExternalVisibility),
    };

    if (isMobile) {
      Container = Dialog;
      containerProps = {
        className: classNames(className),
        classes: classes.dialog,
        open: isMenuShown,
        children: overlay,
        ...DialogProps,
        onClose: () => toggleShown(false, hasExternalVisibility),
      };
    }

    /**
     * Changing visibility according to the `visible` prop value change
     */
    useEffect(() => {
      if (hasExternalVisibility) {
        toggleShown(visibleExt, !hasExternalVisibility);
      }
    }, [visibleExt]);

    const isWrapper = [Component].includes(Container);

    return (
      <VisibleContext.Provider
        value={{
          isDropdownVisible: isMenuShown,
        }}
      >
        {isWrapper ? (
          <Container {...containerProps}>{children}</Container>
        ) : (
          <span onClick={() => toggleShown(true)}>{children}</span>
        )}
        {!isWrapper ? <Container {...containerProps} /> : null}
      </VisibleContext.Provider>
    );
  };
}
