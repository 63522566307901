import React, { Component, Fragment } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CodesActions from '../../actions/codes';
import { Loader } from '../Loader';
import Form, * as validations from '../Form';
import SmartInput from '../SmartInput';
import Button from '../Button';
import CodesTable from '../CodesTable';
import CodesBalance from '../CodesBalance';
import { selectInputValue } from '../../functions/input';
import { makeGetCurrentQuest } from '../../selectors';
import Textarea from '../Textarea';
import './Codes.css';
import { setTimeout } from 'timers';
import ShareLink from '../ShareLink';
import StructuredPopup from '../Popup/StructuredPopup';
import HelpTooltip from '../HelpTooltip';

const defaultFormState = {
  maxPlayers: '',
  count: '',
};

const messages = defineMessages({
  placeholderPlayer: {
    id: 'GenerateCodes.placeholderPlayer',
    defaultMessage: 'Players',
  },
  placeholderQuantity: {
    id: 'GenerateCodes.placeholderQuantity',
    defaultMessage: 'Quantity',
  },
  note: {
    id: 'GenerateCodes.note',
    defaultMessage:
      'After you created a tour you can share it via a code (or links',
  },
});

class GenerateCodes extends Component {
  state = {
    textAreaShowed: false,
  };

  createCodes = (
    { count, maxPlayers },
    setValues,
    isValid,
    isOver,
    validate
  ) => {
    const { onAdd, questId: id } = this.props;
    validate(true);
    if (!isValid() || isOver) return; // TODO: show error
    onAdd(id, {
      count: parseInt(count, 10),
      maxPlayers: parseInt(maxPlayers, 10),
    });
    setValues(defaultFormState);
  };

  toggleTextarea = () => {
    this.setState((prev) => {
      const nextTextAreaShowed = !prev.textAreaShowed;
      if (nextTextAreaShowed) {
        setTimeout(() => {
          selectInputValue(this._textarea);
          this._textarea.focus();
        }, 0);
      }
      return {
        ...prev,
        textAreaShowed: nextTextAreaShowed,
      };
    });
  };

  renderSuccess(lastCreated) {
    if (!lastCreated.length) return null;
    const { textAreaShowed } = this.state;
    const count = lastCreated.length;
    const toggleLinkText = textAreaShowed ? (
      <FormattedMessage
        id="GenerateCodes.hideCodesText"
        defaultMessage="Hide"
      />
    ) : (
      <FormattedMessage
        id="GenerateCodes.showAndCopyCodesText"
        defaultMessage="View and copy"
      />
    );
    return (
      <div className="GenerateCodes__summary GenerateCodes__summary--success">
        <div className="GenerateCodes__summary--line">
          <FormattedMessage
            id="GenerateCodes.successFieldText"
            defaultMessage="You have successfully created {count} {count, plural, =1 {code} other {codes}}!"
            values={{
              count,
            }}
          />
          <span>
            <a className="link link--blue" onClick={this.toggleTextarea}>
              {toggleLinkText}
            </a>
          </span>
        </div>
        <Textarea
          readOnly
          inputRef={(t) => (this._textarea = t)}
          className="GenerateCodes__summary--textarea"
          value={lastCreated.join('\n')}
          style={{ display: textAreaShowed ? 'block' : 'none' }}
        />
      </div>
    );
    // TODO: copy button
  }

  render() {
    const {
      maxDownloads,
      usedDownloads,
      balance = 0,
      lastCreated = [],
      isSending,
      formatMessage,
    } = this.props;
    const lowerOrEqualToBalance = validations.createValidationRule(
      'lowerOrEqualToBalance',
      (_, { maxPlayers, count }) => {
        if (maxPlayers) {
          maxPlayers = 0;
        }
        if (count) {
          count = 0;
        }
        return maxPlayers * count <= balance;
      },
      true
    );

    return (
      <section className="GenerateCodes">
        <h1 className="GenerateCodes__heading">
          <FormattedMessage
            id="GenerateCodes.title"
            defaultMessage="Creating codes"
          />
          <HelpTooltip id="codes-note">
            {formatMessage(messages.note)}
          </HelpTooltip>
        </h1>
        <div className="GenerateCodes__inner">
          <Form
            instantlyValidate
            validations={{
              maxPlayers: [
                validations.notEmpty,
                validations.greaterThanNull,
                lowerOrEqualToBalance,
              ],
              count: [
                validations.notEmpty,
                validations.greaterThanNull,
                lowerOrEqualToBalance,
              ],
            }}
            defaultValues={{
              maxPlayers: 5,
              count: 1,
            }}
            render={({
              getValue,
              setValue,
              setValues,
              getErrors,
              values,
              errors,
              isValid,
              validate,
            }) => {
              const maxPlayers = parseInt(getValue('maxPlayers'), 10) || '';
              const count = parseInt(getValue('count'), 10) || '';
              const isOver = isValid() && !(maxPlayers * count <= balance);
              const downloads = maxPlayers * count;
              return (
                <Fragment>
                  <div className="GenerateCodes__line">
                    <FormattedMessage
                      id="GenerateCodes.accessStart"
                      defaultMessage="Create {count} access for {players} unique players"
                      values={{
                        count: (
                          <SmartInput
                            className="GenerateCodes__part GenerateCodes__input--count"
                            placeholder={formatMessage(
                              messages.placeholderQuantity
                            )}
                            name="count"
                            value={count}
                            errors={getErrors('count')}
                            onChange={setValue}
                          />
                        ),
                        countNumber: count,
                        players: (
                          <SmartInput
                            className="GenerateCodes__part GenerateCodes__input--max-players"
                            placeholder={formatMessage(
                              messages.placeholderPlayer
                            )}
                            name="maxPlayers"
                            value={maxPlayers}
                            errors={getErrors('maxPlayers')}
                            onChange={setValue}
                          />
                        ),
                        playersNumber: maxPlayers,
                      }}
                    />
                    <Button
                      theme="shadow"
                      className="GenerateCodes__button"
                      readOnly={!isValid() || isOver}
                      loading={isSending}
                      onClick={this.createCodes.bind(
                        null,
                        values,
                        setValues,
                        isValid,
                        isOver,
                        validate
                      )}
                    >
                      {isValid() ? (
                        <FormattedMessage
                          id="GenerateCodes.validButtonText"
                          defaultMessage="Create {count} {count, plural, =1 {code} other {codes}}"
                          values={{
                            count,
                          }}
                        />
                      ) : (
                        <FormattedMessage
                          id="GenerateCodes.defButtonText"
                          defaultMessage="Create a code"
                        />
                      )}
                    </Button>
                  </div>
                  <CodesBalance
                    maxDownloads={maxDownloads}
                    usedDownloads={usedDownloads}
                    balance={balance}
                    nextDownloads={downloads}
                  />
                  {this.renderSuccess(lastCreated)}
                </Fragment>
              );
            }}
          />
        </div>
      </section>
    );
  }
}

class Codes extends Component {
  componentWillMount() {
    const {
      user,
      match: {
        params: { questId },
      },
      history,
      codesActions: { fetchQuestCodes },
    } = this.props;

    if (user.email && !user.client) {
      history.push(`/quest/${questId}/events/0`);
    }

    fetchQuestCodes(questId);
  }

  getCurrentCodes(stats, questId) {
    // TODO: move to actions
    questId = parseInt(questId, 10);
    const needed = stats.find((s) => s.questId === questId);
    if (!needed) return null;
    return needed;
  }

  renderAccessPopup() {
    const { history, currentQuest } = this.props;

    return (
      <Route
        path="/quest/:questId/codes/:code"
        render={({
          match: {
            params: { code },
          },
        }) => (
          <StructuredPopup
            isOpen
            handleCloseClick={() => {
              history.push(`/quest/${currentQuest.id}/codes/`);
            }}
            title={
              <FormattedMessage
                id="QuestShare.accessToQuest"
                defaultMessage="Access to the tour"
              />
            }
            noPadding={false}
          >
            <ShareLink code={code} title={currentQuest.title} />
          </StructuredPopup>
        )}
      />
    );
  }

  render() {
    const {
      user,
      user: {
        client: { maxDownloads, downloads },
      },
      codes = [],
      codesActions,
      lastCreated,
      recent,
      isFetching,
      isCreating,
      isUpdating,
      match: {
        params: { questId },
      },
      intl: { formatMessage },
    } = this.props;

    const balance = maxDownloads - downloads;
    const userCanCreateCodes = balance > 0;
    // TODO: move to reducer

    return (
      <section className="App__main Codes__main">
        <Switch>
          <Route
            path="/quest/:questId/codes/"
            render={() => (
              <Fragment>
                {userCanCreateCodes && (
                  <GenerateCodes
                    questId={questId}
                    balance={balance}
                    maxDownloads={maxDownloads}
                    usedDownloads={downloads}
                    isSending={isCreating}
                    lastCreated={lastCreated}
                    formatMessage={formatMessage}
                    onAdd={codesActions.createQuestCodes}
                  />
                )}
                {codes.length ? (
                  <CodesTable
                    questId={questId}
                    maxDownloads={maxDownloads}
                    usedDownloads={downloads}
                    balance={balance}
                    codes={codes}
                    updateCode={codesActions.updateAccessCode}
                    isSending={isUpdating}
                    recent={recent}
                  />
                ) : (
                  isFetching && (
                    <section className="Codes__main Codes__main--center Codes__main--loader">
                      <Loader>
                        <FormattedMessage
                          id="Codes.textLoader"
                          defaultMessage="Load tours codes"
                        />
                      </Loader>
                    </section>
                  )
                )}
                {this.renderAccessPopup()}
              </Fragment>
            )}
          />
        </Switch>
      </section>
    );
  }
}

function makeMapStateToProps() {
  const getCurrentQuest = makeGetCurrentQuest();
  const mapStateToProps = (state, props) => {
    return {
      user: state.user,
      currentQuest: getCurrentQuest(state, props),
      codes: state.codes.codes,
      lastCreated: state.codes.lastCreated,
      recent: state.codes.recent,
      isFetching: state.codes.isFetching,
      isCreating: state.codes.isCreating,
      isUpdating: state.codes.isUpdating,
    };
  };
  return mapStateToProps;
}

function mapDispatchToProps(dispatch) {
  return {
    codesActions: bindActionCreators(CodesActions, dispatch),
  };
}

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(Codes)));
