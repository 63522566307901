import incrementMediaId from '../id/incrementMediaId';
import operateMediaId from './operateMediaId';

/**
 * Shifts indexes in media ids of uploads with same array id with passed `mediaId` and located after it
 * @param {Array[Object]} uploads - descriptions of current uploading stuff from redux `store::uploads.uploads[]`
 * @param {MediaId} mediaId - mediaId of the removed upload
 */
export default function removeMediaId(uploads, mediaId) {
  return operateMediaId(uploads, mediaId, (uploadMediaId) =>
    uploadMediaId.index > mediaId.index
      ? incrementMediaId(uploadMediaId, -1)
      : uploadMediaId
  );
}
