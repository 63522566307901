import React from 'react';
import { Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Redirect } from 'react-router';
import ProfileContent from '../ProfileContent';
import chartImg from '../../img/profileReviews/chart.png';
import HelmetTitle from '../HelmetTitle';
import { HELP_CENTER_URL } from '../../constants/branding';

const useStyles = makeStyles(() => ({
  h5: {
    marginBottom: 16,
  },
  rightContainerChart: {
    float: 'right',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -9,
  },
  rightContainerRating: {
    float: 'right',
  },
  image: {
    width: 152,
    height: 56,
    marginRight: 16,
  },
  rightContainerText: {
    width: 200,
  },
  text: {
    fontSize: 12,
  },
}));

const messages = defineMessages({
  reviewsTitle: {
    id: 'ProfileTour.reviewsTitle',
    defaultMessage: 'Reviews',
  },
});

const ProfileReviews = ({
  partner: {
    data: { rating },
  },
  quests,
  user: { locale },
}) => {
  const classes = useStyles();
  const [tab, setTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  if (quests.quests.length === 0) return <Redirect to="/welcome" />;

  return (
    <ProfileContent>
      <HelmetTitle message={messages.reviewsTitle} />
      {tab === 0 && (
        <div className={classes.rightContainerChart}>
          <img className={classes.image} src={chartImg} alt="chart" />
          <div className={classes.rightContainerText}>
            <Typography color="textSecondary" className={classes.text}>
              <FormattedMessage
                id="ProfileReviews.reviewsChartTitle"
                defaultMessage="Your dynamics of positive reviews for six months"
              />
            </Typography>

            <a
              target="_blank"
              href={`${HELP_CENTER_URL}${locale}/reviews/`}
              rel="noopener noreferrer"
            >
              <Typography className={classes.text} color="primary">
                <FormattedMessage
                  id="ProfileReviews.reviewsChartLink"
                  defaultMessage="How can I improve it?"
                />
              </Typography>
            </a>
          </div>
        </div>
      )}
      {tab === 1 && (
        <div className={classes.rightContainerRating}>
          <Typography>
            <FormattedMessage
              id="ProfileReviews.rating"
              defaultMessage="Your rating {value}"
              values={{ value: rating }}
            />
          </Typography>
          <a
            target="_blank"
            href={`${HELP_CENTER_URL}${locale}/reviews/`}
            rel="noopener noreferrer"
          >
            <Typography className={classes.text}>
              <FormattedMessage
                id="ProfileReviews.ratingSubtitle"
                defaultMessage="How to increase it?"
              />
            </Typography>
          </a>
        </div>
      )}
      <Typography className={classes.h5} variant="h5">
        <FormattedMessage
          id="ProfileReviews.reviews"
          defaultMessage="Reviews"
        />
      </Typography>

      <Tabs
        className={classes.tabs}
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
      >
        <Tab
          label={
            <FormattedMessage
              id="ProfileReviews.unanswered"
              defaultMessage="Unanswered"
            />
          }
        />
        <Tab
          label={
            <FormattedMessage id="ProfileReviews.all" defaultMessage="All" />
          }
        />
      </Tabs>
    </ProfileContent>
  );
};

export default ProfileReviews;
