/**
 * Returns unique pricing categories labels
 * @param {Array[Object]} attractions - array of attractions objects
 * @return {Array[String]} - unique pricing category labels
 */
export default (attractions) => {
  return attractions
    .map((a) => a.tickets.map((t) => t.pricingCategories.map((c) => c)))
    .flat(2)
    .map((pc) => pc.label)
    .filter((value, index, self) => self.indexOf(value) === index);
};
