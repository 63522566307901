export const unauthorized = 'unauthorized';
export const accessDenied = 'accessDenied';
export const serverError = 'serverError';
export const defaultError = 'defaultError';
export const conflictError = 'conflictError';
export const badRequest = 'badRequest';

// Custom errors
export const NO_REQUISITES = 'NO_REQUISITES';
export const PAYOUT_IN_PROCESS = 'PAYOUT_IN_PROCESS';
export const INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS';
export const PAYOUT_SUCCESS_MESSAGE = 'PAYOUT_SUCCESS_MESSAGE';
