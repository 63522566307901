import React, { Component } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { STORAGE_URL } from '../../../constants/branding';
import { getNameUploaded } from '../../../functions/files';
import ProgressCircle from '../../ProgressCircle';
import AudioPlayer, { AudioPlayerMock } from '../../AudioPlayer';
import DropzoneAudio from '../../Dropzone/withAudio';
import EventComponent from '../../Event';
import './EventControlsAudio.css';
import InfoFileCompressing from '../../InfoFileCompressing';

function RemoveButton(props) {
  return <button className="EventControlsAudio__item--remove" {...props} />;
}

function downloadBlob(blob, filename) {
  const a = document.createElement('a');
  a.download = filename;
  a.href = blob;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

function downloadResource(url, filename) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      downloadBlob(blobUrl, filename);
    })
    // eslint-disable-next-line no-console
    .catch((e) => console.error(e));
}

function DownloadButton({ url, fileName }) {
  return (
    <GetAppIcon
      className="EventControlsAudio__item--download"
      onClick={() => downloadResource(url, fileName)}
    />
  );
}

class EventControlsAudio extends Component {
  /**
   * Max amount of content in the dropzone
   * @type {Number}
   */
  audiosAmount = 1;

  /**
   * Processes new files appeared in dropzone
   * Manages dropzone errors
   * @param {Array} files
   */
  handleAudios = (files) => {
    const { params, onAdd } = this.props;
    onAdd(
      params,
      files.map((file) => ({ file, contentType: 'audio' }))
    );
  };

  abortUpload = (id) => {
    const { onAbort } = this.props;
    onAbort(id);
  };

  removeAudio = (index) => {
    const { params, onRemove } = this.props;
    onRemove({ audioId: index, ...params });
  };

  renderUploaded() {
    const { audios } = this.props;
    const audiosExist = Boolean(audios && audios?.length);

    if (!audiosExist) {
      return null;
    }

    return audios.map((path, i) => (
      <div key={path} className="EventControlsAudio__item">
        <AudioPlayer
          theme="material"
          src={STORAGE_URL + path}
          name={getNameUploaded(path)}
        >
          <DownloadButton url={path} fileName={getNameUploaded(path)} />
          <RemoveButton onClick={() => this.removeAudio(i)} />
        </AudioPlayer>
      </div>
    ));
  }

  renderThumbs() {
    const { uploads } = this.props;

    if (!uploads?.length) {
      return null;
    }

    return uploads?.map((upload) => (
      <div key={upload.id} className="EventControlsAudio__item">
        <AudioPlayerMock
          before={
            <ProgressCircle
              animated
              size={20}
              progress={upload.progress * 100}
              color="#fff"
            />
          }
          name={upload.file.name}
        >
          <RemoveButton onClick={() => this.abortUpload(upload.id)} />
        </AudioPlayerMock>
      </div>
    ));
  }

  render() {
    const { audios, uploads } = this.props;

    return (
      <EventComponent>
        {audios?.length + uploads?.length === 0 ? (
          <DropzoneAudio
            currentAmount={audios?.length + uploads?.length}
            onDropAccepted={this.handleAudios}
          />
        ) : null}
        {this.renderUploaded()}
        {this.renderThumbs()}
        <InfoFileCompressing />
      </EventComponent>
    );
  }
}

export default EventControlsAudio;
