import findRoutePoint from '../route/findRoutePoint';

/**
 * Hash map for getting triggers from event by its type
 * Keys are event trigger types, values - functions for getting triggers of that type
 * @type {Object}
 */
const triggerGetters = [
  {
    name: 'geo',
    check: (event, route) => findRoutePoint(route, event.id, true),
  },
  { name: 'button', check: () => true }, // Must always be last
];

/**
 * Gets trigger type event has
 * Note, that this will be a trigger fot the previous event
 * @param {Object} event - event description from redux store
 * @returns {Object} - key are trigger types (`"geo"` or `"button"`) which are presented on event
 */
export default function getEventTrigger(event, route) {
  return event
    ? triggerGetters.reduce((acc, { name: triggerType, check }) => {
        const data = check(event, route);
        switch (triggerType) {
          case 'geo':
            return data > -1 ? { ...acc, geo: data } : acc;
          case 'button':
            return Object.keys(acc).length ? acc : { button: true };
          default:
            return { ...acc, [triggerType]: !!data };
        }
      }, {})
    : {};
}
