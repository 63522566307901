/**
 * Converts query object to URL query string
 * @param {Object} query - query params
 * @returns {String} - URL query string with `?` on start if params are existing
 */
export default function queryObjectToString(query) {
  if (typeof query !== 'object') {
    return;
  }

  const stringQuery = Object.keys(query)
    .reduce((acc, key) => `${acc}&${key}=${query[key]}`, '')
    .slice(1);

  return stringQuery.length ? `?${stringQuery}` : '';
}
