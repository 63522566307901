import React, { useRef, Fragment, useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { injectIntl, defineMessages } from 'react-intl';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import exportToXLS from '../../../functions/table/exportToXLS';
import TableOptionsButton from '../TableOptionsButton';
import ErrorsQueue from '../../../functions/error/ErrorsQueue';

/**
 * Messages for `Table::withExport(Table)`
 * @type {Object}
 */
const messages = defineMessages({
  export: {
    id: 'TableExport.export',
    defaultMessage: 'Export',
  },
  exportTableName: {
    id: 'TableExport.name',
    defaultMessage: 'Table',
  },
});

/**
 * JSS styles for `Table::withExport`
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  loader: {
    marginLeft: '2px',
    [theme.breakpoints.down('sm')]: {
      margin: '2px',
    },
  },
}));

/**
 * Adds "Export" button to `Table::withOptions(Table)`
 * @param {React::Component} Component - `Table` component to overload
 */
export default function withExport(Component) {
  /**
   * `Table` component with "Export" button
   * @param {Object} $
   * @param {String} $.exportName - name of the exported file
   * @param {Function} $.preExport - async function for preprocessing table before exporting
   *  !!IMPORTANT: must catch and show all errors itself
   * @param {...Object} $.tableProps - will be passed to the `Table` component
   */
  return injectIntl(function ({
    exportName,
    endGap,
    preExport,
    intl: { formatMessage },
    ...tableProps
  }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = useStyles();

    /**
     * Ref to the link used in `exportToXLS` for downloading generated file
     * @type {React::Ref}
     */
    const exportLinkRef = useRef();

    /**
     * If export is in progress
     * @type {Boolean}
     */
    const [isExporting, toggleExporting] = useState(false);

    /**
     * Triggers table converting to XLS
     */
    const exportTable = async () => {
      let tableData = ['header', 'rows'].map((k) => tableProps[k]);
      toggleExporting(true);

      if (typeof preExport === 'function') {
        tableData = await preExport(...tableData);
      }

      if (tableData) {
        exportToXLS(
          exportLinkRef,
          exportName || formatMessage(messages.exportTableName),
          ...tableData
        );
      }

      toggleExporting(false);
    };

    /**
     * Checking `TableHeaderData` and `TableRowData` for cells with non-exporting content of cells (e.g. - JSX)
     * If cell found and no `export` prop on `TableCellData` found - will throw a warning
     * Only for development mode
     */
    if (process.env.NODE_ENV !== 'production') {
      const { rows, header } = tableProps;

      useEffect(() => {
        /**
         * Logs error output
         * @param {String} type - type of the data spawned error (`"header"` for `TableHeaderData`, `"cell"` for `TableCellData`)
         * @param {String} id - id of the column spawned error
         */
        function screamCannotExport(type, id, rowIndex) {
          const errorDataType = `Table${
            type[0].toUpperCase() + type.slice(1)
          }Data`;
          const errorDataProp = type === 'header' ? 'title' : 'content';
          console.error(
            `${
              `Warning: ${errorDataType} cannot be exported` +
              `\n${errorDataType}::${errorDataProp} is not of Object type and ${errorDataType}::pure prop not provided or not of String type` +
              `\nIn column where ${errorDataType}::id is "${id}"`
            }${type === 'cell' ? `\nIn row with index ${rowIndex}` : ''}`
          );
        }

        const errors = new ErrorsQueue();

        /** Checking headers */
        header.forEach(({ id, title, pure }) => {
          if (typeof title === 'object' && typeof pure !== 'string') {
            errors.push(['header', id]);
          }
        });

        /** Checking cells */
        rows.forEach((row, i) => {
          header.forEach(({ id }) => {
            const { content, pure } = row[id];
            if (typeof content === 'object' && typeof pure !== 'string') {
              errors.push(['cell', id, i]);
            }
          });
        });

        errors.release(screamCannotExport);
      }, []);
    }

    return (
      <Component
        endGap={
          <Fragment>
            {endGap}
            <span>
              <TableOptionsButton
                disabled={isExporting}
                startIcon={
                  isExporting ? (
                    <CircularProgress
                      className={styles.loader}
                      size={isMobile ? 20 : 16}
                    />
                  ) : (
                    <GetAppIcon />
                  )
                }
                onClick={exportTable}
              >
                {formatMessage(messages.export)}
              </TableOptionsButton>
              <a ref={exportLinkRef} style={{ display: 'none' }} />
            </span>
          </Fragment>
        }
        {...tableProps}
      />
    );
  });
}
