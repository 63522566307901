import getEventTitle from '../../event/getEventTitle';
import getEventText from '../../event/getEventText';
import getEventAudios from '../../event/getEventAudios';
import getEventImages from '../../event/getEventImages';
import createValidationRule from './createValidationRule';
import { isEventContentFull } from './rulesName';

export const isEventContentFullRule = createValidationRule(
  isEventContentFull,
  'content',
  (event) =>
    [
      !!getEventTitle(event),
      !!getEventText(event),
      getEventImages(event).length > 0,
      getEventAudios(event).length > 0,
    ].every((r) => r === true)
);
