import React, { useRef, useState, useEffect, Fragment } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import { latLng, point, divIcon } from 'leaflet';
import { Marker } from 'react-leaflet';
import usePinDrag from '../usePinDrag';
import { getTheme } from '../MapPin';
import './withPinRadius.css';

/**
 * Handle icon size
 * @type {String}
 */
const ICON_SIZE = 14;

/**
 * Creates handle icon
 * @param {String} themeName - name of the `MapPin` theme
 * @param {String} dir - `"v"` and `"h"`, manages cursor changing on handler hover
 * @param {String} width - width of the icon in pixels (`ICON_SIZE` by default)
 * @param {String} height - height of the icon in pixels (`ICON_SIZE` by default)
 * @returns {Leaflet::Icon}
 */
function createHandleIcon(
  themeName,
  dir,
  width = ICON_SIZE,
  height = ICON_SIZE
) {
  const theme = getTheme(themeName);
  const style = {
    'border-color': theme.circle.color,
  };

  return divIcon({
    className: classNames(
      'MapPinRadius__handle',
      dir === 'v'
        ? 'MapPinRadius__handle--vertical'
        : 'MapPinRadius__handle--horizontal'
    ),
    iconSize: point(width, height),
    html: `<div class="MapPinRadius__circle" style="${Object.keys(style)
      .map((name) => `${name}:${style[name]}`)
      .join(';')}"></div>`,
  });
}

/**
 * Description of the bounding rectangle in geodedic coordinates
 * @typedef {Object} GeoBounds
 * @prop {Number} north - northest point of bound
 * @prop {Number} south - southest point of bound
 * @prop {Number} east - eastest point of bound
 * @prop {Number} west - westest point of bound
 * @prop {Leaflet::LatLng} center - center point of bound
 */

/**
 * Extends `MapPin` functional with `MapPin::Circle` radius changing feature
 * @param {React::Component} MapPinComponent - modified `MapPin` component declaration (not it's instance)
 */
export default function withPinRadius(MapPinComponent) {
  /**
   * `MapPin` with changable `MapPin::Circle` radius
   * @param {Object} $
   * @param {Object} $.leafletMap - `leaflet::Map` object from ref to `react-leaflet::Map`
   * @param {Number} $.circleProps.max - max reachable circle radius
   * @param {Number} $.circleProps.min - min reachable circle radius
   * @param {Boolean} $.circleProps.active - if handlers are visible and radius changing feature is enabled
   * @param {Function} $.onRadiusChanged - calls when user changes radius with handlers
   */
  return function ({
    coords,
    radius,
    outer,
    circleProps: { active, min, max, ...circleProps } = {},
    leafletMap,
    onRadiusChanged = () => {},
    ...props
  }) {
    /**
     * Empty mappable array with length equal to the amount of handlers
     * @type {Array[Undefined]}
     */
    const handlersTemplate = ['north', 'east', 'south', 'west'];

    /**
     * Ref to the container `leaflet::Map` class instance
     * @type {React::Ref}
     */
    const leafletMapRef = useRef(leafletMap);

    const [zoom, setZoom] = useState();
    const setZoomHandler = () => {
      if (setZoom && leafletMapRef.current) {
        setZoom(leafletMapRef.current.getZoom());
      }
    };

    /**
     * Bounding rectangle of circle in `MapPin`
     * @type {GeoBounds|Null}
     */
    const [borders, setBorders] = useState(null);

    /**
     * Icons for handlers on the Map
     * @type {Leaflet::Icons}
     */
    const [icons, _setIcons] = useState(
      handlersTemplate.map(() => createHandleIcon(props.theme))
    );
    const setIcons = (theme) => {
      const dirs = ['v', 'h'];
      _setIcons(
        handlersTemplate.map((dir, i) => createHandleIcon(theme, dirs[i % 2]))
      );
    };

    /**
     * Circle showing radius of the event activation
     * @type {React::Ref}
     */
    const circleRef = useRef();

    /**
     * Gets `GeoBounds` of the `MapPin` circle
     * @returns {GeoBounds}
     */
    const getCircleBounds = () => {
      const circle = get(circleRef, 'current.leafletElement');

      if (circle) {
        const bounds = circle.getBounds();
        return ['north', 'south', 'east', 'west', 'center'].reduce(
          (acc, dir) => {
            acc[dir] = bounds[`get${dir[0].toUpperCase()}${dir.slice(1)}`]();
            return acc;
          },
          {}
        );
      }
    };

    /**
     * Gets full coords of `GeoBounds` center points of edges by direction
     * @param {GeoBounds} bounds - bounding rect
     * @param {String} dir - direction on the Earth (e.g. `"north"`, `"south"`, etc.)
     * @returns {Leaflet::LatLng}
     */
    const getCoordsByDir = (bounds, dir) => {
      if (['east', 'west'].includes(dir)) {
        return latLng(bounds.center.lat, bounds[dir]);
      }
      if (['north', 'south'].includes(dir)) {
        return latLng(bounds[dir], bounds.center.lng);
      }
    };

    /**
     * References to the handlers
     * @type {React::Ref}
     */
    const handlersRefs = useRef(handlersTemplate.map(() => React.createRef()));

    /**
     * Drag and drop callback props for handlers
     * @type {Object}
     */
    const mouseTracking = handlersRefs.current.map((ref, i) =>
      usePinDrag({
        onDragging: (coords) => {
          const dir = handlersTemplate[i];
          let dirCoords;
          if (['east', 'west'].includes(dir)) {
            dirCoords = latLng(borders.center.lat, coords[1]);
          }
          if (['north', 'south'].includes(dir)) {
            dirCoords = latLng(coords[0], borders.center.lng);
          }

          let newRadius = borders.center.distanceTo(dirCoords);
          if (newRadius < min) {
            newRadius = min;
          } else if (newRadius > max) {
            newRadius = max;
          }
          onRadiusChanged(parseInt(Math.round(newRadius)));
        },
      })
    );

    /**
     * Recalculating positions of the handlers, when:
     * - `MapPin::Circle` was installed in DOM
     * - circle coords were changed
     * - circle radius was changed
     * - effect features were activated
     */
    useEffect(() => {
      const circle = get(circleRef, 'current.leafletElement');
      if (typeof zoom !== 'number' || zoom < 16) {
        setBorders(null);
      } else if (circle && active) {
        setBorders(getCircleBounds());
      }
    }, [circleRef, zoom, active, radius, coords]);

    /**
     * Saving `leafletMap` to local `leafletMapRef` for removing zoom event listeners
     */
    useEffect(() => {
      if (leafletMap) {
        leafletMapRef.current = leafletMap;
        leafletMap.on('zoomend', setZoomHandler);
        setZoom(leafletMap.getZoom());
      } else if (leafletMapRef.current) {
        try {
          leafletMapRef.current.off('zoomend', setZoomHandler);
        } catch (error) {
          console.error(error);
        }
      }

      return () => {
        if (leafletMapRef.current) {
          leafletMapRef.current.off('zoomend', setZoomHandler);
        }
      };
    }, [leafletMap]);

    /**
     * Recreate handler icons, when:
     * - `MapPin` theme was changed
     */
    useEffect(() => setIcons(props.theme), [props.theme]);

    return (
      <MapPinComponent
        circleProps={{ ref: circleRef, ...circleProps }}
        coords={coords}
        radius={radius}
        outer={
          <Fragment>
            {outer}
            {active && borders
              ? handlersTemplate.map((dir, i) => (
                  <Marker
                    ref={handlersRefs.current[i]}
                    key={dir}
                    position={getCoordsByDir(borders, dir)}
                    icon={icons[i]}
                    {...mouseTracking[i]}
                    draggable
                  />
                ))
              : null}
          </Fragment>
        }
        {...props}
      />
    );
  };
}
