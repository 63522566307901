import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';

/**
 * JSS styles for `ButtonToggle` component
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  toggleButtonText: {
    border: 'unset',
    padding: '4px 5px',
  },
  labelSpacing: {
    '& > *:not(:last-child)': {
      marginRight: '8px',
    },
  },
}));

/**
 * CSS overload for material-ui `ToggleButton`
 * @param {Object} $
 * @param {String} $.variant - themes of the component (`"text"`), same with one in material-ui `Button`
 * @param {...Object} $.props - props passed to material-ui `ToggleButton`
 */
export default function ButtonToggle({
  className,
  classes,
  variant = 'text',
  ...props
}) {
  const styles = useStyles();
  const themeName = variant.charAt(0).toUpperCase() + variant.slice(1);

  return (
    <ToggleButton
      className={classNames(className, styles[`toggleButton${themeName}`])}
      classes={{ ...classes, label: styles.labelSpacing }}
      {...props}
    />
  );
}
