import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import './Logo.css';

const message = defineMessages({
  goToAll: {
    id: 'Logo.goToAll',
    defaultMessage: 'Rename the event',
  },
});

class Logo extends Component {
  render() {
    const {
      intl: { formatMessage },
    } = this.props;
    const logo = <div className="Logo Header__block" />;
    return (
      <Link to="/" data-tip={formatMessage(message.goToAll)}>
        {logo}
      </Link>
    );
  }
}

export default injectIntl(Logo);
