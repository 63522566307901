import React from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/styles/makeStyles';
import './withThemeMaterial.css';

/**
 * Additional CSS overload for `react-calendar::Calendar` component
 *   for adding `material-ui` variables and colors
 * @type {React::Hook}
 */
const useStyles = makeStyles((theme) => ({
  root: {
    '& .react-calendar__month-view__days__day--neighboringMonth': {
      color: theme.palette.text.disabled,
    },
    '& button:hover': {
      background: theme.palette.actions.background,
    },
    '& button:active': {
      background: theme.palette.actions.main,
    },
    '& .react-calendar__tile--range': {
      background: theme.palette.primary.background,
      color: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.background,
        color: theme.palette.primary.border,
      },
      '&:active': {
        background: theme.palette.primary.main,
        color: theme.palette.background.main,
      },
    },
    '& .react-calendar__tile--hasActive': {
      background: theme.palette.primary.main,
      color: theme.palette.background.main,
      '&:hover': {
        background: theme.palette.primary.light,
      },
      '&:active': {
        background: theme.palette.primary.background,
        color: theme.palette.primary.main,
      },
    },
  },
}));

/**
 * Adds `material-ui` like theme to `react-calendar::Component` component
 * @param {React::Component} Component - modified component
 */
export default function withThemeMaterial(Component) {
  return function ({ className, ...calendarProps }) {
    const styles = useStyles();

    return (
      <Component
        className={classNames(
          'Calendar--theme-material',
          styles.root,
          className
        )}
        prevLabel="&#129105;"
        nextLabel="&#129107;"
        {...calendarProps}
      />
    );
  };
}
