import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  confirmYourChoice: {
    id: 'PicturesDropzone.language.confirmYourChoice',
    defaultMessage: 'Confirm your choice',
  },
  selectedPhotos: {
    id: 'PicturesDropzone.language.selectedPhotos',
    defaultMessage: 'Selected Photos',
  },
  imagePreview: {
    id: 'PicturesDropzone.language.imagePreview',
    defaultMessage: 'Preview',
  },
  confirmTheChoice: {
    id: 'PicturesDropzone.language.confirmTheChoice',
    defaultMessage: 'Confirm the choice',
  },
  changesNotSaved: {
    id: 'PicturesDropzone.language.changesNotSaved',
    defaultMessage: 'All unselected photos will be deleted',
  },
  cancel: {
    id: 'PicturesDropzone.language.cancel',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'PicturesDropzone.language.confirm',
    defaultMessage: 'Confirm',
  },
  deletePhoto: {
    id: 'PicturesDropzone.language.deletePhoto',
    defaultMessage: 'Delete the photo?',
  },
  delete: {
    id: 'PicturesDropzone.language.Delete',
    defaultMessage: 'Delete',
  },
  photoRemoved: {
    id: 'PicturesDropzone.language.photoRemoved',
    defaultMessage: 'The photo and text-description will be removed',
  },
});
